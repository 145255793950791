import {
  BlockLayout,
  FlexLayoutColumn,
  FlexLayoutRow,
  ImageLayout,
  TextLayout,
} from 'application/layout'
import { InputLayout } from 'application/layout/input/inputLayout'
import { ObjectPool } from 'application/layout/pool/objectPool'

export class LayoutItemPool {
  private blockPool: ObjectPool<BlockLayout>
  private textPool: ObjectPool<TextLayout>
  private imagePool: ObjectPool<ImageLayout>
  private inputPool: ObjectPool<InputLayout>
  private rowPool: ObjectPool<FlexLayoutRow>
  private columnPool: ObjectPool<FlexLayoutColumn>

  constructor() {
    this.blockPool = new ObjectPool(BlockLayout, 100)
    this.textPool = new ObjectPool(TextLayout, 100)
    this.imagePool = new ObjectPool(ImageLayout, 100)
    this.inputPool = new ObjectPool(InputLayout, 100)
    this.rowPool = new ObjectPool(FlexLayoutRow, 100)
    this.columnPool = new ObjectPool(FlexLayoutColumn, 100)
  }

  getBlock = (): BlockLayout => this.blockPool.get()
  getText = (): TextLayout => this.textPool.get()
  getImage = (): ImageLayout => this.imagePool.get()
  getInput = (): InputLayout => this.inputPool.get()
  getFlexRow = (): FlexLayoutRow => this.rowPool.get()
  getFlexColumn = (): FlexLayoutColumn => this.columnPool.get()

  releaseBlock = (item: BlockLayout): void => this.blockPool.release(item)
  releaseText = (item: TextLayout): void => this.textPool.release(item)
  releaseImage = (item: ImageLayout): void => this.imagePool.release(item)
  releaseInput = (item: InputLayout): void => this.inputPool.release(item)
  releaseFlexRow = (item: FlexLayoutRow): void => this.rowPool.release(item)
  releaseFlexColumn = (item: FlexLayoutColumn): void =>
    this.columnPool.release(item)
}
