import { DocumentSelection } from 'application/selection'
import {
  Command,
  CommandHandler,
  SelectNode,
  SetSelectedCanvas,
  UnselectNode,
} from './types'

export class SelectCommandHandler implements CommandHandler {
  private selection: DocumentSelection

  constructor(selection: DocumentSelection) {
    this.selection = selection
  }

  handle = (command: Command): void => {
    switch (command.type) {
      case 'selectNode':
        this.select(command)
        return
      case 'unselectNode':
        this.unselect(command)
        return
      case 'setSelectedCanvas':
        this.setSelectedCanvas(command)
        return
    }
  }

  private select = (command: SelectNode): void => {
    this.selection.select(command.params.ids, command.params.clear)
  }

  private unselect = (command: UnselectNode): void => {
    this.selection.unselect(command.params.id)
  }

  private setSelectedCanvas = (command: SetSelectedCanvas): void => {
    this.selection.selectCanvas(command.params.id)
  }
}
