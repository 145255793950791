import { UnsplashImage } from 'application/service'
import Text from 'components/Library/Text/Text'
import { DesignColor } from 'themes'

interface UnsplashSectionProps {
  column1: UnsplashImage[]
  column2: UnsplashImage[]

  handleSelectImage: (image: UnsplashImage) => void

  sectionRef?: React.RefObject<HTMLDivElement>
  onScroll?: () => void
}

export default function UnsplashSection({
  column1,
  column2,
  handleSelectImage,
  sectionRef,
  onScroll,
}: UnsplashSectionProps) {
  return (
    <Section sectionRef={sectionRef} onScroll={onScroll}>
      {[column1, column2].map((column, index) => (
        <Column key={index}>
          {column.map((image) => (
            <Card key={image.id} onClick={() => handleSelectImage(image)}>
              <ImageContainer>
                <img
                  src={image.urls.small}
                  alt="unsplash"
                  style={{
                    width: 176,
                    height: 176 * (image.height / image.width),
                    objectFit: 'cover',
                  }}
                  onDragStart={(e) => e.preventDefault()}
                />
              </ImageContainer>
              <ImageDetails>
                <Text maxWidth={100} color={DesignColor('text1')}>
                  {image.user.name}
                </Text>
                <Text maxWidth={70} color={DesignColor('text2')}>
                  {`${image.width} x ${image.height}`}
                </Text>
              </ImageDetails>
            </Card>
          ))}
        </Column>
      ))}
    </Section>
  )
}

function Section({
  sectionRef,
  onScroll,
  children,
}: {
  sectionRef?: React.RefObject<HTMLDivElement>
  onScroll?: () => void
  children?: React.ReactNode
}) {
  return (
    <div
      ref={sectionRef}
      onScroll={onScroll}
      style={{
        width: 400,
        height: 600,
        padding: 8,
        boxSizing: 'border-box',
        display: 'flex',
        overflowY: 'scroll',
      }}
    >
      {children}
    </div>
  )
}

function Column({ children }: { children?: React.ReactNode }) {
  return (
    <div
      style={{
        width: 192,
        padding: 8,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'start',
        gap: 8,
      }}
    >
      {children}
    </div>
  )
}

function Card({
  onClick,
  onMouseDown,
  onMouseUp,
  children,
}: {
  onClick?: () => void
  onMouseDown?: () => void
  onMouseUp?: () => void
  children?: React.ReactNode
}) {
  return (
    <div
      style={{
        width: 176,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'start',
        gap: 4,
        cursor: 'pointer',
      }}
      onClick={onClick}
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
    >
      {children}
    </div>
  )
}

function ImageContainer({ children }: { children?: React.ReactNode }) {
  return (
    <div
      style={{
        width: 176,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {children}
    </div>
  )
}

function ImageDetails({ children }: { children?: React.ReactNode }) {
  return (
    <div
      style={{
        width: 176,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      {children}
    </div>
  )
}
