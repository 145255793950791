import { DesignColor } from 'themes'
import Icon from '../Components/Icon/Icon/Icon'
import styled from 'styled-components'

export default function OnboardingLogo() {
  return (
    <ResponsiveLink
      href="https://repaint.com"
      style={{
        position: 'fixed',
        width: 'fit-content',
        top: '24px',
        zIndex: 1,
        padding: `16px 16px`,
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: '6px',
        cursor: 'pointer',
      }}
    >
      <Icon icon="RepaintLogo" size={24} color={DesignColor('text1')} />
      <p
        style={{
          fontSize: 20,
          fontFamily: 'Poppins',
          color: DesignColor('text1'),
        }}
      >
        Repaint
      </p>
    </ResponsiveLink>
  )
}

const ResponsiveLink = styled.a`
  left: 24px;

  @media (max-width: 450px) {
    left: 50%;
    transform: translateX(-50%);
  }
`
