import { IconKey } from 'assets/iconLibrary'
import ButtonIcon from '../Icon/ButtonIcon/ButtonIcon'
import { DesignColor } from 'themes'
import Text, { textStyles } from 'components/Library/Text/Text'
import { dropdownWidth } from 'components/Library/Containers/constants'
import { FontKey, FontWeight } from 'application/text'

interface DropdownDrawerRowProps {
  width?: dropdownWidth

  icon?: IconKey
  title: string
  subtitle?: string
  style?: textStyles

  selected?: boolean

  hovered: boolean
  setHovered: (hovered: boolean) => void

  disabled?: boolean

  onClick?: () => void
}

export default function DropdownDrawerRow({
  width = 96,
  icon,
  title,
  subtitle,
  style = {},
  selected = false,
  hovered,
  setHovered,
  disabled = false,
  onClick,
}: DropdownDrawerRowProps) {
  const opacity = getOpacity(disabled)
  const background = getBackground(hovered)
  const iconColor = getIconColor(selected, disabled)
  const textColor = getTextColor(selected, disabled)
  const textFamily = getTextFamily(style)
  const textWeight = getTextWeight(style, selected, disabled)
  const subtitleTextColor = getSubtitleTextColor(selected, disabled)

  return (
    <div
      style={{
        width,
        height: 24,
        padding: `0px ${icon ? 4 : 8}px`,
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        gap: 4,
        borderRadius: 4,
        opacity,
        background,
      }}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={onClick}
    >
      {icon && <ButtonIcon icon={icon} color={iconColor} />}
      <Text
        color={textColor}
        styles={{ weight: textWeight, family: textFamily }}
      >
        {title}
      </Text>
      <Text color={subtitleTextColor} styles={{ weight: textWeight }}>
        {subtitle}
      </Text>
    </div>
  )
}

function getBackground(hovered: boolean) {
  return hovered ? DesignColor('inputTint') : 'none'
}

function getOpacity(disabled: boolean) {
  return disabled ? 0.4 : 1
}

function getTextColor(selected: boolean, disabled: boolean) {
  if (disabled) return DesignColor('text1')
  return DesignColor(selected ? 'inputHighlight' : 'text1')
}

function getIconColor(selected: boolean, disabled: boolean) {
  if (disabled) return DesignColor('text2')
  return DesignColor(selected ? 'inputHighlight' : 'text2')
}

function getTextWeight(
  style: textStyles,
  selected: boolean,
  disabled: boolean
): FontWeight {
  if (style.weight) return style.weight
  if (disabled) return 'regular'
  return selected ? 'bold' : 'regular'
}

function getTextFamily(style: textStyles): FontKey {
  return style.family || 'inter'
}

function getSubtitleTextColor(selected: boolean, disabled: boolean) {
  if (disabled) return DesignColor('text2')
  return DesignColor(selected ? 'inputHighlight' : 'text2')
}
