import {
  Context,
  UniformValue,
  VaoData,
  VertexBuffer,
  calcBoundingBox,
  calcRectColors,
  calcRectPosition,
  calcRounding,
  isPointInBox,
} from 'application/render'
import { AttributeFill } from 'application/attributes'
import { Rectangle } from 'application/shapes'
import {
  cleanupVaoData,
  clearBuffers,
  connectBuffersToVao,
  createEmptyVaoData,
  standardDraw,
} from '../utils'
import { MaterialType } from './shaderMap'

export type WebglEllipseData = Rectangle & {
  fill: AttributeFill
  bb?: Rectangle
  bbRounding?: { tl: number; tr: number; br: number; bl: number }
}

export class WebglEllipse {
  private context: Context
  private data: WebglEllipseData
  private vaoData: VaoData

  constructor(context: Context, data: WebglEllipseData) {
    this.context = context
    this.data = data
    this.vaoData = createEmptyVaoData()
  }

  init = (): void => {
    this.vaoData = this.updateRenderData()
  }

  draw(): void {
    standardDraw(this.vaoData)
  }

  updateRenderData(): VaoData {
    clearBuffers(this.vaoData)
    const gl = this.context.getGl()
    const material = this.context.getMaterial(MaterialType.ellipse)
    const vao = gl.createVertexArray()
    const buffers: { [key: string]: VertexBuffer } = {}
    const uniforms: { [key: string]: UniformValue } = {}

    const { x, y, w, h, fill, bb, bbRounding } = this.data

    const aPosition = new Float32Array(calcRectPosition(this.data, fill))
    const aColor = new Float32Array(calcRectColors(fill))
    const uRect = [x, y, w, h]
    const uBB = calcBoundingBox(bb)
    const uBBRounding = calcRounding(uBB[2], uBB[3], bbRounding)

    buffers['aPosition'] = this.context.createVertexBuffer(aPosition, 2)
    buffers['aColor'] = this.context.createVertexBuffer(aColor, 4)
    uniforms['uRect'] = uRect
    uniforms['uBB'] = uBB
    uniforms['uBBRounding'] = uBBRounding
    const verticeCount = aPosition.length / 2

    const vaoData = { vao, material, buffers, uniforms, verticeCount }
    connectBuffersToVao(vaoData)
    return vaoData
  }

  cleanup = (): void => {
    cleanupVaoData(this.vaoData)
  }
}

export const ellipseVs = `#version 300 es
precision highp float;

uniform mat3 uMatrix;

in vec2 aPosition;
in vec4 aColor;

out vec2 vPosition;
out vec4 vColor;

void main() {
  vec3 transformedPosition = uMatrix * vec3(aPosition, 1);
  gl_Position = vec4(transformedPosition.xy, 0, 1);

  vPosition = aPosition;
  vColor = aColor;
}
`

export const ellipseFs = `#version 300 es
precision mediump float;

uniform vec4 uRect;
uniform vec4 uBB;
uniform vec4 uBBRounding;

in vec2 vPosition;
in vec4 vColor;

out vec4 outColor;

${isPointInBox}

void main() {
  if (!isPointInBox(vPosition, uBB, uBBRounding)) {
    discard;
  }

  float centerX = uRect.x + uRect.z * 0.5;
  float centerY = uRect.y + uRect.w * 0.5;
  float radiusX = uRect.z * 0.5;
  float radiusY = uRect.w * 0.5;

  float normalizedX = (vPosition.x - centerX) / radiusX;
  float normalizedY = (vPosition.y - centerY) / radiusY;

  float distance = normalizedX * normalizedX + normalizedY * normalizedY;
  float edgeWidth = fwidth(distance) * 0.5;
  float alpha = 1.0 - smoothstep(1.0 - edgeWidth, 1.0, distance);

  if (distance > (1.0 + edgeWidth)) {
    discard;
  }

  outColor = vec4(vColor.rgb, vColor.a * alpha);
}
`
