import { Node } from 'application/node'
import { ClientTransactionHandler } from './transaction'

export class DocumentTransactionHistoryHandler {
  private handler: ClientTransactionHandler

  constructor(handler: ClientTransactionHandler) {
    this.handler = handler
  }

  onNodeAdded = (node: Node): void => {
    this.handler.addEvent({
      type: 'node_create',
      data: {
        node: node.clone(),
      },
    })
  }

  onNodeDeleted = (node: Node): void => {
    this.handler.addEvent({
      type: 'node_delete',
      data: {
        node: node.clone(),
      },
    })
  }
}
