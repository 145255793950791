import useAuthService from 'hooks/services/useAuthService'
import { Base64 } from 'js-base64'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export default function useRedirectLoggedIn() {
  const authService = useAuthService()
  const navigate = useNavigate()

  useEffect(() => {
    const redirectIfTokenNotExpired = async () => {
      const token = authService.getToken()
      if (token && !isTokenExpired(token || '')) navigate('/projects')
    }

    redirectIfTokenNotExpired()
  }, [authService, navigate])
}

function isTokenExpired(token: string) {
  try {
    if (!token) return true

    const [, payload] = token.split('.')
    const decodedToken = JSON.parse(Base64.decode(payload))
    if (!decodedToken) return true
    if (typeof decodedToken === 'string') return true
    if (!decodedToken.exp) return true

    const dateNow = new Date()
    return decodedToken.exp < Math.floor(dateNow.getTime() / 1000)
  } catch (err) {
    return true
  }
}
