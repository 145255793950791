import { Context } from 'application/render'

export class Texture {
  private context: Context
  private texture: WebGLTexture

  constructor(
    context: Context,
    w: number,
    h: number,
    dpr: number,
    image?: HTMLImageElement
  ) {
    this.context = context
    this.texture = this.createTexture(w, h, dpr, image)
  }

  getTexture = (): WebGLTexture => {
    return this.texture
  }

  private createTexture = (
    w: number,
    h: number,
    dpr: number,
    image?: HTMLImageElement
  ): WebGLTexture => {
    const gl = this.context.getGl()
    const texture = gl.createTexture()

    gl.bindTexture(gl.TEXTURE_2D, texture)
    if (image) {
      gl.texImage2D(gl.TEXTURE_2D, 0, gl.RGBA, gl.RGBA, gl.UNSIGNED_BYTE, image)
    } else {
      gl.texImage2D(
        gl.TEXTURE_2D,
        0,
        gl.RGBA,
        w * dpr,
        h * dpr,
        0,
        gl.RGBA,
        gl.UNSIGNED_BYTE,
        null
      )
    }

    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MIN_FILTER, gl.LINEAR)
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_MAG_FILTER, gl.LINEAR)
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_S, gl.CLAMP_TO_EDGE)
    gl.texParameteri(gl.TEXTURE_2D, gl.TEXTURE_WRAP_T, gl.CLAMP_TO_EDGE)

    gl.bindTexture(gl.TEXTURE_2D, null)

    if (!texture) throw new Error('Could not create texture')

    return texture
  }

  resize = (w: number, h: number, dpr: number) => {
    const gl = this.context.getGl()

    gl.bindTexture(gl.TEXTURE_2D, this.texture)
    gl.texImage2D(
      gl.TEXTURE_2D,
      0,
      gl.RGBA,
      w * dpr,
      h * dpr,
      0,
      gl.RGBA,
      gl.UNSIGNED_BYTE,
      null
    )
    gl.bindTexture(gl.TEXTURE_2D, null)
  }

  cleanup = () => {
    const gl = this.context.getGl()
    gl.deleteTexture(this.texture)
  }
}
