import { AttributeType } from 'application/attributes'
import { ReadOnlyDocument } from 'application/document'

export interface NameGenerator {
  next(type: AttributeType, prefix: string, increment: boolean): string
}

type Name = { base: string; value: number }

export class NodeNameGenerator implements NameGenerator {
  private document: ReadOnlyDocument
  private isDev: boolean

  constructor(document: ReadOnlyDocument, isDev: boolean = false) {
    this.document = document
    this.isDev = isDev
  }

  next(type: AttributeType, name: string, increment: boolean = true): string {
    if (skipTypes.includes(type) || this.isDev || !increment) return name
    const extracted = this.extractName(name)
    const max = this.findMax(extracted)
    return `${extracted.base} ${max + 1}`
  }

  private findMax(name: Name): number {
    return this.document.getNodes().reduce((max, node) => {
      if (!node.getBaseAttribute('name').startsWith(name.base)) return max
      const extracted = this.extractName(node.getBaseAttribute('name'))
      if (extracted.base === name.base) return Math.max(max, extracted.value)
      return max
    }, 0)
  }

  private extractName(name: string): Name {
    const regex = /(.*) (\d+)$/
    const match = name.match(regex)
    if (match) {
      return {
        base: match[1],
        value: parseInt(match[2]),
      }
    }
    return {
      base: name,
      value: 1,
    }
  }
}

const skipTypes: AttributeType[] = ['paragraph', 'content']
