import { Properties } from 'application/export/css'
import { TailwindClassRenderer } from '../types'

export class TailwindClassObjectPosition implements TailwindClassRenderer {
  render = (properties: Partial<Properties>): string[] => {
    const classes: string[] = []

    const objectPosition = properties.objectPosition
    switch (objectPosition) {
      case 'top':
        classes.push('object-top')
        break
      case 'right':
        classes.push('object-contain')
        break
      case 'bottom':
        classes.push('object-bottom')
        break
      case 'left':
        classes.push('object-left')
        break
      case 'center':
        classes.push('object-center')
        break
    }

    return classes
  }
}
