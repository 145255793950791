import { useState } from 'react'
import ColorIcon from './ColorIcon'
import { DesignColor } from 'themes'
import HexInput from './HexInput'
import Text from 'components/Library/Text/Text'
import { Color, createNewColor, hexToRgba, rgbaToHex } from 'application/color'
import useEditInput from 'hooks/editor/useEditInput'
import useAction from 'hooks/editor/useAction'

interface ColorBlockProps {
  color: 'Mixed' | Color
  updateColor: (color: Color) => void

  open?: boolean
  setOpen?: (open: boolean) => void

  displayRef?: React.RefObject<HTMLDivElement>

  hasOverride?: boolean
}

export default function ColorBlock({
  color,
  updateColor,
  open = false,
  setOpen,
  displayRef,
  hasOverride = false,
}: ColorBlockProps) {
  const action = useAction()
  const [editing, setEditing] = useEditInput()
  const [hover, setHover] = useState(false)

  const hexValue: string =
    color === 'Mixed' ? rgbaToHex(createNewColor()) : rgbaToHex(color)

  const handleHexUpdate = (hex: string) => {
    if (color === 'Mixed') return
    if (hex.length === 8) {
      updateColor(hexToRgba(hex))
    } else {
      updateColor({ ...hexToRgba(hex), a: color.a })
    }
  }

  const handleClick = () => {
    if (color === 'Mixed') return
    setOpen && setOpen(!open)
  }

  const boxShadow = getBoxShadow(editing || (hover && !action))
  const background = getBackground(open)

  return (
    <div
      ref={displayRef}
      style={{
        width: 96,
        height: 24,
        padding: '0px 8px 0px 4px',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        gap: 4,
        borderRadius: 4,
        userSelect: 'none',
        boxShadow: boxShadow,
        background: background,
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={handleClick}
    >
      <ColorIcon color={hexValue.slice(0, 6)} />
      {color !== 'Mixed' && (
        <HexInput
          editing={editing}
          setEditing={setEditing}
          value={hexValue.slice(0, -2)}
          setValue={handleHexUpdate}
          hasOverride={hasOverride}
        />
      )}
      {color === 'Mixed' && <Text maxWidth={56}>Mixed</Text>}
    </div>
  )
}

function getBoxShadow(active: boolean) {
  if (active) return `inset 0px 0px 0px 1px ${DesignColor('inputHighlight')}`
  return 'none'
}

function getBackground(open: boolean) {
  return open ? DesignColor('inputTint') : 'transparent'
}
