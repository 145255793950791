import MemoryDisplay from './MemoryDisplay/MemoryDisplay'
import styled from 'styled-components'
import FPSDisplay from './FPSDisplay/FPSDisplay'

export default function DevTools() {
  return (
    <DevLabels>
      <FPSDisplay />
      <MemoryDisplay />
    </DevLabels>
  )
}

const DevLabels = styled.div`
  position: fixed;
  bottom: 8px;
  left: 248px;
  z-index: 1;

  display: flex;
  flex-direction: row;
  pointer-events: none;
`
