import {
  AttributeFill,
  AttributeType,
  StyleMap,
  isFillEqual,
} from 'application/attributes'
import {
  ClientUpdateListener,
  Command,
  Update,
  UpdateNodeUpdated,
} from 'application/client'
import { ReadOnlyDocument } from 'application/document'
import { ReadOnlyDocumentSelection } from 'application/selection'
import { FillsText } from './fillsText'
import { ReadOnlyNode } from 'application/node'
import { FillsDefault } from './fillsDefault'
import {
  Action,
  ActionHandler,
  ActiveActionListener,
} from 'editor/action/types'
import _ from 'lodash'

export interface NodeSelectionColorsHandler {
  get(node: ReadOnlyNode): AttributeFill[]
  updateFill(
    node: ReadOnlyNode,
    before: AttributeFill,
    after: AttributeFill
  ): Partial<StyleMap>
}

export type SelectionColor = {
  ids: string[]
  fill: AttributeFill
}

export type SelectionColorsPanelState = {
  active: boolean
  selection: SelectionColor[]
}

export type SelectionColorsPanelHandlers = {
  updateFill: (
    before: AttributeFill,
    after: AttributeFill,
    ids: string[]
  ) => void
  startUpdate: () => void
  endUpdate: () => void
}

type SelectionColorsPanelListener = (state: SelectionColorsPanelState) => void

interface CommandHandler {
  handle(command: Command[]): void
}

export class SelectionColorsPanel
  implements ClientUpdateListener, ActiveActionListener
{
  private commandHandler: CommandHandler
  private document: ReadOnlyDocument
  private documentSelection: ReadOnlyDocumentSelection
  private handlers: Map<AttributeType, NodeSelectionColorsHandler>

  private listeners: { [key: string]: SelectionColorsPanelListener }

  private ids: string[]
  private selection: SelectionColor[]
  private updating: boolean
  private activeAction: Action | null

  constructor(
    commandHandler: CommandHandler,
    document: ReadOnlyDocument,
    documentSelection: ReadOnlyDocumentSelection
  ) {
    this.commandHandler = commandHandler
    this.document = document
    this.documentSelection = documentSelection
    this.handlers = new Map()
    this.handlers.set('paragraph', new FillsText())
    this.handlers.set('button', new FillsText())
    this.handlers.set('input', new FillsDefault())
    this.handlers.set('page', new FillsDefault())
    this.handlers.set('frame', new FillsDefault())
    this.handlers.set('form', new FillsDefault())
    this.handlers.set('anchor', new FillsDefault())

    this.listeners = {}

    this.ids = []
    this.selection = []
    this.updating = false
    this.activeAction = null
  }

  getSettings(): SelectionColorsPanelState {
    return {
      active: this.getActive(),
      selection: this.selection,
    }
  }

  getHandlers(): SelectionColorsPanelHandlers {
    return {
      updateFill: this.updateFill,
      startUpdate: this.startUpdate,
      endUpdate: this.endUpdate,
    }
  }

  subscribe = (id: string, listener: SelectionColorsPanelListener): void => {
    this.listeners[id] = listener
    listener(this.getSettings())
  }

  unsubscribe = (id: string): void => {
    delete this.listeners[id]
  }

  getTypes = (): Update['type'][] => {
    return ['node_updated', 'selection']
  }

  onUpdate = (updates: Update[]) => {
    let updated = false
    for (const update of updates) {
      switch (update.type) {
        case 'selection':
          updated = true
          break
        case 'node_updated':
          if (this.isDescendantUpdate(update)) {
            updated = true
          }
          break
      }
    }

    if (updated && this.canUpdate()) {
      this.updateIds()
      this.remcomputeSelection()
      this.notifyListeners()
    }
  }

  onActiveAction(handler: ActionHandler | null): void {
    this.activeAction = handler ? handler.getType() : null
    if (this.canUpdate()) {
      this.updateIds()
      this.remcomputeSelection()
      this.notifyListeners()
    }
  }

  private getActive = (): boolean => {
    return (
      this.ids.length > this.getSelectedNodes().length ||
      this.selection.length > 1
    )
  }

  private updateFill = (
    before: AttributeFill,
    after: AttributeFill,
    ids: string[]
  ): void => {
    const updateMap: { [key: string]: Partial<StyleMap> } = {}
    for (const id of ids) {
      const node = this.document.getNode(id)
      if (!node) continue

      const handler = this.handlers.get(node.getBaseAttribute('type'))
      if (!handler) continue

      const update = handler.updateFill(node, before, after)
      if (Object.keys(update).length === 0) continue

      updateMap[id] = update
    }

    this.commandHandler.handle(
      Object.keys(updateMap).map((id) => ({
        type: 'setNodeAttribute',
        params: { id, base: {}, style: updateMap[id] },
      }))
    )

    this.updateSelection(before, after, ids)
  }

  private startUpdate = (): void => {
    this.updating = true
  }

  private endUpdate = (): void => {
    this.updating = false
    if (this.canUpdate()) {
      this.updateIds()
      this.remcomputeSelection()
      this.notifyListeners()
    }
  }

  private isDescendantUpdate = (update: UpdateNodeUpdated): boolean => {
    return this.ids.includes(update.data.id)
  }

  private updateIds = (): void => {
    const ids: string[] = []

    for (const node of this.getSelectedNodes()) {
      const descendants = this.document.getDescendants(node)
      for (const descendant of descendants) {
        ids.push(descendant.getId())
      }
      ids.push(node.getId())
    }

    this.ids = ids
  }

  private updateSelection(
    before: AttributeFill,
    after: AttributeFill,
    ids: string[]
  ): void {
    this.selection = this.selection.map((s) => {
      if (isFillEqual(s.fill, before) && _.isEqual(s.ids, ids)) {
        return { ids: s.ids, fill: after }
      }
      return s
    })
    this.notifyListeners()
  }

  private remcomputeSelection = (): void => {
    const selection: SelectionColor[] = []

    for (const id of this.ids) {
      const node = this.document.getNode(id)
      if (!node) continue

      const type = node.getBaseAttribute('type')

      const handler = this.handlers.get(type)
      if (!handler) continue

      const nodeFills = handler.get(node)
      for (const fill of nodeFills) {
        const matches = selection.find((s) => isFillEqual(s.fill, fill))
        if (matches) {
          matches.ids.push(id)
        } else {
          selection.push({ ids: [id], fill })
        }
      }
    }

    this.selection = selection
  }

  private notifyListeners = (): void => {
    const settings = this.getSettings()
    for (const key in this.listeners) {
      this.listeners[key](settings)
    }
  }

  private canUpdate = (): boolean => {
    if (this.activeAction === 'editInput') return false
    if (this.updating) return false
    return true
  }

  private getSelectedNodes = (): ReadOnlyNode[] => {
    const nodes: ReadOnlyNode[] = []
    const selected = this.documentSelection.getSelected()
    for (const node of selected) {
      switch (node.getBaseAttribute('type')) {
        case 'content':
          const parent = this.document.getParent(node)
          if (parent) nodes.push(parent)
          break
        default:
          nodes.push(node)
          break
      }
    }
    return nodes
  }
}
