import { AttributeType, BaseMap, StyleMap } from 'application/attributes'
import { NodeCreationHandler } from './creation/creation'
import { InsertionStrategyFactory } from './creation/strategy/strategyFactory'
import { Command, SetNodeAttribute, SetParentNode } from 'application/client'
import { ReadOnlyDocument } from 'application/document'
import { NodeDeleteAction } from './delete'
import { Rectangle } from 'application/shapes'
import { ReadOnlyNode } from 'application/node'
import { computeSelectionRectangle } from 'application/selection'
import {
  getAdjustedBottomToNode,
  getAdjustedHeightToNode,
  getAdjustedLeftToNode,
  getAdjustedRightToNode,
  getAdjustedTopToNode,
  getAdjustedWidthToNode,
} from 'application/units'
import { PositionMap } from './types'

interface CommandHandler {
  handle: (command: Command[]) => void
}

type NodeIndexMap = { [key: string]: number }

export class NodeWrapAction {
  private document: ReadOnlyDocument
  private commandHandler: CommandHandler
  private nodeCreationHandler: NodeCreationHandler
  private strategyFactory: InsertionStrategyFactory
  private nodeDeleteAction: NodeDeleteAction

  constructor(
    document: ReadOnlyDocument,
    commandHandler: CommandHandler,
    nodeCreationHandler: NodeCreationHandler,
    insertionStrategyFactory: InsertionStrategyFactory,
    nodeDeleteAction: NodeDeleteAction
  ) {
    this.document = document
    this.commandHandler = commandHandler
    this.nodeCreationHandler = nodeCreationHandler
    this.strategyFactory = insertionStrategyFactory
    this.nodeDeleteAction = nodeDeleteAction
  }

  wrap = (nodeIds: string[], type: AttributeType): string | null => {
    if (nodeIds.length === 0) return null

    const positions = this.getPositions(nodeIds)
    const rectangle = this.getRectangle(nodeIds)
    if (!rectangle) return null

    const indexes = this.getIndexes(nodeIds)
    const strategy = this.strategyFactory.createSharedParentStrategy(nodeIds)

    const reparentNoneCommands = this.buildReparentNoneCommands(nodeIds)
    this.commandHandler.handle(reparentNoneCommands)

    const allAbsolute = this.allChildrenAbsolute(nodeIds)
    const base: Partial<BaseMap> = {}
    const style: Partial<StyleMap> = {
      'position.mode': allAbsolute ? 'absolute' : 'relative',
    }
    if (rectangle) {
      base['h'] = rectangle.h
      base['w'] = rectangle.w
      style['size.w.unit'] = 'px'
      style['size.h.unit'] = 'px'
      style['size.w.px'] = rectangle.w
      style['size.h.px'] = rectangle.h
    }

    const setAbsoluteCommands = this.buildSetAbsoluteCommands(nodeIds)
    this.commandHandler.handle(setAbsoluteCommands)

    const wrapperId = this.nodeCreationHandler.createOnCanvas(
      type,
      base,
      style,
      strategy
    )
    if (!wrapperId) return null

    const reparentCommands = this.buildWrapNewParentCommands(
      nodeIds,
      wrapperId,
      indexes
    )
    const positionCommands = this.buildLayoutCommands(wrapperId, positions)
    this.commandHandler.handle([...reparentCommands, ...positionCommands])

    return wrapperId
  }

  unwrap = (nodeId: string): string[] => {
    const node = this.document.getNode(nodeId)
    if (!node) return []

    const children = node.getChildren()
    if (!children) return []

    const parent = this.document.getParent(node)
    if (!parent) return []

    const parentChildren = parent.getChildren()
    if (!parentChildren) return []

    const reparentCommands = this.buildUnwrapNewParentCommands(
      children,
      parent.getId(),
      parentChildren.findIndex((id) => id === nodeId)
    )
    const positions = this.getPositions(children)
    const positionCommands = this.buildLayoutCommands(parent.getId(), positions)
    this.commandHandler.handle([...reparentCommands, ...positionCommands])

    this.nodeDeleteAction.delete([nodeId])

    return children
  }

  private buildReparentNoneCommands = (nodeIds: string[]): SetParentNode[] => {
    return nodeIds.map((id) => {
      return {
        type: 'setParent',
        params: {
          id: id,
          parentId: null,
          index: 0,
        },
      }
    })
  }

  private buildSetAbsoluteCommands = (
    nodeIds: string[]
  ): SetNodeAttribute[] => {
    return nodeIds.map((id) => {
      return {
        type: 'setNodeAttribute',
        params: {
          id: id,
          base: {},
          style: {
            'position.mode': 'absolute',
            'position.top.unit': 'px',
            'position.left.unit': 'px',
            'position.top.px': 0,
            'position.left.px': 0,
          },
          selector: 'default',
        },
      }
    })
  }

  private buildWrapNewParentCommands = (
    nodeIds: string[],
    newParent: string,
    indexes: NodeIndexMap
  ): SetParentNode[] => {
    const commands: SetParentNode[] = nodeIds.map((id) => {
      return {
        type: 'setParent',
        params: {
          id: id,
          parentId: newParent,
          index: indexes[id],
        },
      }
    })
    commands.sort((a, b) => (a.params.index || 0) - (b.params.index || 0))
    return commands
  }

  private buildUnwrapNewParentCommands = (
    nodeIds: string[],
    newParent: string,
    index: number | undefined = undefined
  ): SetParentNode[] => {
    return nodeIds.map((id, i) => {
      return {
        type: 'setParent',
        params: {
          id: id,
          parentId: newParent,
          index: index === undefined ? 0 : index + i,
        },
      }
    })
  }

  private allChildrenAbsolute = (ids: string[]): boolean => {
    return ids.every((n) => {
      const node = this.document.getNode(n)
      if (!node) return false
      return node.getStyleAttribute('position.mode') === 'absolute'
    })
  }

  private getPositions = (ids: string[]): PositionMap => {
    const positions: PositionMap = {}
    ids.forEach((id) => {
      const node = this.document.getNode(id)
      if (!node) return
      positions[id] = {
        x: node.getBaseAttribute('x'),
        y: node.getBaseAttribute('y'),
      }
    })
    return positions
  }

  private buildLayoutCommands = (
    parentId: string,
    positions: PositionMap
  ): SetNodeAttribute[] => {
    const commands: SetNodeAttribute[] = []
    const wrapper = this.document.getNode(parentId)
    if (!wrapper) return commands

    for (const id in positions) {
      const node = this.document.getNode(id)
      if (!node) continue

      const x = positions[id].x
      const y = positions[id].y
      const w = node.getBaseAttribute('w')
      const h = node.getBaseAttribute('h')

      const adjustedTop = getAdjustedTopToNode(node, wrapper, y)
      const adjustedLeft = getAdjustedLeftToNode(node, wrapper, x)
      const adjustedBottom = getAdjustedBottomToNode(node, wrapper, y + h)
      const adjustedRight = getAdjustedRightToNode(node, wrapper, x + w)
      const adjustedWidth = getAdjustedWidthToNode(node, wrapper, w)
      const adjustedHeight = getAdjustedHeightToNode(node, wrapper, h)
      const wUnit = node.getStyleAttribute('size.w.unit')
      const hUnit = node.getStyleAttribute('size.h.unit')

      const base: Partial<BaseMap> = { x, y, w, h }
      const style: Partial<StyleMap> = {
        ...adjustedTop,
        ...adjustedLeft,
        ...adjustedBottom,
        ...adjustedRight,
        ...adjustedWidth,
        ...adjustedHeight,
      }
      if (!wUnit) {
        style['size.w.unit'] = 'px'
        style['size.w.px'] = w
      }
      if (!hUnit) {
        style['size.h.unit'] = 'px'
        style['size.h.px'] = h
      }

      commands.push({
        type: 'setNodeAttribute',
        params: { id: id, base: base, style: style, selector: 'default' },
      })
    }

    return commands
  }

  private getIndexes = (ids: string[]): NodeIndexMap => {
    const indexes: NodeIndexMap = {}
    ids.forEach((id) => {
      const node = this.document.getNode(id)
      if (!node) return
      const parent = this.document.getParent(node)
      if (!parent) return
      const parentChildren = parent.getChildren()
      if (!parentChildren) return
      const index = parentChildren.findIndex((i) => i === id)
      indexes[id] = index === -1 ? 0 : index
    })
    return indexes
  }

  private getRectangle = (ids: string[]): Rectangle | null => {
    const nodes = ids
      .map((id) => this.document.getNode(id))
      .filter((n) => n) as ReadOnlyNode[]
    return computeSelectionRectangle(nodes)
  }
}
