import useAction from 'hooks/editor/useAction'
import useActionInitiator from 'hooks/editor/useActionInitiator'
import { useEffect } from 'react'
import { Portal } from '../../PortalRoot/PortalRoot'
import PanelContainer from 'components/Library/Containers/PanelContainer/PanelContainer'
import PanelColumn from 'components/Library/Containers/PanelColumn/PanelColumn'
import TemplatesTitle from 'components/Library/Components/Templates/TemplatesTitle'
import TemplateTab from 'components/Library/Components/Templates/TemplatesTab'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'
import { IconKey } from 'assets/iconLibrary'
import TemplatesDisplay from 'components/Library/Components/Templates/TemplatesDisplay'
import { Action } from 'editor'

const allowedActions: (Action | null)[] = [
  'openTemplateLibrary',
  'dragTemplate',
]

export default function TemplateLibrary() {
  const { closeLibrary } = useActionInitiator()
  const action = useAction()

  const { settings, handlers } = usePanelHelper(
    editor.getUI().getTemplatesPanel()
  )

  const { setScroll, setTab, drag, load } = handlers

  useEffect(() => {
    load()
  }, [load])

  if (!settings || !allowedActions.includes(action)) return <></>

  const { tab, scroll, templates } = settings

  return (
    <Portal>
      <PanelContainer
        id={'template-library'}
        position={{
          position: 'absolute',
          top: 64,
          left: 8,
        }}
        width={400}
        height={'calc(100% - 72px)'}
        direction={'column'}
        overflow={'hidden'}
      >
        <TemplatesTitle close={closeLibrary} />
        <div
          style={{
            display: 'flex',
            minHeight: 0,
            height: '100%',
          }}
        >
          <PanelColumn width={168} paddingV={8}>
            {tabs.map((t) => (
              <TemplateTab
                key={t.value}
                icon={t.icon}
                display={t.label}
                select={() => setTab(t.value)}
                selected={t.value === tab}
              />
            ))}
          </PanelColumn>
          <PanelColumn width={232} right={true} paddingV={8}>
            <TemplatesDisplay
              templates={templates}
              scroll={scroll}
              setScroll={setScroll}
              drag={drag}
            />
          </PanelColumn>
        </div>
      </PanelContainer>
    </Portal>
  )
}

const tabs: {
  value: string
  label: string
  icon: IconKey
}[] = [
  {
    value: 'examples',
    label: 'Examples',
    icon: 'Section',
  },
  {
    value: 'components',
    label: 'Components',
    icon: 'Projects',
  },
]
