import { ClientUpdateListener, Update } from 'application/client'
import { ActionInitiator } from 'editor/action/initiator'

export class TextEditorActionInitiator implements ClientUpdateListener {
  private actionInitiator: ActionInitiator

  constructor(actionInitiator: ActionInitiator) {
    this.actionInitiator = actionInitiator
  }

  getTypes = (): Update['type'][] => {
    return ['edit_text']
  }

  onUpdate = (updates: Update[]): void => {
    for (const update of updates) {
      switch (update.type) {
        case 'edit_text':
          this.onEditText(update.data.id)
          break
      }
    }
  }

  private onEditText = (id: string | null): void => {
    if (id === null) {
      this.actionInitiator.cancel()
    } else {
      this.actionInitiator.editText()
    }
  }
}
