import { Color, rgbaToWebgl } from 'application/color'
import { Point } from 'application/shapes'
import { calcLinePositions } from '../data'
import {
  cleanupVaoData,
  clearBuffers,
  connectBuffersToVao,
  createEmptyVaoData,
  standardDraw,
} from '../utils'
import { VaoData } from '../types'
import { Context } from '../gpu/context'
import { VertexBuffer } from '../gpu/buffer'
import { UniformValue } from '../gpu/uniform'
import { MaterialType } from './shaderMap'

export type WebglLineData = {
  lines: { start: Point; end: Point }[]
  thickness: number
  color: Color
  dashed?: boolean
  dashLength?: number
}

export class WebglLine {
  private context: Context
  private data: WebglLineData
  private vaoData: VaoData

  constructor(context: Context, data: WebglLineData) {
    this.context = context
    this.data = data
    this.vaoData = createEmptyVaoData()
  }

  init = (): void => {
    this.vaoData = this.updateRenderData()
  }

  draw(): void {
    standardDraw(this.vaoData)
  }

  updateRenderData(): VaoData {
    clearBuffers(this.vaoData)
    const gl = this.context.getGl()
    const material = this.context.getMaterial(MaterialType.line)
    const vao = gl.createVertexArray()
    const buffers: { [key: string]: VertexBuffer } = {}
    const uniforms: { [key: string]: UniformValue } = {}

    const { lines, thickness, color, dashed, dashLength } = this.data

    const aPosition = new Float32Array(
      calcLinePositions(lines, thickness, dashed, dashLength)
    )
    const uColor = rgbaToWebgl(color)

    buffers['aPosition'] = this.context.createVertexBuffer(aPosition, 2)
    uniforms['uColor'] = uColor
    const verticeCount = aPosition.length / 2

    const vaoData = { material, vao, buffers, uniforms, verticeCount }
    connectBuffersToVao(vaoData)
    return vaoData
  }

  cleanup = (): void => {
    cleanupVaoData(this.vaoData)
  }
}

export const lineVs = `#version 300 es
precision highp float;

uniform mat3 uMatrix;

in vec2 aPosition;

void main() {
  vec3 transformedPosition = uMatrix * vec3(aPosition, 1);
  gl_Position = vec4(transformedPosition.xy, 0, 1);
}
`

export const lineFs = `#version 300 es
precision mediump float;

uniform vec4 uColor;

out vec4 outColor;

void main() {
  outColor = uColor;
}
`
