import { Camera } from 'application/camera'
import { ReadOnlyNode } from 'application/node'
import { Rectangle } from 'application/shapes'

export class ExportCalculator {
  getExportWindow = (
    nodes: ReadOnlyNode[],
    maxWidth: number,
    maxHeight: number
  ): { bounds: Rectangle; camera: Camera } => {
    let minX = Infinity
    let minY = Infinity
    let maxX = -Infinity
    let maxY = -Infinity

    for (const node of nodes) {
      const nodeX = node.getBaseAttribute('x')
      const nodeY = node.getBaseAttribute('y')
      const nodeW = node.getBaseAttribute('w')
      const nodeH = node.getBaseAttribute('h')

      minX = Math.min(minX, nodeX)
      minY = Math.min(minY, nodeY)
      maxX = Math.max(maxX, nodeX + nodeW)
      maxY = Math.max(maxY, nodeY + nodeH)
    }

    let boundsWidth = maxX - minX
    let boundsHeight = maxY - minY
    let scale = 1

    if (boundsWidth > maxWidth || boundsHeight > maxHeight) {
      const widthScale = maxWidth / boundsWidth
      const heightScale = maxHeight / boundsHeight
      scale = Math.min(widthScale, heightScale)

      boundsWidth *= scale
      boundsHeight *= scale
    }

    return {
      bounds: {
        x: minX,
        y: minY,
        w: boundsWidth,
        h: boundsHeight,
      },
      camera: {
        x: minX,
        y: -minY,
        z: scale,
      },
    }
  }
}
