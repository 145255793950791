export default function OnboardingDoubleInputRow({
  children,
}: {
  children: React.ReactNode
}) {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 16,
      }}
    >
      {children}
    </div>
  )
}
