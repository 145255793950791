import { LayoutItem } from '../layoutItem/layoutItem'
import { ObjectPool } from '../pool/objectPool'
import { FlexSizeItemColumn } from './flexSizeItemColumn'

export class FlexSizeItemColumnPool extends ObjectPool<FlexSizeItemColumn> {
  constructor(size: number) {
    super(FlexSizeItemColumn, size)
  }

  fromItem = (layoutItem: LayoutItem): FlexSizeItemColumn => {
    const item = this.get()
    item.layoutItem = layoutItem
    return item
  }
}
