import { Properties, Style, StyleSelector } from './types'

export class StyleImpl implements Style {
  private selector: StyleSelector
  private properties: Partial<Properties>

  constructor(selector: StyleSelector, properties: Partial<Properties>) {
    this.selector = selector
    this.properties = properties
  }

  getSelector = (): StyleSelector => {
    return this.selector
  }

  getProperties = (): Partial<Properties> => {
    return this.properties
  }

  getProperty = <K extends keyof Properties>(
    property: K
  ): Properties[K] | null => {
    return this.properties[property] ?? null
  }

  setProperty = <K extends keyof Properties>(
    property: K,
    value: Properties[K]
  ): void => {
    this.properties[property] = value
  }

  isEmpty = (): boolean => {
    return Object.keys(this.properties).length === 0
  }
}
