import { panelWidth } from './constants'

export function getPanelWidth(width: panelWidth) {
  switch (width) {
    case 'fill':
      return '100%'
    case 'hug':
      return 'fit-content'
    default:
      return width
  }
}
