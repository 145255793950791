import { BaseMap, SelectorName, StyleMap } from 'application/attributes'
import { Command, SetNodeAttribute } from 'application/client'

interface CommandHandler {
  handle: (command: Command[]) => void
}

export class NodeAttributesAction {
  private commandHandler: CommandHandler

  constructor(commandHandler: CommandHandler) {
    this.commandHandler = commandHandler
  }

  setAttributes = (
    ids: string[],
    base: Partial<BaseMap>,
    style: Partial<StyleMap>,
    selector: SelectorName | undefined = undefined,
    commit: boolean = false
  ): void => {
    const commands = this.buildSetAttributesCommands(ids, base, style, selector)
    this.commandHandler.handle(commands)
    if (commit) this.commandHandler.handle([{ type: 'commit' }])
  }

  setStyleAttributes = (
    ids: string[],
    attributes: Partial<StyleMap>,
    selector: SelectorName | undefined = undefined,
    commit: boolean = false
  ): void => {
    const commands = this.buildSetAttributesCommands(
      ids,
      {},
      attributes,
      selector
    )
    this.commandHandler.handle(commands)
    if (commit) this.commandHandler.handle([{ type: 'commit' }])
  }

  setBaseAttributes = (
    ids: string[],
    attributes: Partial<BaseMap>,
    commit: boolean = false
  ): void => {
    const commands = this.buildSetAttributesCommands(ids, attributes, {})
    this.commandHandler.handle(commands)
    if (commit) this.commandHandler.handle([{ type: 'commit' }])
  }

  resetAttributes = (
    ids: string[],
    keys: (keyof StyleMap)[],
    commit: boolean = false
  ): void => {
    const commands = this.buildResetAttributesCommands(ids, keys)
    this.commandHandler.handle(commands)
    if (commit) this.commandHandler.handle([{ type: 'commit' }])
  }

  private buildSetAttributesCommands = (
    ids: string[],
    base: Partial<BaseMap>,
    style: Partial<StyleMap>,
    selector?: SelectorName
  ): SetNodeAttribute[] => {
    return ids.map((id) => ({
      type: 'setNodeAttribute',
      params: {
        id: id,
        base: base,
        style: style,
        selector: selector,
      },
    }))
  }

  private buildResetAttributesCommands = (
    ids: string[],
    keys: (keyof StyleMap)[]
  ): Command[] => {
    return ids.map((id) => ({
      type: 'resetNodeAttribute',
      params: { id: id, keys: keys },
    }))
  }
}
