import { Portal } from 'components/Pages/Editor/PortalRoot/PortalRoot'
import Dropdown, {
  dropdownOption,
} from 'components/Library/Components/Dropdown/Dropdown'
import IconBlock from 'components/Library/Components/IconBlock/IconBlock/IconBlock'
import NumberBlock from 'components/Library/Components/NumberBlock/NumberBlock'
import TextBlock from 'components/Library/Components/Text/TextBlock/TextBlock'
import PanelContainer from 'components/Library/Containers/PanelContainer/PanelContainer'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import { panelPosition } from 'components/Library/Containers/constants'
import useClosePopup from 'hooks/ui/useClosePopup'
import { useEffect, useRef, useState } from 'react'
import useCommit from 'hooks/editor/useCommit'
import { AttributeShadow } from 'application/attributes'

interface ShadowSettingsPopupProps {
  close: () => void

  shadow: AttributeShadow | null
  updateShadow: (shadow: AttributeShadow) => void

  buttonRef: React.RefObject<HTMLDivElement>
}

export default function ShadowSettingsPopup({
  close,
  shadow,
  updateShadow,
  buttonRef,
}: ShadowSettingsPopupProps) {
  const commitUpdate = useCommit()

  const popupRef = useRef<HTMLDivElement>(null)
  useClosePopup({
    ref: popupRef,
    close: close,
    exceptionRef: buttonRef,
  })

  const [position, setPosition] = useState<panelPosition | null>(null)

  useEffect(() => {
    setPosition(getPosition(buttonRef))
  }, [buttonRef])

  if (buttonRef.current === null || !shadow || !position) return <></>

  return (
    <Portal>
      <PanelContainer popup={true} panelRef={popupRef} position={position}>
        <PanelSection>
          <PanelRow>
            <TextBlock width={192} mode="title">
              Shadow Settings
            </TextBlock>
            <IconBlock icon="X" onClick={close} />
          </PanelRow>
          <PanelRow>
            <Dropdown
              width={96}
              options={shadowOptions}
              selected={shadow.mode}
              select={(mode) => {
                updateShadow({ ...shadow, mode })
                commitUpdate()
              }}
            />
          </PanelRow>
          <PanelRow>
            <NumberBlock
              icon="PositionX"
              tooltipKey="ShadowX"
              value={shadow.x}
              setValue={(x) => updateShadow({ ...shadow, x })}
              increment={(v) => updateShadow({ ...shadow, x: shadow.x + v })}
              decrement={(v) => updateShadow({ ...shadow, x: shadow.x - v })}
              min={-200}
              max={200}
            />
            <NumberBlock
              icon="PositionY"
              tooltipKey="ShadowY"
              value={shadow.y}
              setValue={(y) => updateShadow({ ...shadow, y })}
              increment={(v) => updateShadow({ ...shadow, y: shadow.y + v })}
              decrement={(v) => updateShadow({ ...shadow, y: shadow.y - v })}
              min={-200}
              max={200}
            />
          </PanelRow>
          <PanelRow>
            <NumberBlock
              icon="Blur"
              tooltipKey="ShadowBlur"
              value={shadow.blur}
              setValue={(blur) => updateShadow({ ...shadow, blur })}
              increment={(v) => {
                updateShadow({
                  ...shadow,
                  blur: getBlurMinMax(shadow.blur + v),
                })
              }}
              decrement={(v) => {
                updateShadow({
                  ...shadow,
                  blur: getBlurMinMax(shadow.blur - v),
                })
              }}
              min={0}
            />
            <NumberBlock
              icon="Spread"
              tooltipKey="ShadowSpread"
              value={shadow.spread}
              setValue={(spread) => updateShadow({ ...shadow, spread })}
              increment={(v) => {
                updateShadow({
                  ...shadow,
                  spread: getSpreadMinMax(shadow.spread + v),
                })
              }}
              decrement={(v) => {
                updateShadow({
                  ...shadow,
                  spread: getSpreadMinMax(shadow.spread - v),
                })
              }}
              min={0}
            />
          </PanelRow>
        </PanelSection>
      </PanelContainer>
    </Portal>
  )
}

function getPosition(panelRef: React.RefObject<HTMLElement>): panelPosition {
  if (!panelRef.current)
    return {
      position: 'absolute',
      top: 0,
      right: 0,
    }
  const rect = panelRef.current.getBoundingClientRect()
  if (rect.top + 144 + 64 > window.innerHeight) {
    return {
      position: 'absolute',
      bottom: 64,
      right: 248,
    }
  } else {
    return {
      position: 'absolute',
      top: rect.top - 8,
      right: 248,
    }
  }
}

function getBlurMinMax(value: number): number {
  return Math.max(Math.min(value, 100), 0)
}

function getSpreadMinMax(value: number): number {
  return Math.max(Math.min(value, 200), 0)
}

const shadowOptions: dropdownOption<AttributeShadow['mode']>[] = [
  {
    text: 'Inner',
    value: 'inner',
  },
  {
    text: 'Outer',
    value: 'outer',
  },
]
