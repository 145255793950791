import { WriteDocument } from 'application/document'
import { ContentLayoutEngine } from './content'
import { Shaper } from 'application/text'

export class ContentLayoutEngineFactory {
  create = (
    document: WriteDocument,
    textShaper: Shaper
  ): ContentLayoutEngine => {
    return new ContentLayoutEngine(document, textShaper)
  }
}
