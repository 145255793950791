import { Element } from './types'

export class ContentElement implements Element {
  private content: string

  constructor(content: string) {
    this.content = content
  }

  getTag = (): null => {
    return null
  }

  addChild = (): boolean => {
    return false
  }

  removeChild = (): number => {
    return -1
  }

  getChildren = (): null => {
    return null
  }

  getAttribute = (): null => {
    return null
  }

  setAttribute = (): void => {}

  getInlineStyle = (): null => {
    return null
  }

  setInlineStyle = (): void => {}

  toString = (): string => {
    return this.content
  }
}
