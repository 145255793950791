import _ from 'lodash'
import { Cursor, Haptics } from './types'
import { Selection } from '../types'
import { Content } from '../types'
import { Shaper } from 'application/text'
import { State } from '../state/types'
import { Styles } from '../styles/styles'
import { AlignMode } from '../styles/types'
import { Rectangle } from 'application/shapes'
import { AttributeFill } from 'application/attributes'
import { Color } from 'application/color'

export interface EditorHaptics {
  get(state: State): Haptics
}

export class TextEditorHapticCalculator implements EditorHaptics {
  private shaper: Shaper
  private styles: Styles

  constructor(shaper: Shaper, style: Styles) {
    this.shaper = shaper
    this.styles = style
  }

  get = (state: State): Haptics => {
    return {
      cursor: this.getCursor(state),
      selection: this.getSelection(state.selection, state.content, state.width),
    }
  }

  private getCursor = (state: State): Cursor | null => {
    const { selection, content, width } = state
    const { anchor, focus } = selection
    if (anchor !== null && !_.isEqual(anchor, focus)) return null

    const shapedText = this.shaper.getShapedText(content, width)
    const maxWidth = width || Math.max(...shapedText.rows.map((r) => r.w))

    if (focus.index === 0) {
      let x = 0
      if (shapedText.rows[0] && shapedText.rows[0].characters[0]) {
        const firstChar = shapedText.rows[0].characters[0]
        x = firstChar.x
      } else {
        x = this.getDefaultCursorXPosition(content.styles.align, 0, maxWidth)
      }
      const rectangle = {
        color: this.getPreviousCharacterColor(state),
        rectangle: {
          y: 0,
          x: x,
          w: 1.5,
          h: shapedText.rows[0].h,
        },
      }
      this.shaper.releaseShapedText(shapedText)
      return rectangle
    }

    let y = 0
    let x = 0
    let height = 0
    for (let i = 0; i < shapedText.rows.length; i++) {
      const row = shapedText.rows[i]
      for (const c of row.characters) {
        if (c.index === focus.index) {
          y = c.y
          x = c.x + c.w
          height = row.h

          if (focus.wrapped) {
            const nextRow = shapedText.rows[i + 1]
            if (!nextRow) break

            const firstChar = nextRow.characters[0]
            if (firstChar) {
              x = firstChar.x
            } else {
              x = this.getDefaultCursorXPosition(
                content.styles.align,
                nextRow.w,
                maxWidth
              )
            }

            y = c.y + row.h
            height = nextRow.h
          }

          break
        }
      }
    }

    return {
      color: this.getPreviousCharacterColor(state),
      rectangle: {
        y: y,
        x: x,
        w: 1.5,
        h: height,
      },
    }
  }

  private getSelection = (
    selection: Selection,
    content: Content,
    width?: number
  ): Rectangle[] | null => {
    const { anchor, focus } = selection
    if (anchor === null || _.isEqual(anchor, focus)) return null

    const minIndex = Math.min(anchor.index, focus.index)
    const maxIndex = Math.max(anchor.index, focus.index)

    const shapedText = this.shaper.getShapedText(content, width)
    const maxWidth = width || Math.max(...shapedText.rows.map((r) => r.w))

    const rectangles: Rectangle[] = []
    for (const row of shapedText.rows) {
      if (row.characters.length === 0) continue

      let x1: number | null = null
      let x2: number | null = null

      for (const c of row.characters) {
        if (c.index <= minIndex || c.index > maxIndex) continue

        if (x1 === null) x1 = c.x
        if (x1 !== null) x2 = c.x + c.w
      }

      const lastRowIndex = row.characters[row.characters.length - 1].index
      const lastRow =
        lastRowIndex > maxIndex || (maxIndex === lastRowIndex && !focus.wrapped)
      if (!lastRow) x2 = maxWidth

      if (x1 !== null && x2 !== null) {
        rectangles.push({
          y: row.characters[0].y,
          x: x1,
          w: x2 - x1,
          h: row.h,
        })
      }
    }

    return rectangles
  }

  private getPreviousCharacterColor = (state: State): Color => {
    const style = this.styles.get(state.content, state.selection.focus)
    return this.fillToColor(state.nextCharacter?.fill || style.fill)
  }

  private fillToColor = (fill: AttributeFill): Color => {
    if (!fill.color) return { r: 0, g: 0, b: 0, a: 1 }
    return fill.color
  }

  private getDefaultCursorXPosition = (
    align: AlignMode,
    width: number,
    maxWidth: number
  ): number => {
    switch (align) {
      case 'left':
        return 0
      case 'center':
        return (maxWidth - width) / 2
      case 'right':
        return maxWidth - width
    }
  }
}
