import { DesignColor } from 'themes'

export default function OnboardingDivider() {
  return (
    <div
      style={{
        width: '100%',
        height: 1,
        backgroundColor: DesignColor('panelBorder'),
      }}
    />
  )
}
