import { WriteDocument } from 'application/document'
import { getOverrideContainer, getOverrideMode } from './utils'
import { Node, NodeUpdateListener } from 'application/node'

export class BreakpointUpdateHandler implements NodeUpdateListener {
  private document: WriteDocument

  constructor(document: WriteDocument) {
    this.document = document
  }

  onParent = (id: string): void => {
    const node = this.document.getNode(id)
    if (!node) return

    this.updateBreakpoint(id)
    this.updateDescendants(node)
  }

  updateBreakpoint = (id: string): void => {
    const node = this.document.getNode(id)
    if (!node) return

    const container = getOverrideContainer(node, this.document)
    if (!container) {
      node.setActiveBreakpoint('default')
      return
    }

    const containerMode = getOverrideMode(container, this.document)
    if (!containerMode) {
      node.setActiveBreakpoint('default')
      return
    }

    node.setActiveBreakpoint(containerMode)
  }

  private updateDescendants = (node: Node): void => {
    const descendants = this.document.getDescendants(node)
    for (const descendant of descendants) {
      this.updateBreakpoint(descendant.getId())
    }
  }
}
