import { ReadOnlyNode } from 'application/node'
import { AttributeTransformer } from './types'
import { Element, HTMLDocument } from '../html/types'
import { formatNodeId } from '../utils'

export class HTMLAttributes implements AttributeTransformer {
  transformBase = (node: ReadOnlyNode, htmlDocument: HTMLDocument): void => {
    const element = htmlDocument.getById(formatNodeId(node.getId()))
    if (!element) return

    const type = node.getBaseAttribute('type')
    switch (type) {
      case 'image':
        this.addImageAttributes(element, node)
        break
      case 'input':
        this.addInputAttributes(element, node)
        break
      case 'button':
        this.addButtonAttributes(element, node)
        break
      case 'anchor':
        this.addAnchorAttributes(element, node)
        break
    }
  }

  private addImageAttributes(element: Element, node: ReadOnlyNode): void {
    const attributes = node.getBaseAttribute('html.attributes')
    if (!attributes) return

    const src = attributes['src']
    if (src) element.setAttribute('src', src)

    const alt = attributes['alt']
    if (alt) element.setAttribute('alt', alt)
  }

  private addInputAttributes(element: Element, node: ReadOnlyNode): void {
    const attributes = node.getBaseAttribute('html.attributes')
    if (!attributes) return

    const type = attributes['type']
    if (type) element.setAttribute('type', type)

    const name = attributes['name']
    if (name) element.setAttribute('name', name)

    const placeholder = attributes['placeholder']
    if (placeholder) element.setAttribute('placeholder', placeholder)
  }

  private addButtonAttributes(element: Element, node: ReadOnlyNode): void {
    const attributes = node.getBaseAttribute('html.attributes')
    if (!attributes) return

    const type = attributes['type']
    if (type && type !== 'button') element.setAttribute('type', type)

    const name = attributes['name']
    if (name) element.setAttribute('name', name)
  }

  private addAnchorAttributes(element: Element, node: ReadOnlyNode): void {
    const attributes = node.getBaseAttribute('html.attributes')
    if (!attributes) return

    const href = attributes['href']
    if (href) element.setAttribute('href', href)
  }
}
