import { Update } from './types'

export interface UpdateHandler {
  addUpdate(update: Update): void
  get(): Update[]
  clear(): void
}

export class ClientUpdateHandler implements UpdateHandler {
  private updateMap: { [key: string]: Update }

  constructor() {
    this.updateMap = {}
  }

  addUpdate = (update: Update): void => {
    const stringified = JSON.stringify(update)
    if (this.updateMap[stringified]) return
    this.updateMap[stringified] = update
  }

  get = (): Update[] => {
    return Object.values(this.updateMap)
  }

  clear = (): void => {
    this.updateMap = {}
  }
}
