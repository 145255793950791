import { Properties } from 'application/export/css'
import { TailwindClassRenderer } from '../types'

export class TailwindClassAspect implements TailwindClassRenderer {
  render = (properties: Partial<Properties>): string[] => {
    const aspectRatio = properties.aspectRatio
    if (aspectRatio) return [`aspect-[${aspectRatio}]`]

    return []
  }
}
