import { Point } from 'application/shapes'
import { ActionHandler } from '../types'
import { MultiselectAction } from './action'
import { CommandHandler } from 'application/client'
import { NodeSelectionAction } from 'application/action'
import { CoordinatesConversion } from 'application/camera'
import { HapticMultiselectWindow } from 'editor/haptic/multiselectWindow'

export class MultiselectActionFactory {
  private commandHandler: CommandHandler
  private selection: NodeSelectionAction
  private haptic: HapticMultiselectWindow
  private coordinates: CoordinatesConversion

  constructor(
    commandHandler: CommandHandler,
    selection: NodeSelectionAction,
    haptic: HapticMultiselectWindow,
    coordinates: CoordinatesConversion
  ) {
    this.commandHandler = commandHandler
    this.selection = selection
    this.haptic = haptic
    this.coordinates = coordinates
  }

  create = (point: Point): ActionHandler => {
    return new MultiselectAction(
      this.commandHandler,
      this.selection,
      this.haptic,
      this.coordinates,
      point
    )
  }
}
