import { ParentChildIndexCalculator } from 'application/action/reparent/parentIndex'
import { isAbsolutePositionMode } from 'application/attributes'
import { ReadOnlyDocument } from 'application/document'
import { getPadding } from 'application/attributes/utils'
import { ReadOnlyNode } from 'application/node'
import { Point, Rectangle, FlatLine } from 'application/shapes'

type ChildLineComputeSettings = {
  parent: Rectangle
  child: Rectangle
  parentNode: ReadOnlyNode
  previous: Rectangle | null
  next: Rectangle | null
}

export class ChildLineComputer {
  private document: ReadOnlyDocument
  private indexCalculator: ParentChildIndexCalculator

  constructor(
    document: ReadOnlyDocument,
    indexCalculator: ParentChildIndexCalculator
  ) {
    this.document = document
    this.indexCalculator = indexCalculator
  }

  compute = (
    id: string,
    point: Point,
    rectangle: Rectangle
  ): FlatLine | null => {
    const parent = this.document.getNode(id)
    if (!parent) return null

    const index = this.indexCalculator.getIndex(id, point)

    const prev = this.getRectangleForIndex(index - 1, parent)
    const next = this.getRectangleForIndex(index, parent)

    const points = this.getPoints(rectangle, parent, prev, next)
    if (!points) return null

    if (points[0].x === points[1].x) {
      return {
        p1: points[0].y,
        p2: points[1].y,
        counter: points[0].x,
        direction: 'v',
        thickness: 2,
      }
    } else {
      return {
        p1: points[0].x,
        p2: points[1].x,
        counter: points[0].y,
        direction: 'h',
        thickness: 2,
      }
    }
  }

  private getRectangleForIndex = (
    index: number,
    parent: ReadOnlyNode
  ): Rectangle | null => {
    const children = parent.getChildren()
    if (!children) return null
    if (index === -1) return null
    if (index === children.length) return null

    const childId = children.filter((c) => {
      const child = this.document.getNode(c)
      return child && !isAbsolutePositionMode(child)
    })[index]

    const child = this.document.getNode(childId)
    if (!child) return null

    return {
      x: child.getBaseAttribute('x'),
      y: child.getBaseAttribute('y'),
      w: child.getBaseAttribute('w'),
      h: child.getBaseAttribute('h'),
    }
  }

  private getPoints = (
    child: Rectangle,
    parentNode: ReadOnlyNode,
    previous: Rectangle | null,
    next: Rectangle | null
  ): Point[] | null => {
    const parent: Rectangle = {
      x: parentNode.getBaseAttribute('x'),
      y: parentNode.getBaseAttribute('y'),
      w: parentNode.getBaseAttribute('w'),
      h: parentNode.getBaseAttribute('h'),
    }

    const settings: ChildLineComputeSettings = {
      parent,
      child,
      parentNode,
      previous,
      next,
    }

    const mode = parentNode.getStyleAttribute('display')
    switch (mode) {
      case 'flex':
        switch (parentNode.getStyleAttribute('flex.align')) {
          case 'start':
            return this.flexTop(settings)
          case 'center':
            return this.flexCenter(settings)
          case 'end':
          case 'spaced':
            return this.flexBottom(settings)
        }
        break
      case 'block':
        return this.block(settings)
      default:
        return null
    }

    return null
  }

  private block = (settings: ChildLineComputeSettings): Point[] | null => {
    const { parent, child, parentNode, previous } = settings

    const mode = parentNode.getStyleAttribute('display')
    if (mode !== 'block') return null

    const paddingTop = getPadding(parentNode, 'top')
    const paddingLeft = getPadding(parentNode, 'left')
    const yStart = previous ? previous.y + previous.h : parent.y + paddingTop

    return [
      { x: parent.x + paddingLeft, y: yStart },
      { x: parent.x + paddingLeft + child.w, y: yStart },
    ]
  }

  private flexTop = (settings: ChildLineComputeSettings): Point[] | null => {
    const { parent, child, parentNode } = settings

    const mode = parentNode.getStyleAttribute('display')
    if (mode !== 'flex') return null

    const direction = parentNode.getStyleAttribute('flex.direction') || 'row'
    const padding1Key = direction === 'row' ? 'top' : 'left'
    const padding2Key = direction === 'row' ? 'bottom' : 'right'
    const counterKey = direction === 'row' ? 'y' : 'x'
    const sizeKey = direction === 'row' ? 'h' : 'w'

    const padding1 = getPadding(parentNode, padding1Key)
    const padding2 = getPadding(parentNode, padding2Key)

    const startPoint = this.getStartPoint(settings)
    const lineSize = Math.min(
      parent[sizeKey] - padding1 - padding2,
      child[sizeKey]
    )
    const counter1 = parent[counterKey] + padding1
    const counter2 = parent[counterKey] + padding1 + lineSize

    if (direction === 'row') {
      return [
        { x: startPoint, y: counter1 },
        { x: startPoint, y: counter2 },
      ]
    } else {
      return [
        { x: counter1, y: startPoint },
        { x: counter2, y: startPoint },
      ]
    }
  }

  private flexCenter = (settings: ChildLineComputeSettings): Point[] | null => {
    const { parent, child, parentNode } = settings

    const mode = parentNode.getStyleAttribute('display')
    if (mode !== 'flex') return null

    const direction = parentNode.getStyleAttribute('flex.direction') || 'row'
    const padding1Key = direction === 'row' ? 'top' : 'left'
    const padding2Key = direction === 'row' ? 'bottom' : 'right'
    const counterKey = direction === 'row' ? 'y' : 'x'
    const sizeKey = direction === 'row' ? 'h' : 'w'

    const padding1 = getPadding(parentNode, padding1Key)
    const padding2 = getPadding(parentNode, padding2Key)

    const startPoint = this.getStartPoint(settings)
    const lineSize = Math.min(
      parent[sizeKey] - padding1 - padding2,
      child[sizeKey]
    )
    const paddingDiff = padding1 - padding2
    const counter1 =
      parent[counterKey] + paddingDiff + (parent[sizeKey] - lineSize) / 2
    const counter2 =
      parent[counterKey] + paddingDiff + (parent[sizeKey] + lineSize) / 2

    if (direction === 'row') {
      return [
        { x: startPoint, y: counter1 },
        { x: startPoint, y: counter2 },
      ]
    } else {
      return [
        { x: counter1, y: startPoint },
        { x: counter2, y: startPoint },
      ]
    }
  }

  private flexBottom = (settings: ChildLineComputeSettings): Point[] | null => {
    const { parent, child, parentNode } = settings

    const mode = parentNode.getStyleAttribute('display')
    if (mode !== 'flex') return null

    const direction = parentNode.getStyleAttribute('flex.direction') || 'row'
    const padding1Key = direction === 'row' ? 'top' : 'left'
    const padding2Key = direction === 'row' ? 'bottom' : 'right'
    const counterKey = direction === 'row' ? 'y' : 'x'
    const sizeKey = direction === 'row' ? 'h' : 'w'

    const padding1 = getPadding(parentNode, padding1Key)
    const padding2 = getPadding(parentNode, padding2Key)

    const startPoint = this.getStartPoint(settings)
    const lineSize = Math.min(
      parent[sizeKey] - padding1 - padding2,
      child[sizeKey]
    )
    const counter1 = parent[counterKey] + parent[sizeKey] - padding2 - lineSize
    const counter2 = parent[counterKey] + parent[sizeKey] - padding2

    if (direction === 'row') {
      return [
        { x: startPoint, y: counter1 },
        { x: startPoint, y: counter2 },
      ]
    } else {
      return [
        { x: counter1, y: startPoint },
        { x: counter2, y: startPoint },
      ]
    }
  }

  private getStartPoint = (settings: ChildLineComputeSettings): number => {
    const { parent, parentNode, previous, next } = settings

    const mode = parentNode.getStyleAttribute('display')
    if (mode !== 'flex') return 0

    const direction = parentNode.getStyleAttribute('flex.direction') || 'row'
    const paddingKey = direction === 'row' ? 'left' : 'top'
    const padding = getPadding(parentNode, paddingKey)
    const gap = parentNode.getStyleAttribute('flex.gap') || 0
    const positionKey = direction === 'row' ? 'x' : 'y'
    const sizeKey = direction === 'row' ? 'w' : 'h'

    let startPoint = parent[positionKey] + padding
    if (previous) {
      if (next) {
        startPoint = previous[positionKey] + previous[sizeKey] + gap / 2
      } else {
        startPoint = Math.min(
          previous[positionKey] + previous[sizeKey] + gap / 2,
          parent[positionKey] + parent[sizeKey]
        )
      }
    } else if (next) {
      startPoint = Math.max(next[positionKey] - gap / 2, parent[positionKey])
    }

    return startPoint
  }
}
