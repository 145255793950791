import LayerRow from 'components/Library/Components/Layers/LayerRow'
import LayerRowContent from 'components/Library/Components/Layers/LayerRowContent'
import LayersDraggingLine from 'components/Library/Components/Layers/LayersDraggingLine'
import PanelBlock from 'components/Library/Containers/PanelBlock/PanelBlock'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'
import { useRef, useState } from 'react'

interface LayersProps {
  containerRef: React.RefObject<HTMLDivElement>
}

export default function Layers({ containerRef }: LayersProps) {
  const layersRef = useRef<HTMLDivElement>(null)

  const [panelHovered, setPanelHovered] = useState(false)
  const areLayersDragging = false

  const { settings, handlers } = usePanelHelper(editor.getUI().getLayersPanel())
  if (!settings) return <></>

  const { layers, selected, line, selectDisabled } = settings
  const lineInsideId = line && line.position === 'inside' ? line.id : null

  const {
    setLocked,
    setName,
    setEditing,
    toggleOpen,
    select,
    selectRange,
    highlight,
    startDrag,
  } = handlers

  const onMouseDown = (id: string, e: React.MouseEvent) => {
    if (e.button !== 0 || e.ctrlKey || selectDisabled) return
    if (selected.includes(id) && !e.metaKey && !e.ctrlKey && !e.shiftKey) {
      startDrag()
    } else {
      e.shiftKey ? selectRange(id) : select(id, e.metaKey || e.ctrlKey)
      startDrag()
    }
  }

  const onMouseEnter = (id: string) => {
    if (areLayersDragging || selectDisabled) return
    highlight(id)
  }

  return (
    <div
      ref={layersRef}
      id={'layers'}
      onMouseEnter={() => setPanelHovered(true)}
      onMouseLeave={() => setPanelHovered(false)}
      style={{
        position: 'relative',
        width: '100%',
        userSelect: 'none',
        overflowY: 'scroll',
        flexShrink: 1,
      }}
    >
      {layers.map((layer, i) => (
        <LayerRow
          key={layer.id}
          layerId={layer.id}
          level={layer.level}
          right={layer.locked || layer.parentLocked}
          selected={layer.selected}
          selectedParent={layer.parentSelected}
          selectedAbove={layers[i - 1]?.selected || false}
          selectedBelow={layers[i + 1]?.selected || false}
          hovered={layer.hovered}
          renaming={layer.editing}
          lineInside={lineInsideId === layer.id}
          onMouseDown={(e) => onMouseDown(layer.id, e)}
          onMouseEnter={() => onMouseEnter(layer.id)}
          containerRef={containerRef}
        >
          <LayerRowContent
            key={layer.id}
            depth={layer.level}
            layerKey={layer.id}
            name={layer.name}
            setName={(n) => setName(layer.id, n)}
            editing={layer.editing}
            setEditing={(e) => setEditing(layer.id, e)}
            type={layer.type}
            canOpen={layer.open !== undefined}
            open={layer.open || false}
            toggleOpen={() => toggleOpen(layer.id)}
            locked={layer.locked}
            parentLocked={layer.parentLocked}
            setLocked={(l) => setLocked(layer.id, l)}
            hidden={layer.hidden}
            parentHidden={layer.parentHidden}
            panelHovered={panelHovered}
            selected={layer.selected}
            parentSelected={layer.parentSelected}
            hovered={layer.hovered}
          />
        </LayerRow>
      ))}
      <LayersDraggingLine line={line} />
      <PanelBlock height={80} />
    </div>
  )
}
