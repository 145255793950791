import {
  AttributePositionMode,
  MultiselectStyleMap,
} from 'application/attributes'
import { StyleAttributePanel } from './styleAttributePanel'
import { warningKey } from 'assets/warnings'
import { ReadOnlyNode } from 'application/node'
import { getAdjustedLeft, getAdjustedTop } from 'application/units'

type PositionModePanelKeys = 'position.mode'

type PositionModePanelAttributes = Pick<
  MultiselectStyleMap,
  PositionModePanelKeys
> | null

export interface PositionModePanelState {
  attributes: PositionModePanelAttributes
  disabled: boolean
  warning?: warningKey
}

export interface PositionModePanelHandlers {
  setMode: (value: AttributePositionMode) => void
}

export class PositionModePanel extends StyleAttributePanel<
  PositionModePanelState,
  PositionModePanelHandlers,
  PositionModePanelKeys
> {
  getSettings(): PositionModePanelState {
    return {
      attributes: this.attributes,
      disabled: this.getDisabled(),
      warning: this.getWarning(),
    }
  }

  getHandlers(): PositionModePanelHandlers {
    return {
      setMode: this.setMode,
    }
  }

  private getDisabled = (): boolean => {
    const pairs = this.getNodesAndParents()
    if (pairs.length === 0) return true

    for (let i = 0; i < pairs.length; i++) {
      const parent = pairs[i][1]
      if (!parent) continue
      if (parent.getBaseAttribute('type') !== 'canvas') return false
    }

    return true
  }

  private setMode = (value: AttributePositionMode): void => {
    if (this.attributes && value === this.attributes['position.mode']) return

    switch (value) {
      case 'relative':
        this.setMulti({
          'position.mode': value,
          'position.top.unit': undefined,
          'position.left.unit': undefined,
          'position.right.unit': undefined,
          'position.bottom.unit': undefined,
        })
        break
      case 'absolute':
      case 'fixed':
        const nodes = this.getNodes()
        for (const node of nodes) {
          const top = node.getBaseAttribute('y')
          const left = node.getBaseAttribute('x')
          this.setOne(node.getId(), {
            'position.mode': value,
            'position.top.unit': 'px',
            'position.left.unit': 'px',
          })
          const adjustedTop = getAdjustedTop(node, this.document, top)
          const adjustedLeft = getAdjustedLeft(node, this.document, left)
          this.setOne(node.getId(), { ...adjustedTop, ...adjustedLeft })
        }
        break
      case 'sticky':
        this.setMulti({ 'position.mode': value })
        break
    }
  }

  private getWarning = (): warningKey | undefined => {
    const nodes = this.getNodes()
    if (nodes.length === 0) return

    for (const node of nodes) {
      if (node.getStyleAttribute('position.mode') !== 'sticky') continue
      if (this.hasClipAncestor(node)) return 'Sticky'
    }
  }

  private hasClipAncestor = (node: ReadOnlyNode): boolean => {
    const ancestors = this.document.getAncestors(node)
    for (const ancestor of ancestors) {
      if (ancestor.getBaseAttribute('type') === 'canvas') return false
      if (ancestor.getStyleAttribute('overflow') !== 'visible') return true
    }
    return false
  }
}
