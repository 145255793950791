import { DesignColor } from 'themes'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import { GradientStepState } from '../Tabs/MultistepGradientTab'
import { useCallback, useEffect, useRef, useState } from 'react'
import useCommit from 'hooks/editor/useCommit'
import { AttributeGradientStep, gradientToCss } from 'application/attributes'
import { Color, createNewColor, mixColors, rgbaToHex } from 'application/color'

interface GradientStepSliderProps {
  steps: AttributeGradientStep[]
  stepState: GradientStepState
  update: (steps: AttributeGradientStep[]) => void
}

export default function GradientStepSlider({
  steps,
  stepState,
  update,
}: GradientStepSliderProps) {
  const commit = useCommit()
  const sliderRef = useRef<HTMLDivElement>(null)
  const [draggingStep, setDraggingStep] = useState<string | undefined>(
    undefined
  )

  function addStep(e: React.MouseEvent) {
    e.preventDefault()
    e.stopPropagation()
    const rect = sliderRef.current?.getBoundingClientRect()
    if (!rect) return
    const percent =
      ((e.clientX - rect.left - STEP_RADIUS / 2) / (rect.width - STEP_RADIUS)) *
      100
    const position = Math.max(0, Math.min(100, percent))
    const newStep: AttributeGradientStep = {
      key: Math.random().toString(36).substring(2),
      position: Math.round(position),
      color: getNewStepColor(steps, position),
    }
    update([...steps, newStep])
    stepState.setSelectedStep(newStep.key)
    setDraggingStep(newStep.key)
    commit()
  }

  const removeStep = useCallback(
    (key: string) => {
      if (steps.length === 1) return
      const newSteps = [...steps]
      const index = steps.findIndex((step) => step.key === key)
      newSteps.splice(index, 1)
      update(newSteps)

      stepState.setSelectedStep(undefined)
      if (stepState.hoveredStep === key) stepState.setHoveredStep(undefined)
      commit()
    },
    [steps, stepState, update, commit]
  )

  useEffect(() => {
    function handleKeyDown(e: KeyboardEvent) {
      if (e.key === 'Backspace' && stepState.selectedStep) {
        e.preventDefault()
        e.stopPropagation()
        removeStep(stepState.selectedStep)
      }
    }

    document.addEventListener('keydown', handleKeyDown)
    return () => document.removeEventListener('keydown', handleKeyDown)
  }, [stepState.selectedStep, removeStep])

  function updateStep(step: AttributeGradientStep) {
    const newSteps = [...steps].map((s) => {
      return s.key === step.key ? step : s
    })
    update(newSteps)
  }

  return (
    <PanelRow>
      <div
        ref={sliderRef}
        style={{
          position: 'relative',
          width: 216,
          height: 16,
          zIndex: 0,
          boxSizing: 'border-box',
          borderRadius: 8,
          background: gradientToCss({ type: 'linear', angle: 270, steps }),
          boxShadow: `inset 0px 0px 0px 1px ${DesignColor('panelBorder')}`,
        }}
        onMouseDown={addStep}
      >
        {steps.map((step) => (
          <SliderStep
            key={step.key}
            step={step}
            stepState={stepState}
            update={updateStep}
            sliderRef={sliderRef}
            draggingStep={draggingStep}
            setDraggingStep={setDraggingStep}
          />
        ))}
      </div>
    </PanelRow>
  )
}

const STEP_RADIUS = 24

function SliderStep({
  step,
  stepState,
  update,
  sliderRef,
  draggingStep,
  setDraggingStep,
}: {
  step: AttributeGradientStep
  stepState: GradientStepState
  update: (step: AttributeGradientStep) => void
  sliderRef: React.RefObject<HTMLDivElement>
  draggingStep: string | undefined
  setDraggingStep: (key: string | undefined) => void
}) {
  const commit = useCommit()

  const hover = stepState.hoveredStep === step.key
  const selected = stepState.selectedStep === step.key

  function startDrag(e: React.MouseEvent) {
    e.preventDefault()
    e.stopPropagation()
    setDraggingStep(step.key)
  }

  const drag = useCallback(
    (e: MouseEvent) => {
      if (draggingStep !== step.key) return
      e.preventDefault()
      e.stopPropagation()
      const rect = sliderRef.current?.getBoundingClientRect()
      if (!rect) return
      const percent =
        ((e.clientX - rect.left - STEP_RADIUS / 2) /
          (rect.width - STEP_RADIUS)) *
        100
      const position = Math.max(0, Math.min(100, percent))
      update({ ...step, position: Math.round(position) })
    },
    [step, update, draggingStep, sliderRef]
  )

  const endDrag = useCallback(
    (e: MouseEvent) => {
      if (draggingStep !== step.key) return
      e.preventDefault()
      e.stopPropagation()
      setDraggingStep(undefined)
      commit()
    },
    [commit, draggingStep, setDraggingStep, step.key]
  )

  useEffect(() => {
    document.addEventListener('mousemove', drag)
    document.addEventListener('mouseup', endDrag)
    return () => {
      document.removeEventListener('mousemove', drag)
      document.removeEventListener('mouseup', endDrag)
    }
  }, [endDrag, drag])

  return (
    <div
      style={{
        position: 'absolute',
        left: `${step.position}%`,
        top: '50%',
        zIndex: selected ? 1 : 0,
        transform: `translate(-${step.position}%, -50%)`,
        width: STEP_RADIUS,
        height: STEP_RADIUS,
        boxSizing: 'border-box',
        borderRadius: 99,
        background: DesignColor('panelBackground'),
        border: `${selected ? '2px' : '1px'} solid ${DesignColor(
          hover || selected ? 'inputHighlight' : 'panelBorder'
        )}`,
      }}
      onMouseEnter={() => stepState.setHoveredStep(step.key)}
      onMouseLeave={() => stepState.setHoveredStep(undefined)}
      onMouseDown={(e) => {
        stepState.setSelectedStep(step.key)
        startDrag(e)
      }}
    >
      <div
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: STEP_RADIUS / 2,
          height: STEP_RADIUS / 2,
          boxSizing: 'border-box',
          borderRadius: 99,
          background: getStepFill(step),
          border: `${selected ? '2px' : '1px'} solid ${DesignColor(
            hover || selected ? 'inputHighlight' : 'panelBorder'
          )}`,
        }}
      />
    </div>
  )
}

function getStepFill(step: AttributeGradientStep) {
  const hex = `#${rgbaToHex(step.color)}`
  return `linear-gradient(0deg, ${hex}, ${hex}), repeating-linear-gradient(45deg, #ccc, #ccc 3px, #fff 3px, #fff 6px)`
}

function getNewStepColor(
  steps: AttributeGradientStep[],
  position: number
): Color {
  if (steps.length === 0) return createNewColor()
  if (steps.length === 1) return steps[0].color
  if (position < steps[0].position) return steps[0].color
  if (position > steps[steps.length - 1].position)
    return steps[steps.length - 1].color

  var prevStep = steps[0]
  var nextStep = steps[1]

  for (let i = 1; i < steps.length; i++) {
    if (steps[i].position > position) {
      nextStep = steps[i]
      break
    }
    prevStep = steps[i]
  }

  const mixPercent =
    100 -
    ((position - prevStep.position) / (nextStep.position - prevStep.position)) *
      100

  return mixColors(prevStep.color, nextStep.color, mixPercent)
}
