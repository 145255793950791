import { AlignMode, TextContent, TextStyle, TextStyleOverrides } from '../types'
import { ShapedCharacter, ShapedRow, ShapedText } from './types'
import { FontLoaderInterface } from 'application/text'

export interface Shaper {
  getShapedText: (state: TextContent, width?: number) => ShapedText
  releaseShapedText: (text: ShapedText) => void
}

type NextRowResult = {
  row: ShapedRow | null
  nextIndex: number
  nextParagraph: boolean
  wraps: boolean
}

type Char = { char: string; nextChar: string }

export class TextShaper implements Shaper {
  private fontLoader: FontLoaderInterface
  private shapedTextPool: ShapedText[]
  private nextRowPool: NextRowResult[]
  private rowPool: ShapedRow[]
  private shapedCharacterPool: ShapedCharacter[]
  private charPool: Char[]

  constructor(fontLoader: FontLoaderInterface) {
    this.fontLoader = fontLoader

    this.shapedTextPool = []
    for (let i = 0; i < 10; i++) {
      this.shapedTextPool.push({ rows: [], w: 0, h: 0, wraps: false })
    }

    this.nextRowPool = []
    for (let i = 0; i < 10; i++) {
      const next = {
        row: null,
        nextIndex: 0,
        nextParagraph: false,
        wraps: false,
      }
      this.nextRowPool.push(next)
    }

    this.rowPool = []
    for (let i = 0; i < 100; i++) {
      this.rowPool.push({ w: 0, h: 0, paragraph: 0, characters: [] })
    }

    this.shapedCharacterPool = []
    for (let i = 0; i < 1000; i++) {
      this.shapedCharacterPool.push({
        x: 0,
        y: 0,
        w: 0,
        h: 0,
        index: 0,
        yOffset: 0,
      })
    }

    this.charPool = []
    for (let i = 0; i < 100; i++) {
      this.charPool.push({ char: ' ', nextChar: ' ' })
    }
  }

  getShapedText = (state: TextContent, width?: number): ShapedText => {
    const { content, contentStyles, styleOverrides } = state

    let index = 0
    let paragraph = 0
    let x = 0
    let y = 0
    let maxWidth = width
    let contentWraps = false
    const rows: ShapedRow[] = []

    while (index < content.length) {
      const result = this.getNextRow(
        index,
        x,
        y,
        paragraph,
        maxWidth,
        content,
        contentStyles,
        state.styles,
        styleOverrides
      )

      if (!result.row) {
        this.releaseRowResultObject(result)
        break
      }

      if (result.row.characters.length === 0) {
        this.releaseRowObject(result.row)
        this.releaseRowResultObject(result)
        break
      }

      let rowW = 0
      for (let i = 0; i < result.row.characters.length; i++) {
        if (
          i === result.row.characters.length - 1 &&
          (result.row.characters[i].space ||
            result.row.characters[i].newline) &&
          maxWidth !== undefined
        ) {
          continue
        }
        rowW += result.row.characters[i].w
      }

      let rowH = 0
      for (let i = 0; i < result.row.characters.length; i++) {
        if (result.row.characters[i].h > rowH) {
          rowH = Math.max(rowH, result.row.characters[i].h)
        }
      }

      result.row.w = rowW
      result.row.h = rowH

      this.updateYOffsets(
        result.row,
        content,
        contentStyles,
        state.styles,
        styleOverrides
      )

      rows.push(result.row)
      index = result.nextIndex
      x = 0
      y += result.row.h
      if (result.nextParagraph) paragraph++
      contentWraps = contentWraps || result.wraps

      this.releaseRowResultObject(result)
    }

    if (rows.length === 0) {
      const row = this.getRow()
      row.w = 0
      row.h = this.getDefaultHeight(state.styles)
      row.paragraph = 0
      row.characters = []
      rows.push(row)
    } else if (content[index - 1] === '\n') {
      const row = this.getRow()
      row.w = 0
      row.h = rows[rows.length - 1].h
      row.paragraph = paragraph
      row.characters = []
      rows.push(row)
    }

    this.updateRowsForAlignment(rows, state.styles.align, maxWidth)

    const shapedText = this.getShapedTextObject()
    shapedText.rows = rows
    shapedText.w = rows.reduce((acc, row) => Math.max(acc, row.w), 0)
    shapedText.h = rows.reduce((acc, row) => acc + row.h, 0)
    shapedText.wraps = contentWraps

    return shapedText
  }

  releaseShapedText = (text: ShapedText): void => {
    for (let i = 0; i < text.rows.length; i++) {
      this.releaseRowObject(text.rows[i])
    }
    text.rows = []
    this.shapedTextPool.push(text)
  }

  private getNextRow(
    index: number,
    startX: number,
    startY: number,
    paragraph: number,
    maxWidth: number | undefined,
    content: string,
    contentStyles: number[],
    style: TextStyle,
    styleOverrides: TextStyleOverrides
  ): NextRowResult {
    const row = this.getRow()
    row.paragraph = paragraph

    let x = startX
    let y = startY
    let unfinished: ShapedCharacter[] = []
    let unfinishedIndex = index
    let finishedIndex = index
    let paragraphEnd = false

    while (unfinishedIndex < content.length) {
      const charStyleIndex = contentStyles[unfinishedIndex]

      const shapedChar = this.getCharBox(
        content,
        unfinishedIndex,
        style,
        styleOverrides[charStyleIndex]
      )
      if (!shapedChar) break

      unfinishedIndex++

      shapedChar.x = x
      shapedChar.y = y
      shapedChar.index = unfinishedIndex

      if (shapedChar.newline) {
        shapedChar.w = 0
        row.characters.push(...unfinished, shapedChar)
        unfinished = []
        finishedIndex = unfinishedIndex
        paragraphEnd = true
      } else if (shapedChar.space) {
        row.characters.push(...unfinished, shapedChar)
        unfinished = []
        finishedIndex = unfinishedIndex
        x += shapedChar.w
      } else {
        unfinished.push(shapedChar)
        x += shapedChar.w
      }

      const exceedsWidth = maxWidth !== undefined && x > maxWidth
      const hasChars = row.characters.length > 0
      if ((exceedsWidth && hasChars) || paragraphEnd) {
        const result = this.getRowResultObject(row)
        result.row = row
        result.nextIndex = finishedIndex
        result.nextParagraph = paragraphEnd
        result.wraps = exceedsWidth && hasChars

        return result
      }
    }

    row.characters.push(...unfinished)
    finishedIndex = unfinishedIndex

    const result = this.getRowResultObject(row)
    result.row = row
    result.nextIndex = finishedIndex
    result.nextParagraph = paragraphEnd
    result.wraps = false

    return result
  }

  private getCharBox(
    content: string,
    index: number,
    style: TextStyle,
    styleOverrides: Partial<TextStyle>
  ): ShapedCharacter | null {
    const char = this.getChar(index, content)

    const fontFamily = this.getOverriddenStyle(
      'fontFamily',
      style,
      styleOverrides
    )
    const fontWeight = this.getOverriddenStyle(
      'fontWeight',
      style,
      styleOverrides
    )
    const font = this.fontLoader.getFont(fontFamily, fontWeight)
    if (!font) return null

    const charGlyph = font.charToGlyph(char.char)
    if (!charGlyph) {
      this.releaseCharObject(char)
      return null
    }

    const nextCharGlyph = font.charToGlyph(char.nextChar)
    if (!nextCharGlyph) {
      this.releaseCharObject(char)
      return null
    }

    const fontSize = this.getOverriddenStyle('fontSize', style, styleOverrides)
    const scale = fontSize / font.unitsPerEm
    const kerning = font.getKerningValue(charGlyph, nextCharGlyph)

    const letterSpacing = this.getOverriddenStyle(
      'letterSpacing',
      style,
      styleOverrides
    )
    const width = (charGlyph.advanceWidth! + kerning) * scale + letterSpacing

    const lineHeight = this.getOverriddenStyle(
      'lineHeight',
      style,
      styleOverrides
    )
    const height =
      (font.ascender! - font.descender! + font.tables.hhea.lineGap) * scale +
      lineHeight
    const yOffset = (font.ascender! - charGlyph.yMax!) * scale + lineHeight / 2

    const shapedChar = this.getShapedCharacterObject()
    shapedChar.x = 0
    shapedChar.y = 0
    shapedChar.w = width
    shapedChar.h = height
    shapedChar.index = 0
    shapedChar.yOffset = yOffset
    shapedChar.space = content[index] === ' '
    shapedChar.newline = content[index] === '\n'

    this.releaseCharObject(char)

    return shapedChar
  }

  private getChar(index: number, content: string): Char {
    const char =
      content[index] !== undefined && content[index] !== '\n'
        ? content[index]
        : ' '
    const nextChar =
      content[index + 1] !== undefined && content[index + 1] !== '\n'
        ? content[index + 1]
        : ' '

    const charObj = this.getCharObject()
    charObj.char = char
    charObj.nextChar = nextChar

    return charObj
  }

  private getDefaultHeight(style: TextStyle): number {
    const { fontFamily, fontWeight, fontSize, lineHeight } = style

    const font = this.fontLoader.getFont(fontFamily, fontWeight)
    if (!font) return 0

    const scale = fontSize / font.unitsPerEm
    return (
      (font.ascender! - font.descender! + font.tables.hhea.lineGap) * scale +
      lineHeight
    )
  }

  private updateRowsForAlignment(
    rows: ShapedRow[],
    alignment: AlignMode,
    width?: number
  ): void {
    const maxWidth = width || Math.max(...rows.map((r) => r.w))
    for (let i = 0; i < rows.length; i++) {
      const row = rows[i]
      const rowWidth = row.w
      const targetWidth = width || maxWidth

      let x = 0
      switch (alignment) {
        case 'left':
          x = 0
          break
        case 'right':
          x = targetWidth - rowWidth
          break
        case 'center':
          x = (targetWidth - rowWidth) / 2
          break
      }

      for (let j = 0; j < row.characters.length; j++) {
        row.characters[j].x = x + row.characters[j].x
      }
    }
  }

  private updateYOffsets(
    row: ShapedRow,
    content: string,
    contentStyles: number[],
    style: TextStyle,
    styleOverrides: TextStyleOverrides
  ): void {
    let tallestCharIndex = 0
    let tallestCharHeight = 0

    for (let i = 0; i < row.characters.length; i++) {
      const char = row.characters[i]
      if (char.h > tallestCharHeight) {
        tallestCharHeight = char.h
        tallestCharIndex = i
      }
    }

    const tallestChar = row.characters[tallestCharIndex]
    const tallestCharStyleIndex = contentStyles[tallestChar.index - 1]
    const tallestCharStyle = styleOverrides[tallestCharStyleIndex]
    const fontFamily = this.getOverriddenStyle(
      'fontFamily',
      style,
      tallestCharStyle
    )
    const fontWeight = this.getOverriddenStyle(
      'fontWeight',
      style,
      tallestCharStyle
    )
    const tallestFont = this.fontLoader.getFont(fontFamily, fontWeight)
    if (!tallestFont) return

    const fontSize = this.getOverriddenStyle(
      'fontSize',
      style,
      tallestCharStyle
    )
    const tallestCharScale = fontSize / tallestFont.unitsPerEm
    const tallestLineGap = tallestFont.tables.hhea.lineGap
    const gapAboveBaseline =
      (tallestFont.ascender + tallestLineGap / 2) * tallestCharScale

    for (let i = 0; i < row.characters.length; i++) {
      const char = row.characters[i]
      if (char.h < tallestCharHeight) {
        const charStyleIndex = contentStyles[char.index - 1]
        const charStyle = styleOverrides[charStyleIndex]
        const charContent =
          content[char.index - 1] !== undefined &&
          content[char.index - 1] !== '\n'
            ? content[char.index - 1]
            : ' '
        const fontFamily = this.getOverriddenStyle(
          'fontFamily',
          style,
          charStyle
        )
        const fontWeight = this.getOverriddenStyle(
          'fontWeight',
          style,
          charStyle
        )
        const font = this.fontLoader.getFont(fontFamily, fontWeight)
        if (!font) continue

        const charGlyph = font.charToGlyph(charContent)
        if (!charGlyph) continue

        const fontSize = this.getOverriddenStyle('fontSize', style, charStyle)
        const scale = fontSize / font.unitsPerEm

        const glyphYMax = charGlyph.yMax! * scale
        const lineHeight = this.getOverriddenStyle(
          'lineHeight',
          style,
          charStyle
        )
        char.yOffset = gapAboveBaseline - glyphYMax + lineHeight / 2
      }
    }
  }

  private getOverriddenStyle<K extends keyof TextStyle>(
    key: K,
    style: TextStyle,
    styleOverrides: Partial<TextStyle>
  ): TextStyle[K] {
    const overriddenValue = styleOverrides?.[key]
    if (overriddenValue !== undefined) return overriddenValue
    return style[key]
  }

  private getRow = (): ShapedRow => {
    if (this.rowPool.length > 0) {
      return this.rowPool.pop()!
    }
    return { w: 0, h: 0, paragraph: 0, characters: [] }
  }

  private getShapedTextObject = (): ShapedText => {
    if (this.shapedTextPool.length > 0) {
      return this.shapedTextPool.pop()!
    }
    return { w: 0, h: 0, wraps: false, rows: [] }
  }

  private getRowResultObject = (row: ShapedRow): NextRowResult => {
    if (this.nextRowPool.length > 0) {
      const nextRowResult = this.nextRowPool.pop()!
      nextRowResult.row = row
      return nextRowResult
    }
    return { row: null, nextIndex: 0, nextParagraph: false, wraps: false }
  }

  private releaseRowResultObject = (result: NextRowResult): void => {
    result.row = null
    result.nextIndex = 0
    result.nextParagraph = false
    result.wraps = false
    this.nextRowPool.push(result)
  }

  private releaseRowObject = (row: ShapedRow): void => {
    for (let i = 0; i < row.characters.length; i++) {
      this.releaseShapedCharacterObject(row.characters[i])
    }
    row.w = 0
    row.h = 0
    row.paragraph = 0
    row.characters = []
    this.rowPool.push(row)
  }

  private getShapedCharacterObject = (): ShapedCharacter => {
    if (this.shapedCharacterPool.length > 0) {
      return this.shapedCharacterPool.pop()!
    }
    return { x: 0, y: 0, w: 0, h: 0, index: 0, yOffset: 0 }
  }

  private releaseShapedCharacterObject = (char: ShapedCharacter): void => {
    char.x = 0
    char.y = 0
    char.w = 0
    char.h = 0
    char.index = 0
    char.yOffset = 0
    this.shapedCharacterPool.push(char)
  }

  private getCharObject = (): Char => {
    if (this.charPool.length > 0) {
      return this.charPool.pop()!
    }
    return { char: '', nextChar: '' }
  }

  private releaseCharObject = (char: Char): void => {
    char.char = ''
    char.nextChar = ''
    this.charPool.push(char)
  }
}
