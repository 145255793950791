import FillInputRow from 'components/Library/Components/Inputs/FillInputRow/FillInputRow'
import TextBlock from 'components/Library/Components/Text/TextBlock/TextBlock'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import TitleRow from 'components/Library/Components/Button/TitleRow/TitleRow'
import { useRef } from 'react'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'
import OverrideReset from 'components/Library/Components/Inputs/OverrideReset/OverrideReset'

export default function BackgroundSettings() {
  const sectionRef = useRef<HTMLDivElement>(null)

  const { settings, handlers } = usePanelHelper(
    editor.getUI().getBackgroundPanel()
  )
  const overrides = usePanelHelper(editor.getUI().getStyleOverridesPanel())
  if (!settings) return <></>

  const { background, options, mode } = settings
  if (!background) return <></>

  const { hasOverride, sectionHasOverride } = overrides.handlers
  const { add, remove, set, clearMixed } = handlers

  const handleClickRow = () => {
    if (background === 'Mixed') {
      clearMixed()
    } else if (mode === 'add') {
      add()
    }
  }

  const handleClick = () => {
    if (background === 'Mixed') {
      clearMixed()
    } else if (mode === 'remove') {
      remove()
    } else {
      add()
    }
  }

  return (
    <PanelSection sectionRef={sectionRef}>
      <TitleRow
        title={'Background'}
        active={mode !== 'add'}
        onSelectDesign={() => {}}
        canActivate={mode === 'add' || background === 'Mixed'}
        canDeactivate={mode === 'remove' && background !== 'Mixed'}
        onClickRow={handleClickRow}
        onClickIcon={handleClick}
        sectionRef={sectionRef}
        overrideSectionKey={'background'}
        hasOverride={sectionHasOverride('background')}
      />
      <OverrideReset keys={['background']}>
        {background === 'Mixed' && (
          <PanelRow>
            <TextBlock width={216} mode={'label2'}>
              {'Click + to clear mixed backgrounds'}
            </TextBlock>
          </PanelRow>
        )}
        {background !== 'Mixed' && (
          <>
            {background.map((fill, index) => (
              <FillInputRow
                key={index}
                fill={fill}
                updateFill={(f) => set(f)}
                modes={options}
                hasOverride={hasOverride(['background'])}
              />
            ))}
          </>
        )}
      </OverrideReset>
    </PanelSection>
  )
}
