import DisplaySettings from './Display/DisplaySettings'
import BackgroundSettings from './Background/BackgroundSettings'
import FilterBlurSettings from './FilterBlur/FilterBlurSettings'
import BorderSettings from './Border/BorderSettings'
import GeneralSettings from './General/GeneralSettings'
import ImageSettings from './Image/ImageSettings'
import SelectionColors from './SelectionColors/SelectionColors'
import ShadowSettings from './Shadow/ShadowSettings'
import TextSettings from './Text/TextSettings'
import ScrollContainer from 'components/Library/Containers/ScrollContainer/ScrollContainer'
import TransitionSettings from './Transition/TransitionSettings'
import CursorSettings from './Cursor/CursorSettings'
import PaddingSettings from './Padding/PaddingSettings'

export default function StyleSettings() {
  return (
    <ScrollContainer>
      <GeneralSettings />
      <DisplaySettings />
      <PaddingSettings />
      <BackgroundSettings />
      <TextSettings />
      <ImageSettings />
      <BorderSettings />
      <ShadowSettings />
      <FilterBlurSettings />
      <TransitionSettings />
      <CursorSettings />
      <SelectionColors />
      <SidepanelBottom />
    </ScrollContainer>
  )
}

function SidepanelBottom() {
  return (
    <div
      style={{
        width: 232,
        height: 100,
      }}
    />
  )
}
