import {
  Camera,
  CameraUpdateListener,
  CanvasSizeUpdateListener,
} from 'application/camera'
import { Rectangle } from 'application/shapes'
import { Canvas, CanvasUpdateListener } from 'editor/canvas/canvas'
import { GridlinesSmallTransformer } from './transformer/gridlinesSmall'

export const hapticGridlinesSmallKey = 'gridlinesSmall'

export class HapticGridlinesSmall
  implements
    CameraUpdateListener,
    CanvasUpdateListener,
    CanvasSizeUpdateListener
{
  private canvas: Canvas

  private camera: Camera | null
  private canvasSize: Rectangle | null

  constructor(canvas: Canvas) {
    this.canvas = canvas

    this.camera = null
    this.canvasSize = null
  }

  onCamera = (camera: Camera) => {
    this.camera = camera
    this.render()
  }

  onCanvasSize = (canvasSize: Rectangle) => {
    this.canvasSize = canvasSize
    this.render()
  }

  onCanvasInit = () => {
    this.render()
  }

  private render = () => {
    if (!this.canvas.isReady()) return
    if (this.camera === null || this.canvasSize === null) {
      this.canvas.deleteHaptic(hapticGridlinesSmallKey)
    } else {
      this.canvas.setHaptic(
        hapticGridlinesSmallKey,
        GridlinesSmallTransformer.transform(
          this.canvas.getContext(),
          this.camera,
          this.canvasSize
        )
      )
    }
  }
}
