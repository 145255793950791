import { ShapedText } from 'application/text'
import { Selection, SelectionPoint } from '../types'
import {
  belowMinIndex,
  findNextChar,
  findPreviousChar,
  getCharAtPoint,
  getCharacterAtIndex,
  getIndexByPosition,
  getLineAtIndex,
  getLineAtPoint,
  getMaxIndex,
  getParagraph,
  getParagraphAtIndex,
  inBounds,
} from './utils'
import { Point } from 'application/shapes'

export interface Layout {
  getIndexAbove: (
    shapedText: ShapedText,
    layoutPoint: SelectionPoint
  ) => SelectionPoint | null
  getIndexBelow: (
    shapedText: ShapedText,
    layoutPoint: SelectionPoint
  ) => SelectionPoint | null
  getIndexAfter: (
    shapedText: ShapedText,
    layoutPoint: SelectionPoint
  ) => SelectionPoint | null
  getIndexBefore: (
    shapedText: ShapedText,
    layoutPoint: SelectionPoint
  ) => SelectionPoint | null
  getStartOfLine: (
    shapedText: ShapedText,
    layoutPoint: SelectionPoint
  ) => SelectionPoint | null
  getEndOfLine: (
    shapedText: ShapedText,
    layoutPoint: SelectionPoint
  ) => SelectionPoint | null
  getStartOfContent: () => SelectionPoint | null
  getEndOfContent: (shapedText: ShapedText) => SelectionPoint | null
  getIndexAtPoint: (
    shapedText: ShapedText,
    point: Point
  ) => SelectionPoint | null
  getWordAtPoint: (shapedText: ShapedText, point: Point) => Selection | null
  getParagraphAtPoint: (
    shapedText: ShapedText,
    point: Point
  ) => Selection | null
}

export class TextEditorLayout implements Layout {
  getIndexAbove = (
    shapedText: ShapedText,
    layoutPoint: SelectionPoint
  ): SelectionPoint | null => {
    const { index, wrapped } = layoutPoint

    if (!inBounds(shapedText, layoutPoint.index)) {
      if (belowMinIndex(layoutPoint.index)) return { index: 0, wrapped: false }
      return getMaxIndex(shapedText)
    }

    if (wrapped) {
      const maxIndex = getMaxIndex(shapedText)
      if (!maxIndex) return null

      const atMax = maxIndex.index === index
      const currentLine = getLineAtIndex(shapedText, atMax ? index : index + 1)
      if (currentLine <= 0) return maxIndex

      const lineIndex = atMax ? currentLine : currentLine - 1
      const previousLine = shapedText.rows[lineIndex]
      return {
        index: previousLine.characters[0].index - 1,
        wrapped: lineIndex > 0,
      }
    }

    const currentLine = getLineAtIndex(shapedText, index)
    if (currentLine <= 0) return { index: 0, wrapped: false }

    const previousLine = shapedText.rows[currentLine - 1]
    const currentChar = getCharacterAtIndex(shapedText.rows[currentLine], index)
    if (currentChar === null) return null

    const position = currentChar.x + currentChar.w
    return getIndexByPosition(previousLine, position)
  }

  getIndexBelow = (
    shapedText: ShapedText,
    layoutPoint: SelectionPoint
  ): SelectionPoint | null => {
    const { index, wrapped } = layoutPoint

    if (!inBounds(shapedText, layoutPoint.index)) {
      if (belowMinIndex(layoutPoint.index)) return { index: 0, wrapped: false }
      return getMaxIndex(shapedText)
    }

    if (wrapped) {
      const currentLine = getLineAtIndex(shapedText, index)
      if (currentLine === -1) return { index: 0, wrapped: false }

      const nextLine = shapedText.rows[currentLine + 1]
      if (nextLine === undefined) {
        return {
          index: shapedText.rows[currentLine].characters.slice(-1)[0].index,
          wrapped: false,
        }
      }

      const maxIndex = getMaxIndex(shapedText)
      if (!maxIndex) return null

      const nextChar = nextLine.characters.slice(-1)[0]
      if (nextChar === undefined) return maxIndex
      if (nextChar.index > maxIndex.index) return maxIndex

      return {
        index: nextLine.characters.slice(-1)[0].index,
        wrapped: currentLine < shapedText.rows.length - 2,
      }
    }

    const currentLine = getLineAtIndex(shapedText, index)
    if (currentLine === -1) return { index: 0, wrapped: false }

    const nextLine = shapedText.rows[currentLine + 1]
    if (nextLine === undefined) {
      return {
        index: shapedText.rows[currentLine].characters.slice(-1)[0].index,
        wrapped: false,
      }
    } else if (index === 0 || nextLine.characters.length === 0) {
      return {
        index: shapedText.rows[currentLine].characters.slice(-1)[0].index,
        wrapped: true,
      }
    }

    const currentChar = getCharacterAtIndex(shapedText.rows[currentLine], index)
    if (currentChar === null) return null

    const position = currentChar.x + currentChar.w
    return getIndexByPosition(nextLine, position)
  }

  getIndexAfter = (
    shapedText: ShapedText,
    layoutPoint: SelectionPoint
  ): SelectionPoint | null => {
    const { index, wrapped } = layoutPoint

    if (!inBounds(shapedText, layoutPoint.index)) {
      if (belowMinIndex(layoutPoint.index)) return { index: 0, wrapped: false }
      return getMaxIndex(shapedText)
    }

    const maxIndex = getMaxIndex(shapedText)
    if (!maxIndex) return null
    if (index === maxIndex.index) return maxIndex

    const currentLine = getLineAtIndex(shapedText, index)
    if (currentLine === -1) return { index: 0, wrapped: false }

    if (wrapped) {
      const nextLine = shapedText.rows[currentLine + 1]
      if (nextLine === undefined) return getMaxIndex(shapedText)

      const nextChar = nextLine.characters[0]
      return {
        index: nextChar.index,
        wrapped: nextChar.space || nextChar.newline || false,
      }
    }

    const nextLine = getLineAtIndex(shapedText, index + 1)
    if (nextLine === -1) return maxIndex

    const nextChar = getCharacterAtIndex(shapedText.rows[nextLine], index + 1)
    if (nextChar === null) return null

    if (
      shapedText.rows[nextLine].characters.slice(-1)[0] === nextChar &&
      (nextChar.space || nextChar.newline) &&
      nextLine < shapedText.rows.length - 1
    ) {
      return {
        index: nextChar.index,
        wrapped: true,
      }
    }

    return {
      index: nextChar.index,
      wrapped: false,
    }
  }

  getIndexBefore = (
    shapedText: ShapedText,
    layoutPoint: SelectionPoint
  ): SelectionPoint | null => {
    const { index, wrapped } = layoutPoint

    if (!inBounds(shapedText, layoutPoint.index)) {
      if (belowMinIndex(layoutPoint.index)) return { index: 0, wrapped: false }
      return getMaxIndex(shapedText)
    }
    if (index === 1) return { index: 0, wrapped: false }

    const currentLine = getLineAtIndex(shapedText, index)
    if (currentLine === -1) return { index: 0, wrapped: false }

    if (wrapped) {
      const singleChar = shapedText.rows[currentLine].characters.length <= 1
      const lineIndex = singleChar ? currentLine - 1 : currentLine

      const previousChar = getCharacterAtIndex(
        shapedText.rows[lineIndex],
        index - 1
      )
      if (previousChar === null) return null

      return {
        index: previousChar.index,
        wrapped: previousChar.newline || false,
      }
    }

    const previousLine = getLineAtIndex(shapedText, index - 1)
    if (previousLine === -1) return { index: 0, wrapped: false }

    const previousChar = getCharacterAtIndex(
      shapedText.rows[previousLine],
      index - 1
    )
    if (previousChar === null) return null

    return {
      index: previousChar.index,
      wrapped:
        previousChar.newline ||
        (previousChar.space && previousLine !== currentLine) ||
        false,
    }
  }

  getStartOfLine = (
    shapedText: ShapedText,
    layoutPoint: SelectionPoint
  ): SelectionPoint | null => {
    const { index, wrapped } = layoutPoint
    if (wrapped) return layoutPoint

    if (!inBounds(shapedText, layoutPoint.index)) {
      if (belowMinIndex(layoutPoint.index)) return { index: 0, wrapped: false }
      return getMaxIndex(shapedText)
    }

    const currentLine = getLineAtIndex(shapedText, index)
    if (currentLine <= 0) return { index: 0, wrapped: false }

    const previousLine = shapedText.rows[currentLine - 1]
    return {
      index: previousLine.characters.slice(-1)[0].index,
      wrapped: currentLine > 0,
    }
  }

  getEndOfLine = (
    shapedText: ShapedText,
    layoutPoint: SelectionPoint
  ): SelectionPoint | null => {
    const { index, wrapped } = layoutPoint

    if (!inBounds(shapedText, layoutPoint.index)) {
      if (belowMinIndex(layoutPoint.index)) return { index: 0, wrapped: false }
      return getMaxIndex(shapedText)
    }

    if (wrapped) {
      const currentLine = getLineAtIndex(shapedText, index)
      if (currentLine === -1) return { index: 0, wrapped: false }

      const nextLine = shapedText.rows[currentLine + 1]
      if (nextLine === undefined || nextLine.characters.length <= 1) {
        return layoutPoint
      }

      const nextChar = nextLine.characters.slice(-1)[0]
      if (nextChar === undefined) return null

      if (nextChar.newline) return { index: nextChar.index - 1, wrapped: false }

      const maxIndex = getMaxIndex(shapedText)
      if (maxIndex && nextChar.index > maxIndex.index) return maxIndex

      return {
        index: nextLine.characters.slice(-1)[0].index,
        wrapped: false,
      }
    }

    const currentLine = getLineAtIndex(shapedText, index)
    if (currentLine === -1) return { index: 0, wrapped: false }

    const nextChar = shapedText.rows[currentLine].characters.slice(-1)[0]
    if (nextChar === undefined) return null
    if (nextChar.newline) {
      return {
        index: nextChar.index - 1,
        wrapped: false,
      }
    }

    return {
      index: shapedText.rows[currentLine].characters.slice(-1)[0].index,
      wrapped: false,
    }
  }

  getStartOfContent = (): SelectionPoint | null => {
    return { index: 0, wrapped: false }
  }

  getEndOfContent = (shapedText: ShapedText): SelectionPoint | null => {
    return getMaxIndex(shapedText)
  }

  getIndexAtPoint = (
    shapedText: ShapedText,
    point: Point
  ): SelectionPoint | null => {
    const row = getLineAtPoint(shapedText, point)
    if (row === null) return null

    const index = getIndexByPosition(row, point.x)
    if (index === null) return null

    return index
  }

  getWordAtPoint = (shapedText: ShapedText, point: Point): Selection | null => {
    const char = getCharAtPoint(shapedText, point)
    if (char === null) return null

    const start = findPreviousChar(
      shapedText,
      char.index,
      !char.newline && !char.space
    )
    if (start === null) return null

    const end = findNextChar(
      shapedText,
      char.index,
      !char.newline && !char.space
    )
    if (end === null) return null

    return {
      anchor: start,
      focus: end,
    }
  }

  getParagraphAtPoint = (
    shapedText: ShapedText,
    point: Point
  ): Selection | null => {
    const char = getCharAtPoint(shapedText, point)
    if (char === null) return null

    const paragraph = getParagraphAtIndex(shapedText, char.index)
    if (paragraph === -1) return null

    const paragraphSelection = getParagraph(shapedText, paragraph)
    if (paragraphSelection === null) return null

    return paragraphSelection
  }
}
