import { mat3 } from 'gl-matrix'
import { Material, Context } from 'application/render'

export type UniformValue = boolean | number | number[] | mat3

export class Uniform {
  protected material: Material
  protected context: Context
  protected location: WebGLUniformLocation
  protected name: string

  constructor(material: Material, name: string) {
    this.material = material
    this.context = material.getContext()
    this.name = name

    const location = this.context
      .getGl()
      .getUniformLocation(this.material.getProgram(), this.name)
    if (!location) throw new Error(getError(this.name))
    this.location = location
  }

  getLocation = (): WebGLUniformLocation => this.location

  set = (value: UniformValue) => {
    const gl = this.context.getGl()
    if (Array.isArray(value) || ArrayBuffer.isView(value)) {
      switch (value.length) {
        case 1:
          gl.uniform1fv(this.location, value)
          break
        case 2:
          gl.uniform2fv(this.location, value)
          break
        case 4:
          gl.uniform4fv(this.location, value)
          break
        case 9:
          gl.uniformMatrix3fv(this.location, false, value)
          break
        default:
          if (value.length > 9) {
            gl.uniform1fv(this.location, value)
            break
          }
          console.warn('Unsupported uniform size:', value)
          break
      }
    } else if (typeof value === 'boolean') {
      gl.uniform1i(this.location, value ? 1 : 0)
    } else if (typeof value === 'number') {
      gl.uniform1f(this.location, value)
    } else {
      console.warn('Unsupported uniform type:', value)
    }
  }

  setSampler = (unit: number) => {
    const gl = this.context.getGl()
    gl.uniform1i(this.location, unit)
  }
}

function getError(name: string): string {
  return `Uniform location is null ${name}. You might be assigning a uniform that's not used in the shader, or your using the wrong shader/material.`
}
