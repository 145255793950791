import { Renderable } from 'application/render'
import { Rectangle } from 'application/shapes'

export type NodeRenderable = {
  key: string
  children: string[]
  opacity?: number
  renderBounds?: Rectangle
  renderables: {
    before: Renderable[]
    after: Renderable[]
  }
}

export class NodeRenderStore {
  private nodes: Record<string, NodeRenderable>

  constructor() {
    this.nodes = {}
  }

  getNode = (key: string) => {
    return this.nodes[key]
  }

  setNode = (node: NodeRenderable) => {
    this.cleanupNode(node.key)
    this.nodes[node.key] = node
  }

  deleteNode = (key: string) => {
    this.cleanupNode(key)
  }

  cleanup = () => {
    Object.keys(this.nodes).forEach((key) => this.cleanupNode(key))
  }

  private cleanupNode = (key: string) => {
    if (!this.nodes[key]) return
    const renderables = this.nodes[key].renderables
    renderables.before.forEach((renderable) => renderable.cleanup())
    renderables.after.forEach((renderable) => renderable.cleanup())
    delete this.nodes[key]
  }
}
