import { Context } from 'application/render'

export class VertexBuffer {
  private context: Context
  private buffer: WebGLBuffer
  private step: number

  constructor(context: Context, data: Float32Array, step: number) {
    this.context = context
    this.buffer = this.createBuffer(data)
    this.step = step
  }

  getBuffer = (): WebGLBuffer => this.buffer
  getStep = (): number => this.step

  cleanup = (): void => {
    const gl = this.context.getGl()
    gl.deleteBuffer(this.buffer)
  }

  private createBuffer = (data: Float32Array): WebGLBuffer => {
    const gl = this.context.getGl()
    const buffer = gl.createBuffer()

    gl.bindBuffer(gl.ARRAY_BUFFER, buffer)
    gl.bufferData(gl.ARRAY_BUFFER, data, gl.STATIC_DRAW)
    gl.bindBuffer(gl.ARRAY_BUFFER, null)

    if (!buffer) throw new Error('Could not create buffer')

    return buffer
  }
}
