import { CameraService } from 'application/camera'
import { Client } from './client'
import { ClientDependencies } from './clientDependencies'
import {
  FontLoaderInterface,
  ReadOnlyFontDataMap,
  Shaper,
} from 'application/text'

export class ClientFactory {
  create = (
    fontLoader: FontLoaderInterface,
    textShaper: Shaper,
    fontMap: ReadOnlyFontDataMap,
    cameraService: CameraService
  ): Client => {
    const clientDependencies = new ClientDependencies(
      fontLoader,
      textShaper,
      fontMap,
      cameraService
    )

    this.initFonts(clientDependencies)
    this.registerHistoryListeners(clientDependencies)
    this.registerLayoutListeners(clientDependencies)
    this.registerDocumentListeners(clientDependencies)
    this.registerNodeListeners(clientDependencies)
    this.registerSelectionListeners(clientDependencies)
    this.registerHandlers(clientDependencies)

    return clientDependencies.getClient()
  }

  private initFonts = (dependencies: ClientDependencies): void => {
    const fontLoader = dependencies.getFontLoader()
    fontLoader.init(['inter'])
  }

  private registerHistoryListeners = (
    dependencies: ClientDependencies
  ): void => {
    const historyListeners = dependencies.getHistoryListeners()

    historyListeners.registerListener(dependencies.getTransactionHandler())
    historyListeners.registerListener(
      dependencies.getDocumentHistoryActionHandler()
    )
    historyListeners.registerListener(
      dependencies.getSelectionHistoryActionHandler()
    )
    historyListeners.registerListener(dependencies.getClientRulesEngine())
    historyListeners.registerListener(dependencies.getClientLayoutEngine())
    historyListeners.registerListener(
      dependencies.getTextEditorHistoryActionHandler()
    )
    historyListeners.registerListener(dependencies.getClientTextEditor())
  }

  private registerLayoutListeners = (
    dependencies: ClientDependencies
  ): void => {
    const listeners = dependencies.getLayoutEngineListeners()

    listeners.registerListener(dependencies.getClientRulesEngine())
  }

  private registerHandlers = (dependencies: ClientDependencies): void => {
    const client = dependencies.getClient()

    client.registerHandler(dependencies.getHistoryCommandHandler())
    client.registerHandler(dependencies.getInitializeCommandHandler())
    client.registerHandler(dependencies.getNodeCommandHandler())
    client.registerHandler(dependencies.getCommitCommandHandler())
    client.registerHandler(dependencies.getSelectCommandHandler())
    client.registerHandler(dependencies.getTextEditorCommandHandler())
    client.registerHandler(dependencies.getNodeActionCommandHandler())
  }

  private registerDocumentListeners = (
    dependencies: ClientDependencies
  ): void => {
    const documentListeners = dependencies.getDocumentListeners()

    const document = dependencies.getDocument()
    document.setListener(documentListeners)

    documentListeners.registerListener(
      dependencies.getDocumentTransactionHandler()
    )
    documentListeners.registerListener(dependencies.getClientRulesEngine())
    documentListeners.registerListener(dependencies.getClientLayoutEngine())
    documentListeners.registerListener(
      dependencies.getDocumentUpdateAccumulator()
    )
  }

  private registerNodeListeners = (dependencies: ClientDependencies): void => {
    const nodeListeners = dependencies.getNodeListeners()

    nodeListeners.registerListener(dependencies.getNodeTransactionHandler())
    nodeListeners.registerListener(dependencies.getClientTextEditor())
    nodeListeners.registerListener(dependencies.getClientRulesEngine())
    nodeListeners.registerListener(dependencies.getClientLayoutEngine())
    nodeListeners.registerListener(dependencies.getNodeUpdateAccumulator())
    nodeListeners.registerListener(dependencies.getBreakpointEngine())
  }

  private registerSelectionListeners = (
    dependencies: ClientDependencies
  ): void => {
    const selectionListeners = dependencies.getSelectionListeners()

    selectionListeners.registerListener(
      dependencies.getSelectionUpdateAccumulator()
    )
    selectionListeners.registerListener(
      dependencies.getSelectionTransactionHandler()
    )
  }
}
