import { Point } from 'application/shapes'
import { ArrowMode, TextCommand, MouseMode, SelectMode } from './types'

export interface CommandParser {
  onMouse: (mode: MouseMode, point: Point) => TextCommand
  onKey: (key: string, shift: boolean, meta: boolean) => TextCommand
}

export class TextEditorCommandParser {
  private mouseDownPoint: Point | null
  private mouseDownCount: number
  private mouseDownTime: number

  constructor() {
    this.mouseDownPoint = null
    this.mouseDownCount = 0
    this.mouseDownTime = 0
  }

  onMouse = (mode: MouseMode, point: Point): TextCommand => {
    switch (mode) {
      case 'down':
        if (this.inMultiClickTime()) {
          this.mouseDownCount = Math.max((this.mouseDownCount + 1) % 5, 1)
          this.mouseDownTime = Date.now()
        } else {
          this.mouseDownCount = 1
          this.mouseDownTime = Date.now()
        }
        this.mouseDownPoint = point
        return {
          type: 'select',
          parameters: {
            mode: this.getSelectionMode(this.mouseDownCount),
            initial: point,
            current: point,
          },
        }
      case 'move':
        if (!this.mouseDownPoint) {
          return {
            type: 'none',
            parameters: {},
          }
        }
        const initialPoint = this.mouseDownPoint
        return {
          type: 'select',
          parameters: {
            mode: 'range',
            initial: initialPoint,
            current: point,
          },
        }
      case 'up':
        this.mouseDownPoint = null
        return {
          type: 'none',
          parameters: {},
        }
    }
  }

  onKey = (key: string, shift: boolean, meta: boolean): TextCommand => {
    switch (key) {
      case 'ArrowUp':
      case 'ArrowDown':
      case 'ArrowRight':
      case 'ArrowLeft':
        return {
          type: 'arrow',
          parameters: {
            mode: key.replace('Arrow', '').toLowerCase() as ArrowMode,
            shift,
            meta,
          },
        }
      case 'Backspace':
        return {
          type: 'delete',
          parameters: {},
        }
      case 'Enter':
        return {
          type: 'return',
          parameters: {},
        }
      case 'Tab':
        return {
          type: 'select',
          parameters: {
            mode: 'all',
            initial: null,
            current: null,
          },
        }
      case 'Escape':
        return {
          type: 'exit',
          parameters: {},
        }
    }

    if (meta) {
      switch (key) {
        case 'c':
          return {
            type: 'copy',
            parameters: {},
          }
        case 'x':
          return {
            type: 'cut',
            parameters: {},
          }
        case 'v':
          return {
            type: 'paste',
            parameters: {},
          }
        case 'a':
          return {
            type: 'select',
            parameters: {
              mode: 'all',
              initial: null,
              current: null,
            },
          }
        case 'z':
          return {
            type: 'history',
            parameters: {
              mode: shift ? 'redo' : 'undo',
            },
          }
        case 'b':
          return {
            type: 'inlineStyle',
            parameters: {
              mode: 'bold',
              all: true,
            },
          }
        case 'i':
          return {
            type: 'inlineStyle',
            parameters: {
              mode: 'italic',
              all: true,
            },
          }
        case 'u':
          return {
            type: 'inlineStyle',
            parameters: {
              mode: 'underline',
              all: true,
            },
          }
        default:
          return {
            type: 'none',
            parameters: {},
          }
      }
    }

    if (key.length !== 1) {
      return {
        type: 'none',
        parameters: {},
      }
    }

    return {
      type: 'insert',
      parameters: {
        text: key,
      },
    }
  }

  private inMultiClickTime = (): boolean => {
    return this.mouseDownCount >= 1 && Date.now() - this.mouseDownTime < 300
  }

  private getSelectionMode = (count: number): SelectMode => {
    switch (count) {
      case 0:
      case 1:
        return 'range'
      case 2:
        return 'word'
      case 3:
        return 'paragraph'
      case 4:
        return 'all'
      default:
        return 'all'
    }
  }
}
