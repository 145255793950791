import CTAButton from 'components/Library/Components/Button/CTAButton/CTAButton'
import IconBlock from 'components/Library/Components/IconBlock/IconBlock/IconBlock'
import TextBlock from 'components/Library/Components/Text/TextBlock/TextBlock'
import FullScreenPopupContainer from 'components/Library/Containers/FullScreenPopup/FullScreenPopup'
import PanelContainer from 'components/Library/Containers/PanelContainer/PanelContainer'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import { UserInfo } from 'application/service'

interface PaymentPopupProps {
  upgrading: boolean
  userInfo: UserInfo

  onUpgrade: () => void
  onDowngrade: () => void
  onClose: () => void
}

export default function PaymentScreen({
  userInfo,
  onUpgrade,
  onDowngrade,
  onClose,
}: PaymentPopupProps) {
  return (
    <FullScreenPopupContainer close={() => {}}>
      <PanelContainer width={464}>
        <PanelSection width={464}>
          <PanelRow width={464}>
            <TextBlock width={'fill'} mode={'title'}>
              Upgrade Your Plan
            </TextBlock>
            <IconBlock icon={'X'} onClick={onClose} />
          </PanelRow>
        </PanelSection>
        <div style={{ display: 'flex' }}>
          <PanelSection width={232} bottom={true} right={true}>
            <PanelRow>
              <TextBlock width={'fill'} mode={'title'}>
                Free
              </TextBlock>
            </PanelRow>
            <PanelRow>
              <TextBlock width={'fill'} mode={'label2'}>
                $0 / month
              </TextBlock>
            </PanelRow>
            <PanelRow>
              <TextBlock width={'fill'} mode={'label1'}>
                <li>Edit elements & styles</li>
              </TextBlock>
            </PanelRow>
            <PanelRow>
              <TextBlock width={'fill'} mode={'label1'}>
                <li>Free templates</li>
              </TextBlock>
            </PanelRow>
            <PanelRow>
              <TextBlock width={'fill'} mode={'label1'}>
                <li>Export styles</li>
              </TextBlock>
            </PanelRow>
            <PanelRow>
              <CTAButton
                width={144}
                disabled={!userInfo.premium}
                text={userInfo.premium ? 'Cancel Plan' : 'Current Plan'}
                onClick={onDowngrade}
              />
            </PanelRow>
          </PanelSection>
          <PanelSection width={232} bottom={true}>
            <PanelRow>
              <TextBlock width={'fill'} mode={'title'}>
                Premium
              </TextBlock>
            </PanelRow>
            <PanelRow>
              <TextBlock width={'fill'} mode={'label2'}>
                $20 / month
              </TextBlock>
            </PanelRow>
            <PanelRow>
              <TextBlock width={'fill'} mode={'label1'}>
                <li>Everything in free</li>
              </TextBlock>
            </PanelRow>
            <PanelRow>
              <TextBlock width={'fill'} mode={'label1'}>
                <li>Full code export</li>
              </TextBlock>
            </PanelRow>
            <PanelRow>
              <TextBlock width={'fill'} mode={'label1'}>
                <li>'Copy' also exports code</li>
              </TextBlock>
            </PanelRow>
            <PanelRow>
              <CTAButton
                disabled={userInfo.premium}
                width={144}
                text={userInfo.premium ? 'Current Plan' : 'Upgrade'}
                onClick={onUpgrade}
              />
            </PanelRow>
          </PanelSection>
        </div>
      </PanelContainer>
    </FullScreenPopupContainer>
  )
}
