import {
  AttributeFill,
  AttributeGradient,
  AttributeImage,
  AttributeShadow,
  AttributeSizeUnit,
  AttributeTextAlign,
  AttributeType,
  StyleMap,
} from './types'
import {
  Color,
  createNewColor,
  isColorEqual,
  rgbaToHex,
} from 'application/color'
import { v4 } from 'uuid'
import { Rectangle } from 'application/shapes'
import { FontKey, FontWeight } from 'application/text'
import _ from 'lodash'
import { PLACEHOLDER_IMAGE } from './const'
import { ReadOnlyNode } from 'application/node'

export function isContainerType(type: AttributeType): boolean {
  switch (type) {
    case 'canvas':
    case 'page':
    case 'frame':
    case 'form':
    case 'button':
    case 'anchor':
      return true
    default:
      return false
  }
}

export function isContentContainerType(type: AttributeType): boolean {
  return type === 'button' || type === 'anchor'
}

export function isContentType(type: AttributeType): boolean {
  return type === 'content'
}

export function isTextType(type: AttributeType): boolean {
  return (
    type === 'paragraph' ||
    type === 'input' ||
    type === 'button' ||
    type === 'anchor'
  )
}

export function canTypeInsertInto(
  child: AttributeType,
  parent: AttributeType
): boolean {
  if (child === 'canvas') return false
  if (child === 'page') return parent === 'canvas'
  if (child === 'content') return ['button', 'anchor'].includes(parent)
  return isContainerType(parent)
}

export function isInteractable(node: ReadOnlyNode): boolean {
  return !node.getBaseAttribute('locked') && !isDisplayNone(node)
}

export function isFlexChild(node: ReadOnlyNode, parent: ReadOnlyNode): boolean {
  const auto = parent.getStyleAttribute('display') === 'flex'
  const absolute = isAbsolutePositionMode(node)
  return auto && !absolute
}

export function isAbsolutePositionMode(node: ReadOnlyNode): boolean {
  return ['absolute', 'fixed'].includes(
    node.getStyleAttribute('position.mode') || ''
  )
}

export function isDisplayNone(node: ReadOnlyNode): boolean {
  return node.getStyleAttribute('display') === 'none'
}

export function isConstrained(node: ReadOnlyNode, axis: 'w' | 'h'): boolean {
  if (!isAbsolutePositionMode(node)) return false
  switch (axis) {
    case 'w':
      const left = node.getStyleAttribute('position.left.unit')
      const right = node.getStyleAttribute('position.right.unit')
      const wAuto = node.getStyleAttribute('size.w.unit')
      return left !== undefined && right !== undefined && wAuto === undefined
    case 'h':
      const top = node.getStyleAttribute('position.top.unit')
      const bottom = node.getStyleAttribute('position.bottom.unit')
      const hAuto = node.getStyleAttribute('size.h.unit')
      return top !== undefined && bottom !== undefined && hAuto === undefined
  }
}

export function isFlex(node: ReadOnlyNode): boolean {
  return node.getStyleAttribute('display') === 'flex'
}

export function getLayoutMode(node: ReadOnlyNode): 'flex' | 'block' | 'none' {
  const display = node.getStyleAttribute('display')
  if (display === 'flex') return 'flex'
  if (display === 'block') return 'block'
  if (display === 'none') return 'none'
  return 'block'
}

export function getLayoutDirection(
  node: ReadOnlyNode
): 'row' | 'column' | 'wrap' {
  const direction = node.getStyleAttribute('flex.direction')
  if (direction === 'row') return 'row'
  if (direction === 'wrap') return 'wrap'
  return 'column'
}

export function getPadding(
  node: ReadOnlyNode,
  side: 'left' | 'right' | 'top' | 'bottom'
): number {
  const paddingUnit = node.getStyleAttribute(`padding.${side}.unit`)
  switch (paddingUnit) {
    case 'px':
      const pxValue = node.getStyleAttribute(`padding.${side}.px`)
      return pxValue || 0
    default:
      return 0
  }
}

export type TextAttributes = {
  w: number
  h: number
  'size.w.unit': AttributeSizeUnit | undefined
  'size.h.unit': AttributeSizeUnit | undefined
  'text.font.family': FontKey
  'text.font.weight': FontWeight
  'text.font.size': number
  'text.color': AttributeFill
  'text.align': AttributeTextAlign
  'text.letterSpacing': number
  'text.lineHeight': number
  'text.italic': boolean
  'text.underline': boolean
}

export function getTextAttributes(node: ReadOnlyNode): TextAttributes | null {
  const width = node.getBaseAttribute('w')
  const height = node.getBaseAttribute('h')
  const wUnit = node.getStyleAttribute('size.w.unit')
  const hUnit = node.getStyleAttribute('size.h.unit')
  const fontFamily = node.getStyleAttribute('text.font.family')
  const fontWeight = node.getStyleAttribute('text.font.weight')
  const fontSize = node.getStyleAttribute('text.font.size')
  const color = node.getStyleAttribute('text.color')
  const align = node.getStyleAttribute('text.align')
  const letterSpacing = node.getStyleAttribute('text.letterSpacing')
  const lineHeight = node.getStyleAttribute('text.lineHeight')
  const italic = node.getStyleAttribute('text.italic')
  const underline = node.getStyleAttribute('text.underline')

  if (
    width === undefined ||
    height === undefined ||
    fontFamily === undefined ||
    fontWeight === undefined ||
    fontSize === undefined ||
    align === undefined ||
    letterSpacing === undefined ||
    lineHeight === undefined ||
    italic === undefined ||
    underline === undefined ||
    color === undefined
  ) {
    return null
  }

  return {
    w: width,
    h: height,
    'size.w.unit': wUnit,
    'size.h.unit': hUnit,
    'text.font.family': fontFamily,
    'text.font.weight': fontWeight,
    'text.font.size': fontSize,
    'text.color': color,
    'text.align': align,
    'text.letterSpacing': letterSpacing,
    'text.lineHeight': lineHeight,
    'text.italic': italic,
    'text.underline': underline,
  }
}

export function createNewWhiteFill(): AttributeFill {
  return {
    type: 'color',
    color: { r: 255, g: 255, b: 255, a: 1 },
  }
}

export function createNewBlackFill(): AttributeFill {
  return {
    type: 'color',
    color: { r: 0, g: 0, b: 0, a: 1 },
  }
}

export const createNewGradientFill = (color?: Color): AttributeGradient => {
  return {
    type: 'linear',
    angle: 0,
    steps: [
      {
        position: 0,
        color: color || createNewColor(),
        key: v4().replace(/-/g, '').slice(0, 12),
      },
      {
        position: 100,
        color: createNewColor(),
        key: v4().replace(/-/g, '').slice(0, 12),
      },
    ],
  }
}

export function createNewImageFill(): AttributeImage {
  return {
    src: PLACEHOLDER_IMAGE,
    resize: 'fill',
    'originalSize.w': 200,
    'originalSize.h': 200,
  }
}

export function createFlexAttributes(
  parent: ReadOnlyNode,
  childrenAttrs: StyleMap[] = [],
  childrenRects: Rectangle[] = [],
  wrapper: boolean = false
): Partial<StyleMap> {
  const wUnit = parent.getStyleAttribute('size.w.unit')
  const hUnit = parent.getStyleAttribute('size.h.unit')
  const flexGrow = parent.getStyleAttribute('flex.grow')
  const flexShrink = parent.getStyleAttribute('flex.shrink')
  const flexAlignSelf = parent.getStyleAttribute('flex.alignSelf')

  const newFlexGrow = childrenAttrs.every((c) => c['flex.grow'] === 1)
  const newFlexShrink = childrenAttrs.every((c) => c['flex.shrink'] === 1)
  const newFlexAlignSelf = childrenAttrs.every(
    (c) => c['flex.alignSelf'] === 'stretch'
  )
  const newWAuto = wrapper ? undefined : wUnit
  const newHAuto = wrapper ? undefined : hUnit

  let direction: 'row' | 'column' = 'row'
  let gap = 0
  if (parent.getBaseAttribute('type') === 'page') {
    direction = 'column'
    gap = 0
  } else if (isVerticalOverlap(childrenRects)) {
    direction = 'column'
    gap = averageGap('h', childrenRects)
  } else {
    gap = averageGap('w', childrenRects)
  }

  return {
    display: 'flex',
    'flex.direction': direction,
    'flex.align': 'start',
    'flex.justify': 'start',
    'flex.gap': gap,
    'size.w.unit': newWAuto,
    'size.h.unit': newHAuto,
    'size.w.px': newWAuto ? parent.getBaseAttribute('w') : undefined,
    'size.h.px': newHAuto ? parent.getBaseAttribute('h') : undefined,
    'flex.grow': newFlexGrow && wrapper ? 1 : flexGrow,
    'flex.shrink': newFlexShrink && wrapper ? 1 : flexShrink,
    'flex.basis.unit': newFlexGrow && wrapper ? 'px' : undefined,
    'flex.basis.px': newFlexGrow && wrapper ? 0 : undefined,
    'flex.alignSelf': newFlexAlignSelf && wrapper ? 'stretch' : flexAlignSelf,
  }
}

function averageGap(axis: 'w' | 'h', children: Rectangle[]): number {
  if (children.length < 2) return 0

  const positionKey = axis === 'w' ? 'x' : 'y'
  const sizeKey = axis === 'w' ? 'w' : 'h'

  let totalGap = 0
  const sortedChildren = children.sort(
    (a, b) => a[positionKey] - b[positionKey]
  )

  for (let i = 0; i < sortedChildren.length - 1; i++) {
    const child = sortedChildren[i]
    const next = sortedChildren[i + 1]
    totalGap += next[positionKey] - (child[positionKey] + child[sizeKey])
  }

  return Math.max(Math.round(totalGap / (children.length - 1)), 0)
}

function isVerticalOverlap(children: Rectangle[]): boolean {
  const sortedChildren = children.sort((a, b) => a.x - b.x)

  for (let i = 0; i < sortedChildren.length - 1; i++) {
    const child = sortedChildren[i]
    const next = sortedChildren[i + 1]
    if (child.x + child.w > next.x) return true
  }

  return false
}

export function createNewShadow(): AttributeShadow {
  return {
    x: 4,
    y: 4,
    blur: 8,
    spread: 0,
    color: { ...createNewColor(), a: 0.2 },
    mode: 'outer',
  }
}

export function isFillEqual(
  fill1: AttributeFill,
  fill2: AttributeFill
): boolean {
  switch (fill1.type) {
    case 'color':
      if (!fill1.color || !fill2.color) return false
      return isColorEqual(fill1.color, fill2.color)
    case 'gradient':
      const g1 = fill1.gradient
      const g2 = fill2.gradient
      if (!g1 || !g2) return false
      if (g1.type !== g2.type) return false
      if (g1.angle !== g2.angle) return false
      for (let i = 0; i < g1.steps.length; i++) {
        if (g1.steps.length !== g2.steps.length) return false
        if (!isColorEqual(g1.steps[i].color, g2.steps[i].color)) return false
        if (g1.steps[i].position !== g2.steps[i].position) return false
      }
      return true
    case 'image':
      const i1 = fill1.image
      const i2 = fill2.image
      if (!i1 || !i2) return false
      return _.isEqual(i1, i2)
  }
}

export function getRectangle(node: ReadOnlyNode): Rectangle {
  return {
    x: node.getBaseAttribute('x'),
    y: node.getBaseAttribute('y'),
    w: node.getBaseAttribute('w'),
    h: node.getBaseAttribute('h'),
  }
}

export function getBoundingRectangle(node: ReadOnlyNode): Rectangle {
  return {
    x: node.getBaseAttribute('boundingBox.x'),
    y: node.getBaseAttribute('boundingBox.y'),
    w: node.getBaseAttribute('boundingBox.w'),
    h: node.getBaseAttribute('boundingBox.h'),
  }
}

export function getClippedRectangle(node: ReadOnlyNode): Rectangle {
  const nodeRect = getRectangle(node)
  const nodeBoundingRect = getBoundingRectangle(node)

  const maxLeft = Math.max(nodeRect.x, nodeBoundingRect.x)
  const maxTop = Math.max(nodeRect.y, nodeBoundingRect.y)
  const minBottom = Math.min(
    nodeRect.y + nodeRect.h,
    nodeBoundingRect.y + nodeBoundingRect.h
  )
  const minRight = Math.min(
    nodeRect.x + nodeRect.w,
    nodeBoundingRect.x + nodeBoundingRect.w
  )

  return {
    x: maxLeft,
    y: maxTop,
    w: minRight - maxLeft,
    h: minBottom - maxTop,
  }
}

export function gradientToCss(
  gradient: AttributeGradient,
  overrideAngle?: number
): string {
  const angle =
    overrideAngle !== undefined ? overrideAngle : (gradient.angle ?? 0)
  const cssAngle = (angle + 180) % 360

  const gradientSteps = gradient.steps
    .map((step) => `#${rgbaToHex(step.color)} ${step.position}%`)
    .join(', ')

  return `linear-gradient(${cssAngle}deg, ${gradientSteps})`
}
