import { createSelector } from '../utils'
import { AttributeTransformer } from './types'
import { ReadOnlyNode } from 'application/node'
import { HTMLDocument } from '../html/types'
import { truncate } from 'application/math'

export class TransitionStyle implements AttributeTransformer {
  transformBase = (node: ReadOnlyNode, htmlDocument: HTMLDocument): void => {
    const selector = createSelector(node.getId(), 'default')
    const style = htmlDocument.getStyles().get(selector)

    const styles = node.getDefaultSelector().styles

    const transitionMode = styles['transition.mode']
    const transitionDuration = styles['transition.duration']
    const transitionTiming = styles['transition.timing']

    if (
      transitionMode === undefined ||
      transitionMode === 'none' ||
      transitionDuration === undefined ||
      transitionTiming === undefined
    ) {
      return
    }

    style.setProperty('transition', {
      property: 'all',
      duration: truncate(transitionDuration, 2),
      timing: transitionTiming,
    })
  }
}
