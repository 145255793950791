import { Properties, Style, StyleSelector } from './types'
import { StyleImpl } from './style'

export class StyleBuilder {
  private selector: StyleSelector | null = null
  private properties: Partial<Properties> = {}

  withSelector = (selector: StyleSelector): StyleBuilder => {
    this.selector = selector
    return this
  }

  withProperties = (properties: Partial<Properties>): StyleBuilder => {
    this.properties = properties
    return this
  }

  build = (): Style => {
    if (!this.selector) throw new Error('Selector is required')
    return new StyleImpl(this.selector, this.properties)
  }
}
