import { createSelector } from '../utils'
import { AttributeTransformer } from './types'
import { ReadOnlyNode } from 'application/node'
import { HTMLDocument } from '../html/types'
import { AttributeType, StyleMap } from 'application/attributes'
import { Style } from '../css'

export class SizeStyle implements AttributeTransformer {
  transformBase = (node: ReadOnlyNode, htmlDocument: HTMLDocument): void => {
    const selector = createSelector(node.getId(), 'default')
    const style = htmlDocument.getStyles().get(selector)

    const type = node.getBaseAttribute('type')
    const styles = node.getDefaultSelector().styles

    this.addWidth(styles, styles, style)
    this.addHeight(styles, styles, style)
    this.addMinWidth(styles, styles, style, type)
    this.addMinHeight(styles, styles, style)
    this.addMaxWidth(styles, styles, style)
    this.addMaxHeight(styles, styles, style)
    this.addRatio(styles, style)
  }

  private addWidth = (
    cascadedStyles: Partial<StyleMap>,
    styles: Partial<StyleMap>,
    style: Style
  ): void => {
    switch (cascadedStyles['size.w.unit']) {
      case 'px':
        const value = styles['size.w.px']
        if (value === undefined) return
        style.setProperty('width', { unit: 'px', value: value })
        break
      case 'percent':
        const percent = styles['size.w.percent']
        if (percent === undefined) return
        style.setProperty('width', { unit: '%', value: percent })
        break
    }
  }

  private addHeight = (
    cascadedStyles: Partial<StyleMap>,
    styles: Partial<StyleMap>,
    style: Style
  ): void => {
    switch (cascadedStyles['size.h.unit']) {
      case 'px':
        const value = styles['size.h.px']
        if (value === undefined) return
        style.setProperty('height', { unit: 'px', value: value })
        break
      case 'percent':
        const percent = styles['size.h.percent']
        if (percent === undefined) return
        style.setProperty('height', { unit: '%', value: percent })
        break
    }
  }

  private addMinWidth = (
    cascadedStyles: Partial<StyleMap>,
    styles: Partial<StyleMap>,
    style: Style,
    type: AttributeType
  ): void => {
    switch (cascadedStyles['size.w.min.unit']) {
      case 'px':
        const value = styles['size.w.min.px']
        if (value === undefined) return
        style.setProperty('minWidth', { unit: 'px', value: value })
        break
      case undefined:
        switch (type) {
          case 'image':
            style.setProperty('minWidth', { unit: 'px', value: 0 })
            break
        }
    }
  }

  private addMinHeight = (
    cascadedStyles: Partial<StyleMap>,
    styles: Partial<StyleMap>,
    style: Style
  ): void => {
    switch (cascadedStyles['size.h.min.unit']) {
      case 'px':
        const value = styles['size.h.min.px']
        if (value === undefined) return
        style.setProperty('minHeight', { unit: 'px', value: value })
        break
    }
  }

  private addMaxWidth = (
    cascadedStyles: Partial<StyleMap>,
    styles: Partial<StyleMap>,
    style: Style
  ): void => {
    switch (cascadedStyles['size.w.max.unit']) {
      case 'px':
        const value = styles['size.w.max.px']
        if (value === undefined) return
        style.setProperty('maxWidth', { unit: 'px', value: value })
        break
    }
  }

  private addMaxHeight = (
    cascadedStyles: Partial<StyleMap>,
    styles: Partial<StyleMap>,
    style: Style
  ): void => {
    switch (cascadedStyles['size.h.max.unit']) {
      case 'px':
        const value = styles['size.h.max.px']
        if (value === undefined) return
        style.setProperty('maxHeight', { unit: 'px', value: value })
        break
    }
  }

  private addRatio = (
    cascadedStyles: Partial<StyleMap>,
    style: Style
  ): void => {
    const ratio = cascadedStyles['size.ratio']
    if (ratio === undefined) return

    style.setProperty('aspectRatio', ratio[0] / ratio[1])
  }
}
