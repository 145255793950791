import { Layout } from '../layout/layout'
import { Shaper } from 'application/text'
import { CommandHandler, EditorState } from '../textEditor'
import { TextCommand } from '../command/types'
import { State } from '../state/types'
import { deleteSelection } from './utils'

export class ReturnHandler implements CommandHandler {
  private state: EditorState
  private layout: Layout
  private shaper: Shaper

  constructor(state: EditorState, layout: Layout, shaper: Shaper) {
    this.state = state
    this.layout = layout
    this.shaper = shaper
  }

  handle = (command: TextCommand): void => {
    switch (command.type) {
      case 'return':
        this.handleReturn()
        break
    }
  }

  private handleReturn = (): void => {
    const state = this.state.get()

    deleteSelection(state)

    this.insertReturn(state)

    this.state.set(state)
  }

  private insertReturn = (state: State): void => {
    const { content, selection } = state
    const { focus } = selection

    const index = focus.index

    const newContent =
      content.content.slice(0, index) + '\n' + content.content.slice(index)
    const newContentStyles = content.contentStyles
      .slice(0, index)
      .concat([0])
      .concat(content.contentStyles.slice(index))

    state.content.content = newContent
    state.content.contentStyles = newContentStyles

    const shapedText = this.shaper.getShapedText(state.content, state.width)
    const newFocus = this.layout.getIndexAfter(shapedText, {
      index: focus.index,
      wrapped: focus.wrapped,
    })
    if (newFocus === null) return

    state.selection.focus = newFocus
  }
}
