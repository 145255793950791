import { TailwindClassRenderer } from '../types'
import {
  isUnitColor,
  Properties,
  UnitColor,
  unitColorToString,
} from 'application/export/css'
import { colorToTailwind } from '../utils'

export class TailwindClassColor implements TailwindClassRenderer {
  render = (properties: Partial<Properties>): string[] => {
    const classes: string[] = []

    const color = properties.color
    if (color !== undefined) {
      classes.push(...this.renderColor(color))
    }

    return classes
  }

  private renderColor = (color: UnitColor): string[] => {
    if (isUnitColor(color)) {
      const colorString = unitColorToString(color)
      const tailwindColor = colorToTailwind(colorString)
      if (tailwindColor !== null) {
        return [`text-${tailwindColor}`]
      }
      return [`text-[${colorString}]`]
    }
    return []
  }
}
