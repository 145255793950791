import { Node } from 'application/node'
import { CopiedStyles, CopyStylesSnapshot } from '../types'

export class ApplyPastedAttributes {
  apply = (node: Node, snapshot: CopyStylesSnapshot): void => {
    const { defaultSelector, selectors } = snapshot
    for (const key of Object.keys(defaultSelector.styles)) {
      const attributeKey = key as keyof CopiedStyles
      node.setStyleAttribute(
        attributeKey,
        defaultSelector.styles[attributeKey],
        'default'
      )
    }
    for (const selector of selectors) {
      for (const key of Object.keys(selector.styles)) {
        const attributeKey = key as keyof CopiedStyles
        node.setStyleAttribute(
          attributeKey,
          selector.styles[attributeKey],
          selector.name
        )
      }
    }
  }
}
