import { Style } from '../css'
import { ReadOnlyNode } from 'application/node'
import { HTMLDocument } from '../html/types'
import { createSelector, formatNodeId } from '../utils'
import { AttributeTransformer } from './types'
import { StyleMap } from 'application/attributes'
import { ReadOnlyDocument } from 'application/document'

export class PositionStyle implements AttributeTransformer {
  transformBase = (
    node: ReadOnlyNode,
    htmlDocument: HTMLDocument,
    document: ReadOnlyDocument
  ): void => {
    const selector = createSelector(node.getId(), 'default')
    const style = htmlDocument.getStyles().get(selector)

    const element = htmlDocument.getById(formatNodeId(node.getId()))
    if (!element) return

    const parent = document.getParent(node)
    if (parent && parent.getBaseAttribute('type') === 'canvas') return

    const styles = node.getDefaultSelector().styles
    this.addPositionMode(styles, style)
    this.addSide(styles, styles, style, 'top')
    this.addSide(styles, styles, style, 'left')
    this.addSide(styles, styles, style, 'bottom')
    this.addSide(styles, styles, style, 'right')
  }

  private addPositionMode(styles: Partial<StyleMap>, style: Style): void {
    const mode = styles['position.mode']
    switch (mode) {
      case 'absolute':
        style.setProperty('position', 'absolute')
        break
      case 'relative':
        style.setProperty('position', 'relative')
        break
      case 'fixed':
        style.setProperty('position', 'fixed')
        break
      case 'sticky':
        style.setProperty('position', 'sticky')
        break
    }
  }

  private addSide(
    cascadedStyles: Partial<StyleMap>,
    styles: Partial<StyleMap>,
    style: Style,
    side: 'top' | 'right' | 'bottom' | 'left'
  ): void {
    const mode = cascadedStyles[`position.${side}.unit`]
    switch (mode) {
      case 'px':
        const px = styles[`position.${side}.px`]
        if (px === undefined) return
        style.setProperty(side, { unit: 'px', value: px })
        break
      case 'percent':
        const percent = styles[`position.${side}.percent`]
        if (percent === undefined) return
        style.setProperty(side, { unit: '%', value: percent })
        break
    }
  }
}
