import { TailwindClassRenderer } from '../types'
import { Properties, UnitSize, unitSizeToString } from 'application/export/css'

export class TailwindClassInset implements TailwindClassRenderer {
  render = (properties: Partial<Properties>): string[] => {
    const top = properties.top
    const right = properties.right
    const bottom = properties.bottom
    const left = properties.left

    const classes = []

    if (top !== undefined) {
      classes.push(`top-[${this.renderUnit(top)}]`)
    }
    if (right !== undefined) {
      classes.push(`right-[${this.renderUnit(right)}]`)
    }
    if (bottom !== undefined) {
      classes.push(`bottom-[${this.renderUnit(bottom)}]`)
    }
    if (left !== undefined) {
      classes.push(`left-[${this.renderUnit(left)}]`)
    }

    return classes
  }

  private renderUnit(value: UnitSize | 'auto'): string | null {
    if (value === 'auto') return null
    return unitSizeToString(value)
  }
}
