import { FlexPositionItem } from './flexPositionItem'
import { LayoutItem } from '../layoutItem/layoutItem'
import { PositionContext } from '../context/positionContext'
import { CssFlexAlign, CssFlexDirection, CssFlexJustify } from '../types/types'
import { ContainingContext } from '../context/containingContext'

export abstract class FlexPositionLine {
  layoutItem: LayoutItem

  mainSize: number
  crossSize: number

  contentMainSize: number
  contentCrossSize: number

  align: CssFlexAlign
  justify: CssFlexJustify
  flexDirection: CssFlexDirection

  gap: number

  items: FlexPositionItem[]

  constructor(layoutItem: LayoutItem, items: FlexPositionItem[] = []) {
    this.layoutItem = layoutItem

    this.mainSize = 0
    this.crossSize = 0

    this.contentMainSize = 0
    this.contentCrossSize = 0

    this.gap = 0

    this.align = CssFlexAlign.start
    this.justify = CssFlexJustify.start
    this.flexDirection = this.layoutItem.element.css.flexDirection

    this.items = items
  }

  // Initialize the line
  abstract initialize(context: ContainingContext): void

  // Place the line in the layout
  abstract place(context: PositionContext): void
}
