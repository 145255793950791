import { useState } from 'react'
import { DesignColor } from 'themes'
import Text from 'components/Library/Text/Text'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import {
  AttributeFill,
  AttributeGradient,
  gradientToCss,
} from 'application/attributes'

interface MultistepGradientRowProps {
  fill: AttributeFill

  open: boolean
  setOpen: (open: boolean) => void

  displayRef: React.RefObject<HTMLDivElement>

  hasOverride?: boolean
}

export default function MultistepGradientRow({
  fill,
  open,
  setOpen,
  displayRef,
  hasOverride = false,
}: MultistepGradientRowProps) {
  const [hover, setHover] = useState(false)
  const { gradient } = fill

  if (!gradient) return <></>

  const background = getBackground(open)
  const boxShadow = getBoxShadow(hover)

  return (
    <PanelRow>
      <div
        ref={displayRef}
        style={{
          width: 96,
          height: 24,
          padding: '0px 8px',
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          gap: 8,
          borderRadius: 4,
          userSelect: 'none',
          background: background,
          boxShadow,
        }}
        onClick={() => setOpen(!open)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <MultistepGradientDisplaySquare gradient={gradient} />
        <Text color={DesignColor(hasOverride ? 'overrideParent' : 'text1')}>
          Gradient
        </Text>
      </div>
    </PanelRow>
  )
}

function MultistepGradientDisplaySquare({
  gradient,
}: {
  gradient: AttributeGradient
}) {
  return (
    <div
      style={{
        width: 16,
        height: 16,
        boxSizing: 'border-box',
        borderRadius: 4,
        background: gradientToCss(gradient),
        border: `1px solid ${DesignColor('panelBorder')}`,
      }}
    />
  )
}

function getBackground(open: boolean) {
  return open ? DesignColor('inputTint') : 'transparent'
}

function getBoxShadow(active: boolean) {
  if (active) return `inset 0px 0px 0px 1px ${DesignColor('inputHighlight')}`
  return 'none'
}
