import { UpdateHandler } from './update'

export class NodeUpdateAccumulator {
  private handler: UpdateHandler

  constructor(handler: UpdateHandler) {
    this.handler = handler
  }

  onBaseAttribute = (id: string): void => {
    this.handler.addUpdate({
      type: 'node_updated',
      data: {
        id: id,
        mode: 'attributes',
      },
    })
  }

  onStyleAttribute = (id: string): void => {
    this.handler.addUpdate({
      type: 'node_updated',
      data: {
        id: id,
        mode: 'attributes',
      },
    })
  }

  onActiveBreakpoint = (id: string): void => {
    this.handler.addUpdate({
      type: 'node_updated',
      data: {
        id: id,
        mode: 'selectors',
      },
    })
  }

  onActivePseudo = (id: string): void => {
    this.handler.addUpdate({
      type: 'node_updated',
      data: {
        id: id,
        mode: 'selectors',
      },
    })
  }

  onChildren = (id: string) => {
    this.handler.addUpdate({
      type: 'node_updated',
      data: {
        id: id,
        mode: 'children',
      },
    })
  }

  onParent = (id: string) => {
    this.handler.addUpdate({
      type: 'node_updated',
      data: {
        id: id,
        mode: 'parent',
      },
    })
  }
}
