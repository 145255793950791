import { DesignColor } from 'themes'
import { Color, HsbaColor, hsbaToRgba } from 'application/color'
import styled from 'styled-components'

interface ColorSliderProps {
  hsba: HsbaColor
  setHsba: (hsba: HsbaColor) => void
  updateColor: (color: Color) => void
}

export default function ColorSlider({
  hsba,
  setHsba,
  updateColor,
}: ColorSliderProps) {
  return (
    <div
      style={{
        width: 232,
        padding: '8px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'start',
        alignItems: 'center',
        gap: 8,
        borderBottom: `1px solid ${DesignColor('panelBorder')}`,
      }}
    >
      <HueSlider hsba={hsba} setHsba={setHsba} updateColor={updateColor} />
      <OpacitySlider hsba={hsba} setHsba={setHsba} updateColor={updateColor} />
    </div>
  )
}

function HueSlider({ hsba, setHsba, updateColor }: ColorSliderProps) {
  return (
    <StyledHueSlider
      type="range"
      min={0}
      max={360}
      value={hsba.h}
      onChange={(e) => {
        const newHue = Number(e.target.value)
        setHsba({ ...hsba, h: newHue })
        updateColor(hsbaToRgba({ ...hsba, h: newHue }))
      }}
    />
  )
}

function OpacitySlider({ hsba, setHsba, updateColor }: ColorSliderProps) {
  return (
    <StyledOpacitySlider
      type="range"
      min={0}
      max={100}
      value={Math.round(hsba.a * 100)}
      hue={hsba.h}
      saturation={hsba.s}
      lightness={hsba.b}
      onChange={(e) => {
        const a = Number(e.target.value) / 100
        setHsba({ ...hsba, a })
        updateColor(hsbaToRgba({ ...hsba, a }))
      }}
    />
  )
}

const StyledSlider = styled.input`
  height: 12px;
  width: 100%;

  border-radius: 10px;
  box-sizing: border-box;

  outline: none;

  -webkit-appearance: none;
  appearance: none;
  ::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 14px;
    height: 14px;
    box-sizing: border-box;
    border-radius: 50%;
    background: none;
    border: 2px solid #fff;
    cursor: pointer;
    box-shadow: 0px 0px 0px 1px #00000050;
  }

  ::-moz-range-thumb {
    width: 14px;
    height: 14px;
    box-sizing: border-box;
    border-radius: 50%;
    background: none;
    border: 2px solid #fff;
    cursor: pointer;
    box-shadow: 0px 0px 0px 1px #00000050;
  }

  ::-ms-thumb {
    width: 14px;
    height: 14px;
    box-sizing: border-box;
    border-radius: 50%;
    background: none;
    border: 2px solid #fff;
    cursor: pointer;
    box-shadow: 0px 0px 0px 1px #00000050;
  }
`

export const StyledHueSlider = styled(StyledSlider)`
  background: linear-gradient(
    to right,
    #f00 0%,
    #ff0 17%,
    #0f0 33%,
    #0ff 50%,
    #00f 67%,
    #f0f 83%,
    #f00 100%
  );
`

export const StyledOpacitySlider = styled(StyledSlider)<{
  hue: number
  saturation: number
  lightness: number
}>`
  background-image: linear-gradient(
      to right,
      hsla(
          ${(props) => props.hue},
          ${(props) => props.saturation + '%'},
          ${(props) => props.lightness + '%'},
          0
        )
        0%,
      hsla(
          ${(props) => props.hue},
          ${(props) => props.saturation + '%'},
          ${(props) => props.lightness + '%'},
          1
        )
        100%
    ),
    repeating-linear-gradient(
      45deg,
      #ccc,
      #ccc 3px,
      transparent 3px,
      transparent 6px
    );
`
