import { useRef, useState } from 'react'
import { panelBorderFull } from 'components/Library/Containers/constants'
import { DesignColor } from 'themes'
import PresetDropdownRow from './PresetDropdownRow'
import { Portal } from 'components/Pages/Editor/PortalRoot/PortalRoot'
import useClosePopup from 'hooks/ui/useClosePopup'

interface PresetDropdown {
  close: () => void

  value: number | 'Mixed' | undefined

  update: (value: number) => void
  select: (value: number) => void

  presets: number[]

  blockRef: React.RefObject<HTMLDivElement>
}

export default function PresetsDropdown({
  close,
  update,
  value,
  select,
  presets,
  blockRef,
}: PresetDropdown) {
  const initialValue = useRef<number | 'Mixed' | undefined>(value)

  const popupRef = useRef<HTMLDivElement>(null)
  useClosePopup({
    ref: popupRef,
    close: close,
  })

  const [hoveredValue, setHoveredValue] = useState<number>(-1)

  const position = getPosition(blockRef)

  const handleSelect = (value: number) => {
    select(value)
  }

  const handleMouseEnter = (value: number) => {
    setHoveredValue(value)
    update(value)
  }

  const handleMouseLeave = () => {
    setHoveredValue(-1)
  }

  return (
    <Portal>
      <div
        ref={popupRef}
        className={'popup'}
        style={{
          position: 'absolute',
          ...position,
          width: 96,
          display: 'flex',
          flexDirection: 'column',
          boxShadow: panelBorderFull,
          borderRadius: 4,
          background: DesignColor('dropdownBackground'),
          pointerEvents: 'auto',
        }}
      >
        {presets.map((v, i) => {
          return (
            <PresetDropdownRow
              key={i}
              value={v}
              selected={v === initialValue.current}
              hover={v === hoveredValue}
              onMouseEnter={() => handleMouseEnter(v)}
              onMouseLeave={handleMouseLeave}
              onClick={() => handleSelect(v)}
            />
          )
        })}
      </div>
    </Portal>
  )
}

function getPosition(blockRef: React.RefObject<HTMLDivElement>) {
  if (blockRef.current) {
    const rect = blockRef.current.getBoundingClientRect()
    return {
      top: rect.top,
      left: rect.left,
    }
  }
  return { top: 0, left: 0 }
}
