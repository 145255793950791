import ColorBlock from 'components/Library/Components/Inputs/ColorBlock/ColorBlock'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import ColorPicker from '../Sections/ColorPicker'
import NumberBlock from 'components/Library/Components/NumberBlock/NumberBlock'
import { AttributeFill } from 'application/attributes'
import { Color, rgbaToHsba } from 'application/color'
import { useCallback, useEffect, useState } from 'react'
import _ from 'lodash'
import ColorSlider from '../Sections/ColorSlider'
import ColorPresets from '../Sections/ColorPresets'

interface ColorTabProps {
  fill: AttributeFill
  updateFill: (fill: AttributeFill) => void
}

export default function ColorTab({ fill, updateFill }: ColorTabProps) {
  const [hsba, setHsba] = useState(
    rgbaToHsba(fill.color || { r: 0, g: 0, b: 0, a: 1 })
  )

  const updateColor = useCallback(
    (newColor: Color) => {
      updateFill({ ...fill, color: newColor })
    },
    [fill, updateFill]
  )

  useEffect(() => {
    if (!fill.color) return
    const newHsba = rgbaToHsba(fill.color)
    if (
      _.isEqual(newHsba, hsba) ||
      (newHsba.b === 0 && hsba.b === 0) ||
      (newHsba.b === hsba.b && newHsba.s === 0 && hsba.s === 0)
    )
      return
    if (newHsba.b === 0) newHsba.b = 0
    if (newHsba.s === 0) newHsba.h = hsba.h
    setHsba(newHsba)
  }, [fill.color, hsba, setHsba])

  const color = fill.color
  if (!color) return <></>

  return (
    <>
      <PanelSection>
        <PanelRow>
          <ColorBlock color={color} updateColor={updateColor} />
          <NumberBlock
            icon={'Opacity'}
            value={Math.round(color.a * 100)}
            setValue={(a) => updateColor({ ...color, a: a / 100 })}
            increment={(a) =>
              updateColor({
                ...color,
                a: Math.max(0, Math.min(1, color.a + a / 100)),
              })
            }
            decrement={(a) =>
              updateColor({
                ...color,
                a: Math.max(0, Math.min(1, color.a - a / 100)),
              })
            }
            max={100}
            unit={'%'}
          />
        </PanelRow>
      </PanelSection>
      <ColorPicker hsba={hsba} setHsba={setHsba} updateColor={updateColor} />
      <ColorSlider hsba={hsba} setHsba={setHsba} updateColor={updateColor} />
      <ColorPresets hsba={hsba} updateColor={updateColor} />
    </>
  )
}
