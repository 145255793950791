import { editor } from 'index'
import { useState } from 'react'
import useCommit from './useCommit'

export default function useEditInput(
  commitEnabled: boolean = true
): [boolean, (e: boolean) => void] {
  const commit = useCommit()
  const { editInput } = editor.getUI().getActionInitiator()

  const [editing, setEditing] = useState(false)

  return [
    editing,
    (e: boolean) => {
      setEditing(e)
      if (commitEnabled) editInput(e)
      if (!e && commitEnabled) commit()
    },
  ]
}
