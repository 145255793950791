import { QuadTreeNodeFilter } from 'application/action'
import { CoordinatesConversion } from 'application/camera'
import { CommandHandler } from 'application/client'
import { Point, Rectangle } from 'application/shapes'
import {
  Action,
  ActionEventResult,
  ActionHandler,
  DoneNext,
  NotDone,
} from '../types'
import { Cursor } from 'application/cursor'
import { canTypeInsertInto, isInteractable } from 'application/attributes'
import { HapticDrawingWindow } from 'editor/haptic/drawingWindow'
import { HapticChildLine } from 'editor/haptic/childLine'
import { HapticNewParentWindow } from 'editor/haptic/newParentWindow'
import { getInsertionIndex, ReadOnlyDocument } from 'application/document'
import { ChildLineComputer } from '../childLine/computer'
import { QuadTree } from 'application/quadtree'
import { isCanvasClosest } from 'application/browser'
import { TemplateData } from 'application/service'

export class DragTemplateAction implements ActionHandler {
  private commandHandler: CommandHandler
  private document: ReadOnlyDocument
  private coordinates: CoordinatesConversion
  private quadtree: QuadTree
  private hapticWindow: HapticDrawingWindow
  private hapticChildLine: HapticChildLine
  private hapticNewParent: HapticNewParentWindow
  private lineComputer: ChildLineComputer
  private templateData: TemplateData

  constructor(
    commandHandler: CommandHandler,
    document: ReadOnlyDocument,
    coordinates: CoordinatesConversion,
    quadtree: QuadTree,
    hapticWindow: HapticDrawingWindow,
    hapticChildLine: HapticChildLine,
    hapticNewParent: HapticNewParentWindow,
    lineComputer: ChildLineComputer,
    templateData: TemplateData
  ) {
    this.commandHandler = commandHandler
    this.document = document
    this.coordinates = coordinates
    this.quadtree = quadtree
    this.hapticWindow = hapticWindow
    this.hapticChildLine = hapticChildLine
    this.hapticNewParent = hapticNewParent
    this.lineComputer = lineComputer
    this.templateData = templateData
  }

  getType = (): Action => {
    return 'dragTemplate'
  }

  getCursor = (): Cursor => {
    return 'grabbing'
  }

  onMouseMove = (e: MouseEvent): ActionEventResult => {
    if (!isCanvasClosest(e)) {
      this.clearHaptics()
      return NotDone
    }

    const point = this.coordinates.get(e)
    this.handlePointUpdate(point)
    return NotDone
  }

  onMouseUp = (e: MouseEvent): ActionEventResult => {
    this.clearHaptics()
    if (!isCanvasClosest(e)) return DoneNext('openTemplateLibrary')

    const point = this.coordinates.get(e)
    const rectangle = this.getRectangle(point)
    const parentId = this.getParent(point)

    if (!parentId) {
      this.commandHandler.handle({
        type: 'pasteOutsideDocumentNodes',
        params: {
          ids: this.templateData.ids,
          nodes: this.templateData.nodes,
          point: rectangle,
        },
      })
    } else {
      const parent = this.document.getNode(parentId)
      if (!parent) return DoneNext('openTemplateLibrary')

      this.commandHandler.handle({
        type: 'pasteOutsideDocumentNodes',
        params: {
          ids: this.templateData.ids,
          nodes: this.templateData.nodes,
          parentId: parentId,
          index: getInsertionIndex(point, parent, this.document),
        },
      })
      this.commandHandler.handle({ type: 'commit' })
    }

    return DoneNext('openTemplateLibrary')
  }

  onWheel = (e: WheelEvent): ActionEventResult => {
    if (!isCanvasClosest(e)) {
      this.clearHaptics()
      return NotDone
    }
    const point = this.coordinates.get(e)
    this.handlePointUpdate(point)
    return NotDone
  }

  private handlePointUpdate = (point: Point) => {
    const parent = this.getParent(point)
    if (!parent) {
      this.clearNewParent()
      this.clearLine()
    } else {
      this.setNewParent(parent)
      this.setLine(parent, point)
    }
    this.setWindow(point)
  }

  private setWindow = (point: Point) => {
    const rectangle = this.getRectangle(point)
    if (!rectangle) {
      this.clearWindow()
      return
    }

    this.hapticWindow.setRectangle(rectangle, 'rectangle')
  }

  private setLine = (target: string, point: Point): void => {
    const targetNode = this.document.getNode(target)
    if (!targetNode || targetNode.getBaseAttribute('type') === 'canvas') {
      this.clearLine()
      return
    }

    const window = this.getRectangle(point)
    if (!window) {
      this.clearLine()
      return
    }

    const rectangle = this.lineComputer.compute(target, point, window)
    if (!rectangle) {
      this.clearLine()
    } else {
      this.hapticChildLine.setLine(rectangle)
    }
  }

  private setNewParent = (target: string): void => {
    this.hapticNewParent.setNewParent(target)
  }

  private clearHaptics = () => {
    this.clearNewParent()
    this.clearLine()
    this.clearWindow()
  }

  private clearWindow = () => {
    this.hapticWindow.setRectangle(null, 'rectangle')
  }

  private clearLine = (): void => {
    this.hapticChildLine.setLine(null)
  }

  private clearNewParent = (): void => {
    this.hapticNewParent.setNewParent(null)
  }

  private getParent = (point: Point): string | undefined => {
    const rectanglesAtPoint = this.quadtree.getAtPoint(
      point,
      QuadTreeNodeFilter
    )
    const filtered = this.filterParents(rectanglesAtPoint.map((r) => r.id))
    if (filtered.length === 0) return undefined
    return filtered[0]
  }

  private filterParents = (ids: string[]): string[] => {
    return ids.filter((id) => {
      const node = this.document.getNode(id)
      if (!node) return false

      return (
        isInteractable(node) &&
        node.getBaseAttribute('type') === 'page' &&
        this.templateData.ids.every((id) => {
          const templateNode = this.templateData.nodes[id]
          if (!templateNode) return false
          return canTypeInsertInto(
            templateNode.baseAttributes.type,
            node.getBaseAttribute('type')
          )
        })
      )
    })
  }

  private getRectangle = (point: Point): Rectangle => {
    const nodes = this.templateData.ids.map((id) => this.templateData.nodes[id])
    let minX = Infinity
    let minY = Infinity
    let maxX = -Infinity
    let maxY = -Infinity

    for (const node of nodes) {
      minX = Math.min(minX, node.baseAttributes.x)
      minY = Math.min(minY, node.baseAttributes.y)
      maxX = Math.max(maxX, node.baseAttributes.x + node.baseAttributes.w)
      maxY = Math.max(maxY, node.baseAttributes.y + node.baseAttributes.h)
    }

    const w = maxX - minX
    const h = maxY - minY

    return {
      x: point.x - w / 2,
      y: point.y - h / 2,
      w: w,
      h: h,
    }
  }
}
