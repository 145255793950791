import { Point, Rectangle } from './types'

export type QuadTreeRectangleMetadata = {
  [key: string]: string
}

export class QuadTreeRectangle {
  id: string
  x: number
  y: number
  w: number
  h: number
  z: number[]
  metadata: QuadTreeRectangleMetadata

  constructor(
    id: string,
    rectangle: Rectangle,
    z: number[],
    metadata: QuadTreeRectangleMetadata
  ) {
    this.id = id
    this.x = rectangle.x
    this.y = rectangle.y
    this.w = rectangle.w
    this.h = rectangle.h
    this.z = z
    this.metadata = metadata
  }

  contains = (point: Point): boolean => {
    return (
      point.x >= this.x &&
      point.x <= this.x + this.w &&
      point.y >= this.y &&
      point.y <= this.y + this.h
    )
  }

  intersects = (rectangle: Rectangle): boolean => {
    return (
      this.x + this.w >= rectangle.x &&
      this.x <= rectangle.x + rectangle.w &&
      this.y + this.h >= rectangle.y &&
      this.y <= rectangle.y + rectangle.h
    )
  }

  encapsulates = (rectangle: Rectangle): boolean => {
    return (
      this.x <= rectangle.x &&
      this.x + this.w >= rectangle.x + rectangle.w &&
      this.y <= rectangle.y &&
      this.y + this.h >= rectangle.y + rectangle.h
    )
  }
}
