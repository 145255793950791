import { LeafElement } from './elementLeaf'
import { Attributes, Element, Tag } from './types'

export class LeafElementBuilder {
  private tag: Tag | null = null
  private attributes: Partial<Attributes> = {}

  withTag = (tag: Tag): LeafElementBuilder => {
    this.tag = tag
    return this
  }

  withAttributes = (attributes: Partial<Attributes>): LeafElementBuilder => {
    this.attributes = attributes
    return this
  }

  build = (): Element => {
    if (!this.tag) throw new Error('Tag is required')
    return new LeafElement(this.tag!, this.attributes)
  }
}
