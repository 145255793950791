import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import { RightClickElementMode } from './RightClickMenu'
import RightClickRowButton from 'components/Library/Components/Button/RightClickRowButton/RightClickRowButton'
import useNodeActions from 'hooks/editor/useNodeActions'

interface RightClickMenuContentProps {
  close: () => void
  mode: RightClickElementMode
}

export default function ElementsRightClickMenu({
  close,
  mode,
}: RightClickMenuContentProps) {
  const {
    copy,
    copyAttributes,
    cut,
    paste,
    pasteAttributes,
    duplicate,
    replace,
    group,
    ungroup,
    undo,
    redo,
    flex,
    moveToBack,
    moveToFront,
  } = useNodeActions()

  return (
    <>
      <PanelSection>
        {mode !== 'none' && (
          <>
            <RightClickRowButton
              text={'Copy'}
              subtext={getHotkeyPrefix() + 'C'}
              onClick={() => {
                copy()
                close()
              }}
            />
            <RightClickRowButton
              text={'Cut'}
              subtext={getHotkeyPrefix() + 'X'}
              onClick={() => {
                cut()
                close()
              }}
            />
          </>
        )}
        <RightClickRowButton
          text={'Paste'}
          subtext={getHotkeyPrefix() + 'V'}
          onClick={() => {
            paste()
            close()
          }}
        />
        {mode !== 'none' && (
          <RightClickRowButton
            text={'Copy Styles'}
            subtext={getOptionHotkeyPrefix() + getHotkeyPrefix() + 'C'}
            onClick={() => {
              copyAttributes()
              close()
            }}
          />
        )}
        <RightClickRowButton
          text={'Paste Styles'}
          subtext={getOptionHotkeyPrefix() + getHotkeyPrefix() + 'V'}
          onClick={() => {
            pasteAttributes()
            close()
          }}
        />
        {mode !== 'none' && (
          <>
            <RightClickRowButton
              text={'Duplicate'}
              subtext={getHotkeyPrefix() + 'D'}
              onClick={() => {
                duplicate()
                close()
              }}
            />
            <RightClickRowButton
              text={'Replace'}
              subtext={getShiftHotkeyPrefix() + getHotkeyPrefix() + 'R'}
              onClick={() => {
                replace()
                close()
              }}
            />
          </>
        )}
      </PanelSection>
      <PanelSection bottom={mode === 'none'}>
        <RightClickRowButton
          text={'Undo'}
          subtext={getHotkeyPrefix() + 'Z'}
          onClick={() => {
            undo()
            close()
          }}
        />
        <RightClickRowButton
          text={'Redo'}
          subtext={getShiftHotkeyPrefix() + getHotkeyPrefix() + 'Z'}
          onClick={() => {
            redo()
            close()
          }}
        />
      </PanelSection>
      {mode !== 'none' && (
        <>
          <PanelSection>
            <RightClickRowButton
              text={'Group'}
              subtext={getHotkeyPrefix() + 'G'}
              onClick={() => {
                group()
                close()
              }}
            />
            <RightClickRowButton
              text={'Ungroup'}
              subtext={getShiftHotkeyPrefix() + getHotkeyPrefix() + 'G'}
              onClick={() => {
                ungroup()
                close()
              }}
            />
            <RightClickRowButton
              text={'Wrap in Flexbox'}
              subtext={getShiftHotkeyPrefix() + 'A'}
              onClick={() => {
                flex()
                close()
              }}
            />
          </PanelSection>
          <PanelSection bottom={true}>
            <RightClickRowButton
              text={'Bring to Front'}
              subtext={']'}
              onClick={() => {
                moveToFront()
                close()
              }}
            />
            <RightClickRowButton
              text={'Send to Back'}
              subtext={'['}
              onClick={() => {
                moveToBack()
                close()
              }}
            />
          </PanelSection>
        </>
      )}
    </>
  )
}

export const getHotkeyPrefix = () =>
  /Mac|iPad|iPhone|iPod/.test(navigator.userAgent) ? '⌘' : 'Ctrl+'

export const getShiftHotkeyPrefix = () =>
  /Mac|iPad|iPhone|iPod/.test(navigator.userAgent) ? '⇧' : 'Shift+'

export const getOptionHotkeyPrefix = () =>
  /Mac|iPad|iPhone|iPod/.test(navigator.userAgent) ? '⌥' : 'Alt+'
