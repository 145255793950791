import { Command, CommandHandler } from './types'
import { ClientTransactionHandler } from '../transaction'

export class CommitCommandHandler implements CommandHandler {
  private transaction: ClientTransactionHandler

  constructor(transaction: ClientTransactionHandler) {
    this.transaction = transaction
  }

  handle = (command: Command): void => {
    switch (command.type) {
      case 'commit':
        this.commit()
        return
    }
  }

  private commit = (): void => {
    this.transaction.commit()
  }
}
