import { Context, HapticOrder } from 'application/render'
import { ReadOnlyDocumentSelection } from 'application/selection'
import { HapticRenderStore } from './hapticRenderStore'
import { Canvas } from './canvas'
import { NodeRenderStore } from './nodeRenderStore'

export class CanvasDependencies {
  private registry: Map<string, any>
  private documentSelection: ReadOnlyDocumentSelection
  private hapticOrder: HapticOrder

  constructor(
    documentSelection: ReadOnlyDocumentSelection,
    hapticOrder: HapticOrder
  ) {
    this.registry = new Map()
    this.documentSelection = documentSelection
    this.hapticOrder = hapticOrder
  }

  getCanvas = (): Canvas => {
    return new Canvas(
      this.getContext(),
      this.documentSelection,
      this.getNodeRenderStore(),
      this.getHapticRenderStore(),
      this.hapticOrder
    )
  }

  getContext = (): Context => {
    if (!this.registry.has('context')) {
      this.registry.set('context', new Context())
    }
    return this.registry.get('context')
  }

  getNodeRenderStore = (): NodeRenderStore => {
    if (!this.registry.has('nodeRenderStore')) {
      this.registry.set('nodeRenderStore', new NodeRenderStore())
    }
    return this.registry.get('nodeRenderStore')
  }

  getHapticRenderStore = (): HapticRenderStore => {
    if (!this.registry.has('hapticRenderStore')) {
      this.registry.set('hapticRenderStore', new HapticRenderStore())
    }
    return this.registry.get('hapticRenderStore')
  }
}
