import { AttributeFill, StyleMap, isFillEqual } from 'application/attributes'
import { NodeSelectionColorsHandler } from './selectionColorsPanel'
import { ReadOnlyNode } from 'application/node'

export class FillsDefault implements NodeSelectionColorsHandler {
  get = (node: ReadOnlyNode): AttributeFill[] => {
    const fills = this.getFills(node)
    const borderFills = this.getBorderFills(node)
    return [...fills, ...borderFills]
  }

  updateFill(
    node: ReadOnlyNode,
    before: AttributeFill,
    after: AttributeFill
  ): Partial<StyleMap> {
    const fillsUpdate = this.updateFills(node, before, after)
    const borderFillsUpdate = this.updateBorderFills(node, before, after)
    return { ...fillsUpdate, ...borderFillsUpdate }
  }

  private getFills = (node: ReadOnlyNode): AttributeFill[] => {
    const fills = node.getStyleAttribute('background')
    return fills || []
  }

  private getBorderFills = (node: ReadOnlyNode): AttributeFill[] => {
    const borderColor = node.getStyleAttribute('border.color')
    if (!borderColor) return []
    return [{ type: 'color', color: borderColor }]
  }

  private updateFills = (
    node: ReadOnlyNode,
    before: AttributeFill,
    after: AttributeFill
  ): Partial<StyleMap> => {
    const fills = this.getFills(node)
    if (!fills.some((fill) => isFillEqual(fill, before))) return {}

    const updatedFills = fills.map((fill) =>
      isFillEqual(fill, before) ? after : fill
    )

    return { background: updatedFills }
  }

  private updateBorderFills = (
    node: ReadOnlyNode,
    before: AttributeFill,
    after: AttributeFill
  ): Partial<StyleMap> => {
    const borderColor = node.getStyleAttribute('border.color')
    if (!borderColor) return {}
    if (!isFillEqual({ type: 'color', color: borderColor }, before)) return {}

    return { 'border.color': after.color }
  }
}
