import { Selection } from '../types'
import { State } from '../state/types'

export function sortSelection(selection: Selection): void {
  if (selection.anchor === null) return

  const { anchor, focus } = selection
  if (anchor.index > focus.index) {
    selection.anchor = focus
    selection.focus = anchor
  }
}

export function deleteSelection(state: State): void {
  const { selection } = state
  if (selection.anchor === null) return

  sortSelection(selection)

  const start = selection.anchor.index
  const wrapped = selection.anchor.wrapped
  const end = selection.focus.index

  const newContent =
    state.content.content.slice(0, start) + state.content.content.slice(end)
  const newContentStyles = state.content.contentStyles
    .slice(0, start)
    .concat(state.content.contentStyles.slice(end))

  state.content.content = newContent
  state.content.contentStyles = newContentStyles

  selection.focus = {
    index: start,
    wrapped: wrapped,
  }
  selection.anchor = null
}
