export class Size {
  readonly width: number
  readonly height: number

  static create(width: number = 0, height: number = 0): Size {
    return new Size(width, height)
  }

  private constructor(width: number, height: number) {
    this.width = width
    this.height = height
  }
}
