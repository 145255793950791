import { FlexSizeLine } from './flexSizeLine'
import { SizeContext } from '../context/sizeContext'

export class FlexSizeLineImpl extends FlexSizeLine {
  initialize = (): void => {
    this.mainSize = 0
    this.totalGrow = 0
    this.totalShrink = 0

    for (const item of this.items) {
      this.mainSize += item.baseSize
      this.totalGrow += item.grow
      this.totalShrink += item.shrink
    }
  }

  reset = (): void => {
    this.mainSize = 0

    this.totalGap = 0
    this.totalGrow = 0
    this.totalShrink = 0

    this.items = []
  }

  applyMainAxisSize = (size: number, context: SizeContext): void => {
    this.distributeSpace(size - this.totalGap)

    for (const item of this.items) {
      item.applyMainAxisSize(item.computedSize, context)
    }
  }

  applyCrossAxisSize = (size: number, context: SizeContext): void => {
    for (const item of this.items) {
      if (!item.stretch) continue
      item.applyCrossAxisSize(item.computedSize, size, context)
    }
  }

  getMaxCrossSize = (): number => {
    let max = 0
    for (const item of this.items) {
      max = Math.max(max, item.getCrossSize())
    }
    return max
  }

  getMinContentMainSize = (): number => {
    let min = 0
    for (const item of this.items) {
      min += item.minContentMainSize
    }
    return min + this.totalGap
  }

  getMaxContentMainSize = (): number => {
    let max = 0
    for (const item of this.items) {
      max += item.maxContentMainSize
    }
    return max + this.totalGap
  }

  private distributeSpace = (space: number): void => {
    const initialFreeSpace = space - this.mainSize
    const grow = initialFreeSpace > 0
    if (grow && this.totalGrow <= 0) return
    if (!grow && this.totalShrink <= 0) return

    let processed = true
    while (processed) {
      let sumShrinkFactor = 0
      let remainingFreeSpace = space
      let totalNotFrozen = 0

      for (const item of this.items) {
        if (!item.frozen) {
          sumShrinkFactor += item.scaledFlexShrinkFactor
          totalNotFrozen++
          remainingFreeSpace -= item.baseSize
        } else {
          remainingFreeSpace -= item.computedSize
        }
      }
      if (totalNotFrozen === 0) break

      remainingFreeSpace = Math.abs(remainingFreeSpace)
      if (remainingFreeSpace === 0) {
        processed = false
      } else {
        let totalClamped = 0
        let totalGrowFactor = 0
        for (const item of this.items) {
          if (item.frozen) continue
          totalGrowFactor += item.grow
        }
        for (const item of this.items) {
          if (item.frozen) continue

          if (grow) {
            const growFactor = item.grow / totalGrowFactor
            item.computedSize = item.baseSize + remainingFreeSpace * growFactor
          } else if (item.scaledFlexShrinkFactor > 0) {
            const shrinkFactor = item.scaledFlexShrinkFactor / sumShrinkFactor
            item.computedSize =
              item.baseSize - remainingFreeSpace * shrinkFactor
          }

          if (item.computedSize < item.minSize) {
            totalClamped++
            item.computedSize = item.minSize
            item.frozen = true
          } else if (item.computedSize > item.maxSize) {
            totalClamped++
            item.computedSize = item.maxSize
            item.frozen = true
          }
        }

        if (totalClamped === 0) processed = false
      }
    }
  }
}
