import { ReadOnlyNode } from 'application/node'
import { HTMLDocument } from '../html/types'
import { createSelector } from '../utils'
import { AttributeTransformer } from './types'
import { addFlexJustify, addFlexDirection, addGap, addFlexAlign } from './utils'

export class FlexStyle implements AttributeTransformer {
  transformBase = (node: ReadOnlyNode, htmlDocument: HTMLDocument): void => {
    const selector = createSelector(node.getId(), 'default')
    const style = htmlDocument.getStyles().get(selector)

    const styles = node.getDefaultSelector().styles

    const display = styles['display']
    if (display !== 'flex') return

    const direction = styles['flex.direction']
    const main = styles['flex.align']
    const counter = styles['flex.justify']
    const gap = styles['flex.gap']
    if (
      direction === undefined ||
      main === undefined ||
      counter === undefined ||
      gap === undefined
    ) {
      return
    }

    addGap(gap, style)
    addFlexDirection(direction, style)
    addFlexAlign(main, style)
    addFlexJustify(counter, style)
  }
}
