import { BoxShadow } from '../css'
import { ReadOnlyNode } from 'application/node'
import { HTMLDocument } from '../html/types'
import { createSelector } from '../utils'
import { AttributeTransformer } from './types'

export class ShadowStyle implements AttributeTransformer {
  transformBase = (node: ReadOnlyNode, htmlDocument: HTMLDocument): void => {
    const selector = createSelector(node.getId(), 'default')
    const style = htmlDocument.getStyles().get(selector)
    const attrs = node.getDefaultSelector().styles

    const shadows = attrs['shadows']
    if (!shadows || shadows.length === 0) return

    const shadowStyles: BoxShadow[] = shadows.map((s) => {
      return {
        inset: s.mode === 'inner',
        values: [
          { unit: 'px', value: s.x },
          { unit: 'px', value: s.y },
          { unit: 'px', value: s.blur },
          { unit: 'px', value: s.spread },
        ],
        color: s.color,
      }
    })
    style.setProperty('boxShadow', shadowStyles)
  }
}
