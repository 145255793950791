import { AttributeFill } from 'application/attributes'
import ButtonBlock from 'components/Library/Components/Button/ButtonBlock/ButtonBlock'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import useActionInitiator from 'hooks/editor/useActionInitiator'
import useUploadImage, { UploadedImage } from 'hooks/editor/useUploadImage'
import { useRef } from 'react'
import ImageDisplay from '../../ImageDisplay/ImageDisplay'

interface ImageUploadProps {
  fill: AttributeFill
  updateFill: (fill: AttributeFill) => void
}

export default function ImageUpload({ fill, updateFill }: ImageUploadProps) {
  const { openLibrary } = useActionInitiator()

  const inputFile = useRef<HTMLInputElement>(null)
  const uploadImage = useUploadImage()

  if (!fill.image) return <></>

  const handleChangeEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    const callback = async (uploadedImage: UploadedImage) => {
      if (!fill.image) return
      updateFill({
        ...fill,
        image: {
          resize: fill.image.resize,
          src: uploadedImage.url,
          'originalSize.w': uploadedImage.size.w,
          'originalSize.h': uploadedImage.size.h,
        },
      })
    }
    uploadImage(callback, event, undefined)
  }

  const handleDropEvent = (e: React.DragEvent<HTMLDivElement>) => {
    uploadImage(
      async (uploadedImage: UploadedImage) => {
        if (!fill.image) return
        updateFill({
          ...fill,
          image: {
            resize: fill.image.resize,
            src: uploadedImage.url,
            'originalSize.w': uploadedImage.size.w,
            'originalSize.h': uploadedImage.size.h,
          },
        })
      },
      undefined,
      e
    )
  }

  return (
    <>
      <PanelSection bottom={fill.image.src === ''}>
        <PanelRow>
          <input
            type="file"
            accept=".png,.webp,.jpg,.jpeg,.gif,.svg,.avif"
            id="file"
            ref={inputFile}
            style={{ display: 'none' }}
            onChange={handleChangeEvent}
          />
          <ButtonBlock
            width={96}
            icon="Upload"
            text={'Upload'}
            onClick={() => inputFile.current?.click()}
          />
          <ButtonBlock
            width={120}
            icon="Image"
            text={'Image Library'}
            onClick={() => openLibrary('openImageLibrary')}
          />
        </PanelRow>
      </PanelSection>
      <ImageDisplay src={fill.image.src} onDrop={handleDropEvent} />
    </>
  )
}
