import { Camera, CameraService, CameraUpdateListener } from 'application/camera'
import { ClientUpdateListener, Update } from 'application/client'
import { ReadOnlyDocument } from 'application/document'
import { ReadOnlyNode } from 'application/node'
import {
  ReadOnlyDocumentSelection,
  computeSelectionRectangle,
} from 'application/selection'

export class EditorCameraService
  implements ClientUpdateListener, CameraUpdateListener
{
  private document: ReadOnlyDocument
  private documentSelection: ReadOnlyDocumentSelection
  private cameraService: CameraService

  private canvasCameraState: { [key: string]: Camera }

  constructor(
    document: ReadOnlyDocument,
    documentSelection: ReadOnlyDocumentSelection,
    cameraService: CameraService
  ) {
    this.document = document
    this.documentSelection = documentSelection
    this.cameraService = cameraService

    this.canvasCameraState = {}
  }

  getTypes = (): Update['type'][] => {
    return ['initialize', 'selected_canvas']
  }

  onUpdate = (updates: Update[]) => {
    for (const update of updates) {
      switch (update.type) {
        case 'initialize':
          this.handleInit()
          break
        case 'selected_canvas':
          this.handleCanvasSelected(update.data.id)
          break
      }
    }
  }

  onCamera = (camera: Camera) => {
    const canvasId = this.documentSelection.getSelectedCanvas()
    if (!canvasId) return

    this.canvasCameraState[canvasId] = camera
  }

  private handleInit = () => {
    const canvasId = this.documentSelection.getSelectedCanvas()
    if (!canvasId) {
      this.setToDefaultZoom()
      return
    }

    const window = this.computeCanvasWindow(canvasId)
    if (!window) {
      this.setToDefaultZoom()
      return
    }

    this.cameraService.centerOnRectangle(window)
  }

  private handleCanvasSelected = (canvasId: string) => {
    if (!this.canvasCameraState[canvasId]) {
      const window = this.computeCanvasWindow(canvasId)
      if (!window) return
      this.cameraService.centerOnRectangle(window)
    } else {
      const camera = this.canvasCameraState[canvasId]
      this.cameraService.setCamera(camera)
    }
  }

  private computeCanvasWindow = (canvasId: string) => {
    const canvas = this.document.getNode(canvasId)
    if (!canvas) return

    const childrenIds = canvas.getChildren()
    if (!childrenIds) return

    const children = childrenIds
      .map((id) => this.document.getNode(id))
      .filter((n) => n) as ReadOnlyNode[]
    if (!children) return

    return computeSelectionRectangle(children)
  }

  private setToDefaultZoom = () => {
    this.cameraService.zoomToValue(0.5)
  }
}
