import { isCanvasClosest, WheelEventListener } from 'application/browser'
import { CameraService } from 'application/camera'
import { MouseDeviceState } from 'application/mouse'

export class CameraBrowserEventHandler implements WheelEventListener {
  private mouseDeviceState: MouseDeviceState
  private cameraService: CameraService

  constructor(
    mouseDeviceState: MouseDeviceState,
    cameraService: CameraService
  ) {
    this.mouseDeviceState = mouseDeviceState
    this.cameraService = cameraService
  }

  handleWheel = (e: WheelEvent) => {
    if (e.ctrlKey) e.preventDefault()
    if (!isCanvasClosest(e)) return
    e.preventDefault()

    this.mouseDeviceState.updateMouseTypeFromWheel(e)
    const isTouchpad = this.mouseDeviceState.getMouseDevice() === 'touchpad'

    if (e.metaKey || e.ctrlKey || !isTouchpad) {
      this.cameraService.zoomCamera(e)
    } else if (isTouchpad) {
      this.cameraService.panCamera(e, e.shiftKey ? 'horizontal' : 'vertical')
    }
  }
}
