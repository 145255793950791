import { AttributeType, MultiselectStyleMap } from 'application/attributes'
import { StyleAttributePanel } from './styleAttributePanel'
import { ReadOnlyNode } from 'application/node'

export type RoundingPanelSide =
  | 'topLeft'
  | 'topRight'
  | 'bottomRight'
  | 'bottomLeft'
  | 'all'

export type RoundingPanelMode = 'all' | 'custom'

type RoundingPanelKeys =
  | 'border.radius.topLeft.unit'
  | 'border.radius.topRight.unit'
  | 'border.radius.bottomRight.unit'
  | 'border.radius.bottomLeft.unit'
  | 'border.radius.topLeft.px'
  | 'border.radius.topRight.px'
  | 'border.radius.bottomRight.px'
  | 'border.radius.bottomLeft.px'

type RoundingPanelAttributes = Pick<
  MultiselectStyleMap,
  RoundingPanelKeys
> | null

export interface RoundingPanelState {
  attributes: RoundingPanelAttributes
  mode: RoundingPanelMode
}

export interface RoundingPanelHandlers {
  setRounding: (value: number, mode: RoundingPanelSide) => void
  slideRounding: (value: number, mode: RoundingPanelSide) => void
  clearCustom: () => void
}

export class RoundingPanel extends StyleAttributePanel<
  RoundingPanelState,
  RoundingPanelHandlers,
  RoundingPanelKeys
> {
  getSettings(): RoundingPanelState {
    return {
      attributes: this.attributes,
      mode: this.getMode(),
    }
  }

  getHandlers(): RoundingPanelHandlers {
    return {
      setRounding: this.setRounding,
      slideRounding: this.slideRounding,
      clearCustom: this.clearCustom,
    }
  }

  private getMode = (): RoundingPanelMode => {
    const nodes = this.getNodes()
    if (nodes.length === 0) return 'all'

    for (const node of nodes) {
      const tl = node.getStyleAttribute('border.radius.topLeft.px')
      const tr = node.getStyleAttribute('border.radius.topRight.px')
      const br = node.getStyleAttribute('border.radius.bottomRight.px')
      const bl = node.getStyleAttribute('border.radius.bottomLeft.px')
      if (tl !== tr || tl !== br || tl !== bl) return 'custom'
    }

    return 'all'
  }

  private setRounding = (value: number, mode: RoundingPanelSide): void => {
    if (mode === 'all') {
      this.setMulti({
        'border.radius.topLeft.unit': 'px',
        'border.radius.topRight.unit': 'px',
        'border.radius.bottomRight.unit': 'px',
        'border.radius.bottomLeft.unit': 'px',
        'border.radius.topLeft.px': value,
        'border.radius.topRight.px': value,
        'border.radius.bottomRight.px': value,
        'border.radius.bottomLeft.px': value,
      })
    } else {
      this.setMulti({ [`border.radius.${mode}.px`]: value })
    }
  }

  private slideRounding = (value: number, mode: RoundingPanelSide): void => {
    if (mode === 'all') {
      this.setMulti({
        'border.radius.topLeft.unit': 'px',
        'border.radius.topRight.unit': 'px',
        'border.radius.bottomRight.unit': 'px',
        'border.radius.bottomLeft.unit': 'px',
      })
      this.slideMulti('border.radius.topLeft.px', value)
      this.slideMulti('border.radius.topRight.px', value)
      this.slideMulti('border.radius.bottomRight.px', value)
      this.slideMulti('border.radius.bottomLeft.px', value)
    } else {
      this.setMulti({ [`border.radius.${mode}.unit`]: 'px' })
      this.slideMulti(`border.radius.${mode}.px`, value)
    }
  }

  private clearCustom = (): void => {
    const nodes = this.getNodes()
    if (nodes.length === 0) return

    let maxRounding = 0
    for (const node of nodes) {
      const tl = node.getStyleAttribute('border.radius.topLeft.px') || 0
      const tr = node.getStyleAttribute('border.radius.topRight.px') || 0
      const br = node.getStyleAttribute('border.radius.bottomRight.px') || 0
      const bl = node.getStyleAttribute('border.radius.bottomLeft.px') || 0
      maxRounding = Math.max(maxRounding, tl, tr, br, bl)
    }

    this.setMulti({
      'border.radius.topLeft.px': maxRounding,
      'border.radius.topRight.px': maxRounding,
      'border.radius.bottomRight.px': maxRounding,
      'border.radius.bottomLeft.px': maxRounding,
    })
    this.commit()
  }

  protected override getNodeFilterPredicate = (): ((
    node: ReadOnlyNode,
    parent: ReadOnlyNode | null
  ) => boolean) => {
    return (node, _) => allowedTypes.includes(node.getBaseAttribute('type'))
  }

  protected override getSlideMin = (): number => {
    return 0
  }

  protected override getSlideMax = (): number => {
    return 1_000
  }
}

const allowedTypes: AttributeType[] = [
  'frame',
  'image',
  'input',
  'form',
  'button',
  'anchor',
]
