import { ReadOnlyNode } from 'application/node'
import { Point, Rectangle, pointInRectangle } from 'application/shapes'
import { SelectionSide } from './types'
import { WindowBubbleMode } from 'editor/haptic/types'

export function computeSelectionRectangle(
  nodes: ReadOnlyNode[]
): Rectangle | null {
  if (nodes.length === 0) return null

  let minX: number = Infinity
  let minY: number = Infinity
  let maxX: number = -Infinity
  let maxY: number = -Infinity

  for (const node of nodes) {
    const x = node.getBaseAttribute('x')
    const y = node.getBaseAttribute('y')
    const width = node.getBaseAttribute('w')
    const height = node.getBaseAttribute('h')
    minX = Math.min(minX, x)
    minY = Math.min(minY, y)
    maxX = Math.max(maxX, x + width)
    maxY = Math.max(maxY, y + height)
  }

  return {
    x: minX,
    y: minY,
    w: maxX - minX,
    h: maxY - minY,
  }
}

export function getSelectionSide(
  point: Point,
  selection: Rectangle,
  bubble: number,
  bar: number,
  bubbles: WindowBubbleMode
): SelectionSide | null {
  const { y, x, w, h } = selection

  const allowDiagonal = w >= bubble && h >= bubble
  if (!allowDiagonal) return null

  const vOffset = getSideOffset(h, bar)
  const hOffset = getSideOffset(w, bar)

  const top = { y: y - vOffset, x: x, w: w, h: bar }
  const bottom = { y: y + h - bar + vOffset, x: x, w: w, h: bar }
  const left = { y: y, x: x - hOffset, w: bar, h: h }
  const right = { y: y, x: x + w - bar + hOffset, w: bar, h: h }

  const topLeft = {
    y: y - vOffset,
    x: x - hOffset,
    w: bar,
    h: bar,
  }
  const topRight = {
    y: y - vOffset,
    x: x + w - bar + hOffset,
    w: bar,
    h: bar,
  }
  const bottomLeft = {
    y: y + h - bar + vOffset,
    x: x - hOffset,
    w: bar,
    h: bar,
  }
  const bottomRight = {
    y: y + h - bar + vOffset,
    x: x + w - bar + hOffset,
    w: bar,
    h: bar,
  }

  if (pointInRectangle(point, topLeft) && bubbles === 'all') {
    return 'top-left'
  } else if (pointInRectangle(point, topRight) && bubbles === 'all') {
    return 'top-right'
  } else if (pointInRectangle(point, bottomLeft)) {
    return 'bottom-left'
  } else if (pointInRectangle(point, bottomRight) && bubbles === 'all') {
    return 'bottom-right'
  } else if (pointInRectangle(point, top) && ['all', 'v'].includes(bubbles)) {
    return 'top'
  } else if (
    pointInRectangle(point, bottom) &&
    ['all', 'v'].includes(bubbles)
  ) {
    return 'bottom'
  } else if (pointInRectangle(point, left) && ['all', 'h'].includes(bubbles)) {
    return 'left'
  } else if (pointInRectangle(point, right) && ['all', 'h'].includes(bubbles)) {
    return 'right'
  }
  return null
}

export function getSelectionBubbleMode(
  nodes: ReadOnlyNode[]
): WindowBubbleMode {
  if (nodes.length !== 1) return 'none'

  const node = nodes[0]
  const wUnit = node.getStyleAttribute('size.w.unit')
  const hUnit = node.getStyleAttribute('size.h.unit')

  if (wUnit === 'px' && hUnit === 'px') return 'all'
  if (wUnit === 'px') return 'h'
  if (hUnit === 'px') return 'v'
  return 'none'
}

function getSideOffset(windowSize: number, barSize: number): number {
  if (windowSize > 3 * barSize) {
    return barSize / 2
  } else if (barSize > windowSize) {
    return barSize
  } else {
    const scale = barSize / windowSize
    return barSize / 2 + (barSize / 2) * scale
  }
}
