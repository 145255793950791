import { DesignColor } from 'themes'
import { AlignmentLine } from 'application/action'
import { Camera } from 'application/camera'
import { rgbaStringToRgba } from 'application/color'
import {
  Context,
  Renderable,
  WebglLine,
  WebglLineData,
} from 'application/render'

export class AlignmentLineTransformer {
  static transform = (
    context: Context,
    lines: AlignmentLine[],
    camera: Camera
  ): Renderable[] => {
    const renderableLines: Renderable[] = []

    for (const line of lines) {
      const data: WebglLineData = {
        lines: [],
        thickness: 1 / camera.z,
        color: rgbaStringToRgba(DesignColor('alignment')),
      }
      for (let i = 0; i < line.points.length; i += 2) {
        const p1 = line.points[i]
        const p2 = line.points[i + 1]
        data.lines.push({
          start: { x: p1.x, y: p1.y },
          end: { x: p2.x, y: p2.y },
        })
      }
      const renderable = new WebglLine(context, data)
      renderable.init()
      renderableLines.push(renderable)
    }

    return renderableLines
  }
}
