import { CommandHandler } from 'application/client'
import { ReadOnlyDocumentSelection } from 'application/selection'
import { CommandParser } from 'application/textEditor'
import { ActionHandler } from '../types'
import { EditTextAction } from './action'
import { CoordinatesConversion } from 'application/camera'
import { EditInputTextEditor } from './input'
import { NodeDeleteAction, NodeSelectionAction } from 'application/action'

export class EditTextActionFactory {
  private documentSelection: ReadOnlyDocumentSelection
  private selectionAction: NodeSelectionAction
  private deleteAction: NodeDeleteAction
  private commandHandler: CommandHandler
  private commandParser: CommandParser
  private coordinates: CoordinatesConversion
  private editingInput: EditInputTextEditor

  constructor(
    documentSelection: ReadOnlyDocumentSelection,
    selectionAction: NodeSelectionAction,
    deleteAction: NodeDeleteAction,
    commandHandler: CommandHandler,
    commandParser: CommandParser,
    coordinates: CoordinatesConversion,
    editingInput: EditInputTextEditor
  ) {
    this.documentSelection = documentSelection
    this.selectionAction = selectionAction
    this.deleteAction = deleteAction
    this.commandHandler = commandHandler
    this.commandParser = commandParser
    this.coordinates = coordinates
    this.editingInput = editingInput
  }

  create = (): ActionHandler => {
    this.editingInput.clear()
    return new EditTextAction(
      this.documentSelection,
      this.selectionAction,
      this.deleteAction,
      this.commandHandler,
      this.commandParser,
      this.coordinates,
      this.editingInput
    )
  }
}
