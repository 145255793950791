import {
  AlignmentLinesCalculator,
  PointAlignmentCalculator,
  ProportionMap,
} from 'application/action'
import { NodeResizeAction } from 'application/action/resize'
import { CoordinatesConversion } from 'application/camera'
import { CommandHandler } from 'application/client'
import { ReadOnlyDocumentSelection, SelectionSide } from 'application/selection'
import { Point, Rectangle } from 'application/shapes'
import { ActionHandler } from '../types'
import { ResizeAction } from './action'
import { HapticAlignment } from 'editor/haptic/alignment'
import { ReadOnlyDocument } from 'application/document'

export class ResizeActionFactory {
  private document: ReadOnlyDocument
  private documentSelection: ReadOnlyDocumentSelection
  private commandHandler: CommandHandler
  private resize: NodeResizeAction
  private coordinates: CoordinatesConversion
  private alignmentLinesCalculator: AlignmentLinesCalculator
  private align: PointAlignmentCalculator
  private hapticAlignment: HapticAlignment

  constructor(
    document: ReadOnlyDocument,
    documentSelection: ReadOnlyDocumentSelection,
    commandHandler: CommandHandler,
    resize: NodeResizeAction,
    coordinates: CoordinatesConversion,
    alignmentLinesCalculator: AlignmentLinesCalculator,
    align: PointAlignmentCalculator,

    hapticAlignment: HapticAlignment
  ) {
    this.document = document
    this.documentSelection = documentSelection
    this.commandHandler = commandHandler
    this.resize = resize
    this.coordinates = coordinates
    this.alignmentLinesCalculator = alignmentLinesCalculator
    this.align = align

    this.hapticAlignment = hapticAlignment
  }

  create = (side: SelectionSide, point: Point): ActionHandler => {
    return new ResizeAction(
      this.document,
      this.documentSelection,
      this.commandHandler,
      this.resize,
      this.coordinates,
      this.alignmentLinesCalculator.create(),
      this.align,
      this.hapticAlignment,
      side,
      point,
      this.initProportionMap(),
      this.initWindow()
    )
  }

  private initProportionMap = (): ProportionMap => {
    const nodes = this.documentSelection.getSelected()
    const proportions: ProportionMap = {}

    const window = this.documentSelection.getSelectionRectangle()
    if (!window) return proportions

    for (const node of nodes) {
      proportions[node.getId()] = {
        x: (node.getBaseAttribute('x') - window.x) / window.w,
        y: (node.getBaseAttribute('y') - window.y) / window.h,
        w: node.getBaseAttribute('w') / window.w,
        h: node.getBaseAttribute('h') / window.h,
      }
    }

    return proportions
  }

  private initWindow = (): Rectangle => {
    const window = this.documentSelection.getSelectionRectangle()
    if (!window) return { x: 0, y: 0, w: 0, h: 0 }

    return window
  }
}
