import {
  AlignmentGap,
  AlignmentLine,
  AlignmentLinesCalculator,
  NodeMoveAction,
  NodeReparentAction,
  OffsetMap,
  ParentMap,
} from 'application/action'
import { CoordinatesConversion } from 'application/camera'
import { CommandHandler } from 'application/client'
import { Point, Rectangle } from 'application/shapes'
import { Action, ActionEventResult, ActionHandler } from '../types'
import { ReadOnlyDocumentSelection } from 'application/selection'
import { HapticChildLine } from 'editor/haptic/childLine'
import { ChildLineComputer } from '../childLine/computer'
import { PointAlignmentCalculator } from 'application/action/alignment/point'
import { HapticAlignment } from 'editor/haptic/alignment'
import { truncate } from 'application/math'
import { HapticNewParentWindow } from 'editor/haptic/newParentWindow'
import { AlignmentGapsCalculator } from 'application/action/alignment/gaps'
import { ReadOnlyDocument } from 'application/document'
import {
  BaseMap,
  StyleMap,
  getRectangle,
  isAbsolutePositionMode,
} from 'application/attributes'
import { ReadOnlyNode } from 'application/node'
import { NodeAttributesAction } from 'application/action/attributes'
import {
  getAdjustedBottom,
  getAdjustedHeight,
  getAdjustedLeft,
  getAdjustedPositionModes,
  getAdjustedRight,
  getAdjustedSizeModes,
  getAdjustedTop,
  getAdjustedWidth,
} from 'application/units'
import { HapticChildWindows } from 'editor/haptic/childWindows'

export type OriginalAttributeMap = {
  [key: string]: {
    base: Partial<BaseMap>
    style: Partial<StyleMap>
  }
}

export class MoveAction implements ActionHandler {
  private document: ReadOnlyDocument
  private documentSelection: ReadOnlyDocumentSelection
  private commandHandler: CommandHandler
  private move: NodeMoveAction
  private reparent: NodeReparentAction
  private attributes: NodeAttributesAction
  private coordinates: CoordinatesConversion
  private alignmentCalculator: PointAlignmentCalculator
  private linesCalculator: AlignmentLinesCalculator
  private gapsCalculator: AlignmentGapsCalculator
  private lines: AlignmentLine[]
  private gaps: AlignmentGap[]

  private hapticAlignment: HapticAlignment
  private hapticChildWindows: HapticChildWindows
  private hapticChildLine: HapticChildLine
  private hapticNewParentWindow: HapticNewParentWindow
  private lineComputer: ChildLineComputer

  private offsetMap: OffsetMap
  private parentMap: ParentMap
  private offsetWindow: Rectangle
  private allowedIds: string[]
  private filteredIds: string[]

  constructor(
    document: ReadOnlyDocument,
    documentSelection: ReadOnlyDocumentSelection,
    commandHandler: CommandHandler,
    move: NodeMoveAction,
    reparent: NodeReparentAction,
    attributes: NodeAttributesAction,
    coordinates: CoordinatesConversion,
    alignmentCalculator: PointAlignmentCalculator,
    linesCalculator: AlignmentLinesCalculator,
    gapsCalculator: AlignmentGapsCalculator,

    handleAlignment: HapticAlignment,
    hapticChildWindows: HapticChildWindows,
    hapticLine: HapticChildLine,
    hapticNewParent: HapticNewParentWindow,
    lineComputer: ChildLineComputer,

    offsetMap: OffsetMap,
    parentMap: ParentMap,
    offsetWindow: Rectangle,
    allowedIds: string[],
    filteredIds: string[]
  ) {
    this.document = document
    this.documentSelection = documentSelection
    this.commandHandler = commandHandler
    this.move = move
    this.reparent = reparent
    this.attributes = attributes
    this.coordinates = coordinates
    this.alignmentCalculator = alignmentCalculator
    this.linesCalculator = linesCalculator
    this.gapsCalculator = gapsCalculator
    this.lines = linesCalculator.create()
    this.gaps = gapsCalculator.create()

    this.hapticAlignment = handleAlignment
    this.hapticChildWindows = hapticChildWindows
    this.hapticChildLine = hapticLine
    this.hapticNewParentWindow = hapticNewParent
    this.lineComputer = lineComputer

    this.offsetMap = offsetMap
    this.parentMap = parentMap
    this.offsetWindow = offsetWindow
    this.allowedIds = allowedIds
    this.filteredIds = filteredIds
  }

  getType = (): Action => {
    return 'move'
  }

  onMouseMove = (e: MouseEvent): ActionEventResult => {
    const ids = this.getSelectedIds()
    const point = this.coordinates.get(e)
    const aligned = this.alignPoint(point)

    const target = this.reparent.reparentAtPoint(
      ids,
      point,
      this.allowedIds,
      this.filteredIds,
      this.parentMap,
      false
    )

    this.setChildrenWindows(target, point)

    if (target) {
      this.setLine(target, point)
      this.setNewParent(target)
      this.recomputeLines()
      this.recomputeGaps()
    }

    this.move.move(ids, aligned, this.offsetMap)

    return { done: false, passthrough: false }
  }

  onMouseUp = (e: MouseEvent): ActionEventResult => {
    const ids = this.getSelectedIds()
    const point = this.coordinates.get(e)
    const aligned = this.alignPoint(point)

    this.reparent.reparentAtPoint(
      ids,
      point,
      this.allowedIds,
      this.filteredIds,
      this.parentMap,
      true
    )

    this.move.move(ids, aligned, this.offsetMap)

    this.resetAttributes()

    this.commandHandler.handle({ type: 'commit' })

    this.clearChildrenWindows()
    this.clearLine()
    this.clearAlignment()

    return { done: true, passthrough: false }
  }

  private resetAttributes = (): void => {
    const filtered = this.documentSelection
      .getSelected()
      .filter((n) => n.getParent() !== this.parentMap[n.getId()])

    for (const node of filtered) {
      const parent = this.document.getParent(node)
      if (!parent) continue

      const x = node.getBaseAttribute('x')
      const y = node.getBaseAttribute('y')
      const w = node.getBaseAttribute('w')
      const h = node.getBaseAttribute('h')

      const adjustedPositionModes = getAdjustedPositionModes(node)
      const adjustedTop = getAdjustedTop(node, this.document, y)
      const adjustedLeft = getAdjustedLeft(node, this.document, x)
      const adjustedRight = getAdjustedRight(node, this.document, x + w)
      const adjustedBottom = getAdjustedBottom(node, this.document, y + h)
      const adjustedSizeModes = getAdjustedSizeModes(node)
      const adjustedWidth = getAdjustedWidth(node, this.document, w, false)
      const adjustedHeight = getAdjustedHeight(node, this.document, h, false)
      const style: Partial<StyleMap> = {
        ...adjustedPositionModes,
        ...adjustedTop,
        ...adjustedLeft,
        ...adjustedBottom,
        ...adjustedRight,
        ...adjustedSizeModes,
        ...adjustedWidth,
        ...adjustedHeight,
      }

      this.attributes.setStyleAttributes([node.getId()], style, 'default')
      this.attributes.resetAttributes([node.getId()], resetKeys)
    }
  }

  private getSelectedIds = (): string[] => {
    return this.documentSelection.getSelected().map((node) => node.getId())
  }

  private alignPoint = (point: Point): Point => {
    const truncatedPoint = {
      x: truncate(point.x, 0),
      y: truncate(point.y, 0),
    }

    if (!this.allAbsolute()) {
      this.clearAlignment()
      return truncatedPoint
    }

    const window = {
      x: this.offsetWindow.x + truncatedPoint.x,
      y: this.offsetWindow.y + truncatedPoint.y,
      w: this.offsetWindow.w,
      h: this.offsetWindow.h,
    }

    const magnet = this.coordinates.getZoomAdjusted(8)
    const aligned = this.alignmentCalculator.alignMove(
      truncatedPoint,
      window,
      this.lines,
      this.gaps,
      magnet
    )

    this.hapticAlignment.setLines(aligned.lines)
    this.hapticAlignment.setGaps(aligned.gaps)

    return aligned.point
  }

  private setChildrenWindows = (
    target: string | undefined,
    point: Point
  ): void => {
    const moving: Rectangle[] = []
    const highlight: Rectangle[] = []
    const nodes = this.documentSelection.getSelected()
    for (const node of nodes) {
      if (this.isAbsolute(node)) continue

      const rectangle = getRectangle(node)
      const offsetRectangle = {
        x: point.x + this.offsetMap[node.getId()].x,
        y: point.y + this.offsetMap[node.getId()].y,
        w: rectangle.w,
        h: rectangle.h,
      }

      moving.push(offsetRectangle)
      if (!target) highlight.push(rectangle)
    }

    this.hapticChildWindows.setRectangles(moving, highlight)
  }

  private isAbsolute = (node: ReadOnlyNode): boolean => {
    const parent = this.document.getParent(node)
    if (!parent) return false

    const originalParent = this.parentMap[node.getId()]
    return (
      (isAbsolutePositionMode(node) &&
        (parent.getBaseAttribute('type') !== 'page' ||
          parent.getId() === originalParent)) ||
      parent.getBaseAttribute('type') === 'canvas'
    )
  }

  private setLine = (target: string, point: Point): void => {
    const targetNode = this.document.getNode(target)
    if (!targetNode || targetNode.getBaseAttribute('type') === 'canvas') {
      this.clearLine()
      return
    }

    const nodes = this.documentSelection.getSelected()
    const window = this.documentSelection.getSelectionRectangle()
    const hasParent = nodes.some((node) => node.getParent())
    if (hasParent || !window) {
      this.clearLine()
      return
    }

    const rectangle = this.lineComputer.compute(target, point, window)
    if (!rectangle) {
      this.clearLine()
    } else {
      this.hapticChildLine.setLine(rectangle)
    }
  }

  private setNewParent = (target: string | undefined): void => {
    if (!target) return
    this.hapticNewParentWindow.setNewParent(target)
  }

  private recomputeLines = (): void => {
    this.lines = this.linesCalculator.create()
  }

  private recomputeGaps = (): void => {
    this.gaps = this.gapsCalculator.create()
  }

  private clearChildrenWindows = (): void => {
    this.hapticChildWindows.setRectangles([], [])
  }

  private clearLine = (): void => {
    this.hapticChildLine.setLine(null)
  }

  private clearAlignment = (): void => {
    this.hapticAlignment.setLines([])
    this.hapticAlignment.setGaps([])
  }

  private allAbsolute = (): boolean => {
    const nodes = this.documentSelection.getSelected()
    for (const node of nodes) {
      if (!this.isAbsolute(node)) return false
    }
    return true
  }
}

const resetKeys: (keyof StyleMap)[] = [
  'position.top.px',
  'position.right.px',
  'position.bottom.px',
  'position.left.px',
  'position.top.unit',
  'position.right.unit',
  'position.bottom.unit',
  'position.left.unit',
  'position.top.percent',
  'position.right.percent',
  'position.left.percent',
  'position.bottom.percent',
  'position.mode',
  'size.w.unit',
  'size.h.unit',
  'size.w.percent',
  'size.h.percent',
  'size.w.px',
  'size.h.px',
  'size.w.percent',
  'size.h.percent',
  'size.ratio',
]
