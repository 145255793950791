import { ObjectPool } from '../pool/objectPool'
import { SizeContext } from './sizeContext'

export class SizeContextPool extends ObjectPool<SizeContext> {
  constructor(size: number) {
    super(SizeContext, size)
  }

  fromContext = (context: SizeContext): SizeContext => {
    const sizeContext = this.get()
    sizeContext.vw = context.vw
    sizeContext.vh = context.vh
    sizeContext.width.type = context.width.type
    sizeContext.width.value = context.width.value
    sizeContext.height.type = context.height.type
    sizeContext.height.value = context.height.value
    return sizeContext
  }
}
