import { Camera } from 'application/camera'
import {
  Context,
  Renderable,
  WebglRectangle,
  WebglRectangleData,
} from 'application/render'
import { Rectangle } from 'application/shapes'
import { Cursor, Haptics } from 'application/textEditor'

export class TextEditorTransformer {
  static transform = (
    context: Context,
    haptic: Haptics,
    window: Rectangle,
    camera: Camera,
    cursorHidden: boolean
  ): Renderable[] => {
    const { cursor, selection } = haptic

    const renderables: Renderable[] = []

    if (cursor && !cursorHidden) {
      const renderable = this.transformCursor(context, cursor, window, camera)
      renderables.push(renderable)
    }

    if (selection) {
      for (const rectangle of selection) {
        const renderable = this.transformSelection(context, rectangle, window)
        renderables.push(renderable)
      }
    }

    return renderables
  }

  private static transformCursor = (
    context: Context,
    cursor: Cursor,
    window: Rectangle,
    camera: Camera
  ): WebglRectangle => {
    const data: WebglRectangleData = {
      x: cursor.rectangle.x + window.x,
      y: cursor.rectangle.y + window.y,
      w: cursor.rectangle.w,
      h: cursor.rectangle.h,
      fill: { type: 'color', color: cursor.color },
      bb: {
        x: cursor.rectangle.x + window.x,
        y: cursor.rectangle.y + window.y,
        w: cursor.rectangle.w / camera.z,
        h: cursor.rectangle.h,
      },
    }

    const renderable = new WebglRectangle(context, data)
    renderable.init()
    return renderable
  }

  private static transformSelection = (
    context: Context,
    rectangle: Rectangle,
    window: Rectangle
  ): WebglRectangle => {
    const data: WebglRectangleData = {
      x: rectangle.x + window.x,
      y: rectangle.y + window.y,
      w: rectangle.w,
      h: rectangle.h,
      fill: { type: 'color', color: { r: 0, g: 173, b: 248, a: 0.2 } },
      bb: {
        x: rectangle.x + window.x,
        y: rectangle.y + window.y,
        w: rectangle.w,
        h: rectangle.h,
      },
    }

    const renderable = new WebglRectangle(context, data)
    renderable.init()
    return renderable
  }
}
