import { DesignColor } from 'themes'

interface PageBackgroundProps {
  children?: React.ReactNode
}

export default function OnboardingBackground({
  children,
}: PageBackgroundProps) {
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        backgroundColor: DesignColor('menuBackground'),
        overflow: 'hidden',
      }}
    >
      {children}
    </div>
  )
}
