export const isPointInBox = `
bool insideCorner(vec2 point, vec2 cornerPos, float radius) {
  vec2 distanceVec = abs(point - cornerPos);
  return all(lessThan(distanceVec, vec2(radius)));
}

bool isPointInBox(vec2 point, vec4 box, vec4 rounding) {
  float x = box.x;
  float y = box.y;
  float w = box.z;
  float h = box.w;
  
  bool isInsideBox = 
    point.x > x && point.x < x + w &&
    point.y > y && point.y < y + h;

  if (!isInsideBox) {
    return false;
  }

  if (all(equal(rounding, vec4(0.0)))) {
    return true;
  }

  vec2 tl = vec2(x, y);
  vec2 tr = vec2(x + w, y);
  vec2 br = vec2(x + w, y + h);
  vec2 bl = vec2(x, y + h);

  if (
    insideCorner(point, tl, rounding.x) && length(tl + vec2(rounding.x) - point) > rounding.x ||
    insideCorner(point, tr, rounding.y) && length(tr + vec2(-rounding.y, rounding.y) - point) > rounding.y ||
    insideCorner(point, br, rounding.z) && length(br + vec2(-rounding.z) - point) > rounding.z ||
    insideCorner(point, bl, rounding.w) && length(bl + vec2(rounding.w, -rounding.w) - point) > rounding.w
  ) {
    return false;
  }
  
  return true; 
}
`

export const isPointInEllipse = `
bool isPointInEllipse(vec2 point, vec4 ellipseRect) {
  float x = point.x - ellipseRect.x - ellipseRect.z / 2.0;
  float y = point.y - ellipseRect.y - ellipseRect.w / 2.0;
  float a = ellipseRect.z / 2.0;
  float b = ellipseRect.w / 2.0;
  return (x * x) / (a * a) + (y * y) / (b * b) < 1.0;
}
`
