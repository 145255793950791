import { DesignColor } from 'themes'
import { Point, Rectangle } from 'application/shapes'
import { Context } from 'application/render'
import { WebglLine } from 'application/render/renderables/webglLine'
import { rgbaStringToRgba } from 'application/color'
import { Camera } from 'application/camera'

const SPACING = 200
const ZOOM_LEVEL = 0.4

export class GridlinesTransformer {
  static transform(
    context: Context,
    camera: Camera,
    canvasSize: Rectangle
  ): WebglLine[] {
    const opacityMultiplier = Math.min(1, camera.z / ZOOM_LEVEL)
    const opacity = 100 * opacityMultiplier

    const lines: { start: Point; end: Point }[] = []
    const pixelWidth = canvasSize.w / camera.z
    const pixelHeight = canvasSize.h / camera.z

    // Vertical lines
    for (
      let i = Math.floor(camera.x / SPACING) * SPACING;
      i <= camera.x + pixelWidth;
      i += SPACING
    ) {
      lines.push({
        start: { x: i, y: -camera.y },
        end: { x: i, y: -camera.y + pixelHeight },
      })
    }

    // Horizontal lines
    for (
      let i = Math.floor(camera.y / SPACING) * SPACING;
      i >= camera.y - pixelHeight;
      i -= SPACING
    ) {
      lines.push({
        start: { x: camera.x, y: -i },
        end: { x: camera.x + pixelWidth, y: -i },
      })
    }

    const thickness = 1 / camera.z
    const color = {
      ...rgbaStringToRgba(DesignColor('gridlines')),
      a: opacity,
    }

    const renderable = new WebglLine(context, {
      lines: lines,
      thickness,
      color,
    })
    renderable.init()

    return [renderable]
  }
}
