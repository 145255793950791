import { ReactNode, useEffect, useRef } from 'react'
import {
  atom,
  useRecoilCallback,
  useRecoilState,
  useSetRecoilState,
} from 'recoil'
import Text from 'components/Library/Text/Text'
import { Portal } from 'components/Pages/Editor/PortalRoot/PortalRoot'
import Icon from '../Icon/Icon/Icon'
import { DesignColor } from 'themes'
import { TooltipMap, tooltipDirection, tooltipKey } from 'assets/tooltips'
import { WarningMap, warningKey } from 'assets/warnings'
import useHovered from 'hooks/ui/useHovered'
import useAction from 'hooks/editor/useAction'
import { Action } from 'editor'

const tooltipModeState = atom({
  key: 'tooltipMode',
  default: false,
})

const lastTooltipHoverDateState = atom({
  key: 'lastTooltipHoverDate',
  default: new Date(),
})

interface TooltipProps {
  tooltipKey?: tooltipKey
  tooltipString?: string
  warningKey?: warningKey

  direction?: tooltipDirection
  long?: boolean
  disabled?: boolean

  children?: ReactNode
}

export default function Tooltip({
  tooltipKey,
  tooltipString,
  warningKey,
  direction = 'center',
  long = false,
  disabled = false,
  children,
}: TooltipProps) {
  const action = useAction()
  const isAllowed = isAllowedAction(action)
  const [tooltipMode, setTooltipMode] = useRecoilState(tooltipModeState)
  const setLastTooltipHoverDate = useSetRecoilState(lastTooltipHoverDateState)

  const sourceRef = useRef<HTMLDivElement>(null)
  const { hovered, setHovered } = useHovered<boolean>({ ref: sourceRef })

  const hoverTimer = useRef<NodeJS.Timeout | null>(null)
  const leaveTimer = useRef<NodeJS.Timeout | null>(null)

  const sourceRect = sourceRef.current?.getBoundingClientRect()

  const text =
    (tooltipKey && TooltipMap[tooltipKey].text) ||
    (warningKey && WarningMap[warningKey].text) ||
    tooltipString
  const subtext = (tooltipKey && TooltipMap[tooltipKey].subtext) || undefined

  const handleMouseOver = () => {
    if (disabled) return

    setLastTooltipHoverDate(new Date())
    if (tooltipMode) setHovered(true)
    clearTimers()
    hoverTimer.current = setTimeout(() => {
      setTooltipMode(true)
      setHovered(true)
    }, 500)
  }

  const handleMouseOut = () => {
    setHovered(false)
    clearTimers()
    leaveTimer.current = setTimeout(resetTooltipMode, 500)
  }

  const handleClick = () => {
    setHovered(false)
    clearTimers()
    resetTooltipMode()
  }

  const resetTooltipMode = useRecoilCallback(
    ({ snapshot, set }) =>
      async () => {
        const lastTooltipHoverDate = await snapshot.getPromise(
          lastTooltipHoverDateState
        )
        if (new Date().getTime() - lastTooltipHoverDate.getTime() > 500)
          set(tooltipModeState, false)
      },
    []
  )

  const clearTimers = () => {
    if (hoverTimer.current) clearTimeout(hoverTimer.current)
    if (leaveTimer.current) clearTimeout(leaveTimer.current)
  }

  useEffect(() => {
    if (!action) return
    if (hoverTimer.current) clearTimeout(hoverTimer.current)
    if (leaveTimer.current) clearTimeout(leaveTimer.current)
  }, [action])

  if (!text) return <>{children}</>

  return (
    <>
      <div
        ref={sourceRef}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
        onClick={handleClick}
      >
        {children}
      </div>
      {hovered && sourceRect && isAllowed && (
        <Portal>
          <TooltipWrapper
            sourceRect={sourceRect}
            direction={direction}
            width={long ? 232 : undefined}
          >
            {!long && (
              <>
                <Text color={DesignColor('inverseText1')}>{text}</Text>
                {subtext && (
                  <Text color={DesignColor('inverseText2')}>{subtext}</Text>
                )}
              </>
            )}
            {long && <LongText text={text} />}
            <div
              style={{
                position: 'absolute',
                ...getArrowPosition(sourceRect, direction),
              }}
            >
              <Icon
                icon="TooltipArrow"
                color={DesignColor('inverseBackground')}
                size={24}
              />
            </div>
          </TooltipWrapper>
        </Portal>
      )}
    </>
  )
}

function TooltipWrapper({ sourceRect, direction, children, width }: any) {
  return (
    <div
      style={{
        position: 'absolute',
        top: getTop(sourceRect),
        left: getLeft(sourceRect, direction),
        transform: getTransform(direction),
        minHeight: 24,
        width: width || 'auto',
        maxWidth: 232,
        padding: '4px 8px',
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '8px',
        background: DesignColor('inverseBackground'),
        border: `1px solid ${DesignColor('panelBorder')}`,
        borderRadius: 4,
        zIndex: 100,
      }}
    >
      {children}
    </div>
  )
}

function LongText({ text }: { text: string }) {
  return (
    <p
      style={{
        maxWidth: 216,
        fontFamily: 'Inter',
        fontWeight: 400,
        fontSize: 11,
        color: DesignColor('inverseText1'),
        cursor: 'default',
      }}
    >
      {text}
    </p>
  )
}

function getTop(sourceRect: DOMRect) {
  if (sourceRect.y + sourceRect.height + 12 > window.innerHeight) {
    return sourceRect.y - 36
  }
  return sourceRect.y + sourceRect.height + 12
}

function getLeft(sourceRect: DOMRect, direction: tooltipDirection) {
  switch (direction) {
    case 'left':
      return sourceRect.x + sourceRect.width
    case 'right':
      return sourceRect.x
    case 'center':
      return sourceRect.x + sourceRect.width / 2
  }
}

function getTransform(direction: tooltipDirection) {
  switch (direction) {
    case 'left':
      return 'translateX(-100%)'
    case 'right':
      return ''
    case 'center':
      return 'translateX(-50%)'
  }
}

function getArrowPosition(sourceRect: DOMRect, direction: tooltipDirection) {
  if (sourceRect.y + sourceRect.height + 12 > window.innerHeight) {
    switch (direction) {
      case 'left':
        return { top: '100%', right: '0px', transform: 'rotate(180deg)' }
      case 'right':
        return { top: '100%', left: '0px', transform: 'rotate(180deg)' }
      case 'center':
        return {
          top: '100%',
          left: '50%',
          transform: 'translateX(-50%) rotate(180deg)',
        }
    }
  }
  switch (direction) {
    case 'left':
      return { bottom: '100%', right: '0px' }
    case 'right':
      return { bottom: '100%', left: '0px' }
    case 'center':
      return { bottom: '100%', left: '50%', transform: 'translateX(-50%)' }
  }
}

function isAllowedAction(action: Action | null): boolean {
  switch (action) {
    case 'drawFrame':
    case 'drawPage':
    case 'drawParagraph':
    case 'drawImage':
    case null:
      return true
    default:
      return false
  }
}
