import { RulesEngine } from './engine'
import { RuleValidator } from './types'
import { SizeRulesValidator } from './validation/size'
import { SizeViolationRemediator } from './remediation/size'
import { PositionRulesValidator } from './validation/position'
import { PositionViolationRemediator } from './remediation/position'

export class RulesEngineFactory {
  create = (): RulesEngine => {
    return new RulesEngine(this.getValidators(), this.getRemediators())
  }

  private getValidators = (): RuleValidator[] => {
    return [new SizeRulesValidator(), new PositionRulesValidator()]
  }

  private getRemediators = () => {
    return [new SizeViolationRemediator(), new PositionViolationRemediator()]
  }
}
