import { ReactNode, useRef } from 'react'
import { DesignColor } from 'themes'
import { Portal } from 'components/Pages/Editor/PortalRoot/PortalRoot'
import Text from 'components/Library/Text/Text'
import CTAButton from '../Button/CTAButton/CTAButton'

interface OnboardingPopupProps {
  active: boolean
  onClose: () => void
  children?: ReactNode
}

export default function OnboardingPopup({
  active,
  onClose,
  children,
}: OnboardingPopupProps) {
  const sourceRef = useRef<HTMLDivElement>(null)
  const sourceRect = sourceRef.current?.getBoundingClientRect()

  return (
    <>
      <div ref={sourceRef}>{children}</div>
      {sourceRect && active && (
        <Portal>
          <OnboardingPopupWrapper sourceRect={sourceRect}>
            <img
              style={{
                borderRadius: 4,
              }}
              src={'/images/Onboarding.svg'}
              alt={'Onboarding'}
            />
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 8,
                width: '100%',
              }}
            >
              <Text styles={{ size: 12, weight: 'bold' }}>Design Library</Text>
              <p
                style={{
                  fontSize: 11,
                  lineHeight: '150%',
                  color: DesignColor('text2'),
                }}
              >
                {`Open this popup to place sections. It's a great place to start!`}
              </p>
              <CTAButton width={96} text={'Got it!'} onClick={onClose} />
            </div>
          </OnboardingPopupWrapper>
          <div
            style={{ position: 'absolute', ...getArrowPosition(sourceRect) }}
          >
            <div
              style={{
                top: 48,
                width: 20,
                height: 20,
                background: DesignColor('panelBackground'),
                borderLeft: `1px solid ${DesignColor('panelBorder')}`,
                borderTop: `1px solid ${DesignColor('panelBorder')}`,
              }}
            />
          </div>
        </Portal>
      )}
    </>
  )
}

function OnboardingPopupWrapper({
  sourceRect,
  children,
}: {
  sourceRect: DOMRect
  children: ReactNode
}) {
  return (
    <div
      style={{
        position: 'absolute',
        top: getTop(sourceRect),
        left: getLeft(sourceRect),
        width: 232,
        transform: getTransform(),
        padding: '16px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 16,
        background: DesignColor('panelBackground'),
        boxShadow: `inset 0px 0px 0px 1px ${DesignColor('panelBorder')}`,
        borderRadius: 8,
        pointerEvents: 'auto',
      }}
    >
      {children}
    </div>
  )
}
function getTop(sourceRect: DOMRect) {
  if (sourceRect.y + sourceRect.height + 12 > window.innerHeight) {
    return sourceRect.y - 36
  }
  return sourceRect.y + sourceRect.height + 18
}

function getLeft(sourceRect: DOMRect) {
  return sourceRect.x + sourceRect.width / 2
}

function getTransform() {
  return 'translateX(-50%)'
}

function getArrowPosition(sourceRect: DOMRect) {
  const top = getTop(sourceRect)
  const left = getLeft(sourceRect)
  return {
    top: top - 10,
    left: left,
    transform: 'translateX(-50%) rotate(45deg)',
  }
}
