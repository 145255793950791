import { DesignColor } from 'themes'
import { Color, HsbaColor, hsbaToHex, hsbaToRgba } from 'application/color'
import useEditInput from 'hooks/editor/useEditInput'
import { useCallback, useEffect, useRef, useState } from 'react'

interface ColorPickerProps {
  hsba: HsbaColor
  setHsba: (hsba: HsbaColor) => void
  updateColor: (color: Color) => void
}

export default function ColorPicker(props: ColorPickerProps) {
  const { hsba, setHsba, updateColor } = props
  const setEditing = useEditInput(true)[1]

  const squareRef = useRef<HTMLDivElement>(null)
  const [dragging, setDragging] = useState(false)

  const handleMouseDown = useCallback(
    (e: React.MouseEvent) => {
      setDragging(true)
      const newHsba = computeColor(hsba, e.nativeEvent, squareRef)
      if (!newHsba) return
      setHsba(newHsba)
      updateColor(hsbaToRgba(newHsba))
      setEditing(true)
    },
    [setEditing, hsba, setHsba, updateColor]
  )

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (!dragging) return
      const newHsba = computeColor(hsba, e, squareRef)
      if (!newHsba) return
      setHsba(newHsba)
      updateColor(hsbaToRgba(newHsba))
    },
    [dragging, hsba, setHsba, updateColor]
  )

  const handleMouseUp = useCallback(
    (e: MouseEvent) => {
      if (!dragging) return
      setDragging(false)
      setEditing(false)
    },
    [dragging, setEditing]
  )

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove)
    window.addEventListener('mouseup', handleMouseUp)
    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('mouseup', handleMouseUp)
    }
  }, [dragging, handleMouseMove, handleMouseUp])

  return (
    <ColorSquare
      hue={hsba.h}
      onMouseDown={handleMouseDown}
      squareRef={squareRef}
    >
      <Dot hsba={hsba} />
    </ColorSquare>
  )
}

function ColorSquare({
  hue,
  children,
  onMouseDown,
  squareRef,
}: {
  hue: number
  children: React.ReactNode
  onMouseDown: (e: React.MouseEvent) => void
  squareRef: React.RefObject<HTMLDivElement>
}) {
  return (
    <div
      ref={squareRef}
      onMouseDown={onMouseDown}
      style={{
        position: 'relative',
        width: 232,
        height: 232,
        backgroundColor: `hsl(${hue}, 100%, 50%)`,
        backgroundImage:
          'linear-gradient(to top, #000 0%, #00000000 100%), linear-gradient(to right, #fff 0%, #ffffff00 100%)',
        userSelect: 'none',
        boxShadow: `
          inset 1px 0 0 0 ${DesignColor('panelBorder')}, 
          inset -1px -1px 0 0 ${DesignColor('panelBorder')}`,
      }}
    >
      {children}
    </div>
  )
}

function Dot({ hsba }: { hsba: HsbaColor }) {
  const position = getDotPosition(hsba)
  return (
    <div
      style={{
        position: 'absolute',
        width: '14px',
        height: '14px',
        borderRadius: '50%',
        top: position.top,
        left: position.left,
        transform: 'translate(-50%, -50%)',
        backgroundColor: `#${hsbaToHex({ ...hsba, a: 1 })}`,
        border: `2px solid #fff`,
        boxShadow: `0 0 0 1px #00000050`,
        boxSizing: 'border-box',
      }}
    />
  )
}

function getDotPosition(hsba: HsbaColor): {
  top: string
  left: string
} {
  return {
    top: `${100 - hsba.b}%`,
    left: `${hsba.s}%`,
  }
}

function computeColor(
  hsba: HsbaColor,
  e: MouseEvent,
  squareRef: React.RefObject<HTMLDivElement>
): HsbaColor | undefined {
  if (!squareRef.current) return
  const rect = squareRef.current.getBoundingClientRect()
  const x = clamp(e.clientX - rect.left, 0, rect.width)
  const y = clamp(e.clientY - rect.top, 0, rect.height)
  const s_hsb = x / rect.width
  const b_hsb = 1 - y / rect.height

  return {
    h: hsba.h,
    s: s_hsb * 100,
    b: b_hsb * 100,
    a: hsba.a,
  }
}

function clamp(value: number, min?: number, max?: number): number {
  return Math.max(
    min !== undefined ? min : value,
    Math.min(max !== undefined ? max : value, value)
  )
}
