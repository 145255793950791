export class Padding {
  top: number
  right: number
  bottom: number
  left: number

  static create(): Padding {
    return new Padding(0, 0, 0, 0)
  }

  constructor(top: number, right: number, bottom: number, left: number) {
    this.top = top
    this.right = right
    this.bottom = bottom
    this.left = left
  }
}
