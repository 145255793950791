import { WriteDocument } from 'application/document'
import { Node } from 'application/node'
import { Rectangle } from 'application/shapes'

export class BoundsCalculator {
  private document: WriteDocument

  constructor(document: WriteDocument) {
    this.document = document
  }

  calculate(id: string): void {
    const node = this.document.getNode(id)
    if (!node) return

    const boundingBox = this.computeBoundingBox(node)
    if (node.getBaseAttribute('boundingBox.x') !== boundingBox.x) {
      node.setBaseAttribute('boundingBox.x', boundingBox.x)
    }
    if (node.getBaseAttribute('boundingBox.y') !== boundingBox.y) {
      node.setBaseAttribute('boundingBox.y', boundingBox.y)
    }
    if (node.getBaseAttribute('boundingBox.w') !== boundingBox.w) {
      node.setBaseAttribute('boundingBox.w', boundingBox.w)
    }
    if (node.getBaseAttribute('boundingBox.h') !== boundingBox.h) {
      node.setBaseAttribute('boundingBox.h', boundingBox.h)
    }
  }

  private computeBoundingBox(node: Node): Rectangle {
    const parent = this.findClipParent(node)
    if (!parent || ['canvas', 'root'].includes(node.getBaseAttribute('type'))) {
      return defaultCanvasBox
    }

    const top = Math.max(
      parent.getBaseAttribute('y'),
      parent.getBaseAttribute('boundingBox.y')
    )
    const left = Math.max(
      parent.getBaseAttribute('x'),
      parent.getBaseAttribute('boundingBox.x')
    )
    const bottom = Math.min(
      parent.getBaseAttribute('y') + parent.getBaseAttribute('h'),
      parent.getBaseAttribute('boundingBox.y') +
        parent.getBaseAttribute('boundingBox.h')
    )
    const right = Math.min(
      parent.getBaseAttribute('x') + parent.getBaseAttribute('w'),
      parent.getBaseAttribute('boundingBox.x') +
        parent.getBaseAttribute('boundingBox.w')
    )
    return {
      x: left,
      y: top,
      w: right - left,
      h: bottom - top,
    }
  }

  private findClipParent(node: Node): Node | undefined {
    const ancestors = this.document.getAncestors(node)
    return ancestors.find(
      (a) =>
        a.getStyleAttribute('overflow') !== 'visible' &&
        !['canvas', 'root'].includes(a.getBaseAttribute('type'))
    )
  }
}

const defaultCanvasBox: Rectangle = {
  x: -1_000_000,
  y: -1_000_000,
  w: 2_000_000,
  h: 2_000_000,
}
