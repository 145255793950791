import { Rectangle } from 'application/shapes'
import { QuadTreeRectangle, QuadTreeRectangleMetadata } from './rectangle'

export class QuadTreeRectangleFactory {
  static createQuadrant(rectangle: Rectangle): QuadTreeRectangle {
    return new QuadTreeRectangle('quadrant', rectangle, [], {})
  }

  static createRectangle(
    id: string,
    rectangle: Rectangle,
    z: number[],
    metadata?: QuadTreeRectangleMetadata
  ): QuadTreeRectangle {
    return new QuadTreeRectangle(id, rectangle, z, metadata || {})
  }
}
