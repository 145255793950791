import { Element } from './element'
import { Size } from '../types/size'
import { HtmlTag } from '../types/types'

export class ElementImg extends Element {
  static create = (size: Size): Element => {
    const element = new Element(HtmlTag.tag_img)
    element.contentSize = size
    element.sizedByContent = true
    return element
  }
}
