import {
  Context,
  UniformValue,
  VaoData,
  VertexBuffer,
  calcBoundingBox,
  calcImagePosition,
  calcImageTexCoords,
  calcRounding,
  isPointInBox,
} from 'application/render'
import { Rectangle } from 'application/shapes'
import {
  clearBuffers,
  connectBuffersToVao,
  createEmptyVaoData,
  standardDraw,
} from '../utils'
import { MaterialType } from './shaderMap'

export type WebglEllipseImageData = Rectangle & {
  src: string
  resize: 'stretch' | 'fit' | 'fill' | 'parallax' | 'repeat'
  originalSize?: { w: number; h: number }
  bb?: Rectangle
  bbRounding?: { tl: number; tr: number; br: number; bl: number }
}

export class WebglEllipseImage {
  private context: Context
  private data: WebglEllipseImageData
  private vaoData: VaoData

  constructor(context: Context, data: WebglEllipseImageData) {
    this.context = context
    this.data = data
    this.vaoData = createEmptyVaoData()
  }

  init = (): void => {
    this.vaoData = this.updateRenderData()
  }

  draw(): void {
    if (!this.vaoData.material) return

    const texture = this.context.getTexture(this.data.src)
    if (!texture) {
      this.context.createImageTexture(this.data.src)
      return
    }

    this.vaoData.material.setSampler('uSampler', texture.getTexture(), 0)
    standardDraw(this.vaoData)
  }

  updateRenderData(): VaoData {
    clearBuffers(this.vaoData)
    const gl = this.context.getGl()
    const material = this.context.getMaterial(MaterialType.ellipseImage)
    const vao = gl.createVertexArray()
    const buffers: { [key: string]: VertexBuffer } = {}
    const uniforms: { [key: string]: UniformValue } = {}

    const { x, y, w, h, resize, originalSize, bb, bbRounding } = this.data
    const rect = { x, y, w, h }

    const aPosition = new Float32Array(
      calcImagePosition(rect, resize, originalSize)
    )
    const aTexCoords = new Float32Array(
      calcImageTexCoords(rect, resize, originalSize)
    )
    const uRect = [x, y, w, h]
    const uBB = calcBoundingBox(bb)
    const uBBRounding = calcRounding(uBB[2], uBB[3], bbRounding)

    buffers['aPosition'] = this.context.createVertexBuffer(aPosition, 2)
    buffers['aTexCoords'] = this.context.createVertexBuffer(aTexCoords, 2)
    uniforms['uRect'] = uRect
    uniforms['uBB'] = uBB
    uniforms['uBBRounding'] = uBBRounding
    const verticeCount = aPosition.length / 2

    const vaoData = { vao, material, buffers, uniforms, verticeCount }
    connectBuffersToVao(vaoData)
    return vaoData
  }

  cleanup = (): void => {
    clearBuffers(this.vaoData)
  }
}

export const ellipseImageVs = `#version 300 es
precision highp float;

uniform mat3 uMatrix;

in vec2 aPosition;
in vec2 aTexCoords;

out vec2 vPosition;
out vec2 vTexCoords;

void main() {
  vec3 transformedPosition = uMatrix * vec3(aPosition, 1);
  gl_Position = vec4(transformedPosition.xy, 0, 1);

  vPosition = aPosition;
  vTexCoords = aTexCoords;
}
`

export const ellipseImageFs = `#version 300 es
precision mediump float;

uniform sampler2D uSampler;
uniform vec4 uRect;
uniform vec4 uBB;
uniform vec4 uBBRounding;

in vec2 vPosition;
in vec2 vTexCoords;

out vec4 outColor;

${isPointInBox}

void main() {
  if (!isPointInBox(vPosition, uBB, uBBRounding)) {
    discard;
  }

  float centerX = uRect.x + uRect.z * 0.5;
  float centerY = uRect.y + uRect.w * 0.5;
  float radiusX = uRect.z * 0.5;
  float radiusY = uRect.w * 0.5;

  float normalizedX = (vPosition.x - centerX) / radiusX;
  float normalizedY = (vPosition.y - centerY) / radiusY;

  float distance = normalizedX * normalizedX + normalizedY * normalizedY;
  float edgeWidth = fwidth(distance) * 0.5 + 0.01;
  float alpha = 1.0 - smoothstep(1.0 - edgeWidth, 1.0, distance);

  if (distance > (1.0 + edgeWidth)) {
    discard;
  }

  vec4 texColor = texture(uSampler, vTexCoords);
  outColor = vec4(texColor.rgb, texColor.a * alpha);
}
`
