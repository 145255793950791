import {
  AttributeFlexAlign,
  AttributeFlexDirection,
  AttributeFlexAlignSelf,
  StyleMap,
} from 'application/attributes'
import { IconKey } from 'assets/iconLibrary'
import Dropdown, {
  dropdownOption,
} from 'components/Library/Components/Dropdown/Dropdown'
import OverrideReset from 'components/Library/Components/Inputs/OverrideReset/OverrideReset'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import { FlexChildMode } from 'editor/ui/flexChildPanel'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'

export default function FlexChildRow() {
  const flexChild = usePanelHelper(editor.getUI().getFlexChildPanel())
  const overrides = usePanelHelper(editor.getUI().getStyleOverridesPanel())

  if (!flexChild.settings) return <></>
  if (!flexChild.settings.attributes) return <></>

  const { setMode, setAlignSelf } = flexChild.handlers
  const { hasOverride } = overrides.handlers

  const { mode, direction, attributes, align } = flexChild.settings

  const { 'flex.alignSelf': alignSelf } = attributes

  return (
    <PanelRow>
      {direction === 'column' ? (
        <>
          <AlignInput
            alignSelf={alignSelf}
            setAlignSelf={setAlignSelf}
            direction={direction}
            align={align}
            hasOverride={hasOverride}
          />
          <FlexInput
            mode={mode}
            setMode={setMode}
            direction={direction}
            hasOverride={hasOverride}
          />
        </>
      ) : (
        <>
          <FlexInput
            mode={mode}
            setMode={setMode}
            direction={direction}
            hasOverride={hasOverride}
          />
          <AlignInput
            alignSelf={alignSelf}
            setAlignSelf={setAlignSelf}
            direction={direction}
            align={align}
            hasOverride={hasOverride}
          />
        </>
      )}
    </PanelRow>
  )
}

function FlexInput({
  mode,
  setMode,
  direction,
  hasOverride,
}: {
  mode: 'Mixed' | FlexChildMode
  setMode: (mode: FlexChildMode) => void
  direction: 'Mixed' | AttributeFlexDirection
  hasOverride: (keys: (keyof StyleMap)[]) => boolean
}) {
  return (
    <>
      <OverrideReset
        keys={['flex.grow', 'flex.shrink', 'flex.basis.unit', 'flex.basis.px']}
      >
        <Dropdown
          drawerWidth={144}
          tooltipKey={'FlexSize'}
          mixed={mode === 'Mixed'}
          mixedIcon={direction === 'column' ? 'FlexResizeV' : 'FlexResizeH'}
          hasIcon={true}
          selected={mode}
          options={getFlexModeOptions(direction)}
          select={(v) => {
            if (!v) return
            if (v === 'Mixed') return
            setMode(v)
          }}
          empty={mode === 'fixed'}
          hasOverride={hasOverride([
            'flex.grow',
            'flex.shrink',
            'flex.basis.unit',
            'flex.basis.px',
          ])}
          commit={true}
        />
      </OverrideReset>
    </>
  )
}

function AlignInput({
  alignSelf,
  setAlignSelf,
  direction,
  align,
  hasOverride,
}: {
  alignSelf: 'Mixed' | undefined | AttributeFlexAlignSelf
  setAlignSelf: (value: AttributeFlexAlignSelf | undefined) => void
  direction: 'Mixed' | AttributeFlexDirection
  align: 'Mixed' | AttributeFlexAlign
  hasOverride: (keys: (keyof StyleMap)[]) => boolean
}) {
  return (
    <OverrideReset keys={['flex.alignSelf']}>
      <Dropdown
        tooltipKey={'AlignSelf'}
        mixed={alignSelf === 'Mixed'}
        mixedIcon={direction === 'column' ? 'AlignSelfTop' : 'AlignSelfLeft'}
        hasIcon={true}
        selected={alignSelf}
        options={getFlexAlignOptions(direction, align)}
        select={(v) => {
          if (v === 'Mixed') return
          setAlignSelf(v)
        }}
        empty={!alignSelf}
        hasOverride={hasOverride(['flex.alignSelf'])}
        commit={true}
      />
    </OverrideReset>
  )
}

function getFlexModeOptions(
  direction: 'Mixed' | AttributeFlexDirection
): dropdownOption<FlexChildMode>[] {
  return [
    {
      value: 'fixed',
      text: 'Resize',
      dropdownText: 'No resizing',
      icon: direction === 'column' ? 'FlexResizeV' : 'FlexResizeH',
    },
    {
      value: 'grow',
      text: 'Grow',
      dropdownText: 'Grow if possible',
      icon: direction === 'column' ? 'FlexGrowV' : 'FlexGrowH',
    },
    {
      value: 'shrink',
      text: 'Shrink',
      dropdownText: 'Shrink if needed',
      icon: direction === 'column' ? 'FlexShrinkV' : 'FlexShrinkH',
    },
  ]
}

function getFlexAlignOptions(
  direction: 'Mixed' | AttributeFlexDirection,
  align: 'Mixed' | AttributeFlexAlign
): dropdownOption<AttributeFlexAlignSelf | undefined>[] {
  return [
    {
      value: undefined,
      text: 'Align',
      icon: getFlexAlignAutoIcon(direction, align),
    },
    {
      value: 'stretch',
      text: 'Stretch',
      icon: direction === 'column' ? 'AlignSelfStretchH' : 'AlignSelfStretchV',
    },
  ]
}

function getFlexAlignAutoIcon(
  direction: 'Mixed' | AttributeFlexDirection,
  align: 'Mixed' | AttributeFlexAlign
): IconKey {
  switch (align) {
    case 'start':
      return direction === 'column' ? 'AlignSelfLeft' : 'AlignSelfTop'
    case 'center':
      return direction === 'column' ? 'AlignSelfCenterH' : 'AlignSelfCenterV'
    case 'end':
      return direction === 'column' ? 'AlignSelfRight' : 'AlignSelfBottom'
    default:
      return direction === 'column' ? 'AlignSelfTop' : 'AlignSelfLeft'
  }
}
