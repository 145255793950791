import { CodeBlockType } from 'application/export'
import TitleButtonBlock from 'components/Library/Components/Button/TitleButtonBlock/TitleButtonBlock'
import CodeBlock from 'components/Library/Components/CodeBlock/CodeBlock'
import { dropdownOption } from 'components/Library/Components/Dropdown/Dropdown'
import TextCode from 'components/Library/Components/Text/TextCode/TextCode'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import { upgradingState } from 'components/Pages/Editor/UpgradePopup/UpgradePopup'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import useToast from 'hooks/editor/useToast'
import { editor } from 'index'
import { useEffect } from 'react'
import { useSetRecoilState } from 'recoil'

export default function CodeExport() {
  const toast = useToast()
  const codePanel = usePanelHelper(editor.getUI().getCodePanel())
  const setUpgrading = useSetRecoilState(upgradingState)
  const { setExportMode, setStyleMode } = codePanel.handlers

  useEffect(() => {
    codePanel.handlers.load()
  }, [codePanel.handlers])

  if (!codePanel.settings || !codePanel.settings.export) return null
  const { exportMode, styleMode, premium } = codePanel.settings
  const { code, style, font } = codePanel.settings.export

  const handleUpgrade = () => {
    editor.pause()
    setUpgrading(true)
  }

  return (
    <>
      <PanelSection>
        <PanelRow>
          <TitleButtonBlock title={'Code'} active={true} />
        </PanelRow>
        <CodeBlock
          options={codeExportOptions}
          selected={exportMode}
          select={setExportMode}
          locked={!premium}
          upgrade={handleUpgrade}
        >
          {premium ? (
            code.map((block, index) => (
              <TextCode
                key={index}
                language={block.type}
                code={block.code}
                copy={() => {
                  if (!codePanel.settings) return
                  navigator.clipboard.writeText(block.code)
                  toast('Copied code to clipboard', 'success')
                }}
                bottom={index === code.length - 1}
              />
            ))
          ) : (
            <TextCode
              language={'html'}
              code={nonPremiumPlaceholder}
              copy={() => {}}
              bottom={true}
            />
          )}
        </CodeBlock>
      </PanelSection>
      <PanelSection>
        <PanelRow>
          <TitleButtonBlock title={'Styles'} active={true} />
        </PanelRow>
        <CodeBlock
          options={styleExportOptions}
          selected={styleMode}
          select={setStyleMode}
          locked={false}
        >
          <TextCode
            language={styleMode}
            code={style.code}
            copy={() => {
              if (!codePanel.settings) return
              navigator.clipboard.writeText(style.code)
              toast('Copied styles to clipboard', 'success')
            }}
            bottom={true}
          />
        </CodeBlock>
      </PanelSection>
      {font.code !== '' && (
        <PanelSection>
          <PanelRow>
            <TitleButtonBlock title={'Fonts'} active={true} />
          </PanelRow>
          <CodeBlock
            options={fontExportOptions}
            selected={'html'}
            select={() => {}}
            locked={false}
          >
            <TextCode
              language={'html'}
              code={font.code}
              copy={() => {
                if (!codePanel.settings) return
                navigator.clipboard.writeText(font.code)
                toast('Copied fonts to clipboard', 'success')
              }}
              bottom={true}
            />
          </CodeBlock>
        </PanelSection>
      )}
    </>
  )
}

const styleExportOptions: dropdownOption<CodeBlockType>[] = [
  { value: 'css', text: 'CSS' },
  { value: 'tailwind', text: 'Tailwind' },
]

const fontExportOptions: dropdownOption<CodeBlockType>[] = [
  { value: 'html', text: 'Fonts' },
]

const codeExportOptions: dropdownOption<CodeBlockType>[] = [
  { value: 'html', text: 'HTML & CSS' },
  { value: 'jsx+tailwind', text: 'JSX & Tailwind' },
]

const nonPremiumPlaceholder = `
<div id="e_38">
  <div id="e_43">
    <div id="e_42">
      <p id="e_40">Transactions</p>
      <p id="e_41">Recent transactions from your store</p>
    </div>
    <button id="e_123">
      View All
      <img
        id="e_125"
        src="https://s3.us-east-2.amazonaws.com/prod-repaint-public-site-files/5a5d7274-533d-446a-8ee6-79b5603707f6/82248a8a-fcbf-46ff-abc7-312b5beede3e"
      />
    </button>
  </div>
  <div id="e_52">
    <div id="e_44">
      <p id="e_45">Customer</p>
      <p id="e_46">Amount</p>
    </div>
    <div id="e_47">
      <div id="e_51">
        <p id="e_48">Michael Thornton</p>
        <p id="e_50">micheal@example.com</p>
      </div>
      <p id="e_49">$400.00</p>
    </div>
    <div id="e_53">
      <div id="e_57">
        <p id="e_54">Maria Benson</p>
        <p id="e_56">maria@exmaple.com</p>
      </div>
      <p id="e_55">$320.00</p>
    </div>
    <div id="e_58">
      <div id="e_62">
        <p id="e_59">David Corbin</p>
        <p id="e_61">david@example.com</p>
      </div>
      <p id="e_60">$780.00</p>
    </div>
    <div id="e_63">
      <div id="e_67">
        <p id="e_64">Ethan Mitchell</p>
        <p id="e_66">ethan@example.com</p>
      </div>
      <p id="e_65">$100.00</p>
    </div>
    <div id="e_68">
      <div id="e_72">
        <p id="e_69">Isabella Reed</p>
        <p id="e_71">isabella@example.com</p>
      </div>
      <p id="e_70">$550.00</p>
    </div>
  </div>
</div>
`
