import { DesignColor } from 'themes'
import styled from 'styled-components'
import { Cursors } from 'assets/cursorIndex'

export const FullscreenCanvas = styled.canvas`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  margin: 0;
  padding: 0;

  background-color: ${DesignColor('canvasBackground')};
  cursor: ${Cursors.CanvasDefault};
`
