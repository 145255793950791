import { Camera, CameraUpdateListener } from 'application/camera'
import { ClientUpdateListener, Update } from 'application/client'
import { ReadOnlyDocument } from 'application/document'
import { FlatLine } from 'application/shapes'
import {
  Action,
  ActionHandler,
  ActiveActionListener,
} from 'editor/action/types'
import { Canvas, CanvasUpdateListener } from 'editor/canvas/canvas'
import { ConstraintLinesTransformer } from './transformer/constraintLines'
import { isAbsolutePositionMode } from 'application/attributes'
import { ReadOnlyNode } from 'application/node'
import { getContainingParent } from 'application/units'

export const hapticConstraintLinesKey = 'hapticConstraintLines'

export class HapticConstraintLines
  implements
    ClientUpdateListener,
    CameraUpdateListener,
    ActiveActionListener,
    CanvasUpdateListener
{
  private document: ReadOnlyDocument
  private canvas: Canvas

  private ids: string[]
  private actionType: Action | null
  private editingText: boolean
  private lines: FlatLine[]

  private camera: Camera | null

  constructor(document: ReadOnlyDocument, canvas: Canvas) {
    this.document = document
    this.canvas = canvas

    this.ids = []
    this.actionType = null
    this.editingText = false
    this.lines = []

    this.camera = null
  }

  onCamera = (camera: Camera) => {
    this.camera = camera
    this.render()
  }

  onActiveAction = (handler: ActionHandler | null): void => {
    this.actionType = handler ? handler.getType() : null
    this.render()
  }

  onCanvasInit = () => {
    this.render()
  }

  getTypes = (): Update['type'][] => {
    return ['selection', 'node_updated']
  }

  onUpdate = (updates: Update[]) => {
    for (const update of updates) {
      switch (update.type) {
        case 'selection':
          this.updateSelection(update.data.ids)
          break
        case 'node_updated':
          this.updateNode(update.data.id)
          break
        case 'edit_text':
          this.updateText(update.data.id)
          break
      }
    }
  }

  private updateSelection = (ids: string[]) => {
    this.ids = ids
    this.lines = this.computeLines()
    this.render()
  }

  private updateNode = (id: string) => {
    if (this.ids.includes(id)) {
      this.lines = this.computeLines()
      this.render()
    }
  }

  private updateText = (id: string | null) => {
    this.editingText = id !== null
    this.render()
  }

  private render = () => {
    if (!this.canvas.isReady()) return
    if (
      this.camera === null ||
      this.deactivatedAction() ||
      this.editingText ||
      this.lines.length === 0
    ) {
      this.canvas.deleteHaptic(hapticConstraintLinesKey)
    } else {
      this.canvas.setHaptic(
        hapticConstraintLinesKey,
        ConstraintLinesTransformer.transform(
          this.canvas.getContext(),
          this.lines,
          this.camera
        )
      )
    }
  }

  private computeLines = (): FlatLine[] => {
    const lines: FlatLine[] = []
    if (this.ids.length !== 1) return lines

    const node = this.document.getNode(this.ids[0])
    if (!node || !isAbsolutePositionMode(node)) return lines

    const parent = this.document.getParent(node)
    if (!parent || parent.getBaseAttribute('type') === 'canvas') return lines

    const containingParent = getContainingParent(node, this.document)
    if (!containingParent) return lines

    lines.push(
      ...this.computeLineDimensions('h', node, containingParent),
      ...this.computeLineDimensions('v', node, containingParent)
    )

    return lines
  }

  private computeLineDimensions(
    mode: 'h' | 'v',
    node: ReadOnlyNode,
    parent: ReadOnlyNode
  ): FlatLine[] {
    const lines: FlatLine[] = []

    const p1Mode = node.getStyleAttribute(
      `position.${mode === 'h' ? 'left' : 'top'}.unit`
    )
    const p2Mode = node.getStyleAttribute(
      `position.${mode === 'h' ? 'right' : 'bottom'}.unit`
    )
    const pMain = node.getBaseAttribute(mode === 'h' ? 'x' : 'y')
    const pCross = node.getBaseAttribute(mode === 'h' ? 'y' : 'x')
    const sMain = node.getBaseAttribute(mode === 'h' ? 'w' : 'h')
    const sCross = node.getBaseAttribute(mode === 'h' ? 'h' : 'w')
    const pMainParent = parent.getBaseAttribute(mode === 'h' ? 'x' : 'y')
    const sMainParent = parent.getBaseAttribute(mode === 'h' ? 'w' : 'h')

    if (p1Mode !== undefined) {
      lines.push({
        p1: pMain,
        p2: pMainParent,
        counter: pCross + sCross / 2,
        direction: mode,
        thickness: 1,
      })
    }

    if (p2Mode !== undefined) {
      lines.push({
        p1: pMain + sMain,
        p2: pMainParent + sMainParent,
        counter: pCross + sCross / 2,
        direction: mode,
        thickness: 1,
      })
    }

    return lines
  }

  private deactivatedAction = (): boolean => {
    switch (this.actionType) {
      case 'move':
        return true
      default:
        return false
    }
  }
}
