import { ReadOnlyNode } from 'application/node'
import { HTMLDocument } from '../html/types'
import { createSelector } from '../utils'
import { AttributeTransformer } from './types'

export class OpacityStyle implements AttributeTransformer {
  transformBase = (node: ReadOnlyNode, htmlDocument: HTMLDocument): void => {
    const selector = createSelector(node.getId(), 'default')
    const style = htmlDocument.getStyles().get(selector)
    const attrs = node.getDefaultSelector().styles

    const opacity = attrs['opacity']
    if (opacity === undefined || opacity === 100) return

    style.setProperty('opacity', { unit: '%', value: opacity })
  }
}
