import { ReadOnlyDocument } from 'application/document'
import { ReadOnlyNode } from 'application/node'
import { OverrideMode, OverrideThreshold } from './types'
import { isContainerType } from 'application/attributes'

export function getOverrideContainer(
  node: ReadOnlyNode,
  document: ReadOnlyDocument
): ReadOnlyNode | undefined {
  if (isOverrideContainer(node, document)) return node

  const ancestors = document.getAncestors(node)
  return ancestors.find((a) => {
    return isOverrideContainer(a, document)
  })
}

export function isOverrideContainer(
  node: ReadOnlyNode,
  document: ReadOnlyDocument
): boolean {
  const parent = document.getParent(node)
  if (!parent) return false

  return (
    parent.getBaseAttribute('type') === 'canvas' &&
    isContainerType(node.getBaseAttribute('type'))
  )
}

export function doesContainerAllowOverrides(
  node: ReadOnlyNode,
  document: ReadOnlyDocument
): boolean {
  if (!isOverrideContainer(node, document)) return false
  if (node.getStyleAttribute('size.w.unit') !== 'px') return false
  return true
}

export function getOverrideMode(
  node: ReadOnlyNode,
  document: ReadOnlyDocument
): OverrideMode {
  const overrideContainer = getOverrideContainer(node, document)
  if (!overrideContainer) return 'default'

  if (!doesContainerAllowOverrides(overrideContainer, document))
    return 'default'

  return node.getActiveBreakpoint()
}

export function getModeForWidth(width: number): OverrideMode {
  for (const [k, v] of Object.entries(OverrideThreshold)) {
    if (width >= v.min && width <= v.max) return k as OverrideMode
  }
  return 'default'
}

export function getWidthForMode(mode: OverrideMode): number {
  if (mode === 'default') return 1440
  return Math.max(OverrideThreshold[mode].min, 320)
}
