import { Point } from 'application/shapes'
import { InteractableNodeFilter } from './interactable/interactable'
import { Command, SelectNode, UnselectNode } from 'application/client/command'
import { ReadOnlyDocument } from 'application/document'

interface CommandHandler {
  handle: (command: Command | Command[]) => void
}

export class NodeSelectionAction {
  private commandHandler: CommandHandler
  private document: ReadOnlyDocument
  private interactableNodeFilter: InteractableNodeFilter

  constructor(
    commandHandler: CommandHandler,
    document: ReadOnlyDocument,
    interactableNodeFilter: InteractableNodeFilter
  ) {
    this.commandHandler = commandHandler
    this.document = document
    this.interactableNodeFilter = interactableNodeFilter
  }

  selectAtPoint(
    point: Point,
    drill: boolean,
    multiselect: boolean,
    doubleclick: boolean
  ): boolean {
    const id = this.interactableNodeFilter.nodeAtPoint(
      point,
      drill,
      doubleclick
    )

    if (!id) {
      this.selectNodes([], true)
      return false
    }

    this.selectNodes([id], !multiselect)
    return true
  }

  multiselectInRectangle(start: Point, end: Point): void {
    const ids = this.interactableNodeFilter.nodesInRectangle({
      x: Math.min(start.x, end.x),
      y: Math.min(start.y, end.y),
      w: Math.abs(start.x - end.x),
      h: Math.abs(start.y - end.y),
    })
    if (!ids) return

    const command = this.buildSelectCommand(ids, true)
    this.commandHandler.handle(command)
  }

  selectNodes(ids: string[], clear: boolean): void {
    const command = this.buildSelectCommand(ids, clear)
    this.commandHandler.handle(command)
  }

  unselectNode(id: string): void {
    const command = this.buildUnselectCommand(id)
    this.commandHandler.handle(command)
  }

  selectCanvas(id: string): void {
    this.commandHandler.handle({
      type: 'setSelectedCanvas',
      params: { id: id },
    })
  }

  private buildSelectCommand = (ids: string[], clear: boolean): SelectNode => {
    return {
      type: 'selectNode',
      params: {
        ids: ids,
        clear: clear,
      },
    }
  }

  private buildUnselectCommand = (id: string): UnselectNode => {
    return {
      type: 'unselectNode',
      params: {
        id: id,
      },
    }
  }
}
