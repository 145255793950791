import { ContainerElement } from './elementContainer'
import { Attributes, Element, Tag } from './types'

export class ContainerElementBuilder {
  private tag: Tag | null = null
  private attributes: Partial<Attributes> = {}
  private children: Element[] = []

  withTag = (tag: Tag): ContainerElementBuilder => {
    this.tag = tag
    return this
  }

  withAttributes = (
    attributes: Partial<Attributes>
  ): ContainerElementBuilder => {
    this.attributes = attributes
    return this
  }

  withChildren = (children: Element[]): ContainerElementBuilder => {
    this.children = children
    return this
  }

  build = (): Element => {
    if (!this.tag) throw new Error('Tag is required')
    return new ContainerElement(this.tag!, this.attributes, this.children)
  }
}
