import {
  FilterFunction,
  isFilterBlur,
  Properties,
  unitSizeToString,
} from 'application/export/css'
import { TailwindClassRenderer } from '../types'

export class TailwindBlur implements TailwindClassRenderer {
  render = (properties: Partial<Properties>): string[] => {
    const classes: string[] = []

    const backdropFilter = properties.backdropFilter
    if (backdropFilter !== undefined && backdropFilter !== 'none') {
      classes.push(...this.renderBackdropFilter(backdropFilter))
    }

    return classes
  }

  private renderBackdropFilter = (filters: FilterFunction[]): string[] => {
    const classes: string[] = []

    for (const filter of filters) {
      if (isFilterBlur(filter)) {
        classes.push(`blur-[${unitSizeToString(filter.value)}]`)
      }
    }

    return classes
  }
}
