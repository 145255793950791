import { WSResponseHeartbeat } from './types'

export function isHeartbeatAck(data: any): data is WSResponseHeartbeat {
  return (
    'type' in data &&
    data['type'] === 'heartbeat' &&
    'data' in data &&
    'timestamp' in data['data']
  )
}
