import { ReadOnlyNode } from 'application/node'
import { HTMLDocument } from '../html/types'
import { createSelector } from '../utils'
import { AttributeTransformer } from './types'
import { UnitLength } from '../css/types'
import _ from 'lodash'

export class RoundingStyle implements AttributeTransformer {
  transformBase = (node: ReadOnlyNode, htmlDocument: HTMLDocument): void => {
    const selector = createSelector(node.getId(), 'default')
    const style = htmlDocument.getStyles().get(selector)

    const topLeft = this.getCorner(node, 'topLeft')
    const topRight = this.getCorner(node, 'topRight')
    const bottomLeft = this.getCorner(node, 'bottomLeft')
    const bottomRight = this.getCorner(node, 'bottomRight')
    if (
      topLeft.value === 0 &&
      topRight.value === 0 &&
      bottomLeft.value === 0 &&
      bottomRight.value === 0
    ) {
      return
    }

    const topLeftEqualsBottomRight = _.isEqual(topLeft, bottomRight)
    const topRightEqualsBottomLeft = _.isEqual(topRight, bottomLeft)
    const topLeftEqualsTopRight = _.isEqual(topLeft, topRight)
    const bottomLeftEqualsBottomRight = _.isEqual(bottomLeft, bottomRight)
    const allEqual =
      topLeftEqualsBottomRight &&
      topRightEqualsBottomLeft &&
      topLeftEqualsTopRight &&
      bottomLeftEqualsBottomRight

    if (allEqual) {
      style.setProperty('borderRadius', topLeft)
    } else if (topLeftEqualsBottomRight && topRightEqualsBottomLeft) {
      style.setProperty('borderRadius', [topLeft, topRight])
    } else {
      style.setProperty('borderRadius', [
        topLeft,
        topRight,
        bottomRight,
        bottomLeft,
      ])
    }
  }

  private getCorner(
    node: ReadOnlyNode,
    corner: 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight'
  ): UnitLength {
    const unit =
      node.getDefaultSelector().styles[`border.radius.${corner}.unit`]
    switch (unit) {
      case 'px':
        const value =
          node.getDefaultSelector().styles[`border.radius.${corner}.px`]
        if (value === undefined) return { unit: 'px', value: 0 }
        return { unit, value }
    }
    return { unit: 'px', value: 0 }
  }
}
