import { ReadOnlyDocument } from 'application/document'
import { QuadTree } from 'application/quadtree'
import { InsertionStrategy } from '../creation'
import { AttributeType } from 'application/attributes'
import { Delta, Point, Rectangle } from 'application/shapes'
import { ClickedNodeInsertionStrategy } from './clicked'
import { ReadOnlyDocumentSelection } from 'application/selection/types'
import { SharedParentInsertionStrategy } from './shared'
import { CameraService } from 'application/camera'
import { CanvasInsertionStrategy } from './canvas'

export class InsertionStrategyFactory {
  private document: ReadOnlyDocument
  private documentSelection: ReadOnlyDocumentSelection
  private cameraService: CameraService
  private quadtree: QuadTree

  constructor(
    document: ReadOnlyDocument,
    documentSelection: ReadOnlyDocumentSelection,
    cameraService: CameraService,
    quadtree: QuadTree
  ) {
    this.document = document
    this.documentSelection = documentSelection
    this.cameraService = cameraService
    this.quadtree = quadtree
  }

  createClickedNodeStrategy = (
    type: AttributeType,
    point: Point,
    offset?: Delta
  ): InsertionStrategy => {
    return new ClickedNodeInsertionStrategy(
      this.document,
      this.documentSelection,
      this.quadtree,
      type,
      point,
      offset
    )
  }

  createSharedParentStrategy = (ids: string[]): InsertionStrategy => {
    return new SharedParentInsertionStrategy(
      ids,
      this.document,
      this.documentSelection
    )
  }

  createCanvasStrategy = (size: Rectangle): InsertionStrategy => {
    return new CanvasInsertionStrategy(
      this.cameraService,
      this.documentSelection,
      size
    )
  }
}
