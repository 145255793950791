import { MouseMoveListener, WheelEventListener } from 'application/browser'
import { CoordinatesConversion } from 'application/camera'
import { Point } from 'application/shapes'

export class CursorPositionState
  implements MouseMoveListener, WheelEventListener
{
  private point: Point | null
  private coordinates: CoordinatesConversion

  constructor(coordinates: CoordinatesConversion) {
    this.point = null
    this.coordinates = coordinates
  }

  getPoint = (): Point | null => {
    return this.point
  }

  handleMouseMove = (e: MouseEvent) => {
    this.point = this.coordinates.get(e)
  }

  handleWheel = (e: WheelEvent) => {
    this.point = this.coordinates.get(e)
  }
}
