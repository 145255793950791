import { ReadOnlyNode } from 'application/node'
import { HTMLDocument } from '../html/types'
import { createSelector } from '../utils'
import { AttributeTransformer } from './types'

export class FilterStyle implements AttributeTransformer {
  transformBase = (node: ReadOnlyNode, htmlDocument: HTMLDocument): void => {
    const selector = createSelector(node.getId(), 'default')
    const style = htmlDocument.getStyles().get(selector)
    const attrs = node.getDefaultSelector().styles

    const mode = attrs['filter.mode']
    if (mode !== 'none') return

    const blur = attrs['filter.blur.radius']
    if (blur === undefined) return

    style.setProperty('backdropFilter', [
      { type: 'blur', value: { unit: 'px', value: blur / 4 } },
    ])
    style.setProperty('webkitBackdropFilter', [
      { type: 'blur', value: { unit: 'px', value: blur / 4 } },
    ])
  }
}
