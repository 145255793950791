import { DesignColor } from 'themes'
import { Camera } from 'application/camera'
import { rgbaStringToRgba } from 'application/color'
import {
  Context,
  Renderable,
  WebglLine,
  WebglLineData,
  WebglRectangle,
  WebglRectangleData,
  WebglText,
  WebglTextData,
} from 'application/render'
import { DistanceLine } from '../types'
import { FontLoaderInterface, TextContent, Shaper } from 'application/text'
import { Rectangle } from 'application/shapes'

const labelPaddingV = 2
const labbelPaddingH = 4
const labelRounding = 4
const labelOffset = 8

export class DistanceLineTransformer {
  static transform = (
    context: Context,
    fontLoader: FontLoaderInterface,
    textShaper: Shaper,
    distanceLine: DistanceLine[],
    camera: Camera
  ): Renderable[] => {
    const lines: Renderable[] = []
    const dashLength = Math.max(4 / camera.z, 4)

    for (const line of distanceLine) {
      const data: WebglLineData = {
        lines: [],
        thickness: 1 / camera.z,
        color: rgbaStringToRgba(DesignColor('alignment')),
        dashed: line.type === 'dashed',
        dashLength: line.type === 'dashed' ? dashLength : undefined,
      }

      switch (line.line.direction) {
        case 'h':
          data.lines.push({
            start: { x: line.line.p1, y: line.line.counter },
            end: { x: line.line.p2, y: line.line.counter },
          })
          break
        case 'v':
          data.lines.push({
            start: { x: line.line.counter, y: line.line.p1 },
            end: { x: line.line.counter, y: line.line.p2 },
          })
          break
      }

      const renderable = new WebglLine(context, data)
      renderable.init()

      lines.push(renderable)

      const labelRenderables = this.getLabel(
        context,
        fontLoader,
        textShaper,
        camera,
        line
      )
      labelRenderables.forEach((label) => lines.push(label))
    }

    return lines
  }

  private static getLabel = (
    context: Context,
    fontLoader: FontLoaderInterface,
    textShaper: Shaper,
    camera: Camera,
    line: DistanceLine
  ): Renderable[] => {
    if (!line.display) return []

    const labelText = this.getLabelText(line.display, camera.z)
    const rectangle = this.getLabelRectangle(
      line,
      camera.z,
      labelText,
      textShaper
    )
    if (!rectangle) return []

    const labelTextData: WebglTextData = {
      ...rectangle,
      bb: rectangle,
      text: labelText,
    }

    const text = new WebglText(context, labelTextData, fontLoader, textShaper)
    text.init()

    const backgroundRectangle = {
      x: rectangle.x - labbelPaddingH / camera.z,
      y: rectangle.y - labelPaddingV / camera.z,
      w: rectangle.w + (labbelPaddingH * 2) / camera.z,
      h: rectangle.h + (labelPaddingV * 2) / camera.z,
    }
    const labelBackground: WebglRectangleData = {
      ...backgroundRectangle,
      bb: backgroundRectangle,
      fill: {
        type: 'color',
        color: rgbaStringToRgba(DesignColor('alignment')),
      },
      rounding: {
        tl: labelRounding / camera.z,
        tr: labelRounding / camera.z,
        br: labelRounding / camera.z,
        bl: labelRounding / camera.z,
      },
    }

    const background = new WebglRectangle(context, labelBackground)
    background.init()

    return [background, text]
  }

  private static getLabelRectangle = (
    line: DistanceLine,
    z: number,
    textContent: TextContent,
    textShaper: Shaper
  ): Rectangle | null => {
    if (!line.display) return null

    const shapedText = textShaper.getShapedText(textContent)

    switch (line.line.direction) {
      case 'h':
        const yOffset = shapedText.h / 2 + (labelOffset + labelPaddingV * 2) / z

        const rectangleH = {
          x: (line.line.p1 + line.line.p2) / 2 - shapedText.w / 2,
          y: line.line.counter - yOffset,
          w: shapedText.w,
          h: shapedText.h,
        }

        textShaper.releaseShapedText(shapedText)

        return rectangleH
      case 'v':
        const xOffset =
          shapedText.w / 2 + (labelOffset + labbelPaddingH * 2) / z

        const rectangleV = {
          x: line.line.counter - xOffset,
          y: (line.line.p1 + line.line.p2) / 2 - shapedText.h / 2,
          w: shapedText.w,
          h: shapedText.h,
        }

        textShaper.releaseShapedText(shapedText)

        return rectangleV
    }
  }

  private static getLabelText = (value: number, z: number): TextContent => {
    const displayText = value.toString()
    return {
      content: displayText,
      contentStyles: new Array(displayText.length).fill(0),
      styles: {
        fontFamily: 'inter',
        fontWeight: 'regular',
        fontSize: 12 / z,
        align: 'left',
        fill: {
          type: 'color',
          color: rgbaStringToRgba(DesignColor('alignmentText')),
        },
        letterSpacing: 0,
        lineHeight: 0,
        italic: false,
        underline: false,
        link: null,
      },
      styleOverrides: {},
      lineTypes: [],
    }
  }
}
