import { LayoutItem } from '../layoutItem/layoutItem'
import { PositionContext } from '../context/positionContext'
import { SizeContext } from '../context/sizeContext'
import { CssFlexWrap } from '../types/types'
import { FlexPositionItem } from './flexPositionItem'
import { FlexPositionLine } from './flexPositionLine'
import { FlexSizeItem } from './flexSizeItem'
import { FlexSizeLine } from './flexSizeLine'
import { FlexPositionLineImpl } from './flexPositionLineImpl'
import { FlexPositionItemImpl } from './flexPositionItemImpl'
import { AbsoluteItem } from '../absolute/absoluteItem'
import { ContainingContext } from '../context/containingContext'
import { FlexSizeLineImplPool } from './flexSizeLineImplPool'
import { FlexSizeLineImpl } from './flexSizeLineImpl'

export abstract class FlexLayout extends LayoutItem {
  flexSizeLinePool: FlexSizeLineImplPool = new FlexSizeLineImplPool(1)

  abstract createSizeItems(): FlexSizeItem[]
  abstract releaseSizeItems(items: FlexSizeItem[]): void

  abstract getMainSize(): number
  abstract getCrossSize(): number

  abstract computeMainSize(context: SizeContext, items: FlexSizeItem[]): number
  abstract computeCrossSize(
    mainSize: number,
    context: SizeContext,
    lines: FlexSizeLine[]
  ): number

  abstract sizeSelf(mainSize: number, crossSize: number): void

  resize = (context: SizeContext): void => {
    if (this.resizeCached(context)) return

    this.computeOutline(context)

    const childContext = this.createChildSizeContext(context)

    const items = this.createSizeItems()
    for (const item of items) {
      item.initializeMainAxis(childContext)
    }

    const mainSize = this.computeMainSize(context, items)
    const gap = this.computeTotalGap(context, items)

    const lines = this.createSizeLines(mainSize, gap, items)
    for (const line of lines) {
      line.initialize()
    }

    this.setLineMainAxis(childContext, mainSize, lines)

    const crossSize = this.computeCrossSize(mainSize, context, lines)

    for (const line of lines) {
      line.applyCrossAxisSize(crossSize, childContext)
    }

    this.sizeSelf(mainSize, crossSize)

    this.sizeOutOfFlowItems(context)

    this.cacheSizes(context)

    this.sizeContextPool!.release(childContext)
    this.releaseSizeItems(items)
    for (const line of lines) {
      this.flexSizeLinePool!.release(line)
    }
  }

  place = (context: PositionContext): void => {
    this.placeSelf(context)

    const items = this.createPositionItems()
    for (const item of items) {
      item.initialize(
        this.element!.css.flexDirection,
        this.element!.css.alignItems
      )
    }

    const mainSize = this.getMainSize()
    const positionContext = this.createChildPositionContext(context)
    const containingContext = this.createChildContainingContext(positionContext)

    const lines = this.createPositionLines(mainSize, items)
    for (const line of lines) {
      line.initialize(containingContext)
      line.place(positionContext)
    }

    this.placeOutOfFlowItems(positionContext)
  }

  protected setLineMainAxis = (
    context: SizeContext,
    size: number,
    lines: FlexSizeLine[]
  ): void => {
    for (const line of lines) {
      line.applyMainAxisSize(size, context)
    }
  }

  protected createSizeLines = (
    size: number,
    gap: number,
    items: FlexSizeItem[]
  ): FlexSizeLineImpl[] => {
    const wraps = this.getFlexWrap() === CssFlexWrap.wrap

    type ItemSet = {
      items: FlexSizeItem[]
      baseSize: number
    }
    const itemSets: ItemSet[] = []
    let currentSet: ItemSet = { items: [], baseSize: 0 }

    for (let i = 0; i < items.length; i++) {
      if (currentSet.items.length === 0) {
        currentSet.items.push(items[i])
        currentSet.baseSize += items[i].baseSize
      } else if (currentSet.baseSize + items[i].baseSize <= size || !wraps) {
        currentSet.items.push(items[i])
        currentSet.baseSize += items[i].baseSize
      } else {
        itemSets.push(currentSet)
        currentSet = { items: [items[i]], baseSize: items[i].baseSize }
      }
    }

    if (currentSet.items.length > 0) {
      itemSets.push(currentSet)
    }

    const lines: FlexSizeLineImpl[] = []
    for (const set of itemSets) {
      const line = this.flexSizeLinePool!.fromItems(set.items)
      line.totalGap = gap
      lines.push(line)
    }

    return lines
  }

  protected createPositionItems = (): FlexPositionItem[] => {
    const items: FlexPositionItem[] = []
    for (const child of this.children) {
      if (!child.isInFlow()) continue
      const item = new FlexPositionItemImpl(child)
      items.push(item)
    }
    return items
  }

  protected createPositionLines = (
    size: number,
    items: FlexPositionItem[]
  ): FlexPositionLine[] => {
    const lines: FlexPositionLine[] = []
    const wraps = this.getFlexWrap() === CssFlexWrap.wrap

    let line = new FlexPositionLineImpl(this)
    for (const item of items) {
      if (line.items.length === 0) {
        line.items.push(item)
      } else if (line.contentMainSize + item.mainSize <= size || !wraps) {
        line.items.push(item)
      } else {
        lines.push(line)
        line = new FlexPositionLineImpl(this)
        line.items.push(item)
      }
    }

    if (line.items.length > 0) {
      lines.push(line)
    }

    return lines
  }

  protected computeTotalGap(
    context: SizeContext,
    items: FlexSizeItem[]
  ): number {
    const containingContext = ContainingContext.fromSizeContext(context)
    return this.getGap(containingContext) * (items.length - 1)
  }

  protected placeOutOfFlowItems = (context: PositionContext): void => {
    for (const child of this.children) {
      if (!child.isInFlow()) {
        const absoluteItem = new AbsoluteItem(child)
        absoluteItem.place(context)
      }
    }
  }
}
