import OnboardingCTA from 'components/Library/Onboarding/OnboardingCta'
import OnboardingDivider from 'components/Library/Onboarding/OnboardingDivider'
import OnboardingInputField from 'components/Library/Onboarding/OnboardingInputField'
import OnboardingInputWrapper from 'components/Library/Onboarding/OnboardingInputWrapper'
import OnboardingLogo from 'components/Library/Onboarding/OnboardingLogo'
import OnboardingPanel from 'components/Library/Onboarding/OnboardingPanel'
import OnboardingRedirectBlock from 'components/Library/Onboarding/OnboardingRedirectBlock'
import OnboardingTitle from 'components/Library/Onboarding/OnboardingTitle'
import OnboardingBackground from 'components/Library/Onboarding/OnboardingBackground'
import useNavigatePage from 'hooks/admin/useNavigatePage'
import useRedirectLoggedIn from 'hooks/admin/useRedirectLoggedIn'
import useUserService from 'hooks/services/useUserService'
import { useSetRecoilState } from 'recoil'
import { useCallback, useEffect, useState } from 'react'
import useToast from 'hooks/editor/useToast'
import { userInfoState } from '../Projects/Projects'
import axios from 'axios'
import useAnalytics from 'hooks/admin/useAnalytics'

export default function Login() {
  useRedirectLoggedIn()

  const { metricLogin } = useAnalytics()
  const toast = useToast()
  const userService = useUserService()
  const setUserInfo = useSetRecoilState(userInfoState)
  const { toProjects, toSignUp, toReset } = useNavigatePage()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleSumbit = useCallback(async () => {
    if (!email || !password) {
      toast(`Please fill in all fields.`, 'error')
      return
    }
    try {
      await userService.login({ email, password })
      const userInfo = await userService.getUserInfo()
      if (!userInfo || !userInfo.id) return
      setUserInfo(userInfo)
      metricLogin()
      toProjects()
    } catch (e) {
      if (!axios.isAxiosError(e)) return
      if (e.response?.status === 401) {
        toast('Invalid login information, please try again.', 'error')
      } else {
        toast('Repaint is offline, please try again later.', 'error')
      }
    }
  }, [
    email,
    password,
    userService,
    toProjects,
    setUserInfo,
    toast,
    metricLogin,
  ])

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        handleSumbit()
      }
    }

    window.addEventListener('keydown', handleKeyDown)
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [email, password, handleSumbit])

  return (
    <OnboardingBackground>
      <OnboardingLogo />
      <OnboardingPanel>
        <OnboardingTitle>Login</OnboardingTitle>
        <OnboardingInputWrapper>
          <OnboardingInputField
            icon="User"
            label="Email"
            placeholder="Email"
            value={email}
            setValue={setEmail}
          />
          <OnboardingInputField
            icon="LockClosed"
            label="Password"
            subLabel="Forgot password?"
            onClickSubLabel={toReset}
            placeholder="Password"
            value={password}
            setValue={setPassword}
            password={true}
          />
        </OnboardingInputWrapper>
        <OnboardingCTA onClick={handleSumbit}>Login</OnboardingCTA>
        <OnboardingDivider />
        <OnboardingRedirectBlock
          text="Don't have an account?"
          buttonLabel="Signup"
          onClick={toSignUp}
        />
      </OnboardingPanel>
    </OnboardingBackground>
  )
}
