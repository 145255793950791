import {
  Command,
  CreateNode,
  DeleteNode,
  SetNodeAttribute,
  SetChildOrder,
  SetParentNode,
  CommandHandler,
  SetNodeSelector,
  ResetNodeAttribute,
} from './types'
import { NodeFactory } from 'application/node'
import { ClientDocumentHelper } from '../document/helper'

export class NodeCommandHandler implements CommandHandler {
  private nodeFactory: NodeFactory
  private documentHelper: ClientDocumentHelper

  constructor(nodeFactory: NodeFactory, document: ClientDocumentHelper) {
    this.nodeFactory = nodeFactory
    this.documentHelper = document
  }

  handle = (command: Command): void => {
    switch (command.type) {
      case 'createNode':
        this.createNode(command)
        return
      case 'deleteNode':
        this.deleteNode(command)
        return
      case 'setParent':
        this.setParent(command)
        return
      case 'setChildOrder':
        this.setNodeChildOrder(command)
        return
      case 'setNodeAttribute':
        this.setNodeAttribute(command)
        return
      case 'resetNodeAttribute':
        this.resetNodeAttribute(command)
        return
      case 'setNodeSelector':
        this.setNodeSelector(command)
        return
    }
  }

  private createNode = (command: CreateNode): void => {
    const node = this.nodeFactory.createNode(command.params.settings)
    if (!node) return

    this.documentHelper.addNode(node, true)
  }

  private deleteNode = (command: DeleteNode): void => {
    this.documentHelper.deleteNode(command.params.id)
  }

  private setParent = (command: SetParentNode): void => {
    this.documentHelper.setNodeParent(
      command.params.id,
      command.params.parentId,
      command.params.index
    )
  }

  private setNodeChildOrder = (command: SetChildOrder): void => {
    this.documentHelper.setNodeChildOrder(
      command.params.parentId,
      command.params.children
    )
  }

  private setNodeAttribute = (command: SetNodeAttribute): void => {
    this.documentHelper.setNodeAttributes(
      command.params.id,
      command.params.base,
      command.params.style,
      command.params.selector
    )
  }

  private resetNodeAttribute = (command: ResetNodeAttribute): void => {
    this.documentHelper.resetNodeAttribute(
      command.params.id,
      command.params.keys
    )
  }

  private setNodeSelector = (command: SetNodeSelector): void => {
    this.documentHelper.setNodeSelector(
      command.params.id,
      command.params.breakpoint,
      command.params.pseudo
    )
  }
}
