import { useCallback, useEffect, useState } from 'react'

interface HoveredProps {
  ref?: React.RefObject<HTMLElement>
}

export default function useHovered<T>({ ref }: HoveredProps) {
  const [hovered, setHovered] = useState<T | null>(null)

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (hovered === null) return

      const node = ref?.current
      if (!node) {
        setHovered(null)
        return
      }

      if (isTargetDescendant(e.target as HTMLElement, node)) return
      setHovered(null)
    },
    [hovered, ref]
  )

  useEffect(() => {
    document.addEventListener('mousemove', handleMouseMove)

    return () => {
      document.removeEventListener('mousemove', handleMouseMove)
    }
  }, [handleMouseMove, hovered, ref])

  return {
    hovered,
    setHovered,
  }
}

function isTargetDescendant(
  target: HTMLElement | null,
  node: HTMLElement | null
) {
  if (!target) return false
  if (target === node) return true
  return isTargetDescendant(target.parentElement, node)
}
