import { Action, ActionEventResult, ActionHandler } from '../types'
import { Point } from 'application/shapes'
import { CoordinatesConversion } from 'application/camera'
import { NodeSelectionAction } from 'application/action'
import { CommandHandler } from 'application/client'
import { HapticMultiselectWindow } from 'editor/haptic/multiselectWindow'

export class MultiselectAction implements ActionHandler {
  private commandHandler: CommandHandler
  private selection: NodeSelectionAction
  private haptic: HapticMultiselectWindow
  private coordinates: CoordinatesConversion
  private startPoint: Point

  constructor(
    commandHandler: CommandHandler,
    selection: NodeSelectionAction,
    haptic: HapticMultiselectWindow,
    coordinates: CoordinatesConversion,
    startPoint: Point
  ) {
    this.commandHandler = commandHandler
    this.selection = selection
    this.haptic = haptic
    this.coordinates = coordinates
    this.startPoint = startPoint
  }

  getType = (): Action => {
    return 'multiselect'
  }

  onMouseMove = (e: MouseEvent): ActionEventResult => {
    const point = this.coordinates.get(e)

    this.selection.multiselectInRectangle(this.startPoint, point)

    this.setWindow(point)

    return { done: false, passthrough: false }
  }

  onMouseUp = (e: MouseEvent): ActionEventResult => {
    const point = this.coordinates.get(e)

    this.selection.multiselectInRectangle(this.startPoint, point)
    this.commandHandler.handle({ type: 'commit' })

    this.clearWindow()

    return { done: true, passthrough: false }
  }

  private setWindow = (point: Point) => {
    const rectangle = {
      x: Math.min(this.startPoint.x, point.x),
      y: Math.min(this.startPoint.y, point.y),
      w: Math.abs(this.startPoint.x - point.x),
      h: Math.abs(this.startPoint.y - point.y),
    }
    this.haptic.setRectangle(rectangle)
  }

  private clearWindow = () => {
    this.haptic.setRectangle(null)
  }
}
