import {
  isUnitFlex3,
  isUnitFlex3Auto,
  Properties,
  UnitFlex3,
  UnitFlex3Auto,
  UnitSize,
  unitSizeToString,
} from 'application/export/css'
import { TailwindClassRenderer } from '../types'

export class TailwindClassFlex implements TailwindClassRenderer {
  render = (properties: Partial<Properties>): string[] => {
    const classes: string[] = []

    const flexDirection = properties.flexDirection
    const flexWrap = properties.flexWrap
    const justifyContent = properties.justifyContent
    const alignItems = properties.alignItems
    const alignSelf = properties.alignSelf
    const gap = properties.gap
    const flex = properties.flex

    if (flexDirection !== undefined) {
      classes.push(...this.renderFlexDirection(flexDirection))
    }

    if (flexWrap !== undefined) {
      classes.push(...this.renderFlexWrap(flexWrap))
    }

    if (justifyContent !== undefined) {
      classes.push(...this.renderJustifyContent(justifyContent))
    }

    if (alignItems !== undefined) {
      classes.push(...this.renderAlignItems(alignItems))
    }

    if (alignSelf !== undefined) {
      classes.push(...this.renderAlignSelf(alignSelf))
    }

    if (gap !== undefined) {
      classes.push(...this.renderGap(gap))
    }

    if (flex !== undefined) {
      classes.push(...this.renderFlex(flex))
    }

    return classes
  }

  private renderFlexDirection = (
    flexDirection: 'row' | 'row-reverse' | 'column' | 'column-reverse'
  ): string[] => {
    switch (flexDirection) {
      case 'row':
        return []
      case 'row-reverse':
        return ['flex-row-reverse']
      case 'column':
        return ['flex-col']
      case 'column-reverse':
        return ['flex-col-reverse']
    }
  }

  private renderFlexWrap = (
    flexWrap: 'nowrap' | 'wrap' | 'wrap-reverse'
  ): string[] => {
    switch (flexWrap) {
      case 'nowrap':
        return []
      case 'wrap':
      case 'wrap-reverse':
        return [`flex-${flexWrap}`]
    }
  }

  private renderJustifyContent = (
    justifyContent: 'start' | 'end' | 'center' | 'space-between'
  ): string[] => {
    switch (justifyContent) {
      case 'start':
        return ['justify-start']
      case 'end':
        return ['justify-end']
      case 'center':
        return ['justify-center']
      case 'space-between':
        return ['justify-between']
    }
  }

  private renderAlignItems = (
    alignItems: 'start' | 'end' | 'center' | 'stretch'
  ): string[] => {
    switch (alignItems) {
      case 'start':
        return ['items-start']
      case 'end':
        return ['items-end']
      case 'center':
        return ['items-center']
      case 'stretch':
        return ['items-stretch']
    }
  }

  private renderAlignSelf = (
    alignSelf: 'auto' | 'start' | 'end' | 'center' | 'stretch'
  ): string[] => {
    switch (alignSelf) {
      case 'auto':
        return []
      case 'start':
        return ['self-start']
      case 'end':
        return ['self-end']
      case 'center':
        return ['self-center']
      case 'stretch':
        return ['self-stretch']
    }
  }

  private renderGap = (gap: UnitSize): string[] => {
    return [`gap-[${unitSizeToString(gap)}]`]
  }

  private renderFlex = (
    flex: number | UnitFlex3 | UnitFlex3Auto | 'none'
  ): string[] => {
    if (isUnitFlex3(flex)) {
      const [grow, shrink, basis] = flex
      if (grow === 1 && shrink === 1 && basis.value === 0) {
        return ['flex-1']
      } else if (grow === 0 && shrink === 0) {
        return ['flex-none']
      } else {
        return [`flex-[${grow}_${shrink}_${unitSizeToString(basis)}]`]
      }
    } else if (isUnitFlex3Auto(flex)) {
      const [grow, shrink] = flex
      if (grow === 1 && shrink === 1) {
        return ['flex-auto']
      } else {
        return [`flex-[${grow}_${shrink}_auto]`]
      }
    } else if (flex === 'none') {
      return ['flex-none']
    } else if (flex === 1) {
      return ['flex-1']
    } else {
      return [`flex-[${flex}_0_auto]`]
    }
  }
}
