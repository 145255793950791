import { IconKey } from 'assets/iconLibrary'
import { SvgJson, SvgPathTriangles } from './types'
import { getSvgTriangles, parseSvgToSvgJson } from './utils'

const titleIcons: IconKey[] = ['Frame', 'Page']

export class IconLoader {
  private loadedIconSvg: Partial<{
    [key in IconKey]: SvgJson
  }>
  private loadedIconTriangles: Partial<{
    [key in IconKey]: SvgPathTriangles
  }>

  constructor() {
    this.loadedIconSvg = {}
    this.loadedIconTriangles = {}
  }

  init = async () => {
    for (const key of titleIcons) {
      await this.loadIcon(key)
    }
  }

  loadIcon = async (key: IconKey) => {
    if (this.loadedIconSvg[key]) {
      return this.loadedIconSvg[key]
    }
    const response = await fetch(`/icons/Icon=${key}.svg`)
    const iconRaw = await response.text()
    const svg = parseSvgToSvgJson(iconRaw)
    this.loadedIconSvg[key] = svg

    return svg
  }

  getIcon = (key: IconKey): SvgJson | null => {
    return this.loadedIconSvg[key] || null
  }

  getIconTriangles = (key: IconKey): SvgPathTriangles | null => {
    const svg = this.getIcon(key)
    if (!svg) return null
    if (!this.loadedIconTriangles[key]) {
      this.loadedIconTriangles[key] = getSvgTriangles(svg)
    }
    return this.loadedIconTriangles[key] || null
  }
}
