import { ClientUpdateListener, Update } from 'application/client'
import { EditorHaptics, State } from 'application/textEditor'
import { Haptics } from 'application/textEditor/haptic/types'
import { ReadOnlyDocumentSelection } from 'application/selection'
import { Camera, CameraUpdateListener } from 'application/camera'
import { Canvas } from 'editor/canvas/canvas'
import { TextEditorTransformer } from './transformer/textEditor'

export const hapticTextEditorKey = 'textEditor'

export class HapticTextEditor
  implements ClientUpdateListener, CameraUpdateListener
{
  private documentSelection: ReadOnlyDocumentSelection
  private canvas: Canvas
  private hapticCalculator: EditorHaptics

  private haptics: Haptics | null
  private camera: Camera | null
  private cursorOn: boolean
  private cursorBlinkTimeout: NodeJS.Timeout | null

  constructor(
    documentSelection: ReadOnlyDocumentSelection,
    canvas: Canvas,
    hapticCalculator: EditorHaptics
  ) {
    this.documentSelection = documentSelection
    this.canvas = canvas
    this.hapticCalculator = hapticCalculator
    this.haptics = null
    this.camera = null
    this.cursorOn = true
    this.cursorBlinkTimeout = null
  }

  getTypes = (): Update['type'][] => {
    return ['text_editor_state']
  }

  onUpdate = (updates: Update[]): void => {
    for (const update of updates) {
      switch (update.type) {
        case 'text_editor_state':
          this.onTextState(update.data.state)
          break
      }
    }
  }

  onCamera = (camera: Camera): void => {
    this.camera = camera
    this.render()
  }

  private onTextState = (state: State | null): void => {
    if (state === null) {
      this.haptics = null
    } else {
      this.haptics = this.hapticCalculator.get(state)
      this.cursorOn = false
      this.startBlinking()
    }
    this.render()
  }

  private render = (): void => {
    if (!this.canvas.isReady()) return
    const window = this.documentSelection.getSelectionRectangle()
    if (this.haptics === null || window === null || this.camera === null) {
      this.canvas.deleteHaptic(hapticTextEditorKey)
    } else {
      this.canvas.setHaptic(
        hapticTextEditorKey,
        TextEditorTransformer.transform(
          this.canvas.getContext(),
          this.haptics,
          window,
          this.camera,
          this.cursorOn
        )
      )
    }
  }

  private blinkCursor = (): void => {
    if (this.haptics === null) {
      this.stopBlinking()
    } else {
      this.cursorOn = !this.cursorOn
      this.render()
    }
  }

  private startBlinking = (): void => {
    this.stopBlinking()
    this.cursorBlinkTimeout = setInterval(this.blinkCursor, 500)
  }

  private stopBlinking = (): void => {
    if (this.cursorBlinkTimeout) {
      clearInterval(this.cursorBlinkTimeout)
      this.cursorBlinkTimeout = null
    }
  }
}
