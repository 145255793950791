import { userInfoState } from 'components/Pages/Projects/Projects'
import useAuthService from 'hooks/services/useAuthService'
import useUserService from 'hooks/services/useUserService'
import { useEffect } from 'react'
import { useSetRecoilState } from 'recoil'

export default function usePopulateUserInfo() {
  const authService = useAuthService()
  const userService = useUserService()

  const setUserInfo = useSetRecoilState(userInfoState)

  useEffect(() => {
    const populateUserInfo = async () => {
      try {
        const userInfo = await userService.getUserInfo()
        setUserInfo(userInfo)
      } catch (e) {
        console.error('Error loading user info', e)
      }
    }

    populateUserInfo()
  }, [authService, userService, setUserInfo])
}
