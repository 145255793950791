export function colorToTailwind(color: string): string | null {
  const colorMap: { [key: string]: string } = {
    transparent: 'transparent',
    '#000000': 'black',
    '#ffffff': 'white',
    '#00000000': 'transparent',
  }

  if (color.toLowerCase() in colorMap) {
    return colorMap[color.toLowerCase()]
  }

  const namedColors = [
    'slate',
    'gray',
    'zinc',
    'neutral',
    'stone',
    'red',
    'lime',
    'green',
    'emerald',
    'teal',
    'cyan',
    'sky',
    'blue',
    'indigo',
    'violet',
    'purple',
    'fuchsia',
    'pink',
    'rose',
  ]

  for (const namedColor of namedColors) {
    if (color.toLowerCase() === namedColor) {
      return `${namedColor}-500`
    }
  }

  return null
}
