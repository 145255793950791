import { getOptionHotkeyPrefix } from 'components/Pages/Editor/RightClickMenu/ElementsRightClickMenu'

export type tooltipDirection = 'left' | 'right' | 'center'
export type tooltipKey =
  | 'LightMode'
  | 'DarkMode'
  | 'MainMenu'
  | 'TutorialTemplates'
  | 'DesignLibrary'
  | 'Page'
  | 'Frame'
  | 'Text'
  | 'Button'
  | 'Content'
  | 'Image'
  | 'Input'
  | 'Form'
  | 'Anchor'
  | 'Graphics'
  | 'Hand'
  | 'CollapseLayers'
  | 'PositionMode'
  | 'PositionTop'
  | 'PositionLeft'
  | 'PositionBottom'
  | 'PositionRight'
  | 'PositionSplit'
  | 'StickyGap'
  | 'AspectRatio'
  | 'AspectRatioCustom'
  | 'Width'
  | 'Height'
  | 'MaxWidth'
  | 'MaxHeight'
  | 'MinWidth'
  | 'MinHeight'
  | 'FlexSize'
  | 'AlignSelf'
  | 'Opacity'
  | 'Overflow'
  | 'BorderRadius'
  | 'BorderRadiusTopLeft'
  | 'BorderRadiusTopRight'
  | 'BorderRadiusBottomLeft'
  | 'BorderRadiusBottomRight'
  | 'BorderRadiusSplit'
  | 'FontFamily'
  | 'FontWeight'
  | 'FontSize'
  | 'FontAlignLeft'
  | 'FontAlignCenter'
  | 'FontAlignRight'
  | 'FontHugBoth'
  | 'FontHugHeight'
  | 'FontFixedSize'
  | 'FontLineSpacing'
  | 'FontLetterSpacing'
  | 'FontMissing'
  | 'UploadImage'
  | 'ObjectFit'
  | 'BorderWidth'
  | 'BorderMode'
  | 'BorderWidthTop'
  | 'BorderWidthRight'
  | 'BorderWidthBottom'
  | 'BorderWidthLeft'
  | 'Display'
  | 'DisplayBlock'
  | 'DisplayFlex'
  | 'DisplayHidden'
  | 'FlexRow'
  | 'FlexColumn'
  | 'FlexRowWrap'
  | 'FlexGap'
  | 'FlexAlignment'
  | 'FlexSpaceBetween'
  | 'PaddingLeft'
  | 'PaddingRight'
  | 'PaddingTop'
  | 'PaddingBottom'
  | 'PaddingVertical'
  | 'PaddingHorizontal'
  | 'PaddingSplit'
  | 'ShadowSettings'
  | 'ShadowX'
  | 'ShadowY'
  | 'ShadowBlur'
  | 'ShadowSpread'
  | 'BackgroundBlurRadius'
  | 'TransitionDuration'
  | 'TransitionTimingMode'
  | 'CursorMode'
  | 'Zoom'

type tooltipMap = {
  [key in tooltipKey]: {
    text: string
    subtext?: string
  }
}

export const TooltipMap: tooltipMap = {
  // Menus
  LightMode: { text: 'Light Mode' },
  DarkMode: { text: 'Dark Mode' },

  // Top Left Toolbar
  MainMenu: { text: 'Main Menu' },

  // Tutorial
  TutorialTemplates: { text: 'Click this button to access templates' },

  // Tools
  DesignLibrary: { text: 'Design Library', subtext: 'D' },
  Page: { text: 'Page', subtext: 'P' },
  Frame: { text: 'Frame', subtext: 'F' },
  Text: { text: 'Text', subtext: 'T' },
  Button: { text: 'Button', subtext: 'B' },
  Content: { text: 'Content', subtext: 'C' },
  Image: { text: 'Image', subtext: 'I' },
  Input: { text: 'Input', subtext: 'N' },
  Form: { text: 'Form', subtext: 'O' },
  Anchor: { text: 'Anchor', subtext: 'A' },
  Graphics: { text: 'Graphics', subtext: 'G' },
  Hand: { text: 'Hand', subtext: 'Space' },

  // Left Sidepanel
  CollapseLayers: {
    text: 'Open / Close Layers',
    subtext: getOptionHotkeyPrefix() + 'L',
  },

  // General Settings Panels
  PositionMode: { text: 'Position Mode' },
  PositionTop: { text: 'Top' },
  PositionLeft: { text: 'Left' },
  PositionBottom: { text: 'Bottom' },
  PositionRight: { text: 'Right' },
  PositionSplit: { text: 'Split Position' },
  StickyGap: { text: 'Sticky Gap' },
  AspectRatio: { text: 'Aspect Ratio' },
  AspectRatioCustom: { text: 'Custom Aspect Ratio' },
  Width: { text: 'Width' },
  Height: { text: 'Height' },
  MaxWidth: { text: 'Max Width' },
  MaxHeight: { text: 'Max Height' },
  MinWidth: { text: 'Min Width' },
  MinHeight: { text: 'Min Height' },
  FlexSize: { text: 'Flex' },
  AlignSelf: { text: 'Align Self' },
  Opacity: { text: 'Opacity' },
  Overflow: { text: 'Overflow' },
  BorderRadius: { text: 'Border Radius' },
  BorderRadiusTopLeft: { text: 'Top Left Border Radius' },
  BorderRadiusTopRight: { text: 'Top Right Border Radius' },
  BorderRadiusBottomLeft: { text: 'Bottom Left Border Radius' },
  BorderRadiusBottomRight: { text: 'Bottom Right Border Radius' },
  BorderRadiusSplit: { text: 'Split Border Radius' },

  // Text Settings Panel
  FontFamily: { text: 'Font Family' },
  FontWeight: { text: 'Font Weight' },
  FontSize: { text: 'Font Size' },
  FontAlignLeft: { text: 'Text Align Left' },
  FontAlignCenter: { text: 'Text Align Center' },
  FontAlignRight: { text: 'Text Align Right' },
  FontHugBoth: { text: 'Hug Text' },
  FontHugHeight: { text: 'Hug Height' },
  FontFixedSize: { text: 'Fixed Size' },
  FontLineSpacing: { text: 'Line Spacing' },
  FontLetterSpacing: { text: 'Letter Spacing' },
  FontMissing: { text: 'Font Missing or Renamed' },

  // Image Settings Panel
  UploadImage: { text: 'Upload Image' },
  ObjectFit: { text: 'Object Fit' },

  // Border Settings Panel
  BorderWidth: { text: 'Border Width' },
  BorderMode: { text: 'Border Mode' },
  BorderWidthTop: { text: 'Top Border Width' },
  BorderWidthRight: { text: 'Right Border Width' },
  BorderWidthBottom: { text: 'Bottom Border Width' },
  BorderWidthLeft: { text: 'Left Border Width' },

  // Layout Settings Panel
  Display: { text: 'Display' },
  DisplayBlock: { text: 'Block' },
  DisplayFlex: { text: 'Flex' },
  DisplayHidden: { text: 'Hidden' },
  FlexRow: { text: 'Row' },
  FlexColumn: { text: 'Column' },
  FlexRowWrap: { text: 'Row Wrap' },
  FlexGap: { text: 'Gap' },
  FlexAlignment: { text: 'Alignment' },
  FlexSpaceBetween: { text: 'Space Between' },

  // Padding Settings Panel
  PaddingLeft: { text: 'Left Padding' },
  PaddingRight: { text: 'Right Padding' },
  PaddingTop: { text: 'Top Padding' },
  PaddingBottom: { text: 'Bottom Padding' },
  PaddingVertical: { text: 'Vertical Padding' },
  PaddingHorizontal: { text: 'Horizontal Padding' },
  PaddingSplit: { text: 'Split Padding' },

  // Shadow Settings Panel
  ShadowSettings: { text: 'Shadow Settings' },
  ShadowX: { text: 'X Offset' },
  ShadowY: { text: 'Y Offset' },
  ShadowBlur: { text: 'Blur' },
  ShadowSpread: { text: 'Spread' },

  // Background Blur Panel
  BackgroundBlurRadius: { text: 'Blur Radius' },

  // Transition Settings Panel
  TransitionDuration: { text: 'Transition Duration' },
  TransitionTimingMode: { text: 'Transition Timing Mode' },

  // Cursor Settings Panel
  CursorMode: { text: 'Cursor Mode' },

  // UI
  Zoom: { text: 'Zoom' },
}
