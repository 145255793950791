import { ReadOnlyNode } from 'application/node'
import { UpdateHandler } from './update'

export class SelectionUpdateAccumulator {
  private handler: UpdateHandler

  constructor(handler: UpdateHandler) {
    this.handler = handler
  }

  onChange = (_: ReadOnlyNode[], current: ReadOnlyNode[]): void => {
    this.handler.addUpdate({
      type: 'selection',
      data: {
        ids: current.map((node) => node.getId()),
      },
    })
  }

  onCanvasChange = (_: string, current: string): void => {
    this.handler.addUpdate({
      type: 'selected_canvas',
      data: {
        id: current,
      },
    })
  }
}
