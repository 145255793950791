import _ from 'lodash'
import { State } from './types'

export class TextEditorState {
  private state: State

  constructor(state: State) {
    this.state = state
  }

  get = (): State => {
    return _.cloneDeep(this.state)
  }

  set = (state: State): void => {
    this.state = state
  }
}
