import {
  HistoryEvent,
  HistoryEventNodeCreate,
  HistoryEventNodeDelete,
  HistoryEventNodeUpdateChildren,
  HistoryEventNodeUpdateParent,
  HistoryActionListener,
  HistoryEventNodeUpdateBaseAttributes,
  HistoryEventNodeUpdateBreakpoint,
  HistoryEventNodeUpdateStyleAttributes,
  HistoryEventNodeUpdatePseudo,
} from 'application/history'
import { WriteDocument } from 'application/document'
import { BaseMap, StyleMap } from 'application/attributes'

export class DocumentHistoryActionHandler implements HistoryActionListener {
  private document: WriteDocument

  constructor(document: WriteDocument) {
    this.document = document
  }

  onUndo = (event: HistoryEvent): void => {
    switch (event.type) {
      case 'node_create':
        this.handleCreateUndo(event)
        return
      case 'node_delete':
        this.handleDeleteUndo(event)
        return
      case 'node_update_base_attributes':
        this.handleUpdateBaseUndo(event)
        return
      case 'node_update_style_attributes':
        this.handleUpdateStyleUndo(event)
        return
      case 'node_update_breakpoint':
        this.handleUpdateBreakpointUndo(event)
        return
      case 'node_update_pseudo':
        this.handleUpdatePseudoUndo(event)
        return
      case 'node_update_parent':
        this.handleUpdateParentUndo(event)
        return
      case 'node_update_children':
        this.handleUpdateChildrenUndo(event)
        return
    }
  }

  onRedo = (event: HistoryEvent): void => {
    switch (event.type) {
      case 'node_create':
        this.handleCreateRedo(event)
        return
      case 'node_delete':
        this.handleDeleteRedo(event)
        return
      case 'node_update_base_attributes':
        this.handleUpdateBaseRedo(event)
        return
      case 'node_update_style_attributes':
        this.handleUpdateStyleRedo(event)
        return
      case 'node_update_breakpoint':
        this.handleUpdateBreakpointRedo(event)
        return
      case 'node_update_pseudo':
        this.handleUpdatePseudoRedo(event)
        return
      case 'node_update_parent':
        this.handleUpdateParentRedo(event)
        return
      case 'node_update_children':
        this.handleUpdateChildrenRedo(event)
        return
    }
  }

  private handleCreateUndo = (event: HistoryEventNodeCreate): void => {
    const node = event.data.node
    this.document.deleteNode(node.getId())
  }

  private handleCreateRedo = (event: HistoryEventNodeCreate): void => {
    const node = event.data.node
    try {
      this.document.addNode(node.clone())
    } catch (e) {
      console.error('Error redoing create node event: ', e)
    }
  }

  private handleDeleteUndo = (event: HistoryEventNodeDelete): void => {
    const node = event.data.node
    try {
      this.document.addNode(node.clone())
    } catch (e) {
      console.error('Error undoing delete node event: ', e)
    }
  }

  private handleDeleteRedo = (event: HistoryEventNodeDelete): void => {
    const node = event.data.node
    try {
      this.document.deleteNode(node.getId())
    } catch (e) {
      console.error('Error redoing delete node event: ', e)
    }
  }

  private handleUpdateBaseUndo = (
    event: HistoryEventNodeUpdateBaseAttributes
  ): void => {
    const { id, initial } = event.data
    const node = this.document.getNode(id)
    if (!node) return

    for (const key in initial) {
      const typedKey = key as keyof BaseMap
      const value = initial[typedKey]
      node.setBaseAttribute(typedKey, value)
    }
  }

  private handleUpdateBaseRedo = (
    event: HistoryEventNodeUpdateBaseAttributes
  ): void => {
    const { id, current } = event.data
    const node = this.document.getNode(id)
    if (!node) return

    for (const key in current) {
      const typedKey = key as keyof BaseMap
      const value = current[typedKey]
      node.setBaseAttribute(typedKey, value)
    }
  }

  private handleUpdateStyleUndo = (
    event: HistoryEventNodeUpdateStyleAttributes
  ): void => {
    const { id, initial } = event.data
    const node = this.document.getNode(id)
    if (!node) return

    for (const key in initial.style) {
      const typedKey = key as keyof StyleMap
      const value = initial.style[typedKey]
      node.setStyleAttribute(typedKey, value, initial.selector)
    }
  }

  private handleUpdateStyleRedo = (
    event: HistoryEventNodeUpdateStyleAttributes
  ): void => {
    const { id, current } = event.data
    const node = this.document.getNode(id)
    if (!node) return

    for (const key in current.style) {
      const typedKey = key as keyof StyleMap
      const value = current.style[typedKey]
      node.setStyleAttribute(typedKey, value, current.selector)
    }
  }

  private handleUpdateBreakpointUndo = (
    event: HistoryEventNodeUpdateBreakpoint
  ): void => {
    const { id, initial } = event.data
    const node = this.document.getNode(id)
    if (!node) return

    node.setActiveBreakpoint(initial)
  }

  private handleUpdateBreakpointRedo = (
    event: HistoryEventNodeUpdateBreakpoint
  ): void => {
    const { id, current } = event.data
    const node = this.document.getNode(id)
    if (!node) return

    node.setActiveBreakpoint(current)
  }

  private handleUpdatePseudoUndo = (
    event: HistoryEventNodeUpdatePseudo
  ): void => {
    const { id, initial } = event.data
    const node = this.document.getNode(id)
    if (!node) return

    node.setActivePseudo(initial)
  }

  private handleUpdatePseudoRedo = (
    event: HistoryEventNodeUpdatePseudo
  ): void => {
    const { id, current } = event.data
    const node = this.document.getNode(id)
    if (!node) return

    node.setActivePseudo(current)
  }

  private handleUpdateParentUndo = (
    event: HistoryEventNodeUpdateParent
  ): void => {
    const { id, initial } = event.data
    const node = this.document.getNode(id)
    if (!node) return

    node.setParent(initial)
  }

  private handleUpdateParentRedo = (
    event: HistoryEventNodeUpdateParent
  ): void => {
    const { id, current } = event.data
    const node = this.document.getNode(id)
    if (!node) return

    node.setParent(current)
  }

  private handleUpdateChildrenUndo = (
    event: HistoryEventNodeUpdateChildren
  ): void => {
    const { id, initial } = event.data
    const node = this.document.getNode(id)
    if (!node) return

    node.setChildren(initial)
  }

  private handleUpdateChildrenRedo = (
    event: HistoryEventNodeUpdateChildren
  ): void => {
    const { id, current } = event.data
    const node = this.document.getNode(id)
    if (!node) return

    node.setChildren(current)
  }
}
