import { ClientDocumentHelper } from 'application/client/document/helper'
import { DocumentSelection } from 'application/selection'
import { CopyStylesSnapshot } from '../types'
import { Node } from 'application/node'
import { PasteAttributesFilter } from './filter'
import { ApplyPastedAttributes } from './apply'

export class PasteAttributes {
  private documentHelper: ClientDocumentHelper
  private documentSelection: DocumentSelection
  private filter: PasteAttributesFilter
  private apply: ApplyPastedAttributes

  constructor(
    documentHelper: ClientDocumentHelper,
    documentSelection: DocumentSelection
  ) {
    this.documentHelper = documentHelper
    this.documentSelection = documentSelection
    this.filter = new PasteAttributesFilter()
    this.apply = new ApplyPastedAttributes()
  }

  pasteAttributes = (snapshot: CopyStylesSnapshot): void => {
    const nodes = this.getNodes()
    for (const node of nodes) {
      const type = node.getBaseAttribute('type')
      const filteredSnapshot = this.filter.filter(type, snapshot)
      this.apply.apply(node, filteredSnapshot)
    }
  }

  private getNodes = (): Node[] => {
    return this.documentSelection
      .getSelected()
      .map((n) => this.documentHelper.getNode(n.getId()))
      .filter((n) => n !== undefined) as Node[]
  }
}
