import '@dotlottie/player-component'

interface RepaintLogoAnimatedProps {
  size?: number
  color?: 'black' | 'white'
}

export default function RepaintLogoAnimated({
  size = 24,
  color = 'white',
}: RepaintLogoAnimatedProps) {
  const file = color === 'black' ? 'SplashLight' : 'SplashDark'
  const src = `${process.env.PUBLIC_URL}/images/${file}.lottie`

  return (
    <dotlottie-player
      style={{
        width: size,
        height: size,
      }}
      src={src}
      loop
      autoplay
      speed={2}
    />
  )
}
