import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import { GradientStepState } from '../Tabs/MultistepGradientTab'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import TextBlock from 'components/Library/Components/Text/TextBlock/TextBlock'
import IconBlock from 'components/Library/Components/IconBlock/IconBlock/IconBlock'
import NumberBlock from 'components/Library/Components/NumberBlock/NumberBlock'
import ColorBlock from '../../ColorBlock/ColorBlock'
import { DesignColor } from 'themes'
import FillPopup from '../FillPopup'
import { useRef, useState } from 'react'
import useCommit from 'hooks/editor/useCommit'
import { AttributeGradientStep } from 'application/attributes'
import { createNewColor } from 'application/color'
import { v4 } from 'uuid'

interface GradientStepRowsSectionProps {
  steps: AttributeGradientStep[]
  stepState: GradientStepState
  update: (steps: AttributeGradientStep[]) => void
}

export default function GradientStepRowsSection({
  steps,
  stepState,
  update,
}: GradientStepRowsSectionProps) {
  const commit = useCommit()
  const [open, setOpen] = useState(false)
  const sectionRef = useRef<HTMLDivElement>(null)

  function addStep() {
    var position = 100
    if (steps.length >= 2) {
      position =
        (steps[steps.length - 1].position + steps[steps.length - 2].position) /
        2
    }
    const newStep = {
      key: v4().replace(/-/g, '').slice(0, 12),
      position: position,
      color: createNewColor(),
    }
    update([...steps, newStep])
    commit()
  }

  function updateStep(key: string, step: AttributeGradientStep) {
    const newSteps = [...steps]
    const index = steps.findIndex((s) => s.key === key)
    newSteps[index] = step
    update(newSteps)
  }

  function removeStep(key: string) {
    const newSteps = [...steps]
    const index = steps.findIndex((s) => s.key === key)
    newSteps.splice(index, 1)
    update(newSteps)
    commit()
  }

  function toggleColorPopup(key: string) {
    if (stepState.selectedStep === key) {
      setOpen((open) => !open)
      return
    }
    stepState.setSelectedStep(key)
    setOpen(true)
  }

  const selectedStep = steps.find((s) => s.key === stepState.selectedStep)

  return (
    <>
      <PanelSection sectionRef={sectionRef}>
        <PanelRow>
          <TextBlock mode="title" width={192}>
            Colors
          </TextBlock>
          <IconBlock icon="Plus" onClick={addStep} />
        </PanelRow>
        {steps.map((step) => (
          <GradientStepRow
            key={step.key}
            step={step}
            stepState={stepState}
            updateStep={(step) => updateStep(step.key, step)}
            removeStep={() => removeStep(step.key)}
            stepCount={steps.length}
            open={open}
            setOpen={() => toggleColorPopup(step.key)}
          />
        ))}
      </PanelSection>
      {open && stepState.selectedStep && selectedStep && (
        <FillPopup
          fill={{ type: 'color', color: selectedStep.color }}
          updateFill={(fill) => {
            if (fill.color === undefined) return
            updateStep(selectedStep.key, { ...selectedStep, color: fill.color })
          }}
          exceptionRef={sectionRef}
          modes={['color']}
          setOpen={setOpen}
          popupRight={232 * 2 + 24}
          ignorePopup={false}
        />
      )}
    </>
  )
}

function GradientStepRow({
  step,
  stepState,
  updateStep,
  removeStep,
  stepCount,
  open,
  setOpen,
}: {
  step: AttributeGradientStep
  stepState: GradientStepState
  updateStep: (step: AttributeGradientStep) => void
  removeStep: () => void
  stepCount: number
  open: boolean
  setOpen: () => void
}) {
  return (
    <>
      <PanelRow
        background={getRowColor(step, stepState)}
        onMouseUp={() => {
          setTimeout(() => stepState.setSelectedStep(step.key), 0)
        }}
      >
        <NumberBlock
          value={step.position}
          setValue={(position) => updateStep({ ...step, position })}
          width={48}
          max={100}
          unit="%"
        />
        <ColorBlock
          color={step.color}
          updateColor={(color) => updateStep({ ...step, color })}
          open={stepState.selectedStep === step.key && open}
          setOpen={setOpen}
        />
        <NumberBlock
          value={step.color.a * 100}
          setValue={(a) =>
            updateStep({ ...step, color: { ...step.color, a: a / 100 } })
          }
          width={48}
          min={0}
          max={100}
          unit="%"
        />
        {stepCount > 1 && <IconBlock icon="X" onClick={removeStep} />}
      </PanelRow>
    </>
  )
}

function getRowColor(
  step: AttributeGradientStep,
  stepState: GradientStepState
) {
  return stepState.selectedStep === step.key ? DesignColor('inputTint') : ''
}
