import { DesignColor } from 'themes'

interface PanelColumnProps {
  width?: 112 | 168 | 232 | 288 | 'fill'
  paddingV?: 0 | 8

  children?: React.ReactNode
  right?: boolean

  background?: string

  scroll?: boolean
}

export default function PanelColumn({
  width = 232,
  paddingV = 0,
  children,
  right = false,
  background,
  scroll = false,
}: PanelColumnProps) {
  const boxShadow = getBoxShadow(right)
  return (
    <div
      style={{
        width: width !== 'fill' ? width : undefined,
        flex: width === 'fill' ? 1 : undefined,
        flexShrink: width === 'fill' ? 1 : undefined,
        alignSelf: 'stretch',
        padding: `${paddingV}px 0px`,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'flex',
        overflow: scroll ? 'scroll' : 'hidden',
        boxShadow,
        background,
      }}
    >
      {children}
    </div>
  )
}

function getBoxShadow(right: boolean) {
  if (right) return 'none'
  return `inset -1px 0px 0px 0px ${DesignColor('panelBorder')}`
}
