import { SizeContext } from '../context/sizeContext'
import { LayoutItem } from '../layoutItem/layoutItem'
import { CssFlexAlign } from '../types/types'

export abstract class FlexSizeItem {
  layoutItem: LayoutItem | null = null

  baseSize: number
  mainSize: number
  computedSize: number

  minSize: number
  maxSize: number

  minContentMainSize: number
  maxContentMainSize: number

  grow: number
  shrink: number
  align: CssFlexAlign
  stretch: boolean

  scaledFlexShrinkFactor: number
  frozen: boolean

  constructor() {
    this.layoutItem = null

    this.baseSize = 0
    this.mainSize = 0
    this.computedSize = 0

    this.minSize = 0
    this.maxSize = Infinity

    this.minContentMainSize = 0
    this.maxContentMainSize = 0

    this.grow = 0
    this.shrink = 0
    this.align = CssFlexAlign.start

    this.scaledFlexShrinkFactor = 0
    this.frozen = false
    this.stretch = false
  }

  reset = (): void => {
    this.layoutItem = null

    this.baseSize = 0
    this.mainSize = 0
    this.computedSize = 0

    this.minSize = 0
    this.maxSize = Infinity

    this.minContentMainSize = 0
    this.maxContentMainSize = 0

    this.grow = 0
    this.shrink = 0
    this.align = CssFlexAlign.start

    this.scaledFlexShrinkFactor = 0
    this.frozen = false
    this.stretch = false
  }

  // Initialize the main axis
  // - baseSize
  // - mainSize
  // - minSize
  // - maxSize
  // - minContentMainSize
  // - maxContentMainSize
  // - grow
  // - shrink
  // - align
  // - scaledFlexShrinkFactor
  abstract initializeMainAxis(context: SizeContext): void

  // Apply the given main axis size
  abstract applyMainAxisSize(size: number, context: SizeContext): void

  // Return the post layout cross size
  abstract getCrossSize(): number

  // Apply the given main axis size and cross axis size
  abstract applyCrossAxisSize(
    mainSize: number,
    crossSize: number,
    context: SizeContext
  ): void
}
