import { ReadOnlyDocument } from 'application/document'
import { ReadOnlyNode } from 'application/node'
import { hasDependency } from './utils'
import {
  LayoutDependencyGraph,
  LayoutDependencyGraphFactory,
  LayoutDependencyNode,
} from './types'

export class TopDownDependencyGraphFactory
  implements LayoutDependencyGraphFactory
{
  private document: ReadOnlyDocument

  constructor(document: ReadOnlyDocument) {
    this.document = document
  }

  create = (ids: Set<string>): LayoutDependencyGraph => {
    const graph: LayoutDependencyGraph = {}
    const visited = new Set<string>()

    for (const id of ids) {
      const node = this.document.getNode(id)
      if (!node) continue

      const nodeType = node.getBaseAttribute('type')
      if (['root', 'canvas'].includes(nodeType)) continue

      this.getOrAddNode(node, graph)
      this.traverse(node, graph, visited)
    }

    return graph
  }

  createSubtree = (ids: Set<string>): LayoutDependencyGraph => {
    const graph: LayoutDependencyGraph = {}

    for (const id of ids) {
      const node = this.document.getNode(id)
      if (!node) continue

      const nodeType = node.getBaseAttribute('type')
      if (['root', 'canvas'].includes(nodeType)) continue

      this.traverseDown(node, graph)
    }

    return graph
  }

  private traverse = (
    node: ReadOnlyNode,
    graph: LayoutDependencyGraph,
    visited: Set<string>
  ): void => {
    if (visited.has(node.getId())) return
    visited.add(node.getId())
    this.getOrAddNode(node, graph)
    this.traverseUp(node, graph, visited)
    this.traverseDown(node, graph)
  }

  private traverseUp(
    node: ReadOnlyNode,
    graph: LayoutDependencyGraph,
    visited: Set<string>
  ): void {
    const parent = this.document.getParent(node)
    if (!parent) return

    const parentType = parent.getBaseAttribute('type')
    if (['root', 'canvas'].includes(parentType)) return

    this.addDependency(parent, node, graph)
    this.traverse(parent, graph, visited)
  }

  private traverseDown(node: ReadOnlyNode, graph: LayoutDependencyGraph): void {
    const children = node.getChildren()
    if (!children) return

    for (const child of children) {
      const childNode = this.document.getNode(child)
      if (!childNode) continue
      this.addDependency(node, childNode, graph)
      this.traverseDown(childNode, graph)
    }
  }

  private addDependency(
    dependent: ReadOnlyNode,
    dependency: ReadOnlyNode,
    graph: LayoutDependencyGraph
  ): void {
    const dependentNode = this.getOrAddNode(dependent, graph)
    const dependencyNode = this.getOrAddNode(dependency, graph)

    if (hasDependency(dependentNode, dependencyNode)) return

    dependentNode.dependencies.push(dependencyNode)
    dependencyNode.dependentOn.push(dependentNode)
  }

  private getOrAddNode(
    node: ReadOnlyNode,
    graph: LayoutDependencyGraph
  ): LayoutDependencyNode {
    const id = node.getId()
    if (graph[id]) return graph[id]

    const newNode: LayoutDependencyNode = {
      id: id,
      dependentOn: [],
      dependencies: [],
    }
    graph[id] = newNode

    return newNode
  }
}
