import { SizeContext } from '../context/sizeContext'
import { CssFlexWrap, LayoutItemType, SizeValueType } from '../types/types'
import { FlexLayout } from './flexLayout'
import { FlexSizeItem } from './flexSizeItem'
import { FlexSizeItemColumn } from './flexSizeItemColumn'
import { FlexSizeItemColumnPool } from './flexSizeItemColumnPool'
import { FlexSizeLine } from './flexSizeLine'

export class FlexLayoutColumn extends FlexLayout {
  itemPool: FlexSizeItemColumnPool = new FlexSizeItemColumnPool(1)

  getType = (): LayoutItemType => LayoutItemType.flex_column

  createSizeItems = (): FlexSizeItem[] => {
    const items: FlexSizeItem[] = []
    for (const child of this.children) {
      if (!child.isInFlow()) continue
      const item = this.itemPool!.fromItem(child)
      items.push(item)
    }
    return items
  }

  releaseSizeItems = (items: FlexSizeItem[]): void => {
    for (const item of items) {
      this.itemPool.release(item as FlexSizeItemColumn)
    }
  }

  getMainSize = (): number => this.getHeight()

  getCrossSize = (): number => this.getWidth()

  computeMainSize = (context: SizeContext, items: FlexSizeItem[]): number => {
    const wraps = this.getFlexWrap() === CssFlexWrap.wrap

    if (context.height.type === SizeValueType.exact) {
      return context.height.value - this.getOutlineHeight()
    }

    const height = this.innerHeight(context)
    const transferredHeight = this.innerTransferredHeight(context)

    if (transferredHeight !== undefined) return transferredHeight
    if (height !== undefined) return height

    let contentMinHeight = 0
    let contentMaxHeight = 0
    let contentMainSize = 0
    for (const item of items) {
      if (wraps) {
        contentMainSize = Math.max(contentMainSize, item.mainSize)
        contentMinHeight = Math.max(contentMinHeight, item.minContentMainSize)
        contentMaxHeight = Math.max(contentMaxHeight, item.maxContentMainSize)
      } else {
        contentMainSize += item.mainSize
        contentMinHeight += item.minContentMainSize
        contentMaxHeight += item.maxContentMainSize
      }
    }

    if (!wraps) {
      contentMinHeight += this.computeTotalGap(context, items)
      contentMaxHeight += this.computeTotalGap(context, items)
      contentMainSize += this.computeTotalGap(context, items)
    }

    switch (context.height.type) {
      case SizeValueType.absolute:
      case SizeValueType.auto:
        return this.minMaxHeight(contentMainSize, context)
      case SizeValueType.min_content:
        return this.minMaxHeight(contentMinHeight, context)
      case SizeValueType.max_content:
        return this.minMaxHeight(contentMaxHeight, context)
    }
  }

  computeCrossSize = (
    mainSize: number,
    context: SizeContext,
    lines: FlexSizeLine[]
  ): number => {
    if (context.width.type === SizeValueType.exact) {
      return context.width.value - this.getOutlineWidth()
    }

    switch (context.width.type) {
      case SizeValueType.absolute:
      case SizeValueType.auto:
        const width = this.innerWidth(context)
        if (width !== undefined) return width

        const transferredWidth = this.innerTransferredWidth(mainSize, context)
        if (transferredWidth !== undefined) return transferredWidth
        break
    }

    let sumWidth = 0
    for (const line of lines) {
      sumWidth += line.getMaxCrossSize()
    }

    return this.minMaxWidth(sumWidth, context)
  }

  sizeSelf = (mainSize: number, crossSize: number): void => {
    this.position.width = crossSize + this.getOutlineWidth()
    this.position.height = mainSize + this.getOutlineHeight()
  }

  private innerWidth = (context: SizeContext): number | undefined => {
    const preferredWidth = this.getPreferredWidth(context)
    if (preferredWidth === undefined) return undefined

    return this.minMaxWidth(preferredWidth, context) - this.getOutlineWidth()
  }

  private innerHeight = (context: SizeContext): number | undefined => {
    const preferredHeight = this.getPreferredHeight(context)
    if (preferredHeight === undefined) return undefined

    return this.minMaxHeight(preferredHeight, context) - this.getOutlineHeight()
  }

  private innerTransferredWidth = (
    mainSize: number,
    context: SizeContext
  ): number | undefined => {
    const exactHeightContext = this.sizeContextPool!.fromContext(context)
    exactHeightContext.height.type = SizeValueType.exact
    exactHeightContext.height.value = mainSize

    const transferredWidth = this.getTransferredWidth(exactHeightContext)
    if (transferredWidth === undefined) {
      this.sizeContextPool!.release(exactHeightContext)
      return undefined
    }

    this.sizeContextPool!.release(exactHeightContext)

    return (
      this.minMaxWidth(
        this.minMaxTransferredWidth(transferredWidth, context),
        context
      ) - this.getOutlineWidth()
    )
  }

  private innerTransferredHeight = (
    context: SizeContext
  ): number | undefined => {
    const transferredHeight = this.getTransferredHeight(context)
    if (transferredHeight === undefined) return undefined

    return (
      this.minMaxHeight(
        this.minMaxTransferredHeight(transferredHeight, context),
        context
      ) - this.getOutlineHeight()
    )
  }
}
