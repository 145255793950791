import { ActionHandler } from '../types'
import { CommandHandler } from 'application/client'
import { CoordinatesConversion } from 'application/camera'
import { DragTemplateAction } from './action'
import { HapticDrawingWindow } from 'editor/haptic/drawingWindow'
import { HapticChildLine } from 'editor/haptic/childLine'
import { HapticNewParentWindow } from 'editor/haptic/newParentWindow'
import { ReadOnlyDocument } from 'application/document'
import { ChildLineComputer } from '../childLine/computer'
import { QuadTree } from 'application/quadtree'
import { TemplateData } from 'application/service'

export class DragTemplateActionFactory {
  private commandHandler: CommandHandler
  private document: ReadOnlyDocument
  private quadtree: QuadTree
  private hapticWindow: HapticDrawingWindow
  private hapticChildLine: HapticChildLine
  private hapticNewParent: HapticNewParentWindow
  private lineComputer: ChildLineComputer
  private coordinates: CoordinatesConversion

  constructor(
    commandHandler: CommandHandler,
    document: ReadOnlyDocument,
    quadtree: QuadTree,
    hapticWindow: HapticDrawingWindow,
    hapticChildLine: HapticChildLine,
    hapticNewParent: HapticNewParentWindow,
    lineComputer: ChildLineComputer,
    coordinates: CoordinatesConversion
  ) {
    this.commandHandler = commandHandler
    this.document = document
    this.quadtree = quadtree
    this.hapticWindow = hapticWindow
    this.hapticChildLine = hapticChildLine
    this.hapticNewParent = hapticNewParent
    this.lineComputer = lineComputer
    this.coordinates = coordinates
  }

  create = (templateData: TemplateData): ActionHandler => {
    return new DragTemplateAction(
      this.commandHandler,
      this.document,
      this.coordinates,
      this.quadtree,
      this.hapticWindow,
      this.hapticChildLine,
      this.hapticNewParent,
      this.lineComputer,
      templateData
    )
  }
}
