import { CssLength } from './cssLength'
import {
  CssDisplay,
  CssFlexAlign,
  CssFlexDirection,
  CssFlexJustify,
  CssFlexWrap,
  CssOverflow,
  CssPosition,
} from '../types/types'

export class CssStyles {
  display: CssDisplay = CssDisplay.display_block

  position: CssPosition = CssPosition.position_static

  top: CssLength = CssLength.createUndefined()
  right: CssLength = CssLength.createUndefined()
  bottom: CssLength = CssLength.createUndefined()
  left: CssLength = CssLength.createUndefined()

  width: CssLength = CssLength.createUndefined()
  height: CssLength = CssLength.createUndefined()

  minWidth: CssLength = CssLength.createUndefined()
  minHeight: CssLength = CssLength.createUndefined()

  maxWidth: CssLength = CssLength.createUndefined()
  maxHeight: CssLength = CssLength.createUndefined()

  aspectRatio: number | undefined = undefined

  paddingTop: CssLength = CssLength.createUndefined()
  paddingRight: CssLength = CssLength.createUndefined()
  paddingBottom: CssLength = CssLength.createUndefined()
  paddingLeft: CssLength = CssLength.createUndefined()

  borderTop: CssLength = CssLength.createUndefined()
  borderRight: CssLength = CssLength.createUndefined()
  borderBottom: CssLength = CssLength.createUndefined()
  borderLeft: CssLength = CssLength.createUndefined()

  flexDirection: CssFlexDirection = CssFlexDirection.row
  flexWrap: CssFlexWrap = CssFlexWrap.nowrap
  flexGrow: number = 0
  flexShrink: number = 1
  flexBasis: CssLength = CssLength.createUndefined()

  gap: CssLength = CssLength.createUndefined()

  overflow: CssOverflow = CssOverflow.overflow_visible

  alignItems: CssFlexAlign = CssFlexAlign.start
  justifyContent: CssFlexJustify = CssFlexJustify.start

  alignSelf: CssFlexAlign = CssFlexAlign.auto

  static create(): CssStyles {
    return new CssStyles()
  }

  static isOutOfFlow = (positionMode: CssPosition): boolean => {
    return (
      positionMode === CssPosition.position_absolute ||
      positionMode === CssPosition.position_fixed
    )
  }

  private constructor() {}

  isWidthConstrained = (): boolean => {
    return (
      CssStyles.isOutOfFlow(this.position) &&
      !this.width.isDefined() &&
      this.left.isDefined() &&
      this.right.isDefined()
    )
  }

  isHeightConstrained = (): boolean => {
    return (
      CssStyles.isOutOfFlow(this.position) &&
      !this.height.isDefined() &&
      this.top.isDefined() &&
      this.bottom.isDefined()
    )
  }
}
