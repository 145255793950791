import { DesignColor } from 'themes'

export default function ToolbarDivider() {
  return (
    <div
      style={{
        width: 20,
        height: 48,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
      }}
    >
      <div
        style={{
          width: 1,
          height: 28,
          background: DesignColor('panelBorder'),
        }}
      ></div>
    </div>
  )
}
