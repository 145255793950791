import ScrollContainer from 'components/Library/Containers/ScrollContainer/ScrollContainer'
import CodeExport from './CodeExport/CodeExport'
import AttributeSettings from './Attribute/AttributeSettings'

export default function Export() {
  return (
    <ScrollContainer>
      <AttributeSettings />
      <CodeExport />
      <SidepanelBottom />
    </ScrollContainer>
  )
}

function SidepanelBottom() {
  return <div style={{ width: 232, height: 100 }} />
}
