interface ScrollContainerProps {
  height?: string | number
  maxHeight?: string | number

  containerRef?: React.RefObject<HTMLDivElement>

  children?: React.ReactNode
}

export default function ScrollContainer({
  height = '100%',
  maxHeight = '100%',
  containerRef,
  children,
}: ScrollContainerProps) {
  return (
    <div
      ref={containerRef}
      style={{
        width: '100%',
        height: height,
        maxHeight: maxHeight,
        overflowY: 'scroll',
        overflowX: 'hidden',
        scrollbarWidth: 'none',
      }}
    >
      {children}
    </div>
  )
}
