import { WriteDocument } from 'application/document'
import {
  Violation,
  ViolationDynamicSizeOnCanvas,
  ViolationRemediator,
} from '../types'

export class SizeViolationRemediator implements ViolationRemediator {
  canRemediate(type: Violation['type']): boolean {
    switch (type) {
      case 'dynamic_size_on_canvas':
        return true
      default:
        return false
    }
  }

  remediate(violation: Violation, document: WriteDocument): void {
    switch (violation.type) {
      case 'dynamic_size_on_canvas':
        this.remediateDynamicSizeOnCanvas(violation, document)
        break
    }
  }

  private remediateDynamicSizeOnCanvas = (
    violation: ViolationDynamicSizeOnCanvas,
    document: WriteDocument
  ): void => {
    const node = document.getNode(violation.metadata.nodeId)
    if (node === undefined) return

    const h = node.getBaseAttribute('h')
    const w = node.getBaseAttribute('w')

    if (node.getStyleAttribute('size.w.unit') === 'percent') {
      node.setStyleAttribute('size.w.unit', 'px')
      node.setStyleAttribute('size.w.px', w)
    }

    if (node.getStyleAttribute('size.h.unit') === 'percent') {
      node.setStyleAttribute('size.h.unit', 'px')
      node.setStyleAttribute('size.h.px', h)
    }
  }
}
