import { isAbsolutePositionMode } from 'application/attributes'
import { ReadOnlyDocument } from 'application/document'
import { getLayoutMode } from 'application/attributes/utils'
import { ReadOnlyNode } from 'application/node'
import { Point } from 'application/shapes'

export class ParentChildIndexCalculator {
  private document: ReadOnlyDocument

  constructor(document: ReadOnlyDocument) {
    this.document = document
  }

  getIndex = (parentId: string, point: Point): number => {
    const parent = this.document.getNode(parentId)
    if (!parent) return 0

    const layoutMode = getLayoutMode(parent)

    const children = parent.getChildren()
    if (!children) return 0

    const filteredChildren = children
      .map((childId) => this.document.getNode(childId))
      .filter((c) => c && !isAbsolutePositionMode(c)) as ReadOnlyNode[]

    switch (layoutMode) {
      case 'block':
        return this.findIndex(point, filteredChildren, 'y', 'h')
      case 'flex':
        const direction = parent.getStyleAttribute('flex.direction')
        const positionMode = direction === 'row' ? 'x' : 'y'
        const sizeMode = direction === 'row' ? 'w' : 'h'
        return this.findIndex(point, filteredChildren, positionMode, sizeMode)
      default:
        return 0
    }
  }

  private findIndex(
    point: Point,
    children: ReadOnlyNode[],
    position: 'x' | 'y',
    size: 'w' | 'h'
  ): number {
    for (let i = 0; i < children.length; i++) {
      const child = children[i]
      const childPosition = child.getBaseAttribute(position)
      const childSize = child.getBaseAttribute(size)
      if (point[position] < childPosition + childSize / 2) return i
    }

    return children.length
  }
}
