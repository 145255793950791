import { StandardScreens, UniqueScreens } from 'application/shapes'
import PanelRowButton from 'components/Library/Components/Button/PanelRowButton/PanelRowButton'
import TabsBlock from 'components/Library/Components/Tabs/TabsBlock'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import useAction from 'hooks/editor/useAction'
import useActionInitiator from 'hooks/editor/useActionInitiator'
import { useState } from 'react'

export default function PresetSection() {
  const activeAction = useAction()
  const { create, cancel } = useActionInitiator()

  const [tab, setTab] = useState<'desktop' | 'tablet' | 'phone'>('desktop')

  const handleSelect = (w: number, h: number) => {
    switch (activeAction) {
      case 'drawPage':
        create('page', { x: 0, y: 0, w: w, h: h })
        cancel()
        break
      case 'drawFrame':
        create('frame', { x: 0, y: 0, w: w, h: h })
        cancel()
        break
    }
  }

  return (
    <div
      id="container-size-content"
      style={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <PanelSection>
        <PanelRow>
          <TabsBlock
            tabs={['desktop', 'tablet', 'phone']}
            selected={tab}
            select={(t) => setTab(t as 'desktop' | 'tablet' | 'phone')}
          />
        </PanelRow>
      </PanelSection>
      <PanelSection>
        {StandardScreens[tab].map((screen) => (
          <PanelRowButton
            key={screen.label}
            text={screen.label}
            subtext={`${screen.w}x${screen.h}`}
            onClick={() => handleSelect(screen.w, screen.h)}
          />
        ))}
      </PanelSection>
      <PanelSection>
        {UniqueScreens[tab].map((screen) => (
          <PanelRowButton
            key={screen.label}
            text={screen.label}
            subtext={`${screen.w}x${screen.h}`}
            onClick={() => handleSelect(screen.w, screen.h)}
          />
        ))}
      </PanelSection>
    </div>
  )
}
