import { FontKey } from 'application/text'
import { fonts, websafeFonts } from 'assets/fonts'
import { useCallback, useEffect, useState } from 'react'

export default function useLoadFonts() {
  const [fontsLoaded, setFontsLoaded] = useState(false)

  const loadFonts = useCallback(async () => {
    for (const key in fonts) {
      const font = fonts[key as FontKey]
      if (websafeFonts.includes(font.key)) continue
      if (font.urls.regular) loadFont(font.urls.regular)
    }
    setFontsLoaded(true)
  }, [])

  function loadFont(url: string) {
    const link = document.createElement('link')
    link.href = url
    link.rel = 'stylesheet'
    document.head.appendChild(link)
  }

  useEffect(() => {
    if (fontsLoaded) return
    loadFonts()
  }, [fontsLoaded, loadFonts])

  return fontsLoaded
}
