import { DesignColor } from 'themes'
import { useState } from 'react'
import Icon from '../../Icon/Icon/Icon'
import { IconKey } from 'assets/iconLibrary'
import { tooltipDirection, tooltipKey } from 'assets/tooltips'
import Tooltip from '../../Tooltip/Tooltip'

interface ToolbarIconButtonProps {
  buttonRef?: React.RefObject<HTMLDivElement>

  icon: IconKey
  iconColor?: string
  iconHoverColor?: string
  tooltipKey?: tooltipKey
  tooltipDirection?: tooltipDirection

  active?: boolean
  disabled?: boolean

  onClick?: () => void
}

export default function ToolbarIconButton({
  buttonRef,
  icon,
  iconColor = DesignColor('text1'),
  iconHoverColor = DesignColor('text1'),
  tooltipKey,
  tooltipDirection,
  active = false,
  disabled = false,
  onClick,
}: ToolbarIconButtonProps) {
  const [hover, setHover] = useState(false)

  const opacity = getOpacity(disabled)
  const background = getBackground(active, hover, disabled)
  const boxShadow = getBoxShadow(active, disabled)
  const currentIconColor = getIconColor(
    iconColor,
    iconHoverColor,
    hover,
    disabled
  )

  const Element = (
    <div
      ref={buttonRef}
      style={{
        width: 40,
        height: 48,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
        opacity,
        background,
        boxShadow,
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}
    >
      <Icon icon={icon} size={20} color={currentIconColor} />
    </div>
  )

  if (tooltipKey) {
    return (
      <Tooltip tooltipKey={tooltipKey} direction={tooltipDirection}>
        {Element}
      </Tooltip>
    )
  } else {
    return Element
  }
}

function getOpacity(disabled: boolean) {
  return disabled ? 0.4 : 1
}

function getBackground(active: boolean, hovered: boolean, disabled: boolean) {
  if (disabled) return 'none'
  if (active) return DesignColor('inputTint')
  if (hovered) return DesignColor('inputTintNeutral')
  return 'none'
}

function getBoxShadow(active: boolean, disabled: boolean) {
  if (disabled) return 'none'
  if (active) return `inset 0px -1px 0px 0px ${DesignColor('inputHighlight')}`
}

function getIconColor(
  iconColor: string,
  iconHoverColor: string,
  hovered: boolean,
  disabled: boolean
) {
  if (disabled) return DesignColor('text2')
  if (hovered) return iconHoverColor
  return iconColor
}
