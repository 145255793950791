import {
  BaseMap,
  Selector,
  DefaultSelector,
  StyleMap,
  SelectorName,
  SelectorBreakpoint,
  SelectorPseudo,
} from 'application/attributes'
import { NodeUpdateListener } from './types'
import _ from 'lodash'

export class Node {
  private id: string
  private parent?: string
  private children?: string[]
  private baseAttributes: BaseMap
  private defaultSelector: DefaultSelector
  private selectors: Selector[]
  private activeBreakpoint: SelectorBreakpoint
  private activePseudo: SelectorPseudo
  private activeStyles: StyleMap
  private listener: NodeUpdateListener

  constructor(
    id: string,
    parent: string | undefined,
    children: string[] | undefined,
    editorAttributes: BaseMap,
    defaultSelector: DefaultSelector,
    selectors: Selector[],
    activeBreakpoint: SelectorBreakpoint,
    activePseudo: SelectorPseudo,
    listener: NodeUpdateListener
  ) {
    this.id = id
    this.parent = parent
    this.children = children
    this.baseAttributes = editorAttributes
    this.defaultSelector = defaultSelector
    this.selectors = selectors
    this.activeBreakpoint = activeBreakpoint
    this.activePseudo = activePseudo
    this.activeStyles = this.getActiveStyles()
    this.listener = listener
  }

  getId = (): string => {
    return this.id
  }

  getParent = (): string | undefined => {
    return this.parent
  }

  setParent = (parent: string | undefined): void => {
    const prev = this.parent
    this.parent = parent
    if (this.listener.onParent === undefined) return
    this.listener.onParent(this.id, prev, parent)
  }

  getChildren = (): string[] | undefined => {
    return this.children
  }

  setChildren = (children: string[] | undefined): void => {
    const prev = this.children
    this.children = children
    if (this.listener.onChildren === undefined) return
    this.listener.onChildren(this.id, prev, children)
  }

  addChild = (child: string, index: number | undefined): void => {
    if (this.children === undefined) return

    const prev = this.children
    const updated = [...this.children]

    if (index === undefined) {
      updated.push(child)
    } else {
      updated.splice(index, 0, child)
    }

    this.children = updated

    if (this.listener.onChildren === undefined) return
    this.listener.onChildren(this.id, prev, this.children)
  }

  removeChild = (child: string): void => {
    if (this.children === undefined) return

    const prev = this.children
    const updated = this.children.filter((id) => id !== child)

    this.children = updated

    if (this.listener.onChildren === undefined) return
    this.listener.onChildren(this.id, prev, this.children)
  }

  getBaseAttribute = <K extends keyof BaseMap>(key: K): BaseMap[K] => {
    return this.baseAttributes[key]
  }

  getBaseAttributes = (): BaseMap => {
    return this.baseAttributes
  }

  getStyleAttribute = <K extends keyof StyleMap>(key: K): StyleMap[K] => {
    return this.activeStyles[key]
  }

  getStyleAttributes = (): StyleMap => {
    return this.activeStyles
  }

  getDefaultSelector = (): DefaultSelector => {
    return this.defaultSelector
  }

  getSelectors = (): Selector[] => {
    return this.selectors
  }

  getActiveBreakpoint = (): SelectorBreakpoint => {
    return this.activeBreakpoint
  }

  getActivePseudo = (): SelectorPseudo => {
    return this.activePseudo
  }

  getSelector = (name: SelectorName): Selector | undefined => {
    if (name === 'default') return this.defaultSelector
    return this.selectors.find((s) => s.name === name)
  }

  setBaseAttribute = <K extends keyof BaseMap>(
    key: K,
    value: BaseMap[K]
  ): void => {
    const prev = this.baseAttributes[key]
    this.baseAttributes[key] = value
    if (this.listener.onBaseAttribute === undefined) return
    this.listener.onBaseAttribute(this.id, key, prev, value)
  }

  setStyleAttribute = <K extends keyof StyleMap>(
    key: K,
    value: StyleMap[K],
    selectorName?: SelectorName
  ): void => {
    const selector =
      selectorName === undefined
        ? this.getLowestSelector()
        : this.getOrCreateSelector(selectorName)
    if (selector === undefined) return

    const prev = selector.styles[key]
    if (value === undefined) {
      delete selector.styles[key]
    } else {
      selector.styles[key] = value
    }
    this.updateActiveStyles()

    if (this.listener.onStyleAttribute === undefined) return
    const updatedValue = this.activeStyles[key]
    this.listener.onStyleAttribute(
      this.id,
      key,
      { selector: selector.name, value: prev },
      { selector: selector.name, value: updatedValue }
    )
  }

  resetStyleAttribute = <K extends keyof StyleMap>(key: K): void => {
    for (const selector of this.selectors) {
      if (selector.styles[key] === undefined) continue
      const previous = selector.styles[key]
      delete selector.styles[key]
      if (this.listener.onStyleAttribute !== undefined) {
        this.listener.onStyleAttribute(
          this.id,
          key,
          { selector: selector.name, value: previous },
          { selector: selector.name, value: undefined }
        )
      }
    }
  }

  setActiveBreakpoint = (breakpoint: SelectorBreakpoint): void => {
    const prev = this.activeBreakpoint
    this.activeBreakpoint = breakpoint
    this.addEmptySelectors()
    this.updateActiveStyles()
    if (this.listener.onActiveBreakpoint === undefined) return
    this.listener.onActiveBreakpoint(this.id, prev, this.activeBreakpoint)
  }

  setActivePseudo = (pseudo: SelectorPseudo): void => {
    const prev = this.activePseudo
    this.activePseudo = pseudo
    this.addEmptySelectors()
    this.updateActiveStyles()
    if (this.listener.onActivePseudo === undefined) return
    this.listener.onActivePseudo(this.id, prev, this.activePseudo)
  }

  clone = (id?: string): Node => {
    return new Node(
      id === undefined ? this.id : id,
      this.parent,
      _.cloneDeep(this.children),
      _.cloneDeep(this.baseAttributes),
      _.cloneDeep(this.defaultSelector),
      _.cloneDeep(this.selectors),
      this.activeBreakpoint,
      this.activePseudo,
      this.listener
    )
  }

  private updateActiveStyles = (): void => {
    this.activeStyles = this.getActiveStyles()
  }

  private addEmptySelectors = (): void => {
    let name: SelectorName = this.activeBreakpoint
    if (this.activePseudo !== 'none') {
      name = `${name} ${this.activePseudo}`
    }
    if (name === 'default') return
    if (this.selectors.find((s) => s.name === name)) return
    this.selectors.push({
      name: name,
      styles: {},
    })
  }

  private getActiveStyles = (): StyleMap => {
    return {
      ...this.defaultSelector.styles,
      ...this.getApplicableSelectors().reduce((acc, selector) => {
        return { ...acc, ...selector.styles }
      }, {}),
    }
  }

  private getApplicableSelectors = (): Selector[] => {
    const selectors: Selector[] = [this.defaultSelector]
    const breakpointIndex = breakpointOrder.indexOf(this.activeBreakpoint)
    const applicableBreakpoints = breakpointOrder.slice(0, breakpointIndex + 1)
    for (const b of applicableBreakpoints) {
      const selector = this.selectors.find((s) => s.name === b)
      if (selector) selectors.push(selector)
    }
    for (const b of applicableBreakpoints) {
      switch (this.activePseudo) {
        case 'none':
          break
        case 'hover':
          const hover = this.selectors.find((s) => s.name === `${b} hover`)
          if (hover) selectors.push(hover)
          break
      }
    }
    return selectors
  }

  private getLowestSelector = (): Selector => {
    const selectors = this.getApplicableSelectors()
    return selectors[selectors.length - 1]
  }

  private getOrCreateSelector = (name: SelectorName): Selector => {
    if (name === 'default') return this.defaultSelector
    const selector = this.selectors.find((s) => s.name === name)
    if (selector) return selector
    const newSelector = { name, styles: {} }
    this.selectors.push(newSelector)
    return newSelector
  }
}

const breakpointOrder: SelectorName[] = [
  'default',
  'tablet',
  'landscape',
  'mobile',
]
