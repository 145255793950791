import { Context } from 'application/render/gpu/context'
import { TextRenderBox, VaoData } from 'application/render/types'
import { MaterialType } from '../shaderMap'
import { VertexBuffer } from 'application/render/gpu/buffer'
import { UniformValue } from 'application/render/gpu/uniform'
import {
  calcBoundingBox,
  calcGlyphMsdfCoords,
  calcGlyphPositions,
  calcRounding,
} from 'application/render/data'
import { WebglTextData } from './webglText'
import { FontLoaderInterface } from 'application/text'
import { rgbaToWebgl } from 'application/color'
import { connectBuffersToVao, standardDraw } from 'application/render/utils'
import { isPointInBox } from 'application/render/shaderUtils'

export class WebglTextSolidBox {
  static draw(context: Context, vaoData: VaoData) {
    if (!vaoData.src || !vaoData.material) return

    const msdfTexture = context.getTexture(vaoData.src)
    if (!msdfTexture) {
      context.createImageTexture(vaoData.src)
      return
    }
    vaoData.material.setSampler('uSampler', msdfTexture.getTexture(), 0)

    standardDraw(vaoData)
  }

  static createVaoData(
    context: Context,
    data: WebglTextData,
    box: TextRenderBox,
    fontLoader: FontLoaderInterface
  ): VaoData {
    const gl = context.getGl()
    const material = context.getMaterial(MaterialType.textSolid)
    const vao = gl.createVertexArray()
    const buffers: { [key: string]: VertexBuffer } = {}
    const uniforms: { [key: string]: UniformValue } = {}

    const color = box.fill.color
    if (!color) throw new Error('Invalid Text Fill')

    const aPosition = new Float32Array(
      calcGlyphPositions(box, data, fontLoader)
    )
    const aMsdfCoords = new Float32Array(
      calcGlyphMsdfCoords(box, data, fontLoader)
    )
    const uColor = rgbaToWebgl(color)
    const uBB = calcBoundingBox(data.bb)
    const uBBRounding = calcRounding(uBB[2], uBB[3], data.bbRounding)
    const src = fontLoader.getMsdfSrc(box.fontFamily, box.fontWeight)

    buffers['aPosition'] = context.createVertexBuffer(aPosition, 2)
    buffers['aMsdfCoords'] = context.createVertexBuffer(aMsdfCoords, 2)
    uniforms['uColor'] = uColor
    uniforms['uBB'] = uBB
    uniforms['uBBRounding'] = uBBRounding
    const verticeCount = aPosition.length / 2

    const vaoData = { material, vao, buffers, uniforms, verticeCount, src }
    connectBuffersToVao(vaoData)
    return vaoData
  }

  static async preload(context: Context, vaoData: VaoData): Promise<void> {
    if (!vaoData.src) return
    await context.createImageTexture(vaoData.src)
  }
}

export const textSolidVs = `#version 300 es
precision highp float;

uniform mat3 uMatrix;

in vec2 aPosition;
in vec2 aMsdfCoords;

out vec2 vPosition;
out vec2 vMsdfCoords;

void main() {
  vec2 pos = aPosition;
  vec3 transformedPosition = uMatrix * vec3(pos, 1);
  gl_Position = vec4(transformedPosition.xy, 0, 1);

  vPosition = aPosition;
  vMsdfCoords = aMsdfCoords;
}
`

export const textSolidFs = `#version 300 es
precision highp float;

uniform sampler2D uSampler;
uniform vec4 uBB;
uniform vec4 uBBRounding;
uniform vec4 uColor;

in vec2 vPosition;
in vec2 vMsdfCoords;

out vec4 outColor;

${isPointInBox}

float median(float r, float g, float b) {
  return max(min(r, g), min(max(r, g), b));
}

void main() {
  if (!isPointInBox(vPosition, uBB, uBBRounding)) {
    discard;
  }

  vec3 texColor = texture(uSampler, vMsdfCoords).rgb;
  
  float dist = median(texColor.r, texColor.g, texColor.b) - 0.5;
  float alpha = clamp(dist / fwidth(dist) + 0.5, 0.0, 1.0);

  outColor = vec4(uColor.rgb, alpha * uColor.a);
}
`
