import { truncate } from 'application/math'
import { InsertionStrategy } from '../creation'
import { ReadOnlyDocumentSelection } from 'application/selection'
import { Point, Rectangle } from 'application/shapes'

interface ViewableCanvas {
  getViewableRectangle: () => Rectangle
}

export class CanvasInsertionStrategy implements InsertionStrategy {
  private viewable: ViewableCanvas
  private documentSelection: ReadOnlyDocumentSelection
  private nodeSize: Rectangle

  constructor(
    viewable: ViewableCanvas,
    documentSelection: ReadOnlyDocumentSelection,
    nodeSize: Rectangle
  ) {
    this.viewable = viewable
    this.documentSelection = documentSelection
    this.nodeSize = nodeSize
  }

  getParent = (): string => {
    return this.documentSelection.getSelectedCanvas()
  }

  getIndex = (): number | undefined => {
    return 0
  }

  getPosition = (): Point => {
    const canvas = this.viewable.getViewableRectangle()
    const center = this.centerWindow(canvas, this.nodeSize)
    return { x: center.x, y: center.y }
  }

  private centerWindow = (r1: Rectangle, r2: Rectangle): Rectangle => {
    return {
      x: truncate(r1.x + r1.w / 2 - r2.w / 2),
      y: truncate(r1.y + r1.h / 2 - r2.h / 2),
      w: r2.w,
      h: r2.h,
    }
  }
}
