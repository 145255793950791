import { NodeMap, ReadOnlyNodeMap } from 'application/node'
import {
  NodeIdMap,
  CopySnapshot,
  PasteSnapshot,
  NodeOffsets,
  NodeIndexes,
} from '../types'
import { IdGenerator } from 'application/ids'
import { ReferenceUpdater } from './references'

export class PasteSnapshotGenerator {
  private idGenerator: IdGenerator

  constructor(idGenerator: IdGenerator) {
    this.idGenerator = idGenerator
  }

  generate = (snapshot: CopySnapshot): PasteSnapshot => {
    const newIds = this.createIds(snapshot)
    const canvasId = snapshot.canvasId

    const ids = snapshot.ids.map((id) => newIds[id])
    const nodes = this.cloneNodes(snapshot.nodes, newIds)
    ReferenceUpdater.update(nodes, newIds)

    const canvasChild = this.cloneCanvasChild(snapshot, newIds)
    const outsideParent = this.cloneOutsideParent(snapshot, newIds)

    const offsets = this.cloneOffsets(snapshot.offsets, newIds)
    const indexes = this.cloneIndexes(snapshot.indexes, newIds)

    return {
      ids,
      canvasId,
      nodes,
      canvasChild,
      outsideParent,
      offsets,
      indexes,
    }
  }

  private createIds = (snapshot: CopySnapshot): NodeIdMap => {
    const ids: NodeIdMap = {}
    for (const id of Object.keys(snapshot.nodes)) {
      ids[id] = this.idGenerator.next()
    }
    return ids
  }

  private cloneNodes = (nodes: ReadOnlyNodeMap, idMap: NodeIdMap): NodeMap => {
    const newNodes: NodeMap = {}
    for (const id of Object.keys(nodes)) {
      const newId = idMap[id]
      const newNode = nodes[id].clone(newId)
      newNodes[newId] = newNode
    }
    return newNodes
  }

  private cloneCanvasChild = (
    snapshot: CopySnapshot,
    idMap: NodeIdMap
  ): { [key: string]: boolean } => {
    const canvasChild: { [key: string]: boolean } = {}
    for (const id of Object.keys(snapshot.canvasChild)) {
      const newId = idMap[id]
      canvasChild[newId] = snapshot.canvasChild[id]
    }
    return canvasChild
  }

  private cloneOutsideParent = (
    snapshot: CopySnapshot,
    idMap: NodeIdMap
  ): { [key: string]: boolean } => {
    const outsideParent: { [key: string]: boolean } = {}
    for (const id of Object.keys(snapshot.outsideParent)) {
      const newId = idMap[id]
      outsideParent[newId] = snapshot.outsideParent[id]
    }
    return outsideParent
  }

  private cloneOffsets = (
    snapshot: NodeOffsets,
    idMap: NodeIdMap
  ): NodeOffsets => {
    const offsets: NodeOffsets = {}
    for (const id of Object.keys(snapshot)) {
      const newId = idMap[id]
      offsets[newId] = snapshot[id]
    }
    return offsets
  }

  private cloneIndexes = (
    snapshot: NodeIndexes,
    idMap: NodeIdMap
  ): NodeIndexes => {
    const indexes: NodeIndexes = {}
    for (const id of Object.keys(snapshot)) {
      const newId = idMap[id]
      indexes[newId] = snapshot[id]
    }
    return indexes
  }
}
