export class SizeCache {
  private cache: Map<number, [number, number]> = new Map()

  get = (key: number): [number, number] | undefined => {
    return this.cache.get(key)
  }

  set = (key: number, size: [number, number]): void => {
    this.cache.set(key, size)
  }

  clear = (): void => {
    this.cache.clear()
  }
}

export class ChildSizeCache {
  private cache: Map<number, [number, number][]> = new Map()

  get = (key: number): [number, number][] | undefined => {
    return this.cache.get(key)
  }

  set = (key: number, sizes: [number, number][]): void => {
    this.cache.set(key, sizes)
  }

  clear = (): void => {
    this.cache.clear()
  }
}
