import { Point } from 'application/shapes'
import { CameraService } from './camera'

export class CoordinatesConversion {
  private cameraService: CameraService

  constructor(cameraService: CameraService) {
    this.cameraService = cameraService
  }

  get = (e: MouseEvent): Point => {
    return this.cameraService.getCanvasCoordinates({
      x: e.clientX,
      y: e.clientY,
    })
  }

  getZoomAdjusted = (value: number): number => {
    return value / this.cameraService.getCamera().z
  }
}
