import { DesignColor } from 'themes'
import { FontKey, FontWeight } from 'application/text'
import React from 'react'

export type textSize = 11 | 12

export type textStyles = {
  size?: 11 | 12 | 14 | 16
  family?: FontKey
  weight?: FontWeight
  css?: string
  cursor?: 'default' | 'pointer'
}

interface TextProps {
  textRef?: React.RefObject<HTMLParagraphElement>

  maxWidth?: number
  wrap?: boolean

  styles?: textStyles

  color?: string

  children?: React.ReactNode

  onMouseEnter?: () => void
  onMouseLeave?: () => void

  onClick?: () => void
}

export default function Text({
  textRef,
  maxWidth,
  wrap = false,
  styles = {
    size: 11,
    family: 'inter',
    weight: 'regular',
    css: 'Inter',
  },
  color = DesignColor('text1'),
  children,
  onMouseEnter,
  onMouseLeave,
  onClick,
}: TextProps) {
  return (
    <p
      ref={textRef}
      style={{
        maxWidth: maxWidth,
        fontFamily: styles.css,
        fontWeight: weightToNumber(styles.weight || 'regular'),
        fontSize: styles.size || 11,
        color: color,
        cursor: styles.cursor || 'inherit',
        overflow: 'hidden',
        whiteSpace: wrap ? 'normal' : 'nowrap',
        textOverflow: 'ellipsis',
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      {children}
    </p>
  )
}

function weightToNumber(weight: FontWeight): number {
  switch (weight) {
    case 'thin':
      return 100
    case 'extra-light':
      return 200
    case 'light':
      return 300
    case 'regular':
      return 400
    case 'medium':
      return 500
    case 'semi-bold':
      return 600
    case 'bold':
      return 700
    case 'extra-bold':
      return 800
    case 'black':
      return 900
    default:
      return 400
  }
}
