import { PositionContext } from '../context/positionContext'
import { SizeContext } from '../context/sizeContext'
import { LayoutItem } from '../layoutItem/layoutItem'
import { LayoutItemType, SizeValueType } from '../types/types'

export class ImageLayout extends LayoutItem {
  getType = (): LayoutItemType => LayoutItemType.image

  resize = (context: SizeContext): void => {
    if (this.setSizeFromCache(context)) return

    switch (context.width.type) {
      case SizeValueType.max_content:
      case SizeValueType.min_content:
        this.position.width = this.getContentWidth(context)
        break
      case SizeValueType.exact:
        this.position.width = context.width.value
        break
      case SizeValueType.absolute:
      case SizeValueType.auto:
        const preferredWidth = this.getPreferredWidth(context)
        if (preferredWidth !== undefined) {
          this.position.width = this.minMaxWidth(preferredWidth, context)
        } else {
          this.position.width = this.getContentWidth(context)
        }
        break
    }

    switch (context.height.type) {
      case SizeValueType.max_content:
      case SizeValueType.min_content:
        this.position.height = this.getContentHeight(context)
        break
      case SizeValueType.exact:
        this.position.height = context.height.value
        break
      case SizeValueType.auto:
      case SizeValueType.absolute:
        const preferredHeight = this.getPreferredHeight(context)
        if (preferredHeight !== undefined) {
          this.position.height = this.minMaxHeight(preferredHeight, context)
        } else {
          this.position.height = this.getContentHeight(context)
        }
        break
    }

    this.cacheSize(context)
  }

  place = (context: PositionContext): void => {
    this.placeSelf(context)
  }

  override getMinContentContributionWidth = (context: SizeContext): number => {
    return this.getContentWidth(context)
  }

  override getMinContentContributionHeight = (context: SizeContext): number => {
    return this.getContentHeight(context)
  }

  override getMaxContentContributionWidth = (context: SizeContext): number => {
    return this.getContentWidth(context)
  }

  override getMaxContentContributionHeight = (context: SizeContext): number => {
    return this.getContentHeight(context)
  }

  override getRatio = (): number => {
    const cssAspectRatio = this.element.css.aspectRatio
    if (cssAspectRatio !== undefined) return cssAspectRatio

    return this.element.contentSize.width / this.element.contentSize.height
  }

  private getContentWidth(context: SizeContext): number {
    const ratio = this.getRatio()
    const cssWidth = this.getPreferredWidth(context)
    const cssHeight = this.getPreferredHeight(context)

    let usedWidth = cssWidth ?? this.element.contentSize.width
    let usedHeight = cssHeight ?? this.element.contentSize.height

    if (cssWidth !== undefined && cssHeight === undefined) {
      usedHeight = usedWidth / ratio
    } else if (cssHeight !== undefined && cssWidth === undefined) {
      usedWidth = usedHeight * ratio
    }

    const newHeight = this.minMaxHeight(usedHeight, context)
    if (newHeight !== usedHeight && cssWidth === undefined) {
      return this.minMaxWidth(newHeight * ratio, context)
    } else {
      return this.minMaxWidth(usedWidth, context)
    }
  }

  private getContentHeight(context: SizeContext): number {
    const ratio = this.getRatio()
    const cssWidth = this.getPreferredWidth(context)
    const cssHeight = this.getPreferredHeight(context)

    let usedWidth = cssWidth ?? this.element.contentSize.width
    let usedHeight = cssHeight ?? this.element.contentSize.height

    if (cssWidth !== undefined && cssHeight === undefined) {
      usedHeight = usedWidth / ratio
    } else if (cssHeight !== undefined && cssWidth === undefined) {
      usedWidth = usedHeight * ratio
    }

    const newWidth = this.minMaxWidth(usedWidth, context)
    if (newWidth !== usedWidth && cssHeight === undefined) {
      return this.minMaxHeight(newWidth / ratio, context)
    } else {
      return this.minMaxHeight(usedHeight, context)
    }
  }
}
