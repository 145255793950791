import { Renderable } from 'application/render'

export class HapticRenderStore {
  private haptics: Record<string, Renderable[]>

  constructor() {
    this.haptics = {}
  }

  getHaptic = (key: string): Renderable[] => {
    return this.haptics[key]
  }

  setHaptic = (key: string, renderables: Renderable[]) => {
    this.cleanupHaptic(key)
    this.haptics[key] = renderables
  }

  deleteHaptic = (key: string) => {
    this.cleanupHaptic(key)
  }

  cleanup = () => {
    Object.keys(this.haptics).forEach((key) => this.cleanupHaptic(key))
  }

  private cleanupHaptic = (key: string) => {
    if (!this.haptics[key]) return
    this.haptics[key].forEach((renderable) => renderable.cleanup())
    delete this.haptics[key]
  }
}
