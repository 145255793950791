import { ContentElement } from './elementContent'
import { Element } from './types'

export class ContentElementBuilder {
  private content: string = ''

  withContent = (content: string): ContentElementBuilder => {
    this.content = content
    return this
  }

  build = (): Element => {
    return new ContentElement(this.content)
  }
}
