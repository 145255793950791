import { MouseDevice } from './types'

export class MouseDeviceState {
  private mouseDevice: MouseDevice = 'mouse'

  getMouseDevice = (): MouseDevice => {
    return this.mouseDevice
  }

  setMouseDevice = (device: MouseDevice) => {
    this.mouseDevice = device
  }

  updateMouseTypeFromWheel = (e: WheelEvent): void => {
    if (this.mouseDevice === 'touchpad') return
    if (e.ctrlKey || e.metaKey) return
    if (Math.abs(e.deltaY) > 4 || Math.abs(e.deltaX) > 4) {
      this.mouseDevice = 'mouse'
    } else {
      this.mouseDevice = 'touchpad'
    }
  }
}
