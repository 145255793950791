import TextBlock from 'components/Library/Components/Text/TextBlock/TextBlock'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import { AttributeProps } from '../AttributeSettings'
import TextInputBlock from 'components/Library/Components/Text/TextInputBlock/TextInputBlock'

export default function AnchorAttributes({
  settings,
  handlers,
}: AttributeProps) {
  if (!settings.attributes || settings.attributes === 'Mixed') return <></>

  return (
    <>
      <PanelRow>
        <TextBlock mode={'label2'}>Href</TextBlock>
        <TextInputBlock
          width={96}
          value={settings.attributes['href'] || ''}
          setValue={(v) => handlers.setAttribute('href', v)}
          placeholder={settings.name}
          commit={true}
        />
      </PanelRow>
    </>
  )
}
