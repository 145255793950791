import { ReadOnlyDocument } from 'application/document'
import { InsertionStrategy } from '../creation'
import { ReadOnlyDocumentSelection } from 'application/selection'
import { ReadOnlyNode } from 'application/node'
import { Point } from 'application/shapes'
import { truncate } from 'application/math'

export class SharedParentInsertionStrategy implements InsertionStrategy {
  private nodes: string[]
  private document: ReadOnlyDocument
  private documentSelection: ReadOnlyDocumentSelection
  private parent: string
  private indexes: number[]

  constructor(
    nodes: string[],
    document: ReadOnlyDocument,
    documentSelection: ReadOnlyDocumentSelection
  ) {
    this.nodes = nodes
    this.document = document
    this.documentSelection = documentSelection
    this.parent = this.computeParent()
    this.indexes = this.getIndexes()
  }

  getParent = (): string => {
    return this.parent
  }

  getIndex = (): number | undefined => {
    if (this.nodes.length === 0) return 0
    return Math.min(...this.indexes)
  }

  getPosition = (): Point => {
    if (this.nodes.length === 0) return { x: 0, y: 0 }

    const positions = this.getPositions()
    const x = Math.min(...positions.map((p) => p.x))
    const y = Math.min(...positions.map((p) => p.y))

    return { x: truncate(x, 0), y: truncate(y, 0) }
  }

  private computeParent = (): string => {
    const parents = this.getParents()

    const canvasId = this.documentSelection.getSelectedCanvas()
    if (parents.length === 0) return canvasId
    if (parents.some((n) => n.getId() !== parents[0].getId())) return canvasId

    return parents[0].getId()
  }

  private getNodes = (): ReadOnlyNode[] => {
    return this.nodes
      .map((id) => this.document.getNode(id))
      .filter((n) => n) as ReadOnlyNode[]
  }

  private getParents = (): ReadOnlyNode[] => {
    return this.getNodes()
      .map((node) => this.document.getParent(node))
      .filter((n) => n) as ReadOnlyNode[]
  }

  private getIndexes = (): number[] => {
    const nodes = this.getNodes()

    return nodes.map((node) => {
      const parent = this.document.getParent(node)
      if (!parent) return 0

      const children = parent.getChildren()
      if (!children) return 0

      return children.indexOf(node.getId())
    })
  }

  private getPositions = (): Point[] => {
    const nodes = this.getNodes()
    return nodes.map((node) => {
      const attrs = node.getBaseAttributes()
      const x = attrs['x']
      const y = attrs['y']
      return { x, y }
    })
  }
}
