import { truncate } from 'application/math'
import ToolbarUnitInput from 'components/Library/Components/Toolbar/ToolbarUnitInput/ToolbarUnitInput'
import Tooltip from 'components/Library/Components/Tooltip/Tooltip'
import PanelContainer from 'components/Library/Containers/PanelContainer/PanelContainer'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'

export default function ZoomInput() {
  const cameraPanel = usePanelHelper(editor.getUI().getCameraPanel())
  if (!cameraPanel.settings) return <></>

  const { zoom } = cameraPanel.settings
  const { setZoom } = cameraPanel.handlers

  return (
    <PanelContainer
      position={{
        position: 'absolute',
        bottom: 8,
        right: 248,
        zIndex: 1,
      }}
      width={'hug'}
      height={48}
      paddingH={10}
      direction="row"
    >
      <Tooltip tooltipKey="Zoom" direction="center">
        <ToolbarUnitInput
          value={truncate(zoom * 100, 1)}
          setValue={(value: number) => setZoom(value / 100)}
          unit={'%'}
        />
      </Tooltip>
    </PanelContainer>
  )
}
