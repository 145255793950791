import { Color, HsbaColor } from './types'

export function rgbaToHex(rgba: Color): string {
  const r = Math.round(rgba.r).toString(16).padStart(2, '0')
  const g = Math.round(rgba.g).toString(16).padStart(2, '0')
  const b = Math.round(rgba.b).toString(16).padStart(2, '0')

  const a = Math.round(rgba.a * 255)
    .toString(16)
    .padStart(2, '0')

  return `${r}${g}${b}${a}`.toUpperCase()
}

export function rgbaStringToRgba(rgba: string): Color {
  const match = rgba.match(/rgba?\((\d+),\s*(\d+),\s*(\d+),\s*(\d*\.?\d+)\)/)
  if (!match) return { r: 0, g: 0, b: 0, a: 1 }
  return {
    r: parseInt(match[1], 10),
    g: parseInt(match[2], 10),
    b: parseInt(match[3], 10),
    a: parseFloat(match[4]),
  }
}

export function rgbaToWebgl(rgba: Color): number[] {
  return [rgba.r / 255, rgba.g / 255, rgba.b / 255, rgba.a]
}

export function rgbaToHsba(color: Color): HsbaColor {
  const r = color.r / 255
  const g = color.g / 255
  const b = color.b / 255
  const max = Math.max(r, g, b)
  const min = Math.min(r, g, b)
  const delta = max - min
  let h = 0
  let s = 0
  let br = max
  if (delta !== 0) {
    s = delta / max
    if (r === max) {
      h = (g - b) / delta
    } else if (g === max) {
      h = 2 + (b - r) / delta
    } else {
      h = 4 + (r - g) / delta
    }
  }
  h *= 60
  if (h < 0) h += 360
  s *= 100
  br *= 100
  return { h, s, b: br, a: color.a }
}

export function hsbaToRgba(hsba: HsbaColor): Color {
  const h = hsba.h
  const s = hsba.s / 100
  const br = hsba.b / 100
  const a = hsba.a

  const c = br * s
  const x = c * (1 - Math.abs(((h / 60) % 2) - 1))
  const m = br - c
  let r = 0
  let g = 0
  let b = 0

  if (h < 60) {
    r = c
    g = x
  } else if (h < 120) {
    r = x
    g = c
  } else if (h < 180) {
    g = c
    b = x
  } else if (h < 240) {
    g = x
    b = c
  } else if (h < 300) {
    r = x
    b = c
  } else {
    r = c
    b = x
  }
  return {
    r: (r + m) * 255,
    g: (g + m) * 255,
    b: (b + m) * 255,
    a,
  }
}

export function hsbaToHex(hsba: HsbaColor): string {
  return rgbaToHex(hsbaToRgba(hsba))
}

export function hexToRgba(hex: string): Color {
  if (hex.startsWith('#')) hex = hex.slice(1)

  switch (hex.length) {
    case 1:
      return {
        r: parseInt(hex + hex, 16),
        g: parseInt(hex + hex, 16),
        b: parseInt(hex + hex, 16),
        a: 1,
      }
    case 2:
      return {
        r: parseInt(hex, 16),
        g: parseInt(hex, 16),
        b: parseInt(hex, 16),
        a: 1,
      }
    case 3:
      return {
        r: parseInt(hex[0] + hex[0], 16),
        g: parseInt(hex[1] + hex[1], 16),
        b: parseInt(hex[2] + hex[2], 16),
        a: 1,
      }
    case 4:
      return {
        r: parseInt(hex[0] + hex[1], 16),
        g: parseInt(hex[2] + hex[3], 16),
        b: parseInt(hex[3] + hex[3], 16),
        a: 1,
      }
    case 5:
      return {
        r: parseInt(hex[0] + hex[1], 16),
        g: parseInt(hex[2] + hex[3], 16),
        b: parseInt(hex[4] + hex[4], 16),
        a: 1,
      }
    case 6:
      return {
        r: parseInt(hex[0] + hex[1], 16),
        g: parseInt(hex[2] + hex[3], 16),
        b: parseInt(hex[4] + hex[5], 16),
        a: 1,
      }
    case 7:
      return {
        r: parseInt(hex[0] + hex[1], 16),
        g: parseInt(hex[2] + hex[3], 16),
        b: parseInt(hex[4] + hex[5], 16),
        a: parseInt(hex[6] + hex[7], 16) / 255,
      }
    case 8:
      return {
        r: parseInt(hex[0] + hex[1], 16),
        g: parseInt(hex[2] + hex[3], 16),
        b: parseInt(hex[4] + hex[5], 16),
        a: parseInt(hex[6] + hex[7], 16) / 255,
      }
    default:
      return {
        r: parseInt(hex[0] + hex[1], 16),
        g: parseInt(hex[2] + hex[3], 16),
        b: parseInt(hex[4] + hex[5], 16),
        a: 1,
      }
  }
}

export function mixColors(
  color1: Color,
  color2: Color,
  percent: number
): Color {
  const p1 = percent / 100
  const p2 = (100 - percent) / 100

  const r = Math.round(color1.r * p1 + color2.r * p2)
  const g = Math.round(color1.g * p1 + color2.g * p2)
  const b = Math.round(color1.b * p1 + color2.b * p2)
  const a = Math.round(color1.a * p1 + color2.a * p2)

  return { r, g, b, a }
}
