import _ from 'lodash'
import { Content } from '../types'
import {
  Style,
  StyleOverrides,
  SelectionStyle,
  StyleMultiselect,
} from './types'
import { Selection, SelectionPoint } from '../types'
import { ReadOnlyFontDataMap } from 'application/text/types'

export interface Styles {
  get: (state: Content, selectionPoint: SelectionPoint) => Style
  getOverride: (
    content: Content,
    selectionPoint: SelectionPoint
  ) => Partial<Style> | null
  getSelected: (
    content: Content,
    nextCharacter: Partial<Style> | null,
    selection: Selection
  ) => SelectionStyle
  apply: (state: Content, selection: Selection, styles: Partial<Style>) => void
  applyAll: (state: Content, styles: Partial<Style>) => void
  applyDefaults: (state: Content, styles: Partial<Style>) => void
}

type indexMap = { [key: number]: number }

export class TextEditorStyles implements Styles {
  private fontDataMap: ReadOnlyFontDataMap

  constructor(fontDataMap: ReadOnlyFontDataMap) {
    this.fontDataMap = fontDataMap
  }

  get = (state: Content, index: SelectionPoint): Style => {
    const { styles, contentStyles, styleOverrides } = state
    if (index.index === 0) return styles

    const styleIndex = contentStyles[index.index - 1]
    if (styleIndex === 0) return styles

    return {
      ...styles,
      ...styleOverrides[styleIndex],
    }
  }

  getOverride = (
    state: Content,
    selectionPoint: SelectionPoint
  ): Partial<Style> | null => {
    const { contentStyles, styleOverrides } = state
    if (selectionPoint.index === 0) return null

    const styleIndex = contentStyles[selectionPoint.index - 1]
    if (styleIndex === 0) return null

    return styleOverrides[styleIndex]
  }

  getSelected = (
    state: Content,
    nextCharacter: Partial<Style> | null,
    selection: Selection
  ): SelectionStyle => {
    const { focus, anchor } = selection
    if (anchor === null) {
      const style = this.get(state, focus)
      return {
        selected: { ...style, ...nextCharacter },
        defaults: { ...style, ...nextCharacter },
      }
    }

    const start = Math.min(focus.index, anchor.index) + 1
    const end = Math.max(focus.index, anchor.index) + 1

    const firstStyles = this.get(state, { index: start, wrapped: false })
    const multiStyle = this.getStyleMultiselect(start, end, firstStyles, state)

    return {
      selected: multiStyle,
      defaults: firstStyles,
    }
  }

  apply = (
    state: Content,
    selection: Selection,
    style: Partial<Style>
  ): void => {
    if (selection.focus === null || selection.anchor === null) return

    const start = Math.min(selection.focus.index, selection.anchor.index)
    const end = Math.max(selection.focus.index, selection.anchor.index)
    if (start === 0 && end === state.contentStyles.length) {
      this.applyFullSelection(state, style)
    } else {
      this.applyPartialSelection(state, selection, style)
    }

    const { styles, styleOverrides, contentStyles } = state
    this.updateInvalidFontWeights(styles, styleOverrides)
    this.collapseStyles(styleOverrides, contentStyles)
    this.removeSubsetOverrides(styles, styleOverrides, contentStyles)
    this.removeUnusedStyles(styleOverrides, contentStyles)
  }

  applyAll = (state: Content, style: Partial<Style>): void => {
    const selection = {
      anchor: { index: 0, wrapped: false },
      focus: {
        index: state.contentStyles.length,
        wrapped: state.content[state.contentStyles.length - 1] === '\n',
      },
    }
    this.apply(state, selection, style)
  }

  applyDefaults = (state: Content, defaults: Partial<Style>): void => {
    state.styles = { ...state.styles, ...defaults }
    const { styles, styleOverrides, contentStyles } = state

    this.updateInvalidFontWeights(styles, styleOverrides)
    this.collapseStyles(styleOverrides, contentStyles)
    this.removeSubsetOverrides(styles, styleOverrides, contentStyles)
    this.removeUnusedStyles(styleOverrides, contentStyles)
  }

  private applyFullSelection = (
    state: Content,
    style: Partial<Style>
  ): void => {
    state.styles = {
      ...state.styles,
      ...style,
    }
    for (const index of Object.keys(state.styleOverrides)) {
      state.styleOverrides[parseInt(index)] = {
        ...state.styleOverrides[parseInt(index)],
        ...style,
      }
    }
  }

  private applyPartialSelection = (
    state: Content,
    selection: Selection,
    style: Partial<Style>
  ): void => {
    const { focus, anchor } = selection
    const { styleOverrides, contentStyles } = state
    if (focus === null || anchor === null) return

    const start = Math.min(focus.index, anchor.index)
    const end = Math.max(focus.index, anchor.index)
    const selected = contentStyles.slice(start, end)

    const allCount = this.countInstances(contentStyles)
    const selectedCount = this.countInstances(selected)

    const newIndexes = this.createNewIndexes(allCount, selectedCount)
    const newOverrides = this.createNewOverrides(
      selected,
      newIndexes,
      style,
      styleOverrides
    )
    const newContentStyles = this.createNewStyleIndexes(
      start,
      end,
      contentStyles,
      newIndexes
    )
    const newStyle = this.mergeIndexZeroOverrides(state.styles, newOverrides)

    state.styleOverrides = newOverrides
    state.contentStyles = newContentStyles
    state.styles = newStyle
  }

  private countInstances = (array: number[]): indexMap => {
    return _.countBy(array)
  }

  private createNewIndexes = (
    allCount: indexMap,
    selectedCount: indexMap
  ): indexMap => {
    const newIndexes: indexMap = {}
    Object.keys(selectedCount)
      .map((i) => parseInt(i))
      .forEach((index) => {
        if (selectedCount[index] === allCount[index]) {
          newIndexes[index] = index
        } else {
          let newIndex = index
          while (
            allCount[newIndex] !== undefined ||
            Object.values(newIndexes).includes(newIndex)
          ) {
            newIndex++
          }
          newIndexes[index] = newIndex
        }
      })
    return newIndexes
  }

  private createNewOverrides = (
    selected: number[],
    newIndexes: indexMap,
    styles: Partial<Style>,
    overrides: StyleOverrides
  ): StyleOverrides => {
    const newOverrides: StyleOverrides = {
      ...overrides,
    }
    for (const index of selected) {
      const newIndex = newIndexes[index]
      newOverrides[newIndex] = {
        ...overrides[index],
        ...styles,
      }
    }
    return newOverrides
  }

  private createNewStyleIndexes = (
    start: number,
    end: number,
    contentStyles: number[],
    newIndexes: indexMap
  ): number[] => {
    return contentStyles.map((index, i) => {
      if (i >= start && i < end) {
        return newIndexes[index]
      }
      return index
    })
  }

  private updateInvalidFontWeights(
    style: Style,
    overrides: StyleOverrides
  ): void {
    const family = style.fontFamily
    const weight = style.fontWeight

    const fontData = this.fontDataMap.getFontData(family)
    if (fontData && fontData.weights.length > 0) {
      const found = fontData.weights.indexOf(weight) !== -1
      const foundRegular = fontData.weights.indexOf('regular') !== -1
      if (!found && foundRegular) {
        style.fontWeight = 'regular'
      } else if (!found) {
        style.fontWeight = fontData.weights[0]
      }
    }

    for (const id of Object.keys(overrides)) {
      const overrideId = parseInt(id)
      const override = overrides[overrideId]
      const combined = { ...style, ...override }
      const overrideFamily = combined.fontFamily
      const overrideWeight = combined.fontWeight

      const fontData = this.fontDataMap.getFontData(overrideFamily)
      if (!fontData || fontData.weights.length === 0) continue

      const found = fontData.weights.indexOf(overrideWeight) !== -1
      const foundRegular = fontData.weights.indexOf('regular') !== -1
      if (!found && foundRegular) {
        overrides[overrideId].fontWeight = 'regular'
      } else if (!found) {
        overrides[overrideId].fontWeight = fontData.weights[0]
      }
    }
  }

  private collapseStyles(
    overrides: StyleOverrides,
    contentStyles: number[]
  ): void {
    const newIndexes: indexMap = {}
    const newOverrides: StyleOverrides = {}

    Object.keys(overrides)
      .map((i) => parseInt(i))
      .sort()
      .forEach((index) => {
        const style = overrides[index]
        let found = false
        for (const [i, override] of Object.entries(newOverrides)) {
          if (parseInt(i) !== index && _.isEqual(style, override)) {
            newIndexes[index] = parseInt(i)
            found = true
            break
          }
        }
        if (!found) {
          newOverrides[index] = style
        }
      })

    for (const index of Object.keys(overrides)) {
      if (newOverrides[parseInt(index)] === undefined) {
        delete overrides[parseInt(index)]
      }
    }

    for (let i = 0; i < contentStyles.length; i++) {
      if (newIndexes[contentStyles[i]] !== undefined) {
        contentStyles[i] = newIndexes[contentStyles[i]]
      }
    }
  }

  private removeSubsetOverrides(
    style: Style,
    overrides: StyleOverrides,
    contentStyles: number[]
  ): void {
    for (const [index, override] of Object.entries(overrides)) {
      if (_.isMatch(style, override)) {
        delete overrides[parseInt(index)]
      }
    }

    for (let i = 0; i < contentStyles.length; i++) {
      if (overrides[contentStyles[i]] === undefined) {
        contentStyles[i] = 0
      }
    }
  }

  private removeUnusedStyles(
    overrides: StyleOverrides,
    contentStyles: number[]
  ): void {
    const usedStyles = new Set(contentStyles)
    Object.keys(overrides).forEach((key) => {
      const index = parseInt(key)
      if (!usedStyles.has(index)) {
        delete overrides[index]
      }
    })
  }

  private mergeIndexZeroOverrides = (
    style: Style,
    overrides: StyleOverrides
  ): Style => {
    let newStyle = { ...style }
    if (overrides[0] !== undefined) {
      newStyle = { ...newStyle, ...overrides[0] }
      delete overrides[0]
    }
    return newStyle
  }

  private getStyleMultiselect = (
    start: number,
    end: number,
    firstStyles: Style,
    state: Content
  ): StyleMultiselect => {
    const multiStyle: StyleMultiselect = { ...firstStyles }
    for (let i = start + 1; i < end; i++) {
      const index = this.get(state, { index: i, wrapped: false })
      for (const key of Object.keys(index)) {
        const current = multiStyle[key as keyof Style]
        if (current === 'Mixed') continue

        const next = index[key as keyof Style]
        if (_.isEqual(current, next)) continue

        multiStyle[key as keyof StyleMultiselect] = 'Mixed'
      }
    }

    return multiStyle
  }
}
