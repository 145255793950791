import { DesignColor } from 'themes'
import { Rectangle } from 'application/shapes'
import { DraggingLine } from 'editor/action/dragLayers/line'

interface LayersDraggingLineProps {
  line: DraggingLine | null
}

export default function LayersDraggingLine({ line }: LayersDraggingLineProps) {
  if (!line || line.position === 'inside') return <></>

  const box = getLayerRowBox(line.id)
  const level = getLayerRowLevel(line.id)
  const topOffset = getTopOffset(line.id)
  if (!box || level === null) return <></>

  const top = line.position === 'top' ? box.y : box.y + box.h

  return <Line top={top - topOffset} level={level} thickness={2} />
}

function getLayerRowBox(key: string | null): Rectangle | null {
  if (!key) return null

  const layerRow = document.getElementById(`layer-row-${key}`)
  if (!layerRow) return null

  const { left, top, height, width } = layerRow.getBoundingClientRect()
  return {
    x: left,
    y: top,
    w: width,
    h: height,
  }
}

function getLayerRowLevel(key: string | null): number | null {
  if (!key) return null
  const layerRow = document.getElementById(`layer-row-${key}`)
  if (!layerRow) return null
  return parseInt(layerRow.getAttribute('data-layer-level') || '0')
}

function getTopOffset(key: string | null): number {
  if (!key) return 0
  const layersContainer = document.getElementById(`layers`)

  if (!layersContainer) return 0
  return layersContainer.getBoundingClientRect().top
}

function Line({
  top,
  thickness,
  level,
}: {
  top: number
  thickness: number
  level: number
}) {
  return (
    <div
      style={{
        position: 'absolute',
        top: top - thickness / 2,
        right: 0,
        width: `calc(100% - ${8 + level * 24}px)`,
        height: thickness,
        background: DesignColor('text1'),
        zIndex: 1000,
      }}
    />
  )
}
