import {
  Properties,
  Transition,
  TransitionTiming,
} from 'application/export/css'
import { TailwindClassRenderer } from '../types'

export class TailwindTransition implements TailwindClassRenderer {
  render = (properties: Partial<Properties>): string[] => {
    const classes: string[] = []

    const transition = properties.transition
    const duration = properties.transitionDuration
    const property = properties.transitionProperty
    const timing = properties.transitionTimingFunction

    if (
      transition !== undefined &&
      transition !== 'none' &&
      transition !== 'inherit'
    ) {
      classes.push(...this.renderTransition(transition))
    }

    if (duration !== undefined) {
      classes.push(this.renderDuration(duration))
    }

    if (property !== undefined && property !== 'inherit') {
      classes.push(this.renderProperty(property))
    }

    if (timing !== undefined && timing !== 'inherit') {
      classes.push(this.renderTiming(timing))
    }

    return classes
  }

  private renderTransition = (transition: Transition): string[] => {
    const classes: string[] = []

    classes.push(this.renderDuration(transition.duration))
    classes.push(this.renderProperty(transition.property))
    classes.push(this.renderTiming(transition.timing))

    return classes
  }

  private renderDuration = (transitionDuration: string | number): string => {
    if (typeof transitionDuration === 'number') {
      return `duration-[${transitionDuration}ms]`
    }
    return `duration-[${transitionDuration}]`
  }

  private renderProperty = (transitionProperty: string): string => {
    return `transition-[${transitionProperty}]`
  }

  private renderTiming = (timing: TransitionTiming): string => {
    switch (timing) {
      case 'linear':
        return 'ease-linear'
      case 'ease':
        return 'ease'
      case 'ease-in':
        return 'ease-in'
      case 'ease-out':
        return 'ease-out'
      case 'ease-in-out':
        return 'ease-in-out'
    }
  }
}
