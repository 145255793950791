import { useState } from 'react'
import Dropdown, { dropdownOption } from '../../../Dropdown/Dropdown'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import Text from 'components/Library/Text/Text'
import useCommit from 'hooks/editor/useCommit'
import { AttributeFill, AttributeImageResize } from 'application/attributes'
import { DesignColor } from 'themes'

interface ImageRowProps {
  fill: AttributeFill
  updateFill: (fill: AttributeFill) => void

  open: boolean
  setOpen: (open: boolean) => void

  displayRef: React.RefObject<HTMLDivElement>

  hasOverride?: boolean
}

export default function ImageRow({
  fill,
  updateFill,
  open,
  setOpen,
  displayRef,
  hasOverride,
}: ImageRowProps) {
  const commit = useCommit()

  const [hover, setHover] = useState(false)
  const { image } = fill
  if (!image) return <></>

  const handleUpdateImage = (resize: AttributeImageResize) => {
    if (!fill.image) return
    updateFill({
      ...fill,
      image: { ...fill.image, resize },
    })
    commit()
  }

  const background = getBackground(open)
  const boxShadow = getBoxShadow(hover)

  return (
    <PanelRow>
      <div
        ref={displayRef}
        style={{
          width: 96,
          height: 24,
          padding: '0px 8px',
          boxSizing: 'border-box',
          display: 'flex',
          alignItems: 'center',
          gap: 8,
          borderRadius: 4,
          userSelect: 'none',
          background,
          boxShadow,
        }}
        onClick={() => setOpen(!open)}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <ImageDisplaySquare src={image.src} />
        <Text color={DesignColor(hasOverride ? 'overrideParent' : 'text1')}>
          Image
        </Text>
      </div>
      <Dropdown
        tooltipKey={'ObjectFit'}
        selected={image.resize}
        options={resizeModeOptions}
        select={handleUpdateImage}
        hasOverride={hasOverride}
      />
    </PanelRow>
  )
}

function ImageDisplaySquare({ src }: { src: string }) {
  return (
    <div
      style={{
        width: 16,
        height: 16,
        boxSizing: 'border-box',
        borderRadius: 4,
        backgroundImage: `url(${src})`,
        backgroundSize: 'contain',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        border: `1px solid ${DesignColor('panelBorder')}`,
      }}
    />
  )
}

function getBackground(open: boolean) {
  return open ? DesignColor('inputTint') : 'transparent'
}

function getBoxShadow(active: boolean) {
  if (active) return `inset 0px 0px 0px 1px ${DesignColor('inputHighlight')}`
  return 'none'
}

const resizeModeOptions: dropdownOption<AttributeImageResize>[] = [
  {
    value: 'fill',
    text: 'Fill',
  },
  {
    value: 'fit',
    text: 'Fit',
  },
  {
    value: 'stretch',
    text: 'Stretch',
  },
  {
    value: 'repeat',
    text: 'Repeating',
  },
]
