import {
  Context,
  DrawingTarget,
  UniformValue,
  VaoData,
  VertexBuffer,
  calcSceneBoundingBox,
} from 'application/render'
import { Rectangle } from 'application/shapes'
import {
  cleanupVaoData,
  connectBuffersToVao,
  createEmptyVaoData,
  standardDraw,
} from '../utils'
import { MaterialType } from './shaderMap'

export type WebglTransferData = {
  source: DrawingTarget
  bb?: Rectangle
}

export class WebglTransfer {
  private context: Context
  private data: WebglTransferData
  private vaoData: VaoData

  constructor(context: Context, data: WebglTransferData) {
    this.context = context
    this.data = data
    this.vaoData = createEmptyVaoData()
  }

  init = (): void => {
    this.updateRenderData()
  }

  draw(depth?: DrawingTarget, alpha: number = 1): void {
    if (!this.vaoData.material) return
    if (depth !== undefined) this.context.setDrawingTarget(depth)
    this.vaoData.material.setSampler(
      'uSampler',
      this.context.getSceneTexture(this.data.source).getTexture(),
      0
    )
    this.vaoData.uniforms['uAlpha'] = alpha
    standardDraw(this.vaoData)
  }

  updateRenderData(bounds: Rectangle | undefined = undefined): void {
    cleanupVaoData(this.vaoData)
    const gl = this.context.getGl()
    const material = this.context.getMaterial(MaterialType.transfer)
    const vao = gl.createVertexArray()
    const buffers: { [key: string]: VertexBuffer } = {}
    const uniforms: { [key: string]: UniformValue } = {}

    const { bb } = this.data

    const aPosition = new Float32Array(
      calcSceneBoundingBox(this.context, bounds || bb)
    )

    buffers['aPosition'] = this.context.createVertexBuffer(aPosition, 2)
    const verticeCount = aPosition.length / 2

    const vaoData = { vao, material, buffers, uniforms, verticeCount }
    connectBuffersToVao(vaoData)
    this.vaoData = vaoData
  }

  setSource(source: DrawingTarget): void {
    this.data.source = source
  }

  cleanup = (): void => {
    cleanupVaoData(this.vaoData)
  }
}

export const transferVs = `#version 300 es
precision highp float;

uniform mat3 uMatrix;

in vec2 aPosition;

out vec2 vTexCoords;

void main() {
  vec3 transformedPosition = uMatrix * vec3(aPosition, 1);
  gl_Position = vec4(transformedPosition.xy, 0, 1);

  vTexCoords = transformedPosition.xy * 0.5 + 0.5;
}
`

export const transferFs = `#version 300 es
precision mediump float;

uniform sampler2D uSampler;
uniform float uAlpha;

in vec2 vTexCoords;

out vec4 outColor;

void main() {
  vec4 color = texture(uSampler, vTexCoords);
  outColor = vec4(color.rgb, color.a * uAlpha);
}
`
