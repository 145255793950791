import { ContentTransformer } from './content'
import { StyleTransformer } from './style/transformer'
import { HTMLRenderer } from './render/render'
import { formatNodeId } from './utils'
import { HTMLDocumentBuilder } from './html/documentBuilder'
import { ReadOnlyNode } from 'application/node'
import { ReadOnlyDocument } from 'application/document'
import { Element, HTMLDocument } from './html/types'
import { CodeBlockType, CodeExport } from './types'
import { FontsTransformer } from './fonts'

export class DocumentTransformer {
  private content: ContentTransformer
  private style: StyleTransformer
  private fonts: FontsTransformer
  private render: HTMLRenderer

  constructor() {
    this.content = new ContentTransformer()
    this.style = new StyleTransformer()
    this.fonts = new FontsTransformer()
    this.render = new HTMLRenderer()
  }

  transform = (
    id: string,
    document: ReadOnlyDocument,
    styleMode: CodeBlockType,
    exportMode: CodeBlockType
  ): CodeExport | null => {
    const htmlDocument = new HTMLDocumentBuilder().build()

    const baseNode = document.getNode(id)
    if (!baseNode) return null

    this.addElements(document, baseNode, htmlDocument)
    this.addStyles(document, baseNode, htmlDocument)

    const element = htmlDocument.getById(formatNodeId(id))
    if (!element) return null

    const styleMap = htmlDocument.getStyles()
    const styles = styleMap.getForMode(formatNodeId(id), 'id')
    const fonts = this.fonts.transform(id, document)

    const renderedCode = this.render.renderCode(
      id,
      document,
      htmlDocument,
      styleMap,
      exportMode
    )
    const renderedStyles = this.render.renderStyles(styles, styleMode)
    const renderedFonts = this.render.renderFonts(fonts, exportMode)

    return {
      code: renderedCode,
      style: renderedStyles,
      font: renderedFonts,
    }
  }

  private addElements = (
    document: ReadOnlyDocument,
    node: ReadOnlyNode,
    html: HTMLDocument,
    element: Element | null = null
  ): void => {
    const htmlElement = this.content.transform(node)
    if (!htmlElement) return

    if (element) {
      element.addChild(htmlElement)
    } else {
      html.getBody().addChild(htmlElement)
    }

    this.style.transform(node, html, document)

    const children = node.getChildren()
    if (!children) return

    for (const childId of children) {
      const child = document.getNode(childId)
      if (child) this.addElements(document, child, html, htmlElement)
    }
  }

  private addStyles = (
    document: ReadOnlyDocument,
    node: ReadOnlyNode,
    html: HTMLDocument
  ) => {
    const htmlElement = html.getById(formatNodeId(node.getId()))
    if (!htmlElement) return

    this.style.transform(node, html, document)

    const children = node.getChildren()
    if (!children) return

    for (const childId of children) {
      const child = document.getNode(childId)
      if (child) this.addStyles(document, child, html)
    }
  }
}
