import ProjectSettingField from './ProjectSettingField'
import { useRecoilState } from 'recoil'
import ProjectSettingCard from './ProjectSettingCard'
import MenuButton from 'components/Library/Menus/MenuButton'
import useAuthService from 'hooks/services/useAuthService'
import useNavigatePage from 'hooks/admin/useNavigatePage'
import { DesignColor } from 'themes'
import useUserService from 'hooks/services/useUserService'
import useToast from 'hooks/editor/useToast'
import { userInfoState } from 'components/Pages/Projects/Projects'
import useBackendService from 'hooks/services/useBackendService'
import { useState } from 'react'
import PaymentScreen from 'components/Library/Components/PaymentScreen/PaymentScreen'
import { loadStripe } from '@stripe/stripe-js'

export default function ProjectSettingBody() {
  const authService = useAuthService()
  const userService = useUserService()
  const backendService = useBackendService()
  const toast = useToast()
  const { toLogin } = useNavigatePage()

  const { getUserInfo } = useUserService()
  const [userInfo, setUserInfo] = useRecoilState(userInfoState)

  const [upgrading, setUpgrading] = useState(false)

  const updateName = async (firstName: string, lastName: string) => {
    try {
      await userService.updateUserInfo({ firstName, lastName })
      const updated = await userService.getUserInfo()
      setUserInfo(updated)
      toast('User Info Updated', 'success')
    } catch (e) {
      toast('Repaint is offline, please try again later.', 'error')
    }
  }

  const handleUpgrade = async () => {
    const res = await backendService.startCheckoutSession()
    if (!res.sessionId) {
      const userInfo = await getUserInfo()
      setUserInfo(userInfo)
      return
    }

    const stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY || '')
    if (!stripe) return

    await stripe.redirectToCheckout({ sessionId: res.sessionId })
  }

  const handleDowngrade = async () => {
    const res = await backendService.startManageSession()
    if (!res.sessionUrl) {
      const userInfo = await getUserInfo()
      setUserInfo(userInfo)
      return
    }

    window.open(res.sessionUrl, '_blank')
  }

  return (
    <>
      <div
        style={{
          width: '100%',
          padding: '24px',
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
          gap: '24px',
        }}
      >
        <ProjectSettingCard>
          <h2
            style={{
              color: DesignColor('text1'),
              fontSize: '20px',
              fontWeight: 400,
            }}
          >
            Account
          </h2>
          <div
            style={{
              display: 'flex',
              gap: '16px',
            }}
          >
            <ProjectSettingField
              label={'First Name'}
              placeholder={'John'}
              value={userInfo.firstName}
              setValue={(first: string) => updateName(first, userInfo.lastName)}
            />
            <ProjectSettingField
              label={'Last Name'}
              placeholder={'Doe'}
              value={userInfo.lastName}
              setValue={(last: string) => updateName(userInfo.firstName, last)}
            />
          </div>
          <div style={{ display: 'flex', gap: '16px' }}>
            <MenuButton
              label={'Manage Plan'}
              onClick={() => setUpgrading(true)}
            />
            <MenuButton
              label={'Logout'}
              onClick={() => {
                authService.clearToken()
                toLogin()
              }}
            />
          </div>
        </ProjectSettingCard>
      </div>
      {upgrading && (
        <PaymentScreen
          onClose={() => setUpgrading(false)}
          onUpgrade={handleUpgrade}
          onDowngrade={handleDowngrade}
          upgrading={upgrading}
          userInfo={userInfo}
        />
      )}
    </>
  )
}
