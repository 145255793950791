import {
  AttributeFill,
  AttributeGradient,
  isFillEqual,
} from 'application/attributes'
import { Point, Rectangle } from 'application/shapes'
import { Color } from 'application/color'
import { rgbaToWebgl } from 'application/color'
import { WebglTextData } from './renderables/webglText/webglText'
import {
  FontLoaderInterface,
  ShapedText,
  TextContent,
  TextStyle,
} from 'application/text'
import { TextRenderBox } from './types'
import { Context } from './gpu/context'

export function rectToQuadTriangles(rect: Rectangle): number[] {
  const { x, y, w, h } = rect
  // prettier-ignore
  return [
    x, y,
    x + w, y,
    x, y + h,
    x, y + h,
    x + w, y,
    x + w, y + h
  ]
}

export function calcOuterShadowPosition(
  rect: Rectangle,
  spread: number,
  blur: number
): number[] {
  const { x, y, w, h } = rect
  const b = blur * 2
  const s = spread
  const shadowRect = {
    x: x - b - s,
    y: y - b - s,
    w: w + b * 2 + s * 2,
    h: h + b * 2 + s * 2,
  }
  return rectToQuadTriangles(shadowRect)
}

export function calcBlurPosition(rect: Rectangle, blur: number): number[] {
  const { x, y, w, h } = rect
  const b = blur * 2
  const shadowRect = {
    x: x - b,
    y: y - b,
    w: w + b * 2,
    h: h + b * 2,
  }
  return rectToQuadTriangles(shadowRect)
}

export function calcRounding(
  w: number,
  h: number,
  rounding?: { tl: number; tr: number; br: number; bl: number }
): [number, number, number, number] {
  if (!rounding) return [0, 0, 0, 0]
  const tl = rounding.tl || 0
  const tr = rounding.tr || 0
  const br = rounding.br || 0
  const bl = rounding.bl || 0

  const sumL = tl + bl || 1
  const sumT = tl + tr || 1
  const sumR = tr + br || 1
  const sumB = bl + br || 1

  const minTl = Math.min(tl, (h * tl) / sumL, (w * tl) / sumT)
  const minTr = Math.min(tr, (h * tr) / sumR, (w * tr) / sumT)
  const minBr = Math.min(br, (h * br) / sumR, (w * br) / sumB)
  const minBl = Math.min(bl, (h * bl) / sumL, (w * bl) / sumB)

  return [minTl, minTr, minBr, minBl]
}

export function calcBoundingBox(
  bb?: Rectangle
): [number, number, number, number] {
  if (!bb) return [-100000, -100000, 200000, 200000]
  const { x, y, w, h } = bb
  return [x, y, w, h]
}

export function calcRectPosition(
  rect: Rectangle,
  fill: AttributeFill
): number[] {
  if (fill.type === 'gradient') return calcGradientQuads(rect, fill)
  return rectToQuadTriangles(rect)
}

export function calcGradientQuads(
  rect: Rectangle,
  fill: AttributeFill
): number[] {
  const { x, y, w, h } = rect
  const g = fill.gradient

  if (fill.type !== 'gradient' || !g || g.steps.length === 0) {
    return []
  }

  const steps = g.steps

  if (steps[0].position !== 0) {
    steps.unshift({ position: 0, color: steps[0].color, key: steps[0].key })
  }
  if (steps[steps.length - 1].position !== 100) {
    steps.push({
      position: 100,
      color: steps[steps.length - 1].color,
      key: steps[steps.length - 1].key,
    })
  }

  const aPosition: number[] = []

  const angle = g.angle || 0
  const rad = (angle * Math.PI) / 180
  const cx = x + w / 2
  const cy = y + h / 2

  for (let i = 0; i < steps.length - 1; i++) {
    const s1 = steps[i]
    const s2 = steps[i + 1]
    const scaledW = Math.abs(w * Math.cos(rad)) + Math.abs(h * Math.sin(rad))
    const scaledH = Math.abs(w * Math.sin(rad)) + Math.abs(h * Math.cos(rad))

    const stripe = {
      x: cx - scaledW / 2,
      y: cy - scaledH / 2 + (scaledH * s1.position) / 100,
      w: scaledW,
      h: scaledH * ((s2.position - s1.position) / 100),
    }

    const points = [
      { x: stripe.x, y: stripe.y },
      { x: stripe.x + stripe.w, y: stripe.y },
      { x: stripe.x, y: stripe.y + stripe.h },
      { x: stripe.x, y: stripe.y + stripe.h },
      { x: stripe.x + stripe.w, y: stripe.y },
      { x: stripe.x + stripe.w, y: stripe.y + stripe.h },
    ]

    // Rotate each point around the center
    for (let j = 0; j < points.length; j++) {
      const p = points[j]
      const x = cx + (p.x - cx) * Math.cos(rad) - (p.y - cy) * Math.sin(rad)
      const y = cy + (p.x - cx) * Math.sin(rad) + (p.y - cy) * Math.cos(rad)
      aPosition.push(x)
      aPosition.push(y)
    }
  }

  return aPosition
}

export function calcRectColors(fill?: AttributeFill): number[] {
  if (!fill) return []

  if (fill.type === 'color' && fill.color) {
    return calcSolidColors(fill.color)
  }
  if (fill.type === 'gradient' && fill.gradient) {
    return calcGradientColors(fill.gradient)
  }

  return []
}

export function calcSolidColors(color: Color): number[] {
  const colors: number[] = []
  const c = rgbaToWebgl(color)
  const vertices = 6
  for (let i = 0; i < vertices; i++) {
    colors.push(...c)
  }
  return colors
}

export function calcGradientColors(gradient: AttributeGradient): number[] {
  const steps = gradient.steps
  if (steps.length === 0) return []
  if (steps.length === 1) return calcSolidColors(steps[0].color)

  const colors: number[] = []

  for (let i = 0; i < steps.length - 1; i++) {
    const c1 = rgbaToWebgl(steps[i].color)
    const c2 = rgbaToWebgl(steps[i + 1].color)

    if (i === 0 && steps[i].position !== 0) {
      for (let j = 0; j < 6; j++) {
        colors.push(...c1)
      }
    }

    colors.push(...c1, ...c1, ...c2, ...c2, ...c1, ...c2)

    if (i === steps.length - 2 && steps[i + 1].position !== 100) {
      for (let j = 0; j < 6; j++) {
        colors.push(...c2)
      }
    }
  }

  return colors
}

export function calcLinePositions(
  lines: { start: Point; end: Point }[],
  thickness: number,
  dashed?: boolean,
  dashLength?: number
): number[] {
  const aPosition: number[] = []

  for (const linePoints of lines) {
    const solidVertices = calcSolidLinePositions(linePoints, thickness, dashed)
    const dashVertices = calcDashedLinePositions(
      linePoints,
      thickness,
      dashed,
      dashLength
    )
    aPosition.push(...dashVertices, ...solidVertices)
  }

  return aPosition
}

function calcSolidLinePositions(
  linePoints: { start: Point; end: Point },
  thickness: number,
  dashed?: boolean
): number[] {
  if (dashed) return []
  const { start, end } = linePoints
  const dx = end.x - start.x
  const dy = end.y - start.y
  const angle = Math.atan2(dy, dx)
  const offsetX = (thickness / 2) * Math.sin(angle)
  const offsetY = (thickness / 2) * Math.cos(angle)

  // prettier-ignore
  return [
    start.x - offsetX, start.y + offsetY,
    start.x + offsetX, start.y - offsetY,
    end.x - offsetX, end.y + offsetY,
    end.x - offsetX, end.y + offsetY,
    start.x + offsetX, start.y - offsetY,
    end.x + offsetX, end.y - offsetY,
  ]
}

function calcDashedLinePositions(
  linePoints: { start: Point; end: Point },
  thickness: number,
  dashed?: boolean,
  dashLength?: number
): number[] {
  if (!dashed || !dashLength || dashLength <= 0) return []
  const { start, end } = linePoints
  const dx = end.x - start.x
  const dy = end.y - start.y
  const totalLength = Math.sqrt(dx * dx + dy * dy)
  const angle = Math.atan2(dy, dx)
  const offsetX = (thickness / 2) * Math.sin(angle)
  const offsetY = (thickness / 2) * Math.cos(angle)

  let currentLength = 0
  const dashVertices = []

  while (currentLength < totalLength) {
    let dashEndLength = currentLength + dashLength
    if (dashEndLength > totalLength) dashEndLength = totalLength

    const dashStartX = start.x + (dx * currentLength) / totalLength
    const dashStartY = start.y + (dy * currentLength) / totalLength
    const dashEndX = start.x + (dx * dashEndLength) / totalLength
    const dashEndY = start.y + (dy * dashEndLength) / totalLength

    const p1 = [dashStartX - offsetX, dashStartY + offsetY]
    const p2 = [dashStartX + offsetX, dashStartY - offsetY]
    const p3 = [dashEndX - offsetX, dashEndY + offsetY]
    const p4 = [dashEndX + offsetX, dashEndY - offsetY]

    dashVertices.push(...p1, ...p2, ...p3, ...p2, ...p3, ...p4)
    currentLength += dashLength * 2
  }

  return dashVertices
}

export const MAX_BLUR_SAMPLES = 101

export function calcGaussianWeights(blur: number): number[] {
  if (blur > MAX_BLUR_SAMPLES) blur = MAX_BLUR_SAMPLES

  let sigma = blur / 3.0
  let weights: number[] = Array(MAX_BLUR_SAMPLES).fill(0)
  let totalWeight = 0

  if (blur === 0) {
    weights[0] = 1
  } else {
    for (let i = 0; i <= blur; i++) {
      let weight = Math.exp((-0.5 * Math.pow(i, 2)) / Math.pow(sigma, 2))
      weights[i] = weight
      totalWeight += weight * (i === 0 ? 1 : 2)
    }

    for (let i = 0; i <= blur; i++) {
      weights[i] /= totalWeight
    }
  }

  return weights
}

export function calcImagePosition(
  rect: Rectangle,
  resizeMode: 'stretch' | 'fit' | 'fill' | 'parallax' | 'repeat',
  originalSize?: { w: number; h: number }
): number[] {
  const { x, y, w, h } = rect
  const rectRatio = w / h
  let imageRatio = 1
  if (originalSize) {
    imageRatio = originalSize.w / originalSize.h
  }

  switch (resizeMode) {
    case 'stretch':
    case 'fill':
    case 'parallax':
    case 'repeat':
      return rectToQuadTriangles(rect)
    case 'fit':
      const tooWide = rectRatio > imageRatio
      let displayW = tooWide ? h * imageRatio : w
      let displayH = tooWide ? h : w / imageRatio
      return rectToQuadTriangles({
        x: x + (w - displayW) / 2,
        y: y + (h - displayH) / 2,
        w: displayW,
        h: displayH,
      })
  }
}

export function calcImageTexCoords(
  rect: Rectangle,
  resizeMode: 'stretch' | 'fit' | 'fill' | 'parallax' | 'repeat',
  originalSize?: { w: number; h: number }
): number[] {
  const { x, y, w, h } = rect
  const defaultCoords = [0, 0, 1, 0, 0, 1, 0, 1, 1, 0, 1, 1]
  switch (resizeMode) {
    case 'stretch':
    case 'fit':
    case 'repeat':
      return defaultCoords
    case 'fill':
    case 'parallax':
      const rectRatio = w / h
      let imageRatio = 1
      if (originalSize) {
        imageRatio = originalSize.w / originalSize.h
      }
      const tooWide = rectRatio > imageRatio
      const displayW = tooWide ? w : h * imageRatio
      const displayH = tooWide ? w / imageRatio : h
      const displayX = x + (w - displayW) / 2
      const displayY = y + (h - displayH) / 2
      const displayRect = { x: displayX, y: displayY, w: displayW, h: displayH }
      return calcImageTexCoordsFill(rect, displayRect)
  }
}

export function calcImageTexCoordsFill(
  rect: Rectangle,
  displayRect: Rectangle
): number[] {
  const { x: x1, y: y1, w: w1, h: h1 } = displayRect
  const { x: x2, y: y2, w: w2, h: h2 } = rect

  const intersectionRect = {
    x: Math.max(x1, x2),
    y: Math.max(y1, y2),
    w: Math.min(x1 + w1, x2 + w2) - Math.max(x1, x2),
    h: Math.min(y1 + h1, y2 + h2) - Math.max(y1, y2),
  }

  const normalizedRect = {
    x: (intersectionRect.x - x1) / w1,
    y: (intersectionRect.y - y1) / h1,
    w: intersectionRect.w / w1,
    h: intersectionRect.h / h1,
  }

  return rectToQuadTriangles(normalizedRect)
}

export function calcTextBoxes(
  text: TextContent,
  shaped: ShapedText
): TextRenderBox[] {
  const boxes: TextRenderBox[] = []
  let glyphIndex = 0
  let previousRow = -1
  let spanNumber = 0

  for (let i = 0; i < shaped.rows.length; i++) {
    const row = shaped.rows[i]

    for (let j = 0; j < row.characters.length; j++) {
      const glyph = row.characters[j]
      const styleIndex = text.contentStyles[glyphIndex]
      const glyphStyles = {
        ...text.styles,
        ...text.styleOverrides[styleIndex],
      }

      if (!isNewBox(boxes, glyphStyles, i, previousRow)) {
        const box = boxes[boxes.length - 1]
        box.content += text.content[glyphIndex]
        box.glyphs.push(glyph)
        box.w += glyph.w
        if (glyph.h > box.h) box.h = glyph.h
      } else {
        if (isNewSpan(boxes, glyphStyles)) spanNumber++
        boxes.push({
          content: text.content[glyphIndex],
          x: glyph.x,
          y: glyph.y,
          w: glyph.w,
          h: glyph.h,
          fontFamily: glyphStyles.fontFamily,
          fontWeight: glyphStyles.fontWeight,
          fontSize: glyphStyles.fontSize,
          fill: glyphStyles.fill,
          spanNumber: spanNumber,
          boxNumber: boxes.length,
          glyphs: [glyph],
        })
      }
      glyphIndex++
      previousRow = i
    }
  }

  return boxes
}

function isNewBox(
  boxes: TextRenderBox[],
  glyphStyles: TextStyle,
  row: number,
  previousRow: number
) {
  if (boxes.length === 0) return true
  return (
    isNewSpan(boxes, glyphStyles) ||
    (row !== previousRow && glyphStyles.fill.type === 'gradient')
  )
}

function isNewSpan(boxes: TextRenderBox[], glyphStyles: TextStyle) {
  if (boxes.length === 0) return true
  const lastBox = boxes[boxes.length - 1]
  return (
    glyphStyles.fontFamily !== lastBox.fontFamily ||
    glyphStyles.fontWeight !== lastBox.fontWeight ||
    glyphStyles.fontSize !== lastBox.fontSize ||
    !isFillEqual(glyphStyles.fill, lastBox.fill)
  )
}

export function calcGlyphPositions(
  box: TextRenderBox,
  text: WebglTextData,
  fontLoader: FontLoaderInterface
): number[] {
  const aPosition: number[] = []
  const content = box.content

  const font = fontLoader.getFont(box.fontFamily, box.fontWeight)
  const chardata = fontLoader.getChardata(box.fontFamily, box.fontWeight)
  if (!font || !chardata) return []

  for (let i = 0; i < box.glyphs.length; i++) {
    const shapedGlyph = box.glyphs[i]
    const glyph = font.charToGlyph(content[i])

    const msdfData = chardata.glyphs.find((g) => g.unicode === glyph.unicode)
    if (!msdfData) continue

    const { planeBounds } = msdfData
    const yMax = glyph.yMax
    const fontSize = box.fontSize
    if (yMax === undefined || !planeBounds) continue

    const x = shapedGlyph.x + text.x + planeBounds.left * fontSize
    const y =
      shapedGlyph.y +
      shapedGlyph.yOffset +
      text.y -
      (planeBounds.top - yMax / font.unitsPerEm) * fontSize
    const h = (planeBounds.top - planeBounds.bottom) * fontSize
    const w = (planeBounds.right - planeBounds.left) * fontSize

    aPosition.push(...rectToQuadTriangles({ x, y, w, h }))
  }

  return aPosition
}

export function calcGlyphMsdfCoords(
  box: TextRenderBox,
  text: WebglTextData,
  fontLoader: FontLoaderInterface
): number[] {
  const aMsdfCoords: number[] = []
  const content = box.content

  const font = fontLoader.getFont(box.fontFamily, box.fontWeight)
  const chardata = fontLoader.getChardata(box.fontFamily, box.fontWeight)
  if (!font || !chardata) return []
  const atlas = chardata.atlas

  for (let i = 0; i < box.glyphs.length; i++) {
    const glyph = font.charToGlyph(content[i])

    const msdfData = chardata.glyphs.find((g) => g.unicode === glyph.unicode)
    if (!msdfData) continue

    const { atlasBounds } = msdfData
    if (!atlasBounds) continue

    const x = atlasBounds.left / atlas.width
    const y = (atlas.height - atlasBounds.top) / atlas.height
    const w = (atlasBounds.right - atlasBounds.left) / atlas.width
    const h = (atlasBounds.top - atlasBounds.bottom) / atlas.height

    aMsdfCoords.push(...rectToQuadTriangles({ x, y, w, h }))
  }

  return aMsdfCoords
}

export function calcTextGradientBoxRect(
  box: TextRenderBox,
  data: WebglTextData,
  boxes: TextRenderBox[]
): Rectangle {
  var totalWidth = 0
  for (let i = 0; i < boxes.length; i++) {
    if (boxes[i].spanNumber === box.spanNumber) {
      totalWidth += boxes[i].w
    }
  }
  const { startPercent } = getInterpolationPercents(box, boxes)
  return {
    x: data.x + box.x - startPercent * totalWidth,
    y: data.y + box.y,
    w: totalWidth,
    h: box.h,
  }
}

function getInterpolationPercents(box: TextRenderBox, boxes: TextRenderBox[]) {
  let beforeWidth = 0
  let totalWidth = 0
  for (let i = 0; i < boxes.length; i++) {
    if (boxes[i].spanNumber === box.spanNumber) {
      totalWidth += boxes[i].w
      if (boxes[i].boxNumber < box.boxNumber) {
        beforeWidth += boxes[i].w
      }
    }
  }
  const startPercent = beforeWidth / totalWidth
  const endPercent = (beforeWidth + box.w) / totalWidth
  return { startPercent, endPercent }
}

export function calcSceneBoundingBox(context: Context, bb?: Rectangle) {
  if (bb) return rectToQuadTriangles(bb)

  const { w: baseW, h: baseH } = context.getSize()
  const camera = context.getCamera()

  const x = camera.x
  const y = -camera.y
  const w = baseW / camera.z
  const h = baseH / camera.z

  return rectToQuadTriangles({ x, y, w, h })
}
