import { ObjectPool } from '../pool/objectPool'
import { FlexSizeItem } from './flexSizeItem'
import { FlexSizeLineImpl } from './flexSizeLineImpl'

export class FlexSizeLineImplPool extends ObjectPool<FlexSizeLineImpl> {
  constructor(size: number) {
    super(FlexSizeLineImpl, size)
  }

  fromItems = (flexSizeItems: FlexSizeItem[]): FlexSizeLineImpl => {
    const line = this.get()
    line.items = flexSizeItems
    return line
  }
}
