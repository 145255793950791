const publicURL = process.env.PUBLIC_URL

export const Cursors = {
  CanvasDefault: `url(${publicURL}/cursors/CanvasDefault.svg) 6 2, auto`,
  DrawButton: `url(${publicURL}/cursors/DrawButton.svg) 2 13, auto`,
  DrawForm: `url(${publicURL}/cursors/DrawForm.svg) 2 13, auto`,
  DrawFrame: `url(${publicURL}/cursors/DrawFrame.svg) 2 13, auto`,
  DrawImage: `url(${publicURL}/cursors/DrawImage.svg) 2 13, auto`,
  DrawInput: `url(${publicURL}/cursors/DrawInput.svg) 2 13, auto`,
  DrawLink: `url(${publicURL}/cursors/DrawLink.svg) 2 13, auto`,
  DrawPage: `url(${publicURL}/cursors/DrawPage.svg) 2 13, auto`,
  DrawText: `url(${publicURL}/cursors/DrawText.svg) 12 12, auto`,
  DrawHeading: `url(${publicURL}/cursors/DrawHeading.svg) 12 12, auto`,
  DrawAnchor: `url(${publicURL}/cursors/DrawAnchor.svg) 12 12, auto`,
  HandClosed: `url(${publicURL}/cursors/HandClosed.svg) 10 12, auto`,
  HandOpen: `url(${publicURL}/cursors/HandOpen.svg) 10 12, auto`,
  Target: `url(${publicURL}/cursors/Target.svg) 12 12, auto`,
}
