import React, { useEffect, useState } from 'react'
import IconBlock from '../../IconBlock/IconBlock/IconBlock'
import { DesignColor } from 'themes'
import hljs from 'highlight.js'
import 'highlight.js/styles/atom-one-dark.css'

declare const prettier: any
declare const prettierPlugins: any

export interface TextCodeProps {
  code: string
  language: TextCodeLanguage
  copy: () => void
  bottom: boolean
}

export type TextCodeLanguage =
  | 'javascript'
  | 'html'
  | 'css'
  | 'tailwind'
  | 'jsx+tailwind'

const languageToParser = {
  javascript: 'babel',
  html: 'html',
  'jsx+tailwind': 'html',
  css: 'css',
  tailwind: 'plaintext',
}

const languageToSyntax = {
  javascript: 'jsx',
  html: 'html',
  'jsx+tailwind': 'html',
  css: 'css',
  tailwind: 'plaintext',
}

export default function TextCode({
  code,
  language,
  copy,
  bottom,
}: TextCodeProps) {
  const [formattedCode, setFormattedCode] = useState<string>('<div></div>')

  useEffect(() => {
    if (languageToSyntax[language] !== 'plaintext') {
      const options = {
        parser: languageToParser[language],
        plugins: prettierPlugins,
        semi: true,
        singleQuote: true,
        tabWidth: 2,
      }
      prettier.format(code, options).then((formatted: string) => {
        const highlighted = hljs.highlight(
          languageToSyntax[language],
          formatted
        ).value
        setFormattedCode(highlighted)
      })
    } else {
      setFormattedCode(code)
    }
  }, [code, language])

  return (
    <CodeBlockWrapper bottom={bottom} copy={copy}>
      <pre
        style={{
          fontSize: '11px',
          padding: 0,
          margin: 0,
          maxHeight: 200,
          overflow: 'auto',
          color: '#abb2bf',
        }}
      >
        <code dangerouslySetInnerHTML={{ __html: formattedCode }} />
      </pre>
    </CodeBlockWrapper>
  )
}

function CodeBlockWrapper({
  children,
  bottom,
  copy,
}: {
  children: React.ReactNode
  bottom: boolean
  copy: () => void
}) {
  return (
    <div
      style={{
        position: 'relative',
        padding: 8,
        borderBottom: !bottom ? `1px solid ${DesignColor('panelBorder')}` : 0,
      }}
    >
      <IconBlock
        style={{
          position: 'absolute',
          top: 4,
          right: 4,
        }}
        icon={'Copy'}
        onClick={copy}
      />
      {children}
    </div>
  )
}
