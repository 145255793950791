import { HistoryEntry, HistoryEvent, HistoryLog } from 'application/history'

export interface ClientTransactionHandler {
  addEvent: (event: HistoryEvent) => void
  clear: () => void
  commit: () => void
  onStartUndo: () => void
  onEndUndo: () => void
  onStartRedo: () => void
  onEndRedo: () => void
}

export class ClientTransactionLogHandler implements ClientTransactionHandler {
  private log: HistoryLog
  private activeEntry: HistoryEntry
  private inHistoryTransaction: boolean

  constructor(log: HistoryLog) {
    this.log = log
    this.activeEntry = { events: [] }
    this.inHistoryTransaction = false
  }

  addEvent = (event: HistoryEvent): void => {
    if (this.inHistoryTransaction) return
    this.activeEntry.events.push(event)
  }

  clear = (): void => {
    this.activeEntry = { events: [] }
    this.log.clear()
  }

  commit = (): void => {
    if (this.activeEntry.events.length > 0) {
      this.log.add(this.activeEntry)
      this.activeEntry = { events: [] }
    }
  }

  onStartUndo = (): void => {
    this.inHistoryTransaction = true
  }

  onEndUndo = (): void => {
    this.inHistoryTransaction = false
  }

  onStartRedo = (): void => {
    this.inHistoryTransaction = true
  }

  onEndRedo = (): void => {
    this.inHistoryTransaction = false
  }
}
