import { DesignColor } from 'themes'
import { Camera, CameraUpdateListener } from 'application/camera'
import { Rectangle } from 'application/shapes'
import { Window } from './types'
import { Canvas, CanvasUpdateListener } from 'editor/canvas/canvas'
import { WindowTransformer } from './transformer/window'

export const hapticChildWindowsKey = 'childWindows'

export class HapticChildWindows
  implements CameraUpdateListener, CanvasUpdateListener
{
  private canvas: Canvas

  private windows: Window[]
  private camera: Camera | null

  constructor(canvas: Canvas) {
    this.canvas = canvas

    this.windows = []
    this.camera = null
  }

  onCamera = (camera: Camera) => {
    this.camera = camera
    this.render()
  }

  onCanvasInit = () => {
    this.render()
  }

  setRectangles = (moving: Rectangle[], highlight: Rectangle[]): void => {
    this.windows = []
    for (const rectangle of moving) {
      this.windows.push({
        rectangle,
        border: DesignColor('inputHighlight'),
        fill: null,
        borderWidth: 1,
        bubbles: 'none',
        shape: 'rectangle',
        dashed: true,
      })
    }
    for (const rectangle of highlight) {
      this.windows.push({
        rectangle,
        border: DesignColor('inputHighlight'),
        fill: null,
        borderWidth: 1,
        bubbles: 'none',
        shape: 'rectangle',
      })
    }
    this.render()
  }

  private render = () => {
    if (!this.canvas.isReady()) return
    if (this.windows.length === 0 || this.camera === null) {
      this.canvas.deleteHaptic(hapticChildWindowsKey)
    } else {
      this.canvas.setHaptic(
        hapticChildWindowsKey,
        WindowTransformer.transform(
          this.canvas.getContext(),
          this.windows,
          this.camera
        )
      )
    }
  }
}
