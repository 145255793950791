import { StyleMap } from 'application/attributes'
import IconBlock from 'components/Library/Components/IconBlock/IconBlock/IconBlock'
import OverrideReset from 'components/Library/Components/Inputs/OverrideReset/OverrideReset'
import NumberBlock from 'components/Library/Components/NumberBlock/NumberBlock'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'
import { useState } from 'react'
import OverflowBlock from './OverflowBlock'
import PanelBlock from 'components/Library/Containers/PanelBlock/PanelBlock'

export default function BorderRadiusRow() {
  const { settings, handlers } = usePanelHelper(
    editor.getUI().getRoundingPanel()
  )
  const clip = usePanelHelper(editor.getUI().getOverflowPanel())
  const overrides = usePanelHelper(editor.getUI().getStyleOverridesPanel())
  const [custom, setCustom] = useState(settings?.mode === 'custom' || false)
  const hasClip = clip.settings && clip.settings.attributes
  if (!settings || !settings.attributes) {
    if (hasClip) return <PanelRow>{<OverflowBlock />}</PanelRow>
    return <></>
  }

  const { setRounding, slideRounding, clearCustom } = handlers
  const { hasOverride } = overrides.handlers
  const {
    'border.radius.topLeft.px': topLeft,
    'border.radius.topRight.px': topRight,
    'border.radius.bottomRight.px': bottomRight,
    'border.radius.bottomLeft.px': bottomLeft,
  } = settings.attributes
  const { mode } = settings
  const split = mode === 'custom' || custom

  return (
    <>
      <PanelRow>
        {hasClip && <OverflowBlock />}
        <OverrideReset keys={getAllKeys()}>
          <NumberBlock
            icon={'RadiusTopLeft'}
            tooltipKey={split ? 'BorderRadiusTopLeft' : 'BorderRadius'}
            value={split ? 'Mixed' : topLeft === undefined ? 0 : topLeft}
            setValue={(v) => setRounding(v, 'all')}
            increment={(v) => slideRounding(v, 'all')}
            decrement={(v) => slideRounding(-v, 'all')}
            min={0}
            step={2}
            presets={presets}
            hasOverride={hasOverride(getAllKeys())}
            disabled={split}
          />
        </OverrideReset>
        {!hasClip && <PanelBlock />}
        <IconBlock
          icon={'RadiusSplit'}
          tooltipKey={'BorderRadiusSplit'}
          tooltipDirection={'left'}
          selected={split}
          onClick={() => {
            if (split) clearCustom()
            setCustom(!custom)
          }}
        />
      </PanelRow>
      {split && (
        <>
          <PanelRow>
            <NumberBlock
              icon={'RadiusTopLeft'}
              tooltipKey={'BorderRadiusTopLeft'}
              value={topLeft === undefined ? 0 : topLeft}
              setValue={(v) => setRounding(v, 'topLeft')}
              increment={(v) => slideRounding(v, 'topLeft')}
              decrement={(v) => slideRounding(-v, 'topLeft')}
              min={0}
              step={2}
              presets={presets}
              hasOverride={hasOverride([
                'border.radius.topLeft.unit',
                'border.radius.topLeft.px',
              ])}
            />
            <OverrideReset
              keys={[
                'border.radius.topRight.unit',
                'border.radius.topRight.px',
              ]}
            >
              <NumberBlock
                icon={'RadiusTopRight'}
                tooltipKey={'BorderRadiusTopRight'}
                value={topRight === undefined ? 0 : topRight}
                setValue={(v) => setRounding(v, 'topRight')}
                increment={(v) => slideRounding(v, 'topRight')}
                decrement={(v) => slideRounding(-v, 'topRight')}
                min={0}
                step={2}
                presets={presets}
                hasOverride={hasOverride([
                  'border.radius.topRight.unit',
                  'border.radius.topRight.px',
                ])}
              />
            </OverrideReset>
          </PanelRow>
          <PanelRow>
            <OverrideReset
              keys={[
                'border.radius.bottomLeft.unit',
                'border.radius.bottomLeft.px',
              ]}
            >
              <NumberBlock
                icon={'RadiusBottomLeft'}
                tooltipKey={'BorderRadiusBottomLeft'}
                value={bottomLeft === undefined ? 0 : bottomLeft}
                setValue={(v) => setRounding(v, 'bottomLeft')}
                increment={(v) => slideRounding(v, 'bottomLeft')}
                decrement={(v) => slideRounding(-v, 'bottomLeft')}
                min={0}
                step={2}
                presets={presets}
                hasOverride={hasOverride([
                  'border.radius.bottomLeft.unit',
                  'border.radius.bottomLeft.px',
                ])}
              />
            </OverrideReset>
            <OverrideReset
              keys={[
                'border.radius.bottomRight.unit',
                'border.radius.bottomRight.px',
              ]}
            >
              <NumberBlock
                icon={'RadiusBottomRight'}
                tooltipKey={'BorderRadiusBottomRight'}
                value={bottomRight === undefined ? 0 : bottomRight}
                setValue={(v) => setRounding(v, 'bottomRight')}
                increment={(v) => slideRounding(v, 'bottomRight')}
                decrement={(v) => slideRounding(-v, 'bottomRight')}
                min={0}
                step={2}
                presets={presets}
                hasOverride={hasOverride([
                  'border.radius.bottomRight.unit',
                  'border.radius.bottomRight.px',
                ])}
              />
            </OverrideReset>
          </PanelRow>
        </>
      )}
    </>
  )
}

const presets = [0, 4, 8, 12, 16, 20, 24, 28, 32, 40, 48, 64, 128, 256]

function getAllKeys(): (keyof StyleMap)[] {
  return [
    'border.radius.topLeft.unit',
    'border.radius.topLeft.px',
    'border.radius.topRight.unit',
    'border.radius.topRight.px',
    'border.radius.bottomRight.unit',
    'border.radius.bottomRight.px',
    'border.radius.bottomLeft.unit',
    'border.radius.bottomLeft.px',
  ]
}
