import Icon from 'components/Library/Components/Icon/Icon/Icon'
import useClosePopup from 'hooks/ui/useClosePopup'
import { useRef, useState } from 'react'
import { DesignColor } from 'themes'
import {
  MenuDrawerPopupButton,
  MenuDrawerPopupContainer,
} from '../../../../Menus/MenuDrawerPopup'

interface ProjectCardActionButtonProps {
  cardHovered: boolean

  open: boolean
  setOpen: (open: boolean) => void

  openRenamePopup: () => void
  openDeletePopup: () => void
}

export default function ProjectCardActionButton({
  cardHovered,
  open,
  setOpen,
  openRenamePopup,
  openDeletePopup,
}: ProjectCardActionButtonProps) {
  const sourceRef = useRef<HTMLDivElement>(null)
  const popupRef = useRef<HTMLDivElement>(null)
  const [hover, setHover] = useState(false)

  useClosePopup({
    ref: popupRef,
    close: () => setOpen(false),
    exceptionRef: sourceRef,
  })

  return (
    <>
      <div
        ref={sourceRef}
        style={{
          height: 'fit-content',
          padding: `0px 4px`,
          borderRadius: 4,
          cursor: 'pointer',
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={(e) => {
          e.stopPropagation()
          setOpen(!open)
        }}
      >
        <Icon
          icon={'ThreeDots'}
          size={16}
          color={DesignColor(hover ? 'inputHighlight' : 'text1')}
        />
      </div>
      <MenuDrawerPopupContainer
        open={open}
        setOpen={setOpen}
        sourceRef={sourceRef}
        direction={'right'}
      >
        <MenuDrawerPopupButton
          label="Rename"
          onClick={() => {
            openRenamePopup()
            setOpen(false)
          }}
        />
        <MenuDrawerPopupButton
          label="Delete"
          onClick={() => {
            openDeletePopup()
            setOpen(false)
          }}
        />
      </MenuDrawerPopupContainer>
    </>
  )
}
