export type DraggingLine = {
  id: string
  position: 'top' | 'inside' | 'bottom'
}

export type DraggingLayersLineListener = {
  onDraggingLine: (line: DraggingLine | null) => void
}

export class DraggingLayersLine {
  private listeners: { [key: string]: DraggingLayersLineListener }
  private line: DraggingLine | null

  constructor() {
    this.listeners = {}
    this.line = null
  }

  subscribe(key: string, listener: DraggingLayersLineListener): void {
    this.listeners[key] = listener
    listener.onDraggingLine(this.line)
  }

  unsubscribe(key: string): void {
    delete this.listeners[key]
  }

  get(): DraggingLine | null {
    return this.line
  }

  set(line: DraggingLine | null): void {
    this.line = line
    this.notifyListeners()
  }

  private notifyListeners(): void {
    for (const key in this.listeners) {
      this.listeners[key].onDraggingLine(this.line)
    }
  }
}
