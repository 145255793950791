import { DesignColor } from 'themes'
import Text from 'components/Library/Text/Text'
import { useState } from 'react'

interface CTAButtonProps {
  width?: 48 | 96 | 120 | 144 | 'hug'

  text: string

  disabled?: boolean

  onClick?: () => void
}

export default function CTAButton({
  width = 120,
  text,
  disabled = false,
  onClick,
}: CTAButtonProps) {
  const [hover, setHover] = useState(false)

  return (
    <div
      style={{
        width: width === 'hug' ? 'fit-content' : width,
        height: 24,
        padding: '4px 8px',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 4,
        background:
          hover && !disabled
            ? DesignColor('inverseBackgroundHover')
            : DesignColor('inverseBackground'),
        opacity: disabled ? 0.4 : 1,
        cursor: disabled ? 'default' : 'pointer',
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={(e) => {
        e.stopPropagation()
        onClick && onClick()
      }}
    >
      <Text color={DesignColor('inverseText1')}>{text}</Text>
    </div>
  )
}
