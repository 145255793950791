import { AttributePositionUnit, StyleMap } from 'application/attributes'
import IconBlock from 'components/Library/Components/IconBlock/IconBlock/IconBlock'
import OverrideReset from 'components/Library/Components/Inputs/OverrideReset/OverrideReset'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'
import { useState } from 'react'
import PositionInput from './PositionInput'

export default function PositionRow() {
  const [displaySplit, setDisplaySplit] = useState(false)

  const { settings, handlers } = usePanelHelper(
    editor.getUI().getPositionPanel()
  )
  const overrides = usePanelHelper(editor.getUI().getStyleOverridesPanel())
  if (!settings || !handlers) return null

  const { setPosition, setPositionUnit, slidePosition, collapsePosition } =
    handlers
  const { hasOverride } = overrides.handlers

  const {
    leftDisplay,
    topDisplay,
    rightDisplay,
    bottomDisplay,
    attributes,
    displayMode,
  } = settings
  if (!attributes) return <></>

  const {
    'position.top.px': top,
    'position.left.px': left,
    'position.bottom.px': bottom,
    'position.right.px': right,
    'position.top.unit': topUnit,
    'position.left.unit': leftUnit,
    'position.bottom.unit': bottomUnit,
    'position.right.unit': rightUnit,
    'position.left.percent': leftPercent,
    'position.top.percent': topPercent,
    'position.right.percent': rightPercent,
    'position.bottom.percent': bottomPercent,
  } = attributes

  const positionSplit = rightUnit !== undefined || bottomUnit !== undefined
  const split = displayMode === 'css' && (displaySplit || positionSplit)
  const modeOptions = getDropdownOptions(displayMode)

  return (
    <>
      <PanelRow>
        <OverrideReset keys={getLeftKeys(split)}>
          <PositionInput
            icon={'PositionL'}
            tooltipKey={'PositionLeft'}
            mode={leftUnit}
            modeOptions={modeOptions}
            setAuto={(v) => setPositionUnit(v, 'left')}
            value={displayMode === 'canvas' ? leftDisplay : left}
            valuePercent={displayMode === 'canvas' ? undefined : leftPercent}
            valueDisplay={leftDisplay}
            setValue={(v) => setPosition(v, 'left')}
            increment={(v) => slidePosition(v, 'left')}
            decrement={(v) => slidePosition(-v, 'left')}
            min={-50_000}
            max={50_000}
            hasOverride={hasOverride(getLeftKeys(split))}
          />
        </OverrideReset>
        <OverrideReset keys={getTopKeys(split)}>
          <PositionInput
            icon={'PositionT'}
            tooltipKey={'PositionTop'}
            mode={topUnit}
            modeOptions={modeOptions}
            setAuto={(v) => setPositionUnit(v, 'top')}
            value={displayMode === 'canvas' ? topDisplay : top}
            valuePercent={displayMode === 'canvas' ? undefined : topPercent}
            valueDisplay={topDisplay}
            setValue={(v) => setPosition(v, 'top')}
            increment={(v) => slidePosition(v, 'top')}
            decrement={(v) => slidePosition(-v, 'top')}
            min={-50_000}
            max={50_000}
            hasOverride={hasOverride(getTopKeys(split))}
          />
        </OverrideReset>
        {displayMode === 'css' && (
          <IconBlock
            tooltipKey={'PositionSplit'}
            icon={'ConstraintCenterCenter'}
            selected={split}
            onClick={() => {
              if (split) {
                if (positionSplit) collapsePosition()
                setDisplaySplit(false)
              } else {
                setDisplaySplit(true)
              }
            }}
          />
        )}
      </PanelRow>
      {split && (
        <PanelRow>
          <OverrideReset keys={getRightKeys()}>
            <PositionInput
              icon={'PositionR'}
              tooltipKey={'PositionRight'}
              mode={rightUnit}
              modeOptions={modeOptions}
              setAuto={(v) => setPositionUnit(v, 'right')}
              value={right}
              valuePercent={rightPercent}
              valueDisplay={rightDisplay}
              setValue={(v) => setPosition(v, 'right')}
              increment={(v) => slidePosition(v, 'right')}
              decrement={(v) => slidePosition(-v, 'right')}
              min={-50_000}
              max={50_000}
              hasOverride={hasOverride(getRightKeys())}
            />
          </OverrideReset>
          <OverrideReset keys={getBottomKeys()}>
            <PositionInput
              icon={'PositionB'}
              tooltipKey={'PositionBottom'}
              mode={bottomUnit}
              modeOptions={modeOptions}
              setAuto={(v) => setPositionUnit(v, 'bottom')}
              value={bottom}
              valuePercent={bottomPercent}
              valueDisplay={bottomDisplay}
              setValue={(v) => setPosition(v, 'bottom')}
              increment={(v) => slidePosition(v, 'bottom')}
              decrement={(v) => slidePosition(-v, 'bottom')}
              min={-50_000}
              max={50_000}
              hasOverride={hasOverride(getBottomKeys())}
            />
          </OverrideReset>
        </PanelRow>
      )}
    </>
  )
}

function getDropdownOptions(
  displayMode: 'Mixed' | 'canvas' | 'css'
): AttributePositionUnit[] {
  return displayMode === 'canvas' ? ['px'] : ['px', 'percent']
}

const getLeftKeys = (split: boolean): (keyof StyleMap)[] => {
  if (split) {
    return ['position.left.unit', 'position.left.px', 'position.left.percent']
  }
  return [
    'position.left.unit',
    'position.left.px',
    'position.left.percent',
    'position.right.unit',
    'position.right.px',
    'position.right.percent',
  ]
}

const getTopKeys = (split: boolean): (keyof StyleMap)[] => {
  if (split) {
    return ['position.top.unit', 'position.top.px', 'position.top.percent']
  }
  return [
    'position.top.unit',
    'position.top.px',
    'position.top.percent',
    'position.bottom.unit',
    'position.bottom.px',
    'position.bottom.percent',
  ]
}

const getRightKeys = (): (keyof StyleMap)[] => {
  return ['position.right.unit', 'position.right.px', 'position.right.percent']
}

const getBottomKeys = (): (keyof StyleMap)[] => {
  return [
    'position.bottom.unit',
    'position.bottom.px',
    'position.bottom.percent',
  ]
}
