import { StyleSelectorImpl } from './styleSelector'
import {
  Combinator,
  MediaQuery,
  SelectorMode,
  SelectorPseudo,
  StyleSelector,
} from './types'

export class StyleSelectorBuilder {
  private modes: SelectorMode[] = []
  private values: string[] = []
  private combinators: Combinator[] = []
  private pseudos: SelectorPseudo[] = []
  private media: MediaQuery | null = null

  withMode = (mode: SelectorMode): StyleSelectorBuilder => {
    this.modes.push(mode)
    return this
  }

  withValue = (value: string): StyleSelectorBuilder => {
    this.values.push(value)
    return this
  }

  withCombinator = (combinator: Combinator): StyleSelectorBuilder => {
    this.combinators.push(combinator)
    return this
  }

  withPseudo = (pseudo: SelectorPseudo): StyleSelectorBuilder => {
    this.pseudos.push(pseudo)
    return this
  }

  withMedia = (media: MediaQuery): StyleSelectorBuilder => {
    this.media = media
    return this
  }

  build = (): StyleSelector => {
    if (this.modes.length === 0) throw new Error('Modes are required')
    if (this.values.length === 0) throw new Error('Values are required')
    if (this.modes.length !== this.values.length)
      throw new Error('Modes and values must have the same length')
    if (this.combinators.length > this.modes.length - 1)
      throw new Error(
        'Combinators must be less than the number of modes and values'
      )
    return new StyleSelectorImpl(
      this.modes,
      this.values,
      this.combinators,
      this.pseudos,
      this.media
    )
  }
}
