import { VaoData } from './types'

export function createEmptyVaoData(): VaoData {
  return {
    material: null,
    vao: null,
    buffers: {},
    uniforms: {},
    verticeCount: 0,
  }
}

export function connectBuffersToVao(vaoData: VaoData): void {
  if (!vaoData.material) return
  const gl = vaoData.material.getGl()
  const program = vaoData.material.getProgram()
  gl.bindVertexArray(vaoData.vao)

  Object.keys(vaoData.buffers).forEach((name) => {
    const buffer = vaoData.buffers[name]
    const location = gl.getAttribLocation(program, name)
    if (location === -1) throw new Error(`Could not find attribute ${name}`)
    gl.bindBuffer(gl.ARRAY_BUFFER, buffer.getBuffer())
    gl.enableVertexAttribArray(location)
    gl.vertexAttribPointer(location, buffer.getStep(), gl.FLOAT, false, 0, 0)
  })

  gl.bindBuffer(gl.ARRAY_BUFFER, null)
}

export function cleanupVaoData(vaoData: VaoData) {
  if (!vaoData.material) return
  const gl = vaoData.material.getGl()
  gl.deleteVertexArray(vaoData.vao)
  Object.values(vaoData.buffers).forEach((buffer) => buffer.cleanup())
}

export function clearBuffers(vaoData: VaoData) {
  Object.values(vaoData.buffers).forEach((buffer) => buffer.cleanup())
  vaoData.buffers = {}
}

export function standardDraw(vaoData: VaoData) {
  if (!vaoData.material) return
  const gl = vaoData.material.getGl()
  gl.useProgram(vaoData.material.getProgram())
  gl.bindVertexArray(vaoData.vao)

  for (const uniform in vaoData.uniforms) {
    vaoData.material.setUniform(uniform, vaoData.uniforms[uniform])
  }

  gl.drawArrays(gl.TRIANGLES, 0, vaoData.verticeCount)
}
