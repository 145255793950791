import { SizeContext } from '../context/sizeContext'
import { FlexSizeItem } from './flexSizeItem'

export abstract class FlexSizeLine {
  mainSize: number

  totalGap: number
  totalGrow: number
  totalShrink: number

  items: FlexSizeItem[]

  constructor() {
    this.mainSize = 0

    this.totalGap = 0
    this.totalGrow = 0
    this.totalShrink = 0

    this.items = []
  }

  // Initialize the line
  // - mainSize
  // - totalGrow
  // - totalShrink
  abstract initialize(): void

  // Run flex sizing logic for the main axis with the given size
  abstract applyMainAxisSize(size: number, context: SizeContext): void

  // Run flex sizing logic for the cross axis with the given size
  abstract applyCrossAxisSize(size: number, context: SizeContext): void

  // Get the maximum cross size
  abstract getMaxCrossSize(): number

  // Get the minimum content main size for the line
  abstract getMinContentMainSize(): number

  // Get the maximum content main size for the line
  abstract getMaxContentMainSize(): number
}
