import { ArrowHandler } from './handlers/arrow'
import { TextEditorLayout } from './layout/layout'
import { TextEditorState } from './state/state'
import { SelectHandler } from './handlers/select'
import { InsertHandler } from './handlers/insert'
import { DeleteHandler } from './handlers/delete'
import { ReturnHandler } from './handlers/return'
import { StyleHandler } from './handlers/styles'
import { TextEditorStyles } from './styles/styles'
import { InlineStyleHandler } from './handlers/inline'
import { WidthHandler } from './handlers/width'
import { CopyHandler } from './handlers/copy'
import { TextEditor } from './textEditor'
import { newEmptyState } from './state/data'
import {
  FontLoaderInterface,
  ReadOnlyFontDataMap,
  TextShaper,
} from 'application/text'

export class TextEditorFactory {
  private fontLoader: FontLoaderInterface
  private fontMap: ReadOnlyFontDataMap

  constructor(fontLoader: FontLoaderInterface, fontMap: ReadOnlyFontDataMap) {
    this.fontLoader = fontLoader
    this.fontMap = fontMap
  }

  create(): TextEditor {
    const state = new TextEditorState(newEmptyState())
    const shaper = new TextShaper(this.fontLoader)
    const layout = new TextEditorLayout()
    const styles = new TextEditorStyles(this.fontMap)
    const textEditor = new TextEditor(state)

    textEditor.registerHandler(new ArrowHandler(state, layout, shaper))
    textEditor.registerHandler(new SelectHandler(state, layout, shaper))
    textEditor.registerHandler(new InsertHandler(state, layout, shaper, styles))
    textEditor.registerHandler(new DeleteHandler(state, layout, shaper))
    textEditor.registerHandler(new CopyHandler(state, navigator.clipboard))
    textEditor.registerHandler(new ReturnHandler(state, layout, shaper))
    textEditor.registerHandler(new StyleHandler(state, styles))
    textEditor.registerHandler(new InlineStyleHandler(state, styles))
    textEditor.registerHandler(new WidthHandler(state))

    return textEditor
  }
}
