import { useEffect } from 'react'
import { useRecoilValue } from 'recoil'
import { userInfoState } from 'components/Pages/Projects/Projects'
import Intercom from '@intercom/messenger-js-sdk'

export default function IntercomMenu() {
  const userInfo = useRecoilValue(userInfoState)

  useEffect(() => {
    const timestamp = Math.floor(Date.now() / 1000)

    Intercom({
      app_id: 'arva6btt',
      user_id: userInfo.id,
      name: userInfo.firstName + ' ' + userInfo.lastName,
      email: userInfo.email,
      created_at: timestamp,
    })
  }, [userInfo])

  return <></>
}
