import { Project } from 'application/service'
import ProjectCard from './ProjectCard/ProjectCard'
import useNavigatePage from 'hooks/admin/useNavigatePage'
import { ProjectGridSortMode } from '../ProjectGridControls/ProjectSortButton'
import useToast from 'hooks/editor/useToast'

interface ProjectGridProps {
  sortMode: ProjectGridSortMode
  sortedProjects: Project[]

  renameProject: (id: string, name: string) => void
  deleteProject: (id: string) => void
}

export default function ProjectGrid({
  sortMode,
  sortedProjects,
  renameProject,
  deleteProject,
}: ProjectGridProps) {
  const { toEditor } = useNavigatePage()
  const toast = useToast()

  const handleOpenProject = async (project: Project) => {
    try {
      await toEditor(project.id)
    } catch (e) {
      console.error('Error opening project.', e)
      toast('Unable to open project, you are currently offline.', 'error')
    }
  }

  return (
    <div
      style={{
        width: '100%',
        padding: '0px 24px 24px 24px',
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(300px, 1fr))',
        gap: '24px',
        overflow: 'auto',
      }}
    >
      {sortedProjects.map((project) => (
        <ProjectCard
          key={project.id}
          sortMode={sortMode}
          project={project}
          openProject={() => handleOpenProject(project)}
          renameProject={(name) => renameProject(project.id, name)}
          deleteProject={() => deleteProject(project.id)}
        />
      ))}
    </div>
  )
}
