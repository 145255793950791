import {
  BaseMap,
  SelectorBreakpoint,
  SelectorName,
  SelectorPseudo,
  StyleMap,
} from 'application/attributes'
import { ClientTransactionHandler } from './transaction'

export class NodeTransactionHistoryHandler {
  private handler: ClientTransactionHandler

  constructor(handler: ClientTransactionHandler) {
    this.handler = handler
  }

  onBaseAttribute = <K extends keyof BaseMap>(
    id: string,
    key: K,
    prev: BaseMap[K],
    curr: BaseMap[K]
  ): void => {
    this.handler.addEvent({
      type: 'node_update_base_attributes',
      data: {
        id: id,
        initial: { [key]: prev },
        current: { [key]: curr },
      },
    })
  }

  onStyleAttribute = <K extends keyof StyleMap>(
    id: string,
    key: K,
    prev: { selector: SelectorName; value: Partial<StyleMap>[K] },
    curr: { selector: SelectorName; value: Partial<StyleMap>[K] }
  ): void => {
    this.handler.addEvent({
      type: 'node_update_style_attributes',
      data: {
        id: id,
        initial: {
          selector: prev.selector,
          style: { [key]: prev.value },
        },
        current: {
          selector: curr.selector,
          style: { [key]: curr.value },
        },
      },
    })
  }

  onActiveBreakpoint = (
    id: string,
    prev: SelectorBreakpoint,
    curr: SelectorBreakpoint
  ): void => {
    this.handler.addEvent({
      type: 'node_update_breakpoint',
      data: {
        id: id,
        initial: prev,
        current: curr,
      },
    })
  }

  onActivePseudo = (
    id: string,
    prev: SelectorPseudo,
    curr: SelectorPseudo
  ): void => {
    this.handler.addEvent({
      type: 'node_update_pseudo',
      data: {
        id: id,
        initial: prev,
        current: curr,
      },
    })
  }

  onChildren = (
    id: string,
    prev: string[] | undefined,
    curr: string[] | undefined
  ): void => {
    this.handler.addEvent({
      type: 'node_update_children',
      data: {
        id: id,
        initial: prev,
        current: curr,
      },
    })
  }

  onParent = (
    id: string,
    prev: string | undefined,
    curr: string | undefined
  ): void => {
    this.handler.addEvent({
      type: 'node_update_parent',
      data: {
        id: id,
        initial: prev,
        current: curr,
      },
    })
  }
}
