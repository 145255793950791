import { ActionHandler } from '../types'
import { CommandHandler } from 'application/client'
import { ReadOnlyDocument } from 'application/document'
import { ReadOnlyDocumentSelection } from 'application/selection'
import { DragLayersAction } from './action'
import { DraggingLayersLine } from './line'
import { NodeReparentAction, NodeSelectionAction } from 'application/action'

export class DragLayersActionFactory {
  private commandHandler: CommandHandler
  private document: ReadOnlyDocument
  private documentSelection: ReadOnlyDocumentSelection
  private reparent: NodeReparentAction
  private selection: NodeSelectionAction
  private line: DraggingLayersLine

  constructor(
    commandHandler: CommandHandler,
    document: ReadOnlyDocument,
    documentSelection: ReadOnlyDocumentSelection,
    reparent: NodeReparentAction,
    selection: NodeSelectionAction,
    line: DraggingLayersLine
  ) {
    this.commandHandler = commandHandler
    this.document = document
    this.documentSelection = documentSelection
    this.reparent = reparent
    this.selection = selection
    this.line = line
  }

  create = (): ActionHandler => {
    return new DragLayersAction(
      this.commandHandler,
      this.document,
      this.documentSelection,
      this.reparent,
      this.selection,
      this.line
    )
  }
}
