import { Properties } from 'application/export/css'
import { TailwindClassRenderer } from '../types'

export class TailwindClassDisplay implements TailwindClassRenderer {
  render = (style: Partial<Properties>): string[] => {
    const display = style.display
    switch (display) {
      case 'block':
        return ['block']
      case 'inline':
        return ['inline']
      case 'flex':
        return ['flex']
      case 'grid':
        return ['grid']
      case 'none':
        return ['hidden']
      case 'contents':
        return ['contents']
      case 'inherit':
        return ['inherit']
      default:
        return []
    }
  }
}
