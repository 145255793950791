import { useRef, useState } from 'react'
import { panelBorderFull } from 'components/Library/Containers/constants'
import { DesignColor } from 'themes'
import DropdownRow from './DropdownRow'
import { Portal } from 'components/Pages/Editor/PortalRoot/PortalRoot'
import useClosePopup from 'hooks/ui/useClosePopup'
import { dropdownOption } from '../../Dropdown/Dropdown'

interface DropdownProps<T> {
  close: () => void

  select: (value: T) => void

  selected?: T
  options: dropdownOption<T>[]

  blockRef: React.RefObject<HTMLDivElement>
}

export default function Dropdown<T>({
  close,
  select,
  selected,
  options,
  blockRef,
}: DropdownProps<T>) {
  const popupRef = useRef<HTMLDivElement>(null)
  useClosePopup({
    ref: popupRef,
    close: close,
  })

  const [hoveredValue, setHoveredValue] = useState<number>(-1)

  const position = getPosition(blockRef)

  const handleSelect = (value: T) => {
    select(value)
  }

  const handleMouseEnter = (value: number) => {
    setHoveredValue(value)
  }

  const handleMouseLeave = () => {
    setHoveredValue(-1)
  }

  return (
    <Portal>
      <div
        ref={popupRef}
        style={{
          position: 'absolute',
          ...position,
          width: 96,
          display: 'flex',
          flexDirection: 'column',
          boxShadow: panelBorderFull,
          borderRadius: 4,
          background: DesignColor('dropdownBackground'),
          pointerEvents: 'auto',
        }}
      >
        {options.map((o, i) => {
          return (
            <DropdownRow
              key={i}
              icon={o.icon}
              title={o.text}
              hover={i === hoveredValue}
              selected={o.value === selected}
              onMouseEnter={() => handleMouseEnter(i)}
              onMouseLeave={handleMouseLeave}
              onClick={() => handleSelect(o.value)}
            />
          )
        })}
      </div>
    </Portal>
  )
}

function getPosition(blockRef: React.RefObject<HTMLDivElement>) {
  if (blockRef.current) {
    const rect = blockRef.current.getBoundingClientRect()
    return {
      top: rect.top,
      left: rect.left,
    }
  }
  return { top: 0, left: 0 }
}
