import { Node } from 'application/node'
import { DocumentUpdateListener } from './types'

export class DocumentListeners {
  private listeners: DocumentUpdateListener[]

  constructor() {
    this.listeners = []
  }

  registerListener = (listener: DocumentUpdateListener): void => {
    this.listeners.push(listener)
  }

  onNodeAdded(node: Node) {
    for (const listener of this.listeners) {
      if (listener.onNodeAdded) {
        listener.onNodeAdded(node)
      }
    }
  }

  onNodeDeleted(node: Node) {
    for (const listener of this.listeners) {
      if (listener.onNodeDeleted) {
        listener.onNodeDeleted(node)
      }
    }
  }
}
