import { Styles } from '../styles/styles'
import { CommandHandler, EditorState } from '../textEditor'
import { TextCommand, TextCommandInlineStyle } from '../command/types'
import { State } from '../state/types'
import { Style } from '../styles/types'
import { FontWeight } from 'application/text/types'

export class InlineStyleHandler implements CommandHandler {
  private state: EditorState
  private styles: Styles

  constructor(state: EditorState, styles: Styles) {
    this.state = state
    this.styles = styles
  }

  handle = (command: TextCommand): void => {
    switch (command.type) {
      case 'inlineStyle':
        this.handleStyle(command)
        break
    }
  }

  private handleStyle = (command: TextCommandInlineStyle): void => {
    const state = this.state.get()

    this.toggleInlineStyle(state, command)

    this.state.set(state)
  }

  private toggleInlineStyle = (
    state: State,
    command: TextCommandInlineStyle
  ): void => {
    switch (command.parameters.mode) {
      case 'bold':
        if (command.parameters.all) {
          this.toggleAll<FontWeight>(state, 'fontWeight', 'bold', 'regular')
        } else {
          this.togglePartial<FontWeight>(state, 'fontWeight', 'bold', 'regular')
        }
        break
      case 'italic':
        if (command.parameters.all) {
          this.toggleAll<boolean>(state, 'italic', true, false)
        } else {
          this.togglePartial<boolean>(state, 'italic', true, false)
        }
        break
      case 'underline':
        if (command.parameters.all) {
          this.toggleAll<boolean>(state, 'underline', true, false)
        } else {
          this.togglePartial<boolean>(state, 'underline', true, false)
        }
        break
    }
  }

  private togglePartial<T>(
    state: State,
    key: keyof Style,
    onValue: T,
    offValue: T
  ): void {
    const allOn = this.selectionAllEqual(state, key, onValue)
    if (allOn) {
      if (state.selection.anchor === null) {
        state.nextCharacter = {
          ...state.nextCharacter,
          [key]: offValue,
        }
      } else {
        this.styles.apply(state.content, state.selection, { [key]: offValue })
      }
    } else {
      if (state.selection.anchor === null) {
        state.nextCharacter = {
          ...state.nextCharacter,
          [key]: onValue,
        }
      } else {
        this.styles.apply(state.content, state.selection, { [key]: onValue })
      }
    }
  }

  private toggleAll<T>(
    state: State,
    key: keyof Style,
    onValue: T,
    offValue: T
  ): void {
    const allOn = this.allEqual(state, key, onValue)
    if (allOn) {
      this.styles.applyAll(state.content, { [key]: offValue })
    } else {
      this.styles.applyAll(state.content, { [key]: onValue })
    }
  }

  private allEqual<T>(state: State, key: keyof Style, value: T): boolean {
    const { content } = state
    const start = 0
    const end = content.content.length - 1
    for (let i = start; i <= end; i++) {
      const s = this.styles.get(state.content, { index: i, wrapped: false })
      if (s[key] !== value) return false
    }

    return true
  }

  private selectionAllEqual<T>(
    state: State,
    key: keyof Style,
    value: T
  ): boolean {
    const { selection } = state
    const { anchor, focus } = selection
    if (anchor === null) return false

    const start = Math.min(anchor.index, focus.index) + 1
    const end = Math.max(anchor.index, focus.index)

    for (let i = start; i <= end; i++) {
      const s = this.styles.get(state.content, { index: i, wrapped: false })
      if (s[key] !== value) return false
    }

    return true
  }
}
