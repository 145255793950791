import { Properties } from '../css'
import { Attributes, Element, Tag } from './types'
import { attributesToString, inlineStyleToString } from './utils'

export class LeafElement implements Element {
  private tag: Tag
  private attributes: Partial<Attributes>
  private inlineStyle: Partial<Properties>

  constructor(tag: Tag, attributes: Partial<Attributes>) {
    this.tag = tag
    this.attributes = attributes
    this.inlineStyle = {}
  }

  getTag = (): Tag => {
    return this.tag
  }

  addChild = (): boolean => {
    return false
  }

  removeChild = (): number => {
    return -1
  }

  getChildren = (): null => {
    return null
  }

  getAttribute = <K extends keyof Attributes>(key: K): Attributes[K] | null => {
    return this.attributes[key] ?? null
  }

  setAttribute = <K extends keyof Attributes>(
    key: K,
    value: Attributes[K]
  ): void => {
    this.attributes[key] = value
  }

  getInlineStyle = <K extends keyof Properties>(k: K): Properties[K] | null => {
    return this.inlineStyle[k] ?? null
  }

  setInlineStyle = <K extends keyof Properties>(
    k: K,
    v: Properties[K]
  ): void => {
    this.inlineStyle[k] = v
  }

  toString = (): string => {
    if (Object.keys(this.inlineStyle).length === 0) {
      return `<${this.tag}${attributesToString(this.attributes)}/>`
    } else {
      return `<${this.tag}${attributesToString(this.attributes)}${inlineStyleToString(this.inlineStyle)}/>`
    }
  }
}
