import { Shaper, TextContent } from 'application/text'

export type InputContentSize = {
  w: number
  h: number
  minW: number
  minH: number
  wraps: boolean
}

export class InputSizeCalculator {
  private textShaper: Shaper

  static create = (textShaper: Shaper): InputSizeCalculator => {
    return new InputSizeCalculator(textShaper)
  }

  private constructor(textShaper: Shaper) {
    this.textShaper = textShaper
  }

  getContentSize = (state: TextContent): InputContentSize => {
    const shapedText = this.textShaper.getShapedText({
      content: Array(20).join('a'),
      contentStyles: Array(20).fill(0),
      styles: state.styles,
      styleOverrides: state.styleOverrides,
      lineTypes: state.lineTypes,
    })

    let maxWord = 0
    let start = true
    let xStart = 0
    let xCurrent = 0
    for (const row of shapedText.rows) {
      for (const c of row.characters) {
        if (start) {
          xStart = c.x
          start = false
        }
        if (c.space || c.newline) {
          maxWord = Math.max(maxWord, xCurrent - xStart)
          xStart = 0
          xCurrent = 0
          start = true
        } else {
          xCurrent = c.x + c.w
        }
      }
    }

    const result = {
      w: shapedText.w,
      h: shapedText.h,
      minW: Math.max(maxWord, xCurrent - xStart),
      minH: shapedText.h,
      wraps: shapedText.wraps,
    }

    this.textShaper.releaseShapedText(shapedText)

    return result
  }
}
