import {
  StyleMap,
  getTextAttributes,
  isContentContainerType,
} from 'application/attributes'
import { Command, SetNodeAttribute } from 'application/client'
import { ReadOnlyNode } from 'application/node'
import { ReadOnlyDocumentSelection } from 'application/selection'
import { FontWeight, ReadOnlyFontDataMap } from 'application/text'

interface CommandHandler {
  handle: (command: Command[]) => void
}

export class TextAction {
  private commandHandler: CommandHandler
  private documentSelection: ReadOnlyDocumentSelection
  private fontMap: ReadOnlyFontDataMap

  constructor(
    commandHandler: CommandHandler,
    documentSelection: ReadOnlyDocumentSelection,
    fontMap: ReadOnlyFontDataMap
  ) {
    this.commandHandler = commandHandler
    this.documentSelection = documentSelection
    this.fontMap = fontMap
  }

  toggleBold = (): void => {
    const selected = this.documentSelection.getSelected()
    const textNodes = selected.filter((n) =>
      isContentContainerType(n.getBaseAttribute('type'))
    )
    if (textNodes.length === 0) return

    const allBold = this.allBold(textNodes)
    const updates: SetNodeAttribute[] = []

    for (const node of textNodes) {
      const style = this.setFontWeight(node, allBold ? 'regular' : 'bold')
      updates.push({
        type: 'setNodeAttribute',
        params: {
          id: node.getId(),
          base: {},
          style: style,
        },
      })
    }

    this.commandHandler.handle([...updates])
    this.commandHandler.handle([{ type: 'commit' }])
  }

  private setFontWeight = (
    node: ReadOnlyNode,
    fontWeight: FontWeight
  ): Partial<StyleMap> => {
    const fontKey = node.getStyleAttribute('text.font.family')
    if (!fontKey) return {}

    const font = this.fontMap.getFontData(fontKey)
    if (!font || !font.weights.includes(fontWeight)) return {}

    return { 'text.font.weight': fontWeight }
  }

  private allBold = (nodes: ReadOnlyNode[]): boolean => {
    return nodes.every((n) => {
      const textAttributes = getTextAttributes(n)
      if (!textAttributes) return false

      return textAttributes['text.font.weight'] === 'bold'
    })
  }
}
