import { IconKey } from 'assets/iconLibrary'
import ButtonIcon from '../../Icon/ButtonIcon/ButtonIcon'
import { DesignColor } from 'themes'
import { useEffect, useRef, useState } from 'react'
import useEditInput from 'hooks/editor/useEditInput'
import { tooltipKey } from 'assets/tooltips'
import Tooltip from '../../Tooltip/Tooltip'

interface TextInputBlockProps {
  width?: 96 | 120 | 192 | 216 | 288 | 'fill'

  icon?: IconKey
  tooltipKey?: tooltipKey

  value: string
  setValue: (value: string) => void
  submit?: () => void
  submitOnBlur?: boolean

  placeholder?: string
  commit?: boolean
  debounce?: number
  focusOnMount?: boolean
}

export default function TextInputBlock({
  width = 192,
  icon,
  tooltipKey,
  value,
  setValue,
  submit,
  submitOnBlur = false,
  placeholder,
  commit = false,
  debounce = 0,
  focusOnMount = false,
}: TextInputBlockProps) {
  const inputRef = useRef<HTMLInputElement>(null)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null)

  const [localValue, setLocalValue] = useState(value)
  const [hover, setHover] = useState(false)
  const [editing, setEditing] = useEditInput(commit)
  const [select, setSelect] = useState(false)

  const handleMouseUp = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    if (!select && inputRef.current) {
      e.preventDefault()
      inputRef.current.select()
      setSelect(true)
      if (!editing) setEditing(true)
    }
  }

  const handleBlur = () => {
    if (submit && submitOnBlur) submit()
    setEditing(false)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation()
    if (e.key === 'Enter') {
      if (submit && !submitOnBlur) submit()
      inputRef.current?.blur()
    }
  }

  const updateDebounced = (v: string) => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current)
    }

    timeoutRef.current = setTimeout(() => {
      setValue(v)
    }, debounce)
  }

  const handleUpdate = (v: string) => {
    setLocalValue(v)
    updateDebounced(v)
  }

  useEffect(() => {
    if (editing) return
    setLocalValue(value)
    setSelect(false)
  }, [editing, value])

  useEffect(() => {
    if (focusOnMount && inputRef.current) {
      inputRef.current.focus()
    }
  }, [focusOnMount])

  useEffect(() => {
    return () => {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current)
      }
    }
  }, [])

  const Element = (
    <div
      style={{
        width: width === 'fill' ? '100%' : width,
        height: 24,
        padding: icon ? '0px 4px' : '0px 8px',
        boxSizing: 'border-box',
        flexShrink: width === 'fill' ? 1 : 0,
        flexGrow: width === 'fill' ? 1 : 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        borderRadius: 4,
        gap: 4,
        boxShadow: getBoxShadow(hover, editing),
      }}
    >
      {icon && <ButtonIcon icon={icon} color={DesignColor('text2')} />}
      <input
        ref={inputRef}
        type="text"
        value={localValue}
        onChange={(e) => handleUpdate(e.target.value)}
        placeholder={placeholder}
        onClick={(e) => e.stopPropagation()}
        onMouseUp={handleMouseUp}
        onKeyDown={handleKeyDown}
        onFocus={() => setEditing(true)}
        onBlur={handleBlur}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={{
          width:
            width === 'fill'
              ? `calc(100% - ${icon ? 40 : 16}px)`
              : width - (icon ? 40 : 16),
          height: 24,
          padding: 0,
          margin: 0,
          boxSizing: 'border-box',
          border: 'none',
          outline: 'none',
          background: 'transparent',
          fontFamily: 'Inter',
          fontWeight: 'regular',
          fontSize: 11,
          color: !localValue ? DesignColor('text2') : DesignColor('text1'),
        }}
      />
    </div>
  )

  if (tooltipKey) {
    return <Tooltip tooltipKey={tooltipKey}>{Element}</Tooltip>
  } else {
    return Element
  }
}

function getBoxShadow(hover: boolean, editing: boolean) {
  if (hover || editing)
    return `0px 0px 0px 1px ${DesignColor('inputHighlight')}`
  return 'none'
}
