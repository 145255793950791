import { DesignColor } from 'themes'
import { Point, Rectangle } from 'application/shapes'
import { Context } from 'application/render'
import { WebglLine } from 'application/render/renderables/webglLine'
import { rgbaStringToRgba } from 'application/color'
import { Camera } from 'application/camera'

const spacing = 1
const zoomThreshhold = 4

export class GridlinesSmallTransformer {
  static transform(
    context: Context,
    camera: Camera,
    canvasSize: Rectangle
  ): WebglLine[] {
    if (camera.z < zoomThreshhold) return []

    const lines: { start: Point; end: Point }[] = []
    const pixelWidth = canvasSize.w / camera.z
    const pixelHeight = canvasSize.h / camera.z

    // Vertical lines
    for (
      let i = Math.floor(camera.x / spacing) * spacing;
      i <= camera.x + pixelWidth;
      i += spacing
    ) {
      lines.push({
        start: { x: i, y: -camera.y },
        end: { x: i, y: -camera.y + pixelHeight },
      })
    }

    // Horizontal lines
    for (
      let i = Math.floor(camera.y / spacing) * spacing;
      i >= camera.y - pixelHeight;
      i -= spacing
    ) {
      lines.push({
        start: { x: camera.x, y: -i },
        end: { x: camera.x + pixelWidth, y: -i },
      })
    }

    const thickness = 1 / camera.z
    const color = rgbaStringToRgba(DesignColor('smallGridlines'))

    const renderable = new WebglLine(context, {
      lines: lines,
      thickness,
      color,
    })
    renderable.init()

    return [renderable]
  }
}
