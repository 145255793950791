import {
  Context,
  UniformValue,
  VaoData,
  VertexBuffer,
  calcBoundingBox,
  calcImagePosition,
  calcImageTexCoords,
  calcRounding,
  isPointInBox,
} from 'application/render'
import { Rectangle } from 'application/shapes'
import { MaterialType } from './shaderMap'
import {
  cleanupVaoData,
  clearBuffers,
  connectBuffersToVao,
  createEmptyVaoData,
  standardDraw,
} from '../utils'

export type WebglRectangleImageData = Rectangle & {
  src: string
  resize: 'stretch' | 'fit' | 'fill' | 'parallax' | 'repeat'
  originalSize?: { w: number; h: number }
  rounding?: { tl: number; tr: number; br: number; bl: number }
  bb?: Rectangle
  bbRounding?: { tl: number; tr: number; br: number; bl: number }
}

export class WebglRectangleImage {
  private context: Context
  private data: WebglRectangleImageData
  private vaoData: VaoData

  constructor(context: Context, data: WebglRectangleImageData) {
    this.context = context
    this.data = data
    this.vaoData = createEmptyVaoData()
  }

  init = (): void => {
    this.vaoData = this.updateRenderData()
  }

  draw(): void {
    if (!this.vaoData.material) return

    const texture = this.context.getTexture(this.data.src)
    if (!texture) {
      this.context.createImageTexture(this.data.src)
      return
    }

    this.vaoData.material.setSampler('uSampler', texture.getTexture(), 0)
    standardDraw(this.vaoData)
  }

  updateRenderData(): VaoData {
    clearBuffers(this.vaoData)
    const gl = this.context.getGl()
    const material = this.context.getMaterial(MaterialType.rectangleImage)
    const vao = gl.createVertexArray()
    const buffers: { [key: string]: VertexBuffer } = {}
    const uniforms: { [key: string]: UniformValue } = {}

    const { x, y, w, h, resize, originalSize, rounding } = this.data
    const rect = { x, y, w, h }

    const aPosition = new Float32Array(
      calcImagePosition(rect, resize, originalSize)
    )
    const aTexCoords = new Float32Array(
      calcImageTexCoords(rect, resize, originalSize)
    )
    const uRect = [x, y, w, h]
    const uRounding = calcRounding(w, h, rounding)
    const uBB = calcBoundingBox(this.data.bb)
    const uBBRounding = calcRounding(uBB[2], uBB[3], this.data.bbRounding)

    buffers['aPosition'] = this.context.createVertexBuffer(aPosition, 2)
    buffers['aTexCoords'] = this.context.createVertexBuffer(aTexCoords, 2)
    uniforms['uRect'] = uRect
    uniforms['uRounding'] = uRounding
    uniforms['uBB'] = uBB
    uniforms['uBBRounding'] = uBBRounding
    const verticeCount = aPosition.length / 2

    const vaoData = { material, vao, buffers, uniforms, verticeCount }
    connectBuffersToVao(vaoData)
    return vaoData
  }

  cleanup(): void {
    cleanupVaoData(this.vaoData)
  }

  async preload(): Promise<void> {
    await this.context.createImageTexture(this.data.src)
  }
}

export const rectangleImageVs = `#version 300 es
precision highp float;

uniform mat3 uMatrix;

in vec2 aPosition;
in vec2 aTexCoords;

out vec2 vPosition;
out vec2 vTexCoords;

void main() {
  vec3 transformedPosition = uMatrix * vec3(aPosition, 1);
  gl_Position = vec4(transformedPosition.xy, 0, 1);

  vPosition = aPosition;
  vTexCoords = aTexCoords;
}
`

export const rectangleImageFs = `#version 300 es
precision mediump float;

uniform sampler2D uSampler;
uniform vec4 uRounding;
uniform vec4 uRect;
uniform vec4 uBB;
uniform vec4 uBBRounding;

in vec2 vPosition;
in vec2 vTexCoords;

out vec4 outColor;

${isPointInBox}

void main() {
  if (!isPointInBox(vPosition, uBB, uBBRounding) || !isPointInBox(vPosition, vec4(uRect.xy, uRect.zw), uRounding)) {
    discard;
  }

  outColor = texture(uSampler, vTexCoords);
}
`
