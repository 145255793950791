import Icon from 'components/Library/Components/Icon/Icon/Icon'
import MenuButton from 'components/Library/Menus/MenuButton'
import MenuFullscreenPopupContainer from 'components/Library/Menus/MenuFullscreenPopup'
import MenuTextInput from 'components/Library/Menus/MenuTextInput'
import { useState } from 'react'
import { DesignColor } from 'themes'

interface ProjectCardRenamePopupProps {
  open: boolean

  close: () => void
  rename: (name: string) => void
}

export default function ProjectCardRenamePopup({
  open,
  close,
  rename,
}: ProjectCardRenamePopupProps) {
  const [renameValue, setRenameValue] = useState('')
  const [hoverIcon, setHoverIcon] = useState(false)

  const iconColor = hoverIcon ? DesignColor('grayInputTint') : 'transparent'

  const submitRename = () => {
    if (renameValue) rename(renameValue)
    setRenameValue('')
    close()
  }

  return (
    <MenuFullscreenPopupContainer open={open} setOpen={close}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <h2
          style={{
            fontSize: 20,
            color: DesignColor('text1'),
          }}
        >
          Rename
        </h2>
        <div
          style={{
            background: iconColor,
            cursor: 'pointer',
          }}
          onMouseEnter={() => setHoverIcon(true)}
          onMouseLeave={() => setHoverIcon(false)}
          onClick={close}
        >
          <Icon icon="X" size={24} color={DesignColor('text1')} />
        </div>
      </div>
      <MenuTextInput
        value={renameValue}
        placeholder="New project name"
        onChange={setRenameValue}
        onSubmit={submitRename}
        autofocus
      />
      <MenuButton label="Submit" onClick={submitRename} />
    </MenuFullscreenPopupContainer>
  )
}
