import { Point, Rectangle } from 'application/shapes'
import { AlignmentGap, AlignmentLine } from '../types'
import { SelectionSide } from 'application/selection/types'

export type AlignedPointResult = {
  point: Point
  lines: AlignmentLine[]
  gaps: AlignmentGap[]
}

type LineResult = {
  alignment: AlignmentLine | null
  distance: number
  delta: number
}

type GapResult = {
  alignment: AlignmentGap | null
  distance: number
  delta: number
}

type Side = 'left' | 'right' | 'top' | 'bottom' | 'centerH' | 'centerV'

export class PointAlignmentCalculator {
  alignMove = (
    point: Point,
    window: Rectangle,
    lines: AlignmentLine[],
    gaps: AlignmentGap[],
    magnet: number
  ): AlignedPointResult => {
    return this.getAlignedPosition(point, lines, gaps, magnet, window, [
      'left',
      'right',
      'top',
      'bottom',
      'centerH',
      'centerV',
    ])
  }

  alignResize = (
    point: Point,
    window: Rectangle,
    lines: AlignmentLine[],
    side: SelectionSide,
    magnet: number
  ): AlignedPointResult => {
    const sides: Side[] = []
    if (side.includes('left')) sides.push('left')
    if (side.includes('right')) sides.push('right')
    if (side.includes('top')) sides.push('top')
    if (side.includes('bottom')) sides.push('bottom')

    return this.getAlignedPosition(point, lines, [], magnet, window, sides)
  }

  private getAlignedPosition = (
    point: Point,
    lines: AlignmentLine[],
    gaps: AlignmentGap[],
    magnet: number,
    window: Rectangle,
    sides: Side[]
  ): AlignedPointResult => {
    const vLines = lines.filter((line) => line.direction === 'v')
    const hLines = lines.filter((line) => line.direction === 'h')

    const vGaps = gaps.filter((gap) =>
      ['left', 'right', 'centerV'].includes(gap.side)
    )
    const hGaps = gaps.filter((gap) =>
      ['top', 'bottom', 'centerH'].includes(gap.side)
    )

    const closestVLine = this.getClosestLine(window, vLines, magnet, sides)
    const closestHLine = this.getClosestLine(window, hLines, magnet, sides)

    const closestVGap = this.getClosestGap(window, vGaps, magnet)
    const closestHGap = this.getClosestGap(window, hGaps, magnet)

    const minDX = Math.min(closestVLine.delta, closestVGap.delta)
    const minDY = Math.min(closestHLine.delta, closestHGap.delta)

    const dx = minDX === Infinity ? 0 : minDX
    const dy = minDY === Infinity ? 0 : minDY

    const alignedWindow = this.alignWindow(-dx, -dy, window)

    const absDX = Math.abs(dx)
    const absDY = Math.abs(dy)

    const vLinesTransformed = this.getLinesInRange(
      absDX,
      absDY,
      alignedWindow,
      vLines,
      sides
    )

    const hLinesTransformed = this.getLinesInRange(
      absDY,
      absDX,
      alignedWindow,
      hLines,
      sides
    )

    const smallestVGap = this.getSmallestGapInRange(
      absDX,
      absDY,
      alignedWindow,
      vGaps
    )
    const vGapsTransformed = smallestVGap ? [smallestVGap] : []

    const smallestHGap = this.getSmallestGapInRange(
      absDY,
      absDX,
      alignedWindow,
      hGaps
    )
    const hGapsTransformed = smallestHGap ? [smallestHGap] : []

    return {
      point: {
        x: point.x - dx,
        y: point.y - dy,
      },
      lines: [...vLinesTransformed, ...hLinesTransformed],
      gaps: [...vGapsTransformed, ...hGapsTransformed],
    }
  }

  private getClosestLine = (
    window: Rectangle,
    lines: AlignmentLine[],
    magnet: number,
    sides: Side[]
  ): LineResult => {
    let alignment: AlignmentLine | null = null
    let distance = Infinity
    let delta = 0

    for (const al of lines) {
      const result = this.getLineDistance(window, al, sides)
      if (result.distance < distance) {
        alignment = al
        distance = result.distance
        delta = result.delta
      }
    }

    const withinThreshold = distance <= magnet
    if (!alignment || !withinThreshold) {
      alignment = null
      distance = Infinity
      delta = Infinity
    }

    return { alignment, distance, delta }
  }

  private getClosestGap = (
    window: Rectangle,
    gaps: AlignmentGap[],
    magnet: number
  ): GapResult => {
    let alignment: AlignmentGap | null = null
    let distance = Infinity
    let delta = 0

    for (const gap of gaps) {
      if (this.isGapOutOfBounds(window, gap, magnet)) continue
      const result = this.getGapDistance(window, gap)
      if (result.distance < distance) {
        alignment = gap
        distance = result.distance
        delta = result.delta
      }
    }

    const withinThreshold = distance <= magnet
    if (!alignment || !withinThreshold) {
      alignment = null
      distance = Infinity
      delta = Infinity
    }

    return { alignment, distance, delta }
  }

  private getLinesInRange = (
    dx: number,
    dy: number,
    window: Rectangle,
    lines: AlignmentLine[],
    sides: Side[]
  ): AlignmentLine[] => {
    const filtered: AlignmentLine[] = []
    for (const al of lines) {
      const result = this.getLineDistance(window, al, sides)
      switch (al.direction) {
        case 'v':
          if (result.distance <= dx) {
            filtered.push(this.transformLine(window, al))
          }
          break
        case 'h':
          if (result.distance <= dy) {
            filtered.push(this.transformLine(window, al))
          }
          break
      }
    }
    return filtered
  }

  private transformLine = (
    rectangle: Rectangle,
    alignmentLine: AlignmentLine
  ): AlignmentLine => {
    switch (alignmentLine.direction) {
      case 'h':
        const minX = Math.min(
          alignmentLine.points[0].x,
          alignmentLine.points[1].x,
          rectangle.x
        )
        const maxX = Math.max(
          alignmentLine.points[0].x,
          alignmentLine.points[1].x,
          rectangle.x + rectangle.w
        )
        return {
          ...alignmentLine,
          points: [
            { x: minX, y: alignmentLine.counter },
            { x: maxX, y: alignmentLine.counter },
          ],
        }
      case 'v':
        const minY = Math.min(
          alignmentLine.points[0].y,
          alignmentLine.points[1].y,
          rectangle.y
        )
        const maxY = Math.max(
          alignmentLine.points[0].y,
          alignmentLine.points[1].y,
          rectangle.y + rectangle.h
        )
        return {
          ...alignmentLine,
          points: [
            ...alignmentLine.points,
            { x: alignmentLine.counter, y: minY },
            { x: alignmentLine.counter, y: maxY },
          ],
        }
    }
  }

  private getSmallestGapInRange = (
    dx: number,
    dy: number,
    window: Rectangle,
    gaps: AlignmentGap[]
  ): AlignmentGap | null => {
    let smallestGap: AlignmentGap | null = null
    let size = Infinity
    for (const gap of gaps) {
      if (this.isGapOutOfBounds(window, gap)) continue
      const result = this.getGapDistance(window, gap)
      switch (gap.side) {
        case 'top':
        case 'bottom':
        case 'centerH':
          if (result.distance > dy) continue
          if (gap.size > size) continue
          size = gap.size
          smallestGap = this.transformGap(window, gap)
          break
        case 'left':
        case 'right':
        case 'centerV':
          if (result.distance > dx) continue
          if (gap.size > size) continue
          size = gap.size
          smallestGap = this.transformGap(window, gap)
          break
      }
    }
    return smallestGap
  }

  private transformGap = (
    rectangle: Rectangle,
    alignmentGap: AlignmentGap
  ): AlignmentGap => {
    switch (alignmentGap.side) {
      case 'centerV':
        const y = alignmentGap.lines[0][0].y
        return {
          ...alignmentGap,
          lines: [
            [
              { x: alignmentGap.lines[0][0].x, y: y },
              { x: rectangle.x, y: y },
            ],
            [
              { x: rectangle.x + rectangle.w, y: y },
              { x: alignmentGap.lines[0][1].x, y: y },
            ],
          ],
        }
      case 'centerH':
        const x = alignmentGap.lines[0][0].x
        return {
          ...alignmentGap,
          lines: [
            [
              { x: x, y: alignmentGap.lines[0][0].y },
              { x: x, y: rectangle.y },
            ],
            [
              { x: x, y: rectangle.y + rectangle.h },
              { x: x, y: alignmentGap.lines[0][1].y },
            ],
          ],
        }
      default:
        return alignmentGap
    }
  }

  private alignWindow = (
    dx: number,
    dy: number,
    window: Rectangle
  ): Rectangle => {
    return {
      x: window.x + dx,
      y: window.y + dy,
      w: window.w,
      h: window.h,
    }
  }

  private getLineDistance = (
    window: Rectangle,
    line: AlignmentLine,
    sides: Side[]
  ): { distance: number; delta: number } => {
    switch (line.direction) {
      case 'v':
        const leftDelta = sides.includes('left')
          ? window.x - line.counter
          : Infinity
        const centerHDelta = sides.includes('centerH')
          ? window.x + window.w / 2 - line.counter
          : Infinity
        const rightDelta = sides.includes('right')
          ? window.x + window.w - line.counter
          : Infinity
        const leftDistance = Math.abs(leftDelta)
        const centerHDistance = Math.abs(centerHDelta)
        const rightDistance = Math.abs(rightDelta)
        const minVDistance = Math.min(
          leftDistance,
          centerHDistance,
          rightDistance
        )
        if (minVDistance === leftDistance) {
          return { distance: leftDistance, delta: leftDelta }
        } else if (minVDistance === centerHDistance) {
          return { distance: centerHDistance, delta: centerHDelta }
        } else {
          return { distance: rightDistance, delta: rightDelta }
        }
      case 'h':
        const topDelta = sides.includes('top')
          ? window.y - line.counter
          : Infinity
        const centerVDelta = sides.includes('centerV')
          ? window.y + window.h / 2 - line.counter
          : Infinity
        const bottomDelta = sides.includes('bottom')
          ? window.y + window.h - line.counter
          : Infinity
        const topDistance = Math.abs(topDelta)
        const centerVDistance = Math.abs(centerVDelta)
        const bottomDistance = Math.abs(bottomDelta)
        const minHDistance = Math.min(
          topDistance,
          centerVDistance,
          bottomDistance
        )
        if (minHDistance === topDistance) {
          return { distance: topDistance, delta: topDelta }
        } else if (minHDistance === centerVDistance) {
          return { distance: centerVDistance, delta: centerVDelta }
        } else {
          return { distance: bottomDistance, delta: bottomDelta }
        }
    }
  }

  private getGapDistance = (
    window: Rectangle,
    gap: AlignmentGap
  ): { distance: number; delta: number } => {
    switch (gap.side) {
      case 'top':
        const bottomDelta = window.y + window.h - gap.position
        return { distance: Math.abs(bottomDelta), delta: bottomDelta }
      case 'bottom':
        const topDelta = window.y - gap.position
        return { distance: Math.abs(topDelta), delta: topDelta }
      case 'left':
        const rightDelta = window.x + window.w - gap.position
        return { distance: Math.abs(rightDelta), delta: rightDelta }
      case 'right':
        const leftDelta = window.x - gap.position
        return { distance: Math.abs(leftDelta), delta: leftDelta }
      case 'centerV':
        const centerVDelta = window.x + window.w / 2 - gap.position
        return { distance: Math.abs(centerVDelta), delta: centerVDelta }
      case 'centerH':
        const centerHDelta = window.y + window.h / 2 - gap.position
        return { distance: Math.abs(centerHDelta), delta: centerHDelta }
    }
  }

  private isGapOutOfBounds = (
    window: Rectangle,
    gap: AlignmentGap,
    magnet: number = 0
  ): boolean => {
    switch (gap.side) {
      case 'top':
      case 'bottom':
        return (
          window.x + window.w - magnet <= gap.bounds[0] ||
          window.x + magnet >= gap.bounds[1]
        )
      case 'centerH':
        return (
          window.x + window.w - magnet <= gap.bounds[0] ||
          window.x + magnet >= gap.bounds[1] ||
          gap.size - 2 <= window.h
        )
      case 'left':
      case 'right':
        return (
          window.y + window.h - magnet <= gap.bounds[0] ||
          window.y + magnet >= gap.bounds[1]
        )
      case 'centerV':
        return (
          window.y + window.h - magnet <= gap.bounds[0] ||
          window.y + magnet >= gap.bounds[1] ||
          gap.size - 2 <= window.w
        )
    }
  }
}
