import { SelectorName, StyleMap } from 'application/attributes'
import { StyleAttributePanel } from './styleAttributePanel'
import { ReadOnlyNode } from 'application/node'

export type OverriddenAttributes = { [K in keyof StyleMap]?: boolean }

export type OverrideSectionKey =
  | 'display'
  | 'padding'
  | 'border'
  | 'shadows'
  | 'filters'
  | 'background'
  | 'transition'
  | 'cursor'

export interface StyleOverridesPanelHandlers {
  hasOverride(keys: (keyof StyleMap)[]): boolean
  sectionHasOverride(section: OverrideSectionKey): boolean
  hasAnyOverride(): boolean
  resetOverride(keys: (keyof StyleMap)[]): void
  resetOverrideSection(section: OverrideSectionKey): void
  resetAll(): void
}

export class StyleOverridesPanel extends StyleAttributePanel<
  {},
  StyleOverridesPanelHandlers,
  keyof StyleMap
> {
  getSettings(): {} {
    return {}
  }

  getHandlers(): StyleOverridesPanelHandlers {
    return {
      hasAnyOverride: this.hasAnyOverride,
      hasOverride: this.hasOverride,
      sectionHasOverride: this.sectionHasOverride,
      resetOverride: this.resetOverrides,
      resetOverrideSection: this.resetSectionOverrides,
      resetAll: this.resetAll,
    }
  }

  private hasAnyOverride = (): boolean => {
    const overridden = this.getOverridenMap()
    return Object.values(overridden).some((value) => value)
  }

  private hasOverride = (keys: (keyof StyleMap)[]): boolean => {
    const overridden = this.getOverridenMap()
    for (const key of keys) {
      if (overridden[key]) return true
    }
    return false
  }

  private sectionHasOverride = (section: OverrideSectionKey): boolean => {
    const keys = this.getKeysForSection(section)
    for (const key of keys) {
      if (this.hasOverride([key])) return true
    }
    return false
  }

  private resetOverrides = (keys: (keyof StyleMap)[]): void => {
    for (const node of this.getNodes()) {
      const clear: Partial<StyleMap> = {}
      for (const key of keys) {
        clear[key] = undefined
        const section = this.getSectionForAttribute(key)
        if (!section) continue
        const noDefaults = this.nodeHasNoSectionDefaults(node, section)
        if (noDefaults) {
          const keys = this.getKeysForSection(section)
          for (const key of keys) {
            clear[key] = undefined
          }
        }
      }
      this.clearKeys(node, clear)
    }
    this.commit()
  }

  private resetSectionOverrides = (section: OverrideSectionKey): void => {
    const keys = this.getKeysForSection(section)
    const clear: Partial<StyleMap> = {}
    for (const key of keys) {
      clear[key] = undefined
    }
    for (const node of this.getNodes()) {
      this.clearKeys(node, clear)
    }
    this.commit()
  }

  private resetAll = (): void => {
    for (const node of this.getNodes()) {
      const name = this.getNodeSelector(node)
      if (name === undefined) continue

      const selector = node.getSelector(name)
      if (selector === undefined || selector.name === 'default') continue

      const clear: Partial<StyleMap> = {}
      for (const key in selector.styles) {
        const typedKey = key as keyof StyleMap
        clear[typedKey] = undefined
      }
      this.setOne(node.getId(), clear, name)
    }
    this.commit()
  }

  private getOverridenMap(): OverriddenAttributes {
    const overridden: OverriddenAttributes = {}
    for (const node of this.getNodes()) {
      const name = this.getNodeSelector(node)
      if (name === undefined) continue

      const selector = node.getSelector(name)
      if (selector === undefined || selector.name === 'default') continue

      for (const key in selector.styles) {
        const typedKey = key as keyof StyleMap
        if (selector.styles[typedKey] !== undefined) {
          overridden[typedKey] = true
        }
      }
    }
    return overridden
  }

  private getNodeSelector = (node: ReadOnlyNode): SelectorName | undefined => {
    const activeBreakpoint = node.getActiveBreakpoint()
    const activePseudo = node.getActivePseudo()
    if (activePseudo === 'none') return activeBreakpoint
    return `${activeBreakpoint} ${activePseudo}`
  }

  private clearKeys = (node: ReadOnlyNode, clear: Partial<StyleMap>): void => {
    const selector = this.getNodeSelector(node)
    if (selector === undefined || selector === 'default') return
    this.setOne(node.getId(), clear, selector)
  }

  private nodeHasNoSectionDefaults = (
    node: ReadOnlyNode,
    section: OverrideSectionKey
  ): boolean => {
    const defaultSelector = node.getSelector('default')
    if (!defaultSelector) return true
    const styles = defaultSelector.styles
    const keys = this.getKeysForSection(section)
    for (const key of keys) {
      if (styles[key] === undefined && this.hasOverride([key])) {
        return true
      }
    }
    return false
  }

  private getSectionForAttribute = (
    key: keyof StyleMap
  ): OverrideSectionKey | null => {
    switch (key) {
      case 'display':
      case 'flex.direction':
      case 'flex.align':
      case 'flex.justify':
      case 'flex.gap':
        return 'display'
      case 'padding.left.unit':
      case 'padding.right.unit':
      case 'padding.top.unit':
      case 'padding.bottom.unit':
      case 'padding.left.px':
      case 'padding.right.px':
      case 'padding.top.px':
      case 'padding.bottom.px':
        return 'padding'
      case 'border.side':
      case 'border.top.unit':
      case 'border.right.unit':
      case 'border.bottom.unit':
      case 'border.left.unit':
      case 'border.top.px':
      case 'border.right.px':
      case 'border.bottom.px':
      case 'border.left.px':
      case 'border.color':
        return 'border'
      case 'shadows':
        return 'shadows'
      case 'filter.blur.radius':
      case 'filter.mode':
        return 'filters'
      case 'background':
        return 'background'
      case 'transition.mode':
      case 'transition.duration':
      case 'transition.timing':
        return 'transition'
      case 'cursor':
        return 'cursor'
      default:
        return null
    }
  }

  private getKeysForSection(section: OverrideSectionKey): (keyof StyleMap)[] {
    switch (section) {
      case 'display':
        return [
          'display',
          'flex.direction',
          'flex.align',
          'flex.justify',
          'flex.gap',
        ]
      case 'padding':
        return [
          'padding.left.unit',
          'padding.right.unit',
          'padding.top.unit',
          'padding.bottom.unit',
          'padding.left.px',
          'padding.right.px',
          'padding.top.px',
          'padding.bottom.px',
        ]
      case 'border':
        return [
          'border.side',
          'border.top.px',
          'border.right.px',
          'border.bottom.px',
          'border.left.px',
          'border.color',
        ]
      case 'shadows':
        return ['shadows']
      case 'filters':
        return ['filter.blur.radius', 'filter.mode']
      case 'background':
        return ['background']
      case 'transition':
        return ['transition.mode', 'transition.duration', 'transition.timing']
      case 'cursor':
        return ['cursor']
    }
  }
}
