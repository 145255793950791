import { DesignColor } from 'themes'
import { useEffect, useRef, useState } from 'react'
import { parseHexInput } from 'application/color'

interface HexInputProps {
  editing: boolean
  setEditing: (editing: boolean) => void

  value: string
  setValue: (value: string) => void

  hasOverride?: boolean
}

export default function HexInput({
  editing,
  setEditing,
  value,
  setValue,
  hasOverride,
}: HexInputProps) {
  const inputRef = useRef<HTMLInputElement>(null)
  const [localValue, setLocalValue] = useState(value)
  const [initialValue, setInitialValue] = useState(value)
  const [select, setSelect] = useState(false)

  const handleBlur = () => {
    const parsedValue = parseHexInput(localValue)
    if (parsedValue.valid && parsedValue.value !== initialValue) {
      setValue(parsedValue.value)
    }
    setEditing(false)
  }

  const handleFocus = () => {
    if (!editing) setEditing(true)
  }

  const handleMouseUp = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    if (e.button === 2 || e.ctrlKey || e.metaKey) return
    if (!select && inputRef.current) {
      e.preventDefault()
      inputRef.current.select()
      setSelect(true)
      if (!editing) setEditing(true)
    }
  }

  const handleMouseDown = (
    e: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    if (e.button === 2 || e.ctrlKey || e.metaKey) e.preventDefault()
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation()
    if (e.key === 'Enter') inputRef.current?.blur()
  }

  useEffect(() => {
    if (editing) return
    setLocalValue(value)
    setInitialValue(value)
    setSelect(false)
  }, [editing, value])

  return (
    <input
      ref={inputRef}
      type="text"
      value={localValue}
      onChange={(e) => setLocalValue(e.target.value)}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onClick={(e) => e.stopPropagation()}
      onContextMenu={(e) => e.preventDefault()}
      onMouseUp={handleMouseUp}
      onMouseDown={handleMouseDown}
      onKeyDown={handleKeyDown}
      onPaste={(e) => e.stopPropagation()}
      style={{
        width: 56,
        height: 24,
        padding: 0,
        margin: 0,
        boxSizing: 'border-box',
        border: 'none',
        outline: 'none',
        background: 'transparent',
        fontFamily: 'Inter',
        fontWeight: 'regular',
        fontSize: 11,
        color: DesignColor(hasOverride ? 'overrideParent' : 'text1'),
        cursor: 'default',
      }}
    />
  )
}
