export class EditInputTextEditor {
  private editing: boolean = false

  getEditing(): boolean {
    return this.editing
  }

  setEditing(editing: boolean): void {
    this.editing = editing
  }

  clear = (): void => {
    this.editing = false
  }
}
