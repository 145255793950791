import { useRecoilValue } from 'recoil'
import { userInfoState } from 'components/Pages/Projects/Projects'
import posthog from 'posthog-js'

const { REACT_APP_POSTHOG_ENV } = process.env

export default function useIdentity() {
  const userInfo = useRecoilValue(userInfoState)
  if (REACT_APP_POSTHOG_ENV === 'none') return
  if (userInfo.id !== '' && userInfo.email !== '') {
    posthog.identify(userInfo.id, {
      email: userInfo.email,
      env: getEnvTag(),
    })
  }
}

function getEnvTag() {
  if (REACT_APP_POSTHOG_ENV === 'none') return 'local'
  if (REACT_APP_POSTHOG_ENV === 'dev') return 'dev'
  return 'prod'
}
