import { Command } from 'application/client'
import { ReadOnlyDocumentSelection } from 'application/selection'
import { BackendService } from 'application/service/backend'
import { EditorProjectService } from 'editor/project/project'
import { v4 } from 'uuid'

const allowedTypes = [
  'image/jpeg',
  'image/png',
  'image/svg+xml',
  'image/webp',
  'image/gif',
  'image/avif',
]

interface CommandHandler {
  handle: (commands: Command | Command[]) => void
}

export class UploadImageAction {
  private backendService: BackendService
  private editorProjectService: EditorProjectService
  private documentSelection: ReadOnlyDocumentSelection
  private commandHandler: CommandHandler

  constructor(
    backendService: BackendService,
    editorProjectService: EditorProjectService,
    documentSelection: ReadOnlyDocumentSelection,
    commandHandler: CommandHandler
  ) {
    this.backendService = backendService
    this.editorProjectService = editorProjectService
    this.documentSelection = documentSelection
    this.commandHandler = commandHandler
  }

  upload = (nodeId: string): void => {
    const input = document.createElement('input')
    input.type = 'file'
    input.style.display = 'none'
    input.onchange = this.createUploadCallbackHandler(nodeId)
    input.click()
  }

  private createUploadCallbackHandler = (id: string): ((e: Event) => void) => {
    return (e: Event) => {
      if (!(e.target instanceof HTMLInputElement)) return

      const projectId = this.editorProjectService.getProjectId()
      if (!projectId) return

      const file = e.target.files && e.target.files[0]
      if (file && allowedTypes.includes(file.type)) {
        this.getImageSize(file)
          .then(async ({ file, w, h }) => {
            const response = await this.backendService.createAsset(
              projectId,
              v4(),
              file
            )
            if (response) this.updateSelectedImages(id, response.url, w, h)
          })
          .catch((error) => {
            console.error('Image resizing failed:', error)
          })
      }
    }
  }

  private getImageSize(
    file: File
  ): Promise<{ file: File; w: number; h: number }> {
    return new Promise((resolve, reject) => {
      const image = new Image()
      image.src = URL.createObjectURL(file)

      image.onload = () => {
        let width = image.width
        let height = image.height

        resolve({ file, w: width, h: height })
      }

      image.onerror = () => reject(new Error('Failed to load image.'))
    })
  }

  private updateSelectedImages = (
    id: string,
    url: string,
    w: number,
    h: number
  ): void => {
    const selectedImages = this.documentSelection
      .getSelected()
      .filter((n) => n.getId() === id && n.getBaseAttribute('type') === 'image')
    if (selectedImages.length === 0) return

    const updates: Command[] = selectedImages.map((n) => ({
      type: 'setNodeAttribute',
      params: {
        id: n.getId(),
        base: {
          'image.src': url,
          'image.originalSize.w': w,
          'image.originalSize.h': h,
        },
        style: {},
      },
    }))

    this.commandHandler.handle(updates)
    this.commandHandler.handle([{ type: 'commit' }])
  }
}
