import { WriteDocument } from 'application/document'
import { RuleValidator, Violation, ViolationRemediator } from './types'

export class RulesEngine {
  private validators: RuleValidator[]
  private remediators: ViolationRemediator[]

  constructor(validators: RuleValidator[], remediators: ViolationRemediator[]) {
    this.validators = validators
    this.remediators = remediators
  }

  fixViolations = (nodeId: string, document: WriteDocument): void => {
    let count = 0
    let found = true
    while (found) {
      found = this.fixNodeViolations(nodeId, document)
      count++
      if (count > 10) {
        break
      }
    }

    count = 0
    found = true
    while (found) {
      found = this.fixChildViolations(nodeId, document)
      count++
      if (count > 10) {
        break
      }
    }
  }

  private fixNodeViolations = (
    nodeId: string,
    document: WriteDocument
  ): boolean => {
    let found = false
    const violations: Violation[] = []
    for (const validator of this.validators) {
      const node = document.getNode(nodeId)
      if (!node) return false

      const nodeViolations = validator.validate(nodeId, document)
      violations.push(...nodeViolations)
      found = found || nodeViolations.length > 0
    }

    for (const violation of violations) {
      const remediator = this.remediators.find((r) =>
        r.canRemediate(violation.type)
      )
      if (remediator !== undefined) {
        remediator.remediate(violation, document)
      }
    }

    return found
  }

  private fixChildViolations = (
    nodeId: string,
    document: WriteDocument
  ): boolean => {
    let found = false
    const violations: Violation[] = []
    for (const validator of this.validators) {
      const node = document.getNode(nodeId)
      if (!node) return false

      const children = node.getChildren()
      if (children) {
        for (const child of children) {
          const childViolations = validator.validate(child, document)
          violations.push(...childViolations)
          found = found || childViolations.length > 0
        }
      }
    }

    for (const violation of violations) {
      const remediator = this.remediators.find((r) =>
        r.canRemediate(violation.type)
      )
      if (remediator !== undefined) {
        remediator.remediate(violation, document)
      }
    }

    return found
  }
}
