import { AttributeImageResize } from 'application/attributes'
import TitleButtonBlock from 'components/Library/Components/Button/TitleButtonBlock/TitleButtonBlock'
import Dropdown, {
  dropdownOption,
} from 'components/Library/Components/Dropdown/Dropdown'
import ImageInput from 'components/Library/Components/Inputs/ImageInput/ImageInput'
import OverrideReset from 'components/Library/Components/Inputs/OverrideReset/OverrideReset'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'

export default function ImageSettings() {
  const { settings, handlers } = usePanelHelper(editor.getUI().getImagePanel())
  const overrides = usePanelHelper(editor.getUI().getStyleOverridesPanel())
  if (!settings || !settings.baseAttributes || !settings.styleAttributes)
    return <></>

  const { 'image.src': src } = settings.baseAttributes
  const { 'image.resize': resize } = settings.styleAttributes
  if (!src) return <></>

  const { setResize, setSrc, clearMixed } = handlers
  const { hasOverride } = overrides.handlers

  return (
    <PanelSection>
      <PanelRow>
        <TitleButtonBlock title={'Image'} active={true} />
      </PanelRow>
      <PanelRow>
        <ImageInput src={src} setSrc={setSrc} clearMixed={clearMixed} />
        <OverrideReset keys={['image.resize']}>
          <Dropdown
            width={96}
            tooltipKey={'ObjectFit'}
            selected={resize === undefined ? 'fill' : resize}
            mixed={resize === 'Mixed'}
            select={(r) => {
              if (r === 'Mixed') return
              setResize(r)
            }}
            options={resizeModeOptions}
            commit={true}
            hasOverride={hasOverride(['image.resize'])}
          />
        </OverrideReset>
      </PanelRow>
    </PanelSection>
  )
}

const resizeModeOptions: dropdownOption<AttributeImageResize | undefined>[] = [
  {
    value: 'fill',
    text: 'Fill',
  },
  {
    value: 'fit',
    text: 'Fit',
  },
  {
    value: 'stretch',
    text: 'Stretch',
  },
]
