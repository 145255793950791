import { State } from 'application/textEditor'
import { ClientTransactionHandler } from './transaction'
import _ from 'lodash'

export class TextEditorTransactionHistoryHandler {
  private handler: ClientTransactionHandler

  constructor(handler: ClientTransactionHandler) {
    this.handler = handler
  }

  onEditText = (initialId: string | null, currentId: string | null): void => {
    this.handler.addEvent({
      type: 'edit_text',
      data: {
        initialId: initialId,
        currentId: currentId,
      },
    })
  }

  onTextState = (initial: State | null, current: State | null): void => {
    this.handler.addEvent({
      type: 'text_state',
      data: {
        initial: _.cloneDeep(initial),
        current: _.cloneDeep(current),
      },
    })
  }
}
