import { CssUnit } from '../types/types'

export class CssLength {
  private defined: boolean
  private value: number
  private unit: CssUnit

  static create(value: number, unit: CssUnit): CssLength {
    return new CssLength(value, unit, true)
  }

  static createUndefined(): CssLength {
    return new CssLength()
  }

  private constructor(
    value: number = 0,
    unit: CssUnit = CssUnit.unit_none,
    defined: boolean = false
  ) {
    this.value = value
    this.unit = unit
    this.defined = defined
  }

  getValue = (): number => {
    return this.value
  }

  getUnit = (): CssUnit => {
    return this.unit
  }

  isDefined = (): boolean => {
    return this.defined
  }

  calcPercentage = (base: number): number => {
    if (this.unit === CssUnit.unit_percent) {
      return (this.value / 100) * base
    }
    return this.getValue()
  }

  setValue = (value: number, unit: CssUnit): void => {
    this.defined = true
    this.value = value
    this.unit = unit
  }
}
