import { Portal } from 'components/Pages/Editor/PortalRoot/PortalRoot'
import PanelRowButton from 'components/Library/Components/Button/PanelRowButton/PanelRowButton'
import IconBlock from 'components/Library/Components/IconBlock/IconBlock/IconBlock'
import TabsBlock from 'components/Library/Components/Tabs/TabsBlock'
import PanelContainer from 'components/Library/Containers/PanelContainer/PanelContainer'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import { panelPosition } from 'components/Library/Containers/constants'
import { useRef, useState } from 'react'
import useClosePopup from 'hooks/ui/useClosePopup'
import {
  ScreenSize,
  ScreenSizeType,
  StandardScreens,
  UniqueScreens,
} from 'application/shapes'

interface ScreenSizePopupProps {
  sectionRef: React.RefObject<HTMLDivElement>

  close: () => void

  select: (screen: ScreenSize) => void
}

export default function ScreenSizePopup({
  sectionRef,
  close,
  select,
}: ScreenSizePopupProps) {
  const popupRef = useRef<HTMLDivElement>(null)
  const [tab, setTab] = useState<ScreenSizeType>('desktop')

  useClosePopup({
    ref: popupRef,
    close,
    exceptionRef: sectionRef,
  })

  return (
    <Portal>
      <PanelContainer panelRef={popupRef} position={getPosition()}>
        <PanelSection>
          <PanelRow>
            <TabsBlock
              tabs={['desktop', 'tablet', 'phone']}
              selected={tab}
              select={(t) => setTab(t as ScreenSizeType)}
            />
            <IconBlock icon="X" onClick={close} />
          </PanelRow>
        </PanelSection>
        <PanelSection>
          {StandardScreens[tab].map((screen) => (
            <PanelRowButton
              key={screen.label}
              text={screen.label}
              subtext={`${screen.w}x${screen.h}`}
              onClick={() => select(screen)}
            />
          ))}
        </PanelSection>
        <PanelSection bottom={true}>
          {UniqueScreens[tab].map((screen) => (
            <PanelRowButton
              key={screen.label}
              text={screen.label}
              subtext={`${screen.w}x${screen.h}`}
              onClick={() => select(screen)}
            />
          ))}
        </PanelSection>
      </PanelContainer>
    </Portal>
  )
}

function getPosition(): panelPosition {
  return {
    position: 'absolute',
    top: 64,
    right: 248,
    zIndex: 2,
  }
}
