import { Properties, unitSizeToString } from 'application/export/css'
import { TailwindClassRenderer } from '../types'

export class TailwindClassFont implements TailwindClassRenderer {
  render = (properties: Partial<Properties>): string[] => {
    const classes: string[] = []

    const fontFamily = properties.fontFamily
    if (fontFamily !== undefined) {
      classes.push(`font-['${fontFamily}']`)
    }

    const fontSize = properties.fontSize
    if (fontSize !== undefined) {
      classes.push(`text-[${unitSizeToString(fontSize)}]`)
    }

    const fontWeight = properties.fontWeight
    if (fontWeight !== undefined) {
      classes.push(...this.renderFontWeight(fontWeight))
    }

    const lineHeight = properties.lineHeight
    if (lineHeight !== undefined && lineHeight !== 'normal') {
      classes.push(`leading-[${unitSizeToString(lineHeight)}]`)
    }

    const letterSpacing = properties.letterSpacing
    if (letterSpacing !== undefined) {
      classes.push(`tracking-[${unitSizeToString(letterSpacing)}]`)
    }

    const textAlign = properties.textAlign
    if (textAlign !== undefined) {
      classes.push(`text-[${textAlign}]`)
    }

    return classes
  }

  private renderFontWeight = (
    fontWeight: 100 | 200 | 300 | 400 | 500 | 600 | 700 | 800 | 900
  ): string[] => {
    switch (fontWeight) {
      case 100:
        return ['font-thin']
      case 200:
        return ['font-extralight']
      case 300:
        return ['font-light']
      case 400:
        return []
      case 500:
        return ['font-medium']
      case 600:
        return ['font-semibold']
      case 700:
        return ['font-bold']
      case 800:
        return ['font-extrabold']
      case 900:
        return ['font-black']
    }
  }
}
