import { ScreenSize } from 'application/shapes'
import ButtonBlock from 'components/Library/Components/Button/ButtonBlock/ButtonBlock'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'
import { useRef, useState } from 'react'
import ScreenSizePopup from './ScreenSizePopup'

export default function ScreenSizeRow() {
  const buttonRef = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)

  const { settings, handlers } = usePanelHelper(
    editor.getUI().getScreenSizePanel()
  )
  if (!settings || !settings.screen) return <></>

  const { screen } = settings
  const { setScreen } = handlers

  const buttonText = getButtonLabel(screen)

  return (
    <PanelRow>
      <ButtonBlock
        buttonRef={buttonRef}
        width={192}
        icon={'ScreenSizes'}
        text={buttonText}
        onClick={() => setOpen(!open)}
      />
      {open && (
        <ScreenSizePopup
          sectionRef={buttonRef}
          close={() => setOpen(false)}
          select={setScreen}
        />
      )}
    </PanelRow>
  )
}

function getButtonLabel(screen: 'Mixed' | ScreenSize): string {
  if (screen === 'Mixed') return 'Mixed'
  return screen.label
}
