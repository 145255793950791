export class Position {
  x: number
  y: number
  width: number
  height: number

  static create(
    x: number = 0,
    y: number = 0,
    width: number = 0,
    height: number = 0
  ): Position {
    return new Position(x, y, width, height)
  }

  private constructor(x: number, y: number, width: number, height: number) {
    this.x = x
    this.y = y
    this.width = width
    this.height = height
  }

  move = (x: number, y: number): void => {
    this.x = x
    this.y = y
  }
}
