import { Node } from 'application/node'
import { UpdateHandler } from './update'

export class DocumentUpdateAccumulator {
  private handler: UpdateHandler

  constructor(handler: UpdateHandler) {
    this.handler = handler
  }

  onNodeAdded = (node: Node): void => {
    this.handler.addUpdate({
      type: 'node_created',
      data: {
        id: node.getId(),
      },
    })
  }

  onNodeDeleted = (node: Node): void => {
    this.handler.addUpdate({
      type: 'node_deleted',
      data: {
        id: node.getId(),
      },
    })
  }
}
