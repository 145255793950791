import { Properties, propertiesToString } from '../css'
import { Attributes } from './types'

export function attributesToString(attributes: Partial<Attributes>): string {
  return Object.entries(attributes)
    .map(([key, value]) => ` ${key}="${value}"`)
    .join('')
}

export function inlineStyleToString(inlineStyle: Partial<Properties>): string {
  return ` style="${propertiesToString(inlineStyle)}"`
}
