import { DesignColor } from 'themes'
import Icon from '../../Icon/Icon/Icon'

interface PresetArrowProps {
  visible: boolean
  onClick: () => void
}

export default function PresetArrow({ visible, onClick }: PresetArrowProps) {
  return (
    <div
      onClick={onClick}
      style={{
        width: 16,
        height: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
      }}
    >
      {visible && (
        <Icon
          icon={'DropdownArrowDown'}
          size={10}
          color={DesignColor('text1')}
        />
      )}
    </div>
  )
}
