import { AbsoluteItem } from '../absolute/absoluteItem'
import {
  PositionContext,
  PositionContextBuilder,
} from '../context/positionContext'
import { SizeContext } from '../context/sizeContext'
import { LayoutItem } from '../layoutItem/layoutItem'
import { SizeValueType } from '../types/types'

export class BlockLayout extends LayoutItem {
  resize = (context: SizeContext): void => {
    this.computeOutline(context)

    const childContext = this.createChildSizeContext(context)

    const width = this.computeWidth(context, childContext)

    this.applyWidth(width, childContext)

    this.sizeSelf(width, context)

    this.sizeOutOfFlowItems(context)

    this.cacheSizes(context)

    this.sizeContextPool!.release(childContext)
  }

  place = (context: PositionContext): void => {
    this.placeSelf(context)

    const positionContext = this.createChildPositionContext(context)

    let offsetY = 0
    for (const child of this.children) {
      const childContext = PositionContextBuilder.create(positionContext)
        .withY(positionContext.y + offsetY)
        .build()
      if (!child.isInFlow()) {
        const absoluteItem = new AbsoluteItem(child)
        absoluteItem.place(childContext)
      } else {
        child.place(childContext)
        offsetY += child.position.height
      }
    }
  }

  private applyWidth = (mainSize: number, context: SizeContext): void => {
    for (const child of this.children) {
      const childMainSize = this.computeChildWidth(mainSize, child, context)

      const childContext = this.sizeContextPool!.fromContext(context)
      childContext.width.type = SizeValueType.exact
      childContext.width.value = childMainSize

      child.resize(childContext)

      this.sizeContextPool!.release(childContext)
    }
  }

  private computeWidth = (
    context: SizeContext,
    childContext: SizeContext
  ): number => {
    switch (context.width.type) {
      case SizeValueType.exact:
        return context.width.value - this.getOutlineWidth()
      default:
        const preferredWidth = this.getPreferredWidth(context)
        if (preferredWidth !== undefined) {
          return (
            this.minMaxWidth(preferredWidth, context) - this.getOutlineWidth()
          )
        }

        const transferredWidth = this.getTransferredWidth(context)
        if (transferredWidth !== undefined) {
          return (
            this.minMaxWidth(
              this.minMaxTransferredWidth(transferredWidth, context),
              context
            ) - this.getOutlineWidth()
          )
        }
    }

    switch (context.width.type) {
      case SizeValueType.min_content:
        return this.getChildMinContentWidth(childContext)
      case SizeValueType.absolute:
        return this.getChildFitContentWidth(childContext)
      default:
        return this.getChildMaxContentWidth(childContext)
    }
  }

  private getChildMaxContentWidth = (context: SizeContext): number => {
    let maxWidth = 0
    for (const child of this.children) {
      maxWidth = Math.max(maxWidth, child.getMaxContentWidth(context))
    }
    return maxWidth
  }

  private getChildFitContentWidth = (context: SizeContext): number => {
    let maxWidth = 0
    for (const child of this.children) {
      maxWidth = Math.max(maxWidth, child.getFitContentSize(context, 'width'))
    }
    return maxWidth
  }

  private getChildMinContentWidth = (context: SizeContext): number => {
    let maxWidth = 0
    for (const child of this.children) {
      maxWidth = Math.max(maxWidth, child.getMaxContentWidth(context))
    }
    return maxWidth
  }

  private computeChildWidth = (
    mainSize: number,
    child: LayoutItem,
    context: SizeContext
  ): number => {
    const preferredWidth = child.getPreferredWidth(context)
    if (preferredWidth !== undefined) {
      return child.minMaxWidth(preferredWidth, context)
    }

    const transferredWidth = child.getTransferredWidth(context)
    if (transferredWidth !== undefined) {
      return child.minMaxWidth(
        child.minMaxTransferredWidth(transferredWidth, context),
        context
      )
    }

    if (child.element!.sizedByContent) {
      return child.getFitContentSize(context, 'width')
    }

    return mainSize
  }

  private sizeSelf = (mainSize: number, context: SizeContext): void => {
    this.position.width = mainSize + this.getOutlineWidth()
    switch (context.height.type) {
      case SizeValueType.exact:
        this.position.height = context.height.value
        break
      default:
        this.position.height = this.computeHeight(context)
        break
    }
  }

  private computeHeight = (context: SizeContext): number => {
    const preferredHeight = this.getPreferredHeight(context)
    if (preferredHeight !== undefined) {
      return this.minMaxHeight(preferredHeight, context)
    }

    const transferredHeight = this.getTransferredHeight(context)
    if (transferredHeight !== undefined) {
      return this.minMaxHeight(
        this.minMaxTransferredHeight(transferredHeight, context),
        context
      )
    }

    let sumHeight = 0
    for (const child of this.children) {
      if (!child.isInFlow()) continue
      sumHeight += child.position.height
    }
    return sumHeight + this.getOutlineHeight()
  }
}
