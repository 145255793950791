import { AlignmentGap, AlignmentLine } from 'application/action'
import { Camera, CameraUpdateListener } from 'application/camera'
import { Canvas, CanvasUpdateListener } from 'editor/canvas/canvas'
import { AlignmentLineTransformer } from './transformer/alignmentLines'
import { AlignmentGapsTransformer } from './transformer/alignmentGaps'
import { FontLoaderInterface, Shaper } from 'application/text'

export const hapticAlignmentLinesKey = 'alignmentLines'
export const hapticAlignmentGapsKey = 'alignmentGaps'

export class HapticAlignment
  implements CameraUpdateListener, CanvasUpdateListener
{
  private canvas: Canvas
  private fontLoader: FontLoaderInterface
  private textShaper: Shaper

  private lines: AlignmentLine[]
  private gaps: AlignmentGap[]
  private camera: Camera | null

  constructor(
    canvas: Canvas,
    fontLoader: FontLoaderInterface,
    textShaper: Shaper
  ) {
    this.canvas = canvas
    this.fontLoader = fontLoader
    this.textShaper = textShaper

    this.lines = []
    this.gaps = []
    this.camera = null
  }

  onCamera = (camera: Camera) => {
    this.camera = camera
    this.render()
  }

  onCanvasInit = () => {
    this.render()
  }

  setLines = (alignmentLines: AlignmentLine[]): void => {
    this.lines = alignmentLines
    this.render()
  }

  setGaps = (alignmentGaps: AlignmentGap[]): void => {
    this.gaps = alignmentGaps
    this.render()
  }

  private render = () => {
    if (!this.canvas.isReady()) return

    if (this.lines.length === 0 || this.camera === null) {
      this.canvas.deleteHaptic(hapticAlignmentLinesKey)
    } else {
      this.canvas.setHaptic(
        hapticAlignmentLinesKey,
        AlignmentLineTransformer.transform(
          this.canvas.getContext(),
          this.lines,
          this.camera
        )
      )
    }

    if (this.gaps.length === 0 || this.camera === null) {
      this.canvas.deleteHaptic(hapticAlignmentGapsKey)
    } else {
      this.canvas.setHaptic(
        hapticAlignmentGapsKey,
        AlignmentGapsTransformer.transform(
          this.canvas.getContext(),
          this.fontLoader,
          this.textShaper,
          this.gaps,
          this.camera
        )
      )
    }
  }
}
