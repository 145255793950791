import styled from 'styled-components'

export const TabsLine = styled.div<{
  line: { animate: boolean; left: number; width: number }
  color: string
  bottom?: number
}>`
  position: absolute;
  left: ${({ line: lineProps }) => lineProps.left}px;
  width: ${({ line: lineProps }) => lineProps.width}px;
  bottom: 0px;
  height: 1px;
  background: ${({ color }) => color};

  transition: left 0.2s ease-in-out, width 0.2s ease-in-out;
  transition-duration: ${({ line: lineProps }) =>
    lineProps.animate ? '0.2s' : '0s'};
`
