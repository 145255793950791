import { CANVAS_RESOLUTION_SCALE } from './consts'
import { Rectangle } from 'application/shapes'

export type CanvasSizeUpdateListener = {
  onCanvasSize: (canvasSize: Rectangle) => void
}

export class CanvasSizeService {
  private listeners: { [key: string]: CanvasSizeUpdateListener }
  private target: string
  private canvasRectangle: Rectangle

  constructor(target: string) {
    this.listeners = {}
    this.target = target
    this.canvasRectangle = {
      x: 0,
      y: 0,
      w: 1000,
      h: 1000,
    }
  }

  init = () => {
    this.handleResize()
  }

  get = (): Rectangle => {
    return this.canvasRectangle
  }

  subscribe = (key: string, listener: CanvasSizeUpdateListener) => {
    this.listeners[key] = listener
    listener.onCanvasSize(this.canvasRectangle)
  }

  unsubscribe = () => {
    delete this.listeners[this.target]
  }

  handleResize = () => {
    this.update()
    this.notifyListeners()
  }

  update = () => {
    const canvas = document.getElementById(this.target) as HTMLCanvasElement
    if (!canvas) return

    const canvasRect = canvas.getBoundingClientRect()
    this.canvasRectangle = {
      x: canvasRect.left,
      y: canvasRect.top,
      w: canvasRect.width,
      h: canvasRect.height,
    }

    const dpr = window.devicePixelRatio
    canvas.width = canvasRect.width * dpr * CANVAS_RESOLUTION_SCALE
    canvas.height = canvasRect.height * dpr * CANVAS_RESOLUTION_SCALE
  }

  private notifyListeners = () => {
    for (const listener of Object.values(this.listeners)) {
      listener.onCanvasSize(this.canvasRectangle)
    }
  }
}
