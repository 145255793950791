import { State } from './types'

export function newEmptyState(): State {
  return {
    content: {
      content: '',
      contentStyles: [],
      styles: {
        fontFamily: 'inter',
        fontWeight: 'regular',
        fontSize: 16,
        fill: {
          type: 'color',
          color: { r: 0, g: 0, b: 0, a: 1 },
        },
        align: 'left',
        letterSpacing: 0,
        lineHeight: 0,
        italic: false,
        underline: false,
        link: null,
      },
      styleOverrides: {},
      lineTypes: ['none'],
    },
    selection: {
      focus: { index: 0, wrapped: false },
      anchor: null,
    },
    nextCharacter: null,
  }
}

export function newTestState(): State {
  return {
    content: {
      content: 'abcde',
      contentStyles: [0, 0, 0, 0, 0],
      styles: {
        fontFamily: 'inter',
        fontWeight: 'regular',
        fontSize: 16,
        fill: {
          type: 'color',
          color: { r: 0, g: 0, b: 0, a: 1 },
        },
        align: 'left',
        letterSpacing: 0,
        lineHeight: 0,
        italic: false,
        underline: false,
        link: null,
      },
      styleOverrides: {},
      lineTypes: ['none'],
    },
    selection: {
      focus: { index: 0, wrapped: false },
      anchor: null,
    },
    nextCharacter: null,
  }
}
