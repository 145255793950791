import {
  BlendMode,
  Context,
  UniformValue,
  VaoData,
  VertexBuffer,
  calcBoundingBox,
  calcGaussianWeights,
  calcRounding,
  isPointInBox,
  isPointInEllipse,
  rectToQuadTriangles,
} from 'application/render'
import { Rectangle } from 'application/shapes'
import { Color, glClearColors } from 'application/color'
import { WebglEllipse } from './webglEllipse'
import {
  cleanupVaoData,
  clearBuffers,
  connectBuffersToVao,
  createEmptyVaoData,
  standardDraw,
} from '../utils'
import { MaterialType } from './shaderMap'

export type WebglEllipseInnerShadowData = Rectangle & {
  color: Color
  blur: number
  spread: number
  sourceRect: Rectangle
  bb?: Rectangle
  bbRounding?: { tl: number; tr: number; br: number; bl: number }
}

export class WebglEllipseInnerShadow {
  private context: Context
  private data: WebglEllipseInnerShadowData
  private vaoData: VaoData
  private ellipse: WebglEllipse | null = null

  constructor(context: Context, data: WebglEllipseInnerShadowData) {
    this.context = context
    this.data = data
    this.vaoData = createEmptyVaoData()
  }

  init = (): void => {
    this.vaoData = this.updateRenderData()
  }

  draw(depth: number = 0): void {
    if (!this.ellipse || !this.vaoData.material) return

    this.context.setDrawingTarget('temp1')
    this.context.clear(this.data.color)
    this.context.setBlendMode(BlendMode.clear)

    this.ellipse.draw()

    this.context.setDrawingTarget('temp2')
    this.context.clear(glClearColors.transparent)
    this.context.setBlendMode(BlendMode.shadow)

    const temp1 = this.context.getSceneTexture('temp1')
    this.vaoData.material.setSampler('uSampler', temp1.getTexture(), 0)
    this.vaoData.uniforms['uPass'] = 1.0
    standardDraw(this.vaoData)

    this.context.setDrawingTarget(depth)

    const temp2 = this.context.getSceneTexture('temp2')
    this.vaoData.material.setSampler('uSampler', temp2.getTexture(), 0)
    this.vaoData.uniforms['uPass'] = 2.0
    standardDraw(this.vaoData)

    this.context.setBlendMode(BlendMode.default)
    this.context.setClearColor(glClearColors.default)
  }

  updateRenderData(): VaoData {
    clearBuffers(this.vaoData)
    const gl = this.context.getGl()
    const material = this.context.getMaterial(MaterialType.ellipseInnerShadow)
    const vao = gl.createVertexArray()
    const buffers: { [key: string]: VertexBuffer } = {}
    const uniforms: { [key: string]: UniformValue } = {}

    const { blur, sourceRect, bb, bbRounding } = this.data

    this.updateEllipse()

    const aPosition = new Float32Array(
      rectToQuadTriangles({
        x: sourceRect.x - blur,
        y: sourceRect.y - blur,
        w: sourceRect.w + blur * 2,
        h: sourceRect.h + blur * 2,
      })
    )
    const uBlur = blur
    const uGaussianWeights = calcGaussianWeights(blur)
    const uSourceRect = [...Object.values(sourceRect)]
    const uBB = calcBoundingBox(bb)
    const uBBRounding = calcRounding(uBB[2], uBB[3], bbRounding)

    buffers['aPosition'] = this.context.createVertexBuffer(aPosition, 2)
    uniforms['uBlur'] = uBlur
    uniforms['uGaussianWeights'] = uGaussianWeights
    uniforms['uSourceRect'] = uSourceRect
    uniforms['uBB'] = uBB
    uniforms['uBBRounding'] = uBBRounding
    const verticeCount = aPosition.length / 2

    const vaoData = { vao, material, buffers, uniforms, verticeCount }
    connectBuffersToVao(vaoData)
    return vaoData
  }

  private updateEllipse(): void {
    const { x, y, w, h, color, spread } = this.data

    if (this.ellipse) this.ellipse.cleanup()
    this.ellipse = new WebglEllipse(this.context, {
      x: x + spread,
      y: y + spread,
      w: w - spread * 2,
      h: h - spread * 2,
      fill: { type: 'color', color: color },
    })
    this.ellipse.init()
  }

  cleanup = (): void => {
    if (this.ellipse) this.ellipse.cleanup()
    cleanupVaoData(this.vaoData)
  }
}

export const ellipseInnerShadowVs = `#version 300 es
precision highp float;

uniform mat3 uMatrix;

in vec2 aPosition;

out vec2 vPosition;
out vec2 vTexCoords;

void main() {
  vec3 transformedPosition = uMatrix * vec3(aPosition, 1);
  gl_Position = vec4(transformedPosition.xy, 0, 1);

  vPosition = aPosition;
  vTexCoords = vec2(transformedPosition.x / 2.0 + 0.5, transformedPosition.y / 2.0 + 0.5);
}
`

export const ellipseInnerShadowFs = `#version 300 es
precision highp float;

uniform vec2 uTexelSize;

uniform sampler2D uSampler;
uniform float uGaussianWeights[101];
uniform float uPass;
uniform float uBlur;
uniform vec4 uSourceRect;
uniform vec4 uBB;
uniform vec4 uBBRounding;

in vec2 vPosition;
in vec2 vTexCoords;

out vec4 outColor;

${isPointInBox}
${isPointInEllipse}

void main() {
  if (uPass == 2.0 && (!isPointInBox(vPosition, uBB, uBBRounding) || !isPointInEllipse(vPosition, uSourceRect))) {
    discard;
  }

  vec4 color = texture(uSampler, vTexCoords) * uGaussianWeights[0];
  vec2 offsetDirection = uPass == 1.0 ? vec2(1.0, 0.0) : vec2(0.0, 1.0);
  const int MAX_BLUR_RADIUS = 100;

  for (int i = 1; i < MAX_BLUR_RADIUS; i++) {
    if (i > int(uBlur)) {
      break;
    }
    vec2 offset = vec2(float(i) * uTexelSize.x, float(i) * uTexelSize.y) * offsetDirection;
    
    color += texture(uSampler, vTexCoords + offset) * (uGaussianWeights[i]);
    color += texture(uSampler, vTexCoords - offset) * (uGaussianWeights[i]);
  }

  outColor = color;
}
`
