import { TextAttributeFactory } from 'application/action/creation/attributes/text'
import { ClientUpdateListener, Update } from 'application/client'
import { ReadOnlyDocumentSelection } from 'application/selection'

export class DefaultTextAttributes implements ClientUpdateListener {
  private documentSelection: ReadOnlyDocumentSelection
  private textAttributeFactory: TextAttributeFactory

  constructor(
    documentSelection: ReadOnlyDocumentSelection,
    textAttributeFactory: TextAttributeFactory
  ) {
    this.documentSelection = documentSelection
    this.textAttributeFactory = textAttributeFactory
  }

  getTypes = (): Update['type'][] => {
    return ['selection', 'node_updated']
  }

  onUpdate = (update: Update[]): void => {
    let shouldUpdate = false
    for (const u of update) {
      switch (u.type) {
        case 'selection':
          shouldUpdate = true
          break
        case 'node_updated':
          const selected = this.documentSelection.getSelected()
          if (selected.length === 0) break
          if (
            selected.some((n) => n.getBaseAttribute('type') === 'paragraph') ||
            selected.some((n) => n.getBaseAttribute('type') === 'button') ||
            selected.some((n) => n.getBaseAttribute('type') === 'anchor')
          ) {
            shouldUpdate = true
          }
          break
      }
    }
    if (shouldUpdate) this.setDefaultAttributes()
  }

  private setDefaultAttributes = (): void => {
    const selected = this.documentSelection.getSelected()
    const lastSelectedText = selected.find(
      (n) =>
        n.getBaseAttribute('type') === 'paragraph' ||
        n.getBaseAttribute('type') === 'button' ||
        n.getBaseAttribute('type') === 'anchor'
    )
    if (!lastSelectedText) return

    this.textAttributeFactory.setDefaultAttributes(
      lastSelectedText.getStyleAttributes()
    )
  }
}
