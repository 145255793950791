import { DesignColor } from 'themes'

interface ImageDisplayProps {
  src: string
  onDrop?: (e: React.DragEvent<HTMLDivElement>) => void
}

export default function ImageDisplay({ src, onDrop }: ImageDisplayProps) {
  return (
    <div
      style={{
        width: 232,
        height: 232,
        boxSizing: 'border-box',
        padding: '8px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        boxShadow: `inset 0px -1px 0px 0px ${DesignColor(
          'panelBorder'
        )}, inset -1px 0px 0px 0px ${DesignColor(
          'panelBorder'
        )}, inset 1px 0px 0px 0px ${DesignColor('panelBorder')}`,
      }}
      onDragOver={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
      onDragEnter={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
      onDragLeave={(e) => {
        e.preventDefault()
        e.stopPropagation()
      }}
      onDrop={(e) => {
        e.preventDefault()
        e.stopPropagation()
        if (onDrop) onDrop(e)
      }}
    >
      <ImageBox src={src} />
    </div>
  )
}

function ImageBox({ src }: { src: string }) {
  return (
    <img
      alt="Display"
      src={src}
      style={{
        width: 216,
        height: 216,
        objectFit: 'contain',
        backgroundImage: `url(${process.env.PUBLIC_URL}/images/ImageBackground.svg)`,
      }}
    />
  )
}
