import {
  isUnitSize,
  isUnitSize2,
  isUnitSize4,
  Properties,
  UnitSize,
  UnitSize2,
  UnitSize4,
  unitSizeToString,
} from 'application/export/css'
import { TailwindClassRenderer } from '../types'

export class TailwindClassRadius implements TailwindClassRenderer {
  render = (properties: Partial<Properties>): string[] => {
    const classes: string[] = []

    const radius = properties.borderRadius

    if (radius !== undefined) {
      classes.push(...this.renderRadius(radius))
    }

    return classes
  }

  private renderRadius = (
    radius: UnitSize | UnitSize2 | UnitSize4 | 'inherit'
  ): string[] => {
    if (isUnitSize(radius)) {
      return [`rounded-[${unitSizeToString(radius)}]`]
    } else if (isUnitSize2(radius)) {
      return [
        `rounded-tl-[${unitSizeToString(radius[0])}]`,
        `rounded-br-[${unitSizeToString(radius[0])}]`,
        `rounded-tr-[${unitSizeToString(radius[1])}]`,
        `rounded-bl-[${unitSizeToString(radius[1])}]`,
      ]
    } else if (isUnitSize4(radius)) {
      return [
        `rounded-tr-[${unitSizeToString(radius[0])}]`,
        `rounded-br-[${unitSizeToString(radius[1])}]`,
        `rounded-bl-[${unitSizeToString(radius[2])}]`,
        `rounded-tl-[${unitSizeToString(radius[3])}]`,
      ]
    }
    return []
  }
}
