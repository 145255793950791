import { DesignColor } from 'themes'
import Text, { textStyles } from 'components/Library/Text/Text'
import { useState } from 'react'

interface RightClickRowButtonProps {
  text: string
  subtext?: string

  styles?: textStyles

  selected?: boolean

  onClick?: () => void
}

export default function RightClickRowButton({
  text,
  subtext,
  styles,
  selected = false,
  onClick,
}: RightClickRowButtonProps) {
  const [hover, setHover] = useState(false)

  const background = getBackground(selected)
  const boxShadow = getBoxShadow(hover)

  return (
    <div
      style={{
        width: 232,
        height: 24,
        padding: '0px 16px',
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        background,
        boxShadow,
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}
    >
      <Text color={DesignColor('text1')} styles={styles}>
        {text}
      </Text>
      {subtext && <Text color={DesignColor('text2')}>{subtext}</Text>}
    </div>
  )
}

function getBoxShadow(hover: boolean) {
  if (hover) return `inset 0px 0px 0px 1px ${DesignColor('inputHighlight')}`
  return 'none'
}

function getBackground(selected: boolean) {
  if (selected) return DesignColor('inputTint')
  return 'none'
}
