import { fonts } from 'assets/fonts'
import { FontData, FontKey, FontDataMap } from './types'

export class FontDataMapMock implements FontDataMap {
  getFontData = (family: FontKey): FontData | null => {
    return fonts[family]
  }

  isInternalFont = (family: FontKey): boolean => {
    return !!fonts[family]
  }

  addProjectFont = () => {}

  removeProjectFont = () => {}
}
