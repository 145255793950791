import { DesignColor } from 'themes'
import { truncate } from 'application/math'
import { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'

interface NumberInputProps {
  width: number

  dragging: boolean
  editing: boolean
  setEditing: (editing: boolean) => void

  value: 'Mixed' | number | undefined
  setValue: (value: number) => void
  increment: (value: number) => void
  decrement: (value: number) => void
  setEmpty?: () => void

  unit?: string

  step?: number
  min?: number
  max?: number
  decimals?: number

  disabled?: boolean
  dim?: boolean
  empty?: boolean
  hasOverride?: boolean
}

export default function NumberInput({
  width,
  dragging,
  editing,
  setEditing,
  value,
  setValue,
  increment,
  decrement,
  setEmpty,
  unit = '',
  step = 1,
  min = -Infinity,
  max = Infinity,
  decimals = 1,
  disabled = false,
  dim = false,
  empty = false,
  hasOverride = false,
}: NumberInputProps) {
  const [localValue, setLocalValue] = useState(toStringValue(value, decimals))
  const [initialValue, setInitialValue] = useState(value)
  const [select, setSelect] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)
  const placeholder = getPlaceholder(value)

  const handleSetEmpty = () => {
    setLocalValue('-')
    setEmpty?.()
  }

  const handleBlur = () => {
    const parsed = parse(localValue, value)
    if (parsed === undefined && setEmpty) {
      if (initialValue !== undefined) {
        handleSetEmpty()
      }
    } else if (parsed !== 'Mixed' && parsed !== undefined) {
      const formatted = truncate(clampValue(parsed, min, max), decimals)
      if (formatted !== initialValue) {
        setValue(formatted)
      }
    }
    setEditing(false)
  }

  const handleFocus = () => {
    if (disabled) return
    if (!editing) setEditing(true)
  }

  const handleMouseDown = (
    e: React.MouseEvent<HTMLInputElement, MouseEvent>
  ) => {
    if (disabled || e.button === 2 || e.ctrlKey || e.metaKey) {
      e.preventDefault()
    }
  }

  const handleMouseUp = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    if (disabled || e.button === 2 || e.ctrlKey || e.metaKey) {
      e.preventDefault()
      return
    }
    if (!select && inputRef.current) {
      e.preventDefault()
      inputRef.current.select()
      setSelect(true)
      if (!editing) setEditing(true)
    }
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation()
    switch (e.key) {
      case 'Enter':
        inputRef.current?.blur()
        break
      case 'ArrowUp':
        e.preventDefault()
        const incrementValue = e.shiftKey ? step * 10 : step
        increment(incrementValue)
        break
      case 'ArrowDown':
        e.preventDefault()
        const decrementValue = e.shiftKey ? step * 10 : step
        decrement(decrementValue)
        break
    }
  }

  useEffect(() => {
    setLocalValue(toStringValue(value, decimals))
    setInitialValue(value)
    setSelect(false)
  }, [dragging, editing, value, decimals])

  return (
    <StyledInput
      ref={inputRef}
      type="text"
      placeholder={placeholder}
      value={
        empty && localValue === placeholder
          ? ''
          : editing
          ? localValue
          : value === undefined
          ? '-'
          : value !== 'Mixed'
          ? truncate(value, decimals) + unit
          : 'Mixed'
      }
      onChange={(e) => setLocalValue(e.target.value)}
      onClick={(e) => e.stopPropagation()}
      onContextMenu={(e) => e.preventDefault()}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onKeyDown={handleKeyDown}
      onMouseUp={handleMouseUp}
      onMouseDown={handleMouseDown}
      disabled={disabled}
      width={width}
      textColor={getTextColor(hasOverride, disabled, empty, editing)}
      placeholderColor={getPlaceholderColor(hasOverride)}
      dim={dim}
    />
  )
}

const StyledInput = styled.input<{
  width: number
  textColor: string
  placeholderColor: string
  dim?: boolean
}>`
  width: ${(props) => props.width}px;
  height: 100%;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  border: none;
  outline: none;
  background: transparent;
  color: ${(props) => props.textColor};
  font-family: Inter;
  font-weight: regular;
  font-size: 11px;
  cursor: default;
  &::placeholder {
    color: ${(props) => props.placeholderColor};
  }
`

function getTextColor(
  override: boolean,
  disabled: boolean,
  empty: boolean,
  editing: boolean
): string {
  if (override && editing) return DesignColor('overrideParent')
  if (override && (disabled || empty)) return DesignColor('overrideParent2')
  if (override) return DesignColor('overrideParent')
  if (editing) return DesignColor('text1')
  if (disabled || empty) return DesignColor('text3')
  return DesignColor('text1')
}

function getPlaceholder(value: 'Mixed' | number | undefined): string {
  if (value === undefined) return ''
  return value.toString()
}

function getPlaceholderColor(override: boolean): string {
  if (override) return DesignColor('overrideParent2')
  return DesignColor('text3')
}

function toStringValue(
  value: 'Mixed' | number | undefined,
  decimals: number
): string {
  if (value === 'Mixed') return 'Mixed'
  if (value === undefined) return '-'
  return truncate(value, decimals).toString()
}

function clampValue(
  value: number,
  min: number | undefined,
  max: number | undefined
) {
  if (min !== undefined && value < min) return min
  if (max !== undefined && value > max) return max
  return value
}

function parse(v: string, current: 'Mixed' | number | undefined) {
  const parsedFormula = parseFormula(v)
  if (parsedFormula !== null) return parsedFormula
  if (v === '-' || v === '') return undefined
  if (v === 'Mixed') return current
  const parsed = parseFloat(v)
  if (isNaN(parsed)) return current
  return parsed
}

function parseFormula(v: string): number | null {
  const operatorsFound: string[] = []
  const operatorIndex: number[] = []

  for (let i = 0; i < v.length; i++) {
    if (v[i] === '+' || v[i] === '-' || v[i] === '*' || v[i] === '/') {
      operatorsFound.push(v[i])
      operatorIndex.push(i)
    }
  }

  if (
    operatorsFound.length === 0 ||
    operatorsFound.length !== operatorIndex.length
  ) {
    return null
  }

  const parts: string[] = []
  let start = 0
  for (let i = 0; i < operatorIndex.length; i++) {
    parts.push(v.slice(start, operatorIndex[i]))
    start = operatorIndex[i] + 1
  }
  parts.push(v.slice(start, v.length))

  const isNegative = operatorsFound[0] === '-' && parts[0] === ''

  let total = 0
  for (let i = 0; i < parts.length; i++) {
    if (!isNegative || i !== 0) {
      const parsed = parseFloat(parts[i])
      if (isNaN(parsed)) return null
      if (i === 0) {
        total = parsed
      } else {
        switch (operatorsFound[i - 1]) {
          case '+':
            total += parsed
            break
          case '-':
            total -= parsed
            break
          case '*':
            total *= parsed
            break
          case '/':
            total /= parsed
            break
        }
      }
    }
  }

  return total
}
