import {
  AttributeCursor,
  AttributeType,
  MultiselectStyleMap,
} from 'application/attributes'
import { StyleAttributePanel } from './styleAttributePanel'
import { ReadOnlyNode } from 'application/node'

type CursorPanelKeys = 'cursor'

type CursorPanelAttributes = Pick<MultiselectStyleMap, CursorPanelKeys> | null

export interface CursorPanelState {
  attributes: CursorPanelAttributes
  mode: 'mixed' | 'add' | 'remove' | 'none'
}

export interface CursorPanelHandlers {
  addCursor: () => void
  removeCursor: () => void
  setStyle: (style: AttributeCursor) => void
  clearMixed: () => void
}

export class CursorPanel extends StyleAttributePanel<
  CursorPanelState,
  CursorPanelHandlers,
  CursorPanelKeys
> {
  getSettings(): CursorPanelState {
    return {
      attributes: this.attributes,
      mode: this.getMode(),
    }
  }

  getHandlers(): CursorPanelHandlers {
    return {
      addCursor: this.addCursor,
      removeCursor: this.removeCursor,
      setStyle: this.setStyle,
      clearMixed: this.clearMixed,
    }
  }

  private getMode = (): 'mixed' | 'add' | 'remove' => {
    const nodes = this.getNodes()

    let cursor: AttributeCursor | undefined = undefined
    for (const node of nodes) {
      const mode = node.getStyleAttribute('cursor')
      if (cursor && mode !== cursor) return 'mixed'
      cursor = mode
    }

    if (cursor === undefined) return 'add'
    return 'remove'
  }

  private addCursor = (): void => {
    this.setMulti({ cursor: 'auto' })
    this.commit()
  }

  private removeCursor = (): void => {
    const nodes = this.getNodes()
    for (const node of nodes) {
      this.setOne(node.getId(), { cursor: undefined })
    }
    this.commit()
  }

  private setStyle = (style: AttributeCursor): void => {
    this.setMulti({ cursor: style })
    this.commit()
  }

  private clearMixed = (): void => {
    const nodes = this.getNodes()
    let cursor: AttributeCursor | undefined = undefined
    for (const node of nodes) {
      const style = node.getStyleAttribute('cursor')
      if (style === undefined) continue
      cursor = style
      break
    }
    if (cursor === undefined) return

    this.setMulti({ cursor })
    this.commit()
  }

  protected override getNodeFilterPredicate = (): ((
    node: ReadOnlyNode,
    parent: ReadOnlyNode | null
  ) => boolean) => {
    return (node, _) => allowedTypes.includes(node.getBaseAttribute('type'))
  }

  protected override getSlideMin = (): number => {
    return 0
  }

  protected override getSlideMax = (): number => {
    return 20
  }
}

const allowedTypes: AttributeType[] = [
  'frame',
  'paragraph',
  'button',
  'image',
  'input',
  'form',
  'anchor',
]
