import { PasteSnapshot } from '../../types'
import { Point, Rectangle } from 'application/shapes'
import { CameraService } from 'application/camera'
import { PastePositionHandler } from '../paste'
import { computeSelectionRectangle } from 'application/selection'
import { moveTree, setAttributes } from './utils'

export class PasteAtCanvasCenter implements PastePositionHandler {
  private cameraService: CameraService

  constructor(cameraService: CameraService) {
    this.cameraService = cameraService
  }

  paste = (snapshot: PasteSnapshot): void => {
    const window = this.getWindow(snapshot)
    if (!window) return

    const canvas = this.cameraService.getViewableRectangle()
    const centered = this.centerChild(window, canvas)

    for (const id of snapshot.ids) {
      const node = snapshot.nodes[id]
      if (!node) continue

      const xDelta = node.getBaseAttribute('x') - centered.x
      const yDelta = node.getBaseAttribute('y') - centered.y

      setAttributes(
        node,
        {
          'position.top.unit': 'px',
          'position.left.unit': 'px',
          'position.top.px': centered.y,
          'position.left.px': centered.x,
        },
        {
          x: centered.x,
          y: centered.y,
        }
      )

      moveTree(node, snapshot, xDelta, yDelta)
    }
  }

  private getWindow = (snapshot: PasteSnapshot): Rectangle | null => {
    const nodes = snapshot.ids.map((id) => snapshot.nodes[id])
    return computeSelectionRectangle(nodes)
  }

  private centerChild = (child: Rectangle, parent: Rectangle): Point => {
    return {
      x: parent.x + (parent.w - child.w) / 2,
      y: parent.y + (parent.h - child.h) / 2,
    }
  }
}
