import { ScreenSizeType, StandardScreens, UniqueScreens } from './data'
import { FlatLine, Point, Rectangle, ScreenSize } from './shapes'

export function encapsulates(r1: Rectangle, r2: Rectangle): boolean {
  return (
    r1.x <= r2.x &&
    r1.y <= r2.y &&
    r1.x + r1.w >= r2.x + r2.w &&
    r1.y + r1.h >= r2.y + r2.h
  )
}

export function intersects(r1: Rectangle, r2: Rectangle): boolean {
  return (
    r1.x < r2.x + r2.w &&
    r1.x + r1.w > r2.x &&
    r1.y < r2.y + r2.h &&
    r1.y + r1.h > r2.y
  )
}

export function pointInRectangle(p: Point, r: Rectangle): boolean {
  return p.x >= r.x && p.x <= r.x + r.w && p.y >= r.y && p.y <= r.y + r.h
}

export function pointsToRectangle(
  p1: Point,
  p2: Point,
  round: boolean = false
): Rectangle {
  return {
    x: round ? Math.round(Math.min(p1.x, p2.x)) : Math.min(p1.x, p2.x),
    y: round ? Math.round(Math.min(p1.y, p2.y)) : Math.min(p1.y, p2.y),
    w: round ? Math.round(Math.abs(p2.x - p1.x)) : Math.abs(p2.x - p1.x),
    h: round ? Math.round(Math.abs(p2.y - p1.y)) : Math.abs(p2.y - p1.y),
  }
}

export function lineToRectangle(line: FlatLine, scale: number): Rectangle {
  const scaled = line.thickness / scale
  let x = 0
  let y = 0
  let w = 0
  let h = 0

  switch (line.direction) {
    case 'h':
      x = Math.min(line.p1, line.p2)
      y = line.counter - scaled / 2
      w = Math.abs(line.p2 - line.p1)
      h = scaled
      break
    case 'v':
      x = line.counter - scaled / 2
      y = Math.min(line.p1, line.p2)
      w = scaled
      h = Math.abs(line.p2 - line.p1)
      break
  }

  return { x, y, w, h }
}

export function getScreenSize(w: number): ScreenSize | null {
  const modes: ScreenSizeType[] = ['desktop', 'tablet', 'phone']
  for (const mode of modes) {
    const screens = UniqueScreens[mode]
    for (const screen of screens) {
      if (w === screen.w) return screen
    }
  }
  for (const mode of modes) {
    const screens = StandardScreens[mode]
    for (const screen of screens) {
      if (w === screen.w) return screen
    }
  }
  return null
}
