import { StyleMap } from 'application/attributes'
import Text from 'components/Library/Text/Text'
import { Portal } from 'components/Pages/Editor/PortalRoot/PortalRoot'
import { OverrideSectionKey } from 'editor/ui/styleOverrides'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import useClosePopup from 'hooks/ui/useClosePopup'
import useHovered from 'hooks/ui/useHovered'
import { editor } from 'index'
import { useRef, useState } from 'react'
import { DesignColor } from 'themes'

interface OverrideResetProps {
  keys?: (keyof StyleMap)[]
  section?: OverrideSectionKey
  all?: boolean

  children?: React.ReactNode
}

export default function OverrideReset({
  keys = [],
  section,
  all = false,
  children,
}: OverrideResetProps) {
  const overrides = usePanelHelper(editor.getUI().getStyleOverridesPanel())
  const wrapperRef = useRef<HTMLDivElement>(null)
  const popupRef = useRef<HTMLDivElement>(null)
  const [open, setOpen] = useState(false)
  const [position, setPosition] = useState({ x: 0, y: 0 })
  const { hovered, setHovered } = useHovered<boolean>({ ref: popupRef })
  const {
    hasOverride,
    sectionHasOverride,
    hasAnyOverride,
    resetOverride,
    resetOverrideSection,
    resetAll,
  } = overrides.handlers

  useClosePopup({
    ref: popupRef,
    close: () => {
      if (open) setOpen(false)
    },
  })

  const handleOpen = () => {
    if (!all && !section && keys.length === 0) return
    if (section && !sectionHasOverride(section)) return
    if (keys.length > 0 && !hasOverride(keys)) return
    if (all && !hasAnyOverride()) return
    setPosition(getPosition(wrapperRef))
    setOpen(true)
  }

  const handleClick = () => {
    if (all) {
      resetAll()
    } else if (section) {
      resetOverrideSection(section)
    } else {
      resetOverride(keys)
    }
    setOpen(false)
  }

  return (
    <>
      <div
        ref={wrapperRef}
        onMouseDown={(e) => {
          if (e.button === 2) {
            e.preventDefault()
            handleOpen()
          }
        }}
        onContextMenu={(e) => {
          e.preventDefault()
          handleOpen()
        }}
      >
        {children}
      </div>
      {open && (
        <Portal>
          <div
            ref={popupRef}
            style={{
              position: 'fixed',
              top: position.y,
              left: position.x,
              width: 120,
              height: 24,
              padding: '0 8px',
              boxSizing: 'border-box',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
              borderRadius: 4,
              background: DesignColor('panelBackground'),
              boxShadow: `inset 0 0 0 1px ${DesignColor('panelBorder')}`,
              cursor: 'pointer',
              pointerEvents: 'all',
              zIndex: 101,
            }}
            onClick={handleClick}
            onMouseDown={(e) => e.stopPropagation()}
            onMouseUp={(e) => e.stopPropagation()}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            <Text
              color={DesignColor(hovered ? 'inputHighlight' : 'text1')}
              styles={{ cursor: 'pointer' }}
            >
              Reset Override
            </Text>
          </div>
        </Portal>
      )}
    </>
  )
}

function getPosition(wrapperRef: React.RefObject<HTMLDivElement>): {
  x: number
  y: number
} {
  if (wrapperRef.current) {
    const rect = wrapperRef.current.getBoundingClientRect()
    let x = rect.x
    let y = rect.y + rect.height + 4

    if (y + 24 > window.innerHeight) {
      y = rect.y - 24 - 4
    } else if (x + 120 > window.innerWidth) {
      x = rect.x + rect.width - 120
    }

    return { x, y }
  }
  return { x: 0, y: 0 }
}
