import { FlexStyle } from './flex'
import { BackgroundStyle } from './background'
import { BorderStyle } from './border'
import { CursorStyle } from './cursor'
import { DisplayStyle } from './display'
import { FilterStyle } from './filter'
import { FlexChildStyle } from './flexChild'
import { ImageStyle } from './image'
import { OpacityStyle } from './opacity'
import { PaddingStyle } from './padding'
import { PositionStyle } from './position'
import { RoundingStyle } from './rounding'
import { ShadowStyle } from './shadow'
import { SizeStyle } from './size'
import { TextStyle } from './text'
import { TransitionStyle } from './transition'
import { AttributeTransformer } from './types'
import { ReadOnlyNode } from 'application/node'
import { HTMLDocument } from '../html/types'
import { ReadOnlyDocument } from 'application/document'
import { HTMLAttributes } from './attributes'

export class StyleTransformer {
  private transformers: AttributeTransformer[]

  constructor() {
    this.transformers = [
      new PositionStyle(),
      new SizeStyle(),
      new FlexStyle(),
      new TextStyle(),
      new BackgroundStyle(),
      new RoundingStyle(),
      new BorderStyle(),
      new ShadowStyle(),
      new FilterStyle(),
      new FlexChildStyle(),
      new ImageStyle(),
      new DisplayStyle(),
      new PaddingStyle(),
      new OpacityStyle(),
      new CursorStyle(),
      new TransitionStyle(),
      new HTMLAttributes(),
    ]
  }

  transform(
    node: ReadOnlyNode,
    htmlDocument: HTMLDocument,
    document: ReadOnlyDocument
  ): void {
    for (const transformer of this.transformers) {
      transformer.transformBase(node, htmlDocument, document)
    }
  }
}
