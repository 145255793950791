import {
  Context,
  UniformValue,
  VaoData,
  VertexBuffer,
  calcBoundingBox,
  calcOuterShadowPosition,
  calcRounding,
  isPointInBox,
} from 'application/render'
import { Rectangle } from 'application/shapes'
import { Color, rgbaToWebgl } from 'application/color'
import {
  cleanupVaoData,
  clearBuffers,
  connectBuffersToVao,
  createEmptyVaoData,
  standardDraw,
} from '../utils'
import { MaterialType } from './shaderMap'

export type WebglRectangleOuterShadowData = Rectangle & {
  color: Color
  blur: number
  spread: number
  sourceRect: Rectangle
  rounding?: { tl: number; tr: number; br: number; bl: number }
  bb?: Rectangle
  bbRounding?: { tl: number; tr: number; br: number; bl: number }
}

export class WebglRectangleOuterShadow {
  private context: Context
  private data: WebglRectangleOuterShadowData
  private vaoData: VaoData

  constructor(context: Context, data: WebglRectangleOuterShadowData) {
    this.context = context
    this.data = data
    this.vaoData = createEmptyVaoData()
  }

  init = (): void => {
    this.vaoData = this.updateRenderData()
  }

  draw(): void {
    standardDraw(this.vaoData)
  }

  updateRenderData(): VaoData {
    clearBuffers(this.vaoData)
    const gl = this.context.getGl()
    const material = this.context.getMaterial(MaterialType.rectangleOuterShadow)
    const vao = gl.createVertexArray()
    const buffers: { [key: string]: VertexBuffer } = {}
    const uniforms: { [key: string]: UniformValue } = {}

    const {
      x,
      y,
      w,
      h,
      color,
      blur,
      spread,
      sourceRect,
      rounding,
      bb,
      bbRounding,
    } = this.data

    const aPosition = new Float32Array(
      calcOuterShadowPosition(this.data, spread, blur)
    )
    const uColor = rgbaToWebgl(color)
    const uBlur = blur
    const uRect = [x - spread, y - spread, w + spread * 2, h + spread * 2]
    const uRounding = calcRounding(w, h, rounding)
    const uSourceRect = [...Object.values(sourceRect)]
    const uBB = calcBoundingBox(bb)
    const uBBRounding = calcRounding(uBB[2], uBB[3], bbRounding)

    buffers['aPosition'] = this.context.createVertexBuffer(aPosition, 2)
    uniforms['uColor'] = uColor
    uniforms['uBlur'] = uBlur
    uniforms['uRect'] = uRect
    uniforms['uRounding'] = uRounding
    uniforms['uSourceRect'] = uSourceRect
    uniforms['uBB'] = uBB
    uniforms['uBBRounding'] = uBBRounding
    const verticeCount = aPosition.length / 2

    const vaoData = { material, vao, buffers, uniforms, verticeCount }
    connectBuffersToVao(vaoData)
    return vaoData
  }

  cleanup(): void {
    cleanupVaoData(this.vaoData)
  }
}

export const rectangleOuterShadowVs = `#version 300 es
precision highp float;

uniform mat3 uMatrix;

in vec2 aPosition;

out vec2 vPosition;

void main() {
  vec3 transformedPosition = uMatrix * vec3(aPosition, 1);
  gl_Position = vec4(transformedPosition.xy, 0, 1);

  vPosition = aPosition;
}
`

export const rectangleOuterShadowFs = `#version 300 es
precision mediump float;

uniform vec4 uColor;
uniform float uBlur;
uniform vec4 uRounding;
uniform vec4 uRect;
uniform vec4 uSourceRect;
uniform vec4 uBB;
uniform vec4 uBBRounding;

in vec2 vPosition;

out vec4 outColor;

${isPointInBox}

float gaussian(float x, float sigma) {
  const float pi = 3.141592653589793;
  return exp(-(x * x) / (2.0 * sigma * sigma)) / (sqrt(2.0 * pi) * sigma);
}

// Approximate error function for gaussian integral
vec2 erf(vec2 x) {
  vec2 s = sign(x), a = abs(x);
  x = 1.0 + (0.278393 + (0.230389 + 0.078108 * (a * a)) * a) * a;
  x *= x;
  return s - s / (x * x);
}

// Return the blurred mask along the x dimension
float roundedBoxShadowX(float x, float y, float sigma, float corner, vec2 halfSize) {
  float delta = min(halfSize.y - corner - abs(y), 0.0);
  float curved = halfSize.x - corner + sqrt(max(0.0, corner * corner - delta * delta));
  vec2 integral = 0.5 + 0.5 * erf((x + vec2(-curved, curved)) * (sqrt(0.5) / sigma));
  return integral.y - integral.x;
}

// Return the mask for the shadow of a box from lower to upper
float roundedBoxShadow(vec2 point, float corner) {
  float sigma = uBlur / 2.0;

  // Center everything to make the math easier
  vec2 center = uRect.xy + uRect.zw * 0.5;
  vec2 halfSize = uRect.zw * 0.5;
  point -= center;

  // Limit samples to non-zero range
  float low = point.y - halfSize.y;
  float high = point.y + halfSize.y;
  float start = clamp(-3.0 * sigma, low, high);
  float end = clamp(3.0 * sigma, low, high);

  // Accumulate samples
  float step = (end - start) / 4.0;
  float y = start + step * 0.5;
  float value = 0.0;
  for (int i = 0; i < 4; i++) {
    value += roundedBoxShadowX(point.x, point.y - y, sigma, corner, halfSize) * gaussian(y, sigma) * step;
    y += step;
  }

  return value;
}

void main() {
  if (
    !isPointInBox(vPosition, uBB, uBBRounding) ||
    isPointInBox(vPosition, uSourceRect, uRounding)
  ) {
    discard;
  }

  float cornerRadius = uRounding.x;
  if (uBlur == 0.0 && isPointInBox(vPosition, uRect, uRounding)) {
    outColor = uColor;
    return;
  }

  float shadowIntensity = roundedBoxShadow(vPosition, cornerRadius);
  outColor = vec4(uColor.rgb, uColor.a * shadowIntensity);
}
`
