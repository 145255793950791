import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import ResizePanelContainer from 'components/Library/Containers/ResizePanelContainer/ResizePanelContainer'
import ScrollContainer from 'components/Library/Containers/ScrollContainer/ScrollContainer'
import TextBlock from 'components/Library/Components/Text/TextBlock/TextBlock'
import IconBlock from 'components/Library/Components/IconBlock/IconBlock/IconBlock'
import Layers from './Layers/Layers'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import Tooltip from 'components/Library/Components/Tooltip/Tooltip'
import persistAtom from 'components/State/persistAtom'
import { atom, useRecoilState } from 'recoil'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'
import { useRef } from 'react'

export const layersWidth = atom<number>(
  persistAtom({
    key: 'layersWidth',
    default: 232,
    persistMode: 'local',
  })
)

export default function LeftSidepanel() {
  const layersHelper = usePanelHelper(editor.getUI().getLayersPanel())

  const panelRef = useRef<HTMLDivElement>(null)
  const [width, setWidth] = useRecoilState(layersWidth)

  const { toggleCollapsed } = layersHelper.handlers

  if (!layersHelper.settings) return <></>

  return (
    <ResizePanelContainer
      position={{
        position: 'absolute',
        top: 64,
        left: 8,
        zIndex: 1,
      }}
      height={'calc(100vh - 74px)'}
      width={width}
      setWidth={setWidth}
    >
      <PanelSection
        sectionRef={panelRef}
        bottom={true}
        width="fill"
        height="fill"
        paddingB={0}
      >
        <PanelRow width={'fill'}>
          <TextBlock width={'fill'} mode={'title'}>
            Layers
          </TextBlock>
          <Tooltip tooltipKey="CollapseLayers">
            <IconBlock
              icon="CollapseLayers"
              onClick={toggleCollapsed}
              iconRotation={layersHelper.settings.collapsed ? 180 : 0}
            />
          </Tooltip>
        </PanelRow>
        <ScrollContainer>
          <Layers containerRef={panelRef} />
        </ScrollContainer>
      </PanelSection>
    </ResizePanelContainer>
  )
}
