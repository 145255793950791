export interface HotkeyHandler {
  getKeys: () => string[]
  handle: (e: KeyboardEvent) => void
  canTriggerMulti: () => boolean
}

export class HotkeyActionHandler {
  private handlers: HotkeyHandler[]
  private downKey: string | null = null

  constructor(handlers: HotkeyHandler[]) {
    this.handlers = handlers
  }

  reset = () => {
    this.downKey = null
  }

  handleKeyDown = (e: KeyboardEvent) => {
    for (const handler of this.handlers) {
      if (handler.getKeys().includes(e.key)) {
        if (
          this.downKey === e.key &&
          handler.canTriggerMulti &&
          handler.canTriggerMulti()
        ) {
          handler.handle(e)
        } else if (this.downKey !== e.key) {
          handler.handle(e)
        }
      }
    }
    this.downKey = e.key
  }

  handleKeyUp = () => {
    this.downKey = null
  }
}
