import { ReadOnlyNode } from 'application/node'
import { HTMLDocument } from '../html/types'
import { createSelector } from '../utils'
import { AttributeTransformer } from './types'
import { UnitLength } from '../css'
import _ from 'lodash'

export class PaddingStyle implements AttributeTransformer {
  transformBase = (node: ReadOnlyNode, htmlDocument: HTMLDocument): void => {
    const selector = createSelector(node.getId(), 'default')
    const style = htmlDocument.getStyles().get(selector)

    const top = this.getSide(node, 'top')
    const bottom = this.getSide(node, 'bottom')
    const left = this.getSide(node, 'left')
    const right = this.getSide(node, 'right')
    if (
      top.value === 0 &&
      bottom.value === 0 &&
      left.value === 0 &&
      right.value === 0
    ) {
      return
    }

    const topEqualsBottom = _.isEqual(top, bottom)
    const leftEqualsRight = _.isEqual(left, right)
    const topEqualsRight = _.isEqual(top, right)
    const bottomEqualsLeft = _.isEqual(bottom, left)
    const allEqual =
      topEqualsBottom && leftEqualsRight && topEqualsRight && bottomEqualsLeft

    if (allEqual) {
      style.setProperty('padding', top)
    } else if (topEqualsBottom && leftEqualsRight) {
      style.setProperty('padding', [top, right])
    } else {
      style.setProperty('padding', [top, right, bottom, left])
    }
  }

  private getSide(
    node: ReadOnlyNode,
    side: 'top' | 'bottom' | 'left' | 'right'
  ): UnitLength {
    const unit = node.getDefaultSelector().styles[`padding.${side}.unit`]
    switch (unit) {
      case 'px':
        const value = node.getDefaultSelector().styles[`padding.${side}.px`]
        if (value === undefined) return { unit: 'px', value: 0 }
        return { unit, value }
    }
    return { unit: 'px', value: 0 }
  }
}
