import { SizeContext } from '../context/sizeContext'
import { ChildSizeCache, SizeCache } from './contextCache'

export abstract class CachedItem {
  private sizeCache: SizeCache = new SizeCache()
  private childSizeCache: ChildSizeCache = new ChildSizeCache()

  setSizeFromCache = (context: SizeContext): boolean => {
    const cachedSize = this.sizeCache.get(context.encode())
    if (cachedSize) {
      this.setWidth(cachedSize[0])
      this.setHeight(cachedSize[1])
      return true
    }
    return false
  }

  cacheSize = (context: SizeContext): void => {
    this.sizeCache.set(context.encode(), [this.getWidth(), this.getHeight()])
  }

  cacheChildSizes = (context: SizeContext, children: CachedItem[]): void => {
    this.childSizeCache.set(
      context.encode(),
      children.map((child) => [child.getWidth(), child.getHeight()])
    )
  }

  getCachedSize = (context: SizeContext): [number, number] | undefined => {
    return this.sizeCache.get(context.encode())
  }

  clearSizeCache = (): void => {
    this.sizeCache.clear()
  }

  getCachedChildSizes = (
    context: SizeContext
  ): [number, number][] | undefined => {
    return this.childSizeCache.get(context.encode())
  }

  setCachedChildSizes = (
    context: SizeContext,
    sizes: [number, number][]
  ): void => {
    this.childSizeCache.set(context.encode(), sizes)
  }

  clearChildSizeCache = (): void => {
    this.childSizeCache.clear()
  }

  abstract getWidth(): number
  abstract getHeight(): number

  abstract setWidth(width: number): void
  abstract setHeight(height: number): void
}
