import { lineFs, lineVs } from './webglLine'
import { ellipseFs, ellipseVs } from './webglEllipse'
import { ellipseBorderFs, ellipseBorderVs } from './webglEllipseBorder'
import {
  rectangleOuterShadowFs,
  rectangleOuterShadowVs,
} from './webglRectangleOuterShadow'
import {
  rectangleInnerShadowFs,
  rectangleInnerShadowVs,
} from './webglRectangleInnerShadow'
import {
  ellipseOuterShadowFs,
  ellipseOuterShadowVs,
} from './webglEllipseOuterShadow'
import {
  ellipseInnerShadowFs,
  ellipseInnerShadowVs,
} from './webglEllipseInnerShadow'
import { rectangleImageFs, rectangleImageVs } from './webglRectangleImage'
import { rectangleFs, rectangleVs } from './webglRectangle'
import { rectangleBorderFs, rectangleBorderVs } from './webglRectangleBorder'
import { ellipseImageFs, ellipseImageVs } from './webglEllipseImage'
import { textSolidFs, textSolidVs } from './webglText/solid'
import {
  textGradientFillFs,
  textGradientFillVs,
  textGradientGlyphsFs,
  textGradientGlyphsVs,
} from './webglText/gradient'
import { rectangleBlurFs, rectangleBlurVs } from './webglRectangleBlur'
import { transferFs, transferVs } from './webglTransfer'
import { iconFs, iconVs } from './webglIcon'

export enum MaterialType {
  line = 'line',
  icon = 'icon',
  rectangle = 'rectangle',
  rectangleBlur = 'rectangleBlur',
  rectangleBorder = 'rectangleBorder',
  rectangleImage = 'rectangleImage',
  rectangleOuterShadow = 'rectangleOuterShadow',
  rectangleInnerShadow = 'rectangleInnerShadow',
  ellipse = 'ellipse',
  ellipseBorder = 'ellipseBorder',
  ellipseImage = 'ellipseImage',
  ellipseOuterShadow = 'ellipseOuterShadow',
  ellipseInnerShadow = 'ellipseInnerShadow',
  textSolid = 'textSolid',
  textGradientFill = 'textGradientFill',
  textGradientGlyphs = 'textGradientGlyphs',
  transfer = 'transfer',
}

export const shaderMap: {
  [key in MaterialType]: { vs: string; fs: string }
} = {
  [MaterialType.line]: { vs: lineVs, fs: lineFs },
  [MaterialType.icon]: { vs: iconVs, fs: iconFs },
  [MaterialType.rectangle]: { vs: rectangleVs, fs: rectangleFs },
  [MaterialType.rectangleBlur]: { vs: rectangleBlurVs, fs: rectangleBlurFs },
  [MaterialType.rectangleBorder]: {
    vs: rectangleBorderVs,
    fs: rectangleBorderFs,
  },
  [MaterialType.rectangleImage]: { vs: rectangleImageVs, fs: rectangleImageFs },
  [MaterialType.rectangleOuterShadow]: {
    vs: rectangleOuterShadowVs,
    fs: rectangleOuterShadowFs,
  },
  [MaterialType.rectangleInnerShadow]: {
    vs: rectangleInnerShadowVs,
    fs: rectangleInnerShadowFs,
  },
  [MaterialType.ellipse]: { vs: ellipseVs, fs: ellipseFs },
  [MaterialType.ellipseBorder]: { vs: ellipseBorderVs, fs: ellipseBorderFs },
  [MaterialType.ellipseImage]: { vs: ellipseImageVs, fs: ellipseImageFs },
  [MaterialType.ellipseOuterShadow]: {
    vs: ellipseOuterShadowVs,
    fs: ellipseOuterShadowFs,
  },
  [MaterialType.ellipseInnerShadow]: {
    vs: ellipseInnerShadowVs,
    fs: ellipseInnerShadowFs,
  },
  [MaterialType.textSolid]: { vs: textSolidVs, fs: textSolidFs },
  [MaterialType.textGradientFill]: {
    vs: textGradientFillVs,
    fs: textGradientFillFs,
  },
  [MaterialType.textGradientGlyphs]: {
    vs: textGradientGlyphsVs,
    fs: textGradientGlyphsFs,
  },
  [MaterialType.transfer]: { vs: transferVs, fs: transferFs },
}
