import { Cursor } from 'application/cursor'
import {
  Action,
  ActionEventResult,
  ActionHandler,
  DonePassthrough,
  NotDonePassthrough,
} from '../types'
import { isTargetClosest } from 'application/browser'

export class OpenLibraryAction implements ActionHandler {
  private type: Action

  constructor(type: Action) {
    this.type = type
  }

  getType = (): Action => {
    return this.type
  }

  getCursor = (): Cursor => {
    return 'default'
  }

  onMouseDown = (e: MouseEvent): ActionEventResult => {
    const targets = targetMap[this.type]
    if (!targets) return DonePassthrough
    if (targets.some((t) => isTargetClosest(e, t))) return NotDonePassthrough
    return DonePassthrough
  }

  onKeyDown = (e: KeyboardEvent): ActionEventResult => {
    switch (e.key) {
      case 'Escape':
        return DonePassthrough
    }
    return NotDonePassthrough
  }
}

const targetMap: { [key: string]: string[] } = {
  openTemplateLibrary: ['template-library', 'tool-buttons'],
  openImageLibrary: ['image-library', 'tool-buttons'],
}
