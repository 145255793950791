import { createGlobalStyle } from 'styled-components'
import { DesignColor } from 'themes'

export const GlobalStyle = createGlobalStyle`
  #root {
    min-height: 100vh;
  }

  * {
    box-sizing: border-box;
  }

  body {
    margin: 0;
    background: ${DesignColor('canvasBackground')};
    overscroll-behavior-x: none;
    user-select: none;
  }

  p,
  button,
  input,
  textarea,
  div {
      font-family: 'Inter', sans-serif;
  }

  a {
    text-decoration: none;
  }

  h1 {
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    font-size: 2rem;
    color: ${DesignColor('text1')};
    margin: 0;
  }

  h2 {
    font-family: 'Inter', sans-serif;
    color: ${DesignColor('text1')};
    margin: 0;
  }

  p {
    margin: 0;
  }

  b {
    font-weight: 700;
  }

  .hideScrollbar {
    scrollbar-width: none;  /* Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
  }
  .hideScrollbar ::-webkit-scrollbar {
    display: none;  /* Chrome, Safari and Opera */
  }

  .loading_screen_text {
    font-family: 'Poppins', sans-serif;
  }

  .dragging-cursor * {
    cursor: ns-resize !important;
  }

  @keyframes loadingBar {
    0% {
      width: 0px;
    }
    10% {
      width: 0px;
    }
    80% {
      width: 50px;
    }
    100% {
      width: 180px;
    }
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
`
