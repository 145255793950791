import { WriteDocument } from 'application/document'
import { LayoutEngine } from './engine'
import { Shaper } from 'application/text'
import { LayoutEngineListener } from './types'
import { BoxLayoutEngineFactory } from './box/boxFactory'
import { ContentLayoutEngineFactory } from './content/contentFactory'

export class LayoutEngineFactory {
  create(
    document: WriteDocument,
    textShaper: Shaper,
    listener: LayoutEngineListener
  ) {
    const boxLayoutEngineFactory = new BoxLayoutEngineFactory()
    const contentLayoutEngineFactory = new ContentLayoutEngineFactory()

    const boxCalculator = boxLayoutEngineFactory.create(document)
    const contentCalculator = contentLayoutEngineFactory.create(
      document,
      textShaper
    )

    return new LayoutEngine([contentCalculator, boxCalculator], listener)
  }
}
