import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import TextBlock from 'components/Library/Components/Text/TextBlock/TextBlock'
import { capitalize } from 'lodash'
import NumberBlock from 'components/Library/Components/NumberBlock/NumberBlock'
import PanelBlock from 'components/Library/Containers/PanelBlock/PanelBlock'
import IconBlock from 'components/Library/Components/IconBlock/IconBlock/IconBlock'
import GradientStepSlider from '../Sections/GradientStepSlider'
import { useState } from 'react'
import GradientStepRowsSection from '../Sections/GradientStepRows'
import useCommit from 'hooks/editor/useCommit'
import { AttributeFill, AttributeGradientStep } from 'application/attributes'

interface MultistepGradientTabProps {
  fill: AttributeFill
  updateFill: (fill: AttributeFill) => void
}

export interface GradientStepState {
  hoveredStep?: string | undefined
  selectedStep?: string | undefined

  setHoveredStep: (hoveredStep: string | undefined) => void
  setSelectedStep: (selectedStep: string | undefined) => void
}

export default function MultistepGradientTab({
  fill,
  updateFill,
}: MultistepGradientTabProps) {
  const commitUpdate = useCommit()

  const { gradient } = fill
  const [hoveredStep, setHoveredStep] = useState<string | undefined>()
  const [selectedStep, setSelectedStep] = useState<string | undefined>(
    gradient?.steps[0].key
  )

  if (!gradient) return <></>

  const stepState = {
    hoveredStep,
    selectedStep,
    setHoveredStep,
    setSelectedStep,
  }

  const setAngle = (angle: number) => {
    updateFill({
      ...fill,
      gradient: { ...gradient, angle },
    })
  }

  const swapSteps = () => {
    const newSteps = gradient.steps
    for (let i = 0; i < newSteps.length; i++) {
      newSteps[i].position = 100 - newSteps[i].position
    }
    newSteps.sort((a, b) => a.position - b.position)
    updateFill({
      ...fill,
      gradient: { ...gradient, steps: newSteps },
    })
    commitUpdate()
  }

  const updateSteps = (steps: AttributeGradientStep[]) => {
    const newSteps = steps.sort((a, b) => a.position - b.position)
    updateFill({
      ...fill,
      gradient: { ...gradient, steps: newSteps },
    })
  }

  return (
    <>
      <PanelSection>
        <PanelRow>
          <TextBlock mode="label1">{capitalize(gradient.type)}</TextBlock>
          {gradient.angle !== undefined ? (
            <NumberBlock
              value={gradient.angle}
              icon={'Rotate'}
              setValue={(angle) => setAngle(angle)}
              min={-50_000}
              max={50_000}
              increment={(v) => setAngle((gradient.angle || 0) + v)}
              decrement={(v) => setAngle((gradient.angle || 0) - v)}
              unit={'°'}
              presets={ANGLE_PRESETS}
            />
          ) : (
            <PanelBlock />
          )}
          <IconBlock icon={'Swap'} onClick={swapSteps} />
        </PanelRow>
        <GradientStepSlider
          steps={gradient.steps}
          stepState={stepState}
          update={updateSteps}
        />
      </PanelSection>
      <GradientStepRowsSection
        steps={gradient.steps}
        stepState={stepState}
        update={updateSteps}
      />
    </>
  )
}

const ANGLE_PRESETS = [0, 45, 90, 135, 180, 225, 270, 315, 360]
