import TabsBlock from 'components/Library/Components/Tabs/TabsBlock'
import PanelContainer from 'components/Library/Containers/PanelContainer/PanelContainer'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import StyleSettings from './StyleSettings/StyleSettings'
import PresetSection from './PresetSection/PresetSection'
import { atom, useRecoilState } from 'recoil'
import useSelected from 'hooks/editor/useSelected'
import useAction from 'hooks/editor/useAction'
import Export from './Export/Export'

export const rightSidepanelTabState = atom({
  key: 'rightSidepanelTab',
  default: 'style',
})

export default function RightSidepanel() {
  const selected = useSelected()
  const action = useAction()
  const [tab, setTab] = useRecoilState(rightSidepanelTabState)
  const screenPresetsOpen = ['drawPage', 'drawFrame'].includes(action || '')

  return (
    <PanelContainer
      position={{
        position: 'absolute',
        top: 8,
        right: 8,
        zIndex: 1,
      }}
      height={'calc(100vh - 8px)'}
      backgroundColor={'panelBackground'}
    >
      {screenPresetsOpen && <PresetSection />}
      {!screenPresetsOpen && selected.length > 0 && (
        <>
          <PanelSection>
            <PanelRow>
              <TabsBlock
                tabs={['style', 'export']}
                selected={tab}
                select={setTab}
              />
            </PanelRow>
          </PanelSection>
          {tab === 'style' && <StyleSettings />}
          {tab === 'export' && <Export />}
        </>
      )}
    </PanelContainer>
  )
}
