import { truncate } from 'application/math'
import { ReadOnlyNode } from 'application/node'

export function getVW(node: ReadOnlyNode): number {
  return node.getBaseAttribute('w')
}

export function getVH(node: ReadOnlyNode): number {
  const w = getVW(node)
  if (w === undefined) return 0
  if (w > 1201) return truncate((w / 4) * 3)
  if (w > 768) return truncate((w / 3) * 4)
  return truncate((w / 9) * 16)
}

export function getVX(node: ReadOnlyNode): number {
  const left = node.getStyleAttribute('position.left.px')
  if (left !== undefined) return left
  return 0
}

export function getVY(node: ReadOnlyNode): number {
  const top = node.getStyleAttribute('position.top.px')
  if (top !== undefined) return top
  return 0
}
