import { QuadTreeRectangle } from './rectangle'
import { QuadTreeRectangleFactory } from './rectangleFactory'
import { MetadataFilter, Point, Rectangle } from './types'

type QuadTreeRectangleMap = {
  [key: string]: QuadTreeRectangle
}

export class QuadTree {
  private boundary: QuadTreeRectangle
  private capacity: number
  private divided: boolean
  private rectangleMap: QuadTreeRectangleMap
  private length: number
  private northWest?: QuadTree
  private northEast?: QuadTree
  private southWest?: QuadTree
  private southEast?: QuadTree

  constructor(x: number, y: number, w: number, h: number, capacity: number) {
    this.boundary = QuadTreeRectangleFactory.createQuadrant({ x, y, w, h })
    this.capacity = capacity
    this.rectangleMap = {}
    this.length = 0
    this.divided = false
    this.northWest = undefined
    this.northEast = undefined
    this.southWest = undefined
    this.southEast = undefined
  }

  clear = () => {
    this.rectangleMap = {}
    this.length = 0
    this.divided = false
    this.northWest = undefined
    this.northEast = undefined
    this.southWest = undefined
    this.southEast = undefined
  }

  insert = (rectangle: QuadTreeRectangle): boolean => {
    this.remove(rectangle.id)
    if (!this.boundary.intersects(rectangle)) return false

    if (this.length < this.capacity && !this.divided) {
      this.rectangleMap[rectangle.id] = rectangle
      this.length++
      return true
    }

    if (!this.divided) this.subdivide()

    let inserted = false

    if (this.insertIntoQuadTree(rectangle, this.northWest)) inserted = true
    if (this.insertIntoQuadTree(rectangle, this.northEast)) inserted = true
    if (this.insertIntoQuadTree(rectangle, this.southWest)) inserted = true
    if (this.insertIntoQuadTree(rectangle, this.southEast)) inserted = true

    return inserted
  }

  includes = (id: string): boolean => {
    if (this.rectangleMap[id]) return true

    if (this.divided) {
      if (this.northWest!.includes(id)) return true
      if (this.northEast!.includes(id)) return true
      if (this.southWest!.includes(id)) return true
      if (this.southEast!.includes(id)) return true
    }

    return false
  }

  remove = (id: string): boolean => {
    let removed = false

    if (this.rectangleMap[id]) {
      this.length--
      delete this.rectangleMap[id]
      removed = true
    } else if (this.divided) {
      if (this.removeFromQuadTree(id, this.northWest)) removed = true
      if (this.removeFromQuadTree(id, this.northEast)) removed = true
      if (this.removeFromQuadTree(id, this.southWest)) removed = true
      if (this.removeFromQuadTree(id, this.southEast)) removed = true
    }

    return removed
  }

  getAtPoint = (point: Point, filter?: MetadataFilter): QuadTreeRectangle[] => {
    const found: QuadTreeRectangle[] = []
    if (!this.boundary.contains(point)) return found

    for (const key in this.rectangleMap) {
      const rectangle = this.rectangleMap[key]
      if (rectangle.contains(point)) {
        if (filter && !filter.filter(rectangle.metadata)) continue
        found.push(rectangle)
      }
    }

    if (this.divided) {
      found.push(...this.northWest!.getAtPoint(point))
      found.push(...this.northEast!.getAtPoint(point))
      found.push(...this.southWest!.getAtPoint(point))
      found.push(...this.southEast!.getAtPoint(point))
    }

    return found
      .filter((rect, i) => found.findIndex((r) => r.id === rect.id) === i)
      .sort(this.sort)
  }

  getInterscected = (
    rectangle: Rectangle,
    filter?: MetadataFilter
  ): QuadTreeRectangle[] => {
    const queryRectangle = QuadTreeRectangleFactory.createRectangle(
      'query',
      rectangle,
      []
    )

    const found: QuadTreeRectangle[] = []
    if (!this.boundary.intersects(rectangle)) return found

    for (const key in this.rectangleMap) {
      const quadTreeRectangle = this.rectangleMap[key]
      if (queryRectangle.intersects(quadTreeRectangle)) {
        if (filter && !filter.filter(quadTreeRectangle.metadata)) continue
        found.push(quadTreeRectangle)
      }
    }

    if (this.divided) {
      found.push(...this.northWest!.getInterscected(rectangle))
      found.push(...this.northEast!.getInterscected(rectangle))
      found.push(...this.southWest!.getInterscected(rectangle))
      found.push(...this.southEast!.getInterscected(rectangle))
    }

    return found
      .filter((rect, i) => found.findIndex((r) => r.id === rect.id) === i)
      .sort(this.sort)
  }

  getEncapsulated = (
    rectangle: Rectangle,
    filter?: MetadataFilter
  ): QuadTreeRectangle[] => {
    const queryRectangle = QuadTreeRectangleFactory.createRectangle(
      'query',
      rectangle,
      []
    )

    const found: QuadTreeRectangle[] = []
    if (!this.boundary.intersects(rectangle)) return found

    for (const key in this.rectangleMap) {
      const quadTreeRectangle = this.rectangleMap[key]
      if (queryRectangle.encapsulates(quadTreeRectangle)) {
        if (filter && !filter.filter(quadTreeRectangle.metadata)) continue
        found.push(quadTreeRectangle)
      }
    }

    if (this.divided) {
      found.push(...this.northWest!.getEncapsulated(rectangle))
      found.push(...this.northEast!.getEncapsulated(rectangle))
      found.push(...this.southWest!.getEncapsulated(rectangle))
      found.push(...this.southEast!.getEncapsulated(rectangle))
    }

    return found
      .filter((rect, i) => found.findIndex((r) => r.id === rect.id) === i)
      .sort(this.sort)
  }

  private subdivide = () => {
    const x = this.boundary.x
    const y = this.boundary.y
    const w = this.boundary.w / 2
    const h = this.boundary.h / 2

    this.northWest = new QuadTree(x, y, w, h, this.capacity)
    this.northEast = new QuadTree(x + w, y, w, h, this.capacity)
    this.southWest = new QuadTree(x, y + h, w, h, this.capacity)
    this.southEast = new QuadTree(x + w, y + h, w, h, this.capacity)

    const redistribute = Object.values(this.rectangleMap)

    this.rectangleMap = {}
    this.length = 0

    for (let i = 0; i < redistribute.length; i++) {
      this.insert(redistribute[i])
    }

    this.divided = true
  }

  private insertIntoQuadTree(
    rectangle: QuadTreeRectangle,
    quadTree: QuadTree | undefined
  ): boolean {
    if (quadTree === undefined) return false

    if (quadTree.boundary.intersects(rectangle)) {
      return quadTree.insert(rectangle)
    }

    return false
  }

  private removeFromQuadTree(
    id: string,
    quadTree: QuadTree | undefined
  ): boolean {
    if (quadTree === undefined) return false

    if (quadTree.rectangleMap[id]) {
      quadTree.length--
      delete quadTree.rectangleMap[id]
      return true
    } else if (this.divided) {
      return (
        this.removeFromQuadTree(id, quadTree.northWest) ||
        this.removeFromQuadTree(id, quadTree.northEast) ||
        this.removeFromQuadTree(id, quadTree.southWest) ||
        this.removeFromQuadTree(id, quadTree.southEast)
      )
    }

    return false
  }

  private sort = (a: QuadTreeRectangle, b: QuadTreeRectangle): number => {
    let i = 0
    while (true) {
      if (a.z[i] === undefined) return 1
      if (b.z[i] === undefined) return -1
      if (a.z[i] > b.z[i]) return 1
      if (a.z[i] < b.z[i]) return -1
      i++
    }
  }
}
