import { ReadOnlyDocumentSelection } from 'application/selection'
import { CopyStylesSnapshot } from '../types'
import _ from 'lodash'

export class CopyAttributes {
  private documentSelection: ReadOnlyDocumentSelection

  private snapshot: CopyStylesSnapshot | null

  constructor(documentSelection: ReadOnlyDocumentSelection) {
    this.documentSelection = documentSelection

    this.snapshot = null
  }

  getSnapshot = (): CopyStylesSnapshot | null => {
    const selected = this.documentSelection.getSelected()
    if (selected.length !== 1) return null

    const node = selected[0]
    const defaultSelector = _.cloneDeep(node.getDefaultSelector())
    const selectors = _.cloneDeep(node.getSelectors())
    return { defaultSelector, selectors }
  }

  getSavedSnapshot = (): CopyStylesSnapshot | null => {
    return this.snapshot
  }

  saveSnapshot = (): void => {
    this.snapshot = this.getSnapshot()
  }

  clearSnapshot = (): void => {
    this.snapshot = null
  }
}
