import { ReadOnlyDocument } from 'application/document'
import {
  CodeBlockType,
  CodeExport,
  DocumentTransformer,
} from 'application/export'

export class CodeExporter {
  private document: ReadOnlyDocument
  private transformer: DocumentTransformer

  constructor(document: ReadOnlyDocument) {
    this.document = document
    this.transformer = new DocumentTransformer()
  }

  export = (
    id: string,
    styleMode: CodeBlockType,
    exportMode: CodeBlockType
  ): CodeExport | null => {
    return this.transformer.transform(id, this.document, styleMode, exportMode)
  }
}
