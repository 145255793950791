import { Command, DeleteNode } from 'application/client'
import {
  isSingleContentContainer,
  ReadOnlyDocument,
} from 'application/document'

interface CommandHandler {
  handle: (command: Command[]) => void
}

export class NodeDeleteAction {
  private commandHandler: CommandHandler
  private document: ReadOnlyDocument

  constructor(commandHandler: CommandHandler, document: ReadOnlyDocument) {
    this.commandHandler = commandHandler
    this.document = document
  }

  delete = (ids: string[]): void => {
    const commands = this.buildDeleteCommands(ids)
    this.commandHandler.handle(commands)
  }

  private buildDeleteCommands = (ids: string[]): DeleteNode[] => {
    return ids.flatMap((id) => this.deleteTree(id))
  }

  private deleteTree = (id: string): DeleteNode[] => {
    const commands: DeleteNode[] = []

    const node = this.document.getNode(id)
    if (!node) return commands

    const children = node.getChildren()
    if (children) {
      for (const child of children) {
        commands.push(...this.deleteTree(child))
      }
    }

    const parent = this.document.getParent(node)
    if (parent && isSingleContentContainer(parent, this.document)) {
      commands.push({ type: 'deleteNode', params: { id: parent.getId() } })
    }

    commands.push({ type: 'deleteNode', params: { id } })

    return commands
  }
}
