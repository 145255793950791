import Editor from 'components/Pages/Editor/Editor'
import Login from 'components/Pages/Login/Login'
import Projects from 'components/Pages/Projects/Projects'
import Reset from 'components/Pages/Reset/Reset'
import ResetUpdate from 'components/Pages/ResetUpdate/ResetUpdate'
import Signup from 'components/Pages/Signup/Signup'
import usePosthogInit from 'hooks/admin/usePosthogInit'
import { useEffect } from 'react'
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom'

function PageViews() {
  const location = useLocation()
  const { REACT_APP_WEBSITE_ADDRESS } = process.env
  const isProduction =
    REACT_APP_WEBSITE_ADDRESS &&
    REACT_APP_WEBSITE_ADDRESS !== 'http://localhost:4000'

  useEffect(() => {
    const pageTitles: Record<string, string> = {
      '/': 'Repaint',
      '/login': 'Login',
      '/signup': 'Signup',
      '/reset': 'Reset Password',
      '/reset-update': 'Reset Password',
      '/projects': 'Projects',
      '/editor': 'Editor',
      '/preview': 'Preview',
      '/auth': 'Auth',
    }

    const title = pageTitles[location.pathname] || 'Unknown Page'

    document.title = `${title}`

    if (isProduction && window.gtag) {
      window.gtag('event', 'page_view', {
        page_title: document.title,
        page_location: window.location.href,
        page_path: location.pathname,
        send_to: 'G-SN9PWWZRPF',
      })
    }
  }, [location, isProduction])

  return null
}

export default function Router() {
  usePosthogInit()
  return (
    <BrowserRouter>
      <PageViews />
      <Routes>
        <Route path="/" element={<Signup />} />
        <Route path="/login" element={<Login />} />
        <Route path="/reset" element={<Reset />} />
        <Route path="/reset-update" element={<ResetUpdate />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/editor" element={<Editor />} />
        <Route path="*" element={<h1>404: Page Not Found</h1>} />
      </Routes>
    </BrowserRouter>
  )
}
