import { IconKey } from 'assets/iconLibrary'
import { DesignColor } from 'themes'
import Text, { textStyles } from 'components/Library/Text/Text'
import { useState } from 'react'
import ButtonIcon from '../../Icon/ButtonIcon/ButtonIcon'

interface PanelRowButtonProps {
  width?: 112 | 232
  icon?: IconKey

  text: string
  subtext?: string

  styles?: textStyles

  selected?: boolean
  hovered?: boolean

  onClick?: () => void
}

export default function PanelRowButton({
  width = 232,
  icon,
  text,
  subtext,
  styles,
  selected = false,
  hovered = false,
  onClick,
}: PanelRowButtonProps) {
  const [hover, setHover] = useState(false)

  const background = getBackground(selected)
  const boxShadow = getBoxShadow(hover || hovered)

  return (
    <div
      style={{
        width: width,
        height: 32,
        paddingRight: 16,
        paddingLeft: icon ? 12 : 16,
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        gap: 4,
        background,
        boxShadow,
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}
    >
      {icon && (
        <div
          style={{
            width: 24,
            height: 24,
            display: 'flex',
            alignContent: 'center',
            justifyItems: 'center',
          }}
        >
          <ButtonIcon icon={icon} color={DesignColor('text2')} />
        </div>
      )}
      <TextContainer>
        <Text color={DesignColor('text1')} styles={styles}>
          {text}
        </Text>
        {subtext && <Text color={DesignColor('text2')}>{subtext}</Text>}
      </TextContainer>
    </div>
  )
}

function TextContainer({ children }: { children?: React.ReactNode }) {
  return (
    <div
      style={{
        width: '100%',
        height: 32,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      {children}
    </div>
  )
}

function getBoxShadow(hover: boolean) {
  if (hover) return `inset 0px 0px 0px 1px ${DesignColor('inputHighlight')}`
  return 'none'
}

function getBackground(selected: boolean) {
  if (selected) return DesignColor('inputTint')
  return 'none'
}
