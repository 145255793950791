import opentype, { Font } from 'opentype.js'
import { fonts } from 'assets/fonts'
import { FontKey, FontWeight, FontsLoadedListener, MsdfChardata } from './types'
import { weightToNumber } from './utils'

export interface FontLoaderInterface {
  init: (requiredFonts: FontKey[]) => Promise<void>
  getFont: (fontFamily: FontKey, fontWeight: FontWeight) => Font | null
  getLoadedFonts: () => Partial<{
    [key in FontKey]: Partial<{ [weight in FontWeight]: Font }>
  }>
  loadInternalFont: (key: FontKey) => Promise<void>
  loadExternalFont: (
    key: FontKey,
    weight: FontWeight,
    fileUrl: string,
    jsonUrl: string,
    pngUrl: string
  ) => Promise<void>
  subscribe: (key: string, listener: FontsLoadedListener) => void
  unsubscribe: (key: string) => void
  getChardata: (key: FontKey, weight: FontWeight) => MsdfChardata | undefined
  getMsdfSrc: (key: FontKey, weight: FontWeight) => string | undefined
}

export class FontLoader implements FontLoaderInterface {
  private listeners: { [key: string]: FontsLoadedListener }

  private loadedFonts: Partial<{
    [key in FontKey]: Partial<{ [weight in FontWeight]: Font }>
  }>
  private msdfChardata: Partial<{
    [key in FontKey]: Partial<{ [weight in FontWeight]: MsdfChardata }>
  }>
  private pngUrls: Partial<{
    [key in FontKey]: Partial<{ [weight in FontWeight]: string }>
  }>
  private allLoaded: boolean

  constructor() {
    this.loadedFonts = {}
    this.msdfChardata = {}
    this.loadedFonts = {}
    this.pngUrls = {}
    this.listeners = {}
    this.allLoaded = false
  }

  init = async (requiredFonts: FontKey[]) => {
    for (let i = 0; i < requiredFonts.length; i++) {
      await this.loadInternalFont(requiredFonts[i])
    }
    this.allLoaded = true
    this.notifyLoaded()
  }

  getFont = (fontFamily: FontKey, fontWeight: FontWeight): Font | null => {
    const font = this.loadedFonts[fontFamily]
    if (!font && fontFamily === 'inter') return null
    if (!font) return this.getFont('inter', 'regular')
    if (!font) return null

    return font[fontWeight] || null
  }

  loadInternalFont = async (key: FontKey) => {
    if (this.loadedFonts[key]) return
    const data = fonts[key]

    const loadPromises = data.weights.map(async (weight) => {
      const fontPath = data.paths[weight]
      if (!fontPath) return

      const charDataPath = `https://repaint-public-font-data.s3.us-east-2.amazonaws.com/${key}/${weight}.json`
      try {
        const response = await fetch(charDataPath)
        if (response.ok) {
          const charData: MsdfChardata = await response.json()
          this.msdfChardata[key] = {
            ...this.msdfChardata[key],
            [weight]: charData,
          }
        }
      } catch (error) {
        console.error(
          `Error loading character data for ${key} weight ${weight}:`,
          error
        )
      }

      return new Promise<void>((resolve, reject) => {
        opentype.load(fontPath, (err, loadedFont) => {
          if (err || !loadedFont) {
            console.error(
              `Font ${key} weight ${weight} could not be loaded: ${err}`
            )
            reject(err)
            return
          }
          this.loadedFonts[key] = {
            ...this.loadedFonts[key],
            [weight]: loadedFont,
          }
          resolve()
        })
      })
    })

    await Promise.all(loadPromises)
  }

  loadExternalFont = async (
    key: FontKey,
    weight: FontWeight,
    fileUrl: string,
    jsonUrl: string,
    pngUrl: string
  ) => {
    if (this.loadedFonts[key]?.[weight]) return

    if (!this.pngUrls[key]) this.pngUrls[key] = {}
    this.pngUrls[key] = {
      ...this.pngUrls[key],
      [weight]: pngUrl,
    }

    document.fonts.add(
      new FontFace(key, `url(${fileUrl})`, {
        style: 'normal',
        weight: `${weightToNumber(weight)}`,
      })
    )

    const loadPromise = async () => {
      try {
        const response = await fetch(jsonUrl)
        if (response.ok) {
          const charData: MsdfChardata = await response.json()
          this.msdfChardata[key] = {
            ...this.msdfChardata[key],
            [weight]: charData,
          }
        }
      } catch (error) {
        console.error(
          `Error loading character data for ${key} weight ${weight}:`,
          error
        )
      }

      return new Promise<void>((resolve, reject) => {
        opentype.load(fileUrl, (err, loadedFont) => {
          if (err || !loadedFont) {
            console.error(
              `Font ${key} weight ${weight} could not be loaded: ${err}`
            )
            reject(err)
            return
          }
          this.loadedFonts[key] = {
            ...this.loadedFonts[key],
            [weight]: loadedFont,
          }
          resolve()
        })
      })
    }

    await loadPromise()
  }

  subscribe = (key: string, listener: FontsLoadedListener) => {
    this.listeners[key] = listener
    if (this.allLoaded) listener.onFontsLoaded()
  }

  unsubscribe = (key: string) => {
    delete this.listeners[key]
  }

  private notifyLoaded = () => {
    for (const key in this.listeners) {
      this.listeners[key].onFontsLoaded()
    }
  }

  getLoadedFonts = (): Partial<{
    [key in FontKey]: Partial<{ [weight in FontWeight]: Font }>
  }> => {
    return this.loadedFonts
  }

  getChardata = (
    key: FontKey,
    weight: FontWeight
  ): MsdfChardata | undefined => {
    if (!this.msdfChardata[key]?.[weight] && key === 'inter') return undefined
    if (!this.msdfChardata[key]?.[weight]) {
      return this.getChardata('inter', 'regular')
    }

    return this.msdfChardata[key]?.[weight]
  }

  getMsdfSrc = (key: FontKey, weight: FontWeight): string | undefined => {
    if (this.pngUrls[key]?.[weight]) return this.pngUrls[key]?.[weight]
    if (!this.loadedFonts[key] && key === 'inter') return undefined
    if (!this.loadedFonts[key]) {
      return this.getMsdfSrc('inter', 'regular')
    }

    return `https://repaint-public-font-data.s3.us-east-2.amazonaws.com/${key}/${weight}.png`
  }
}
