import { EditorState } from '../textEditor'
import { TextCommand } from '../command/types'
import { State } from '../state/types'
import { deleteSelection } from './utils'

export interface Clipboard {
  writeText: (text: string) => void
}

export class CopyHandler {
  private state: EditorState
  private clipboard: Clipboard

  constructor(state: EditorState, clipboard: Clipboard) {
    this.state = state
    this.clipboard = clipboard
  }

  handle = (command: TextCommand): void => {
    switch (command.type) {
      case 'copy':
        this.handleCopy()
        break
      case 'cut':
        this.handleCut()
        break
    }
  }

  private handleCopy = (): void => {
    const state = this.state.get()
    if (!state.selection.anchor) return

    const text = this.getSelectedText(state)
    this.clipboard.writeText(text)
  }

  private handleCut = (): void => {
    const state = this.state.get()
    if (!state.selection.anchor) return

    const text = this.getSelectedText(state)
    this.clipboard.writeText(text)

    deleteSelection(state)
    this.state.set(state)
  }

  private getSelectedText = (state: State): string => {
    const { content, selection } = state
    const { anchor, focus } = selection
    if (!anchor || !focus) return ''

    const start = Math.min(anchor.index, focus.index)
    const end = Math.max(anchor.index, focus.index)

    return content.content.slice(start, end)
  }
}
