import { TailwindClassRenderer } from '../types'
import {
  isUnitLength,
  isUnitPercent,
  Properties,
  UnitSize,
  unitSizeToString,
} from 'application/export/css'

export class TailwindClassSize implements TailwindClassRenderer {
  render = (properties: Partial<Properties>): string[] => {
    const classes: string[] = []

    const width = properties.width
    const height = properties.height
    const minWidth = properties.minWidth
    const minHeight = properties.minHeight
    const maxWidth = properties.maxWidth
    const maxHeight = properties.maxHeight

    const widthClass = this.getSizeClass(width, 'w')
    const heightClass = this.getSizeClass(height, 'h')
    const minWidthClass = this.getMinSizeClass(minWidth, 'width')
    const minHeightClass = this.getMinSizeClass(minHeight, 'height')
    const maxWidthClass = this.getMaxSizeClass(maxWidth, 'width')
    const maxHeightClass = this.getMaxSizeClass(maxHeight, 'height')

    if (widthClass) classes.push(widthClass)
    if (heightClass) classes.push(heightClass)

    if (minWidthClass) classes.push(minWidthClass)
    if (minHeightClass) classes.push(minHeightClass)

    if (maxWidthClass) classes.push(maxWidthClass)
    if (maxHeightClass) classes.push(maxHeightClass)

    return classes
  }

  private getSizeClass = (
    height:
      | UnitSize
      | 'auto'
      | 'max-content'
      | 'min-content'
      | 'fit-content'
      | undefined,
    side: 'w' | 'h'
  ): string | null => {
    switch (height) {
      case 'max-content':
        return `${side}-max`
      case 'min-content':
        return `${side}-min`
      case 'fit-content':
        return `${side}-fit`
      case undefined:
      case 'auto':
        return null
      default:
        if (this.is100Percent(height)) return `${side}-full`
        if (this.isZero(height)) return `${side}-0`
        return `${side}-[${unitSizeToString(height)}]`
    }
  }

  private getMinSizeClass = (
    min: UnitSize | 'auto' | undefined,
    side: 'width' | 'height'
  ): string | null => {
    switch (min) {
      case 'auto':
      case undefined:
        return null
      default:
        if (this.is100Percent(min)) return `min-${side}-full`
        if (this.isZero(min)) return `min-${side}-0`
        return `min-${side}-[${unitSizeToString(min)}]`
    }
  }

  private getMaxSizeClass = (
    max: UnitSize | 'none' | undefined,
    side: 'width' | 'height'
  ): string | null => {
    switch (max) {
      case 'none':
        return `max-${side}-none`
      case undefined:
        return null
      default:
        if (this.is100Percent(max)) return `max-${side}-full`
        if (this.isZero(max)) return `max-${side}-0`
        return `max-${side}-[${unitSizeToString(max)}]`
    }
  }

  private is100Percent(v: UnitSize): boolean {
    return isUnitPercent(v) && v.value === 100
  }

  private isZero(v: UnitSize): boolean {
    return (
      (isUnitLength(v) && v.value === 0) || (isUnitPercent(v) && v.value === 0)
    )
  }
}
