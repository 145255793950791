import { Element } from './element'
import { HtmlTag } from '../types/types'

export class ElementButton extends Element {
  static create = (): Element => {
    const element = new Element(HtmlTag.tag_button)
    element.sizedByContent = true
    return element
  }
}
