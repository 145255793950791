import { DesignColor } from 'themes'
import Icon from '../../Icon/Icon/Icon'

interface DropdownArrowProps {
  visible: boolean
  onClick: () => void

  hasOverride?: boolean
}

export default function DropdownArrow({
  visible,
  onClick,
  hasOverride = false,
}: DropdownArrowProps) {
  return (
    <div
      onClick={(e) => {
        if (e.button === 2) return
        onClick()
      }}
      style={{
        width: 16,
        height: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
      }}
    >
      {visible && (
        <Icon
          icon={'DropdownArrowDown'}
          size={10}
          color={DesignColor(hasOverride ? 'overrideParent' : 'text1')}
        />
      )}
    </div>
  )
}
