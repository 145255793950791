import { DesignColor } from 'themes'
import styled from 'styled-components'
import { useFPS } from 'hooks/admin/useFPS'

export default function FPSDisplay() {
  const fps = useFPS()

  return <FpsLabel>FPS: {Math.round(fps * 10) / 10}</FpsLabel>
}

const FpsLabel = styled.p`
  width: 64px;

  color: ${DesignColor('text2')};
  font-size: 10px;
`
