import { DesignColor } from 'themes'
import Icon from '../../Icon/Icon/Icon'

interface DeleteIconProps {
  visible: boolean
  onClick: () => void
}

export default function DeleteIcon({ visible, onClick }: DeleteIconProps) {
  return (
    <div
      onClick={onClick}
      style={{
        width: 16,
        height: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
      }}
    >
      {visible && <Icon icon={'X'} size={10} color={DesignColor('text1')} />}
    </div>
  )
}
