import { ClientUpdateListener, Update } from 'application/client'
import {
  Action,
  ActionHandler,
  ActiveActionListener,
} from 'editor/action/types'

export type HighlightListener = {
  onHighlight: (id: string | null) => void
}

export class EditorHighlightService
  implements ActiveActionListener, ClientUpdateListener
{
  private action: Action | null = null

  private id: string | null = null
  private listeners: { [key: string]: HighlightListener }

  constructor() {
    this.action = null

    this.id = null
    this.listeners = {}
  }

  getTypes = (): Update['type'][] => {
    return ['selection']
  }

  onUpdate = (update: Update[]): void => {
    if (!this.id) return
    for (const u of update) {
      if (u.type === 'selection') {
        if (u.data.ids.includes(this.id)) this.unhighlight()
      }
    }
  }

  onActiveAction(handler: ActionHandler | null): void {
    this.action = handler?.getType() || null
    if (this.action !== null) this.id = null
    this.notifyListeners()
  }

  subscribe = (key: string, listener: HighlightListener) => {
    this.listeners[key] = listener
    listener.onHighlight(this.id)
    return key
  }

  unsubscribe = (key: string) => {
    delete this.listeners[key]
  }

  highlight = (id: string) => {
    if (this.id === id) return
    this.id = id
    this.notifyListeners()
  }

  unhighlight = () => {
    if (this.id === null) return
    this.id = null
    this.notifyListeners()
  }

  private notifyListeners = () => {
    for (const key in this.listeners) {
      this.listeners[key].onHighlight(this.id)
    }
  }
}
