import {
  ImageFunction,
  imageFunctionToString,
  isImageFunction,
  isUnitColor,
  isUnitLinearGradient,
  isUnitSize2,
  Properties,
  UnitColor,
  unitColorToString,
  UnitLinearGradient,
  unitLinearGradientToString,
  UnitSize2,
  unitSize2ToString,
} from 'application/export/css'
import { TailwindClassRenderer } from '../types'
import { colorToTailwind } from '../utils'

export class TailwindClassBackground implements TailwindClassRenderer {
  render = (properties: Partial<Properties>): string[] => {
    const classes: string[] = []

    const background = properties.background
    const backgroundPosition = properties.backgroundPosition
    const backgroundSize = properties.backgroundSize
    const backgroundRepeat = properties.backgroundRepeat
    const backgroundClip = properties.backgroundClip

    if (background !== undefined) {
      classes.push(...this.renderBackground(background))
    }

    if (backgroundPosition !== undefined) {
      classes.push(...this.renderBackgroundPosition(backgroundPosition))
    }

    if (backgroundSize !== undefined) {
      classes.push(...this.renderBackgroundSize(backgroundSize))
    }

    if (backgroundRepeat !== undefined) {
      classes.push(...this.renderBackgroundRepeat(backgroundRepeat))
    }

    if (backgroundClip !== undefined) {
      classes.push(...this.renderBackgroundClip(backgroundClip))
    }

    return classes
  }

  private renderBackground = (
    background: UnitColor | UnitLinearGradient | ImageFunction
  ): string[] => {
    if (isUnitColor(background)) {
      const colorString = unitColorToString(background)
      const tailwindColor = colorToTailwind(colorString)
      if (tailwindColor !== null) {
        return [`bg-${tailwindColor}`]
      }
      return [`bg-[${colorString}]`]
    } else if (isUnitLinearGradient(background)) {
      return [`bg-[${unitLinearGradientToString(background)}]`]
    } else if (isImageFunction(background)) {
      return [`bg-[${imageFunctionToString(background)}]`]
    }
    return []
  }

  private renderBackgroundPosition = (
    position: 'top' | 'right' | 'bottom' | 'left' | 'center'
  ): string[] => {
    return [`bg-${position}`]
  }

  private renderBackgroundSize = (
    size: 'auto' | 'cover' | 'contain' | UnitSize2
  ): string[] => {
    if (isUnitSize2(size)) {
      return [`bg-[${unitSize2ToString(size)}]`]
    }
    return [`bg-${size}`]
  }

  private renderBackgroundRepeat = (
    repeat: 'repeat' | 'repeat-x' | 'repeat-y' | 'no-repeat'
  ): string[] => {
    return [`bg-${repeat}`]
  }

  private renderBackgroundClip = (clip: 'text'): string[] => {
    return [`bg-clip-${clip}`]
  }
}
