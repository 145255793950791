import {
  Context,
  UniformValue,
  VaoData,
  VertexBuffer,
  calcBoundingBox,
  calcRounding,
  isPointInBox,
  rectToQuadTriangles,
} from 'application/render'
import { Rectangle } from 'application/shapes'
import { Color, rgbaToWebgl } from 'application/color'
import { MaterialType } from './shaderMap'
import {
  cleanupVaoData,
  clearBuffers,
  connectBuffersToVao,
  createEmptyVaoData,
  standardDraw,
} from '../utils'

export type WebglRectangleBorderData = Rectangle & {
  color: Color
  widths: { t: number; r: number; b: number; l: number }
  rounding?: { tl: number; tr: number; br: number; bl: number }
  bb?: Rectangle
  bbRounding?: { tl: number; tr: number; br: number; bl: number }
}

export class WebglRectangleBorder {
  private context: Context
  private data: WebglRectangleBorderData
  private vaoData: VaoData

  constructor(context: Context, data: WebglRectangleBorderData) {
    this.context = context
    this.data = data
    this.vaoData = createEmptyVaoData()
  }

  init = (): void => {
    this.vaoData = this.updateRenderData()
  }

  draw(): void {
    standardDraw(this.vaoData)
  }

  updateRenderData(): VaoData {
    clearBuffers(this.vaoData)
    const gl = this.context.getGl()
    const material = this.context.getMaterial(MaterialType.rectangleBorder)
    const vao = gl.createVertexArray()
    const buffers: { [key: string]: VertexBuffer } = {}
    const uniforms: { [key: string]: UniformValue } = {}

    const { x, y, w, h, color, widths, rounding, bb, bbRounding } = this.data

    const aPosition = new Float32Array(rectToQuadTriangles(this.data))
    const uColor = rgbaToWebgl(color)
    const uRect = [x, y, w, h]
    const uRounding = calcRounding(w, h, rounding)
    const uWidths = [widths.t, widths.r, widths.b, widths.l]
    const uBB = calcBoundingBox(bb)
    const uBBRounding = calcRounding(uBB[2], uBB[3], bbRounding)

    buffers['aPosition'] = this.context.createVertexBuffer(aPosition, 2)
    uniforms['uColor'] = uColor
    uniforms['uRect'] = uRect
    uniforms['uRounding'] = uRounding
    uniforms['uWidths'] = uWidths
    uniforms['uBB'] = uBB
    uniforms['uBBRounding'] = uBBRounding
    const verticeCount = aPosition.length / 2

    const vaoData = { material, vao, buffers, uniforms, verticeCount }
    connectBuffersToVao(vaoData)
    return vaoData
  }

  cleanup(): void {
    cleanupVaoData(this.vaoData)
  }
}

export const rectangleBorderVs = `#version 300 es
precision highp float;

uniform mat3 uMatrix;

in vec2 aPosition;

out vec2 vPosition;

void main() {
  vec3 transformedPosition = uMatrix * vec3(aPosition, 1);
  gl_Position = vec4(transformedPosition.xy, 0, 1);

  vPosition = aPosition;
}
`

export const rectangleBorderFs = `#version 300 es
precision mediump float;

uniform float uZoom;
uniform float uDpr;
uniform vec4 uColor;
uniform vec4 uRect;
uniform vec4 uRounding;
uniform vec4 uWidths;
uniform vec4 uBB;
uniform vec4 uBBRounding;

in vec2 vPosition;

out vec4 outColor;

${isPointInBox}

bool insideEllipse(vec2 center, vec2 radius) {
  if (radius.x < 0.0 || radius.y < 0.0) return false;
  vec2 distanceVec = abs(vPosition - center);
  return distanceVec.x * distanceVec.x / (radius.x * radius.x) + distanceVec.y * distanceVec.y / (radius.y * radius.y) < 1.0;
}

bool isInsideBorder(int border) {
  float width = uWidths[border];
  if (width * uZoom < 1.0 / uDpr) {
    width = 1.0 / uDpr / uZoom; // Ensures minimum visible width
  }

  if (border == 0) // Top
    return vPosition.y < (uRect.y + width);
  else if (border == 1) // Right
    return vPosition.x > (uRect.x + uRect.z - width);
  else if (border == 2) // Bottom
    return vPosition.y > (uRect.y + uRect.w - width);
  else if (border == 3) // Left
    return vPosition.x < (uRect.x + width);

  return false;
}

bool isOutsideCorner(vec2 point, vec2 innerCorner, float rounding) {
  return distance(point, innerCorner) > rounding;
}

void main() {
  if (!isPointInBox(vPosition, uBB, uBBRounding)) {
    discard;
  }

  vec2 tlCorner = uRect.xy;
  vec2 trCorner = uRect.xy + vec2(uRect.z, 0);
  vec2 brCorner = uRect.xy + uRect.zw;
  vec2 blCorner = uRect.xy + vec2(0, uRect.w);

  vec2 tlInnerCorner = tlCorner + vec2(uRounding.x);
  vec2 trInnerCorner = trCorner + vec2(-uRounding.y, uRounding.y);
  vec2 brInnerCorner = brCorner + vec2(-uRounding.z);
  vec2 blInnerCorner = blCorner + vec2(uRounding.w, -uRounding.w);

  bool inTlCorner = insideCorner(vPosition, tlCorner, uRounding.x);
  bool inTrCorner = insideCorner(vPosition, trCorner, uRounding.y);
  bool inBrCorner = insideCorner(vPosition, brCorner, uRounding.z);
  bool inBlCorner = insideCorner(vPosition, blCorner, uRounding.w);

  if ((inTlCorner && isOutsideCorner(vPosition, tlInnerCorner, uRounding.x)) || 
    (inTrCorner && isOutsideCorner(vPosition, trInnerCorner, uRounding.y)) ||
    (inBrCorner && isOutsideCorner(vPosition, brInnerCorner, uRounding.z)) ||
    (inBlCorner && isOutsideCorner(vPosition, blInnerCorner, uRounding.w))) {
      discard;
  }

  vec4 opacities = vec4(uColor.a);
  vec4 widths = uWidths;

  for (int i = 0; i < 4; i++) {
    if (widths[i] * uZoom < 1.0 / uDpr) {
      opacities[i] = opacities[i] * (uWidths[i] * uZoom);
      widths[i] = widths[i] / (widths[i] * uZoom);
    }
  }

  if (isInsideBorder(0)) {
    outColor = vec4(uColor.rgb, opacities.x);
    return;
  } else if (isInsideBorder(1)) {
    outColor = vec4(uColor.rgb, opacities.y);
    return;
  } else if (isInsideBorder(2)) {
    outColor = vec4(uColor.rgb, opacities.z);
    return;
  } else if (isInsideBorder(3)) {
    outColor = vec4(uColor.rgb, opacities.w);
    return;
  }

  if (inTlCorner && !insideEllipse(tlInnerCorner, vec2(uRounding.x - widths.w, uRounding.x - widths.x))) {
    outColor = vec4(uColor.rgb, min(opacities.x, opacities.w));
    return;
  }

  if (inTrCorner && !insideEllipse(trInnerCorner, vec2(uRounding.y - widths.y, uRounding.y - widths.x))) {
    outColor = vec4(uColor.rgb, min(opacities.x, opacities.y));
    return;
  }

  if (inBrCorner && !insideEllipse(brInnerCorner, vec2(uRounding.z - widths.y, uRounding.z - widths.z))) {
    outColor = vec4(uColor.rgb, min(opacities.z, opacities.y));
    return;
  }

  if (inBlCorner && !insideEllipse(blInnerCorner, vec2(uRounding.w - widths.w, uRounding.w - widths.z))) {
    outColor = vec4(uColor.rgb, min(opacities.z, opacities.w));
    return;
  }

  discard;
}
`
