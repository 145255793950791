import { EditorCursor } from 'editor/cursor/cursor'
import { ActionHandler, ActiveActionListener } from './types'

export class ActiveAction {
  private cursor: EditorCursor

  private activeActionHandler: ActionHandler | null = null
  private listeners: { [key: string]: ActiveActionListener } = {}

  constructor(cursor: EditorCursor) {
    this.cursor = cursor
  }

  subscribe = (key: string, listener: ActiveActionListener): void => {
    this.listeners[key] = listener
    listener.onActiveAction(this.activeActionHandler || null)
  }

  unsubscribe = (key: string): void => {
    delete this.listeners[key]
  }

  set = (handler: ActionHandler): void => {
    this.activeActionHandler = handler
    if (handler && handler.getCursor) this.cursor.set(handler.getCursor())
    else this.cursor.reset()
    this.notifyListeners()
  }

  get = (): ActionHandler | null => {
    return this.activeActionHandler
  }

  reset = (): void => {
    this.activeActionHandler = null
    this.cursor.reset()
    this.notifyListeners()
  }

  private notifyListeners = (): void => {
    for (const key in this.listeners) {
      this.listeners[key].onActiveAction(this.activeActionHandler || null)
    }
  }
}
