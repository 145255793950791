import {
  HistoryActionListener,
  HistoryEvent,
  HistoryEventSelectedCanvas,
  HistoryEventSelection,
} from 'application/history'
import { DocumentSelection } from 'application/selection'

export class SelectionHistoryActionHandler implements HistoryActionListener {
  private selection: DocumentSelection

  constructor(selection: DocumentSelection) {
    this.selection = selection
  }

  onUndo = (event: HistoryEvent): void => {
    switch (event.type) {
      case 'selection':
        this.handleSelectionUndo(event)
        return
      case 'selected_canvas':
        this.handleSelectedCanvasUndo(event)
        return
    }
  }

  onRedo = (event: HistoryEvent): void => {
    switch (event.type) {
      case 'selection':
        this.handleSelectionRedo(event)
        return
      case 'selected_canvas':
        this.handleSelectedCanvasRedo(event)
        return
    }
  }

  private handleSelectionUndo = (event: HistoryEventSelection): void => {
    this.selection.select(event.data.initial, true)
  }

  private handleSelectionRedo = (event: HistoryEventSelection): void => {
    this.selection.select(event.data.current, true)
  }

  private handleSelectedCanvasUndo = (
    event: HistoryEventSelectedCanvas
  ): void => {
    this.selection.selectCanvas(event.data.initial)
  }

  private handleSelectedCanvasRedo = (
    event: HistoryEventSelectedCanvas
  ): void => {
    this.selection.selectCanvas(event.data.current)
  }
}
