import { ReadOnlyNode } from 'application/node'
import { HTMLDocument } from '../html/types'
import { createSelector } from '../utils'
import { AttributeTransformer } from './types'
import { AttributeDisplay } from 'application/attributes'
import { Style } from '../css'

export class DisplayStyle implements AttributeTransformer {
  transformBase = (node: ReadOnlyNode, htmlDocument: HTMLDocument): void => {
    const selector = createSelector(node.getId(), 'default')
    const style = htmlDocument.getStyles().get(selector)

    const mode = node.getDefaultSelector().styles['display']
    if (mode) this.addDisplay(mode, style)
  }

  private addDisplay = (mode: AttributeDisplay, style: Style): void => {
    switch (mode) {
      case 'block':
        style.setProperty('display', 'block')
        break
      case 'flex':
        style.setProperty('display', 'flex')
        break
      case 'none':
        style.setProperty('display', 'none')
        break
    }
  }
}
