interface PooledObject {
  reset: () => void
}

export class ObjectPool<T extends PooledObject> {
  private pool: T[] = []
  private readonly objectConstructor: new () => T

  constructor(objectConstructor: new () => T, size: number) {
    this.objectConstructor = objectConstructor
    this.pool = new Array(size)
    for (let i = 0; i < size; i++) {
      this.pool[i] = new this.objectConstructor()
    }
  }

  get = (): T => {
    if (this.pool.length > 0) {
      return this.pool.pop()!
    }
    return new this.objectConstructor()
  }

  release = (object: T) => {
    object.reset()
    this.pool.push(object)
  }
}
