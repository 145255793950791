import { BackendService } from 'application/service/backend'
import useAuthService from './useAuthService'
import { useMemo } from 'react'
import { HTTPService } from 'application/service/http'

const { REACT_APP_SERVER_ADDRESS } = process.env
const SERVER_ADDRESS = REACT_APP_SERVER_ADDRESS
  ? REACT_APP_SERVER_ADDRESS
  : 'http://localhost:5000'

export default function useBackendService() {
  const authService = useAuthService()

  return useMemo(() => {
    return new BackendService(new HTTPService(SERVER_ADDRESS), authService)
  }, [authService])
}
