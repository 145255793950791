import { CanvasSizeService } from './canvasSize'
import { CameraCalculation } from './calculation'
import { Camera, PanCameraMode } from './types'
import { Point, Rectangle } from 'application/shapes'
import { ZOOM_MAX, ZOOM_MIN } from './consts'
import { MouseDevice } from 'application/mouse'

export type CameraUpdateListener = {
  onCamera: (camera: Camera) => void
}

export class CameraService {
  private canvasSizeStateService: CanvasSizeService
  private cameraListeners: { [key: string]: CameraUpdateListener }

  private camera: Camera
  private lastCameraUpdateTimestamp: number
  private mouseDevice: MouseDevice

  constructor(canvasSize: CanvasSizeService) {
    this.canvasSizeStateService = canvasSize
    this.cameraListeners = {}

    this.camera = { x: 0, y: 0, z: 1 }
    this.lastCameraUpdateTimestamp = 0
    this.mouseDevice = 'mouse'
  }

  getCanvasCoordinates = (point: Point): Point => {
    const canvasRectangle = this.canvasSizeStateService.get()
    return this.screenToCanvas(
      {
        x: point.x - canvasRectangle.x,
        y: point.y - canvasRectangle.y,
      },
      this.camera
    )
  }

  getCamera = (): Camera => {
    return this.camera
  }

  subscribe = (key: string, listener: CameraUpdateListener) => {
    this.cameraListeners[key] = listener
    listener.onCamera(this.getCamera())
  }

  unsubscribe = (key: string) => {
    delete this.cameraListeners[key]
  }

  getViewableRectangle = (): Rectangle => {
    const canvasRectangle = this.canvasSizeStateService.get()
    const camera = this.getCamera()
    return {
      x: camera.x,
      y: -camera.y,
      w: canvasRectangle.w / camera.z,
      h: canvasRectangle.h / camera.z,
    }
  }

  setCamera = (camera: Camera) => {
    this.camera = camera
    this.notifyCameraListeners()
  }

  panCamera = (
    e: WheelEvent | MouseEvent,
    mode: PanCameraMode | undefined = undefined
  ) => {
    this.updateMouseType(e)
    if (e instanceof WheelEvent) {
      const update = CameraCalculation.panCameraWheel({
        camera: this.getCamera(),
        e: e,
        type: this.mouseDevice,
      })
      this.setCamera(update)
    } else if (e instanceof MouseEvent) {
      const update = CameraCalculation.panCameraMouse({
        camera: this.getCamera(),
        e: e,
        type: this.mouseDevice,
        mode: mode,
      })
      this.setCamera(update)
    }
    this.setLastCameraUpdateTimestamp()
  }

  panCameraDrag = (e: MouseEvent) => {
    const update = CameraCalculation.panCameraMouse({
      camera: this.getCamera(),
      type: 'mouse',
      mode: 'drag',
      e: e,
    })
    this.setCamera(update)
    this.setLastCameraUpdateTimestamp()
  }

  zoomCamera = (e: WheelEvent) => {
    this.updateMouseType(e)
    const canvasRectangle = this.canvasSizeStateService.get()
    const update = CameraCalculation.zoomCamera({
      e: e,
      type: this.mouseDevice,
      camera: this.getCamera(),
      canvasRect: canvasRectangle,
    })
    this.setCamera(update)
    this.setLastCameraUpdateTimestamp()
  }

  zoomCameraByFactor = (amount: number) => {
    const newZoom = this.getCamera().z * amount
    const adjustedZoom = Math.min(Math.max(newZoom, ZOOM_MIN), ZOOM_MAX)
    const canvasRectangle = this.canvasSizeStateService.get()
    this.setCamera(
      this.zoomCameraToNumberHelper(
        this.getCamera(),
        canvasRectangle,
        adjustedZoom
      )
    )
    this.setLastCameraUpdateTimestamp()
  }

  zoomToValue = (value: number) => {
    const adjustedZoom = Math.min(Math.max(value, ZOOM_MIN), ZOOM_MAX)
    const canvasRectangle = this.canvasSizeStateService.get()
    this.setCamera(
      this.zoomCameraToNumberHelper(
        this.getCamera(),
        canvasRectangle,
        adjustedZoom
      )
    )
    this.setLastCameraUpdateTimestamp()
  }

  centerOnRectangle = (rectangle: Rectangle) => {
    const canvasRectangle = this.canvasSizeStateService.get()
    this.setCamera(
      CameraCalculation.centerCameraOnRectangle(rectangle, canvasRectangle)
    )
  }

  moveOntoRectangle = (rectangle: Rectangle) => {
    const canvasRectangle = this.canvasSizeStateService.get()
    const camera = this.getCamera()
    this.setCamera(
      CameraCalculation.moveCameraOnRectangle(
        rectangle,
        canvasRectangle,
        camera
      )
    )
  }

  isRectangleInView = (rectangle: Rectangle): boolean => {
    const viewableRectangle = this.getViewableRectangle()
    return CameraCalculation.isRectangleInView(rectangle, viewableRectangle)
  }

  private notifyCameraListeners = () => {
    const camera = this.getCamera()
    for (const listener of Object.values(this.cameraListeners)) {
      listener.onCamera(camera)
    }
  }

  private setLastCameraUpdateTimestamp = () => {
    this.lastCameraUpdateTimestamp = Date.now()
  }

  private updateMouseType = (e: WheelEvent | MouseEvent): void => {
    if (
      this.mouseDevice === 'mouse' &&
      e instanceof WheelEvent &&
      CameraCalculation.isTouchpad(e)
    ) {
      this.mouseDevice = 'touchpad'
    } else if (
      this.mouseDevice === 'touchpad' &&
      e instanceof WheelEvent &&
      !CameraCalculation.isTouchpad(e)
    ) {
      this.mouseDevice = 'mouse'
    }

    if (Date.now() - this.lastCameraUpdateTimestamp < 300) return
    if (e instanceof WheelEvent) {
      const device = CameraCalculation.getMouseDevice(e)
      this.mouseDevice = device
    } else if (e instanceof MouseEvent) {
      const device = CameraCalculation.getMouseDevice(e)
      this.mouseDevice = device
    }
  }

  private screenToCanvas(point: Point, camera: Camera): Point {
    return {
      x: point.x / camera.z + camera.x,
      y: point.y / camera.z - camera.y,
    }
  }

  private zoomCameraToNumberHelper(
    camera: Camera,
    canvasRect: Rectangle,
    z: number
  ): Camera {
    const point = {
      x: window.innerWidth / 2,
      y: window.innerHeight / 2,
    }
    if (!canvasRect) return { x: 0, y: 0, z: 1 }
    const adjustedX = point.x - canvasRect.x
    const adjustedY = point.y - canvasRect.y
    const p1 = this.screenToCanvas({ x: adjustedX, y: adjustedY }, camera)
    const p2 = this.screenToCanvas(
      { x: adjustedX, y: adjustedY },
      { ...camera, z: z }
    )
    return {
      x: camera.x - (p2.x - p1.x),
      y: camera.y + (p2.y - p1.y),
      z: z,
    }
  }
}
