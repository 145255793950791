import { PositionContext } from './positionContext'
import { SizeContext } from './sizeContext'

export class ContainingContext {
  vw: number
  vh: number
  w: number
  h: number

  constructor() {
    this.vw = 0
    this.vh = 0
    this.w = 0
    this.h = 0
  }

  static create(): ContainingContext {
    return new ContainingContext()
  }

  static fromPositionContext(context: PositionContext): ContainingContext {
    const containingContext = ContainingContext.create()
    containingContext.vw = context.vw
    containingContext.vh = context.vh
    containingContext.w = context.right - context.left
    containingContext.h = context.bottom - context.top
    return containingContext
  }

  static fromSizeContext(context: SizeContext): ContainingContext {
    const containingContext = ContainingContext.create()
    containingContext.vw = context.vw
    containingContext.vh = context.vh
    containingContext.w = context.width.value
    containingContext.h = context.height.value
    return containingContext
  }
}

export class ContainingContextBuilder {
  private containingContext: ContainingContext

  static create(
    containingContext: ContainingContext = ContainingContext.create()
  ): ContainingContextBuilder {
    return new ContainingContextBuilder(containingContext)
  }

  constructor(containingContext: ContainingContext) {
    this.containingContext = containingContext
  }

  withW(w: number): ContainingContextBuilder {
    this.containingContext.w = w
    return this
  }

  withH(h: number): ContainingContextBuilder {
    this.containingContext.h = h
    return this
  }

  withVW(vw: number): ContainingContextBuilder {
    this.containingContext.vw = vw
    return this
  }

  withVH(vh: number): ContainingContextBuilder {
    this.containingContext.vh = vh
    return this
  }

  build(): ContainingContext {
    return this.containingContext
  }
}
