import { HistoryListeners, HistoryLog } from 'application/history'
import { NodeFactory, NodeListeners } from 'application/node'
import {
  ClientTransactionHandler,
  ClientTransactionLogHandler,
  DocumentTransactionHistoryHandler,
  NodeTransactionHistoryHandler,
  SelectionTransactionHistoryHandler,
} from './transaction'
import {
  WriteDocument,
  DocumentListeners,
  ReadOnlyDocument,
  Document,
} from 'application/document'
import {
  DocumentHistoryActionHandler,
  SelectionHistoryActionHandler,
} from './history'
import { DocumentSelection, SelectionListeners } from 'application/selection'
import {
  CommitCommandHandler,
  HistoryCommandHandler,
  NodeCommandHandler,
  SelectCommandHandler,
} from './command'
import { RulesEngine, RulesEngineFactory } from 'application/rules'
import { ClientRulesEngine } from './rules/rules'
import { ClientLayoutEngine } from './layout/layout'
import { Client } from './client'
import { ClientUpdateHandler } from './update/update'
import { DocumentUpdateAccumulator } from './update/document'
import { NodeUpdateAccumulator } from './update/node'
import { SelectionUpdateAccumulator } from './update/selection'
import { TextEditorCommandHandler } from './command/text'
import { ClientTextEditor } from './textEditor/textEditor'
import { TextEditor, TextEditorFactory } from 'application/textEditor'
import { TextEditorTransactionHistoryHandler } from './transaction/textEditor'
import { TextEditorUpdateAccumulator } from './update/textEditor'
import { TextEditorHistoryActionHandler } from './history/textEditor'
import { NodeActionCommandHandler } from './command/action'
import { Paste } from './action/paste/paste'
import { IdGenerator, NodeIdGenerator } from 'application/ids'
import { PasteSnapshotGenerator } from './action/paste/generator'
import { Copy } from './action/copy/copy'
import { PasteTargetHandlerFactory } from './action/paste/target/factory'
import { PastePositionHandlerFactory } from './action/paste/position/factory'
import {
  FontLoaderInterface,
  ReadOnlyFontDataMap,
  Shaper,
} from 'application/text'
import { NameGenerator, NodeNameGenerator } from 'application/name/generator'
import { ClientDocumentHelper } from './document/helper'
import { InitializeCommandHandler } from './command/initialize'
import { CameraService } from 'application/camera'
import { PasteAttributes } from './action/pasteAttributes/pasteAttributes'
import { CopyAttributes } from './action/copyAttributes/copyAttributes'
import { BreakpointEngineFactory } from 'application/breakpoint/engineFactory'
import { BreakpointEngine } from 'application/breakpoint/engine'
import {
  LayoutEngine,
  LayoutEngineFactory,
  LayoutEngineListeners,
} from 'application/layoutEngine'

export class ClientDependencies {
  private registry = new Map()

  constructor(
    fontLoader: FontLoaderInterface,
    textShaper: Shaper,
    fontMap: ReadOnlyFontDataMap,
    cameraService: CameraService
  ) {
    this.registry.set('fontLoader', fontLoader)
    this.registry.set('textShaper', textShaper)
    this.registry.set('fontMap', fontMap)
    this.registry.set('cameraService', cameraService)
  }

  getFontLoader = (): FontLoaderInterface => {
    return this.registry.get('fontLoader')
  }

  getTextShaper = (): Shaper => {
    return this.registry.get('textShaper')
  }

  getFontMap = (): ReadOnlyFontDataMap => {
    return this.registry.get('fontMap')
  }

  getCameraService = (): CameraService => {
    return this.registry.get('cameraService')
  }

  getNameGenerator = (): NameGenerator => {
    if (!this.registry.has('nameGenerator')) {
      const generator = new NodeNameGenerator(
        this.getDocument(),
        process.env.REACT_APP_DEV_MODE === 'true'
      )
      this.registry.set('nameGenerator', generator)
    }
    return this.registry.get('nameGenerator')
  }

  getIdGenerator(): IdGenerator {
    if (!this.registry.has('idGenerator')) {
      this.registry.set('idGenerator', new NodeIdGenerator(this.getDocument()))
    }
    return this.registry.get('idGenerator')
  }

  getCopy = (): Copy => {
    if (!this.registry.has('copy')) {
      const document = this.getDocument()
      const documentSelection = this.getSelection()
      const copy = new Copy(document, documentSelection)
      this.registry.set('copy', copy)
    }
    return this.registry.get('copy')
  }

  getCopyAttributes = (): CopyAttributes => {
    if (!this.registry.has('copyAttributes')) {
      const documentSelection = this.getSelection()
      const copyAttributes = new CopyAttributes(documentSelection)
      this.registry.set('copyAttributes', copyAttributes)
    }
    return this.registry.get('copyAttributes')
  }

  getPasteSnapshotGenerator = (): PasteSnapshotGenerator => {
    if (!this.registry.has('pasteSnapshotGenerator')) {
      const idGenerator = this.getIdGenerator()
      const generator = new PasteSnapshotGenerator(idGenerator)
      this.registry.set('pasteSnapshotGenerator', generator)
    }
    return this.registry.get('pasteSnapshotGenerator')
  }

  getPasteTargetFactory = (): PasteTargetHandlerFactory => {
    if (!this.registry.has('pasteTargetFactory')) {
      const document = this.getDocument()
      const documentSelection = this.getSelection()
      const factory = new PasteTargetHandlerFactory(document, documentSelection)
      this.registry.set('pasteTargetFactory', factory)
    }
    return this.registry.get('pasteTargetFactory')
  }

  getPastePositionFactory = (): PastePositionHandlerFactory => {
    if (!this.registry.has('pastePositionFactory')) {
      const document = this.getDocument()
      const selection = this.getSelection()
      const cameraService = this.getCameraService()
      const factory = new PastePositionHandlerFactory(
        document,
        selection,
        cameraService
      )
      this.registry.set('pastePositionFactory', factory)
    }
    return this.registry.get('pastePositionFactory')
  }

  getPaste = (): Paste => {
    if (!this.registry.has('paste')) {
      const clientDocument = this.getClientDocumentHelper()
      const documentSelection = this.getSelection()
      const snapshotGenerator = this.getPasteSnapshotGenerator()
      const paste = new Paste(
        clientDocument,
        documentSelection,
        snapshotGenerator
      )
      this.registry.set('paste', paste)
    }
    return this.registry.get('paste')
  }

  getPasteAttributes = (): PasteAttributes => {
    if (!this.registry.has('pasteAttributes')) {
      const documentHelper = this.getClientDocumentHelper()
      const documentSelection = this.getSelection()
      const pasteAttributes = new PasteAttributes(
        documentHelper,
        documentSelection
      )
      this.registry.set('pasteAttributes', pasteAttributes)
    }
    return this.registry.get('pasteAttributes')
  }

  getNodeListeners = (): NodeListeners => {
    if (!this.registry.has('nodeListeners')) {
      const listeners = new NodeListeners()
      this.registry.set('nodeListeners', listeners)
    }
    return this.registry.get('nodeListeners')
  }

  getNodeFactory = (): NodeFactory => {
    if (!this.registry.has('nodeFactory')) {
      const nodeListeners = this.getNodeListeners()
      const nodeFactory = new NodeFactory(nodeListeners)
      this.registry.set('nodeFactory', nodeFactory)
    }
    return this.registry.get('nodeFactory')
  }

  getHistoryLog = (): HistoryLog => {
    if (!this.registry.has('historyLog')) {
      const historyLog = new HistoryLog()
      this.registry.set('historyLog', historyLog)
    }
    return this.registry.get('historyLog')
  }

  getHistoryListeners = (): HistoryListeners => {
    if (!this.registry.has('historyListeners')) {
      const historyListeners = new HistoryListeners()
      this.registry.set('historyListeners', historyListeners)
    }
    return this.registry.get('historyListeners')
  }

  getTransactionHandler = (): ClientTransactionHandler => {
    if (!this.registry.has('transactionHandler')) {
      const historyLog = this.getHistoryLog()
      const handler = new ClientTransactionLogHandler(historyLog)
      this.registry.set('transactionHandler', handler)
    }
    return this.registry.get('transactionHandler')
  }

  getNodeTransactionHandler = (): NodeTransactionHistoryHandler => {
    if (!this.registry.has('nodeTransactionHandler')) {
      const transactionHandler = this.getTransactionHandler()
      const handler = new NodeTransactionHistoryHandler(transactionHandler)
      this.registry.set('nodeTransactionHandler', handler)
    }
    return this.registry.get('nodeTransactionHandler')
  }

  getDocumentTransactionHandler = (): DocumentTransactionHistoryHandler => {
    if (!this.registry.has('documentTransactionHandler')) {
      const transactionHandler = this.getTransactionHandler()
      const handler = new DocumentTransactionHistoryHandler(transactionHandler)
      this.registry.set('documentTransactionHandler', handler)
    }
    return this.registry.get('documentTransactionHandler')
  }

  getSelectionTransactionHandler = (): SelectionTransactionHistoryHandler => {
    if (!this.registry.has('selectionTransactionHandler')) {
      const transactionHandler = this.getTransactionHandler()
      const handler = new SelectionTransactionHistoryHandler(transactionHandler)
      this.registry.set('selectionTransactionHandler', handler)
    }
    return this.registry.get('selectionTransactionHandler')
  }

  getTextEditorTransactionHandler = (): TextEditorTransactionHistoryHandler => {
    if (!this.registry.has('textEditorTransactionHandler')) {
      const transactionHandler = this.getTransactionHandler()
      const handler = new TextEditorTransactionHistoryHandler(
        transactionHandler
      )
      this.registry.set('textEditorTransactionHandler', handler)
    }
    return this.registry.get('textEditorTransactionHandler')
  }

  getDocumentListeners = (): DocumentListeners => {
    if (!this.registry.has('documentListeners')) {
      const listeners = new DocumentListeners()
      this.registry.set('documentListeners', listeners)
    }
    return this.registry.get('documentListeners')
  }

  getDocument = (): WriteDocument => {
    if (!this.registry.has('document')) {
      const listeners = this.getDocumentListeners()
      const document = new Document({})
      document.setListener(listeners)
      this.registry.set('document', document)
    }
    return this.registry.get('document')
  }

  getClientDocumentHelper = (): ClientDocumentHelper => {
    if (!this.registry.has('documentHelper')) {
      const document = this.getDocument()
      const nodeFactory = this.getNodeFactory()
      const nameGenerator = this.getNameGenerator()
      const helper = new ClientDocumentHelper(
        document,
        nodeFactory,
        nameGenerator
      )
      this.registry.set('documentHelper', helper)
    }
    return this.registry.get('documentHelper')
  }

  getRulesEngineFactory = (): RulesEngineFactory => {
    if (!this.registry.has('rulesEngineFactory')) {
      const factory = new RulesEngineFactory()
      this.registry.set('rulesEngineFactory', factory)
    }
    return this.registry.get('rulesEngineFactory')
  }

  getRulesEngine = (): RulesEngine => {
    if (!this.registry.has('rulesEngine')) {
      const rulesEngineFactory = this.getRulesEngineFactory()
      const rulesEngine = rulesEngineFactory.create()
      this.registry.set('rulesEngine', rulesEngine)
    }
    return this.registry.get('rulesEngine')
  }

  getClientRulesEngine = (): ClientRulesEngine => {
    if (!this.registry.has('clientRulesEngine')) {
      const rulesEngine = this.getRulesEngine()
      const document = this.getDocument()
      const clientRulesEngine = new ClientRulesEngine(document, rulesEngine)
      this.registry.set('clientRulesEngine', clientRulesEngine)
    }
    return this.registry.get('clientRulesEngine')
  }

  getLayoutEngineListeners = (): LayoutEngineListeners => {
    if (!this.registry.has('layoutEngineListeners')) {
      const listeners = new LayoutEngineListeners()
      this.registry.set('layoutEngineListeners', listeners)
    }
    return this.registry.get('layoutEngineListeners')
  }

  getLayoutEngineFactory = (): LayoutEngineFactory => {
    if (!this.registry.has('layoutEngineFactory')) {
      const factory = new LayoutEngineFactory()
      this.registry.set('layoutEngineFactory', factory)
    }
    return this.registry.get('layoutEngineFactory')
  }

  getLayoutEngine = (): LayoutEngine => {
    if (!this.registry.has('layoutEngine')) {
      const document = this.getDocument()
      const layoutEngineFactory = this.getLayoutEngineFactory()
      const textShaper = this.getTextShaper()
      const layoutEngineListeners = this.getLayoutEngineListeners()
      const layoutEngine = layoutEngineFactory.create(
        document,
        textShaper,
        layoutEngineListeners
      )
      this.registry.set('layoutEngine', layoutEngine)
    }
    return this.registry.get('layoutEngine')
  }

  getClientLayoutEngine = (): ClientLayoutEngine => {
    if (!this.registry.has('clientLayoutEngine')) {
      const layoutEngine = this.getLayoutEngine()
      const clientLayoutEngine = new ClientLayoutEngine(layoutEngine)
      this.registry.set('clientLayoutEngine', clientLayoutEngine)
    }
    return this.registry.get('clientLayoutEngine')
  }

  getBreakpointEngine = (): BreakpointEngine => {
    if (!this.registry.has('breakpointEngine')) {
      const document = this.getDocument()
      const engine = BreakpointEngineFactory.create(document)
      this.registry.set('breakpointEngine', engine)
    }
    return this.registry.get('breakpointEngine')
  }

  getTextEditor = (): TextEditor => {
    if (!this.registry.has('textEditor')) {
      const textEditorFactory = new TextEditorFactory(
        this.getFontLoader(),
        this.getFontMap()
      )
      const textEditor = textEditorFactory.create()
      this.registry.set('textEditor', textEditor)
    }
    return this.registry.get('textEditor')
  }

  getClientTextEditor = (): ClientTextEditor => {
    if (!this.registry.has('clientTextEditor')) {
      const document = this.getDocument()
      const textEditor = this.getTextEditor()
      const history = this.getTextEditorTransactionHandler()
      const update = this.getTextEditorUpdateAccumulator()
      const clientTextEditor = new ClientTextEditor(
        document,
        textEditor,
        history,
        update
      )
      this.registry.set('clientTextEditor', clientTextEditor)
    }
    return this.registry.get('clientTextEditor')
  }

  getSelectionListeners = (): SelectionListeners => {
    if (!this.registry.has('selectionListeners')) {
      const listeners = new SelectionListeners()
      this.registry.set('selectionListeners', listeners)
    }
    return this.registry.get('selectionListeners')
  }

  getSelection = (): DocumentSelection => {
    if (!this.registry.has('selection')) {
      const document = this.getDocument()
      const selectionListeners = this.getSelectionListeners()
      const selection = new DocumentSelection(document, selectionListeners)
      this.registry.set('selection', selection)
    }
    return this.registry.get('selection')
  }

  getDocumentHistoryActionHandler = (): DocumentHistoryActionHandler => {
    if (!this.registry.has('documentHistoryActionHandler')) {
      const document = this.getDocument()
      const handler = new DocumentHistoryActionHandler(document)
      this.registry.set('documentHistoryActionHandler', handler)
    }
    return this.registry.get('documentHistoryActionHandler')
  }

  getSelectionHistoryActionHandler = (): SelectionHistoryActionHandler => {
    if (!this.registry.has('selectionHistoryActionHandler')) {
      const selection = this.getSelection()
      const handler = new SelectionHistoryActionHandler(selection)
      this.registry.set('selectionHistoryActionHandler', handler)
    }
    return this.registry.get('selectionHistoryActionHandler')
  }

  getTextEditorHistoryActionHandler = (): TextEditorHistoryActionHandler => {
    if (!this.registry.has('textEditorHistoryActionHandler')) {
      const document = this.getDocument()
      const textEditor = this.getTextEditor()
      const update = this.getTextEditorUpdateAccumulator()
      const handler = new TextEditorHistoryActionHandler(
        document,
        textEditor,
        update
      )
      this.registry.set('textEditorHistoryActionHandler', handler)
    }
    return this.registry.get('textEditorHistoryActionHandler')
  }

  getNodeActionCommandHandler = (): NodeActionCommandHandler => {
    if (!this.registry.has('nodeActionHandler')) {
      const helper = this.getClientDocumentHelper()
      const selection = this.getSelection()
      const copy = this.getCopy()
      const copyAttributes = this.getCopyAttributes()
      const paste = this.getPaste()
      const pasteAttributes = this.getPasteAttributes()
      const pasteTargetFactory = this.getPasteTargetFactory()
      const pastePositionFactory = this.getPastePositionFactory()
      const handler = new NodeActionCommandHandler(
        helper,
        selection,
        copy,
        copyAttributes,
        paste,
        pasteAttributes,
        pasteTargetFactory,
        pastePositionFactory
      )
      this.registry.set('nodeActionHandler', handler)
    }
    return this.registry.get('nodeActionHandler')
  }

  getHistoryCommandHandler = (): HistoryCommandHandler => {
    if (!this.registry.has('historyHandler')) {
      const historyLog = this.getHistoryLog()
      const historyListeners = this.getHistoryListeners()
      const handler = new HistoryCommandHandler(historyLog, historyListeners)
      this.registry.set('historyHandler', handler)
    }
    return this.registry.get('historyHandler')
  }

  getInitializeCommandHandler = (): InitializeCommandHandler => {
    if (!this.registry.has('initializeHandler')) {
      const transactionHandler = this.getTransactionHandler()
      const updateHandler = this.getUpdateHandler()
      const nodeFactory = this.getNodeFactory()
      const documentHelper = this.getClientDocumentHelper()
      const documentSelection = this.getSelection()
      const handler = new InitializeCommandHandler(
        transactionHandler,
        updateHandler,
        nodeFactory,
        documentHelper,
        documentSelection
      )
      this.registry.set('initializeHandler', handler)
    }
    return this.registry.get('initializeHandler')
  }

  getCommitCommandHandler = (): CommitCommandHandler => {
    if (!this.registry.has('commitHandler')) {
      const transactionHandler = this.getTransactionHandler()
      const handler = new CommitCommandHandler(transactionHandler)
      this.registry.set('commitHandler', handler)
    }
    return this.registry.get('commitHandler')
  }

  getNodeCommandHandler = (): NodeCommandHandler => {
    if (!this.registry.has('nodeHandler')) {
      const nodeFactory = this.getNodeFactory()
      const helper = this.getClientDocumentHelper()
      const handler = new NodeCommandHandler(nodeFactory, helper)
      this.registry.set('nodeHandler', handler)
    }
    return this.registry.get('nodeHandler')
  }

  getSelectCommandHandler = (): SelectCommandHandler => {
    if (!this.registry.has('selectHandler')) {
      const selection = this.getSelection()
      const handler = new SelectCommandHandler(selection)
      this.registry.set('selectHandler', handler)
    }
    return this.registry.get('selectHandler')
  }

  getTextEditorCommandHandler = (): TextEditorCommandHandler => {
    if (!this.registry.has('textHandler')) {
      const clientTextEditor = this.getClientTextEditor()
      const documentSelection = this.getSelection()
      const handler = new TextEditorCommandHandler(
        clientTextEditor,
        documentSelection
      )
      this.registry.set('textHandler', handler)
    }
    return this.registry.get('textHandler')
  }

  getReadOnlyDocument = (): ReadOnlyDocument => {
    if (!this.registry.has('readOnlyDocument')) {
      const document = this.getDocument()
      this.registry.set('readOnlyDocument', document)
    }
    return this.registry.get('readOnlyDocument')
  }

  getUpdateHandler = (): ClientUpdateHandler => {
    if (!this.registry.has('updateHandler')) {
      const handler = new ClientUpdateHandler()
      this.registry.set('updateHandler', handler)
    }
    return this.registry.get('updateHandler')
  }

  getDocumentUpdateAccumulator = (): DocumentUpdateAccumulator => {
    if (!this.registry.has('documentUpdateAccumulator')) {
      const handler = this.getUpdateHandler()
      const accumulator = new DocumentUpdateAccumulator(handler)
      this.registry.set('documentUpdateAccumulator', accumulator)
    }
    return this.registry.get('documentUpdateAccumulator')
  }

  getNodeUpdateAccumulator = (): NodeUpdateAccumulator => {
    if (!this.registry.has('nodeUpdateAccumulator')) {
      const handler = this.getUpdateHandler()
      const accumulator = new NodeUpdateAccumulator(handler)
      this.registry.set('nodeUpdateAccumulator', accumulator)
    }
    return this.registry.get('nodeUpdateAccumulator')
  }

  getSelectionUpdateAccumulator = (): SelectionUpdateAccumulator => {
    if (!this.registry.has('selectionUpdateAccumulator')) {
      const handler = this.getUpdateHandler()
      const accumulator = new SelectionUpdateAccumulator(handler)
      this.registry.set('selectionUpdateAccumulator', accumulator)
    }
    return this.registry.get('selectionUpdateAccumulator')
  }

  getTextEditorUpdateAccumulator = (): TextEditorUpdateAccumulator => {
    if (!this.registry.has('textEditorUpdateAccumulator')) {
      const handler = this.getUpdateHandler()
      const accumulator = new TextEditorUpdateAccumulator(handler)
      this.registry.set('textEditorUpdateAccumulator', accumulator)
    }
    return this.registry.get('textEditorUpdateAccumulator')
  }

  getClient = (): Client => {
    if (!this.registry.has('client')) {
      const readOnlyDocument = this.getReadOnlyDocument()
      const idGenerator = this.getIdGenerator()
      const selection = this.getSelection()
      const updateHandler = this.getUpdateHandler()
      const clientLayoutEngine = this.getClientLayoutEngine()
      const client = new Client(
        readOnlyDocument,
        idGenerator,
        selection,
        updateHandler,
        clientLayoutEngine
      )
      this.registry.set('client', client)
    }
    return this.registry.get('client')
  }
}
