import { Window } from './types'
import { Camera, CameraUpdateListener } from 'application/camera'
import { Rectangle } from 'application/shapes'
import { DesignColor } from 'themes'
import { Canvas, CanvasUpdateListener } from 'editor/canvas/canvas'
import { WindowTransformer } from './transformer/window'

export const hapticMultiselectWindowKey = 'multiselectWindow'

export class HapticMultiselectWindow
  implements CameraUpdateListener, CanvasUpdateListener
{
  private canvas: Canvas

  private window: Window | null
  private camera: Camera | null

  constructor(canvas: Canvas) {
    this.canvas = canvas

    this.window = null
    this.camera = null
  }

  onCamera = (camera: Camera) => {
    this.camera = camera
    this.render()
  }

  onCanvasInit = () => {
    this.render()
  }

  setRectangle = (rectangle: Rectangle | null): void => {
    if (rectangle === null) {
      this.window = null
    } else {
      this.window = {
        rectangle,
        border: DesignColor('inputHighlight'),
        fill: DesignColor('inputHighlightTint1'),
        borderWidth: 1,
        bubbles: 'none',
        shape: 'rectangle',
      }
    }
    this.render()
  }

  private render = () => {
    if (!this.canvas.isReady()) return
    if (this.window === null || this.camera === null) {
      this.canvas.deleteHaptic(hapticMultiselectWindowKey)
    } else {
      this.canvas.setHaptic(
        hapticMultiselectWindowKey,
        WindowTransformer.transform(
          this.canvas.getContext(),
          [this.window],
          this.camera
        )
      )
    }
  }
}
