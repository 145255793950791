import _ from 'lodash'
import { Layout } from '../layout/layout'
import { Shaper } from 'application/text'
import { CommandHandler, EditorState } from '../textEditor'
import { TextCommand, TextCommandSelect } from '../command/types'
import { State } from '../state/types'

export class SelectHandler implements CommandHandler {
  private state: EditorState
  private layout: Layout
  private shaper: Shaper

  constructor(state: EditorState, layout: Layout, shaper: Shaper) {
    this.state = state
    this.layout = layout
    this.shaper = shaper
  }

  handle = (command: TextCommand): void => {
    switch (command.type) {
      case 'select':
        this.handleSelect(command)
        break
    }
  }

  private handleSelect = (command: TextCommandSelect): void => {
    const state = this.state.get()

    switch (command.parameters.mode) {
      case 'word':
        this.selectWord(state, command)
        break
      case 'range':
        this.selectRange(state, command)
        break
      case 'paragraph':
        this.selectParagraph(state, command)
        break
      case 'all':
        this.selectAll(state)
        break
    }

    this.state.set(state)
  }

  private selectRange = (state: State, command: TextCommandSelect): void => {
    const { selection } = state

    const initial = command.parameters.initial
    const current = command.parameters.current
    if (initial === null || current === null) return

    const shapedText = this.shaper.getShapedText(state.content, state.width)
    const start = this.layout.getIndexAtPoint(shapedText, initial)
    const end = this.layout.getIndexAtPoint(shapedText, current)
    if (start === null || end === null) return

    if (_.isEqual(start, end)) {
      selection.anchor = null
    } else {
      selection.anchor = start
    }
    selection.focus = end
  }

  private selectWord = (state: State, command: TextCommandSelect): void => {
    const { selection } = state

    const point = command.parameters.current
    if (point === null) return

    const shapedText = this.shaper.getShapedText(state.content, state.width)
    const word = this.layout.getWordAtPoint(shapedText, point)
    if (word === null) return

    selection.anchor = word.anchor
    selection.focus = word.focus
  }

  private selectParagraph = (
    state: State,
    command: TextCommandSelect
  ): void => {
    const { selection } = state

    const point = command.parameters.current
    if (point === null) return

    const shapedText = this.shaper.getShapedText(state.content, state.width)
    const paragraph = this.layout.getParagraphAtPoint(shapedText, point)
    if (paragraph === null) return

    selection.anchor = paragraph.anchor
    selection.focus = paragraph.focus
  }

  private selectAll = (state: State): void => {
    const { selection } = state

    const shapedText = this.shaper.getShapedText(state.content, state.width)
    const start = this.layout.getStartOfContent()
    const end = this.layout.getEndOfContent(shapedText)
    if (start === null || end === null) return

    selection.anchor = start
    selection.focus = end
  }
}
