import { Properties } from 'application/export/css'
import { TailwindClassRenderer } from '../types'

export class TailwindCursor implements TailwindClassRenderer {
  render = (properties: Partial<Properties>): string[] => {
    const classes: string[] = []

    const cursor = properties.cursor

    if (cursor !== undefined) {
      classes.push(this.renderCursor(cursor))
    }

    return classes
  }

  private renderCursor = (cursor: string): string => {
    return `cursor-[${cursor}]`
  }
}
