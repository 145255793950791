import { IconKey } from 'assets/iconLibrary'
import Icon from '../../Icon/Icon/Icon'
import { DesignColor } from 'themes'

export interface DraggingIconProps {
  icon: IconKey
  warning?: boolean
  iconRotation?: number

  editing: boolean
  dragging: boolean
  setDragging: (dragging: boolean) => void

  increment: (value: number) => void
  decrement: (value: number) => void

  min?: number
  max?: number
  step?: number

  disabled?: boolean
  hasOverride?: boolean
}

export default function DraggingIcon({
  icon,
  warning = false,
  iconRotation = 0,
  disabled = false,
  hasOverride = false,
}: DraggingIconProps) {
  const iconColor = getIconColor(warning, hasOverride, disabled)
  const iconKey = warning ? 'Warning' : icon

  return (
    <div
      style={{
        height: 24,
        width: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div style={{ transform: `rotate(${iconRotation}deg)` }}>
        <Icon icon={iconKey} size={16} color={iconColor} />
      </div>
    </div>
  )
}

function getIconColor(
  warning: boolean,
  hasOverride: boolean,
  disabled: boolean
): string {
  if (disabled && !hasOverride) return DesignColor('overrideParent2')
  if (hasOverride) return DesignColor('overrideParent')
  if (warning) return DesignColor('warning')
  if (disabled) return DesignColor('text3')
  return DesignColor('text2')
}
