import { DesignColor } from 'themes'

export interface ImageIconProps {
  src: string
}

export default function ImageIcon({ src }: ImageIconProps) {
  return (
    <div
      style={{
        height: 24,
        width: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <img
        style={{
          height: 16,
          width: 16,
          borderRadius: 4,
          boxShadow: `inset 0px 0px 0px 1px ${DesignColor('panelBorder')}`,
        }}
        src={src}
        alt="icon"
      />
    </div>
  )
}
