import { CanvasRenderLoopListener } from 'editor/canvas/canvas'

export type FPSPanelState = {
  fps: number
}

export type FPSPanelListener = (settings: FPSPanelState) => void

export class FPSPanel implements CanvasRenderLoopListener {
  private fps: number
  private lastTime: number
  private frameCount: number

  private listeners: { [key: string]: FPSPanelListener } = {}

  constructor() {
    this.listeners = {}

    this.fps = 0
    this.lastTime = 0
    this.frameCount = 0
  }

  getSettings = (): FPSPanelState => {
    return {
      fps: this.fps,
    }
  }

  getHandlers = () => {
    return {}
  }

  subscribe = (key: string, listener: FPSPanelListener) => {
    this.listeners[key] = listener
  }

  unsubscribe = (key: string) => {
    delete this.listeners[key]
  }

  onRender = () => {
    this.frameCount++
    const currentTime = performance.now()
    const timeDiff = currentTime - this.lastTime
    if (timeDiff > 1000) {
      this.fps = this.frameCount / (timeDiff / 1000)
      this.frameCount = 0
      this.lastTime = currentTime
    }
    this.notifyListeners()
  }

  private notifyListeners = () => {
    const settings = this.getSettings()
    for (const key in this.listeners) {
      this.listeners[key](settings)
    }
  }
}
