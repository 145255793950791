import { IconMap, IconKey } from 'assets/iconLibrary'

export type iconSize = 42 | 24 | 20 | 16 | 12 | 10

interface IconProps {
  icon: IconKey
  size: iconSize
  color: string
}

export default function Icon({ icon, size, color }: IconProps) {
  const IconElement = IconMap[icon]

  return (
    <div
      style={{
        width: size,
        height: size,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: 0,
      }}
    >
      <IconElement
        style={{
          width: '100%',
          height: '100%',
          color: color,
        }}
      />
    </div>
  )
}
