import { Command, CommandHandler } from './types'
import { HistoryLog, HistoryListeners } from 'application/history'

export class HistoryCommandHandler implements CommandHandler {
  private historyLog: HistoryLog
  private historyListeners: HistoryListeners

  constructor(historyLog: HistoryLog, historyListeners: HistoryListeners) {
    this.historyLog = historyLog
    this.historyListeners = historyListeners
  }

  handle = (command: Command): void => {
    switch (command.type) {
      case 'undo':
        this.undo()
        return
      case 'redo':
        this.redo()
        return
      case 'editTextCommand':
        if (command.params.command.type !== 'history') return
        switch (command.params.command.parameters.mode) {
          case 'undo':
            this.undo()
            return
          case 'redo':
            this.redo()
            return
        }
    }
  }

  private undo = (): void => {
    const entry = this.historyLog.undo()
    if (!entry) return

    this.historyListeners.notifyStartUndo()
    const reversed = [...entry.events].reverse()
    for (const e of reversed) {
      this.historyListeners.notifyUndo(e)
    }
    this.historyListeners.notifyEndUndo()
  }

  private redo = (): void => {
    const entry = this.historyLog.redo()
    if (!entry) return

    this.historyListeners.notifyStartRedo()
    for (const e of entry.events) {
      this.historyListeners.notifyRedo(e)
    }
    this.historyListeners.notifyEndRedo()
  }
}
