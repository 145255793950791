import { TemplateInfo } from 'application/service'
import { panelShadow } from 'components/Library/Containers/constants'
import { DesignColor } from 'themes'
import { useCallback, useEffect, useRef, useState } from 'react'
import useHovered from 'hooks/ui/useHovered'
import useAction from 'hooks/editor/useAction'

interface TemplateCardProps {
  template: TemplateInfo
  drag(template: TemplateInfo): void
}

export default function TemplateCard({ template, drag }: TemplateCardProps) {
  const cardRef = useRef<HTMLDivElement>(null)
  const action = useAction()
  const { hovered, setHovered } = useHovered<boolean>({ ref: cardRef })

  const boxShadow = getBoxShadow(hovered || false)
  const [dragging, setDragging] = useState(false)
  const [startedDragging, setStartedDragging] = useState(false)
  const [dragOffset, setDragOffset] = useState({ x: 0, y: 0 })

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    setDragging(true)
    setDragOffset({ x: e.clientX, y: e.clientY })
  }

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      if (dragging && !startedDragging) {
        const diffX = Math.abs(e.clientX - dragOffset.x)
        const diffY = Math.abs(e.clientY - dragOffset.y)
        if (diffX > 10 || diffY > 10) {
          setStartedDragging(true)
          drag(template)
        }
      }
    },
    [dragging, dragOffset, drag, template, startedDragging]
  )

  const handleMouseUp = useCallback(() => {
    setDragging(false)
    setStartedDragging(false)
  }, [])

  useEffect(() => {
    if (action === 'openTemplateLibrary') {
      setDragging(false)
      setStartedDragging(false)
    }
  }, [action])

  useEffect(() => {
    window.addEventListener('mousemove', handleMouseMove)
    window.addEventListener('mouseup', handleMouseUp)
    return () => {
      window.removeEventListener('mousemove', handleMouseMove)
      window.removeEventListener('mouseup', handleMouseUp)
    }
  }, [dragging, handleMouseMove, handleMouseUp])

  return (
    <div
      ref={cardRef}
      style={{
        width: 216,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 8,
        boxSizing: 'border-box',
        boxShadow: boxShadow,
        backgroundColor: DesignColor('canvasBackground'),
        borderRadius: 8,
        cursor: 'grab',
      }}
      onMouseDown={handleMouseDown}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <img
        src={template.previewUrl}
        style={{ width: '100%' }}
        alt={template.name}
        onDragStart={(e) => e.preventDefault()}
        onDragEnd={(e) => e.preventDefault()}
      />
    </div>
  )
}

function getBoxShadow(hovered: boolean) {
  if (hovered) {
    return [
      panelShadow,
      `inset 0px 0px 0px 1px ${DesignColor('inputHighlight')}`,
    ].join(', ')
  }
  return panelShadow
}
