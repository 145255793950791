import { DesignColor } from 'themes'
import {
  panelBorderBottom,
  panelBorderRight,
  panelHeight,
  panelWidth,
} from '../constants'
import { getPanelWidth } from '../utils'

interface PanelSectionProps {
  id?: string

  width?: panelWidth
  height?: panelHeight
  maxHeight?: number

  paddingT?: number
  paddingB?: number
  paddingL?: number
  paddingR?: number

  active?: boolean
  bottom?: boolean
  right?: boolean

  children?: React.ReactNode

  sectionRef?: React.RefObject<HTMLDivElement>

  onMouseEnter?: () => void
  onMouseLeave?: () => void
}

export default function PanelSection({
  id,
  width = 232,
  height,
  maxHeight,
  paddingT = 8,
  paddingB = 8,
  paddingL = 0,
  paddingR = 0,
  active = true,
  bottom = false,
  right = false,
  children,
  sectionRef,
  onMouseEnter,
  onMouseLeave,
}: PanelSectionProps) {
  const background = getBackground(active)

  return (
    <div
      id={id}
      ref={sectionRef}
      style={{
        position: 'relative',
        width: getPanelWidth(width),
        maxHeight: maxHeight,
        padding: `${paddingT}px ${paddingR}px ${paddingB}px ${paddingL}px`,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'start',
        boxShadow: getBorder(bottom, right),
        background: background,
        userSelect: 'none',
        overflow: 'hidden',
        flexGrow: height === 'fill' ? 1 : 0,
        flexShrink: height === 'fill' ? 1 : 0,
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </div>
  )
}

function getBackground(active: boolean) {
  return active ? 'none' : DesignColor('panelTint')
}

function getBorder(bottom: boolean, right: boolean) {
  if (!bottom && right) return panelBorderBottom + ', ' + panelBorderRight
  if (!bottom) return panelBorderBottom
  if (right) return panelBorderRight
  return 'none'
}
