import {
  DropEventListener,
  KeyDownListener,
  KeyUpListener,
  MouseDownListener,
  MouseMoveListener,
  MouseUpListener,
  PasteEventListener,
  ResizeEventListener,
  UnloadEventListener,
  WheelEventListener,
} from './types'

export class BrowserEventService {
  private active: boolean
  private mouseDownListeners: MouseDownListener[]
  private mouseMoveListeners: MouseMoveListener[]
  private mouseUpListeners: MouseUpListener[]
  private keyDownListeners: KeyDownListener[]
  private keyUpListeners: KeyUpListener[]
  private wheelEventListeners: WheelEventListener[]
  private resizeEventListeners: ResizeEventListener[]
  private pasteEventListeners: PasteEventListener[]
  private dropEventListeners: DropEventListener[]
  private unloadEventListeners: UnloadEventListener[]

  constructor() {
    this.active = false
    this.mouseDownListeners = []
    this.mouseMoveListeners = []
    this.mouseUpListeners = []
    this.keyDownListeners = []
    this.keyUpListeners = []
    this.wheelEventListeners = []
    this.resizeEventListeners = []
    this.pasteEventListeners = []
    this.dropEventListeners = []
    this.unloadEventListeners = []
  }

  init = () => {
    this.active = true
    this.addMouseDownListeners()
    this.addMouseUpListeners()
    this.addMouseMoveListeners()
    this.addKeyDownListeners()
    this.addKeyUpListeners()
    this.addWheelListeners()
    this.addResizeListeners()
    this.addPasteListeners()
    this.addDragOverListeners()
    this.addDragEnterListeners()
    this.addDragLeaveListeners()
    this.addDropListeners()
    this.addUnloadListeners()
  }

  cleanup = () => {
    this.active = false
    window.removeEventListener('mousedown', this.forwardMouseDown)
    window.removeEventListener('mouseup', this.forwardMouseUp)
    window.removeEventListener('mousemove', this.forwardMouseMove)
    window.removeEventListener('keydown', this.forwardKeyDown)
    window.removeEventListener('keyup', this.forwardKeyUp)
    window.removeEventListener('wheel', this.forwardWheel)
    window.removeEventListener('resize', this.forwardResize)
    window.removeEventListener('paste', this.forwardPaste)
    window.removeEventListener('dragover', this.forwardDragOver)
    window.removeEventListener('dragenter', this.forwardDragEnter)
    window.removeEventListener('dragleave', this.forwardDragLeave)
    window.removeEventListener('drop', this.forwardDrop)
    window.removeEventListener('unload', this.forwardUnload)
  }

  setActive = (active: boolean) => {
    this.active = active
  }

  registerMouseDownListener = (listener: MouseDownListener) => {
    this.mouseDownListeners.push(listener)
  }

  registerMouseMoveListener = (listener: MouseMoveListener) => {
    this.mouseMoveListeners.push(listener)
  }

  registerMouseUpListener = (listener: MouseUpListener) => {
    this.mouseUpListeners.push(listener)
  }

  registerKeyDownListener = (listener: KeyDownListener) => {
    this.keyDownListeners.push(listener)
  }

  registerKeyUpListener = (listener: KeyUpListener) => {
    this.keyUpListeners.push(listener)
  }

  registerWheelEventListener = (listener: WheelEventListener) => {
    this.wheelEventListeners.push(listener)
  }

  registerResizeEventListener = (listener: ResizeEventListener) => {
    this.resizeEventListeners.push(listener)
  }

  registerPasteEventListener = (listener: PasteEventListener) => {
    this.pasteEventListeners.push(listener)
  }

  registerDropEventListener = (listener: DropEventListener) => {
    this.dropEventListeners.push(listener)
  }

  registerUnloadEventListener = (listener: UnloadEventListener) => {
    this.unloadEventListeners.push(listener)
  }

  private addMouseDownListeners = () => {
    window.addEventListener('mousedown', this.forwardMouseDown)
  }

  private addMouseUpListeners = () => {
    window.addEventListener('mouseup', this.forwardMouseUp)
  }

  private addMouseMoveListeners = () => {
    window.addEventListener('mousemove', this.forwardMouseMove)
  }

  private addKeyDownListeners = () => {
    window.addEventListener('keydown', this.forwardKeyDown)
  }

  private addKeyUpListeners = () => {
    window.addEventListener('keyup', this.forwardKeyUp)
  }

  private addWheelListeners = () => {
    window.addEventListener('wheel', this.forwardWheel, { passive: false })
  }

  private addResizeListeners = () => {
    window.addEventListener('resize', this.forwardResize)
  }

  private addPasteListeners = () => {
    window.addEventListener('paste', this.forwardPaste)
  }

  private addDragOverListeners = () => {
    window.addEventListener('dragover', this.forwardDragOver)
  }

  private addDragEnterListeners = () => {
    window.addEventListener('dragenter', this.forwardDragEnter)
  }

  private addDragLeaveListeners = () => {
    window.addEventListener('dragleave', this.forwardDragLeave)
  }

  private addDropListeners = () => {
    window.addEventListener('drop', this.forwardDrop)
  }

  private addUnloadListeners = () => {
    window.addEventListener('unload', this.forwardUnload)
  }

  private forwardMouseDown = (event: MouseEvent) => {
    if (!this.active) return
    this.mouseDownListeners.forEach((listener) => {
      listener.handleMouseDown(event)
    })
  }

  private forwardMouseUp = (event: MouseEvent) => {
    if (!this.active) return
    this.mouseUpListeners.forEach((listener) => {
      listener.handleMouseUp(event)
    })
  }

  private forwardMouseMove = (event: MouseEvent) => {
    if (!this.active) return
    this.mouseMoveListeners.forEach((listener) => {
      listener.handleMouseMove(event)
    })
  }

  private forwardKeyDown = (event: KeyboardEvent) => {
    if (!this.active) return
    this.keyDownListeners.forEach((listener) => {
      listener.handleKeyDown(event)
    })
  }

  private forwardKeyUp = (event: KeyboardEvent) => {
    if (!this.active) return
    this.keyUpListeners.forEach((listener) => {
      listener.handleKeyUp(event)
    })
  }

  private forwardWheel = (event: WheelEvent) => {
    if (!this.active) return
    this.wheelEventListeners.forEach((listener) => {
      listener.handleWheel(event)
    })
  }

  private forwardResize = (event: UIEvent) => {
    if (!this.active) return
    this.resizeEventListeners.forEach((listener) => {
      listener.handleResize(event)
    })
  }

  private forwardPaste = (event: ClipboardEvent) => {
    if (!this.active) return
    this.pasteEventListeners.forEach((listener) => {
      listener.handlePaste(event)
    })
  }

  private forwardDragOver = (event: DragEvent) => {
    event.preventDefault()
  }

  private forwardDragEnter = (event: DragEvent) => {
    event.preventDefault()
  }

  private forwardDragLeave = (event: DragEvent) => {
    event.preventDefault()
  }

  private forwardDrop = (event: DragEvent) => {
    if (!this.active) return
    this.dropEventListeners.forEach((listener) => {
      listener.handleDrop(event)
    })
  }

  private forwardUnload = () => {
    if (!this.active) return
    this.unloadEventListeners.forEach((listener) => {
      listener.handleUnload()
    })
  }
}
