import { CommandHandler, EditorState } from '../textEditor'
import {
  TextCommand,
  TextCommandApplyAllStyle,
  TextCommandApplyStyle,
} from '../command/types'
import { Styles } from '../styles/styles'
import { State } from '../state/types'

export class StyleHandler implements CommandHandler {
  private state: EditorState
  private styles: Styles

  constructor(state: EditorState, styles: Styles) {
    this.state = state
    this.styles = styles
  }

  handle = (command: TextCommand): void => {
    switch (command.type) {
      case 'style':
        this.handleStyle(command)
        break
      case 'allStyle':
        this.handleAllStyle(command)
        break
    }
  }

  private handleStyle = (command: TextCommandApplyStyle): void => {
    const state = this.state.get()

    this.applyStyle(state, command)

    this.state.set(state)
  }

  private handleAllStyle = (command: TextCommandApplyAllStyle): void => {
    const state = this.state.get()

    this.applyAllStyle(state, command)

    this.state.set(state)
  }

  private applyStyle = (state: State, command: TextCommandApplyStyle): void => {
    if (state.selection.anchor === null) {
      state.nextCharacter = {
        ...state.nextCharacter,
        ...command.parameters.style,
      }
    } else {
      this.styles.apply(
        state.content,
        state.selection,
        command.parameters.style
      )
    }
  }

  private applyAllStyle = (
    state: State,
    command: TextCommandApplyAllStyle
  ): void => {
    this.styles.applyAll(state.content, command.parameters.style)
  }
}
