import { DesignColor } from 'themes'
import Dropdown, { dropdownOption } from '../Dropdown/Dropdown'
import TextBlock from '../Text/TextBlock/TextBlock'
import Icon from '../Icon/Icon/Icon'
import CTAButton from '../Button/CTAButton/CTAButton'

interface CodeBlockProps<T> {
  selected: T | null
  select: (value: T) => void

  locked: boolean
  upgrade?: () => void

  options: dropdownOption<T>[]
}

export default function CodeBlock<T>({
  selected,
  select,
  locked = false,
  upgrade,
  options,
  children,
}: CodeBlockProps<T> & React.PropsWithChildren) {
  return (
    <div
      style={{
        width: 216,
        padding: '4px 8px',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          width: '100%',
          background: '#1E1E1E',
          borderRadius: 4,
          border: `1px solid ${DesignColor('panelBorder')}`,
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            gap: 4,
            padding: `4px 4px 4px 0px`,
            boxSizing: 'border-box',
            borderBottom: `1px solid ${DesignColor('panelBorder')}`,
          }}
        >
          {options.length === 1 && (
            <TextBlock width={'fill'} mode={'label1'}>
              {options[0].text}
            </TextBlock>
          )}
          {options.length > 1 && (
            <Dropdown
              width={120}
              options={options}
              selected={selected}
              select={select}
            />
          )}
        </div>
        <div style={{ position: 'relative' }}>
          {children}
          {locked && (
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                boxSizing: 'border-box',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 8,
                background: '#00000020',
                backdropFilter: 'blur(5px)',
                borderRadius: 4,
              }}
            >
              <Icon
                icon={'LockClosed'}
                color={DesignColor('text1')}
                size={24}
              />
              <CTAButton
                width={'hug'}
                text={'Upgrade for full code export'}
                onClick={upgrade}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
