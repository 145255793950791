import ToolbarIconButton from 'components/Library/Components/Toolbar/ToolbarIconButton/ToolbarIconButton'
import Tooltip from 'components/Library/Components/Tooltip/Tooltip'
import useNavigatePage from 'hooks/admin/useNavigatePage'

export default function RepaintButton() {
  const { toProjects } = useNavigatePage()

  return (
    <Tooltip tooltipKey={'MainMenu'}>
      <ToolbarIconButton icon={'RepaintLogo'} onClick={toProjects} />
    </Tooltip>
  )
}
