import { DesignColor } from 'themes'
import Text from 'components/Library/Text/Text'

interface PresetDropdownRowProps {
  value: number

  selected: boolean

  hover: boolean

  onMouseEnter: () => void
  onMouseLeave: () => void
  onClick: () => void
}

export default function PresetDropdownRow({
  value,
  selected,
  hover,
  onMouseEnter,
  onMouseLeave,
  onClick,
}: PresetDropdownRowProps) {
  const textColor = getTextColor(selected)
  const background = getBackground(hover)

  return (
    <div
      style={{
        width: 96,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background,
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
    >
      <Text color={textColor}>{value}</Text>
    </div>
  )
}

function getBackground(hovered: boolean) {
  if (hovered) return DesignColor('inputTint')
  return 'none'
}

function getTextColor(selected: boolean) {
  if (selected) return DesignColor('inputHighlight')
  return DesignColor('text1')
}
