import { AttributeType } from 'application/attributes'
import { ScreenSize, getScreenSize } from 'application/shapes'
import { ReadOnlyNode } from 'application/node'
import { StyleAttributePanel } from './styleAttributePanel'

export interface ScreenSizePanelState {
  screen: 'Mixed' | ScreenSize | null
}

export interface ScreenSizePanelHandlers {
  setScreen: (size: ScreenSize) => void
}

export class ScreenSizePanel extends StyleAttributePanel<
  ScreenSizePanelState,
  ScreenSizePanelHandlers,
  never
> {
  getSettings(): ScreenSizePanelState {
    return {
      screen: this.getScreen(),
    }
  }

  getHandlers(): ScreenSizePanelHandlers {
    return {
      setScreen: this.setScreen,
    }
  }

  private getScreen = (): 'Mixed' | ScreenSize | null => {
    const nodes = this.getNodes()
    if (nodes.length === 0) return null

    const firstNode = nodes[0]
    const width = firstNode.getBaseAttribute('w')
    for (const node of nodes) {
      if (node.getBaseAttribute('w') !== width) return 'Mixed'
    }

    const screen = getScreenSize(width)
    if (!screen) {
      return {
        label: 'Custom',
        w: width,
        h: firstNode.getBaseAttribute('h'),
      }
    }
    return screen
  }

  private setScreen = (size: ScreenSize): void => {
    this.setMulti({
      'size.w.unit': 'px',
      'size.h.unit': 'px',
      'size.w.px': size.w,
      'size.h.px': size.h,
    })
    this.commit()
  }

  protected override getNodeFilterPredicate = (): ((
    node: ReadOnlyNode,
    parent: ReadOnlyNode | null
  ) => boolean) => {
    return (node, parent) =>
      allowedTypes.includes(node.getBaseAttribute('type')) &&
      parent?.getBaseAttribute('type') === 'canvas'
  }
}

const allowedTypes: AttributeType[] = ['frame', 'page']
