import {
  State,
  TextCommand,
  TextEditor,
  attributesToTextEditorState,
  textEditorStateToAttributes,
} from 'application/textEditor'
import { TextEditorTransactionHistoryHandler } from '../transaction/textEditor'
import { WriteDocument } from 'application/document'
import {
  BaseMap,
  isContentContainerType,
  StyleMap,
} from 'application/attributes'
import _ from 'lodash'
import { TextEditorUpdateAccumulator } from '../update/textEditor'
import { Node, NodeUpdateListener } from 'application/node'
import { HistoryActionListener } from 'application/history'

export class ClientTextEditor
  implements NodeUpdateListener, HistoryActionListener
{
  private document: WriteDocument
  private textEditor: TextEditor
  private history: TextEditorTransactionHistoryHandler
  private update: TextEditorUpdateAccumulator

  private historyActionInProgress = false

  constructor(
    document: WriteDocument,
    textEditor: TextEditor,
    history: TextEditorTransactionHistoryHandler,
    update: TextEditorUpdateAccumulator
  ) {
    this.document = document
    this.textEditor = textEditor
    this.history = history
    this.update = update

    this.historyActionInProgress = false
  }

  setEditing = (id: string | null): void => {
    if (id === null) {
      this.stopEditing()
    } else {
      this.startEditing(id)
    }
  }

  handleCommand = (command: TextCommand): void => {
    const state = this.textEditor.getState()
    const initial = _.cloneDeep(state)

    this.updateWidth()
    this.textEditor.handleCommand(command)

    const updatedState = this.textEditor.getState()
    if (_.isEqual(initial, updatedState)) return

    this.updateNode(updatedState)
    this.onTextState(initial, updatedState)
  }

  onBaseAttribute = (id: string, key: keyof BaseMap): void => {
    if (this.textEditor.getId() !== id) return
    if (this.historyActionInProgress || key !== 'w') return

    const state = this.textEditor.getState()
    const initial = _.cloneDeep(state)

    this.updateWidth()

    const updatedState = this.textEditor.getState()
    if (_.isEqual(initial, updatedState)) return

    this.onTextState(initial, updatedState)
  }

  onStartUndo = (): void => {
    this.historyActionInProgress = true
  }

  onEndUndo = (): void => {
    this.historyActionInProgress = false
  }

  onStartRedo = (): void => {
    this.historyActionInProgress = true
  }

  onEndRedo = (): void => {
    this.historyActionInProgress = false
  }

  private startEditing = (id: string): void => {
    if (this.textEditor.getId() === id) return

    const node = this.getContentNode(id)
    if (!node) return

    const parent = this.getStyleNode(id)
    if (!parent) return

    const attributeState = attributesToTextEditorState(node, parent)
    if (attributeState === null) return

    this.textEditor.setId(id)
    this.textEditor.setState(attributeState)
    this.textEditor.handleCommand(selectAllCommand)

    const state = this.textEditor.getState()

    this.onEditText(null, id)
    this.onTextState(null, state)
  }

  private stopEditing = (): void => {
    if (this.textEditor.getId() === null) return

    const id = this.textEditor.getId()
    const state = _.cloneDeep(this.textEditor.getState())

    this.textEditor.setId(null)
    this.textEditor.clearState()

    this.onEditText(id, null)
    this.onTextState(state, null)
  }

  private updateNode = (current: State): void => {
    const id = this.textEditor.getId()
    if (id === null) return

    const contentNode = this.getContentNode(id)
    if (!contentNode) return

    const parent = this.getStyleNode(id)
    if (!parent) return

    const { style, base } = textEditorStateToAttributes(current)
    for (const [key, value] of Object.entries(style)) {
      const current = parent.getStyleAttribute(key as keyof StyleMap)
      if (_.isEqual(value, current)) continue
      parent.setStyleAttribute(key as keyof StyleMap, value)
    }
    for (const [key, value] of Object.entries(base)) {
      const current = contentNode.getBaseAttribute(key as keyof BaseMap)
      if (_.isEqual(value, current)) continue
      contentNode.setBaseAttribute(key as keyof BaseMap, value)
    }

    const nameOverridden = contentNode.getBaseAttribute('nameOverridden')
    if (!nameOverridden) {
      contentNode.setBaseAttribute(
        'name',
        current.content.content.slice(0, 100)
      )
    }
  }

  private onEditText = (
    initialId: string | null,
    currentId: string | null
  ): void => {
    this.update.onEditContent(currentId)
    this.history.onEditText(initialId, currentId)
  }

  private onTextState = (
    initial: State | null,
    current: State | null
  ): void => {
    this.update.onTextState(current)
    this.history.onTextState(initial, current)
  }

  private updateWidth = (): void => {
    const id = this.textEditor.getId()
    if (id === null) return

    const node = this.document.getNode(id)
    if (!node) return

    const width = node.getBaseAttribute('w')
    this.textEditor.handleCommand({ type: 'width', parameters: { width } })
  }

  private getContentNode = (id: string): Node | null => {
    const node = this.document.getNode(id)
    if (!node) return null
    return node
  }

  private getStyleNode = (id: string): Node | null => {
    const node = this.document.getNode(id)
    if (!node) return null

    switch (node.getBaseAttribute('type')) {
      case 'content':
        const parent = this.document.getParent(node)
        if (!parent) return null
        const parentType = parent.getBaseAttribute('type')
        if (!isContentContainerType(parentType)) return null
        return parent
      default:
        return node
    }
  }
}

const selectAllCommand: TextCommand = {
  type: 'select',
  parameters: {
    mode: 'all',
    initial: null,
    current: null,
  },
}
