import { TextContent } from 'application/text'
import { HtmlTag } from '../types/types'
import { Element } from './element'

export class ElementP extends Element {
  static create = (content: TextContent): Element => {
    const element = new Element(HtmlTag.tag_p)
    element.textContent = content
    return element
  }
}
