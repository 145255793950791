import { LayoutEngineListener } from './types'

export class LayoutEngineListeners {
  private listeners: LayoutEngineListener[]

  constructor() {
    this.listeners = []
  }

  registerListener(listener: LayoutEngineListener) {
    this.listeners.push(listener)
  }

  onStartLayout() {
    for (const listener of this.listeners) {
      if (listener.onStartLayout) {
        listener.onStartLayout()
      }
    }
  }

  onFinishLayout() {
    for (const listener of this.listeners) {
      if (listener.onFinishLayout) {
        listener.onFinishLayout()
      }
    }
  }
}
