import { PositionContext } from '../context/positionContext'
import { SizeContext } from '../context/sizeContext'
import { LayoutItem } from '../layoutItem/layoutItem'
import { TextContentSize, TextSizeCalculator } from './textSize'
import { LayoutItemType, SizeValueType } from '../types/types'

export class TextLayout extends LayoutItem {
  calculator: TextSizeCalculator | null = null
  private cachedSizes: { [key: number]: TextContentSize } = {}
  private cachedDefaultSize: TextContentSize | null = null

  getType = (): LayoutItemType => LayoutItemType.text

  override reset = (): void => {
    super.reset()
    this.cachedSizes = {}
    this.cachedDefaultSize = null
  }

  resize = (context: SizeContext): void => {
    if (this.setSizeFromCache(context)) return

    const width = this.computeWidth(context)
    this.setWidth(this.minMaxWidth(width, context))

    const height = this.computeHeight(width, context)
    this.setHeight(this.minMaxHeight(height, context))

    this.cacheSize(context)
  }

  place = (context: PositionContext): void => {
    this.placeSelf(context)
  }

  override getMinContentContributionWidth = (context: SizeContext): number => {
    if (!this.element!.textContent) return 0

    const minWidth = this.getMinWidth(context) ?? 0

    const size = this.computeContentSize()
    const value = Math.max(minWidth, size.minW)

    return value
  }

  override getMinContentContributionHeight = (context: SizeContext): number => {
    if (!this.element!.textContent) return 0

    const width = this.computeWidth(context)
    const height = this.computeHeight(width, context)

    return height
  }

  override getMaxContentContributionWidth = (context: SizeContext): number => {
    if (!this.element!.textContent) return 0

    const minWidth = this.getMinWidth(context) ?? 0

    const size = this.computeContentSize()
    const value = Math.max(minWidth, size.w)

    return value
  }

  override getMaxContentContributionHeight = (context: SizeContext): number => {
    if (!this.element!.textContent) return 0

    const width = this.computeWidth(context)
    const height = this.computeHeight(width, context)

    return height
  }

  private computeWidth = (context: SizeContext): number => {
    if (!this.element!.textContent) return 0

    switch (context.width.type) {
      case SizeValueType.max_content:
        return this.getMaxContentContributionWidth(context)
      case SizeValueType.min_content:
        return this.getMinContentContributionWidth(context)
      case SizeValueType.exact:
        return context.width.value
    }

    const preferredWidth = this.getPreferredWidth(context)
    if (preferredWidth !== undefined) {
      return this.minMaxWidth(preferredWidth, context)
    }

    const transferredWidth = this.getTransferredWidth(context)
    if (transferredWidth !== undefined) {
      return this.minMaxWidth(
        this.minMaxTransferredWidth(transferredWidth, context),
        context
      )
    }

    const size = this.computeContentSize(context.width.value)
    return this.minMaxWidth(
      size.wraps ? Math.max(context.width.value, size.minW) : size.w,
      context
    )
  }

  private computeHeight = (width: number, context: SizeContext): number => {
    if (!this.element!.textContent) return 0
    switch (context.height.type) {
      case SizeValueType.exact:
        return context.height.value
      default:
        const preferredHeight = this.getPreferredHeight(context)
        if (preferredHeight !== undefined) {
          return this.minMaxHeight(preferredHeight, context)
        }

        const sizeContext = this.sizeContextPool!.fromContext(context)
        sizeContext.width.type = SizeValueType.exact
        sizeContext.width.value = width

        const transferredHeight = this.getTransferredHeight(sizeContext)
        if (transferredHeight !== undefined) {
          this.sizeContextPool!.release(sizeContext)
          return this.minMaxHeight(
            this.minMaxTransferredHeight(transferredHeight, context),
            context
          )
        }

        this.sizeContextPool!.release(sizeContext)
    }

    const size = this.computeContentSize(width)
    return this.minMaxHeight(size.h, context)
  }

  private computeContentSize = (width?: number): TextContentSize => {
    if (!this.element!.textContent) {
      return { w: 0, h: 0, minW: 0, minH: 0, wraps: false }
    }

    if (width === undefined) {
      if (this.cachedDefaultSize) return this.cachedDefaultSize

      const size = this.calculator!.getContentSize(this.element!.textContent)
      this.cachedDefaultSize = size
      return size
    }

    const cachedSize = this.cachedSizes[width]
    if (cachedSize) return cachedSize

    const size = this.calculator!.getContentSize(
      this.element!.textContent,
      width
    )
    this.cachedSizes[width] = size
    return size
  }
}
