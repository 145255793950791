import { Color, rgbaToWebgl } from 'application/color'
import { Rectangle } from 'application/shapes'
import { calcBoundingBox, calcRounding } from '../data'
import { cleanupVaoData, connectBuffersToVao, standardDraw } from '../utils'
import { VaoData } from '../types'
import { BlendMode, Context } from '../gpu/context'
import { VertexBuffer } from '../gpu/buffer'
import { UniformValue } from '../gpu/uniform'
import { MaterialType } from './shaderMap'
import { isPointInBox } from '../shaderUtils'
import { IconKey } from 'assets/iconLibrary'
import { IconLoader } from 'application/render/svg/iconLoader'
import { SvgJson } from 'application/render/svg/types'
import { WebglTransfer } from './webglTransfer'

const JITTER_PATTERN = [
  [-5 / 12, -3 / 12],
  [-3 / 12, 1 / 12],
  [-1 / 12, 5 / 12],
  [1 / 12, -5 / 12],
  [3 / 12, -1 / 12],
  [5 / 12, 3 / 12],
]

export type WebglIconData = Rectangle & {
  key: IconKey
  color: Color
  bb?: Rectangle
  bbRounding?: { tl: number; tr: number; br: number; bl: number }
}

export class WebglIcon {
  private context: Context
  private data: WebglIconData
  private paths: VaoData[]
  private iconLoader: IconLoader
  private transferrer: WebglTransfer | null

  constructor(context: Context, data: WebglIconData, iconLoader: IconLoader) {
    this.context = context
    this.data = data
    this.paths = []
    this.iconLoader = iconLoader
    this.transferrer = null
  }

  init = (): void => {
    this.updateRenderData()
  }

  draw(depth: number = 0): void {
    this.paths.forEach((path) => {
      if (!this.transferrer) return
      this.context.setBlendMode(BlendMode.jitter)
      this.context.setDrawingTarget('temp1')

      // These clear statements are required for AA. But it causes lag. TODO: make icons not lag
      // this.context.setClearColor(glClearColors.transparent)
      // this.context.clear()

      JITTER_PATTERN.forEach((jitter) => {
        const dpr = window.devicePixelRatio
        path.uniforms['uJitter'] = [jitter[0] / dpr, jitter[1] / dpr]
        standardDraw(path)
      })

      this.context.setBlendMode(BlendMode.default)
      this.transferrer.draw(depth)
    })

    // this.context.setClearColor(glClearColors.default)
  }

  updateRenderData(): void {
    this.cleanup()

    const { x, y, w, h } = this.data
    this.transferrer = new WebglTransfer(this.context, {
      source: 'temp1',
      bb: { x, y, w, h },
    })
    this.transferrer.init()

    const svg = this.iconLoader.getIcon(this.data.key)
    const triangles = this.iconLoader.getIconTriangles(this.data.key)
    if (!svg || !triangles) return

    for (let i = 0; i < svg.paths.length; i++) {
      const path = this.createPathVaoData(triangles[i], svg)
      this.paths.push(path)
    }
  }

  private createPathVaoData = (aTriangles: number[], svg: SvgJson): VaoData => {
    const gl = this.context.getGl()
    const material = this.context.getMaterial(MaterialType.icon)
    const vao = gl.createVertexArray()
    const buffers: { [key: string]: VertexBuffer } = {}
    const uniforms: { [key: string]: UniformValue } = {}

    const { x, y, w, h, color, bb, bbRounding } = this.data

    const aPosition = new Float32Array(aTriangles)
    const uColor = rgbaToWebgl(color)
    const uRect = [x, y, w, h]
    const uSvgResolution = [svg.w, svg.h]
    const uBB = calcBoundingBox(bb)
    const uBBRounding = calcRounding(uBB[2], uBB[3], bbRounding)

    buffers['aPosition'] = this.context.createVertexBuffer(aPosition, 2)
    uniforms['uColor'] = uColor
    uniforms['uRect'] = uRect
    uniforms['uSvgResolution'] = uSvgResolution
    uniforms['uBB'] = uBB
    uniforms['uBBRounding'] = uBBRounding

    const verticeCount = aPosition.length / 2

    const vaoData = { material, vao, buffers, uniforms, verticeCount }
    connectBuffersToVao(vaoData)
    return vaoData
  }

  cleanup = (): void => {
    this.paths.forEach((path) => cleanupVaoData(path))
    this.transferrer?.cleanup()
  }
}

export const iconVs = `#version 300 es
precision highp float;

uniform mat3 uMatrix;
uniform vec4 uRect;
uniform vec2 uSvgResolution;
uniform vec2 uJitter;
uniform float uZoom;

in vec2 aPosition;

out vec2 vPosition;

void main() {
  vec2 aPosition = aPosition / uSvgResolution * uRect.zw + uRect.xy + uJitter / uZoom;
  vec3 transformedPosition = uMatrix * vec3(aPosition, 1);
  gl_Position = vec4(transformedPosition.xy, 0, 1);

  vPosition = aPosition;
}
`

export const iconFs = `#version 300 es
precision mediump float;

uniform vec4 uColor;
uniform vec4 uBB;
uniform vec4 uBBRounding;

in vec2 vPosition;

out vec4 outColor;

${isPointInBox}

void main() {
  if (!isPointInBox(vPosition, uBB, uBBRounding)) {
    discard;
  }

  outColor = vec4(uColor.rgb, uColor.a / 6.0);
}
`
