import { CameraService } from 'application/camera'
import { ActionHandler } from '../types'
import { HandAction } from './action'
import { MouseDeviceState } from 'application/mouse'

export class HandActionFactory {
  private mouseDeviceState: MouseDeviceState
  private cameraService: CameraService

  constructor(
    mouseDeviceState: MouseDeviceState,
    cameraService: CameraService
  ) {
    this.mouseDeviceState = mouseDeviceState
    this.cameraService = cameraService
  }

  create = (mode: 'wheel' | 'mouse' | 'key'): ActionHandler => {
    return new HandAction(this.mouseDeviceState, this.cameraService, mode)
  }
}
