import { AttributeType } from 'application/attributes'
import { Camera } from 'application/camera'
import { Color } from 'application/color'
import { Context, Renderable, WebglText } from 'application/render'
import { WebglIcon } from 'application/render/renderables/webglIcon'
import { IconLoader } from 'application/render/svg/iconLoader'
import { FontLoaderInterface, Shaper } from 'application/text'
import { IconKey } from 'assets/iconLibrary'
import { NodeTitle } from 'editor/titles/types'

export const TitleIconSize = 14
export const TitlePadding = 3

export class NodeTitleTransformer {
  static transform = (
    context: Context,
    titles: NodeTitle[],
    camera: Camera,
    fontLoader: FontLoaderInterface,
    textShaper: Shaper,
    iconLoader: IconLoader
  ): Renderable[] => {
    const renderables: Renderable[] = []

    for (const title of titles) {
      const color = title.text.styles.fill.color
      if (!color) continue
      renderables.push(
        this.createTitleText(context, title, camera, fontLoader, textShaper)
      )
      renderables.push(
        this.createTitleIcon(context, title, camera, color, iconLoader)
      )
    }

    return renderables
  }

  private static createTitleText = (
    context: Context,
    title: NodeTitle,
    camera: Camera,
    fontLoader: FontLoaderInterface,
    textShaper: Shaper
  ): WebglText => {
    const data = {
      ...title.rectangle,
      x: title.rectangle.x + (TitleIconSize + TitlePadding) / camera.z,
      text: title.text,
      bb: title.rectangle,
    }
    const renderable = new WebglText(context, data, fontLoader, textShaper)
    renderable.init()
    return renderable
  }

  private static createTitleIcon = (
    context: Context,
    title: NodeTitle,
    camera: Camera,
    color: Color,
    iconLoader: IconLoader
  ): WebglIcon => {
    const data = {
      ...title.rectangle,
      w: TitleIconSize / camera.z,
      h: TitleIconSize / camera.z,
      key: this.getIconKey(title.type),
      color: color,
      bb: title.rectangle,
    }
    const renderable = new WebglIcon(context, data, iconLoader)
    renderable.init()
    return renderable
  }

  private static getIconKey = (type: AttributeType): IconKey => {
    switch (type) {
      case 'frame':
        return 'Frame'
      case 'page':
        return 'Page'
      default:
        return 'Frame'
    }
  }
}
