import { getClippedRectangle, getRectangle } from 'application/attributes'
import {
  ClientUpdateListener,
  UpdateNodeUpdated,
  Update,
  UpdateNodeCreated,
  UpdateNodeDeleted,
} from 'application/client'
import { ReadOnlyDocument } from 'application/document'
import { ReadOnlyNode } from 'application/node'
import { QuadTree, QuadTreeRectangleFactory } from 'application/quadtree'
import { ReadOnlyDocumentSelection } from 'application/selection'
import { NodeTitle, NodeTitlesListener } from 'editor/titles/types'

export class EditorQuadTreeService
  implements ClientUpdateListener, NodeTitlesListener
{
  private document: ReadOnlyDocument
  private selection: ReadOnlyDocumentSelection
  private quadTree: QuadTree

  private titles: NodeTitle[]

  constructor(
    document: ReadOnlyDocument,
    selection: ReadOnlyDocumentSelection,
    quadTree: QuadTree
  ) {
    this.document = document
    this.selection = selection
    this.quadTree = quadTree

    this.titles = []
  }

  init = (): void => {
    this.insertDocument()
  }

  getTypes = (): Update['type'][] => {
    return [
      'node_created',
      'node_deleted',
      'node_updated',
      'selected_canvas',
      'initialize',
    ]
  }

  onUpdate = (update: Update[]): void => {
    for (const u of update) {
      switch (u.type) {
        case 'node_deleted':
          this.handleDelete(u as UpdateNodeDeleted)
          break
        case 'node_created':
          this.handleCreate(u as UpdateNodeCreated)
          break
        case 'node_updated':
          this.handleUpdate(u as UpdateNodeUpdated)
          break
        case 'selected_canvas':
        case 'initialize':
          this.insertDocument()
          break
      }
    }
  }

  onNodeTitles = (titles: NodeTitle[]): void => {
    const removed = this.getRemovedTitles(titles)
    this.removeTitles(removed)

    this.titles = titles
    this.insertTitles(titles)
  }

  private handleDelete = (update: UpdateNodeDeleted): void => {
    this.quadTree.remove(update.data.id)
  }

  private handleCreate = (update: UpdateNodeCreated): void => {
    const node = this.document.getNode(update.data.id)
    if (!node) return

    this.insertNode(node)
  }

  private handleUpdate = (update: UpdateNodeUpdated): void => {
    const node = this.document.getNode(update.data.id)
    if (!node) return

    this.insertTree(node, this.getNodeDepth(node), false)
  }

  private insertDocument = (): void => {
    const canvasId = this.selection.getSelectedCanvas()
    const canvas = this.document.getNode(canvasId)
    if (!canvas) return

    this.quadTree.clear()
    this.insertTree(canvas, [], true)
  }

  private insertTree = (
    node: ReadOnlyNode,
    depth: number[],
    root: boolean
  ): void => {
    if (!root) {
      const id = node.getId()
      const nodeRectangle = getClippedRectangle(node)

      const rectangle = QuadTreeRectangleFactory.createRectangle(
        id,
        nodeRectangle,
        depth,
        { type: 'node' }
      )

      this.quadTree.insert(rectangle)
    }

    const children = node.getChildren()
    if (!children) return

    children.forEach((id, index) => {
      const child = this.document.getNode(id)
      if (!child) return

      this.insertTree(child, [...depth, index], false)
    })
  }

  private insertNode(node: ReadOnlyNode): void {
    if (node.getBaseAttribute('type') === 'canvas') return
    if (node.getBaseAttribute('type') === 'root') return

    const id = node.getId()
    const nodeRectangle = getRectangle(node)

    const rectangle = QuadTreeRectangleFactory.createRectangle(
      id,
      nodeRectangle,
      this.getNodeDepth(node),
      { type: 'node' }
    )

    this.quadTree.insert(rectangle)
  }

  private getNodeDepth = (startNode: ReadOnlyNode): number[] => {
    const depth: number[] = []
    let node = startNode
    let parent = this.document.getParent(startNode)
    while (parent && parent.getBaseAttribute('type') !== 'root') {
      const children = parent.getChildren()
      if (!children) return depth

      const index = children.indexOf(node.getId())
      if (index === -1) return depth

      depth.unshift(index)
      node = parent
      parent = this.document.getParent(node)
    }

    return depth
  }

  private insertTitles = (title: NodeTitle[]): void => {
    for (const titles of title) {
      this.quadTree.insert(
        QuadTreeRectangleFactory.createRectangle(
          `${titles.id}-title`,
          titles.rectangle,
          [0],
          { type: 'title' }
        )
      )
    }
  }

  private removeTitles = (ids: string[]): void => {
    for (const id of ids) {
      this.quadTree.remove(`${id}-title`)
    }
  }

  private getRemovedTitles = (titles: NodeTitle[]): string[] => {
    const removed: string[] = []
    for (const title of this.titles) {
      const found = titles.find((t) => t.id === title.id)
      if (!found) removed.push(title.id)
    }

    return removed
  }
}
