import Icon from 'components/Library/Components/Icon/Icon/Icon'
import MenuButton from 'components/Library/Menus/MenuButton'
import MenuFullscreenPopupContainer from 'components/Library/Menus/MenuFullscreenPopup'
import { useState } from 'react'
import { DesignColor } from 'themes'

interface ProjectCardDeletePopupProps {
  open: boolean
  name: string

  close: () => void
  deleteProject: () => void
}

export default function ProjectCardDeletePopup({
  open,
  name,
  close,
  deleteProject,
}: ProjectCardDeletePopupProps) {
  const [hoverIcon, setHoverIcon] = useState(false)

  const iconColor = hoverIcon ? DesignColor('grayInputTint') : 'transparent'

  const submitDelete = () => {
    deleteProject()
    close()
  }

  return (
    <MenuFullscreenPopupContainer open={open} setOpen={close}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <h2
          style={{
            fontSize: 20,
            color: DesignColor('text1'),
          }}
        >
          Delete
        </h2>
        <div
          style={{
            background: iconColor,
            cursor: 'pointer',
          }}
          onMouseEnter={() => setHoverIcon(true)}
          onMouseLeave={() => setHoverIcon(false)}
          onClick={close}
        >
          <Icon icon="X" size={24} color={DesignColor('text1')} />
        </div>
      </div>
      <p
        style={{
          color: DesignColor('text1'),
          fontSize: '12px',
          whiteSpace: 'pre-wrap',
        }}
      >
        {getMessage(name)}
      </p>
      <div
        style={{
          width: '100%',
          display: 'flex',
          gap: '16px',
        }}
      >
        <MenuButton label="Keep" width="100%" onClick={close} />
        <MenuButton
          label="Delete"
          width="100%"
          fontColor={'red'}
          fontWeight="bold"
          onClick={submitDelete}
        />
      </div>
    </MenuFullscreenPopupContainer>
  )
}

function getMessage(name: string) {
  return `Are you sure you want to delete "${name}"?

This cannot be undone.`
}
