import { NodeSelectionAction } from 'application/action'
import { CommandHandler } from 'application/client'
import { ReadOnlyDocument } from 'application/document'
import { ReadOnlyDocumentSelection } from 'application/selection'

export class TreeSelectionAction {
  private commandHandler: CommandHandler
  private document: ReadOnlyDocument
  private documentSelection: ReadOnlyDocumentSelection
  private selectionAction: NodeSelectionAction

  constructor(
    commandHandler: CommandHandler,
    document: ReadOnlyDocument,
    documentSelection: ReadOnlyDocumentSelection,
    selectionAction: NodeSelectionAction
  ) {
    this.commandHandler = commandHandler
    this.document = document
    this.documentSelection = documentSelection
    this.selectionAction = selectionAction
  }

  selectChildren = (): void => {
    const selected = this.documentSelection.getSelected()
    const allChildren = selected.flatMap((node) => {
      const children = node.getChildren()
      if (!children || children.length === 0) return node.getId()
      return children
    })

    this.selectionAction.selectNodes(allChildren, true)
    this.commandHandler.handle({ type: 'commit' })
  }

  selectParents = (): void => {
    const selected = this.documentSelection.getSelected()
    const allParents = selected.flatMap((node) => {
      const parent = this.document.getParent(node)
      if (!parent) return node.getId()
      if (parent.getBaseAttribute('type') === 'canvas') return node.getId()
      return parent.getId()
    })

    this.selectionAction.selectNodes(allParents, true)
    this.commandHandler.handle({ type: 'commit' })
  }
}
