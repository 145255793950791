import { Properties } from '../css'
import { TailwindClassAspect } from './classes/aspect'
import { TailwindClassBackground } from './classes/background'
import { TailwindBlur } from './classes/blur'
import { TailwindClassBorder } from './classes/border'
import { TailwindClassColor } from './classes/color'
import { TailwindCursor } from './classes/cursor'
import { TailwindClassDisplay } from './classes/display'
import { TailwindClassFlex } from './classes/flex'
import { TailwindClassFont } from './classes/font'
import { TailwindClassInset } from './classes/inset'
import { TailwindClassMargin } from './classes/margin'
import { TailwindClassObjectFit } from './classes/objectFit'
import { TailwindClassObjectPosition } from './classes/objectPosition'
import { TailwindClassOpacity } from './classes/opacity'
import { TailwindClassOverflow } from './classes/overflow'
import { TailwindClassPadding } from './classes/padding'
import { TailwindClassPosition } from './classes/position'
import { TailwindClassRadius } from './classes/radius'
import { TailwindShadow } from './classes/shadow'
import { TailwindClassSize } from './classes/size'
import { TailwindTransition } from './classes/transition'
import { TailwindClassRenderer } from './types'

export class TailwindRenderer {
  private renderers: TailwindClassRenderer[]

  constructor() {
    this.renderers = [
      new TailwindClassDisplay(),
      new TailwindClassPosition(),
      new TailwindClassInset(),
      new TailwindClassSize(),
      new TailwindClassFlex(),
      new TailwindClassAspect(),
      new TailwindClassMargin(),
      new TailwindClassPadding(),
      new TailwindClassBorder(),
      new TailwindClassRadius(),
      new TailwindClassOverflow(),
      new TailwindClassObjectFit(),
      new TailwindClassObjectPosition(),
      new TailwindClassFont(),
      new TailwindClassColor(),
      new TailwindClassBackground(),
      new TailwindClassOpacity(),
      new TailwindBlur(),
      new TailwindShadow(),
      new TailwindCursor(),
      new TailwindTransition(),
    ]
  }

  renderClass = (properties: Partial<Properties>): string => {
    let classes: string[] = []
    for (const renderer of this.renderers) {
      classes.push(...renderer.render(properties))
    }
    return classes.join(' ')
  }
}
