import { SizeValueType, TypedValue } from '../types/types'

export class SizeContext {
  vw: number
  vh: number
  width: TypedValue
  height: TypedValue

  constructor() {
    this.vw = 0
    this.vh = 0
    this.width = { type: SizeValueType.auto, value: Number.MAX_VALUE }
    this.height = { type: SizeValueType.auto, value: Number.MAX_VALUE }
  }

  isWidthAuto = (): boolean => {
    if (this.width.type === SizeValueType.exact) return false
    if (this.width.type === SizeValueType.absolute) return false
    return true
  }

  isHeightAuto = (): boolean => {
    if (this.height.type === SizeValueType.exact) return false
    if (this.height.type === SizeValueType.absolute) return false
    return true
  }

  encode = (): number => {
    return (
      (this.width.type << 28) |
      (this.width.value << 16) |
      (this.height.type << 12) |
      this.height.value
    )
  }

  reset = (): void => {
    this.width.type = SizeValueType.auto
    this.width.value = Number.MAX_VALUE
    this.height.type = SizeValueType.auto
    this.height.value = Number.MAX_VALUE
  }
}
