import {
  BaseMap,
  SelectorBreakpoint,
  SelectorName,
  SelectorPseudo,
  StyleMap,
} from 'application/attributes'
import { NodeUpdateListener } from './types'

export class NodeListeners {
  private listeners: NodeUpdateListener[]

  constructor() {
    this.listeners = []
  }

  registerListener = (listener: NodeUpdateListener): void => {
    this.listeners.push(listener)
  }

  onBaseAttribute = <K extends keyof BaseMap>(
    id: string,
    key: K,
    prev: BaseMap[K],
    curr: BaseMap[K]
  ): void => {
    for (const listener of this.listeners) {
      if (listener.onBaseAttribute) {
        listener.onBaseAttribute(id, key, prev, curr)
      }
    }
  }

  onStyleAttribute = <K extends keyof StyleMap>(
    id: string,
    key: K,
    prev: { selector: SelectorName; value: Partial<StyleMap>[K] },
    curr: { selector: SelectorName; value: Partial<StyleMap>[K] }
  ): void => {
    for (const listener of this.listeners) {
      if (listener.onStyleAttribute) {
        listener.onStyleAttribute(id, key, prev, curr)
      }
    }
  }

  onActiveBreakpoint = (
    id: string,
    prev: SelectorBreakpoint,
    curr: SelectorBreakpoint
  ): void => {
    for (const listener of this.listeners) {
      if (listener.onActiveBreakpoint) {
        listener.onActiveBreakpoint(id, prev, curr)
      }
    }
  }

  onActivePseudo = (
    id: string,
    prev: SelectorPseudo,
    curr: SelectorPseudo
  ): void => {
    for (const listener of this.listeners) {
      if (listener.onActivePseudo) {
        listener.onActivePseudo(id, prev, curr)
      }
    }
  }

  onChildren = (
    id: string,
    prev: string[] | undefined,
    curr: string[] | undefined
  ) => {
    for (const listener of this.listeners) {
      if (listener.onChildren) {
        listener.onChildren(id, prev, curr)
      }
    }
  }

  onParent = (
    id: string,
    prev: string | undefined,
    curr: string | undefined
  ) => {
    for (const listener of this.listeners) {
      if (listener.onParent) {
        listener.onParent(id, prev, curr)
      }
    }
  }
}
