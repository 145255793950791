import { LayoutItem } from '../layoutItem/layoutItem'
import { ObjectPool } from '../pool/objectPool'
import { FlexSizeItemRow } from './flexSizeItemRow'

export class FlexSizeItemRowPool extends ObjectPool<FlexSizeItemRow> {
  constructor(size: number) {
    super(FlexSizeItemRow, size)
  }

  fromItem = (layoutItem: LayoutItem): FlexSizeItemRow => {
    const item = this.get()
    item.layoutItem = layoutItem
    return item
  }
}
