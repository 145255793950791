import { ReadOnlyNode } from 'application/node'
import { Shaper, TextContent } from 'application/text'
import { StyleInitializer } from './styleInitializer'
import {
  BlockLayout,
  CssFlexDirection,
  CssLength,
  CssUnit,
  Element,
  ElementDiv,
  ElementImg,
  ElementP,
  FlexLayoutColumn,
  FlexLayoutRow,
  ImageLayout,
  LayoutItem,
  LayoutItemType,
  Size,
  TextLayout,
  TextSizeCalculator,
} from 'application/layout'
import { SizeContextPool } from 'application/layout/context/sizeContextPool'
import { FlexSizeLineImplPool } from 'application/layout/flex/flexSizeLineImplPool'
import { FlexSizeItemRowPool } from 'application/layout/flex/flexSizeItemRowPool'
import { FlexSizeItemColumnPool } from 'application/layout/flex/flexSizeItemColumnPool'
import { LayoutItemPool } from './layoutItemPool'
import { InputLayout } from 'application/layout/input/inputLayout'
import { InputSizeCalculator } from 'application/layout/input/inputSize'
import { getTextAttributes } from 'application/attributes'
import { ElementButton } from 'application/layout/element/elementButton'

export class LayoutItemFactory {
  private textSizeCalculator: TextSizeCalculator
  private inputSizeCalculator: InputSizeCalculator
  private styleInitializer: StyleInitializer

  private layoutItemPool: LayoutItemPool
  private sizeContextPool: SizeContextPool
  private flexSizeLinePool: FlexSizeLineImplPool
  private flexSizeItemRowPool: FlexSizeItemRowPool
  private flexSizeItemColumnPool: FlexSizeItemColumnPool

  constructor(textShaper: Shaper) {
    this.textSizeCalculator = TextSizeCalculator.create(textShaper)
    this.inputSizeCalculator = InputSizeCalculator.create(textShaper)
    this.styleInitializer = new StyleInitializer()

    this.layoutItemPool = new LayoutItemPool()
    this.sizeContextPool = new SizeContextPool(10)
    this.flexSizeLinePool = new FlexSizeLineImplPool(10)
    this.flexSizeItemRowPool = new FlexSizeItemRowPool(100)
    this.flexSizeItemColumnPool = new FlexSizeItemColumnPool(100)
  }

  create = (
    node: ReadOnlyNode,
    parent: ReadOnlyNode | null
  ): LayoutItem | null => {
    const element = this.createElementFromNode(node, parent)
    const type = node.getBaseAttribute('type')
    const display = node.getStyleAttribute('display')
    if (display === 'none') {
      return null
    } else if (type === 'content' || type === 'paragraph') {
      return this.createLayoutItem('text', element)
    } else if (type === 'image') {
      return this.createLayoutItem('image', element)
    } else if (type === 'input') {
      return this.createLayoutItem('input', element)
    } else if (display === 'flex') {
      return this.createLayoutItem('flex', element)
    }
    return this.createLayoutItem('block', element)
  }

  release = (item: LayoutItem): void => {
    switch (item.getType()) {
      case LayoutItemType.block:
        this.layoutItemPool.releaseBlock(item as BlockLayout)
        break
      case LayoutItemType.flex_row:
        this.layoutItemPool.releaseFlexRow(item as FlexLayoutRow)
        break
      case LayoutItemType.flex_column:
        this.layoutItemPool.releaseFlexColumn(item as FlexLayoutColumn)
        break
      case LayoutItemType.text:
        this.layoutItemPool.releaseText(item as TextLayout)
        break
      case LayoutItemType.image:
        this.layoutItemPool.releaseImage(item as ImageLayout)
        break
      case LayoutItemType.input:
        this.layoutItemPool.releaseInput(item as InputLayout)
        break
    }
  }

  private createElementFromNode = (
    node: ReadOnlyNode,
    parent: ReadOnlyNode | null
  ): Element => {
    switch (node.getBaseAttribute('type')) {
      case 'content':
      case 'paragraph':
      case 'input':
        const styleNode = this.getStyleNode(node, parent)
        const textContent = this.getTextContent(node, styleNode)
        const p = ElementP.create(textContent)
        this.styleInitializer.init(node, p)
        return p
      case 'image':
        const imageSize = this.getImageSize(node)
        const img = ElementImg.create(imageSize)
        this.styleInitializer.init(node, img)
        if (!img.css.minWidth.isDefined()) {
          img.css.minWidth = CssLength.create(0, CssUnit.unit_px)
        }
        return img
      case 'button':
        const button = ElementButton.create()
        this.styleInitializer.init(node, button)
        return button
      default:
        const div = ElementDiv.create()
        this.styleInitializer.init(node, div)
        return div
    }
  }

  private createLayoutItem = (
    mode: 'flex' | 'block' | 'text' | 'image' | 'input' = 'block',
    element: Element
  ): LayoutItem => {
    switch (mode) {
      case 'block':
        const block = this.layoutItemPool.getBlock()
        block.element = element
        block.sizeContextPool = this.sizeContextPool
        return block
      case 'flex':
        const flexDirection = element.css.flexDirection
        if (flexDirection === CssFlexDirection.row) {
          const flex = this.layoutItemPool.getFlexRow()
          flex.element = element
          flex.sizeContextPool = this.sizeContextPool
          flex.flexSizeLinePool = this.flexSizeLinePool
          flex.itemPool = this.flexSizeItemRowPool
          return flex
        } else {
          const flex = this.layoutItemPool.getFlexColumn()
          flex.element = element
          flex.sizeContextPool = this.sizeContextPool
          flex.flexSizeLinePool = this.flexSizeLinePool
          flex.itemPool = this.flexSizeItemColumnPool
          return flex
        }
      case 'image':
        const image = this.layoutItemPool.getImage()
        image.element = element
        image.sizeContextPool = this.sizeContextPool
        return image
      case 'input':
        const input = this.layoutItemPool.getInput()
        input.element = element
        input.sizeContextPool = this.sizeContextPool
        input.calculator = this.inputSizeCalculator
        return input
      case 'text':
        const text = this.layoutItemPool.getText()
        text.element = element
        text.sizeContextPool = this.sizeContextPool
        text.calculator = this.textSizeCalculator
        return text
    }
  }

  private getImageSize = (node: ReadOnlyNode): Size => {
    const width = node.getBaseAttribute('image.originalSize.w')
    const height = node.getBaseAttribute('image.originalSize.h')
    return Size.create(width, height)
  }

  private getTextContent = (
    node: ReadOnlyNode,
    parent: ReadOnlyNode | null
  ): TextContent => {
    const textContent: TextContent = {
      content: '',
      contentStyles: [],
      styles: {
        fill: { type: 'color', color: { r: 0, g: 0, b: 0, a: 0 } },
        fontFamily: 'inter',
        fontWeight: 'regular',
        fontSize: 0,
        align: 'left',
        letterSpacing: 0,
        lineHeight: 0,
        italic: false,
        underline: false,
        link: null,
      },
      styleOverrides: {},
      lineTypes: [],
    }
    if (!parent) return textContent

    const textAttributes = getTextAttributes(parent)
    if (!textAttributes) return textContent

    const content = node.getBaseAttribute('text.content') || ''
    const fontFamily = textAttributes['text.font.family']
    const fontWeight = textAttributes['text.font.weight']
    const fontSize = textAttributes['text.font.size']
    const fill = textAttributes['text.color']
    const align = textAttributes['text.align']
    const letterSpacing = textAttributes['text.letterSpacing']
    const lineHeight = textAttributes['text.lineHeight']
    const italic = textAttributes['text.italic']
    const underline = textAttributes['text.underline']
    const link = null

    if (
      content === undefined ||
      fontFamily === undefined ||
      fontWeight === undefined ||
      fontSize === undefined ||
      fill === undefined ||
      align === undefined ||
      letterSpacing === undefined ||
      lineHeight === undefined ||
      italic === undefined ||
      underline === undefined ||
      link === undefined
    ) {
      return textContent
    }

    textContent.content = content
    textContent.contentStyles = Array(content.length).fill(0)
    textContent.styles = {
      fontFamily,
      fontWeight,
      fontSize,
      fill,
      align,
      letterSpacing,
      lineHeight,
      italic,
      underline,
      link,
    }

    return textContent
  }

  private getStyleNode = (
    node: ReadOnlyNode,
    parent: ReadOnlyNode | null
  ): ReadOnlyNode | null => {
    const type = node.getBaseAttribute('type')
    switch (type) {
      case 'content':
        return parent
      default:
        return node
    }
  }
}
