import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import { AttributeProps } from '../AttributeSettings'
import TextBlock from 'components/Library/Components/Text/TextBlock/TextBlock'
import TextInputBlock from 'components/Library/Components/Text/TextInputBlock/TextInputBlock'

export default function ImageAttributes({
  settings,
  handlers,
}: AttributeProps) {
  if (!settings.attributes || settings.attributes === 'Mixed') return <></>

  return (
    <>
      <PanelRow>
        <TextBlock mode={'label2'}>Src</TextBlock>
        <TextInputBlock
          width={96}
          value={settings.attributes['src'] || ''}
          setValue={(v) => handlers.setAttribute('src', v)}
          placeholder={`/${nameToCamelCase(settings.name)}.png`}
          commit={true}
        />
      </PanelRow>
      <PanelRow>
        <TextBlock mode={'label2'}>Alt Text</TextBlock>
        <TextInputBlock
          width={96}
          value={settings.attributes['alt'] || ''}
          setValue={(v) => handlers.setAttribute('alt', v)}
          placeholder={settings.name}
          commit={true}
        />
      </PanelRow>
    </>
  )
}

function nameToCamelCase(name: string) {
  return name
    .replace(/\s+/g, '')
    .replace(/-+/g, '-')
    .replace(/-([a-z])/g, (_, letter) => letter.toUpperCase())
    .replace(/^[A-Z]/, (firstChar) => firstChar.toLowerCase())
}
