import { Uniform, UniformValue, Context } from 'application/render'

export class Material {
  private context: Context
  private program: WebGLProgram
  private uniformMap: { [key: string]: Uniform }

  constructor(context: Context, sourceVs: string, sourceFs: string) {
    this.context = context
    this.program = this.createProgram(context.getGl(), sourceVs, sourceFs)
    this.uniformMap = {}
  }

  getContext = (): Context => this.context
  getGl = (): WebGL2RenderingContext => this.context.getGl()
  getProgram = (): WebGLProgram => this.program

  setUniform = (name: string, value: UniformValue) => {
    this.context.getGl().useProgram(this.program)
    let uniform = this.uniformMap[name]
    if (!uniform) {
      uniform = new Uniform(this, name)
      this.uniformMap[name] = uniform
    }
    uniform.set(value)
  }

  setSampler = (name: string, texture: WebGLTexture, unit: number) => {
    const gl = this.context.getGl()
    gl.useProgram(this.program)
    gl.activeTexture(gl.TEXTURE0 + unit)
    gl.bindTexture(gl.TEXTURE_2D, texture)

    let uniform = this.uniformMap[name]
    if (!uniform) {
      uniform = new Uniform(this, name)
      this.uniformMap[name] = uniform
    }
    uniform.setSampler(unit)
  }

  private createProgram(
    context: WebGL2RenderingContext,
    sourceVs: string,
    sourceFs: string
  ): WebGLProgram {
    const vs = this.createShader(context, context.VERTEX_SHADER, sourceVs)
    const fs = this.createShader(context, context.FRAGMENT_SHADER, sourceFs)
    const program = context.createProgram()
    if (!program) throw new Error('Could not create program')

    context.attachShader(program, vs)
    context.attachShader(program, fs)
    context.linkProgram(program)

    if (!context.getProgramParameter(program, context.LINK_STATUS)) {
      context.deleteProgram(program)
      throw new Error(
        `Program linking failed: ${context.getProgramInfoLog(program)}`
      )
    }

    context.deleteShader(vs)
    context.deleteShader(fs)

    return program
  }

  private createShader(
    context: WebGL2RenderingContext,
    type: number,
    source: string
  ): WebGLShader {
    const shader = context.createShader(type)
    if (!shader) throw new Error('Could not create shader')

    context.shaderSource(shader, source)
    context.compileShader(shader)

    if (!context.getShaderParameter(shader, context.COMPILE_STATUS)) {
      context.deleteShader(shader)
      throw new Error(
        `Shader compilation failed: ${context.getShaderInfoLog(
          shader
        )} - ${source}`
      )
    }

    return shader
  }
}
