import { DesignColor } from 'themes'
import Text, { textStyles } from 'components/Library/Text/Text'
import { useState } from 'react'
import Icon from '../../Icon/Icon/Icon'

interface FontPopupRowProps {
  custom?: boolean

  text: string
  subtext?: string

  styles?: textStyles

  selected?: boolean
  hovered?: boolean

  onClick?: () => void
}

export default function FontPopupRow({
  custom,
  text,
  subtext,
  styles,
  selected = false,
  hovered = false,
  onClick,
}: FontPopupRowProps) {
  const [hover, setHover] = useState(false)

  const background = getBackground(selected)
  const boxShadow = getBoxShadow(hover || hovered)

  return (
    <div
      style={{
        width: 232,
        height: 32,
        paddingRight: 12,
        paddingLeft: 16,
        boxSizing: 'border-box',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'start',
        gap: 4,
        background,
        boxShadow,
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={onClick}
    >
      <TextContainer>
        <Text color={DesignColor('text1')} styles={styles}>
          {text}
        </Text>
        {subtext && <Text color={DesignColor('text2')}>{subtext}</Text>}
      </TextContainer>
      {custom && (
        <Icon size={16} icon={'LockChild'} color={DesignColor('text2')} />
      )}
    </div>
  )
}

function TextContainer({ children }: { children?: React.ReactNode }) {
  return (
    <div
      style={{
        width: 184,
        height: 32,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      {children}
    </div>
  )
}

function getBoxShadow(hover: boolean) {
  if (hover) return `inset 0px 0px 0px 1px ${DesignColor('inputHighlight')}`
  return 'none'
}

function getBackground(selected: boolean) {
  if (selected) return DesignColor('inputTint')
  return 'none'
}
