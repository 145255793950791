import { DesignColor } from 'themes'
import { Color, HsbaColor, hexToRgba } from 'application/color'
import useCommit from 'hooks/editor/useCommit'
import { useState } from 'react'

interface ColorPresetsProps {
  hsba: HsbaColor
  updateColor: (hsba: Color) => void
}

export default function ColorPresets({ hsba, updateColor }: ColorPresetsProps) {
  const commit = useCommit()

  const handleClick = (preset: string) => {
    const parsedColor = hexToRgba(preset)
    updateColor({ ...parsedColor, a: hsba.a })
    commit()
  }

  return (
    <div
      style={{
        width: 232,
        height: 32,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0px 8px',
      }}
    >
      {presetColors.map((preset) => (
        <Square
          key={preset}
          color={preset}
          onClick={() => handleClick(preset)}
        />
      ))}
    </div>
  )
}

function Square({ color, onClick }: { color: string; onClick: () => void }) {
  const [hover, setHover] = useState(false)
  return (
    <div
      style={{
        width: 16,
        height: 16,
        boxSizing: 'border-box',
        border: `1px solid ${
          hover ? DesignColor('inputHighlight') : DesignColor('panelBorder')
        }`,
        borderRadius: 4,
        backgroundColor: color,
      }}
      onClick={onClick}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    />
  )
}

const presetColors = [
  '#f54f4f',
  '#f78f4f',
  '#f7d94f',
  '#57f74f',
  '#4ff7f7',
  '#4f9ff7',
  '#a14ff7',
  '#f74ff7',
  '#775506',
  '#ffffff',
  '#808080',
  '#000000',
]
