import { ReadOnlyDocument } from 'application/document'
import { ReparentCalculator } from './calculator'
import { Point } from 'application/shapes'
import { isAbsolutePositionMode } from 'application/attributes'
import {
  Command,
  SetNodeAttribute,
  SetParentNode,
} from 'application/client/command'
import { ParentMap } from '../types'
import { ParentChildIndexCalculator } from './parentIndex'
import {
  getAdjustedBottomToNode,
  getAdjustedHeightToNode,
  getAdjustedLeftToNode,
  getAdjustedRightToNode,
  getAdjustedTopToNode,
  getAdjustedWidthToNode,
} from 'application/units'

interface CommandHandler {
  handle: (command: Command[]) => void
}

export class NodeReparentAction {
  private commandHandler: CommandHandler
  private document: ReadOnlyDocument
  private reparentCalculator: ReparentCalculator
  private index: ParentChildIndexCalculator

  constructor(
    commandHandler: CommandHandler,
    document: ReadOnlyDocument,
    reparentCalculator: ReparentCalculator,
    indexCalculator: ParentChildIndexCalculator
  ) {
    this.commandHandler = commandHandler
    this.document = document
    this.reparentCalculator = reparentCalculator
    this.index = indexCalculator
  }

  reparentAtPoint = (
    nodeIds: string[],
    point: Point,
    allowedIds: string[],
    filteredIds: string[],
    originalParents: ParentMap,
    final: boolean
  ): string | undefined => {
    const newParent = this.reparentCalculator.getParentAtPoint(
      nodeIds,
      point,
      allowedIds,
      filteredIds
    )
    if (!newParent) return undefined

    const allInOriginal = this.allInOrignalParent(nodeIds, originalParents)
    const isAnOriginalParent = this.isOriginalParent(newParent, originalParents)
    if (allInOriginal && isAnOriginalParent) return

    const allSameOriginalParent = this.allSameOriginalParent(originalParents)
    const anyAutoChildren = this.anyAutoChildren(nodeIds)
    const isPage = this.isPageTarget(newParent)
    const isSameOriginalParent = isAnOriginalParent && allSameOriginalParent

    if ((isPage || anyAutoChildren) && !isSameOriginalParent && !final) {
      this.reparentToNone(nodeIds)
    } else {
      this.reparentToNewParent(nodeIds, newParent, point)
    }

    return newParent
  }

  reparentToNode = (nodeIds: string[], parentId: string, index: number) => {
    const newParentCommands = this.buildNewParentCommands(
      nodeIds,
      parentId,
      index
    )
    const updatedLayoutCommands = this.buildUpdatedLayoutCommands(
      nodeIds,
      parentId
    )
    this.commandHandler.handle([...newParentCommands, ...updatedLayoutCommands])
  }

  private allInOrignalParent = (
    nodeIds: string[],
    originalParents: ParentMap
  ): boolean => {
    for (const id of nodeIds) {
      const node = this.document.getNode(id)
      if (!node) return false

      const parentId = this.document.getParent(node)?.getId()
      if (!parentId) return false

      if (parentId !== originalParents[id]) return false
    }
    return true
  }

  private isOriginalParent = (id: string, originalParents: ParentMap) => {
    return Object.values(originalParents).includes(id)
  }

  private allSameOriginalParent = (originalParents: ParentMap): boolean => {
    const keys = Object.keys(originalParents)
    if (keys.length === 0) return false
    return keys.every((k) => originalParents[k] === originalParents[keys[0]])
  }

  private anyAutoChildren = (nodeIds: string[]): boolean => {
    return nodeIds.some((id) => {
      const node = this.document.getNode(id)
      if (!node) return false
      return !isAbsolutePositionMode(node)
    })
  }

  private isPageTarget = (nodeId: string): boolean => {
    const node = this.document.getNode(nodeId)
    if (!node) return false

    return node.getBaseAttribute('type') === 'page'
  }

  private reparentToNone = (nodeIds: string[]): void => {
    const commands = this.buildNewParentCommands(nodeIds, null, 0)
    this.commandHandler.handle(commands)
  }

  private reparentToNewParent = (
    nodeIds: string[],
    newParent: string,
    point: Point
  ): void => {
    const index = this.index.getIndex(newParent, point)
    const newParentCommands = this.buildNewParentCommands(
      nodeIds,
      newParent,
      index
    )
    const updatedLayoutCommands = this.buildUpdatedLayoutCommands(
      nodeIds,
      newParent
    )
    this.commandHandler.handle([...newParentCommands, ...updatedLayoutCommands])
  }

  private buildNewParentCommands = (
    nodeIds: string[],
    newParentId: string | null,
    index: number
  ): SetParentNode[] => {
    return nodeIds.map((id) => {
      return {
        type: 'setParent',
        params: {
          id: id,
          parentId: newParentId,
          index: index,
        },
      }
    })
  }

  private buildUpdatedLayoutCommands = (
    nodeIds: string[],
    newParentId: string | null
  ): SetNodeAttribute[] => {
    if (!newParentId) return []

    const newParent = this.document.getNode(newParentId)
    if (!newParent) return []
    if (newParent?.getBaseAttribute('type') === 'canvas') return []

    const commands: SetNodeAttribute[] = []
    for (const nodeId of nodeIds) {
      const node = this.document.getNode(nodeId)
      if (!node) continue

      const x = node.getBaseAttribute('x')
      const y = node.getBaseAttribute('y')
      const w = node.getBaseAttribute('w')
      const h = node.getBaseAttribute('h')

      const adjustedTop = getAdjustedTopToNode(node, newParent, y)
      const adjustedLeft = getAdjustedLeftToNode(node, newParent, x)
      const adjustedBottom = getAdjustedBottomToNode(node, newParent, y + h)
      const adjustedRight = getAdjustedRightToNode(node, newParent, x + w)
      const adjustedWidth = getAdjustedWidthToNode(node, newParent, w)
      const adjustedHeight = getAdjustedHeightToNode(node, newParent, h)

      commands.push({
        type: 'setNodeAttribute',
        params: {
          id: nodeId,
          base: {},
          style: {
            ...adjustedTop,
            ...adjustedLeft,
            ...adjustedBottom,
            ...adjustedRight,
            ...adjustedWidth,
            ...adjustedHeight,
          },
        },
      })
    }

    return commands
  }
}
