import {
  isUnitSize,
  isUnitSize2,
  isUnitSize4,
  Properties,
  UnitSize,
  UnitSize2,
  UnitSize4,
  unitSizeToString,
} from 'application/export/css'
import { TailwindClassRenderer } from '../types'

export class TailwindClassPadding implements TailwindClassRenderer {
  render = (properties: Partial<Properties>): string[] => {
    const classes: string[] = []

    const padding = properties.padding
    if (isUnitSize(padding)) {
      classes.push(this.getPadding1Class(padding))
    } else if (isUnitSize2(padding)) {
      classes.push(this.getPadding2Class(padding))
    } else if (isUnitSize4(padding)) {
      classes.push(this.getPadding4Class(padding))
    }

    return classes
  }

  private getPadding1Class(padding: UnitSize): string {
    return `p-[${unitSizeToString(padding)}]`
  }

  private getPadding2Class(padding: UnitSize2): string {
    return `py-[${unitSizeToString(padding[0])}] px-[${unitSizeToString(padding[1])}]`
  }

  private getPadding4Class(padding: UnitSize4): string {
    return `pt-[${unitSizeToString(padding[0])}] pr-[${unitSizeToString(padding[1])}] pb-[${unitSizeToString(padding[2])}] pl-[${unitSizeToString(padding[3])}]`
  }
}
