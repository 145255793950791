import {
  AttributeType,
  MultiselectBaseMap,
  MultiselectStyleMap,
} from 'application/attributes'
import { Size } from 'application/shapes'
import { ReadOnlyNode } from 'application/node'
import { AttributePanel } from './attributePanel'

type ImageLibraryPanelBaseKeys =
  | 'image.src'
  | 'image.originalSize.w'
  | 'image.originalSize.h'
type ImageLibraryPanelBaseAttributes = Pick<
  MultiselectBaseMap,
  ImageLibraryPanelBaseKeys
> | null

type ImageLibraryPanelStyleKeys = 'image.resize' | 'background'
type ImageLibraryPanelStyleAttributes = Pick<
  MultiselectStyleMap,
  ImageLibraryPanelStyleKeys
> | null

export interface ImageLibraryPanelState {
  baseAttributes: ImageLibraryPanelBaseAttributes
  styleAttributes: ImageLibraryPanelStyleAttributes
}

export interface ImageLibraryPanelHandlers {
  setSrc: (src: string, size: Size) => void
}

export class ImageLibraryPanel extends AttributePanel<
  ImageLibraryPanelState,
  ImageLibraryPanelHandlers,
  ImageLibraryPanelStyleKeys,
  ImageLibraryPanelBaseKeys
> {
  getSettings(): ImageLibraryPanelState {
    return {
      baseAttributes: this.baseAttributes,
      styleAttributes: this.styleAttributes,
    }
  }

  getHandlers(): ImageLibraryPanelHandlers {
    return {
      setSrc: this.setSrc,
    }
  }

  private setSrc = (src: string, size: Size): void => {
    const nodes = this.getNodes()
    for (const node of nodes) {
      switch (node.getBaseAttribute('type')) {
        case 'image':
          this.updateImage(node, src, size)
          break
        case 'page':
        case 'frame':
          this.updateBackgroundImage(node, src, size)
          break
      }
    }
    this.commit()
  }

  private updateImage = (node: ReadOnlyNode, src: string, size: Size): void => {
    const id = node.getId()
    this.setOne(
      id,
      {
        'image.src': src,
        'image.originalSize.w': size.w,
        'image.originalSize.h': size.h,
      },
      {}
    )
  }

  private updateBackgroundImage = (
    node: ReadOnlyNode,
    src: string,
    size: Size
  ): void => {
    const background = node.getStyleAttribute('background')
    if (!background) return

    const newBackground = background.map((fill) => {
      if (fill.type !== 'image') return fill
      if (fill.image === undefined) return fill
      return {
        ...fill,
        image: {
          ...fill.image,
          src: src,
          'originalSize.w': size.w,
          'originalSize.h': size.h,
        },
      }
    })

    this.setOne(node.getId(), {}, { background: newBackground })
  }

  protected override getNodeFilterPredicate = (): ((
    node: ReadOnlyNode,
    parent: ReadOnlyNode | null
  ) => boolean) => {
    return (node, _) => allowedTypes.includes(node.getBaseAttribute('type'))
  }
}

const allowedTypes: AttributeType[] = ['image', 'page', 'frame']
