import { Cursor } from 'application/cursor'
import { Cursors } from 'assets/cursorIndex'

type CursorMode = 'canvas' | 'all'

export class EditorCursor {
  private targetId: string
  private canvasCursor: Cursor

  constructor(targetId: string) {
    this.targetId = targetId
    this.canvasCursor = 'canvasDefault'
  }

  initialize = () => {
    const canvas = this.getCanvasTarget()
    if (canvas) {
      canvas.style.cursor = Cursors.CanvasDefault
      this.canvasCursor = 'canvasDefault'
    }
  }

  set = (cursor: Cursor) => {
    if (this.canvasCursor === cursor) return
    const mode = this.getCursorMode(cursor)
    const canvas = this.getCanvasTarget()
    const mappedCursor = this.mapCursorByType(cursor)

    if (canvas) {
      canvas.style.cursor = mappedCursor
      this.canvasCursor = cursor
    }

    if (mode === 'all') {
      document.body.style.cursor = mappedCursor
    }
  }

  reset = () => {
    const currentMode = this.getCursorMode(this.canvasCursor)
    const canvas = this.getCanvasTarget()
    if (canvas) {
      canvas.style.cursor = Cursors.CanvasDefault
      this.canvasCursor = 'canvasDefault'
    }
    if (currentMode === 'all') {
      document.body.style.cursor = 'default'
    }
  }

  private getCanvasTarget = (): HTMLCanvasElement | null => {
    const canvas = document.getElementById(this.targetId)
    if (canvas instanceof HTMLCanvasElement) {
      return canvas
    } else {
      return null
    }
  }

  private mapCursorByType = (cursor: Cursor) => {
    switch (cursor) {
      case 'handOpen':
        return Cursors.HandOpen
      case 'handClosed':
        return Cursors.HandClosed
      case 'drawPage':
        return Cursors.DrawPage
      case 'drawFrame':
        return Cursors.DrawFrame
      case 'drawHeading':
        return Cursors.DrawHeading
      case 'drawText':
        return Cursors.DrawText
      case 'drawAnchor':
        return Cursors.DrawAnchor
      case 'drawImage':
        return Cursors.DrawImage
      case 'drawInput':
        return Cursors.DrawInput
      case 'drawForm':
        return Cursors.DrawForm
      case 'target':
        return Cursors.Target
      case 'grabbing':
        return 'grabbing'
      case 'top':
        return 'ns-resize'
      case 'bottom':
        return 'ns-resize'
      case 'left':
        return 'ew-resize'
      case 'right':
        return 'ew-resize'
      case 'top-left':
        return 'nwse-resize'
      case 'top-right':
        return 'nesw-resize'
      case 'bottom-left':
        return 'nesw-resize'
      case 'bottom-right':
        return 'nwse-resize'
      default:
        return Cursors.CanvasDefault
    }
  }

  private getCursorMode = (cursor: Cursor): CursorMode => {
    switch (cursor) {
      case 'target':
        return 'all'
      default:
        return 'canvas'
    }
  }
}
