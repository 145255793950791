import { CommandHandler, EditorState } from '../textEditor'
import { TextCommand, TextCommandWidth } from '../command/types'

export class WidthHandler implements CommandHandler {
  private state: EditorState

  constructor(state: EditorState) {
    this.state = state
  }

  handle = (command: TextCommand): void => {
    switch (command.type) {
      case 'width':
        this.handleWidth(command)
        break
    }
  }

  private handleWidth = (command: TextCommandWidth): void => {
    const state = this.state.get()

    state.width = command.parameters.width

    this.state.set(state)
  }
}
