interface ProjectMainContainerProps {
  children?: React.ReactNode
}

export default function ProjectMainContainer({
  children,
}: ProjectMainContainerProps) {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        display: 'flex',
        overflow: 'hidden',
      }}
    >
      {children}
    </div>
  )
}
