import { ReadOnlyNode } from 'application/node'
import { SelectionUpdateListener } from './types'
import { ReadOnlyDocument } from 'application/document'
import _ from 'lodash'
import { computeSelectionRectangle } from './utils'
import { Rectangle } from 'application/shapes'

export class DocumentSelection {
  private document: ReadOnlyDocument
  private listener: SelectionUpdateListener
  private selectedNodes: ReadOnlyNode[]
  private selectedCanvas: string

  constructor(document: ReadOnlyDocument, listener: SelectionUpdateListener) {
    this.document = document
    this.listener = listener
    this.selectedNodes = []
    this.selectedCanvas = this.getFirstCanvas()
  }

  isSelected = (id: string): boolean => {
    return this.selectedNodes.some((node) => node.getId() === id)
  }

  getSelected = (): ReadOnlyNode[] => {
    return this.selectedNodes
  }

  getSelectedCanvas = (): string => {
    return this.selectedCanvas
  }

  getSelectionRectangle = (): Rectangle | null => {
    return computeSelectionRectangle(this.selectedNodes)
  }

  select = (ids: string[], clear: boolean = false): void => {
    const initial = this.selectedNodes

    if (clear) {
      const nodes = ids
        .map((id) => this.document.getNode(id))
        .filter((node) => node !== undefined) as ReadOnlyNode[]

      this.setSelection(
        nodes.filter((node) =>
          nodes.every((n) => this.canBothBeSelected(node, n))
        )
      )

      this.publishUpdate(initial)
      return
    }

    const nodes = ids
      .map((id) => this.document.getNode(id))
      .filter((node) => node !== undefined) as ReadOnlyNode[]
    const filteredNodes = nodes.filter((node) =>
      nodes.every((n) => this.canBothBeSelected(node, n))
    )
    const filteredCurrent = this.selectedNodes.filter((node) =>
      filteredNodes.every((n) => this.canBothBeSelected(node, n))
    )

    this.setSelection([...filteredCurrent, ...filteredNodes])

    this.publishUpdate(initial)
  }

  unselect = (id: string): void => {
    const initial = this.selectedNodes

    const found = this.selectedNodes.find((node) => node.getId() === id)
    if (found === undefined) return

    this.setSelection(this.selectedNodes.filter((node) => node.getId() !== id))

    this.publishUpdate(initial)
  }

  selectCanvas = (id: string): void => {
    if (this.selectedCanvas === id) return
    const initial = this.selectedCanvas

    this.selectedCanvas = id

    if (initial === this.selectedCanvas) return
    if (!this.listener.onCanvasChange) return
    this.listener.onCanvasChange(initial, this.selectedCanvas)
  }

  private setSelection = (nodes: ReadOnlyNode[]): void => {
    this.selectedNodes = _.uniq(nodes)
  }

  private publishUpdate = (initial: ReadOnlyNode[]): void => {
    if (_.isEqual(initial, this.selectedNodes)) return
    if (!this.listener.onChange) return
    this.listener.onChange(initial, this.selectedNodes)
  }

  private getFirstCanvas = (): string => {
    const root = this.document.getRoot()
    if (root === undefined) return ''

    const canvases = root.getChildren()
    if (canvases === undefined || canvases.length === 0) return ''

    return canvases[0]
  }

  private canBothBeSelected = (n1: ReadOnlyNode, n2: ReadOnlyNode): boolean => {
    if (n1.getId() === n2.getId()) return true
    if (this.document.isDescendantOf(n1, n2)) return false
    if (this.document.isAncestorOf(n1, n2)) return false
    return true
  }
}
