import {
  AttributeOverflow,
  AttributeType,
  MultiselectStyleMap,
} from 'application/attributes'
import { StyleAttributePanel } from './styleAttributePanel'
import { ReadOnlyNode } from 'application/node'

type OverflowPanelKeys = 'overflow'

type OverflowPanelAttributes = Pick<
  MultiselectStyleMap,
  OverflowPanelKeys
> | null

export interface OverflowPanelState {
  attributes: OverflowPanelAttributes
}

export interface OverflowPanelHandlers {
  setOverflow: (value: AttributeOverflow) => void
}

export class OverflowPanel extends StyleAttributePanel<
  OverflowPanelState,
  OverflowPanelHandlers,
  OverflowPanelKeys
> {
  getSettings(): OverflowPanelState {
    return {
      attributes: this.attributes,
    }
  }

  getHandlers(): OverflowPanelHandlers {
    return {
      setOverflow: this.setOverflow,
    }
  }

  private setOverflow = (value: AttributeOverflow): void => {
    this.setMulti({ overflow: value })
    this.commit()
  }

  protected override getNodeFilterPredicate = (): ((
    node: ReadOnlyNode,
    parent: ReadOnlyNode | null
  ) => boolean) => {
    return (node, _) => allowedTypes.includes(node.getBaseAttribute('type'))
  }
}

const allowedTypes: AttributeType[] = [
  'frame',
  'page',
  'form',
  'button',
  'anchor',
]
