import { Portal } from 'components/Pages/Editor/PortalRoot/PortalRoot'

interface FullScreenPopupContainerProps {
  children: React.ReactNode

  close: () => void
}

export default function FullScreenPopupContainer({
  children,
}: FullScreenPopupContainerProps) {
  return (
    <Portal>
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          boxSizing: 'border-box',
          backgroundColor: 'rgba(0, 0, 0, 0.70)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {children}
      </div>
    </Portal>
  )
}
