import { DesignColor } from 'themes'

interface ColorIconProps {
  color: string
}

export default function ColorIcon({ color }: ColorIconProps) {
  return (
    <div
      style={{
        width: 24,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <div
        style={{
          width: 16,
          height: 16,
          borderRadius: 4,
          boxSizing: 'border-box',
          backgroundColor: '#' + color,
          border: `1px solid ${DesignColor('panelBorder')}`,
        }}
      />
    </div>
  )
}
