import { SelectorMode, Style, StyleMap, StyleSelector } from './types'
import { StyleBuilder } from './styleBuilder'
import { formatStyle } from './utils'

export class StyleMapImpl implements StyleMap {
  private styles: Style[] = []

  get = (selector: StyleSelector): Style => {
    const existing = this.styles.find((s) => s.getSelector().equals(selector))
    if (!existing) {
      const newStyle = this.createNewStyle(selector)
      this.styles.push(newStyle)
      return newStyle
    }
    return existing
  }

  getForMode = (value: string, mode: SelectorMode): Style[] => {
    return this.styles.filter((s) => s.getSelector().hasMode(value, mode))
  }

  toString(): string {
    let styles = ''
    const sortedStyles = [...this.styles].sort((a, b) =>
      a.getSelector().compareTo(b.getSelector())
    )
    for (const style of sortedStyles) {
      styles += formatStyle(style)
    }
    return styles
  }

  private createNewStyle = (selector: StyleSelector): Style => {
    return new StyleBuilder().withSelector(selector).build()
  }
}
