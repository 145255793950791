import IconBlock from 'components/Library/Components/IconBlock/IconBlock/IconBlock'
import NumberBlock from 'components/Library/Components/NumberBlock/NumberBlock'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'
import { useRef, useState } from 'react'
import OverrideReset from 'components/Library/Components/Inputs/OverrideReset/OverrideReset'
import TitleRow from 'components/Library/Components/Button/TitleRow/TitleRow'
import { StyleMap } from 'application/attributes'

export default function PaddingSettings() {
  const sectionRef = useRef<HTMLDivElement>(null)
  const [displaySplit, setDisplaySplit] = useState(false)

  const padding = usePanelHelper(editor.getUI().getPaddingPanel())
  const overrides = usePanelHelper(editor.getUI().getStyleOverridesPanel())
  if (!padding.settings || !padding.settings.attributes) return <></>

  const { setPadding, collapsePadding, slidePadding, activate, deactivate } =
    padding.handlers
  const { left, right, bottom, top, activationMode } = padding.settings

  const split = left !== right || top !== bottom || displaySplit

  const { hasOverride, sectionHasOverride } = overrides.handlers

  const handleClickRow = () => {
    if (!active) activate()
  }

  const handleClickIcon = () => {
    active ? deactivate() : activate()
  }

  const active = activationMode === 'remove'

  return (
    <PanelSection>
      <TitleRow
        title={'Padding'}
        active={active}
        canDeactivate={active}
        sectionRef={sectionRef}
        onClickRow={handleClickRow}
        onClickIcon={handleClickIcon}
        overrideSectionKey={'padding'}
        hasOverride={sectionHasOverride('padding')}
      />
      {active && (
        <>
          <PanelRow>
            <OverrideReset keys={getLeftKeys(split)}>
              <NumberBlock
                icon={split ? 'PaddingLeft' : 'PaddingH'}
                tooltipKey={split ? 'PaddingLeft' : 'PaddingHorizontal'}
                value={left}
                min={0}
                setValue={(v) => setPadding(v, 'left', split)}
                increment={(v) => slidePadding(v, 'left', split)}
                decrement={(v) => slidePadding(-v, 'left', split)}
                hasOverride={hasOverride(getLeftKeys(split))}
              />
            </OverrideReset>
            <OverrideReset keys={getTopKeys(split)}>
              <NumberBlock
                icon={split ? 'PaddingTop' : 'PaddingV'}
                tooltipKey={split ? 'PaddingTop' : 'PaddingVertical'}
                value={top}
                min={0}
                setValue={(v) => setPadding(v, 'top', split)}
                increment={(v) => slidePadding(v, 'top', split)}
                decrement={(v) => slidePadding(-v, 'top', split)}
                hasOverride={hasOverride(getTopKeys(split))}
              />
            </OverrideReset>
            <IconBlock
              icon={!split ? 'Link' : 'LinkBroken'}
              tooltipKey={'PaddingSplit'}
              tooltipDirection={'left'}
              selected={split}
              onClick={() => {
                if (split) {
                  if (!displaySplit) collapsePadding()
                  setDisplaySplit(false)
                } else {
                  setDisplaySplit(true)
                }
              }}
            />
          </PanelRow>
          {split && (
            <PanelRow>
              <OverrideReset keys={getRightKeys(split)}>
                <NumberBlock
                  icon={'PaddingRight'}
                  tooltipKey={'PaddingRight'}
                  value={right}
                  min={0}
                  setValue={(v) => setPadding(v, 'right', split)}
                  increment={(v) => slidePadding(v, 'right', split)}
                  decrement={(v) => slidePadding(-v, 'right', split)}
                  hasOverride={hasOverride(getRightKeys(split))}
                />
              </OverrideReset>
              <OverrideReset keys={getBottomKeys(split)}>
                <NumberBlock
                  icon={'PaddingBottom'}
                  tooltipKey={'PaddingBottom'}
                  value={bottom}
                  min={0}
                  setValue={(v) => setPadding(v, 'bottom', split)}
                  increment={(v) => slidePadding(v, 'bottom', split)}
                  decrement={(v) => slidePadding(-v, 'bottom', split)}
                  hasOverride={hasOverride(getBottomKeys(split))}
                />
              </OverrideReset>
            </PanelRow>
          )}
        </>
      )}
    </PanelSection>
  )
}

function getTopKeys(split: boolean): (keyof StyleMap)[] {
  return split
    ? ['padding.top.unit', 'padding.top.px']
    : [
        'padding.top.unit',
        'padding.top.px',
        'padding.bottom.unit',
        'padding.bottom.px',
      ]
}

function getLeftKeys(split: boolean): (keyof StyleMap)[] {
  return split
    ? ['padding.left.unit', 'padding.left.px']
    : [
        'padding.left.unit',
        'padding.left.px',
        'padding.right.unit',
        'padding.right.px',
      ]
}

function getRightKeys(split: boolean): (keyof StyleMap)[] {
  return split
    ? ['padding.right.unit', 'padding.right.px']
    : [
        'padding.right.unit',
        'padding.right.px',
        'padding.left.unit',
        'padding.left.px',
      ]
}

function getBottomKeys(split: boolean): (keyof StyleMap)[] {
  return split
    ? ['padding.bottom.unit', 'padding.bottom.px']
    : [
        'padding.bottom.unit',
        'padding.bottom.px',
        'padding.top.unit',
        'padding.top.px',
      ]
}
