import {
  AttributeFill,
  AttributeTextAlign,
  AttributeType,
  StyleMap,
  getTextAttributes,
} from 'application/attributes'
import { StyleAttributePanel } from '../styleAttributePanel'
import { ReadOnlyNode } from 'application/node'
import {
  TextPanel,
  TextPanelAttributes,
  TextPanelHandlers,
  TextPanelKeys,
  TextPanelState,
} from './interface'
import _ from 'lodash'
import { Style, Styles, TextEditorStyles } from 'application/textEditor'
import { FontKey, FontWeight, ReadOnlyFontDataMap } from 'application/text'
import { NodeAttributesAction } from 'application/action/attributes'
import { ReadOnlyDocument } from 'application/document'
import { ReadOnlyDocumentSelection } from 'application/selection'
import { CommandHandler } from 'application/client'

export class TextPanelDefault
  extends StyleAttributePanel<TextPanelState, TextPanelHandlers, TextPanelKeys>
  implements TextPanel
{
  private fontMap: ReadOnlyFontDataMap
  private styles: Styles

  constructor(
    commandHandler: CommandHandler,
    action: NodeAttributesAction,
    document: ReadOnlyDocument,
    documentSelection: ReadOnlyDocumentSelection,
    fontMap: ReadOnlyFontDataMap
  ) {
    super(commandHandler, action, document, documentSelection)
    this.fontMap = fontMap
    this.styles = new TextEditorStyles(fontMap)
  }

  getSettings(): TextPanelState {
    return {
      attributes: this.getAttributes(),
    }
  }

  getHandlers(): TextPanelHandlers {
    return {
      getFontData: this.fontMap.getFontData,
      setFontFamily: this.setFontFamily,
      setFontWeight: this.setFontWeight,
      setFontSize: this.setFontSize,
      slideFontSize: this.slideFontSize,
      setColor: this.setColor,
      clearMixedColor: this.clearMixedColor,
      setLetterSpacing: this.setLetterSpacing,
      slideLetterSpacing: this.slideLetterSpacing,
      setLineHeight: this.setLineHeight,
      slideLineHeight: this.slideLineHeight,
      setAlignment: this.setAlignment,
    }
  }

  private getAttributes = (): TextPanelAttributes => {
    const nodes = this.getNodes()
    if (nodes.length === 0) return null

    const attributes = this.getNodeAttributes(nodes[0])
    if (!attributes) return null

    for (let i = 1; i < nodes.length; i++) {
      const nodeAttributes = this.getNodeAttributes(nodes[i])
      if (!nodeAttributes) return null

      for (const key in attributes) {
        const typedKey = key as TextPanelKeys
        if (attributes[typedKey] === 'Mixed') continue
        if (_.isEqual(attributes[typedKey], nodeAttributes[typedKey])) continue
        attributes[typedKey] = 'Mixed'
      }
    }

    return attributes
  }

  private setFontFamily = (fontFamily: FontKey): void => {
    for (const node of this.getNodes()) {
      const attributes = this.setStyles({ fontFamily })
      this.setOne(node.getId(), attributes)
    }
    this.commit()
  }

  private setFontWeight = (fontWeight: FontWeight): void => {
    for (const node of this.getNodes()) {
      const attributes = this.setStyles({ fontWeight })
      this.setOne(node.getId(), attributes)
    }
    this.commit()
  }

  private setFontSize = (fontSize: number): void => {
    for (const node of this.getNodes()) {
      const attributes = this.setStyles({ fontSize })
      this.setOne(node.getId(), attributes)
    }
  }

  private slideFontSize = (fontSize: number): void => {
    for (const node of this.getNodes()) {
      const current = getTextAttributes(node)
      if (!current) return

      const currentFontSize = current['text.font.size']
      if (currentFontSize === undefined) return
      const attributes = this.setStyles({
        fontSize: Math.max(currentFontSize + fontSize, 1),
      })
      this.setOne(node.getId(), attributes)
    }
  }

  private setColor = (fill: AttributeFill): void => {
    for (const node of this.getNodes()) {
      const attributes = this.setStyles({ fill })
      this.setOne(node.getId(), attributes)
    }
  }

  private clearMixedColor = (): void => {
    let firstColor: AttributeFill | undefined = undefined
    for (const node of this.getNodes()) {
      const current = this.getNodeAttributes(node)
      if (!current) return
      const color = current['text.color']
      if (color === undefined || color === 'Mixed') continue
      firstColor = color
      break
    }
    for (const node of this.getNodes()) {
      const attributes = this.setStyles({ fill: firstColor })
      this.setOne(node.getId(), attributes)
    }
    this.commit()
  }

  private setLetterSpacing = (letterSpacing: number): void => {
    for (const node of this.getNodes()) {
      const attributes = this.setStyles({ letterSpacing })
      this.setOne(node.getId(), attributes)
    }
  }

  private slideLetterSpacing = (letterSpacing: number): void => {
    for (const node of this.getNodes()) {
      const current = getTextAttributes(node)
      if (!current) return

      const currentValue = current['text.letterSpacing']
      if (currentValue === undefined) return
      const attributes = this.setStyles({
        letterSpacing: Math.max(currentValue + letterSpacing, -100),
      })
      this.setOne(node.getId(), attributes)
    }
  }

  private setLineHeight = (lineHeight: number): void => {
    for (const node of this.getNodes()) {
      const attributes = this.setStyles({ lineHeight })
      this.setOne(node.getId(), attributes)
    }
  }

  private slideLineHeight = (lineHeight: number): void => {
    for (const node of this.getNodes()) {
      const current = getTextAttributes(node)
      if (!current) return

      const currentValue = current['text.lineHeight']
      if (currentValue === undefined) return
      const attributes = this.setStyles({
        lineHeight: Math.max(currentValue + lineHeight, -100),
      })
      this.setOne(node.getId(), attributes)
    }
  }

  private setAlignment = (align: AttributeTextAlign): void => {
    for (const node of this.getNodes()) {
      const attributes = this.setStyles({ align })
      this.setOne(node.getId(), attributes)
    }
    this.commit()
  }

  private getNodeAttributes = (
    node: ReadOnlyNode
  ): TextPanelAttributes | null => {
    return getTextAttributes(node)
  }

  private setStyles = (styles: Partial<Style>): Partial<StyleMap> => {
    const styleMap: Partial<StyleMap> = {}
    if (styles.align !== undefined) {
      styleMap['text.align'] = styles.align
    }
    if (styles.fill !== undefined) {
      styleMap['text.color'] = styles.fill
    }
    if (styles.fontFamily !== undefined) {
      styleMap['text.font.family'] = styles.fontFamily
    }
    if (styles.fontWeight !== undefined) {
      styleMap['text.font.weight'] = styles.fontWeight
    }
    if (styles.fontSize !== undefined) {
      styleMap['text.font.size'] = styles.fontSize
    }
    if (styles.letterSpacing !== undefined) {
      styleMap['text.letterSpacing'] = styles.letterSpacing
    }
    if (styles.lineHeight !== undefined) {
      styleMap['text.lineHeight'] = styles.lineHeight
    }
    return styleMap
  }

  protected override getNodeFilterPredicate = (): ((
    node: ReadOnlyNode,
    parent: ReadOnlyNode | null
  ) => boolean) => {
    return (node) => allowedTypes.includes(node.getBaseAttribute('type'))
  }
}

const allowedTypes: AttributeType[] = ['input', 'paragraph', 'button', 'anchor']
