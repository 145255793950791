import { HistoryActionListener } from 'application/history'
import { NodeUpdateListener } from 'application/node'
import { BreakpointUpdateHandler } from './update'

export class BreakpointEngine
  implements HistoryActionListener, NodeUpdateListener
{
  private updateHandler: BreakpointUpdateHandler

  private historyActionInProgress: boolean

  constructor(updateHandler: BreakpointUpdateHandler) {
    this.updateHandler = updateHandler

    this.historyActionInProgress = false
  }

  onParent = (id: string): void => {
    if (this.historyActionInProgress) return
    this.updateHandler.onParent(id)
  }

  onStartUndo = (): void => {
    this.historyActionInProgress = true
  }

  onEndUndo = (): void => {
    this.historyActionInProgress = false
  }

  onStartRedo = (): void => {
    this.historyActionInProgress = true
  }

  onEndRedo = (): void => {
    this.historyActionInProgress = false
  }
}
