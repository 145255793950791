import {
  AttributePositionUnit,
  AttributeSizeUnit,
} from 'application/attributes'
import { IconKey } from 'assets/iconLibrary'
import { tooltipKey } from 'assets/tooltips'
import { dropdownOption } from 'components/Library/Components/Dropdown/Dropdown'
import DropdownNumberBlock from 'components/Library/Components/NumberBlock/DropdownNumberBlock'

interface PositionInputProps {
  icon: IconKey
  tooltipKey?: tooltipKey

  mode: 'Mixed' | AttributePositionUnit | undefined
  modeOptions: AttributePositionUnit[]
  setAuto: (v: AttributePositionUnit | undefined) => void

  value: 'Mixed' | number | undefined
  valuePercent: 'Mixed' | number | undefined
  valueDisplay: 'Mixed' | number

  setValue: (v: number) => void
  increment: (v: number) => void
  decrement: (v: number) => void

  min: number
  max: number

  hasOverride?: boolean
}

export default function PositionInput({
  icon,
  tooltipKey,
  mode,
  modeOptions,
  setAuto,
  value,
  valuePercent,
  valueDisplay,
  setValue,
  increment,
  decrement,
  min,
  max,
  hasOverride = false,
}: PositionInputProps) {
  const options = getOptions(sizeDropdownOptions, modeOptions)

  const handleSelect = (v: dropdownOptionType) => {
    switch (v) {
      case 'px':
      case 'percent':
      case undefined:
        setAuto(v)
        break
    }
  }

  switch (mode) {
    case 'px':
    case 'percent':
    case 'Mixed':
      return (
        <DropdownNumberBlock
          icon={icon}
          tooltipKey={tooltipKey}
          value={getNumberValue(mode, value, valuePercent)}
          setValue={setValue}
          increment={increment}
          decrement={decrement}
          min={min}
          max={max}
          selectedOption={mode === 'Mixed' ? undefined : mode}
          dropdownOptions={options}
          selectOption={handleSelect}
          unit={mode === 'percent' ? '%' : undefined}
          hasOverride={hasOverride}
        />
      )
    case undefined:
      return (
        <DropdownNumberBlock
          icon={icon}
          value={valueDisplay}
          setValue={setValue}
          increment={increment}
          decrement={decrement}
          min={min}
          max={max}
          empty={true}
          dropdownOptions={options}
          selectOption={handleSelect}
          hasOverride={hasOverride}
        />
      )
  }
}

type dropdownOptionType = 'px' | 'percent' | undefined

function getOptions(
  defaultOptions: dropdownOption<dropdownOptionType>[],
  options: AttributeSizeUnit[]
): dropdownOption<dropdownOptionType>[] {
  return defaultOptions.filter((o) => {
    switch (o.value) {
      case 'percent':
        return options.includes('percent')
      default:
        return true
    }
  })
}

function getNumberValue(
  auto: 'Mixed' | AttributeSizeUnit,
  value: 'Mixed' | number | undefined,
  valuePercent: 'Mixed' | number | undefined
): 'Mixed' | number | undefined {
  switch (auto) {
    case 'Mixed':
      return 'Mixed'
    case 'px':
      return value
    case 'percent':
      return valuePercent
    default:
      return undefined
  }
}

const sizeDropdownOptions: dropdownOption<dropdownOptionType>[] = [
  { value: 'px', text: 'px' },
  { value: 'percent', text: '%' },
  { value: undefined, text: 'Auto' },
]
