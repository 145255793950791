import { BackendService, ProjectFontInfo } from 'application/service'
import {
  FontDataMap,
  FontKey,
  FontLoaderInterface,
  FontWeight,
} from 'application/text'
import { EditorProjectService } from 'editor/project/project'

export type UserFont = {
  key: FontKey
  weights: FontWeight[]
}

export type FontPopupPanelSettings = {
  fonts: UserFont[]
}

export type FontPopupPanelHandlers = {
  load: () => void
  loadInternalFont: (key: FontKey) => Promise<void>
  loadExternalFont: (key: FontKey) => Promise<void>
}

type FontPopupPanelListener = (settings: FontPopupPanelSettings) => void

export class FontPopupPanel {
  private projectService: EditorProjectService
  private backendService: BackendService
  private fontLoader: FontLoaderInterface
  private fontMap: FontDataMap

  private subscribers: { [key: string]: FontPopupPanelListener }
  private projectFonts: ProjectFontInfo[]
  private fonts: UserFont[]

  constructor(
    projectService: EditorProjectService,
    backendService: BackendService,
    fontLoader: FontLoaderInterface,
    fontMap: FontDataMap
  ) {
    this.projectService = projectService
    this.backendService = backendService
    this.fontLoader = fontLoader
    this.fontMap = fontMap

    this.subscribers = {}
    this.projectFonts = []
    this.fonts = []
  }

  getSettings(): FontPopupPanelSettings {
    return {
      fonts: this.fonts,
    }
  }

  getHandlers(): FontPopupPanelHandlers {
    return {
      load: this.load,
      loadInternalFont: this.loadInternalFont,
      loadExternalFont: this.loadExternalFont,
    }
  }

  subscribe = (key: string, listener: FontPopupPanelListener) => {
    this.subscribers[key] = listener
  }

  unsubscribe = (key: string) => {
    delete this.subscribers[key]
  }

  private load = async () => {
    const projectId = this.projectService.getProjectId()
    if (!projectId) return

    const projectFonts = await this.backendService.getFonts(projectId)
    if (!projectFonts) return

    const fontMap: { [key: string]: Set<FontWeight> } = {}
    for (const font of projectFonts) {
      this.fontMap.addProjectFont(font)
      if (!fontMap[font.family]) fontMap[font.family] = new Set()
      fontMap[font.family].add(font.weight)
    }

    this.projectFonts = projectFonts
    this.fonts = Object.keys(fontMap).map((key) => ({
      key: key,
      weights: Array.from(fontMap[key]),
    }))

    this.notifyListeners()
  }

  private loadInternalFont = async (key: FontKey) => {
    await this.fontLoader.loadInternalFont(key)
  }

  private loadExternalFont = async (key: FontKey) => {
    const weights = this.projectFonts.filter((font) => font.family === key)
    if (!weights) return

    for (const weight of weights) {
      await this.fontLoader.loadExternalFont(
        key,
        weight.weight,
        weight.fileUrl,
        weight.msdfJsonUrl,
        weight.msdfPngUrl
      )
    }
  }

  private notifyListeners = () => {
    const settings = this.getSettings()
    for (const key in this.subscribers) {
      this.subscribers[key](settings)
    }
  }
}
