import {
  SvgJson,
  SvgPath,
  PathCommand,
  CubicBezierCommand,
  SvgPathTriangles,
} from 'application/render/svg/types'
import { Point } from 'application/shapes'
import { GluTesselator, gluEnum } from 'libtess'

export function parseSvgToSvgJson(svgString: string): SvgJson {
  const parser = new DOMParser()
  const xmlDoc = parser.parseFromString(svgString, 'image/svg+xml')
  const svgElement = xmlDoc.getElementsByTagName('svg')[0]

  const width = parseInt(svgElement.getAttribute('width') || '0', 10)
  const height = parseInt(svgElement.getAttribute('height') || '0', 10)
  const backgroundColor = svgElement.getAttribute('fill') || 'none'

  const paths: SvgPath[] = Array.from(svgElement.querySelectorAll('path')).map(
    (pathElement) => ({
      fillRule: pathElement.getAttribute('fill-rule') as 'evenodd' | 'nonzero',
      color: pathElement.getAttribute('fill') || 'none',
      commands: parsePathDAttribute(pathElement.getAttribute('d') || ''),
    })
  )

  return { w: width, h: height, color: backgroundColor, paths }
}

function parsePathDAttribute(d: string): PathCommand[] {
  const commandRegex = /([MLHVCSQTAZ])([^MLHVCSQTAZ]*)/gi
  let match: RegExpExecArray | null
  const commands: PathCommand[] = []

  while ((match = commandRegex.exec(d)) !== null) {
    const type = match[1]
    const args = match[2]
      .trim()
      .split(/[\s,]+/)
      .map(Number)

    switch (type) {
      case 'M':
        commands.push({ type: 'M', x: args[0], y: args[1] })
        break
      case 'L':
        commands.push({ type: 'L', x: args[0], y: args[1] })
        break
      case 'H':
        commands.push({ type: 'H', x: args[0] })
        break
      case 'V':
        commands.push({ type: 'V', y: args[0] })
        break
      case 'C':
        commands.push({
          type: 'C',
          c1x: args[0],
          c1y: args[1],
          c2x: args[2],
          c2y: args[3],
          x: args[4],
          y: args[5],
        })
        break
      case 'Z':
        commands.push({ type: 'Z' })
        break
    }
  }

  return commands
}

export function getSvgTriangles(svg: SvgJson): SvgPathTriangles {
  return svg.paths.map((path) => getPathFillTriangles(path))
}

function getPathFillTriangles(svgPath: SvgPath) {
  const aTriangles: number[] = []
  const tessy = new GluTesselator()
  tessy.gluTessBeginPolygon(null)

  tessy.gluTessCallback(
    gluEnum.GLU_TESS_VERTEX,
    (data: any, polygonData: any) => {
      aTriangles.push(data[0], data[1])
    }
  )

  var lastPoint: Point = { x: 0, y: 0 }

  svgPath.commands.forEach((cmd, index) => {
    switch (cmd.type) {
      case 'M':
        if (index > 0) {
          tessy.gluTessEndContour()
        }
        tessy.gluTessBeginContour()
        tessy.gluTessVertex([cmd.x, cmd.y, 0], [cmd.x, cmd.y, 0])
        lastPoint = { x: cmd.x, y: cmd.y }
        break
      case 'L':
        tessy.gluTessVertex([cmd.x, cmd.y, 0], [cmd.x, cmd.y, 0])
        lastPoint = { x: cmd.x, y: cmd.y }
        break
      case 'H':
        tessy.gluTessVertex([cmd.x, lastPoint.y, 0], [cmd.x, lastPoint.y, 0])
        lastPoint = { x: cmd.x, y: lastPoint.y }
        break
      case 'V':
        tessy.gluTessVertex([lastPoint.x, cmd.y, 0], [lastPoint.x, cmd.y, 0])
        lastPoint = { x: lastPoint.x, y: cmd.y }
        break
      case 'C':
        const points = cubicBezierToPoints(lastPoint, cmd)
        for (const point of points) {
          tessy.gluTessVertex([point.x, point.y, 0], [point.x, point.y, 0])
        }
        lastPoint = { x: cmd.x, y: cmd.y }
        break
      case 'Z':
        tessy.gluTessEndContour()
        break
      default:
        break
    }
  })
  tessy.gluTessEndPolygon()

  return aTriangles
}

function cubicBezierToPoints(lastPoint: Point, cmd: CubicBezierCommand) {
  const SEGMENTS = 10
  const points: Point[] = []
  for (let i = 0; i <= SEGMENTS; i++) {
    const t = i / SEGMENTS
    const x =
      Math.pow(1 - t, 3) * lastPoint.x +
      3 * Math.pow(1 - t, 2) * t * cmd.c1x +
      3 * (1 - t) * Math.pow(t, 2) * cmd.c2x +
      Math.pow(t, 3) * cmd.x
    const y =
      Math.pow(1 - t, 3) * lastPoint.y +
      3 * Math.pow(1 - t, 2) * t * cmd.c1y +
      3 * (1 - t) * Math.pow(t, 2) * cmd.c2y +
      Math.pow(t, 3) * cmd.y
    points.push({ x, y })
  }

  return points
}
