import { HTMLDocumentImpl } from './document'
import { Element, HTMLDocument } from './types'
import { ContainerElementBuilder } from './elementContainerBuilder'
import { StyleMap } from '../css'
import { StyleMapBuilder } from '../css/styleMapBuilder'

export class HTMLDocumentBuilder {
  private root: Element | null = null
  private head: Element | null = null
  private body: Element | null = null
  private styleMap: StyleMap | null = null

  withRoot = (root: Element): HTMLDocumentBuilder => {
    this.root = root
    return this
  }

  withHead = (head: Element): HTMLDocumentBuilder => {
    this.head = head
    return this
  }

  withBody = (body: Element): HTMLDocumentBuilder => {
    this.body = body
    return this
  }

  withStyleMap = (styleMap: StyleMap): HTMLDocumentBuilder => {
    this.styleMap = styleMap
    return this
  }

  build = (): HTMLDocument => {
    if (!this.root) this.addRoot()
    if (!this.head) this.addHead()
    if (!this.body) this.addBody()
    if (!this.styleMap) this.addStyleMap()

    if (!this.root || !this.head || !this.body || !this.styleMap) {
      throw new Error('Root, head, body, and style map are required')
    }
    return new HTMLDocumentImpl(this.root, this.head, this.body, this.styleMap)
  }

  private addRoot = (): void => {
    this.root = new ContainerElementBuilder()
      .withTag('html')
      .withAttributes({ lang: 'en' })
      .withChildren([])
      .build()
  }

  private addHead = (): void => {
    if (!this.root) throw new Error('Root element is required')
    this.head = new ContainerElementBuilder()
      .withTag('head')
      .withChildren([])
      .build()
    this.root.addChild(this.head)
  }

  private addBody = (): void => {
    if (!this.root) throw new Error('Root element is required')
    this.body = new ContainerElementBuilder()
      .withTag('body')
      .withChildren([])
      .build()
    this.root.addChild(this.body)
  }

  private addStyleMap = (): void => {
    this.styleMap = new StyleMapBuilder().build()
  }
}
