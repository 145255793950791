import { DesignColor } from 'themes'
import ProjectCreateButton from './ProjectCreateButton'
import ProjectSortButton, { ProjectGridSortMode } from './ProjectSortButton'
import MenuTextInput from 'components/Library/Menus/MenuTextInput'

interface ProjectGridControlsProps {
  searchString: string
  sortMode: ProjectGridSortMode

  setSearchString: (searchString: string) => void
  setSortMode: (sortMode: ProjectGridSortMode) => void
  createProject: () => void
}

export default function ProjectGridControls({
  searchString,
  sortMode,
  setSearchString,
  setSortMode,
  createProject,
}: ProjectGridControlsProps) {
  return (
    <div
      style={{
        position: 'sticky',
        width: '100%',
        padding: '24px',
        flexShrink: 0,
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
        gap: '24px',
        backgroundColor: DesignColor('menuBackground'),
      }}
    >
      <MenuTextInput
        value={searchString}
        iconKey="Search"
        placeholder="Search"
        onChange={setSearchString}
      />
      <div />
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          gap: '24px',
        }}
      >
        <ProjectSortButton sortMode={sortMode} setSortMode={setSortMode} />
        <ProjectCreateButton createProject={createProject} />
      </div>
    </div>
  )
}
