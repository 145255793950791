import { NodeCreateAction, NodeSelectionAction } from 'application/action'
import { Command } from 'application/client'
import { ReadOnlyNode } from 'application/node'
import { ReadOnlyDocumentSelection } from 'application/selection'
import { BackendService } from 'application/service/backend'
import { EditorProjectService } from 'editor/project/project'
import { v4 } from 'uuid'

type UploadedImage = {
  url: string
  w: number
  h: number
}

type CommandHandler = {
  handle: (command: Command | Command[]) => void
}

export class PasteAction {
  private documentSelection: ReadOnlyDocumentSelection
  private createAction: NodeCreateAction
  private selectionAction: NodeSelectionAction
  private commandHandler: CommandHandler
  private projectService: EditorProjectService
  private backendService: BackendService

  constructor(
    documentSelection: ReadOnlyDocumentSelection,
    createAction: NodeCreateAction,
    selectionAction: NodeSelectionAction,
    commandHandler: CommandHandler,
    projectService: EditorProjectService,
    backendService: BackendService
  ) {
    this.documentSelection = documentSelection
    this.createAction = createAction
    this.selectionAction = selectionAction
    this.commandHandler = commandHandler
    this.projectService = projectService
    this.backendService = backendService
  }

  paste = async (e: ClipboardEvent): Promise<void> => {
    const images = this.parseImagesFromClipboardEvent(e)
    if (images.length > 0) {
      await this.pasteImages(images)
    } else {
      this.commandHandler.handle({ type: 'pasteNodes' })
    }

    this.commandHandler.handle({ type: 'commit' })
  }

  pasteFromClipboard = async (): Promise<void> => {
    const images = await this.parseImagesFromClipboard()
    if (images.length > 0) {
      await this.pasteImages(images)
    } else {
      this.commandHandler.handle({ type: 'pasteNodes' })
    }

    this.commandHandler.handle({ type: 'commit' })
  }

  drop = async (e: DragEvent): Promise<void> => {
    e.preventDefault()
    const images = this.parseImagesFromDragEvent(e)
    if (images.length > 0) {
      await this.pasteImages(images)
      this.commandHandler.handle({ type: 'commit' })
    }
  }

  private pasteImages = async (images: File[]): Promise<void> => {
    const uploaded = await this.uploadImage(images)
    if (uploaded.length === 0) return

    const updated = this.updateImageFills(uploaded)
    if (updated) return

    const ids: string[] = []
    for (const i of uploaded) {
      const nodeId = this.createAction.insert('image', {
        x: 0,
        y: 0,
        w: i.w,
        h: i.h,
      })
      if (!nodeId) continue

      this.commandHandler.handle({
        type: 'setNodeAttribute',
        params: {
          id: nodeId,
          base: {
            'image.src': i.url,
            'image.originalSize.h': i.h,
            'image.originalSize.w': i.w,
          },
          style: {
            'image.resize': 'fill',
          },
        },
      })
      ids.push(nodeId)
    }

    this.selectionAction.selectNodes(ids, true)
  }

  private uploadImage = async (images: File[]): Promise<UploadedImage[]> => {
    const projectId = this.projectService.getProjectId()
    if (!projectId) return []

    const uploaded: UploadedImage[] = []
    for (const i of images) {
      const asset = await this.backendService.createAsset(projectId, v4(), i)
      if (!asset) continue

      const size = await this.getImageSizeFromFile(i)
      uploaded.push({ url: asset.url, w: size.w, h: size.h })
    }

    return uploaded
  }

  private getImageSizeFromFile = (
    image: File
  ): Promise<{
    w: number
    h: number
  }> => {
    const img = new Image()
    img.src = URL.createObjectURL(image)
    return new Promise((resolve) => {
      img.onload = () => {
        resolve({ w: img.width, h: img.height })
      }
    })
  }

  private parseImagesFromClipboardEvent = (e: ClipboardEvent): File[] => {
    const images: File[] = []
    const clipboardData = e.clipboardData
    if (clipboardData && clipboardData.items) {
      if (clipboardData.items.length === 0) return []
      for (let i = 0; i < clipboardData.items.length; i++) {
        const item = clipboardData.items[i]
        if (item.kind === 'file' && item.type.includes('image')) {
          const file = item.getAsFile()
          if (file) images.push(file)
        }
      }
    }
    return images
  }

  private parseImagesFromClipboard = async (): Promise<File[]> => {
    const images: File[] = []
    const clipboard = navigator.clipboard
    if (!clipboard) return images

    try {
      const clipboardItems = await clipboard.read()
      if (clipboardItems.length === 0) return images

      for (const item of clipboardItems) {
        const imageTypes = item.types.filter((type) =>
          type.startsWith('image/')
        )
        for (const type of imageTypes) {
          const blob = await item.getType(type)
          if (blob)
            images.push(
              new File([blob], `clipboard-image.${blob.type.split('/')[1]}`, {
                type: blob.type,
              })
            )
        }
      }
    } catch (err) {}

    return images
  }

  private parseImagesFromDragEvent = (e: DragEvent): File[] => {
    const images: File[] = []
    const files = Array.from(e.dataTransfer?.files || [])
    for (const f of files) {
      if (f.type.includes('image')) images.push(f)
    }
    return images
  }

  private updateImageFills = (uploaded: UploadedImage[]): boolean => {
    if (uploaded.length === 0) return false

    const images = this.getSelectedImages()
    const imageFills = this.getSelectedImageFills()

    const commands: Command[] = []
    for (const node of images) {
      const command = this.buildImageUpdateCommand(node, uploaded[0])
      if (command) commands.push(command)
    }
    for (const node of imageFills) {
      const command = this.buildFillUpdateCommand(node, uploaded[0])
      if (command) commands.push(command)
    }
    if (commands.length === 0) return false

    this.commandHandler.handle(commands)
    return true
  }

  private getSelectedImages = (): ReadOnlyNode[] => {
    return this.documentSelection
      .getSelected()
      .filter((n) => n.getBaseAttribute('type') === 'image')
  }

  private getSelectedImageFills = (): ReadOnlyNode[] => {
    return this.documentSelection
      .getSelected()
      .filter((n) =>
        n.getStyleAttribute('background')?.some((f) => f.type === 'image')
      )
  }

  private buildImageUpdateCommand = (
    node: ReadOnlyNode,
    image: UploadedImage
  ): Command | undefined => {
    if (node.getBaseAttribute('type') !== 'image') return

    return {
      type: 'setNodeAttribute',
      params: {
        id: node.getId(),
        base: {
          'image.src': image.url,
          'image.originalSize.h': image.h,
          'image.originalSize.w': image.w,
        },
        style: {},
      },
    }
  }

  private buildFillUpdateCommand = (
    node: ReadOnlyNode,
    image: UploadedImage
  ): Command | undefined => {
    const fills = node.getStyleAttribute('background')
    if (!fills) return

    return {
      type: 'setNodeAttribute',
      params: {
        id: node.getId(),
        base: {},
        style: {
          background: fills.map((f) => {
            if (f.type === 'image' && f.image) {
              return {
                ...f,
                image: {
                  ...f.image,
                  src: image.url,
                  'originalSize.h': image.h,
                  'originalSize.w': image.w,
                },
              }
            }
            return f
          }),
        },
      },
    }
  }
}
