import {
  Camera,
  CameraUpdateListener,
  CanvasSizeUpdateListener,
} from 'application/camera'
import { Context, HapticOrder, Renderable } from 'application/render'
import { ReadOnlyDocumentSelection } from 'application/selection'
import { HapticRenderStore } from './hapticRenderStore'
import { NodeRenderStore, NodeRenderable } from './nodeRenderStore'
import { hapticGridlinesKey } from 'editor/haptic/gridlines'
import { Rectangle } from 'application/shapes'

export type CanvasUpdateListener = {
  onCanvasInit: (status: boolean) => void
}

export type CanvasRenderLoopListener = {
  onRender: () => void
}

export class Canvas implements CameraUpdateListener, CanvasSizeUpdateListener {
  private context: Context
  private selection: ReadOnlyDocumentSelection
  private nodeRenderStore: NodeRenderStore
  private hapticRenderStore: HapticRenderStore
  private hapticOrder: HapticOrder

  private canvasListeners: { [key: string]: CanvasUpdateListener }
  private renderLoopListeners: { [key: string]: CanvasRenderLoopListener }
  private inited: boolean

  constructor(
    context: Context,
    selection: ReadOnlyDocumentSelection,
    nodeRenderStore: NodeRenderStore,
    hapticRenderStore: HapticRenderStore,
    hapticOrder: HapticOrder
  ) {
    this.context = context
    this.selection = selection
    this.nodeRenderStore = nodeRenderStore
    this.hapticRenderStore = hapticRenderStore
    this.hapticOrder = hapticOrder

    this.canvasListeners = {}
    this.renderLoopListeners = {}
    this.inited = false
  }

  init = () => {
    this.inited = true

    const htmlCanvas = document.getElementById(
      'webgl-canvas'
    ) as HTMLCanvasElement
    if (!htmlCanvas) throw new Error('Could not find webgl-canvas element')

    this.context.init(htmlCanvas)
    this.startRenderLoop()
    this.notifyCanvasListeners()
  }

  getContext = (): Context => {
    return this.context
  }

  isReady = (): boolean => {
    return this.inited
  }

  setNode = (node: NodeRenderable) => {
    this.nodeRenderStore.setNode(node)
  }

  deleteNode = (key: string) => {
    this.nodeRenderStore.deleteNode(key)
  }

  setHaptic = (key: string, haptic: Renderable[]) => {
    this.hapticRenderStore.setHaptic(key, haptic)
  }

  deleteHaptic = (key: string) => {
    this.hapticRenderStore.deleteHaptic(key)
  }

  onCamera = (camera: Camera) => {
    this.context.setCamera(camera)
  }

  onCanvasSize = (size: Rectangle) => {
    const dpr = window.devicePixelRatio
    this.context.updateCanvasSize(size.w, size.h, dpr)
  }

  cleanup = () => {
    this.inited = false
    this.context.cleanup()
    this.nodeRenderStore.cleanup()
    this.hapticRenderStore.cleanup()
  }

  subscribeToCanvas = (key: string, listener: CanvasUpdateListener) => {
    this.canvasListeners[key] = listener
    if (this.inited) listener.onCanvasInit(true)
  }

  unsubscribeToCanvas = (key: string) => {
    delete this.canvasListeners[key]
  }

  subscribeToRenderLoop = (key: string, listener: CanvasRenderLoopListener) => {
    this.renderLoopListeners[key] = listener
  }

  unsubscribeToRenderLoop = (key: string) => {
    delete this.renderLoopListeners[key]
  }

  startRenderLoop = () => {
    const renderFrame = () => {
      if (!this.inited) return
      this.render()
      requestAnimationFrame(renderFrame)
    }
    renderFrame()
  }

  private render = () => {
    this.context.clearAllScenes()
    this.context.setDrawingTarget(0)

    this.renderGridlines()
    this.renderNode(this.selection.getSelectedCanvas(), 0)
    this.renderHaptics()

    this.context.drawToCanvas()
    this.notifyRenderLoopListeners()
  }

  private renderNode = (key: string, depth: number) => {
    const node = this.nodeRenderStore.getNode(key)
    if (!node) return

    let activeDepth = depth
    if (node.opacity !== undefined && node.opacity < 100) {
      activeDepth = depth + 1
      this.context.setDrawingTarget(activeDepth)
    }

    if (!node) return
    const renderables = node.renderables
    renderables.before.forEach((renderable) => renderable.draw(activeDepth))
    for (let i = node.children.length - 1; i >= 0; i--) {
      const child = node.children[i]
      this.renderNode(child, activeDepth)
    }
    renderables.after.forEach((renderable) => renderable.draw(activeDepth))

    if (activeDepth > depth) {
      this.context.transferScene(
        activeDepth,
        depth,
        node.opacity,
        node.renderBounds
      )
      this.context.clearScene(activeDepth)
    }
  }

  private renderHaptics = () => {
    this.hapticOrder.forEach((key) => {
      const haptic = this.hapticRenderStore.getHaptic(key)
      if (haptic) haptic.forEach((renderable) => renderable.draw())
    })
  }

  private renderGridlines = () => {
    const gridlines = this.hapticRenderStore.getHaptic(hapticGridlinesKey)
    if (gridlines) gridlines.forEach((renderable) => renderable.draw())
  }

  private notifyCanvasListeners = () => {
    for (const listener of Object.values(this.canvasListeners)) {
      listener.onCanvasInit(this.inited)
    }
  }

  private notifyRenderLoopListeners = () => {
    for (const listener of Object.values(this.renderLoopListeners)) {
      listener.onRender()
    }
  }
}
