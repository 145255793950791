import { QuadTreeRectangleMetadata } from 'application/quadtree'
import { Point, Delta, Proportion } from 'application/shapes'

export type ParentMap = { [key: string]: string | undefined }

export type DeltaMap = { [key: string]: Delta }

export type PositionMap = { [key: string]: Point }

export type OffsetMap = { [key: string]: Point }

export type SizeMap = { [key: string]: { w?: number; h?: number } }

export type ProportionMap = { [key: string]: Proportion }

export type AlignmentLine = {
  points: Point[]
  counter: number
  direction: 'h' | 'v'
}

export type AlignmentGap = {
  side: 'top' | 'bottom' | 'left' | 'right' | 'centerV' | 'centerH'
  bounds: [number, number]
  position: number
  lines: [Point, Point][]
  size: number
}

export const QuadTreeNodeFilter = {
  filter: (m: QuadTreeRectangleMetadata) => {
    return m.type === 'node'
  },
}

export const QuadTreeNodeTitleFilter = {
  filter: (m: QuadTreeRectangleMetadata) => {
    return m.type === 'title' || m.type === 'node'
  },
}
