import { RecoilRoot } from 'recoil'
import Router from './Router'
import { GlobalStyle } from 'GlobalStyles.styled'

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'dotlottie-player': any
    }
  }
}

function App() {
  return (
    <RecoilRoot>
      <GlobalStyle />
      <Router />
    </RecoilRoot>
  )
}

export default App
