import { Attributes, Tag } from 'application/export/html/types'
import { AttributePanel } from './attributePanel'
import { AttributeType } from 'application/attributes'
import { ReadOnlyNode } from 'application/node'

type HTMLAttributePanelStyleKeys = never
type HTMLAttributePanelBaseKeys = 'type' | 'html.attributes' | 'html.tag'

export type HTMLAttributePanelState = {
  tag: Tag | 'Mixed'
  type: AttributeType | 'Mixed'
  name: string | 'Mixed'
  attributes: Partial<Attributes> | 'Mixed'
}

export type HTMLAttributePanelHandlers = {
  setTag: (tag: Tag) => void
  setAttribute: (key: keyof Attributes, value: string) => void
}

export class HTMLAttributePanel extends AttributePanel<
  HTMLAttributePanelState,
  HTMLAttributePanelHandlers,
  HTMLAttributePanelStyleKeys,
  HTMLAttributePanelBaseKeys
> {
  getSettings = (): HTMLAttributePanelState => {
    return {
      tag: this.getTag(),
      name: this.getName(),
      type: this.baseAttributes?.type ?? 'Mixed',
      attributes: this.baseAttributes?.['html.attributes'] ?? {},
    }
  }

  getHandlers(): HTMLAttributePanelHandlers {
    return {
      setTag: this.setTag,
      setAttribute: this.setAttribute,
    }
  }

  private getName = (): string | 'Mixed' => {
    const nodes = this.getNodes()
    if (nodes.length === 0) return 'Mixed'
    return nodes[0].getBaseAttribute('name') ?? 'Mixed'
  }

  private getTag = (): Tag | 'Mixed' => {
    const nodes = this.getNodes()
    if (nodes.length === 0) return 'Mixed'

    let tag: Tag | undefined = undefined
    for (const node of nodes) {
      const nodeTag = this.getNodeTag(node)
      if (!tag && nodeTag) tag = nodeTag
      else if (tag && nodeTag && tag !== nodeTag) return 'Mixed'
    }
    return tag ?? 'Mixed'
  }

  private setTag = (tag: Tag) => {
    const nodes = this.getNodes()
    for (const node of nodes) {
      this.setOne(node.getId(), { 'html.tag': tag }, {})
    }
  }

  private setAttribute = (key: keyof Attributes, value: string) => {
    const nodes = this.getNodes()
    for (const node of nodes) {
      const attributes = node.getBaseAttribute('html.attributes')
      if (attributes) {
        this.setOne(
          node.getId(),
          { 'html.attributes': { ...attributes, [key]: value } },
          {}
        )
      } else {
        this.setOne(node.getId(), { 'html.attributes': { [key]: value } }, {})
      }
    }
  }

  private getNodeTag = (node: ReadOnlyNode): Tag | undefined => {
    const type = node.getBaseAttribute('type')
    const tag = node.getBaseAttribute('html.tag')
    switch (type) {
      case 'paragraph':
        return tag ?? 'p'
      default:
        return tag
    }
  }
}
