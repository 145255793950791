import Dropdown, {
  dropdownOption,
} from 'components/Library/Components/Dropdown/Dropdown'
import TextBlock from 'components/Library/Components/Text/TextBlock/TextBlock'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import { AttributeProps } from '../AttributeSettings'
import TextInputBlock from 'components/Library/Components/Text/TextInputBlock/TextInputBlock'

export default function ButtonAttributes({
  settings,
  handlers,
}: AttributeProps) {
  if (!settings.attributes || settings.attributes === 'Mixed') return <></>

  return (
    <>
      <PanelRow>
        <TextBlock mode={'label2'}>Type</TextBlock>
        <Dropdown
          options={typeOptions}
          selected={settings.attributes['type'] || 'button'}
          select={(v) => handlers.setAttribute('type', v)}
          commit={true}
        />
      </PanelRow>
      <PanelRow>
        <TextBlock mode={'label2'}>Name</TextBlock>
        <TextInputBlock
          width={96}
          value={settings.attributes['name'] || ''}
          setValue={(v) => handlers.setAttribute('name', v)}
          placeholder={settings.name}
          commit={true}
        />
      </PanelRow>
    </>
  )
}

const typeOptions: dropdownOption<string>[] = [
  { text: 'Button', value: 'button' },
  { text: 'Submit', value: 'submit' },
  { text: 'Reset', value: 'reset' },
]
