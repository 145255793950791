import { Properties } from 'application/export/css'
import { TailwindClassRenderer } from '../types'

export class TailwindClassOverflow implements TailwindClassRenderer {
  render = (properties: Partial<Properties>): string[] => {
    const classes: string[] = []

    const overflow = properties.overflow
    switch (overflow) {
      case 'visible':
        break
      case 'hidden':
        classes.push('overflow-hidden')
        break
      case 'scroll':
        classes.push('overflow-scroll')
        break
      case 'auto':
        classes.push('overflow-auto')
        break
    }

    return classes
  }
}
