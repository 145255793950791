import {
  Context,
  UniformValue,
  VaoData,
  VertexBuffer,
  calcBoundingBox,
  calcRounding,
  isPointInBox,
  isPointInEllipse,
  rectToQuadTriangles,
} from 'application/render'
import { Rectangle } from 'application/shapes'
import { Color, rgbaToWebgl } from 'application/color'
import {
  cleanupVaoData,
  clearBuffers,
  connectBuffersToVao,
  createEmptyVaoData,
  standardDraw,
} from '../utils'
import { MaterialType } from './shaderMap'

export type WebglEllipseBorderData = Rectangle & {
  color: Color
  width: number
  bb?: Rectangle
  bbRounding?: { tl: number; tr: number; br: number; bl: number }
}

export class WebglEllipseBorder {
  private context: Context
  private data: WebglEllipseBorderData
  private vaoData: VaoData

  constructor(context: Context, data: WebglEllipseBorderData) {
    this.context = context
    this.data = data
    this.vaoData = createEmptyVaoData()
  }

  init = (): void => {
    this.vaoData = this.updateRenderData()
  }

  draw(): void {
    standardDraw(this.vaoData)
  }

  updateRenderData(): VaoData {
    clearBuffers(this.vaoData)
    const gl = this.context.getGl()
    const material = this.context.getMaterial(MaterialType.ellipseBorder)
    const vao = gl.createVertexArray()
    const buffers: { [key: string]: VertexBuffer } = {}
    const uniforms: { [key: string]: UniformValue } = {}

    const { x, y, w, h, color, width, bb, bbRounding } = this.data

    const aPosition = new Float32Array(rectToQuadTriangles(this.data))
    const uColor = rgbaToWebgl(color)
    const uWidth = width
    const uRect = [x, y, w, h]
    const uBB = calcBoundingBox(bb)
    const uBBRounding = calcRounding(uBB[2], uBB[3], bbRounding)

    buffers['aPosition'] = this.context.createVertexBuffer(aPosition, 2)
    uniforms['uColor'] = uColor
    uniforms['uWidth'] = uWidth
    uniforms['uRect'] = uRect
    uniforms['uBB'] = uBB
    uniforms['uBBRounding'] = uBBRounding
    const verticeCount = aPosition.length / 2

    const vaoData = { vao, material, buffers, uniforms, verticeCount }
    connectBuffersToVao(vaoData)
    return vaoData
  }

  cleanup = (): void => {
    cleanupVaoData(this.vaoData)
  }
}

export const ellipseBorderVs = `#version 300 es
precision highp float;

uniform mat3 uMatrix;

in vec2 aPosition;

out vec2 vPosition;

void main() {
  vec3 transformedPosition = uMatrix * vec3(aPosition, 1);
  gl_Position = vec4(transformedPosition.xy, 0, 1);

  vPosition = aPosition;
}
`

export const ellipseBorderFs = `#version 300 es
precision mediump float;

uniform vec4 uColor;
uniform float uWidth;
uniform vec4 uRect;
uniform vec4 uBB;
uniform vec4 uBBRounding;

in vec2 vPosition;

out vec4 outColor;

${isPointInBox}
${isPointInEllipse}

void main() {
  if (!isPointInBox(vPosition, uBB, uBBRounding)) {
    discard;
  }

  vec4 innerRect = vec4(
    uRect.x + uWidth,
    uRect.y + uWidth,
    uRect.z - 2.0 * uWidth,
    uRect.w - 2.0 * uWidth
  );

  if (isPointInEllipse(vPosition, innerRect) || !isPointInEllipse(vPosition, uRect)) {
    discard;
  }

  outColor = uColor;
}
`
