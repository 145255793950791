import { DesignColor } from 'themes'

interface OnboardingTitleProps {
  children: React.ReactNode
}

export default function OnboardingTitle({ children }: OnboardingTitleProps) {
  return (
    <h1
      style={{
        fontSize: 24,
        color: DesignColor('text1'),
        fontFamily: 'inter',
        fontWeight: 'bold',
      }}
    >
      {children}
    </h1>
  )
}
