import { DesignColor } from 'themes'
import useMemory from 'hooks/admin/useMemory'
import styled from 'styled-components'

const { REACT_APP_DEV_MODE } = process.env

const DEV_MODE = REACT_APP_DEV_MODE === 'true'

export default function MemoryDisplay() {
  const memory = useMemory(1)
  if (!memory || !DEV_MODE) return null

  return <MemoryLabel>Memory: {memory} MB</MemoryLabel>
}

const MemoryLabel = styled.p`
  width: 124px;

  color: ${DesignColor('text2')};
  font-size: 10px;
`
