import { Context } from 'application/render/gpu/context'
import { WebglTextData } from './webglText'
import { TextRenderBox, VaoData } from 'application/render/types'
import { FontLoaderInterface } from 'application/text'
import { MaterialType } from '../shaderMap'
import { VertexBuffer } from 'application/render/gpu/buffer'
import { UniformValue } from 'application/render/gpu/uniform'
import { isPointInBox } from 'application/render/shaderUtils'
import {
  calcBoundingBox,
  calcGlyphMsdfCoords,
  calcGlyphPositions,
  calcGradientColors,
  calcGradientQuads,
  calcRounding,
  calcTextGradientBoxRect,
} from 'application/render/data'
import { connectBuffersToVao, standardDraw } from 'application/render/utils'
import { glClearColors } from 'application/color'

export type TextGradienBoxVaoData = {
  fill: VaoData
  glyphs: VaoData
}

export class WebglTextGradientBox {
  static draw(context: Context, vaoData: TextGradienBoxVaoData, depth: number) {
    const { fill, glyphs } = vaoData
    if (!fill || !fill.material || !glyphs || !glyphs.src || !glyphs.material)
      return

    const msdfTexture = context.getTexture(glyphs.src)
    if (!msdfTexture) {
      context.createImageTexture(glyphs.src)
      return
    }

    context.setDrawingTarget('temp1')
    context.clear(glClearColors.transparent)
    standardDraw(vaoData.fill)

    context.setDrawingTarget(depth)
    const fillTexture = context.getSceneTexture('temp1')
    glyphs.material.setSampler('uFillSampler', fillTexture.getTexture(), 0)
    glyphs.material.setSampler('uMsdfSampler', msdfTexture.getTexture(), 1)
    standardDraw(vaoData.glyphs)

    context.setClearColor(glClearColors.default)
  }

  static createVaoData(
    context: Context,
    data: WebglTextData,
    box: TextRenderBox,
    boxes: TextRenderBox[],
    fontLoader: FontLoaderInterface
  ): TextGradienBoxVaoData {
    const fill = this.createFillVaoData(context, data, box, boxes)
    const glyphs = this.createGlyphVaoData(context, data, box, fontLoader)
    return { fill, glyphs }
  }

  private static createFillVaoData(
    context: Context,
    data: WebglTextData,
    box: TextRenderBox,
    boxes: TextRenderBox[]
  ): VaoData {
    const gl = context.getGl()
    const material = context.getMaterial(MaterialType.textGradientFill)
    const vao = gl.createVertexArray()
    const buffers: { [key: string]: VertexBuffer } = {}
    const uniforms: { [key: string]: UniformValue } = {}

    const { fill } = box
    if (fill.type !== 'gradient' || !fill.gradient)
      throw new Error('Invalid text gradient fill')

    const aPosition = new Float32Array(
      calcGradientQuads(calcTextGradientBoxRect(box, data, boxes), box.fill)
    )
    const aColor = new Float32Array(calcGradientColors(fill.gradient))

    buffers['aPosition'] = context.createVertexBuffer(aPosition, 2)
    buffers['aColor'] = context.createVertexBuffer(aColor, 4)
    const verticeCount = aPosition.length / 2

    const vaoData = { vao, material, buffers, uniforms, verticeCount }
    connectBuffersToVao(vaoData)
    return vaoData
  }

  private static createGlyphVaoData(
    context: Context,
    data: WebglTextData,
    box: TextRenderBox,
    fontLoader: FontLoaderInterface
  ): VaoData {
    const gl = context.getGl()
    const material = context.getMaterial(MaterialType.textGradientGlyphs)
    const vao = gl.createVertexArray()
    const buffers: { [key: string]: VertexBuffer } = {}
    const uniforms: { [key: string]: UniformValue } = {}

    const aPosition = new Float32Array(
      calcGlyphPositions(box, data, fontLoader)
    )
    const aMsdfCoords = new Float32Array(
      calcGlyphMsdfCoords(box, data, fontLoader)
    )
    const uBB = calcBoundingBox(data.bb)
    const uBBRounding = calcRounding(uBB[2], uBB[3], data.bbRounding)
    const src = fontLoader.getMsdfSrc(box.fontFamily, box.fontWeight)

    buffers['aPosition'] = context.createVertexBuffer(aPosition, 2)
    buffers['aMsdfCoords'] = context.createVertexBuffer(aMsdfCoords, 2)
    uniforms['uBB'] = uBB
    uniforms['uBBRounding'] = uBBRounding
    const verticeCount = aPosition.length / 2

    const vaoData = { material, vao, buffers, uniforms, verticeCount, src }
    connectBuffersToVao(vaoData)
    return vaoData
  }

  static async preload(context: Context, vaoData: VaoData): Promise<void> {
    if (!vaoData.src) return
    await context.createImageTexture(vaoData.src)
  }
}

export const textGradientFillVs = `#version 300 es
precision highp float;

uniform mat3 uMatrix;

in vec2 aPosition;
in vec4 aColor;

out vec2 vPosition;
out vec4 vColor;

void main() {
  vec3 transformedPosition = uMatrix * vec3(aPosition, 1);
  gl_Position = vec4(transformedPosition.xy, 0, 1);

  vPosition = aPosition;
  vColor = aColor;
}
`

export const textGradientFillFs = `#version 300 es
precision mediump float;

in vec2 vPosition;
in vec4 vColor;

out vec4 outColor;

void main() {
  outColor = vColor;
}
`

export const textGradientGlyphsVs = `#version 300 es
precision highp float;

uniform mat3 uMatrix;

in vec2 aPosition;
in vec2 aMsdfCoords;

out vec2 vPosition;
out vec2 vFillTexCoords;
out vec2 vMsdfTexCoords;

void main() {
  vec2 pos = aPosition;
  vec3 transformedPosition = uMatrix * vec3(pos, 1);
  gl_Position = vec4(transformedPosition.xy, 0, 1);

  vPosition = aPosition;
  vMsdfTexCoords = aMsdfCoords;
  vFillTexCoords = gl_Position.xy * 0.5 + 0.5;
}
`

export const textGradientGlyphsFs = `#version 300 es
precision highp float;

uniform sampler2D uMsdfSampler;
uniform sampler2D uFillSampler;
uniform vec4 uBB;
uniform vec4 uBBRounding;

in vec2 vPosition;
in vec2 vMsdfTexCoords;
in vec2 vFillTexCoords;

out vec4 outColor;

${isPointInBox}

float median(float r, float g, float b) {
  return max(min(r, g), min(max(r, g), b));
}

void main() {
  if (!isPointInBox(vPosition, uBB, uBBRounding)) {
    discard;
  }

  vec3 texColor = texture(uMsdfSampler, vMsdfTexCoords).rgb;
  
  float dist = median(texColor.r, texColor.g, texColor.b) - 0.5;
  float alpha = clamp(dist / fwidth(dist) + 0.5, 0.0, 1.0);
  
  vec4 color = texture(uFillSampler, vFillTexCoords);
  outColor = vec4(color.rgb, color.a * alpha);
}
`
