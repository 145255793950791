import { ReadOnlyNode } from 'application/node'
import { SelectionUpdateListener } from './types'

export class SelectionListeners {
  private listeners: SelectionUpdateListener[]

  constructor() {
    this.listeners = []
  }

  registerListener = (listener: SelectionUpdateListener): void => {
    this.listeners.push(listener)
  }

  onChange = (initial: ReadOnlyNode[], current: ReadOnlyNode[]): void => {
    for (const listener of this.listeners) {
      if (listener.onChange) {
        listener.onChange(initial, current)
      }
    }
  }

  onCanvasChange = (initial: string, current: string): void => {
    for (const listener of this.listeners) {
      if (listener.onCanvasChange) {
        listener.onCanvasChange(initial, current)
      }
    }
  }
}
