import { UnsplashImage } from 'application/service'
import UnsplashSection from 'components/Library/Components/Unsplash/UnsplashSection'
import useBackendService from 'hooks/services/useBackendService'
import { useCallback, useEffect, useRef, useState } from 'react'

interface UnsplashSearchProps {
  searchTerm: string
  handleSelectImage: (image: UnsplashImage) => void
}

export default function UnsplashSearch({
  searchTerm,
  handleSelectImage,
}: UnsplashSearchProps) {
  const backendService = useBackendService()

  const sectionRef = useRef<HTMLDivElement | null>(null)
  const [column1, setColumn1] = useState<UnsplashImage[]>([])
  const [column2, setColumn2] = useState<UnsplashImage[]>([])
  const [page, setPage] = useState(1)
  const [maxPages, setMaxPages] = useState(1)
  const [fetching, setFetching] = useState(false)

  const populated = column1.length > 0 || column2.length > 0

  const onSearch = useCallback(async () => {
    const result = await backendService.getUnsplashImages(searchTerm, page)
    const split = splitImages(result.results)
    setColumn1(split.column1)
    setColumn2(split.column2)
    setPage(1)
    setMaxPages(result.total_pages)
  }, [backendService, searchTerm, page])

  const handleScrollToBottom = useCallback(async () => {
    if (page >= maxPages) return
    const result = await backendService.getUnsplashImages(searchTerm, page + 1)
    const split = splitImages(result.results)
    if (page % 2 === 0) {
      setColumn1([...column1, ...split.column1])
      setColumn2([...column2, ...split.column2])
    } else {
      setColumn1([...column1, ...split.column2])
      setColumn2([...column2, ...split.column1])
    }
    setPage(page + 1)
    setFetching(false)
  }, [page, maxPages, backendService, searchTerm, column1, column2])

  const onScroll = useCallback(async () => {
    const scrollContainer = sectionRef.current
    if (!scrollContainer) return
    const scrollBottom =
      scrollContainer.scrollHeight -
      scrollContainer.scrollTop -
      scrollContainer.clientHeight
    if (scrollBottom < 400 && !fetching) {
      setFetching(true)
      await handleScrollToBottom()
    }
  }, [fetching, handleScrollToBottom])

  useEffect(() => {
    onSearch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm])

  if (!populated) return <></>

  return (
    <UnsplashSection
      sectionRef={sectionRef}
      onScroll={onScroll}
      column1={column1}
      column2={column2}
      handleSelectImage={handleSelectImage}
    />
  )
}

function splitImages(images: UnsplashImage[]): {
  column1: UnsplashImage[]
  column2: UnsplashImage[]
} {
  let column1Height = 0
  let column2Height = 0
  let column1: UnsplashImage[] = []
  let column2: UnsplashImage[] = []

  images
    .filter((i) => !aboveMaxRenderableSize(i))
    .forEach((image) => {
      const ratio = image.height / image.width
      const height = 200 * ratio
      if (column1Height <= column2Height) {
        column1.push(image)
        column1Height += height
      } else {
        column2.push(image)
        column2Height += height
      }
    })

  return { column1, column2 }
}

function aboveMaxRenderableSize(size: { width: number; height: number }) {
  return size.width > 8192 || size.height > 8192
}
