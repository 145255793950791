import { Node } from 'application/node'
import { PasteSnapshot } from '../../types'
import { BaseMap, StyleMap } from 'application/attributes'
import _ from 'lodash'

export function moveTree(
  node: Node,
  pasteSnapshot: PasteSnapshot,
  x: number,
  y: number
): void {
  const children = node.getChildren()
  if (!children) return

  for (const childId of children) {
    const child = pasteSnapshot.nodes[childId]
    if (!child) continue

    child.setBaseAttribute('x', child.getBaseAttribute('x') - x)
    child.setBaseAttribute('y', child.getBaseAttribute('y') - y)

    moveTree(child, pasteSnapshot, x, y)
  }
}

export function setAttributes(
  node: Node,
  style: Partial<StyleMap>,
  baseMap: Partial<BaseMap> = {}
): void {
  for (const [key, value] of Object.entries(style)) {
    const current = node.getStyleAttribute(key as keyof StyleMap)
    if (_.isEqual(value, current)) continue
    node.setStyleAttribute(key as keyof StyleMap, value)
  }
  for (const [key, value] of Object.entries(baseMap)) {
    const current = node.getBaseAttribute(key as keyof BaseMap)
    if (_.isEqual(value, current)) continue
    node.setBaseAttribute(key as keyof BaseMap, value)
  }
}
