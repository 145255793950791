import { TextContent } from 'application/text'
import { Size } from '../types/size'
import { HtmlTag } from '../types/types'
import { CssStyles } from '../css/css'

export class Element {
  readonly tag: HtmlTag
  readonly css: CssStyles
  parent: Element | undefined
  children: Element[]
  contentSize: Size
  sizedByContent: boolean
  textContent: TextContent | undefined

  protected constructor(tag: HtmlTag) {
    this.tag = tag
    this.css = CssStyles.create()
    this.parent = undefined
    this.children = []
    this.contentSize = Size.create()
    this.sizedByContent = false
    this.textContent = undefined
  }
}
