import { AddFlexAction } from 'editor/action/node/flex'
import { UINodeActions } from './types'
import { CommandHandler } from 'application/client'
import { GroupAction } from 'editor/action/node/group'
import { RearrangeAction } from 'editor/action/node/rearrange'
import { PasteAction } from 'editor/action/paste/action'

export class NodeActions implements UINodeActions {
  private commandHandler: CommandHandler
  private flexAction: AddFlexAction
  private groupAction: GroupAction
  private rearrangeAction: RearrangeAction
  private pasteAction: PasteAction

  constructor(
    commandHandler: CommandHandler,
    flexAction: AddFlexAction,
    groupAction: GroupAction,
    rearrangeAction: RearrangeAction,
    pasteAction: PasteAction
  ) {
    this.commandHandler = commandHandler
    this.flexAction = flexAction
    this.groupAction = groupAction
    this.rearrangeAction = rearrangeAction
    this.pasteAction = pasteAction
  }

  copy = (): void => {
    this.commandHandler.handle({
      type: 'copySelectedNodes',
      params: { clipboard: false },
    })
  }

  copyAttributes = (): void => {
    this.commandHandler.handle({ type: 'copySelectedNodeAttributes' })
  }

  cut = (): void => {
    this.commandHandler.handle({ type: 'cutSelectedNodes' })
    this.commandHandler.handle({ type: 'commit' })
  }

  paste = async (): Promise<void> => {
    await this.pasteAction.pasteFromClipboard()
  }

  pasteAttributes = (): void => {
    this.commandHandler.handle({ type: 'pasteNodeAttributes' })
    this.commandHandler.handle({ type: 'commit' })
  }

  duplicate = (): void => {
    this.commandHandler.handle({
      type: 'duplicateSelectedNodes',
      params: { addOffset: true },
    })
    this.commandHandler.handle({ type: 'commit' })
  }

  replace = (): void => {
    this.commandHandler.handle({ type: 'replaceSelectedNodes' })
    this.commandHandler.handle({ type: 'commit' })
  }

  group = (): void => {
    this.groupAction.group()
  }

  ungroup = (): void => {
    this.groupAction.ungroup()
  }

  undo = (): void => {
    this.commandHandler.handle({ type: 'undo' })
  }

  redo = (): void => {
    this.commandHandler.handle({ type: 'redo' })
  }

  flex = (): void => {
    this.flexAction.addFlex()
    this.commandHandler.handle({ type: 'commit' })
  }

  moveToFront = (): void => {
    this.rearrangeAction.moveToFront()
  }

  moveToBack = (): void => {
    this.rearrangeAction.moveToBack()
  }
}
