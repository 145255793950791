import { LayoutItem } from '../layoutItem/layoutItem'
import { PositionContext } from '../context/positionContext'
import { CssFlexAlign, CssFlexDirection } from '../types/types'

export abstract class FlexPositionItem {
  layoutItem: LayoutItem

  mainSize: number
  crossSize: number

  alignSelf: CssFlexAlign
  flexDirection: CssFlexDirection

  constructor(layoutItem: LayoutItem) {
    this.layoutItem = layoutItem
    this.mainSize = 0
    this.crossSize = 0

    this.alignSelf = CssFlexAlign.auto
    this.flexDirection = CssFlexDirection.row
  }

  // Initialize the item
  // - baseSize
  // - mainSize
  // - alignSelf
  abstract initialize(direction: CssFlexDirection, align: CssFlexAlign): void

  // Place the item in the layout
  abstract place(context: PositionContext, crossSize: number): void
}
