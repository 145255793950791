import { ReadOnlyNode } from 'application/node'
import { ClientTransactionHandler } from './transaction'

export class SelectionTransactionHistoryHandler {
  private handler: ClientTransactionHandler

  constructor(handler: ClientTransactionHandler) {
    this.handler = handler
  }

  onChange = (initial: ReadOnlyNode[], current: ReadOnlyNode[]): void => {
    this.handler.addEvent({
      type: 'selection',
      data: {
        initial: initial.map((node) => node.getId()),
        current: current.map((node) => node.getId()),
      },
    })
  }

  onCanvasChange = (initial: string, current: string): void => {
    this.handler.addEvent({
      type: 'selected_canvas',
      data: {
        initial: initial,
        current: current,
      },
    })
  }
}
