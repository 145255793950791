import { isCanvasClosest } from 'application/browser'
import {
  Action,
  ActionEventResult,
  ActionHandler,
  DonePassthrough,
  NotDone,
} from '../types'

export class EditInputAction implements ActionHandler {
  getType = (): Action => {
    return 'editInput'
  }

  onMouseDown = (e: MouseEvent): ActionEventResult => {
    if (!isCanvasClosest(e)) return NotDone
    this.blurActiveElement()
    return DonePassthrough
  }

  onKeyDown = (): ActionEventResult => {
    return NotDone
  }

  onKeyUp = (): ActionEventResult => {
    return NotDone
  }

  cancel = (): void => {
    this.blurActiveElement()
  }

  private blurActiveElement = (): void => {
    const activeElement = document.activeElement as HTMLElement
    if (activeElement) activeElement.blur()
  }
}
