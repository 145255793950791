import { ActionHandler } from '../types'
import { CommandHandler } from 'application/client'
import { NodeCreateAction, NodeSelectionAction } from 'application/action'
import { CoordinatesConversion } from 'application/camera'
import { DrawAction } from './action'
import { AttributeType } from 'application/attributes'
import { HapticDrawingWindow } from 'editor/haptic/drawingWindow'
import { HapticChildLine } from 'editor/haptic/childLine'
import { HapticNewParentWindow } from 'editor/haptic/newParentWindow'
import { ReadOnlyDocument } from 'application/document'
import { ChildLineComputer } from '../childLine/computer'
import { QuadTree } from 'application/quadtree'
import { Point } from 'application/shapes'

export class DrawActionFactory {
  private commandHandler: CommandHandler
  private document: ReadOnlyDocument
  private createAction: NodeCreateAction
  private select: NodeSelectionAction
  private quadtree: QuadTree
  private hapticWindow: HapticDrawingWindow
  private hapticChildLine: HapticChildLine
  private hapticNewParent: HapticNewParentWindow
  private lineComputer: ChildLineComputer
  private coordinates: CoordinatesConversion

  constructor(
    commandHandler: CommandHandler,
    document: ReadOnlyDocument,
    createAction: NodeCreateAction,
    select: NodeSelectionAction,
    quadtree: QuadTree,
    hapticWindow: HapticDrawingWindow,
    hapticChildLine: HapticChildLine,
    hapticNewParent: HapticNewParentWindow,
    lineComputer: ChildLineComputer,
    coordinates: CoordinatesConversion
  ) {
    this.commandHandler = commandHandler
    this.document = document
    this.createAction = createAction
    this.select = select
    this.quadtree = quadtree
    this.hapticWindow = hapticWindow
    this.hapticChildLine = hapticChildLine
    this.hapticNewParent = hapticNewParent
    this.lineComputer = lineComputer
    this.coordinates = coordinates
  }

  create = (
    type: AttributeType,
    hotkey: boolean = false,
    point: Point | null = null
  ): ActionHandler => {
    return new DrawAction(
      this.commandHandler,
      this.document,
      this.createAction,
      this.select,
      this.coordinates,
      this.quadtree,
      this.hapticWindow,
      this.hapticChildLine,
      this.hapticNewParent,
      this.lineComputer,
      type,
      hotkey,
      point
    )
  }
}
