import {
  AttributeType,
  BaseMap,
  PLACEHOLDER_IMAGE,
  StyleMap,
} from 'application/attributes'
import { Node } from './node'
import { NewNodeSettings, NodeUpdateListener } from './types'
import { ProjectDocumentNode } from 'application/service'

const nodesWithChildren: AttributeType[] = [
  'root',
  'canvas',
  'page',
  'frame',
  'form',
  'anchor',
  'paragraph',
  'button',
]

export class NodeFactory {
  private listener: NodeUpdateListener

  constructor(listener: NodeUpdateListener) {
    this.listener = listener
  }

  createNode(settings: NewNodeSettings): Node | null {
    if (!settings.base.type) return null
    const hasChildren = nodesWithChildren.includes(settings.base.type)

    return new Node(
      settings.id,
      undefined,
      hasChildren ? [] : undefined,
      {
        ...this.getDefaultBase(settings.base.type),
        ...this.addContent(settings.base.type),
        ...this.addImageSrc(settings.base.type),
        ...settings.base,
      },
      {
        name: 'default',
        styles: {
          ...this.addPadding(settings.base.type),
          ...this.addBorder(settings.base.type),
          ...this.addRadius(settings.base.type),
          ...settings.style,
        },
      },
      [],
      'default',
      'none',
      this.listener
    )
  }

  createLoadedNode(node: ProjectDocumentNode): Node {
    return new Node(
      node.id,
      node.parent,
      node.children,
      node.baseAttributes,
      node.defaultSelector,
      node.selectors,
      node.activeBreakpoint,
      node.activePseudo,
      this.listener
    )
  }

  private getDefaultBase(type: AttributeType): BaseMap {
    return {
      type: type,
      name: 'Node',
      nameOverridden: false,
      x: 0,
      y: 0,
      w: 0,
      h: 0,
      'boundingBox.h': 0,
      'boundingBox.w': 0,
      'boundingBox.x': 0,
      'boundingBox.y': 0,
    }
  }

  private addContent(type: AttributeType): Partial<BaseMap> {
    switch (type) {
      case 'content':
      case 'paragraph':
        return { 'text.content': 'Text' }
      default:
        return {}
    }
  }

  private addImageSrc(type: AttributeType): Partial<BaseMap> {
    switch (type) {
      case 'image':
        return {
          'image.src': PLACEHOLDER_IMAGE,
          'image.originalSize.w': 800,
          'image.originalSize.h': 800,
        }
      default:
        return {}
    }
  }

  private addPadding(type: AttributeType): Partial<StyleMap> {
    switch (type) {
      case 'input':
      case 'button':
        return {
          'padding.top.unit': 'px',
          'padding.right.unit': 'px',
          'padding.bottom.unit': 'px',
          'padding.left.unit': 'px',
          'padding.top.px': 4,
          'padding.bottom.px': 4,
          'padding.left.px': 8,
          'padding.right.px': 8,
        }
      default:
        return {}
    }
  }

  private addBorder(type: AttributeType): Partial<StyleMap> {
    switch (type) {
      case 'input':
      case 'button':
        return {
          'border.side': 'all',
          'border.top.unit': 'px',
          'border.right.unit': 'px',
          'border.bottom.unit': 'px',
          'border.left.unit': 'px',
          'border.top.px': 1,
          'border.right.px': 1,
          'border.bottom.px': 1,
          'border.left.px': 1,
          'border.color': { r: 0, g: 0, b: 0, a: 1 },
        }
      default:
        return {}
    }
  }

  private addRadius(type: AttributeType): Partial<StyleMap> {
    switch (type) {
      case 'input':
      case 'button':
        return {
          'border.radius.topLeft.unit': 'px',
          'border.radius.topRight.unit': 'px',
          'border.radius.bottomLeft.unit': 'px',
          'border.radius.bottomRight.unit': 'px',
          'border.radius.topLeft.px': 8,
          'border.radius.topRight.px': 8,
          'border.radius.bottomLeft.px': 8,
          'border.radius.bottomRight.px': 8,
        }
      default:
        return {}
    }
  }
}
