import { Portal } from 'components/Pages/Editor/PortalRoot/PortalRoot'
import IconBlock from 'components/Library/Components/IconBlock/IconBlock/IconBlock'
import TextBlock from 'components/Library/Components/Text/TextBlock/TextBlock'
import TextInputBlock from 'components/Library/Components/Text/TextInputBlock/TextInputBlock'
import PanelContainer from 'components/Library/Containers/PanelContainer/PanelContainer'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import { useState } from 'react'
import UnsplashSearch from './UnsplashSearch/UnsplashSearch'
import Text from 'components/Library/Text/Text'
import { DesignColor } from 'themes'
import { useRecoilValue } from 'recoil'
import { layersWidth } from '../../LeftSidepanel/LeftSidepanel'
import useActionInitiator from 'hooks/editor/useActionInitiator'
import useAction from 'hooks/editor/useAction'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'
import { Size } from 'application/shapes'
import { UnsplashImage } from 'application/service'

export default function ImageLibrary() {
  const { closeLibrary } = useActionInitiator()
  const action = useAction()

  const width = useRecoilValue(layersWidth)

  const { handlers } = usePanelHelper(editor.getUI().getImageLibraryPanel())
  const { setSrc } = handlers

  const [searchTerm, setSearchTerm] = useState('Mountain')

  if (action !== 'openImageLibrary') return <></>

  const handleSelectImage = (i: UnsplashImage) => {
    const src = i.urls.regular.replace(/w=\d+/, 'w=2560')
    const size = resizeImageToMax({ w: i.width, h: i.height })
    setSrc(src, size)
  }

  return (
    <Portal>
      <PanelContainer
        id={'image-library'}
        position={{
          position: 'absolute',
          top: 64,
          left: width + 16,
          zIndex: 2,
        }}
        width={400}
        height={696}
      >
        <PanelSection width={'fill'}>
          <PanelRow width={'fill'}>
            <TextBlock width={'fill'} mode="title">
              Graphics
            </TextBlock>
            <IconBlock icon="X" onClick={closeLibrary} />
          </PanelRow>
        </PanelSection>
        <PanelSection width={'fill'}>
          <PanelRow width={'fill'}>
            <TextInputBlock
              width={288}
              icon="Search"
              value={searchTerm}
              setValue={setSearchTerm}
              placeholder={'Search for images'}
              debounce={300}
            />
          </PanelRow>
        </PanelSection>
        <UnsplashSearch
          searchTerm={searchTerm}
          handleSelectImage={handleSelectImage}
        />
        <PanelRow width={400} paddingH={16}>
          <Text>
            Powered by{' '}
            <a
              style={{
                color: DesignColor('text1'),
                textDecoration: 'underline',
              }}
              href="https://unsplash.com/"
              target="_blank"
              rel="noreferrer"
            >
              Unsplash
            </a>
          </Text>
        </PanelRow>
      </PanelContainer>
    </Portal>
  )
}

export function resizeImageToMax(size: Size): Size {
  const maxWidth = 1080
  const maxHeight = 768
  const ratio = size.w / size.h

  if (size.w > maxWidth) {
    return { w: maxWidth, h: maxWidth / ratio }
  } else if (size.h > maxHeight) {
    return { w: maxHeight * ratio, h: maxHeight }
  }
  return size
}
