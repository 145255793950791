interface OnboardingInputWrapperProps {
  children?: React.ReactNode
}

export default function OnboardingInputWrapper({
  children,
}: OnboardingInputWrapperProps) {
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'start',
        justifyContent: 'center',
        gap: 16,
      }}
    >
      {children}
    </div>
  )
}
