import { Node, NodeMap } from 'application/node'
import { NodeIdMap } from '../types'

export class ReferenceUpdater {
  static update = (snapshot: NodeMap, idMap: NodeIdMap): void => {
    for (const node of Object.values(snapshot)) {
      this.updateChildren(node, idMap)
      this.updateParent(node, idMap)
    }
  }

  private static updateChildren = (node: Node, idMap: NodeIdMap): void => {
    const children = node.getChildren()
    if (!children) return

    const updatedChildren = children.map((child) => {
      const updated = idMap[child]
      return updated || child
    })
    node.setChildren(updatedChildren)
  }

  private static updateParent = (node: Node, idMap: NodeIdMap): void => {
    const parentId = node.getParent()
    if (!parentId || idMap[parentId] === undefined) return
    node.setParent(idMap[parentId])
  }
}
