import { DocumentUpdateListener } from 'application/document/types'
import { Node, NodeUpdateListener } from 'application/node'
import { HistoryActionListener } from 'application/history'
import { RulesEngine } from 'application/rules/engine'
import { WriteDocument } from 'application/document'
import { LayoutEngineListener } from 'application/layoutEngine'

export class ClientRulesEngine
  implements
    NodeUpdateListener,
    DocumentUpdateListener,
    HistoryActionListener,
    LayoutEngineListener
{
  private document: WriteDocument
  private rulesEngine: RulesEngine
  private historyActionInProgress = false
  private layoutEngineInProgress = false
  private runningRules = false

  constructor(document: WriteDocument, rulesEngine: RulesEngine) {
    this.document = document
    this.rulesEngine = rulesEngine
    this.historyActionInProgress = false
    this.layoutEngineInProgress = false
  }

  onBaseAttribute = (id: string): void => {
    if (this.disallowedActionInProgress()) return
    this.startRules()
    this.rulesEngine.fixViolations(id, this.document)
    this.endRules()
  }

  onStyleAttribute = (id: string): void => {
    if (this.disallowedActionInProgress()) return
    this.startRules()
    this.rulesEngine.fixViolations(id, this.document)
    this.endRules()
  }

  onParent = (id: string) => {
    if (this.disallowedActionInProgress()) return
    this.startRules()
    this.rulesEngine.fixViolations(id, this.document)
    this.endRules()
  }

  onNodeAdded = (node: Node): void => {
    if (this.disallowedActionInProgress()) return
    this.startRules()
    this.rulesEngine.fixViolations(node.getId(), this.document)
    this.endRules()
  }

  onStartUndo = (): void => {
    this.historyActionInProgress = true
  }

  onEndUndo = (): void => {
    this.historyActionInProgress = false
  }

  onStartRedo = (): void => {
    this.historyActionInProgress = true
  }

  onEndRedo = (): void => {
    this.historyActionInProgress = false
  }

  onStartLayout = (): void => {
    this.layoutEngineInProgress = true
  }

  onFinishLayout = (): void => {
    this.layoutEngineInProgress = false
  }

  private startRules = (): void => {
    this.runningRules = true
  }

  private endRules = (): void => {
    this.runningRules = false
  }

  private disallowedActionInProgress = (): boolean => {
    return (
      this.historyActionInProgress ||
      this.layoutEngineInProgress ||
      this.runningRules
    )
  }
}
