import { Action } from 'editor'
import { editor } from 'index'
import { useEffect, useState } from 'react'
import { v4 } from 'uuid'

export default function useAction() {
  const ui = editor.getUI()
  const [action, setAction] = useState<Action | null>(null)

  useEffect(() => {
    const key = v4().replace(/-/g, '').slice(0, 12)
    ui.subscribeToActions(key, {
      onAction: (a) => setAction(a),
    })
    return () => {
      ui.unsubscribeFromActions(key)
    }
  }, [ui])

  return action
}
