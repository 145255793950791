import { ReadOnlyNode } from 'application/node'
import { HTMLDocument } from '../html/types'
import { createSelector } from '../utils'
import { AttributeTransformer } from './types'

export class FlexChildStyle implements AttributeTransformer {
  transformBase = (node: ReadOnlyNode, htmlDocument: HTMLDocument): void => {
    const selector = createSelector(node.getId(), 'default')
    const style = htmlDocument.getStyles().get(selector)

    const styles = node.getDefaultSelector().styles

    const grow = styles['flex.grow']
    if (grow === undefined) return

    const shrink = styles['flex.shrink']
    if (shrink === undefined) return

    const basisUnit = styles['flex.basis.unit']
    switch (basisUnit) {
      case 'px':
        const px = styles['flex.basis.px']
        if (px === undefined) break
        style.setProperty('flex', [grow, shrink, { value: px, unit: 'px' }])
        break
      case undefined:
        if (grow === 0 && shrink === 0) {
          style.setProperty('flex', 'none')
        } else {
          style.setProperty('flex', [grow, shrink, 'auto'])
        }
        break
    }

    const alignSelf = styles['flex.alignSelf']
    switch (alignSelf) {
      case 'stretch':
        style.setProperty('alignSelf', 'stretch')
        break
    }
  }
}
