export function isTargetClosest(e: MouseEvent, targetId: string) {
  const target = e.target as HTMLElement
  if (!target || !target.closest) return false
  return target.closest(`#${targetId}`) !== null
}

export function isCanvasClosest(e: MouseEvent): boolean {
  return isTargetClosest(e, 'webgl-canvas')
}

export function getClosestByClass(
  e: MouseEvent,
  targetClass: string
): HTMLElement | null {
  const target = e.target as HTMLElement
  if (!target) return null
  return target.closest(`.${targetClass}`)
}

export function getById(targetId: string): HTMLElement | null {
  return document.getElementById(targetId)
}
