import { UserService } from 'application/service'
import { HashingService } from 'application/service/hash'
import { HTTPService } from 'application/service/http'
import { useMemo } from 'react'
import useAuthService from './useAuthService'

const { REACT_APP_SERVER_ADDRESS } = process.env
const SERVER_ADDRESS = REACT_APP_SERVER_ADDRESS
  ? REACT_APP_SERVER_ADDRESS
  : 'https://localhost:5000'

export default function useUserService(): UserService {
  const authService = useAuthService()

  return useMemo(() => {
    return new UserService(
      new HTTPService(SERVER_ADDRESS),
      authService,
      new HashingService()
    )
  }, [authService])
}
