import { Layout } from '../layout/layout'
import { Shaper } from 'application/text'
import { Styles } from '../styles/styles'
import { CommandHandler, EditorState } from '../textEditor'
import { TextCommand, TextCommandInsert } from '../command/types'
import { State } from '../state/types'
import { Style } from '../styles/types'
import { deleteSelection } from './utils'

export class InsertHandler implements CommandHandler {
  private state: EditorState
  private layout: Layout
  private shaper: Shaper
  private styles: Styles

  constructor(
    state: EditorState,
    layout: Layout,
    shaper: Shaper,
    style: Styles
  ) {
    this.state = state
    this.layout = layout
    this.shaper = shaper
    this.styles = style
  }

  handle = (command: TextCommand): void => {
    switch (command.type) {
      case 'insert':
        this.handleInsert(command)
        break
    }
  }

  private handleInsert = (command: TextCommandInsert): void => {
    const state = this.state.get()

    this.updateNextCharacterStyles(state)

    deleteSelection(state)

    this.insertText(state, command)

    this.state.set(state)
  }

  private insertText = (state: State, command: TextCommandInsert): void => {
    const { content, selection } = state
    const { focus } = selection

    const { text } = command.parameters
    const index = focus.index

    const newContent =
      content.content.slice(0, index) + text + content.content.slice(index)
    const newContentStyles = content.contentStyles
      .slice(0, index)
      .concat(new Array(text.length).fill(0))
      .concat(content.contentStyles.slice(index))

    state.content.content = newContent
    state.content.contentStyles = newContentStyles

    this.addStyles(state, index, index + text.length)

    const shapedText = this.shaper.getShapedText(state.content, state.width)
    const newFocus = this.layout.getIndexAfter(shapedText, {
      index: focus.index + text.length - 1,
      wrapped: false,
    })
    if (newFocus === null) return

    selection.focus = newFocus
  }

  private addStyles = (state: State, start: number, end: number): void => {
    if (state.nextCharacter !== null) {
      const newCharSelection = {
        anchor: { index: start, wrapped: false },
        focus: { index: end, wrapped: false },
      }
      this.styles.apply(state.content, newCharSelection, state.nextCharacter)
      state.nextCharacter = null
    } else {
      const previousStyles = this.getPreviousCharacterStyles(state)
      const newCharSelection = {
        anchor: { index: start, wrapped: false },
        focus: { index: end, wrapped: false },
      }
      this.styles.apply(state.content, newCharSelection, previousStyles)
    }
  }

  private updateNextCharacterStyles = (state: State): void => {
    const { content, selection } = state
    const { focus, anchor } = selection
    if (anchor === null) return

    const index = Math.min(focus.index, anchor.index) + 1
    const styles = this.styles.getOverride(content, { index, wrapped: false })
    if (styles === null) return

    state.nextCharacter = {
      ...styles,
    }
  }

  private getPreviousCharacterStyles = (state: State): Style => {
    const { content, selection } = state
    const { focus } = selection
    return this.styles.get(content, focus)
  }
}
