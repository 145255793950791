import { IconKey } from 'assets/iconLibrary'
import Icon from '../Icon/Icon'

interface ButtonIconProps {
  icon: IconKey
  color: string
}

export default function ButtonIcon({ icon, color }: ButtonIconProps) {
  return (
    <div
      style={{
        width: 24,
        height: 24,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: 0,
      }}
    >
      <Icon size={16} icon={icon} color={color} />
    </div>
  )
}
