import { fonts } from 'assets/fonts'
import { FontData, FontKey, FontDataMap } from './types'
import { ProjectFontInfo } from 'application/service'

export class FontDataMapImpl implements FontDataMap {
  private customFontData: { [key in FontKey]: FontData } = {}

  constructor() {
    this.customFontData = {}
  }

  addProjectFont = (data: ProjectFontInfo) => {
    if (!this.customFontData[data.family]) {
      this.customFontData[data.family] = {
        key: data.family,
        weights: [data.weight],
        label: data.family,
        css: data.family,
        paths: { [data.weight]: data.fileUrl },
        urls: { [data.weight]: data.fileUrl },
      }
    } else if (
      !this.customFontData[data.family].weights.includes(data.weight)
    ) {
      this.customFontData[data.family].weights.push(data.weight)
      this.customFontData[data.family].paths[data.weight] = data.fileUrl
      this.customFontData[data.family].urls[data.weight] = data.fileUrl
    }
  }

  removeProjectFont = (data: ProjectFontInfo) => {
    if (!this.customFontData[data.family]) return
    const index = this.customFontData[data.family].weights.indexOf(data.weight)
    if (index === -1) return
    this.customFontData[data.family].weights.splice(index, 1)
    delete this.customFontData[data.family].paths[data.weight]
    delete this.customFontData[data.family].urls[data.weight]
  }

  getFontData = (family: FontKey): FontData | null => {
    if (this.customFontData[family]) return this.customFontData[family]
    if (!fonts[family]) return null
    return fonts[family]
  }

  isInternalFont = (family: FontKey): boolean => {
    return !!fonts[family]
  }
}
