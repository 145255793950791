import {
  AttributeFlexAlign,
  AttributeFlexDirection,
  AttributeDisplay,
  MultiselectStyleMap,
  StyleMap,
  isContainerType,
} from 'application/attributes'
import { StyleAttributePanel } from './styleAttributePanel'
import { ReadOnlyNode } from 'application/node'

type DisplayPanelKeys =
  | 'display'
  | 'flex.direction'
  | 'flex.align'
  | 'flex.justify'
  | 'flex.gap'

type DisplayPanelAttributes = Pick<MultiselectStyleMap, DisplayPanelKeys> | null

export interface DisplayPanelState {
  attributes: DisplayPanelAttributes
  modes: AttributeDisplay[]
}

export interface DisplayPanelHandlers {
  setMode: (mode: AttributeDisplay) => void
  setDirection: (value: AttributeFlexDirection) => void
  setAlign: (main: AttributeFlexAlign, counter: AttributeFlexAlign) => void
  setMain: (main: AttributeFlexAlign) => void
  setCounter: (value: AttributeFlexAlign) => void
  setGap: (value: number) => void
  slideGap: (value: number) => void
}

export class DisplayPanel extends StyleAttributePanel<
  DisplayPanelState,
  DisplayPanelHandlers,
  DisplayPanelKeys
> {
  getSettings(): DisplayPanelState {
    return {
      attributes: this.attributes,
      modes: this.getModeOptions(),
    }
  }

  getHandlers(): DisplayPanelHandlers {
    return {
      setMode: this.setMode,
      setDirection: this.setDirection,
      setAlign: this.setBoth,
      setMain: this.setAlign,
      setCounter: this.setJustify,
      setGap: this.setGap,
      slideGap: this.slideGap,
    }
  }

  private getModeOptions = (): AttributeDisplay[] => {
    const nodes = this.getNodes()
    if (nodes.length === 0) return []

    const modes: AttributeDisplay[] = ['block', 'none']
    const flex = nodes.every((node) => this.isModeAllowed(node, 'flex'))
    if (flex) modes.push('flex')

    return modes
  }

  private setMode = (mode: AttributeDisplay): void => {
    const nodes = this.getNodes()
    if (nodes.length === 0) return

    switch (mode) {
      case 'flex':
        for (const node of nodes) {
          this.setOne(node.getId(), this.getFlexAttributes(node))
        }
        break
      case 'block':
      case 'none':
        this.setMulti({ display: mode })
        break
    }
  }

  private setDirection = (value: AttributeFlexDirection): void => {
    this.setMulti({ 'flex.direction': value })
    this.commit()
  }

  private setBoth = (
    main: AttributeFlexAlign,
    counter: AttributeFlexAlign
  ): void => {
    this.setMulti({ 'flex.align': main, 'flex.justify': counter })
    this.commit()
  }

  private setAlign = (main: AttributeFlexAlign): void => {
    this.setMulti({ 'flex.align': main })
    this.commit()
  }

  private setJustify = (value: AttributeFlexAlign): void => {
    this.setMulti({ 'flex.justify': value })
    this.commit()
  }

  private setGap = (value: number): void => {
    this.setMulti({ 'flex.gap': value })
  }

  private slideGap = (value: number): void => {
    this.slideMulti('flex.gap', value)
  }

  private getFlexAttributes = (node: ReadOnlyNode): Partial<StyleMap> => {
    const attributes: Partial<StyleMap> = { display: 'flex' }
    const direction = node.getStyleAttribute('flex.direction')
    const main = node.getStyleAttribute('flex.align')
    const counter = node.getStyleAttribute('flex.justify')
    const gap = node.getStyleAttribute('flex.gap')
    const isPage = node.getBaseAttribute('type') === 'page'
    const initialDirection = isPage ? 'column' : 'row'
    if (!direction) attributes['flex.direction'] = initialDirection
    if (!main) attributes['flex.align'] = 'start'
    if (!counter) attributes['flex.justify'] = 'start'
    if (gap === undefined) attributes['flex.gap'] = 0
    return attributes
  }

  private isModeAllowed = (
    node: ReadOnlyNode,
    mode: AttributeDisplay
  ): boolean => {
    switch (mode) {
      case 'block':
      case 'none':
        return true
      case 'flex':
        const type = node.getBaseAttribute('type')
        return isContainerType(type)
    }
  }

  protected override getSlideMin = (): number => {
    return 0
  }

  protected override getSlideMax = (): number => {
    return 1_000
  }
}
