import {
  Border1,
  Border2,
  Border3,
  BorderStyle,
  isBorder2,
  isBorder3,
  isBorderStyle,
  isUnitLength,
  Properties,
  UnitColor,
  unitColorToString,
  UnitSize,
  unitSizeToString,
} from 'application/export/css'
import { TailwindClassRenderer } from '../types'
import { colorToTailwind } from '../utils'

export class TailwindClassBorder implements TailwindClassRenderer {
  render = (properties: Partial<Properties>): string[] => {
    const classes: string[] = []

    const border = properties.border
    const borderTop = properties.borderTop
    const borderRight = properties.borderRight
    const borderBottom = properties.borderBottom
    const borderLeft = properties.borderLeft

    if (border !== undefined) {
      const values = this.renderBorderValue(border)
      classes.push(...this.renderBorderSide('all', values))
    }
    if (borderTop !== undefined) {
      const values = this.renderBorderValue(borderTop)
      classes.push(...this.renderBorderSide('top', values))
    }
    if (borderRight !== undefined) {
      const values = this.renderBorderValue(borderRight)
      classes.push(...this.renderBorderSide('right', values))
    }
    if (borderBottom !== undefined) {
      const values = this.renderBorderValue(borderBottom)
      classes.push(...this.renderBorderSide('bottom', values))
    }
    if (borderLeft !== undefined) {
      const values = this.renderBorderValue(borderLeft)
      classes.push(...this.renderBorderSide('left', values))
    }

    return classes
  }

  private renderBorderValue = (
    border: Border1 | Border2 | Border3 | 'none'
  ): string[] => {
    if (isBorderStyle(border)) {
      return this.renderBorderStyle(border)
    } else if (isBorder2(border)) {
      return [
        ...this.renderBorderStyle(border[0]),
        ...this.renderBorderWidth(border[1]),
      ]
    } else if (isBorder3(border)) {
      return [
        ...this.renderBorderStyle(border[0]),
        ...this.renderBorderWidth(border[1]),
        ...this.renderBorderColor(border[2]),
      ]
    }
    return ['-none']
  }

  private renderBorderStyle = (style: BorderStyle): string[] => {
    switch (style) {
      case 'solid':
        return []
      case 'dotted':
        return ['-dotted']
      case 'dashed':
        return ['-dashed']
    }
  }

  private renderBorderWidth = (width: UnitSize): string[] => {
    if (isUnitLength(width) && width.unit === 'px' && width.value === 1) {
      return ['']
    }
    return [`-[${unitSizeToString(width)}]`]
  }

  private renderBorderColor = (color: UnitColor): string[] => {
    const colorString = unitColorToString(color)
    const tailwindColor = colorToTailwind(colorString)
    if (tailwindColor !== null) return [`-${tailwindColor}`]
    return [`-[${colorString}]`]
  }

  private renderBorderSide(
    side: 'top' | 'right' | 'bottom' | 'left' | 'all',
    values: string[]
  ): string[] {
    switch (side) {
      case 'top':
        return values.map((v) => `border-t${v}`)
      case 'right':
        return values.map((v) => `border-r${v}`)
      case 'bottom':
        return values.map((v) => `border-b${v}`)
      case 'left':
        return values.map((v) => `border-l${v}`)
      case 'all':
        return values.map((v) => `border${v}`)
    }
  }
}
