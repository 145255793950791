import { DesignColor } from 'themes'
import { panelShadow } from 'components/Library/Containers/constants'
import { useCallback } from 'react'
import toast, { ToastOptions } from 'react-hot-toast'

export default function useToast() {
  const showToast = useCallback(
    (message: string, type: 'success' | 'error' | 'info') => {
      const options: ToastOptions = {
        duration: 3000,
        style: {
          cursor: 'pointer',
        },
      }

      toast.custom(
        (t) => (
          <CustomToast
            message={message}
            type={type}
            onClose={() => toast.remove(t.id)}
          />
        ),
        options
      )
    },
    []
  )

  return showToast
}

type CustomToastProps = {
  message: string
  type: 'success' | 'error' | 'info'
  onClose: () => void
}

const CustomToast: React.FC<CustomToastProps> = ({
  message,
  type,
  onClose,
}) => {
  let backgroundColor
  switch (type) {
    case 'success':
      backgroundColor = DesignColor('toastSuccess')
      break
    case 'error':
      backgroundColor = DesignColor('toastError')
      break
    case 'info':
      backgroundColor = DesignColor('toastInfo')
      break
    default:
      backgroundColor = DesignColor('toastInfo')
  }

  return (
    <div
      onClick={onClose}
      style={{
        color: DesignColor('text1'),
        borderRadius: '0px',
        boxShadow: panelShadow,
        border: `1px solid ${DesignColor('panelBorder')}`,
        cursor: 'pointer',
        background: backgroundColor,
        padding: '16px',
        fontSize: '14px',
        fontFamily: 'inter',
      }}
    >
      {message}
    </div>
  )
}
