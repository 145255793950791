import { ScreenSize } from './shapes'

export type ScreenSizeType = 'desktop' | 'tablet' | 'phone'

export const StandardScreens: Record<ScreenSizeType, ScreenSize[]> = {
  desktop: [
    { label: 'Small Desktop', w: 1280, h: 720 },
    { label: 'Medium Desktop', w: 1440, h: 1024 },
    { label: 'Large Desktop', w: 1920, h: 1280 },
  ],
  tablet: [
    { label: 'Small Tablet', w: 600, h: 960 },
    { label: 'Medium Tablet', w: 768, h: 1024 },
    { label: 'Large Tablet', w: 1024, h: 1366 },
  ],
  phone: [
    { label: 'Small Phone', w: 360, h: 640 },
    { label: 'Medium Phone', w: 375, h: 812 },
    { label: 'Large Phone', w: 414, h: 896 },
  ],
}

export const UniqueScreens = {
  desktop: [
    { label: `MacBook Air`, w: 1280, h: 832 },
    { label: `MacBook Pro 14"`, w: 1512, h: 982 },
    { label: `MacBook Pro 16"`, w: 1728, h: 1117 },
    { label: `Standard 24"`, w: 1920, h: 1080 },
    { label: `Ultrawide 34"`, w: 2560, h: 1080 },
  ],
  tablet: [
    { label: `iPad mini`, w: 768, h: 1024 },
    { label: `iPad Air 4th Gen`, w: 820, h: 1180 },
    { label: `iPad Pro"`, w: 1024, h: 1366 },
  ],
  phone: [
    { label: `iPhone 14`, w: 390, h: 844 },
    { label: `iPhone 14 Pro`, w: 393, h: 852 },
    { label: `iPhone 14 Pro Max`, w: 430, h: 932 },
    { label: `iPhone 8`, w: 375, h: 667 },
    { label: `Google Pixel 6`, w: 412, h: 915 },
    { label: `Samsung Galaxy S22`, w: 360, h: 780 },
  ],
}
