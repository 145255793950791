import {
  Context,
  UniformValue,
  VaoData,
  VertexBuffer,
  calcBoundingBox,
  calcRectColors,
  calcRectPosition,
  calcRounding,
  isPointInBox,
} from 'application/render'
import { AttributeFill } from 'application/attributes'
import { Rectangle } from 'application/shapes'
import { MaterialType } from './shaderMap'
import {
  cleanupVaoData,
  clearBuffers,
  connectBuffersToVao,
  createEmptyVaoData,
  standardDraw,
} from '../utils'

export type WebglRectangleData = Rectangle & {
  fill: AttributeFill
  rounding?: { tl: number; tr: number; br: number; bl: number }
  bb?: Rectangle
  bbRounding?: { tl: number; tr: number; br: number; bl: number }
}

export class WebglRectangle {
  private context: Context
  private data: WebglRectangleData
  private vaoData: VaoData

  constructor(context: Context, data: WebglRectangleData) {
    this.context = context
    this.data = data
    this.vaoData = createEmptyVaoData()
  }

  init = (): void => {
    this.vaoData = this.updateRenderData()
  }

  draw(): void {
    standardDraw(this.vaoData)
  }

  updateRenderData(): VaoData {
    clearBuffers(this.vaoData)
    const gl = this.context.getGl()
    const material = this.context.getMaterial(MaterialType.rectangle)
    const vao = gl.createVertexArray()
    const buffers: { [key: string]: VertexBuffer } = {}
    const uniforms: { [key: string]: UniformValue } = {}

    const { x, y, w, h, fill, rounding, bb, bbRounding } = this.data

    const aPosition = new Float32Array(calcRectPosition(this.data, fill))
    const aColor = new Float32Array(calcRectColors(fill))
    const uRect = [x, y, w, h]
    const uRounding = calcRounding(w, h, rounding)
    const uBB = calcBoundingBox(bb)
    const uBBRounding = calcRounding(uBB[2], uBB[3], bbRounding)

    buffers['aPosition'] = this.context.createVertexBuffer(aPosition, 2)
    buffers['aColor'] = this.context.createVertexBuffer(aColor, 4)
    uniforms['uRect'] = uRect
    uniforms['uRounding'] = uRounding
    uniforms['uBB'] = uBB
    uniforms['uBBRounding'] = uBBRounding
    const verticeCount = aPosition.length / 2

    const vaoData = { material, vao, buffers, uniforms, verticeCount }
    connectBuffersToVao(vaoData)
    return vaoData
  }

  cleanup(): void {
    cleanupVaoData(this.vaoData)
  }
}

export const rectangleVs = `#version 300 es

uniform mat3 uMatrix;

in vec2 aPosition;
in vec4 aColor;

out vec2 vPosition;
out vec4 vColor;

void main() {
  vec3 clipPos = uMatrix * vec3(aPosition, 1);
  gl_Position = vec4(clipPos.xy, 0, 1);
  
  vPosition = aPosition;
  vColor = aColor;
}
`

export const rectangleFs = `#version 300 es
precision mediump float;

in vec2 vPosition;
in vec4 vColor;

uniform vec4 uRect;
uniform vec4 uRounding;
uniform vec4 uBB;
uniform vec4 uBBRounding;

out vec4 outColor;

${isPointInBox}

void main() {
  if (!isPointInBox(vPosition, uRect, uRounding) || !isPointInBox(vPosition, uBB, uBBRounding)) {
    discard;
  }

  outColor = vColor;
}
`
