import { FlexPositionLine } from './flexPositionLine'
import { PositionContext } from '../context/positionContext'
import { CssFlexDirection, CssFlexJustify } from '../types/types'
import { ContainingContext } from '../context/containingContext'

export class FlexPositionLineImpl extends FlexPositionLine {
  initialize = (context: ContainingContext): void => {
    this.mainSize = this.getMainSize()
    this.crossSize = this.getCrossSize()

    this.contentMainSize = 0
    this.contentCrossSize = 0

    for (const item of this.items) {
      this.contentMainSize += item.mainSize
      this.contentCrossSize = Math.max(this.contentCrossSize, item.crossSize)
    }

    this.align = this.layoutItem.element.css.alignItems
    this.justify = this.layoutItem.element.css.justifyContent

    this.gap = this.layoutItem.getGap(context)

    if (this.justify !== CssFlexJustify.space_between) {
      this.contentMainSize += this.gap * (this.items.length - 1)
    }
  }

  place = (context: PositionContext): void => {
    let offset = 0
    let gap = this.gap
    switch (this.justify) {
      case CssFlexJustify.space_between:
        const freeSpace = this.mainSize - this.contentMainSize
        gap = Math.max(this.gap, freeSpace / (this.items.length - 1))
        break
      case CssFlexJustify.center:
        offset = (this.mainSize - this.contentMainSize) / 2
        break
      case CssFlexJustify.end:
        offset = this.mainSize - this.contentMainSize
        break
    }

    let current = offset
    for (const item of this.items) {
      switch (this.flexDirection) {
        case CssFlexDirection.row:
          item.place(context.newOffsetX(current), this.crossSize)
          break
        case CssFlexDirection.column:
          item.place(context.newOffsetY(current), this.crossSize)
          break
      }
      current += item.mainSize + gap
    }
  }

  private getMainSize = (): number => {
    return this.flexDirection === CssFlexDirection.row
      ? this.layoutItem.getWidth() - this.layoutItem.getOutlineWidth()
      : this.layoutItem.getHeight() - this.layoutItem.getOutlineHeight()
  }

  private getCrossSize = (): number => {
    return this.flexDirection === CssFlexDirection.row
      ? this.layoutItem.getHeight() - this.layoutItem.getOutlineHeight()
      : this.layoutItem.getWidth() - this.layoutItem.getOutlineWidth()
  }
}
