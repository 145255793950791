import { TemplateInfo } from 'application/service'
import { BackendService } from 'application/service/backend'
import { ActionInitiator } from 'editor/action/initiator'

interface TemplatesPanelState {
  templates: TemplateInfo[]
  tab: string
  scroll: number
}

interface TemplatesPanelHandlers {
  setTab: (tab: string) => void
  setScroll: (scroll: number) => void
  drag: (templateInfo: TemplateInfo) => Promise<void>
  load: () => Promise<void>
}

type TemplatesPanelListener = (state: TemplatesPanelState) => void

export class TemplatesPanel {
  private backendService: BackendService
  private actionInitiator: ActionInitiator

  private templates: TemplateInfo[]
  private filtered: TemplateInfo[]
  private tab: string
  private scroll: number

  private listeners: { [key: string]: TemplatesPanelListener } = {}

  constructor(
    backendService: BackendService,
    actionInitiator: ActionInitiator
  ) {
    this.backendService = backendService
    this.actionInitiator = actionInitiator

    this.templates = []
    this.filtered = []
    this.tab = 'examples'
    this.scroll = 0

    this.listeners = {}
  }

  getSettings = (): TemplatesPanelState => {
    return {
      templates: this.filtered,
      tab: this.tab,
      scroll: this.scroll,
    }
  }

  getHandlers = (): TemplatesPanelHandlers => {
    return {
      setTab: this.setTab,
      setScroll: this.setScroll,
      drag: this.drag,
      load: this.load,
    }
  }

  subscribe = (id: string, listener: TemplatesPanelListener) => {
    this.listeners[id] = listener
  }

  unsubscribe = (id: string) => {
    delete this.listeners[id]
  }

  private drag = async (templateInfo: TemplateInfo): Promise<void> => {
    try {
      const data = await this.backendService.getTemplateData(templateInfo)
      this.actionInitiator.dragTemplate(data)
    } catch (e) {}
  }

  private setScroll = (scroll: number) => {
    this.scroll = scroll
    this.notifyListeners()
  }

  private setTab = (tab: string) => {
    this.tab = tab
    this.scroll = 0
    this.filtered = this.templates.filter((t) => t.tags.includes(tab))
    this.notifyListeners()
  }

  private load = async () => {
    try {
      const templates = await this.backendService.getTemplates()
      this.templates = templates
      this.filtered = this.templates.filter((t) => t.tags.includes(this.tab))
      this.scroll = 0
      this.tab = 'examples'
      this.notifyListeners()
    } catch (e) {
      console.error('Failed to get templates', e)
    }
  }

  private notifyListeners = () => {
    for (const listener of Object.values(this.listeners)) {
      listener(this.getSettings())
    }
  }
}
