import Text from 'components/Library/Text/Text'
import { DesignColor } from 'themes'
import IconBlock from '../IconBlock/IconBlock/IconBlock'

interface TemplatesTitleProps {
  close: () => void
}

export default function TemplatesTitle({ close }: TemplatesTitleProps) {
  return (
    <div
      style={{
        width: '100%',
        height: 48,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 16px',
        boxSizing: 'border-box',
        boxShadow: `inset 0 0 0 1px ${DesignColor('panelBorder')}`,
        flexShrink: 0,
      }}
    >
      <Text styles={{ size: 12, weight: 'bold' }}>Starter Library</Text>
      <IconBlock icon={'X'} onClick={close} />
    </div>
  )
}
