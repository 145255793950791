import { ContainingContext } from '../context/containingContext'
import { PositionContext } from '../context/positionContext'
import { SizeContext } from '../context/sizeContext'
import { LayoutItem } from '../layoutItem/layoutItem'
import { SizeValueType } from '../types/types'

export class AbsoluteItem {
  layoutItem: LayoutItem

  constructor(layoutItem: LayoutItem) {
    this.layoutItem = layoutItem
  }

  resize = (context: SizeContext): void => {
    const containingContext = ContainingContext.fromSizeContext(context)

    const sizeContext = this.layoutItem.sizeContextPool.fromContext(context)

    const height = this.layoutItem.getPreferredHeight(context)
    const transferedHeight = this.layoutItem.getTransferredHeight(context)

    const top = this.layoutItem.getConvertedTop(containingContext)
    const bottom = this.layoutItem.getConvertedBottom(containingContext)
    if (
      top !== undefined &&
      bottom !== undefined &&
      height === undefined &&
      transferedHeight === undefined
    ) {
      sizeContext.height.type = SizeValueType.exact
      sizeContext.height.value = this.layoutItem.minMaxHeight(
        this.layoutItem.minMaxTransferredHeight(bottom - top, context),
        context
      )
    }

    const width = this.layoutItem.getPreferredWidth(context)
    const transferedWidth = this.layoutItem.getTransferredWidth(context)

    const left = this.layoutItem.getConvertedLeft(containingContext)
    const right = this.layoutItem.getConvertedRight(containingContext)
    if (
      left !== undefined &&
      right !== undefined &&
      width === undefined &&
      transferedWidth === undefined
    ) {
      sizeContext.width.type = SizeValueType.exact
      sizeContext.width.value = this.layoutItem.minMaxWidth(
        this.layoutItem.minMaxTransferredWidth(right - left, context),
        context
      )
    }

    this.layoutItem.resize(sizeContext)

    this.layoutItem.sizeContextPool.release(sizeContext)
  }

  place = (context: PositionContext): void => {
    this.layoutItem.place(context)
  }
}
