import { FlexLayout } from './flexLayout'
import { FlexSizeItem } from './flexSizeItem'
import { FlexSizeItemRow } from './flexSizeItemRow'
import { FlexSizeLine } from './flexSizeLine'
import { SizeContext } from '../context/sizeContext'
import { CssFlexWrap, LayoutItemType, SizeValueType } from '../types/types'
import { FlexSizeItemRowPool } from './flexSizeItemRowPool'

export class FlexLayoutRow extends FlexLayout {
  itemPool: FlexSizeItemRowPool = new FlexSizeItemRowPool(1)

  getType = (): LayoutItemType => LayoutItemType.flex_row

  createSizeItems = (): FlexSizeItem[] => {
    const items: FlexSizeItem[] = []
    for (const child of this.children) {
      if (!child.isInFlow()) continue
      const item = this.itemPool!.fromItem(child)
      items.push(item)
    }
    return items
  }

  releaseSizeItems = (items: FlexSizeItem[]): void => {
    for (const item of items) {
      this.itemPool!.release(item as FlexSizeItemRow)
    }
  }

  getMainSize = (): number => this.getWidth()

  getCrossSize = (): number => this.getHeight()

  computeMainSize = (context: SizeContext, items: FlexSizeItem[]): number => {
    if (context.width.type === SizeValueType.exact) {
      return context.width.value - this.getOutlineWidth()
    }

    const width = this.innerWidth(context)
    const transferredWidth = this.innerTransferredWidth(context)

    if (transferredWidth !== undefined) return transferredWidth
    if (width !== undefined) return width

    let contentMinWidth = 0
    let contentMaxWidth = 0
    let contentMainSize = 0
    const wraps = this.getFlexWrap() === CssFlexWrap.wrap
    for (const item of items) {
      if (wraps) {
        contentMinWidth = Math.max(contentMinWidth, item.minContentMainSize)
        contentMaxWidth = Math.max(contentMaxWidth, item.maxContentMainSize)
        contentMainSize = Math.max(contentMainSize, item.mainSize)
      } else {
        contentMinWidth += item.minContentMainSize
        contentMaxWidth += item.maxContentMainSize
        contentMainSize += item.mainSize
      }
    }

    if (!wraps) {
      contentMinWidth += this.computeTotalGap(context, items)
      contentMaxWidth += this.computeTotalGap(context, items)
      contentMainSize += this.computeTotalGap(context, items)
    }

    switch (context.width.type) {
      case SizeValueType.absolute:
      case SizeValueType.auto:
        if (transferredWidth !== undefined) return transferredWidth
        if (width !== undefined) return width
        const fitContentWidth = Math.max(
          contentMinWidth,
          Math.min(
            Math.max(0, context.width.value - this.getOutlineWidth()),
            contentMaxWidth
          )
        )
        return fitContentWidth
      case SizeValueType.min_content:
        return this.minMaxWidth(contentMinWidth, context)
      case SizeValueType.max_content:
        return this.minMaxWidth(contentMaxWidth, context)
    }
  }

  computeCrossSize = (
    mainSize: number,
    context: SizeContext,
    lines: FlexSizeLine[]
  ): number => {
    if (context.height.type === SizeValueType.exact) {
      return context.height.value - this.getOutlineHeight()
    }

    switch (context.height.type) {
      case SizeValueType.auto:
      case SizeValueType.absolute:
        const height = this.innerHeight(context)
        if (height !== undefined) return height

        const transferredHeight = this.innerTransferredHeight(mainSize, context)
        if (transferredHeight !== undefined) return transferredHeight
        break
    }

    let sumHeight = 0
    for (const line of lines) {
      sumHeight += line.getMaxCrossSize()
    }

    return this.minMaxHeight(sumHeight, context)
  }

  sizeSelf = (mainSize: number, crossSize: number): void => {
    this.position.width = mainSize + this.getOutlineWidth()
    this.position.height = crossSize + this.getOutlineHeight()
  }

  private innerHeight = (context: SizeContext): number | undefined => {
    const preferredHeight = this.getPreferredHeight(context)
    if (preferredHeight !== undefined) {
      return (
        this.minMaxHeight(preferredHeight, context) - this.getOutlineHeight()
      )
    }
  }

  private innerWidth = (context: SizeContext): number | undefined => {
    const preferredWidth = this.getPreferredWidth(context)
    if (preferredWidth !== undefined) {
      return this.minMaxWidth(preferredWidth, context) - this.getOutlineWidth()
    }
  }

  private innerTransferredWidth = (
    context: SizeContext
  ): number | undefined => {
    const transferredWidth = this.getTransferredWidth(context)
    if (transferredWidth === undefined) return undefined
    return (
      this.minMaxWidth(
        this.minMaxTransferredWidth(transferredWidth, context),
        context
      ) - this.getOutlineWidth()
    )
  }

  private innerTransferredHeight = (
    mainSize: number,
    context: SizeContext
  ): number | undefined => {
    const exactWidthContext = this.sizeContextPool!.fromContext(context)
    exactWidthContext.width.type = SizeValueType.exact
    exactWidthContext.width.value = mainSize

    const transferredHeight = this.getTransferredHeight(exactWidthContext)
    if (transferredHeight === undefined) {
      this.sizeContextPool!.release(exactWidthContext)
      return undefined
    }

    this.sizeContextPool!.release(exactWidthContext)

    return (
      this.minMaxHeight(
        this.minMaxTransferredHeight(transferredHeight, context),
        context
      ) - this.getOutlineHeight()
    )
  }
}
