import { ReadOnlyDocument } from 'application/document'
import { Element } from './html/types'
import { ReadOnlyNode } from 'application/node'
import { FontKey, FontWeight } from 'application/text'
import { weightToNumber } from 'application/text/utils'
import { LeafElementBuilder } from './html/elementLeafBuilder'
import { isTextType } from 'application/attributes'

export class FontsTransformer {
  transform = (id: string, document: ReadOnlyDocument): Element[] => {
    const fonts: { [key: FontKey]: FontWeight[] } = {}

    const baseNode = document.getNode(id)
    if (!baseNode) return []

    this.traverseTree(baseNode, document, fonts)

    const urls = Object.keys(fonts).map((font) =>
      this.fontToURL(font, fonts[font])
    )

    return urls.map((url) => {
      return new LeafElementBuilder()
        .withTag('link')
        .withAttributes({ rel: 'stylesheet', href: url })
        .build()
    })
  }

  private getFont = (node: ReadOnlyNode): [FontKey, FontWeight] | null => {
    if (!isTextType(node.getBaseAttribute('type'))) return null

    const defaultSelector = node.getDefaultSelector()
    const fontFamily = defaultSelector.styles['text.font.family']
    const fontWeight = defaultSelector.styles['text.font.weight']
    if (!fontFamily || !fontWeight) return null

    return [fontFamily, fontWeight]
  }

  private traverseTree = (
    node: ReadOnlyNode,
    document: ReadOnlyDocument,
    fonts: { [key: FontKey]: FontWeight[] }
  ): void => {
    const nodeFont = this.getFont(node)
    if (nodeFont) {
      if (!fonts[nodeFont[0]]) fonts[nodeFont[0]] = []
      if (!fonts[nodeFont[0]].includes(nodeFont[1])) {
        fonts[nodeFont[0]].push(nodeFont[1])
      }
    }

    const children = node.getChildren()
    if (!children) return

    for (const childId of children) {
      const child = document.getNode(childId)
      if (child) this.traverseTree(child, document, fonts)
    }
  }

  private fontToURL = (font: FontKey, weights: FontWeight[]): string => {
    const formattedFont = this.formatFontName(font)
    const formattedWeights = weights
      .map((w) => weightToNumber(w))
      .sort((a, b) => a - b)
      .join(';')
    return `https://fonts.googleapis.com/css2?family=${formattedFont}:wght@${formattedWeights}`
  }

  private formatFontName = (font: FontKey): string => {
    return font
      .split(/(?=[A-Z])/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join('+')
  }
}
