import { StyleMap } from '../css'
import { Element, HTMLDocument } from './types'

export class HTMLDocumentImpl implements HTMLDocument {
  private root: Element
  private head: Element
  private body: Element
  private styleMap: StyleMap

  constructor(root: Element, head: Element, body: Element, styleMap: StyleMap) {
    this.root = root
    this.head = head
    this.body = body
    this.styleMap = styleMap
  }

  getRoot = (): Element => {
    return this.root
  }

  getHead = (): Element => {
    return this.head
  }

  getBody = (): Element => {
    return this.body
  }

  getStyles = (): StyleMap => {
    return this.styleMap
  }

  getParent = (node: Element): Element | null => {
    return this.findParent(node, this.root)
  }

  getById = (id: string): Element | null => {
    return this.findNodeById(id, this.root)
  }

  private findParent = (node: Element, parent: Element): Element | null => {
    const children = parent.getChildren()
    if (!children) return null

    for (const child of children) {
      if (child === node) return parent
      const found = this.findParent(node, child)
      if (found) return found
    }

    return null
  }

  private findNodeById = (id: string, node: Element): Element | null => {
    if (node.getAttribute('id') === id) return node

    const children = node.getChildren()
    if (!children) return null

    for (const child of children) {
      const found = this.findNodeById(id, child)
      if (found) return found
    }

    return null
  }
}
