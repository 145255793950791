import {
  CssDisplay,
  CssFlexAlign,
  CssFlexDirection,
  CssFlexJustify,
  CssFlexWrap,
  CssLength,
  CssOverflow,
  CssPosition,
  CssStyles,
  CssUnit,
  Element,
} from 'application/layout'
import { ReadOnlyNode } from 'application/node'
import _ from 'lodash'
import { getVH } from './utils'

const sides: ('top' | 'right' | 'bottom' | 'left')[] = [
  'top',
  'right',
  'bottom',
  'left',
]

export class StyleInitializer {
  init = (node: ReadOnlyNode, element: Element) => {
    this.addDisplay(element.css, node)

    this.addPositionMode(element.css, node)

    for (const side of sides) {
      this.addPosition(element.css, node, side)
    }

    this.addWidth(element.css, node)
    this.addHeight(element.css, node)

    this.addMinWidth(element.css, node)
    this.addMinHeight(element.css, node)

    this.addMaxWidth(element.css, node)
    this.addMaxHeight(element.css, node)

    this.addAspectRatio(element.css, node)

    for (const side of sides) {
      this.addPadding(element.css, node, side)
      this.addBorder(element.css, node, side)
    }

    this.addFlexDirection(element.css, node)
    this.addFlexWrap(element.css, node)

    this.addFlexGrow(element.css, node)
    this.addFlexShrink(element.css, node)
    this.addFlexBasis(element.css, node)

    this.addGap(element.css, node)

    this.addOverflow(element.css, node)

    this.addAlignItems(element.css, node)
    this.addJustifyContent(element.css, node)
    this.addAlignSelf(element.css, node)
  }

  private addDisplay = (css: CssStyles, node: ReadOnlyNode) => {
    const mode = node.getStyleAttribute('display')
    switch (mode) {
      case 'flex':
        css.display = CssDisplay.display_flex
        break
    }
  }

  private addPositionMode = (css: CssStyles, node: ReadOnlyNode) => {
    const mode = node.getStyleAttribute('position.mode')
    switch (mode) {
      case 'absolute':
        css.position = CssPosition.position_absolute
        break
      case 'fixed':
        css.position = CssPosition.position_fixed
        break
      case 'sticky':
        css.position = CssPosition.position_sticky
        break
      case 'static':
        css.position = CssPosition.position_static
        break
      default:
        css.position = CssPosition.position_relative
        break
    }
  }

  private addPosition = (
    css: CssStyles,
    node: ReadOnlyNode,
    side: 'top' | 'right' | 'bottom' | 'left'
  ) => {
    const mode = node.getStyleAttribute(`position.${side}.unit`)
    switch (mode) {
      case 'px':
        const px = node.getStyleAttribute(`position.${side}.px`)
        if (px === undefined) break
        css[side] = CssLength.create(px, CssUnit.unit_px)
        break
      case 'percent':
        const percent = node.getStyleAttribute(`position.${side}.percent`)
        if (percent === undefined) break
        css[side] = CssLength.create(percent, CssUnit.unit_percent)
        break
    }
  }

  private addWidth = (css: CssStyles, node: ReadOnlyNode) => {
    const mode = node.getStyleAttribute('size.w.unit')
    switch (mode) {
      case 'px':
        const px = node.getStyleAttribute('size.w.px')
        if (px === undefined) break
        css.width = CssLength.create(px, CssUnit.unit_px)
        break
      case 'percent':
        const percent = node.getStyleAttribute('size.w.percent')
        if (percent === undefined) break
        css.width = CssLength.create(percent, CssUnit.unit_percent)
        break
    }
  }

  private addHeight = (css: CssStyles, node: ReadOnlyNode) => {
    const mode = node.getStyleAttribute('size.h.unit')
    switch (mode) {
      case 'px':
        const px = node.getStyleAttribute('size.h.px')
        if (px === undefined) break
        css.height = CssLength.create(px, CssUnit.unit_px)
        break
      case 'percent':
        const percent = node.getStyleAttribute('size.h.percent')
        if (percent === undefined) break
        css.height = CssLength.create(percent, CssUnit.unit_percent)
        break
    }
  }

  private addMinWidth = (css: CssStyles, node: ReadOnlyNode) => {
    const mode = node.getStyleAttribute('size.w.min.unit')
    switch (mode) {
      case 'px':
        const px = node.getStyleAttribute('size.w.min.px')
        if (px === undefined) break
        css.minWidth = CssLength.create(px, CssUnit.unit_px)
        break
    }
  }

  private addMinHeight = (css: CssStyles, node: ReadOnlyNode) => {
    const mode = node.getStyleAttribute('size.h.min.unit')
    switch (mode) {
      case 'px':
        const px = node.getStyleAttribute('size.h.min.px')
        if (px === undefined) break
        css.minHeight = CssLength.create(px, CssUnit.unit_px)
        break
      default:
        const page = node.getBaseAttribute('type') === 'page'
        const vh = getVH(node)
        if (page) css.minHeight = CssLength.create(vh, CssUnit.unit_px)
        break
    }
  }

  private addMaxWidth = (css: CssStyles, node: ReadOnlyNode) => {
    const mode = node.getStyleAttribute('size.w.max.unit')
    switch (mode) {
      case 'px':
        const px = node.getStyleAttribute('size.w.max.px')
        if (px === undefined) break
        css.maxWidth = CssLength.create(px, CssUnit.unit_px)
        break
    }
  }

  private addMaxHeight = (css: CssStyles, node: ReadOnlyNode) => {
    const mode = node.getStyleAttribute('size.h.max.unit')
    switch (mode) {
      case 'px':
        const px = node.getStyleAttribute('size.h.max.px')
        if (px === undefined) break
        css.maxHeight = CssLength.create(px, CssUnit.unit_px)
        break
    }
  }

  private addAspectRatio = (css: CssStyles, node: ReadOnlyNode) => {
    const ratio = node.getStyleAttribute('size.ratio')
    if (ratio === undefined) return
    css.aspectRatio = ratio[0] / ratio[1]
  }

  private addPadding = (
    css: CssStyles,
    node: ReadOnlyNode,
    side: 'top' | 'right' | 'bottom' | 'left'
  ) => {
    const unit = node.getStyleAttribute(`padding.${side}.unit`)
    switch (unit) {
      case 'px':
        const px = node.getStyleAttribute(`padding.${side}.px`)
        if (px === undefined) return

        const upperSide = _.upperFirst(side) as
          | 'Top'
          | 'Right'
          | 'Bottom'
          | 'Left'
        css[`padding${upperSide}`] = CssLength.create(px, CssUnit.unit_px)
        break
    }
  }

  private addBorder = (
    css: CssStyles,
    node: ReadOnlyNode,
    side: 'top' | 'right' | 'bottom' | 'left'
  ) => {
    const unit = node.getStyleAttribute(`border.${side}.unit`)
    switch (unit) {
      case 'px':
        const px = node.getStyleAttribute(`border.${side}.px`)
        if (px === undefined) return

        const upperSide = _.upperFirst(side) as
          | 'Top'
          | 'Right'
          | 'Bottom'
          | 'Left'
        css[`border${upperSide}`] = CssLength.create(px, CssUnit.unit_px)
        break
    }
  }

  private addFlexDirection = (css: CssStyles, node: ReadOnlyNode) => {
    const mode = node.getStyleAttribute('flex.direction')
    switch (mode) {
      case 'row':
        css.flexDirection = CssFlexDirection.row
        break
      case 'column':
        css.flexDirection = CssFlexDirection.column
        break
    }
  }

  private addFlexWrap = (css: CssStyles, node: ReadOnlyNode) => {
    const mode = node.getStyleAttribute('flex.direction')
    switch (mode) {
      case 'wrap':
        css.flexWrap = CssFlexWrap.wrap
        break
      default:
        css.flexWrap = CssFlexWrap.nowrap
        break
    }
  }

  private addFlexGrow = (css: CssStyles, node: ReadOnlyNode) => {
    const grow = node.getStyleAttribute('flex.grow')
    if (grow === undefined) return
    css.flexGrow = grow
  }

  private addFlexShrink = (css: CssStyles, node: ReadOnlyNode) => {
    const shrink = node.getStyleAttribute('flex.shrink')
    if (shrink === undefined) return
    css.flexShrink = shrink
  }

  private addFlexBasis = (css: CssStyles, node: ReadOnlyNode) => {
    const mode = node.getStyleAttribute('flex.basis.unit')
    switch (mode) {
      case 'px':
        const px = node.getStyleAttribute('flex.basis.px')
        if (px === undefined) break
        css.flexBasis = CssLength.create(px, CssUnit.unit_px)
        break
    }
  }

  private addGap = (css: CssStyles, node: ReadOnlyNode) => {
    const gap = node.getStyleAttribute('flex.gap')
    if (gap === undefined) return
    css.gap = CssLength.create(gap, CssUnit.unit_px)
  }

  private addOverflow = (css: CssStyles, node: ReadOnlyNode) => {
    const overflow = node.getStyleAttribute('overflow')
    switch (overflow) {
      case 'visible':
        css.overflow = CssOverflow.overflow_visible
        break
      case 'hidden':
        css.overflow = CssOverflow.overflow_hidden
        break
      case 'scroll':
        css.overflow = CssOverflow.overflow_scroll
        break
      case 'auto':
        css.overflow = CssOverflow.overflow_auto
        break
    }
  }

  private addAlignItems = (css: CssStyles, node: ReadOnlyNode) => {
    const mode = node.getStyleAttribute('flex.align')
    switch (mode) {
      case 'start':
        css.alignItems = CssFlexAlign.start
        break
      case 'center':
        css.alignItems = CssFlexAlign.center
        break
      case 'end':
        css.alignItems = CssFlexAlign.end
        break
    }
  }

  private addJustifyContent = (css: CssStyles, node: ReadOnlyNode) => {
    const mode = node.getStyleAttribute('flex.justify')
    switch (mode) {
      case 'start':
        css.justifyContent = CssFlexJustify.start
        break
      case 'end':
        css.justifyContent = CssFlexJustify.end
        break
      case 'center':
        css.justifyContent = CssFlexJustify.center
        break
      case 'spaced':
        css.justifyContent = CssFlexJustify.space_between
        break
    }
  }

  private addAlignSelf = (css: CssStyles, node: ReadOnlyNode) => {
    const mode = node.getStyleAttribute('flex.alignSelf')
    switch (mode) {
      case 'stretch':
        css.alignSelf = CssFlexAlign.stretch
        break
    }
  }
}
