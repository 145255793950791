import { ResizeEventListener } from 'application/browser'
import { CanvasSizeService } from 'application/camera'

export class CanvasBrowserEventHandler implements ResizeEventListener {
  private canvasSizeService: CanvasSizeService

  constructor(canvasSizeService: CanvasSizeService) {
    this.canvasSizeService = canvasSizeService
  }

  handleResize = () => {
    this.canvasSizeService.handleResize()
  }
}
