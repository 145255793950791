import {
  isUnitSize,
  Properties,
  unitSizeToString,
} from 'application/export/css'
import { TailwindClassRenderer } from '../types'

export class TailwindClassMargin implements TailwindClassRenderer {
  render = (properties: Partial<Properties>): string[] => {
    const classes: string[] = []

    const margin = properties.margin
    if (isUnitSize(margin)) {
      classes.push(`m-[${unitSizeToString(margin)}]`)
    }

    return []
  }
}
