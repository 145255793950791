import { ReadOnlyDocument } from 'application/document'
import { formatStyle, Style, StyleMap } from '../css'
import { Element, HTMLDocument } from '../html/types'
import { TailwindRenderer } from '../tailwind/render'
import { CodeBlock, CodeBlockType } from '../types'
import { formatNodeId } from '../utils'
import { ReadOnlyNode } from 'application/node'

export class HTMLRenderer {
  private tailwindRenderer: TailwindRenderer

  constructor() {
    this.tailwindRenderer = new TailwindRenderer()
  }

  renderCode = (
    id: string,
    document: ReadOnlyDocument,
    htmlDocument: HTMLDocument,
    styleMap: StyleMap,
    mode: CodeBlockType
  ): CodeBlock[] => {
    const node = htmlDocument.getById(formatNodeId(id))
    if (!node) return []

    switch (mode) {
      case 'jsx+tailwind':
        this.addTailwindToTree(id, htmlDocument, document, styleMap)
        return [{ type: 'jsx+tailwind', code: this.renderHTMLNode(node) }]
      default:
        return [
          { type: 'html', code: this.renderHTMLNode(node) },
          { type: 'css', code: styleMap.toString() },
        ]
    }
  }

  renderStyles = (styles: Style[], mode: CodeBlockType): CodeBlock => {
    switch (mode) {
      case 'tailwind':
        return { type: 'tailwind', code: this.renderTailwindStyles(styles) }
      default:
        return { type: 'css', code: this.renderCSSStyles(styles) }
    }
  }

  renderFonts = (fonts: Element[], mode: CodeBlockType): CodeBlock => {
    return { type: 'html', code: this.renderHTMLNodes(fonts) }
  }

  private renderHTMLNode = (node: Element): string => {
    return node.toString()
  }

  private renderHTMLNodes = (nodes: Element[]): string => {
    return nodes.map((node) => this.renderHTMLNode(node)).join('\n')
  }

  private renderTailwindStyle = (style: Style): string => {
    return this.tailwindRenderer.renderClass(style.getProperties())
  }

  private renderTailwindStyles = (styles: Style[]): string => {
    return styles.map((style) => this.renderTailwindStyle(style)).join('\n')
  }

  private renderCSSStyle = (style: Style): string => {
    return formatStyle(style)
  }

  private renderCSSStyles = (styles: Style[]): string => {
    return styles.map((style) => this.renderCSSStyle(style)).join('\n')
  }

  private addTailwindToTree = (
    id: string,
    htmlDocument: HTMLDocument,
    document: ReadOnlyDocument,
    styleMap: StyleMap
  ): void => {
    const node = htmlDocument.getById(formatNodeId(id))
    if (!node) return

    const documentNode = document.getNode(id)
    if (!documentNode) return

    this.addTailwindStyles(documentNode, node, styleMap)

    const children = documentNode.getChildren()
    if (!children) return

    for (const childId of children) {
      this.addTailwindToTree(childId, htmlDocument, document, styleMap)
    }
  }

  private addTailwindStyles = (
    node: ReadOnlyNode,
    element: Element,
    styleMap: StyleMap
  ): void => {
    const styles = styleMap.getForMode(formatNodeId(node.getId()), 'id')
    const tailwindStyles = this.renderTailwindStyles(styles)
    element.setAttribute('class', tailwindStyles)
  }
}
