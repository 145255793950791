import { BreakpointEngine } from './engine'
import { BreakpointUpdateHandler } from './update'
import { WriteDocument } from 'application/document'

export class BreakpointEngineFactory {
  static create(document: WriteDocument): BreakpointEngine {
    const handler = new BreakpointUpdateHandler(document)
    return new BreakpointEngine(handler)
  }
}
