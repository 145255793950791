import { AttributeType } from 'application/attributes'
import { ClientUpdateListener, Update } from 'application/client'
import { ReadOnlyDocument } from 'application/document'

export type NamePanelSettings = {
  types: { [id: string]: AttributeType }
  names: { [id: string]: string }
}

type DetailsPanelListener = (settings: NamePanelSettings) => void

export class DetailsPanel implements ClientUpdateListener {
  private document: ReadOnlyDocument

  private listeners: { [key: string]: DetailsPanelListener }
  private names: { [id: string]: string }
  private types: { [id: string]: AttributeType }

  constructor(document: ReadOnlyDocument) {
    this.document = document

    this.listeners = {}
    this.names = {}
    this.types = {}
  }

  getTypes = (): Update['type'][] => {
    return ['initialize', 'node_updated', 'node_deleted']
  }

  onUpdate = (updates: Update[]): void => {
    if (updates.length === 0) return

    let updated: boolean = false
    for (const u of updates) {
      switch (u.type) {
        case 'initialize':
          for (const node of this.document.getNodes()) {
            this.names[node.getId()] = node.getBaseAttribute('name')
            this.types[node.getId()] = node.getBaseAttribute('type')
          }
          updated = true
          break
        case 'node_updated':
          const node = this.document.getNode(u.data.id)
          if (!node) continue

          const name = node.getBaseAttribute('name')
          if (name !== this.names[u.data.id]) {
            this.names[u.data.id] = name
            updated = true
          }

          const type = node.getBaseAttribute('type')
          if (type !== this.types[u.data.id]) {
            this.types[u.data.id] = type
            updated = true
          }

          break
        case 'node_deleted':
          delete this.names[u.data.id]
          delete this.types[u.data.id]
          updated = true
          break
      }
    }

    if (updated) this.notifyListeners()
  }

  getSettings = (): NamePanelSettings => {
    return { names: this.names, types: this.types }
  }

  getHandlers = (): {} => {
    return {}
  }

  subscribe = (key: string, listener: DetailsPanelListener): void => {
    this.listeners[key] = listener
    listener(this.getSettings())
  }

  unsubscribe = (key: string): void => {
    delete this.listeners[key]
  }

  private notifyListeners = (): void => {
    const settings = this.getSettings()
    for (const key in this.listeners) {
      this.listeners[key](settings)
    }
  }
}
