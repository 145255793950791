import { HistoryEvent, HistoryActionListener } from './types'

export class HistoryListeners {
  private listeners: HistoryActionListener[]

  constructor() {
    this.listeners = []
  }

  registerListener = (listener: HistoryActionListener): void => {
    this.listeners.push(listener)
  }

  notifyStartUndo = (): void => {
    for (const listener of this.listeners) {
      if (listener.onStartUndo) listener.onStartUndo()
    }
  }

  notifyEndUndo = (): void => {
    for (const listener of this.listeners) {
      if (listener.onEndUndo) listener.onEndUndo()
    }
  }

  notifyStartRedo = (): void => {
    for (const listener of this.listeners) {
      if (listener.onStartRedo) listener.onStartRedo()
    }
  }

  notifyEndRedo = (): void => {
    for (const listener of this.listeners) {
      if (listener.onEndRedo) listener.onEndRedo()
    }
  }

  notifyUndo = (entry: HistoryEvent): void => {
    for (const listener of this.listeners) {
      if (listener.onUndo) listener.onUndo(entry)
    }
  }

  notifyRedo = (entry: HistoryEvent): void => {
    for (const listener of this.listeners) {
      if (listener.onRedo) listener.onRedo(entry)
    }
  }
}
