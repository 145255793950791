import { useEffect, useState } from 'react'
import { v4 } from 'uuid'

interface PanelHelper<S, H> {
  getSettings(): S
  getHandlers(): H
  subscribe(key: string, listener: (s: S) => void): void
  unsubscribe(key: string): void
}

export default function usePanelHelper<S, H>(panelHelper: PanelHelper<S, H>) {
  const [settings, setSettings] = useState<S | null>(panelHelper.getSettings())

  useEffect(() => {
    const key = v4()
    panelHelper.subscribe(key, (s) => {
      setSettings(s)
    })
    return () => {
      return panelHelper.unsubscribe(key)
    }
  }, [panelHelper])

  return {
    settings: settings,
    handlers: panelHelper.getHandlers(),
  }
}
