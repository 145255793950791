import { Camera, CameraService, CameraUpdateListener } from 'application/camera'

export type CameraPanelState = {
  zoom: number
}

export type CameraPanelHandlers = {
  setZoom: (zoom: number) => void
}

type CameraPanelListener = (state: CameraPanelState) => void

export class CameraPanel implements CameraUpdateListener {
  private cameraService: CameraService

  private listeners: { [key: string]: CameraPanelListener }

  constructor(cameraService: CameraService) {
    this.cameraService = cameraService

    this.listeners = {}
  }

  onCamera = (camera: Camera): void => {
    for (const key in this.listeners) {
      this.listeners[key]({
        zoom: camera.z,
      })
    }
  }

  getSettings = (): CameraPanelState => {
    return {
      zoom: this.cameraService.getCamera().z,
    }
  }

  getHandlers = (): CameraPanelHandlers => {
    return {
      setZoom: this.setZoom,
    }
  }

  subscribe = (key: string, listener: CameraPanelListener): void => {
    this.listeners[key] = listener
    listener(this.getSettings())
  }

  unsubscribe = (key: string): void => {
    delete this.listeners[key]
  }

  private setZoom = (zoom: number): void => {
    this.cameraService.zoomToValue(zoom)
  }
}
