import { WriteDocument } from 'application/document'
import { Node } from 'application/node'
import { Rectangle } from 'application/shapes'

export class RenderBoxCalculator {
  private document: WriteDocument

  constructor(document: WriteDocument) {
    this.document = document
  }

  calculate(id: string): void {
    const node = this.document.getNode(id)
    if (!node) return

    const renderBox = this.computeRenderBox(node)
    if (node.getBaseAttribute('renderBox.x') !== renderBox.x) {
      node.setBaseAttribute('renderBox.x', renderBox.x)
    }
    if (node.getBaseAttribute('renderBox.y') !== renderBox.y) {
      node.setBaseAttribute('renderBox.y', renderBox.y)
    }
    if (node.getBaseAttribute('renderBox.w') !== renderBox.w) {
      node.setBaseAttribute('renderBox.w', renderBox.w)
    }
    if (node.getBaseAttribute('renderBox.h') !== renderBox.h) {
      node.setBaseAttribute('renderBox.h', renderBox.h)
    }
  }

  private computeRenderBox(node: Node): Rectangle {
    const nodeBox = this.computeShadowBox(node)
    if (node.getStyleAttribute('overflow') === 'visible') return nodeBox

    const childrenBoxes = this.getChildrenRenderBoxes(node)
    const boxes = [nodeBox, ...childrenBoxes]
    return this.maxOverlappingRectangle(boxes)
  }

  private computeShadowBox(node: Node): Rectangle {
    const x = node.getBaseAttribute('x')
    const y = node.getBaseAttribute('y')
    const w = node.getBaseAttribute('w')
    const h = node.getBaseAttribute('h')

    const shadows = node.getStyleAttribute('shadows')
    if (!shadows || shadows.length === 0) {
      return { x, y, w, h }
    }

    let x1 = x
    let y1 = y
    let x2 = x + w
    let y2 = y + h
    for (const shadow of shadows) {
      const shadowX = x - shadow.x - shadow.blur * 2 - shadow.spread * 2
      const shadowY = y - shadow.y - shadow.blur * 2 - shadow.spread * 2
      const shadowW = w + shadow.blur * 4 + shadow.spread * 4
      const shadowH = h + shadow.blur * 4 + shadow.spread * 4
      if (shadowX < x1) x1 = shadowX
      if (shadowY < y1) y1 = shadowY
      if (shadowX + shadowW > x2) x2 = shadowX + shadowW
      if (shadowY + shadowH > y2) y2 = shadowY + shadowH
    }

    return {
      x: x1,
      y: y1,
      w: x2 - x1,
      h: y2 - y1,
    }
  }

  private getChildrenRenderBoxes(node: Node): Rectangle[] {
    const children = node.getChildren()
    if (!children) return []

    const boxes: Rectangle[] = []
    for (const childId of children) {
      const child = this.document.getNode(childId)
      if (!child) continue

      const childBox = this.computeRenderBox(child)
      boxes.push(childBox)
    }

    return boxes
  }

  private maxOverlappingRectangle(rects: Rectangle[]): Rectangle {
    let x1 = Infinity
    let y1 = Infinity
    let x2 = -Infinity
    let y2 = -Infinity

    for (const rect of rects) {
      if (rect.x < x1) x1 = rect.x
      if (rect.y < y1) y1 = rect.y
      if (rect.x + rect.w > x2) x2 = rect.x + rect.w
      if (rect.y + rect.h > y2) y2 = rect.y + rect.h
    }

    return {
      x: x1,
      y: y1,
      w: x2 - x1,
      h: y2 - y1,
    }
  }
}
