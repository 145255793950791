import ColorBlock from '../../ColorBlock/ColorBlock'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import NumberBlock from '../../../NumberBlock/NumberBlock'
import { AttributeFill } from 'application/attributes'
import { Color } from 'application/color'

interface ColorRowProps {
  fill: AttributeFill
  updateFill: (fill: AttributeFill) => void

  open: boolean
  setOpen: (open: boolean) => void

  displayRef: React.RefObject<HTMLDivElement>

  hasOverride?: boolean
}

export default function ColorRow({
  fill,
  updateFill,
  open,
  setOpen,
  displayRef,
  hasOverride = false,
}: ColorRowProps) {
  if (!fill.color) return <></>
  const { color } = fill

  return (
    <PanelRow>
      <ColorBlock
        color={fill.color}
        updateColor={(color) => updateFill(updateColor(color, fill))}
        open={open}
        setOpen={setOpen}
        displayRef={displayRef}
        hasOverride={hasOverride}
      />
      <NumberBlock
        icon="Opacity"
        tooltipKey="Opacity"
        value={Math.round(fill.color.a * 100)}
        setValue={(a) => {
          updateFill({ ...fill, color: { ...color, a: a / 100 } })
        }}
        increment={(v) =>
          updateFill({
            ...fill,
            color: { ...color, a: Math.min(1, color.a + v / 100) },
          })
        }
        decrement={(v) => {
          updateFill({
            ...fill,
            color: { ...color, a: Math.max(0, color.a - v / 100) },
          })
        }}
        max={100}
        unit={'%'}
        hasOverride={hasOverride}
      />
    </PanelRow>
  )
}

function updateColor(color: Color, fill: AttributeFill): AttributeFill {
  return {
    ...fill,
    color,
  }
}
