import {
  AttributeImageResize,
  AttributeType,
  MultiselectBaseMap,
  MultiselectStyleMap,
} from 'application/attributes'
import { Size } from 'application/shapes'
import { ReadOnlyNode } from 'application/node'
import { AttributePanel } from './attributePanel'

type ImagePanelStyleKeys = 'image.resize'
type ImagePanelStyleAttributes = Pick<
  MultiselectStyleMap,
  ImagePanelStyleKeys
> | null

type ImagePanelBaseKeys =
  | 'image.src'
  | 'image.originalSize.w'
  | 'image.originalSize.h'
type ImagePanelBaseAttributes = Pick<
  MultiselectBaseMap,
  ImagePanelBaseKeys
> | null

export interface ImagePanelState {
  styleAttributes: ImagePanelStyleAttributes
  baseAttributes: ImagePanelBaseAttributes
}

export interface ImagePanelHandlers {
  setSrc: (src: string, size: Size) => void
  setResize: (value: AttributeImageResize | undefined) => void
  clearMixed: () => void
}

export class ImagePanel extends AttributePanel<
  ImagePanelState,
  ImagePanelHandlers,
  ImagePanelStyleKeys,
  ImagePanelBaseKeys
> {
  getSettings(): ImagePanelState {
    return {
      styleAttributes: this.styleAttributes,
      baseAttributes: this.baseAttributes,
    }
  }

  getHandlers(): ImagePanelHandlers {
    return {
      setSrc: this.setSrc,
      setResize: this.setResize,
      clearMixed: this.clearMixed,
    }
  }

  private setResize = (value: AttributeImageResize | undefined): void => {
    this.setMulti({}, { 'image.resize': value })
  }

  private setSrc = (src: string, size: Size): void => {
    this.setMulti(
      {
        'image.src': src,
        'image.originalSize.w': size.w,
        'image.originalSize.h': size.h,
      },
      {}
    )
    this.commit()
  }

  private clearMixed = (): void => {
    const nodes = this.getNodes()
    if (nodes.length === 0) return

    let src: string | undefined
    let w: number | undefined
    let h: number | undefined
    for (const node of nodes) {
      src = node.getBaseAttribute('image.src')
      w = node.getBaseAttribute('image.originalSize.w')
      h = node.getBaseAttribute('image.originalSize.h')
      if (src !== undefined && w !== undefined && h !== undefined) break
    }

    this.setMulti(
      {
        'image.src': src,
        'image.originalSize.w': w,
        'image.originalSize.h': h,
      },
      {}
    )
    this.commit()
  }

  protected override getNodeFilterPredicate = (): ((
    node: ReadOnlyNode,
    parent: ReadOnlyNode | null
  ) => boolean) => {
    return (node, _) => allowedTypes.includes(node.getBaseAttribute('type'))
  }
}

const allowedTypes: AttributeType[] = ['image']
