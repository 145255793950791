import { Selection, SelectionPoint } from 'application/textEditor'
import { ShapedCharacter, ShapedRow, ShapedText } from '../../text/shaper/types'
import { Point } from 'application/shapes'

export function getLineAtIndex(shapedText: ShapedText, index: number): number {
  let line = -1
  if (index === 0) return 0
  shapedText.rows.forEach((r, i) => {
    r.characters.forEach((c) => {
      if (c.index === index) {
        line = i
        return
      }
    })
  })
  return line
}

export function getCharacterAtIndex(
  row: ShapedRow,
  index: number
): ShapedCharacter | null {
  let character = null
  row.characters.forEach((c) => {
    if (c.index === index) {
      character = c
      return
    }
  })
  return character
}

export function getIndexByPosition(
  row: ShapedRow,
  x: number
): SelectionPoint | null {
  if (x < row.characters[0].x) {
    return {
      index: row.characters[0].index - 1,
      wrapped: row.characters[0].index > 1,
    }
  }

  for (let i = 0; i < row.characters.length; i++) {
    const c = row.characters[i]
    if (x >= c.x && x <= c.x + c.w) {
      if (x < c.x + c.w / 2) {
        return {
          index: c.index - 1,
          wrapped: i === 0 && c.index > 1,
        }
      } else {
        if (c.newline) {
          return {
            index: c.index - 1,
            wrapped: row.characters.length === 1 && c.index > 1,
          }
        } else {
          return {
            index: c.index,
            wrapped: false,
          }
        }
      }
    }
  }

  const lastChar = row.characters[row.characters.length - 1]
  if (lastChar.newline) {
    return {
      index: lastChar.index - 1,
      wrapped: row.characters.length === 1 && lastChar.index > 1,
    }
  } else {
    return {
      index: lastChar.index,
      wrapped: false,
    }
  }
}

export function belowMinIndex(index: number): boolean {
  return index < 0
}

export function inBounds(shapedText: ShapedText, index: number): boolean {
  return (
    Math.max(
      ...shapedText.rows.map((r) =>
        r.characters.length === 0
          ? -1
          : r.characters[r.characters.length - 1].index
      )
    ) >= index && index >= 0
  )
}

export function getMaxIndex(shapedText: ShapedText): SelectionPoint | null {
  if (shapedText.rows.length === 0) return { index: 0, wrapped: false }

  let index = shapedText.rows.length - 1
  let lastRow = shapedText.rows[index]
  while (lastRow.characters.length === 0 && index > 0) {
    index--
    lastRow = shapedText.rows[index]
  }

  const char = lastRow.characters.slice(-1)[0]
  if (char === undefined) return { index: 0, wrapped: false }

  return {
    index: char.index,
    wrapped:
      (char.newline || char.space || false) &&
      index !== shapedText.rows.length - 1,
  }
}

export function getCharAtPoint(
  shapedText: ShapedText,
  point: Point
): ShapedCharacter | null {
  const row = getLineAtPoint(shapedText, point)
  if (!row) return null

  if (point.x < row.characters[0].x) {
    return row.characters[0]
  } else if (point.x > row.characters.slice(-1)[0].x) {
    return row.characters.slice(-1)[0]
  }

  for (let i = 0; i < row.characters.length; i++) {
    const char = row.characters[i]
    if (
      point.x >= char.x &&
      point.x <= char.x + char.w &&
      point.y >= char.y &&
      point.y <= char.y + char.h
    ) {
      return char
    }
  }

  return null
}

export function getLineAtPoint(
  shapedText: ShapedText,
  point: Point
): ShapedRow | null {
  if (point.y < 0) return shapedText.rows[0]

  let lastLineIndex = shapedText.rows.length - 1
  let lastLine = shapedText.rows[lastLineIndex]
  while (lastLine.characters.length === 0) {
    lastLineIndex--
    lastLine = shapedText.rows[lastLineIndex]
  }
  if (point.y > lastLine.characters.slice(-1)[0].y + lastLine.h) {
    return lastLine
  }

  for (let i = 0; i < shapedText.rows.length; i++) {
    const row = shapedText.rows[i]
    if (
      point.y >= row.characters[0].y &&
      point.y <= row.characters[0].y + row.h
    ) {
      return row
    }
  }

  return null
}

export function findPreviousChar(
  shapedText: ShapedText,
  index: number,
  empty: boolean
): SelectionPoint | null {
  if (index < 1) return { index: 0, wrapped: false }

  let currentRow = shapedText.rows.find((row) =>
    row.characters.some((char) => char.index === index)
  )
  if (!currentRow) return null

  let rowIndex = shapedText.rows.indexOf(currentRow)
  let charIndex = currentRow.characters.findIndex(
    (char) => char.index === index
  )

  while (rowIndex >= 0) {
    while (charIndex > 0) {
      charIndex--
      const char = currentRow.characters[charIndex]
      if (
        (empty && (char.space || char.newline)) ||
        (!empty && !(char.space || char.newline))
      ) {
        return {
          index: char.index,
          wrapped:
            empty &&
            (char.newline || char.space || false) &&
            charIndex === currentRow.characters.length - 1,
        }
      }
    }

    rowIndex--
    if (rowIndex >= 0) {
      currentRow = shapedText.rows[rowIndex]
      charIndex = currentRow.characters.length
    }
  }

  return {
    index: 0,
    wrapped: false,
  }
}

export function findNextChar(
  shapedText: ShapedText,
  index: number,
  empty: boolean
): SelectionPoint | null {
  let currentRow = shapedText.rows.find((row) =>
    row.characters.some((char) => char.index === index)
  )
  if (!currentRow) return null

  let rowIndex = shapedText.rows.indexOf(currentRow)
  let charIndex = currentRow.characters.findIndex(
    (char) => char.index === index
  )

  while (rowIndex < shapedText.rows.length) {
    while (charIndex < currentRow.characters.length - 1) {
      charIndex++
      const char = currentRow.characters[charIndex]
      if (
        (empty && (char.space || char.newline)) ||
        (!empty && !(char.space || char.newline))
      ) {
        const previousLine = shapedText.rows[rowIndex - 1]
        if (!previousLine) {
          return {
            index: char.index - 1,
            wrapped: false,
          }
        }

        const previousChar = previousLine.characters.slice(-1)[0]
        return {
          index: char.index - 1,
          wrapped: !empty && charIndex === 0 && (previousChar.newline || false),
        }
      }
    }

    rowIndex++
    if (rowIndex < shapedText.rows.length) {
      currentRow = shapedText.rows[rowIndex]
      charIndex = -1
    }
  }

  return getMaxIndex(shapedText)
}

export function getParagraphAtIndex(
  shapedText: ShapedText,
  index: number
): number {
  let paragraph = -1
  shapedText.rows.forEach((r, i) => {
    r.characters.forEach((c) => {
      if (c.index === index) {
        paragraph = r.paragraph
        return
      }
    })
  })
  return paragraph
}

export function getParagraph(
  shapedText: ShapedText,
  paragraph: number
): Selection | null {
  let start: SelectionPoint | null = null
  let end: SelectionPoint | null = null

  for (let i = 0; i < shapedText.rows.length; i++) {
    const row = shapedText.rows[i]
    if (row.paragraph === paragraph) {
      if (start === null) {
        start = {
          index: row.characters[0].index - 1,
          wrapped: i > 0,
        }
      }
      if (row.characters.slice(-1)[0].newline) {
        end = {
          index: row.characters.slice(-1)[0].index - 1,
          wrapped: false,
        }
      } else {
        end = {
          index: row.characters.slice(-1)[0].index,
          wrapped: false,
        }
      }
    }
  }
  if (start === null || end === null) return null

  if (start.index === end.index) {
    return {
      focus: {
        index: start.index,
        wrapped: start.index > 0,
      },
      anchor: null,
    }
  }

  return {
    anchor: start,
    focus: end,
  }
}
