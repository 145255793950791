import { IconKey } from 'assets/iconLibrary'
import TitleButtonBlock from 'components/Library/Components/Button/TitleButtonBlock/TitleButtonBlock'
import LayoutInput from 'components/Library/Components/Inputs/LayoutInput/LayoutInput'
import IconBlock from 'components/Library/Components/IconBlock/IconBlock/IconBlock'
import NumberBlock from 'components/Library/Components/NumberBlock/NumberBlock'
import IconSetBlock from 'components/Library/Containers/IconSetBlock/IconSetBlock'
import PanelBlock from 'components/Library/Containers/PanelBlock/PanelBlock'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'
import PanelSection from 'components/Library/Containers/PanelSection/PanelSection'
import { tooltipKey } from 'assets/tooltips'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'
import {
  AttributeFlexDirection,
  AttributeDisplay,
} from 'application/attributes'
import OverrideReset from 'components/Library/Components/Inputs/OverrideReset/OverrideReset'
import Dropdown, {
  dropdownOption,
} from 'components/Library/Components/Dropdown/Dropdown'

export default function DisplaySettings() {
  const display = usePanelHelper(editor.getUI().getDisplayPanel())
  const overrides = usePanelHelper(editor.getUI().getStyleOverridesPanel())
  if (!display.settings || !display.settings.attributes) return <></>

  const { setMode, setDirection, setAlign, setCounter, setGap, slideGap } =
    display.handlers
  const { attributes, modes } = display.settings

  const mode = attributes['display'] || 'block'
  const direction = attributes['flex.direction'] || 'row'
  const align = attributes['flex.align'] || 'start'
  const justify = attributes['flex.justify'] || 'start'
  const gap = attributes['flex.gap'] || 0

  const { hasOverride, sectionHasOverride } = overrides.handlers

  return (
    <PanelSection>
      <PanelRow>
        <TitleButtonBlock
          width={96}
          title={'Display'}
          active={true}
          hasOverride={sectionHasOverride('display')}
        />
        <Dropdown
          width={96}
          mixed={mode === 'Mixed'}
          tooltipKey={'Display'}
          selected={mode}
          options={getFilteredModes(modes)}
          select={(o) => {
            if (o === 'Mixed') return
            setMode(o)
          }}
          commit={true}
          hasOverride={hasOverride(['display'])}
        />
      </PanelRow>
      {mode === 'flex' && (
        <>
          <PanelRow height={64} paddingH={8} paddingV={0}>
            <PanelBlock height={64} direction={'column'} gap={8} paddingV={4}>
              <IconSetBlock>
                {directionModes.map((d) => (
                  <OverrideReset key={d.direction} keys={['flex.direction']}>
                    <IconBlock
                      key={d.direction}
                      icon={d.iconKey}
                      tooltipKey={d.tooltipKey}
                      selected={d.direction === direction}
                      hasOverride={hasOverride(['flex.direction'])}
                      onClick={() => setDirection(d.direction)}
                    />
                  </OverrideReset>
                ))}
              </IconSetBlock>
              <OverrideReset keys={['flex.gap']}>
                <NumberBlock
                  icon={'Gap'}
                  iconRotation={direction === 'column' ? 90 : 0}
                  tooltipKey={'FlexGap'}
                  value={gap}
                  setValue={setGap}
                  increment={(v) => slideGap(v)}
                  decrement={(v) => slideGap(-v)}
                  min={0}
                  hasOverride={hasOverride(['flex.gap'])}
                />
              </OverrideReset>
            </PanelBlock>
            <PanelBlock height={64} paddingL={8} paddingR={4}>
              <OverrideReset keys={['flex.align', 'flex.justify']}>
                <LayoutInput
                  main={align}
                  counter={justify}
                  direction={direction}
                  setAlign={setAlign}
                  hasOverride={hasOverride(['flex.align', 'flex.justify'])}
                />
              </OverrideReset>
            </PanelBlock>
            <PanelBlock
              width={24}
              height={64}
              direction={'column'}
              paddingV={4}
              gap={8}
            >
              <PanelBlock width={24} height={24} />
              <OverrideReset keys={['flex.justify']}>
                <IconBlock
                  icon="SpreadGap"
                  iconRotation={direction === 'column' ? 90 : 0}
                  tooltipKey={'FlexSpaceBetween'}
                  tooltipDirection="left"
                  selected={justify === 'spaced'}
                  hasOverride={hasOverride(['flex.justify'])}
                  onClick={() =>
                    setCounter(justify === 'spaced' ? 'start' : 'spaced')
                  }
                />
              </OverrideReset>
            </PanelBlock>
          </PanelRow>
        </>
      )}
    </PanelSection>
  )
}

const dropdownOptions: dropdownOption<AttributeDisplay>[] = [
  {
    value: 'block',
    text: 'Block',
  },
  {
    value: 'flex',
    text: 'Flex',
  },
  {
    value: 'none',
    text: 'None',
  },
]

function getFilteredModes(modes: AttributeDisplay[]) {
  return dropdownOptions.filter((o) => modes.includes(o.value))
}

const directionModes: {
  direction: AttributeFlexDirection
  tooltipKey: tooltipKey
  iconKey: IconKey
}[] = [
  {
    direction: 'row',
    tooltipKey: 'FlexRow',
    iconKey: 'Row',
  },
  {
    direction: 'column',
    tooltipKey: 'FlexColumn',
    iconKey: 'Column',
  },
  {
    direction: 'wrap',
    tooltipKey: 'FlexRowWrap',
    iconKey: 'Wrap',
  },
]
