import { Properties } from 'application/export/css'
import { TailwindClassRenderer } from '../types'

export class TailwindClassObjectFit implements TailwindClassRenderer {
  render = (properties: Partial<Properties>): string[] => {
    const classes: string[] = []

    const objectFit = properties.objectFit
    switch (objectFit) {
      case 'fill':
        classes.push('object-fill')
        break
      case 'contain':
        classes.push('object-contain')
        break
      case 'cover':
        classes.push('object-cover')
        break
    }

    return classes
  }
}
