import { ListenerType, WebsocketConnection } from './connection'
import { WSCommand } from './types'

interface AuthService {
  getToken(): string
}

export class WebsocketService {
  private connection: WebsocketConnection
  private authService: AuthService

  constructor(
    websocketConnection: WebsocketConnection,
    authService: AuthService
  ) {
    this.connection = websocketConnection
    this.authService = authService
  }

  open = (): void => {
    this.connection.connect()
  }

  close = (): void => {
    this.connection.close()
  }

  isOpen = (): boolean => {
    return this.connection.isOpen()
  }

  addMessageListener = (
    name: string,
    listener: (object?: object) => void
  ): void => {
    this.connection.addListener(ListenerType.ON_MESSAGE, name, listener)
  }

  removeMessageListener = (name: string): void => {
    this.connection.removeListener(ListenerType.ON_MESSAGE, name)
  }

  addConnectFailedListener = (name: string, listener: () => void): void => {
    this.connection.addListener(ListenerType.ON_CONNECT_ERROR, name, listener)
  }

  removeConnectFailedListener = (name: string): void => {
    this.connection.removeListener(ListenerType.ON_CONNECT_ERROR, name)
  }

  addOpenListener = (name: string, listener: () => void): void => {
    this.connection.addListener(ListenerType.ON_OPEN, name, listener)
  }

  removeOpenListener = (name: string): void => {
    this.connection.removeListener(ListenerType.ON_OPEN, name)
  }

  addCloseListener = (name: string, listener: () => void): void => {
    this.connection.addListener(ListenerType.ON_CLOSE, name, listener)
  }

  removeCloseListener = (name: string): void => {
    this.connection.removeListener(ListenerType.ON_CLOSE, name)
  }

  sendCommand = (command: WSCommand): boolean => {
    return this.connection.send({
      ...command,
      token: this.authService.getToken(),
    })
  }
}
