import { CameraService, CanvasSizeService } from 'application/camera'
import { HapticOrder } from 'application/render'
import { ReadOnlyDocumentSelection } from 'application/selection'
import { CanvasDependencies } from './canvasDependencies'
import { Canvas } from './canvas'

export class CanvasFactory {
  create = (
    cameraService: CameraService,
    canvasSizeService: CanvasSizeService,
    documentSelection: ReadOnlyDocumentSelection,
    hapticOrder: HapticOrder
  ): Canvas => {
    const dependencies = new CanvasDependencies(documentSelection, hapticOrder)

    const canvas = dependencies.getCanvas()

    cameraService.subscribe('canvas', canvas)
    canvasSizeService.subscribe('canvas', canvas)

    return canvas
  }
}
