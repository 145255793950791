import { userInfoState } from 'components/Pages/Projects/Projects'
import useBackendService from 'hooks/services/useBackendService'
import { atom, useRecoilState } from 'recoil'
import { loadStripe } from '@stripe/stripe-js'
import useUserService from 'hooks/services/useUserService'
import { editor } from 'index'
import PaymentScreen from 'components/Library/Components/PaymentScreen/PaymentScreen'

export const upgradingState = atom<boolean>({
  key: 'upgrading',
  default: false,
})

export default function UpgradePopup() {
  const [upgrading, setUpgrading] = useRecoilState(upgradingState)
  const [userInfo, setUserInfo] = useRecoilState(userInfoState)
  const { getUserInfo } = useUserService()
  const { startCheckoutSession, startManageSession } = useBackendService()

  if (!upgrading) return null

  const handleClose = () => {
    editor.resume()
    setUpgrading(false)
  }

  const handleUpgrade = async () => {
    const res = await startCheckoutSession()
    if (!res.sessionId) {
      const userInfo = await getUserInfo()
      setUserInfo(userInfo)
      editor.resume()
      return
    }

    const stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY || '')
    if (!stripe) return

    await stripe.redirectToCheckout({ sessionId: res.sessionId })
  }

  const handleDowngrade = async () => {
    const res = await startManageSession()
    if (!res.sessionUrl) {
      const userInfo = await getUserInfo()
      setUserInfo(userInfo)
      editor.resume()
      return
    }

    window.open(res.sessionUrl, '_blank')
  }

  return (
    <PaymentScreen
      onClose={handleClose}
      onUpgrade={handleUpgrade}
      onDowngrade={handleDowngrade}
      upgrading={upgrading}
      userInfo={userInfo}
    />
  )
}
