import { TemplateInfo } from 'application/service'
import TemplateCard from './TemplateCard'
import { useEffect, useRef } from 'react'
import PanelRow from 'components/Library/Containers/PanelRow/PanelRow'

interface TemplatesDisplayProps {
  templates: TemplateInfo[]

  scroll: number
  setScroll: (scroll: number) => void

  drag(template: TemplateInfo): void
}

export default function TemplatesDisplay({
  templates,
  scroll,
  setScroll,
  drag,
}: TemplatesDisplayProps) {
  const scrollRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTo({ top: scroll })
    }
  }, [scroll, scrollRef])

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.addEventListener('scroll', () => {
        if (scrollRef.current) {
          setScroll(scrollRef.current.scrollTop)
        }
      })
    }
  }, [scrollRef, setScroll])

  return (
    <div
      ref={scrollRef}
      style={{
        display: 'flex',
        flexDirection: 'column',
        overflowY: 'scroll',
        flexShrink: 1,
      }}
    >
      {templates.map((template, index) => (
        <PanelRow key={`${template.name}-${index}`} height={'hug'}>
          <TemplateCard
            key={`${template.name}-${index}`}
            template={template}
            drag={() => drag(template)}
          />
        </PanelRow>
      ))}
    </div>
  )
}
