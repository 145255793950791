import {
  NodeCreateAction,
  NodeSelectionAction,
  QuadTreeNodeFilter,
} from 'application/action'
import { CoordinatesConversion } from 'application/camera'
import { CommandHandler } from 'application/client'
import { Point, Rectangle, Size } from 'application/shapes'
import {
  Action,
  ActionEventResult,
  ActionHandler,
  Done,
  DoneNext,
  DonePassthrough,
  NotDone,
} from '../types'
import { Cursor } from 'application/cursor'
import {
  AttributeType,
  canTypeInsertInto,
  isInteractable,
} from 'application/attributes'
import { HapticDrawingWindow } from 'editor/haptic/drawingWindow'
import { HapticChildLine } from 'editor/haptic/childLine'
import { HapticNewParentWindow } from 'editor/haptic/newParentWindow'
import { ReadOnlyDocument } from 'application/document'
import { ChildLineComputer } from '../childLine/computer'
import { QuadTree } from 'application/quadtree'
import { isCanvasClosest } from 'application/browser'

export const hotkeyToAttributeType: { [key: string]: AttributeType } = {
  a: 'anchor',
  A: 'anchor',
  f: 'frame',
  F: 'frame',
  p: 'page',
  P: 'page',
  t: 'paragraph',
  T: 'paragraph',
  b: 'button',
  B: 'button',
  i: 'image',
  I: 'image',
  n: 'input',
  N: 'input',
  o: 'form',
  O: 'form',
}

export class DrawAction implements ActionHandler {
  private commandHandler: CommandHandler
  private document: ReadOnlyDocument
  private create: NodeCreateAction
  private select: NodeSelectionAction
  private coordinates: CoordinatesConversion
  private quadtree: QuadTree
  private hapticWindow: HapticDrawingWindow
  private hapticChildLine: HapticChildLine
  private hapticNewParent: HapticNewParentWindow
  private lineComputer: ChildLineComputer
  private type: AttributeType
  private keyUp: boolean
  private point: Point | null

  constructor(
    commandHandler: CommandHandler,
    document: ReadOnlyDocument,
    create: NodeCreateAction,
    select: NodeSelectionAction,
    coordinates: CoordinatesConversion,
    quadtree: QuadTree,
    hapticWindow: HapticDrawingWindow,
    hapticChildLine: HapticChildLine,
    hapticNewParent: HapticNewParentWindow,
    lineComputer: ChildLineComputer,
    type: AttributeType,
    hotkey: boolean,
    point: Point | null = null
  ) {
    this.commandHandler = commandHandler
    this.document = document
    this.create = create
    this.select = select
    this.coordinates = coordinates
    this.quadtree = quadtree
    this.hapticWindow = hapticWindow
    this.hapticChildLine = hapticChildLine
    this.hapticNewParent = hapticNewParent
    this.lineComputer = lineComputer
    this.type = type
    this.keyUp = !hotkey
    this.point = point
  }

  init = () => {
    if (this.point) this.handlePointUpdate(this.point)
  }

  getType = (): Action => {
    switch (this.type) {
      case 'page':
        return 'drawPage'
      case 'paragraph':
        return 'drawParagraph'
      case 'button':
        return 'drawButton'
      case 'anchor':
        return 'drawAnchor'
      case 'image':
        return 'drawImage'
      case 'input':
        return 'drawInput'
      case 'form':
        return 'drawForm'
      case 'content':
        return 'drawContent'
      default:
        return 'drawFrame'
    }
  }

  getCursor = (): Cursor => {
    switch (this.type) {
      case 'page':
        return 'drawPage'
      case 'anchor':
        return 'drawAnchor'
      case 'paragraph':
        return 'drawText'
      case 'button':
        return 'drawButton'
      case 'image':
        return 'drawImage'
      case 'input':
        return 'drawInput'
      case 'form':
        return 'drawForm'
      case 'content':
        return 'drawText'
      default:
        return 'drawFrame'
    }
  }

  onMouseDown = (): ActionEventResult => {
    return NotDone
  }

  onMouseMove = (e: MouseEvent): ActionEventResult => {
    if (!isCanvasClosest(e)) {
      this.clearHaptics()
      return NotDone
    }

    const point = this.coordinates.get(e)
    this.handlePointUpdate(point)
    return NotDone
  }

  onMouseUp = (e: MouseEvent): ActionEventResult => {
    this.clearHaptics()

    if (!isCanvasClosest(e)) return NotDone

    const point = this.coordinates.get(e)
    const newId = this.create.click(this.type, point)
    if (!newId) return NotDone

    this.select.selectNodes([newId], true)

    switch (this.type) {
      case 'paragraph':
      case 'content':
      case 'button':
      case 'anchor':
        return DoneNext('editText')
      default:
        this.commandHandler.handle({ type: 'commit' })
        return Done
    }
  }

  onKeyDown = (e: KeyboardEvent): ActionEventResult => {
    if (!this.keyUp) return NotDone
    if (['v', 'V', 'Escape'].includes(e.key)) {
      this.clearHaptics()
      return Done
    }

    const type = hotkeyToAttributeType[e.key]
    if (!type) return NotDone

    if (type === this.type) {
      this.clearHaptics()
      return Done
    }

    return DonePassthrough
  }

  onKeyUp = (): ActionEventResult => {
    this.keyUp = true
    return NotDone
  }

  onWheel = (e: WheelEvent): ActionEventResult => {
    if (!isCanvasClosest(e)) {
      this.clearHaptics()
      return NotDone
    }
    const point = this.coordinates.get(e)
    this.handlePointUpdate(point)
    return NotDone
  }

  private handlePointUpdate = (point: Point) => {
    const parent = this.getParent(point)
    if (!parent) {
      this.clearNewParent()
      this.clearLine()
    } else {
      this.setNewParent(parent)
      this.setLine(parent, point)
    }
    this.setWindow(point)
  }

  private setWindow = (point: Point) => {
    const rectangle = this.getRectangle(point, false)
    if (!rectangle) {
      this.clearWindow()
      return
    }

    this.hapticWindow.setRectangle(rectangle, 'rectangle')
  }

  private setLine = (target: string, point: Point): void => {
    const targetNode = this.document.getNode(target)
    if (!targetNode || targetNode.getBaseAttribute('type') === 'canvas') {
      this.clearLine()
      return
    }

    const window = this.getRectangle(point, true)
    if (!window) {
      this.clearLine()
      return
    }

    const rectangle = this.lineComputer.compute(target, point, window)
    if (!rectangle) {
      this.clearLine()
    } else {
      this.hapticChildLine.setLine(rectangle)
    }
  }

  private setNewParent = (target: string): void => {
    this.hapticNewParent.setNewParent(target)
  }

  private clearHaptics = () => {
    this.clearNewParent()
    this.clearLine()
    this.clearWindow()
  }

  private clearWindow = () => {
    this.hapticWindow.setRectangle(null, 'rectangle')
  }

  private clearLine = (): void => {
    this.hapticChildLine.setLine(null)
  }

  private clearNewParent = (): void => {
    this.hapticNewParent.setNewParent(null)
  }

  private getParent = (point: Point): string | null => {
    const rectanglesAtPoint = this.quadtree.getAtPoint(
      point,
      QuadTreeNodeFilter
    )
    const filtered = this.filterParents(rectanglesAtPoint.map((r) => r.id))
    if (filtered.length === 0) return null
    return filtered[0]
  }

  private filterParents = (ids: string[]): string[] => {
    return ids.filter((id) => {
      const node = this.document.getNode(id)
      if (!node) return false

      return (
        isInteractable(node) &&
        canTypeInsertInto(this.type, node.getBaseAttribute('type'))
      )
    })
  }

  private getRectangle = (point: Point, line: boolean): Rectangle | null => {
    const size = this.getSize(line)
    if (!size) return null

    return {
      x: point.x - size.w / 2,
      y: point.y - size.h / 2,
      w: size.w,
      h: size.h,
    }
  }

  private getSize = (line: boolean): Size | null => {
    switch (this.type) {
      case 'page':
        return { w: 1440, h: 1080 }
      case 'anchor':
      case 'paragraph':
      case 'content':
        if (line) return { w: 50, h: 50 }
        return null
      case 'button':
        return { w: 70, h: 40 }
      case 'input':
        return { w: 275, h: 40 }
      case 'image':
      case 'frame':
      case 'form':
        return { w: 100, h: 100 }
      default:
        return null
    }
  }
}
