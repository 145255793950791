import ImageUpload from '../Sections/ImageUpload'
import useCommit from 'hooks/editor/useCommit'
import { AttributeFill } from 'application/attributes'

interface ImageTabProps {
  fill: AttributeFill
  updateFill: (fill: AttributeFill) => void
}

export default function ImageTab({ fill, updateFill }: ImageTabProps) {
  const commitUpdate = useCommit()

  if (!fill.image) return <></>

  return (
    <ImageUpload
      fill={fill}
      updateFill={(f) => {
        updateFill(f)
        commitUpdate()
      }}
    />
  )
}
