export class PositionContext {
  vy: number
  vx: number
  vw: number
  vh: number
  top: number
  right: number
  bottom: number
  left: number
  x: number
  y: number

  newOffsetX = (x: number): PositionContext => {
    const context = this.clone()
    context.x = this.x + x
    return context
  }

  newOffsetY = (y: number): PositionContext => {
    const context = this.clone()
    context.y = this.y + y
    return context
  }

  constructor() {
    this.vy = 0
    this.vx = 0
    this.vw = 0
    this.vh = 0
    this.top = 0
    this.left = 0
    this.right = 0
    this.bottom = 0
    this.x = 0
    this.y = 0
  }

  clone = (): PositionContext => {
    const context = new PositionContext()
    context.x = this.x
    context.y = this.y
    context.top = this.top
    context.left = this.left
    context.right = this.right
    context.bottom = this.bottom
    return context
  }
}

export class PositionContextBuilder {
  positionContext: PositionContext

  static create(
    positionContext: PositionContext = new PositionContext()
  ): PositionContextBuilder {
    return new PositionContextBuilder(positionContext)
  }

  private constructor(positionContext: PositionContext) {
    this.positionContext = positionContext.clone()
    this.positionContext.vy = positionContext.vy
    this.positionContext.vx = positionContext.vx
    this.positionContext.vw = positionContext.vw
    this.positionContext.vh = positionContext.vh
    this.positionContext.top = positionContext.top
    this.positionContext.left = positionContext.left
    this.positionContext.right = positionContext.right
    this.positionContext.bottom = positionContext.bottom
    this.positionContext.x = positionContext.x
    this.positionContext.y = positionContext.y
  }

  withVW = (vw: number): PositionContextBuilder => {
    this.positionContext.vw = vw
    return this
  }

  withVH = (vh: number): PositionContextBuilder => {
    this.positionContext.vh = vh
    return this
  }

  withTop = (top: number): PositionContextBuilder => {
    this.positionContext.top = top
    return this
  }

  withLeft = (left: number): PositionContextBuilder => {
    this.positionContext.left = left
    return this
  }

  withRight = (right: number): PositionContextBuilder => {
    this.positionContext.right = right
    return this
  }

  withBottom = (bottom: number): PositionContextBuilder => {
    this.positionContext.bottom = bottom
    return this
  }

  withX = (x: number): PositionContextBuilder => {
    this.positionContext.x = x
    return this
  }

  withY = (y: number): PositionContextBuilder => {
    this.positionContext.y = y
    return this
  }

  withBlock = (
    top: number,
    left: number,
    right: number,
    bottom: number
  ): PositionContextBuilder => {
    this.positionContext.top = top
    this.positionContext.left = left
    this.positionContext.right = right
    this.positionContext.bottom = bottom
    return this
  }

  build = (): PositionContext => {
    return this.positionContext
  }
}
