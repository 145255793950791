import { DesignColor } from 'themes'
import { rgbaStringToRgba, rgbaToHex } from './conversion'
import { Color } from './types'

export const glClearColors = {
  transparent: { r: 0, g: 0, b: 0, a: 0 },
  default: rgbaStringToRgba(DesignColor('canvasBackground')),
}

export function createNewColor(): Color {
  return { r: 0, g: 0, b: 0, a: 1 }
}

export function isColorEqual(color1: Color, color2: Color) {
  const hex1 = rgbaToHex(color1)
  const hex2 = rgbaToHex(color2)
  return hex1 === hex2
}

const colorStrings: { [key: string]: string } = {
  red: '#FF0000',
  orange: '#FFA500',
  yellow: '#FFFF00',
  green: '#008000',
  blue: '#0000FF',
  purple: '#800080',
  pink: '#FFC0CB',
  black: '#000000',
  white: '#FFFFFF',
  gray: '#808080',
  brown: '#A52A2A',
  yeller: '#FFFF00',
  gween: '#008000',
}

export function parseHexInput(input: string): {
  value: string
  valid: boolean
} {
  if (isValidRgbString(input)) {
    const rgb = parseRgbString(input)
    return { value: rgbToHex(rgb.r, rgb.g, rgb.b, 1), valid: true }
  }
  if (isValidRgbaString(input)) {
    const rgba = parseRgbaString(input)
    return { value: rgbToHex(rgba.r, rgba.g, rgba.b, rgba.a), valid: true }
  }
  if (isValidHex(input)) return { value: input, valid: true }
  if (colorStrings[input.toLowerCase()]) {
    return { value: colorStrings[input.toLowerCase()], valid: true }
  }
  return { value: input, valid: false }
}

export function rgbToHex(r: number, g: number, b: number, a: number): string {
  let hex = ''
  for (let value of [r, g, b]) {
    let hexValue = value.toString(16)
    hexValue = hexValue.length < 2 ? '0' + hexValue : hexValue
    hex += hexValue
  }
  if (a !== 1) {
    hex += Math.round(a * 255)
      .toString(16)
      .padStart(2, '0')
  }
  return hex.toUpperCase()
}

function isValidHex(hex: string) {
  const hexRegex =
    /^(#)?([0-9A-F]{1}|[0-9A-F]{2}|[0-9A-F]{3}|[0-9A-F]{4}|[0-9A-F]{5}|[0-9A-F]{6}|[0-9A-F]{8})$/i
  return hexRegex.test(hex)
}

function isValidRgbaString(rgba: string) {
  const rgbaRegex = /rgba?\((\d+),\s*(\d+),\s*(\d+),\s*(\d*\.?\d+)\)/
  return rgbaRegex.test(rgba)
}

function parseRgbaString(rgba: string) {
  const match = rgba.match(/rgba?\((\d+),\s*(\d+),\s*(\d+),\s*(\d*\.?\d+)\)/)
  if (!match) return { r: 0, g: 0, b: 0, a: 1 }
  return {
    r: parseInt(match[1], 10),
    g: parseInt(match[2], 10),
    b: parseInt(match[3], 10),
    a: parseFloat(match[4]),
  }
}

function isValidRgbString(rgb: string) {
  const rgbRegex = /rgb\((\d+),\s*(\d+),\s*(\d+)\)/
  return rgbRegex.test(rgb)
}

function parseRgbString(rgb: string) {
  const match = rgb.match(/rgb\((\d+),\s*(\d+),\s*(\d+)\)/)
  if (!match) return { r: 0, g: 0, b: 0 }
  return {
    r: parseInt(match[1], 10),
    g: parseInt(match[2], 10),
    b: parseInt(match[3], 10),
  }
}
