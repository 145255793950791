import { DesignColor } from 'themes'
import {
  dropdownWidth,
  panelBorderFull,
  panelPosition,
  panelShadow,
} from '../constants'

interface DropdownDrawerContainerProps {
  drawerRef: React.RefObject<HTMLDivElement>

  position?: panelPosition
  width?: dropdownWidth

  children?: React.ReactNode

  onMouseEnter?: () => void
  onMouseLeave?: () => void
}

export default function DropdownDrawerContainer({
  drawerRef,
  position = {},
  width = 96,
  children,
  onMouseEnter,
  onMouseLeave,
}: DropdownDrawerContainerProps) {
  return (
    <div
      className={'popup'}
      ref={drawerRef}
      style={{
        ...position,
        width: width,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'start',
        gap: 4,
        borderRadius: 4,
        background: DesignColor('dropdownBackground'),
        boxShadow: [panelBorderFull, panelShadow].join(', '),
        pointerEvents: 'auto',
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </div>
  )
}
