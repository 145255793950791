import { DesignColor } from 'themes'
import Icon from '../Components/Icon/Icon/Icon'
import { ProjectTab, userInfoState } from 'components/Pages/Projects/Projects'
import { useRecoilValue } from 'recoil'

export interface ProjectTopbarProps {
  activeTab: ProjectTab
}

export default function ProjectTopbar({ activeTab }: ProjectTopbarProps) {
  const title = activeTab === 'Projects' ? 'Projects' : 'Settings'
  const userInfo = useRecoilValue(userInfoState)

  return (
    <div
      style={{
        width: '100%',
        height: '64px',
        backgroundColor: DesignColor('panelBackground'),
        flexShrink: 0,
        display: 'flex',
        borderBottom: `1px solid ${DesignColor('panelBorder')}`,
      }}
    >
      <a
        href="https://repaint.com"
        style={{
          width: 232,
          padding: '0 24px',
          boxSizing: 'border-box',
          flexShrink: 0,
          display: 'flex',
          alignItems: 'center',
          gap: '6px',
          cursor: 'pointer',
          borderRight: `1px solid ${DesignColor('panelBorder')}`,
        }}
      >
        <Icon icon="RepaintLogo" size={24} color={DesignColor('text1')} />
        <h2
          style={{
            fontSize: 20,
            fontFamily: 'Poppins',
            fontWeight: 400,
            color: DesignColor('text1'),
          }}
        >
          Repaint
        </h2>
      </a>
      <div
        style={{
          width: '100%',
          padding: '0 24px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <h2
          style={{
            fontSize: 20,
            fontFamily: 'inter',
            fontWeight: 'bold',
            color: DesignColor('text1'),
          }}
        >
          {title}
        </h2>
        <div
          style={{
            width: '32px',
            height: '32px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 12,
            background: DesignColor('grayInputTint'),
            borderRadius: '50%',
          }}
        >
          <h3
            style={{
              fontSize: 14,
              fontWeight: 400,
              color: DesignColor('text1'),
            }}
          >
            {userInfo.firstName.substring(0, 1).toUpperCase()}
            {userInfo.lastName.substring(0, 1).toUpperCase()}
          </h3>
        </div>
      </div>
    </div>
  )
}
