import { useEffect, useState } from 'react'

interface PerformanceMemory {
  usedJSHeapSize: number
  totalJSHeapSize: number
  jsHeapSizeLimit: number
}

export default function useMemory(precision: number): number | undefined {
  const [memory, setMemory] = useState<number | undefined>(undefined)

  useEffect(() => {
    const fpsLoop = setInterval(() => {
      if (typeof performance !== 'undefined') {
        const perf = performance as Performance & {
          memory?: PerformanceMemory
        }
        if (perf.memory) {
          const mb = perf.memory.totalJSHeapSize / 1024 / 1024
          const rounded = Math.round(mb * precision * 10) / (precision * 10)
          setMemory(rounded)
        }
      }
    }, 200)

    return () => {
      clearInterval(fpsLoop)
    }
  }, [precision])

  return memory
}
