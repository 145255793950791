import { Properties } from 'application/export/css'
import { TailwindClassRenderer } from '../types'

export class TailwindClassPosition implements TailwindClassRenderer {
  render = (properties: Partial<Properties>): string[] => {
    const position = properties.position
    switch (position) {
      case 'static':
        return ['static']
      case 'relative':
        return ['relative']
      case 'absolute':
        return ['absolute']
      case 'fixed':
        return ['fixed']
      case 'sticky':
        return ['sticky']
      default:
        return []
    }
  }
}
