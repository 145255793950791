import { Style } from '../css'
import { HTMLDocument } from '../html/types'
import { createSelector } from '../utils'
import { AttributeTransformer } from './types'
import { ReadOnlyNode } from 'application/node'
import { AttributeBorderSide } from 'application/attributes'
import { Color } from 'application/color'

export class BorderStyle implements AttributeTransformer {
  transformBase = (node: ReadOnlyNode, htmlDocument: HTMLDocument): void => {
    const side = node.getDefaultSelector().styles['border.side']
    const color = node.getDefaultSelector().styles['border.color']
    const top = node.getDefaultSelector().styles['border.top.px']
    const bottom = node.getDefaultSelector().styles['border.bottom.px']
    const left = node.getDefaultSelector().styles['border.left.px']
    const right = node.getDefaultSelector().styles['border.right.px']
    if (color === undefined || side === undefined) return

    const selector = createSelector(node.getId(), 'default')
    const style = htmlDocument.getStyles().get(selector)

    this.addBorderSides(
      side,
      top || 0,
      bottom || 0,
      left || 0,
      right || 0,
      color,
      style
    )
  }

  private addBorderSides(
    side: AttributeBorderSide,
    top: number,
    bottom: number,
    left: number,
    right: number,
    color: Color,
    style: Style
  ): void {
    switch (side) {
      case 'custom':
      case 'all':
        if (top === bottom && bottom === left && left === right) {
          style.setProperty('border', [
            'solid',
            { unit: 'px', value: top },
            color,
          ])
        } else {
          style.setProperty('borderTop', [
            'solid',
            { unit: 'px', value: top },
            color,
          ])
          style.setProperty('borderRight', [
            'solid',
            { unit: 'px', value: right },
            color,
          ])
          style.setProperty('borderBottom', [
            'solid',
            { unit: 'px', value: bottom },
            color,
          ])
          style.setProperty('borderLeft', [
            'solid',
            { unit: 'px', value: left },
            color,
          ])
        }
        break
      case 'top':
        style.setProperty('borderTop', [
          'solid',
          { unit: 'px', value: top },
          color,
        ])
        break
      case 'right':
        style.setProperty('borderRight', [
          'solid',
          { unit: 'px', value: right },
          color,
        ])
        break
      case 'bottom':
        style.setProperty('borderBottom', [
          'solid',
          { unit: 'px', value: bottom },
          color,
        ])
        break
      case 'left':
        style.setProperty('borderLeft', [
          'solid',
          { unit: 'px', value: left },
          color,
        ])
        break
    }
  }
}
