import { DesignColor } from 'themes'
import { Camera } from 'application/camera'
import { rgbaStringToRgba } from 'application/color'
import {
  Context,
  Renderable,
  WebglLine,
  WebglLineData,
} from 'application/render'
import { FlatLine } from 'application/shapes'

export class ConstraintLinesTransformer {
  static transform = (
    context: Context,
    lines: FlatLine[],
    camera: Camera
  ): Renderable[] => {
    const renderableLines: Renderable[] = []

    for (const line of lines) {
      const data: WebglLineData = {
        lines: [],
        thickness: 1 / camera.z,
        color: rgbaStringToRgba(DesignColor('inputHighlight')),
        dashed: true,
        dashLength: Math.max(2 / camera.z, 2),
      }

      switch (line.direction) {
        case 'h':
          data.lines.push({
            start: { x: line.p1, y: line.counter },
            end: { x: line.p2, y: line.counter },
          })
          break
        case 'v':
          data.lines.push({
            start: { x: line.counter, y: line.p1 },
            end: { x: line.counter, y: line.p2 },
          })
          break
      }

      const renderable = new WebglLine(context, data)
      renderable.init()
      renderableLines.push(renderable)
    }

    return renderableLines
  }
}
