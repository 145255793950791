import { ReadOnlyNode } from 'application/node'
import { HTMLDocument } from '../html/types'
import { createSelector, formatNodeId } from '../utils'
import { AttributeTransformer } from './types'

export class ImageStyle implements AttributeTransformer {
  transformBase = (node: ReadOnlyNode, htmlDocument: HTMLDocument): void => {
    const selector = createSelector(node.getId(), 'default')
    const style = htmlDocument.getStyles().get(selector)

    const element = htmlDocument.getById(formatNodeId(node.getId()))
    if (!element) return

    const baseStyles = node.getBaseAttributes()
    const styles = node.getDefaultSelector().styles

    const src = baseStyles['image.src']
    if (src === undefined) return

    element.setAttribute('src', src)

    const resize = styles['image.resize']
    switch (resize) {
      case 'fill':
        style.setProperty('objectFit', 'cover')
        break
      case 'fit':
        style.setProperty('objectFit', 'contain')
        break
      case 'stretch':
      case undefined:
        style.setProperty('objectFit', 'fill')
        break
    }

    style.setProperty('backgroundPosition', 'center')
  }
}
