import useToast from 'hooks/editor/useToast'
import { editor } from 'index'
import { useCallback, useEffect, useState } from 'react'
import { v4 } from 'uuid'
import useNavigatePage from './useNavigatePage'

type ConnectedState = 'connected' | 'connecting' | 'disconnected' | 'leaving'

export default function useWebsocket(): boolean {
  const toast = useToast()
  const { toProjects } = useNavigatePage()
  const { cancel } = editor.getUI().getActionInitiator()
  const { reloadProject } = editor.getUI().getProjectService()
  const {
    open,
    subscribeConnect,
    unsubscribeConnect,
    subscribeDisconnect,
    unsubscribeDisconnect,
    subscribeError,
    unsubscribeError,
  } = editor.getUI().getWebsocketService()

  const [state, setState] = useState<ConnectedState>('disconnected')
  const [visible, setVisible] = useState(true)

  const handleTabVisibilityChange = useCallback(() => {
    setVisible(document.visibilityState === 'visible')
  }, [])

  const handleError = useCallback(() => {
    setState('leaving')
    toast('Leaving editor due to being offline.', 'error')
    toProjects()
  }, [toast, toProjects])

  const handleDisconnect = useCallback(() => {
    if (state === 'leaving') return
    setState('disconnected')
  }, [state])

  const handleConnect = useCallback(async () => {
    if (state === 'leaving' || state === 'connected') return
    cancel()
    await reloadProject()
    setState('connected')
  }, [state, cancel, reloadProject])

  useEffect(() => {
    const listenerKey = v4()

    subscribeError(listenerKey, handleError)
    subscribeDisconnect(listenerKey, handleDisconnect)
    subscribeConnect(listenerKey, handleConnect)

    return () => {
      unsubscribeConnect(listenerKey)
      unsubscribeDisconnect(listenerKey)
      unsubscribeError(listenerKey)
    }
  }, [
    subscribeError,
    subscribeDisconnect,
    subscribeConnect,
    unsubscribeConnect,
    unsubscribeDisconnect,
    unsubscribeError,
    handleError,
    handleConnect,
    handleDisconnect,
  ])

  useEffect(() => {
    if (visible && state === 'disconnected') open()
  }, [open, state, visible])

  useEffect(() => {
    document.addEventListener('visibilitychange', handleTabVisibilityChange)
    return () => {
      document.removeEventListener(
        'visibilitychange',
        handleTabVisibilityChange
      )
    }
  }, [handleTabVisibilityChange])

  return state === 'connected'
}
