import { ReadOnlyNode } from 'application/node'
import { editor } from 'index'
import { useEffect, useState } from 'react'
import { v4 } from 'uuid'

export default function useSelected() {
  const ui = editor.getUI()
  const [selected, setSelected] = useState<ReadOnlyNode[]>([])

  useEffect(() => {
    const key = v4()
    ui.subscribeToSelection(key, {
      onSelection(nodes: ReadOnlyNode[]) {
        setSelected(nodes)
      },
    })

    return () => {
      ui.unsubscribeFromSelection(key)
    }
  })

  return selected
}
