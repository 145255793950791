import { CodeExporter } from 'editor/export/code'
import { AttributePanel } from './attributePanel'
import { ReadOnlyDocumentSelection } from 'application/selection'
import { CommandHandler } from 'application/client'
import { NodeAttributesAction } from 'application/action/attributes'
import { ReadOnlyDocument } from 'application/document'
import { CodeBlockType, CodeExport } from 'application/export'
import { UserService } from 'application/service'

type CodePanelBaseKeys = never

type CodePanelStyleKeys = never

export type CodePanelState = {
  styleMode: CodeBlockType
  exportMode: CodeBlockType
  export: CodeExport | null
  premium: boolean
}

export type CodePanelHandlers = {
  setStyleMode: (mode: CodeBlockType) => void
  setExportMode: (mode: CodeBlockType) => void
  load: () => void
}

export class CodePanel extends AttributePanel<
  CodePanelState,
  CodePanelHandlers,
  CodePanelStyleKeys,
  CodePanelBaseKeys
> {
  private exporter: CodeExporter
  private userService: UserService

  private styleMode: CodeBlockType
  private exportMode: CodeBlockType
  private premium: boolean

  constructor(
    commandHandler: CommandHandler,
    action: NodeAttributesAction,
    document: ReadOnlyDocument,
    documentSelection: ReadOnlyDocumentSelection,
    exporter: CodeExporter,
    userService: UserService
  ) {
    super(commandHandler, action, document, documentSelection)
    this.exporter = exporter
    this.styleMode = this.getStoredMode('styleMode', 'css')
    this.exportMode = this.getStoredMode('exportMode', 'html')
    this.userService = userService
    this.premium = false
    this.load()
  }

  getSettings = (): CodePanelState => {
    const codeExport = this.getExport()
    return {
      styleMode: this.styleMode,
      exportMode: this.exportMode,
      export: codeExport,
      premium: this.premium,
    }
  }

  getHandlers = (): CodePanelHandlers => {
    return {
      setStyleMode: this.setStyleMode,
      setExportMode: this.setExportMode,
      load: this.load,
    }
  }

  private setStyleMode = (mode: CodeBlockType): void => {
    this.styleMode = mode
    localStorage.setItem('styleMode', mode)
    this.notifyListeners()
  }

  private setExportMode = (mode: CodeBlockType): void => {
    this.exportMode = mode
    localStorage.setItem('exportMode', mode)
    this.notifyListeners()
  }

  private getExport = (): CodeExport | null => {
    const selected = this.getNodes()
    if (selected.length === 0) return null
    return this.exporter.export(
      selected[0].getId(),
      this.styleMode,
      this.exportMode
    )
  }

  private load = (): void => {
    this.userService.getUserInfo().then((userInfo) => {
      this.premium = userInfo.premium || false
      this.notifyListeners()
    })
  }

  private getStoredMode(
    key: string,
    defaultValue: CodeBlockType
  ): CodeBlockType {
    const storedValue = localStorage.getItem(key)
    return (storedValue as CodeBlockType) || defaultValue
  }
}
