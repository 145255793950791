import { HistoryEntry } from './types'

export class HistoryLog {
  private log: HistoryEntry[]
  private index: number

  constructor() {
    this.log = []
    this.index = -1
  }

  add = (entry: HistoryEntry): void => {
    this.log = this.log.slice(0, this.index + 1)
    this.log.push(entry)
    this.index++
  }

  clear = (): void => {
    this.log = []
    this.index = -1
  }

  undo = (): HistoryEntry | null => {
    if (this.index === -1) return null

    const entry = this.log[this.index]
    this.index--

    return entry
  }

  redo = (): HistoryEntry | null => {
    if (this.index === this.log.length - 1) return null

    this.index++
    const entry = this.log[this.index]

    return entry
  }

  canUndo = (): boolean => {
    return this.index !== -1
  }

  canRedo = (): boolean => {
    return this.index !== this.log.length - 1
  }

  reset = (): void => {
    this.log = []
    this.index = -1
  }
}
