import { TextCommand } from './command/types'
import { State } from './state/types'
import { newEmptyState } from './state/data'

export interface CommandHandler {
  handle: (command: TextCommand) => void
}

export interface EditorState {
  get: () => State
  set: (state: State) => void
}

export class TextEditor {
  private id: string | null
  private handlers: CommandHandler[]
  private state: EditorState

  constructor(state: EditorState) {
    this.handlers = []
    this.id = null
    this.state = state
  }

  registerHandler = (handler: CommandHandler) => {
    this.handlers.push(handler)
  }

  getId = (): string | null => {
    return this.id
  }

  getState = (): State => {
    return this.state.get()
  }

  setId = (id: string | null): void => {
    this.id = id
  }

  setState = (state: State): void => {
    this.state.set(state)
  }

  clearState = (): void => {
    this.setState(newEmptyState())
  }

  handleCommand = (command: TextCommand): void => {
    for (const handler of this.handlers) {
      handler.handle(command)
    }
  }
}
