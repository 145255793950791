import _ from 'lodash'
import { Layout } from '../layout/layout'
import { CommandHandler, EditorState } from '../textEditor'
import { TextCommand, TextCommandArrow } from '../command/types'
import { Selection } from '../types'
import { State } from '../state/types'
import { Shaper, ShapedText } from 'application/text'

export class ArrowHandler implements CommandHandler {
  private state: EditorState
  private layout: Layout
  private shaper: Shaper

  constructor(state: EditorState, layout: Layout, shaper: Shaper) {
    this.state = state
    this.layout = layout
    this.shaper = shaper
  }

  handle = (command: TextCommand): void => {
    switch (command.type) {
      case 'arrow':
        this.handleArrow(command)
        break
    }
  }

  private handleArrow = (command: TextCommandArrow): void => {
    const state = this.state.get()

    this.moveSelection(state, command)

    this.state.set(state)
  }

  private moveSelection = (
    currentState: State,
    command: TextCommandArrow
  ): void => {
    const { selection, content } = currentState
    const shapedText = this.shaper.getShapedText(content, currentState.width)

    switch (command.parameters.mode) {
      case 'right':
        this.moveRight(selection, shapedText, command)
        break
      case 'left':
        this.moveLeft(selection, shapedText, command)
        break
      case 'up':
        this.moveUp(selection, shapedText, command)
        break
      case 'down':
        this.moveDown(selection, shapedText, command)
        break
    }
  }

  private moveLeft = (
    selection: Selection,
    shapedText: ShapedText,
    command: TextCommandArrow
  ): void => {
    if (!command.parameters.shift && selection.anchor !== null) {
      this.sortSelection(selection)
      selection.focus = _.cloneDeep(selection.anchor)
      selection.anchor = null
      return
    }

    if (command.parameters.meta) {
      this.moveToStartOfLine(selection, shapedText, command)
      return
    }

    const newFocus = this.layout.getIndexBefore(shapedText, selection.focus)
    if (newFocus === null || _.isEqual(newFocus, selection.focus)) return

    if (command.parameters.shift && selection.anchor === null) {
      selection.anchor = _.cloneDeep(selection.focus)
    }

    selection.focus = newFocus
  }

  private moveRight = (
    selection: Selection,
    shapedText: ShapedText,
    command: TextCommandArrow
  ): void => {
    if (!command.parameters.shift && selection.anchor !== null) {
      this.sortSelection(selection)
      selection.anchor = null
      return
    }

    if (command.parameters.meta) {
      this.moveToEndOfLine(selection, shapedText, command)
      return
    }

    const newFocus = this.layout.getIndexAfter(shapedText, selection.focus)
    if (newFocus === null || _.isEqual(newFocus, selection.focus)) return

    if (command.parameters.shift && selection.anchor === null) {
      selection.anchor = _.cloneDeep(selection.focus)
    }

    selection.focus = newFocus
  }

  private moveUp = (
    selection: Selection,
    shapedText: ShapedText,
    command: TextCommandArrow
  ): void => {
    if (!command.parameters.shift && selection.anchor !== null) {
      this.sortSelection(selection)
      selection.focus = _.cloneDeep(selection.anchor)
      selection.anchor = null
    }

    if (command.parameters.meta) {
      this.moveToStartOfContent(selection, command)
      return
    }

    const newFocus = this.layout.getIndexAbove(shapedText, selection.focus)
    if (newFocus === null || _.isEqual(newFocus, selection.focus)) return

    if (command.parameters.shift && selection.anchor === null) {
      selection.anchor = _.cloneDeep(selection.focus)
    }

    selection.focus = newFocus
  }

  private moveDown = (
    selection: Selection,
    shapedText: ShapedText,
    command: TextCommandArrow
  ): void => {
    if (!command.parameters.shift && selection.anchor !== null) {
      this.sortSelection(selection)
      selection.anchor = null
    }

    if (command.parameters.meta) {
      this.moveToEndOfContent(selection, shapedText, command)
      return
    }

    const newFocus = this.layout.getIndexBelow(shapedText, selection.focus)
    if (newFocus === null || _.isEqual(newFocus, selection.focus)) return

    if (command.parameters.shift && selection.anchor === null) {
      selection.anchor = _.cloneDeep(selection.focus)
    }

    selection.focus = newFocus
  }

  private sortSelection = (selection: Selection): void => {
    if (selection.anchor === null) return

    const { anchor, focus } = selection
    if (anchor.index > focus.index) {
      selection.anchor = focus
      selection.focus = anchor
    }
  }

  private moveToStartOfLine = (
    selection: Selection,
    shapedText: ShapedText,
    command: TextCommandArrow
  ): void => {
    if (selection.anchor === null) {
      const newFocus = this.layout.getStartOfLine(shapedText, selection.focus)
      if (newFocus === null || _.isEqual(newFocus, selection.focus)) return

      if (command.parameters.shift) {
        selection.anchor = _.cloneDeep(selection.focus)
      }
      selection.focus = newFocus
    } else {
      if (this.isForwards(selection)) {
        const newAnchor = this.layout.getStartOfLine(
          shapedText,
          selection.anchor
        )
        if (newAnchor === null || _.isEqual(newAnchor, selection.anchor)) return

        selection.anchor = newAnchor
      } else {
        const newFocus = this.layout.getStartOfLine(shapedText, selection.focus)
        if (newFocus === null || _.isEqual(newFocus, selection.focus)) return

        selection.focus = newFocus
      }
    }
  }

  private moveToEndOfLine = (
    selection: Selection,
    shapedText: ShapedText,
    command: TextCommandArrow
  ): void => {
    if (selection.anchor === null) {
      const newFocus = this.layout.getEndOfLine(shapedText, selection.focus)
      if (newFocus === null || _.isEqual(newFocus, selection.focus)) return

      if (command.parameters.shift) {
        selection.anchor = _.cloneDeep(selection.focus)
      }
      selection.focus = newFocus
    } else {
      if (this.isForwards(selection)) {
        const newFocus = this.layout.getEndOfLine(shapedText, selection.focus)
        if (newFocus === null || _.isEqual(newFocus, selection.focus)) return

        selection.focus = newFocus
      } else {
        const newAnchor = this.layout.getEndOfLine(shapedText, selection.anchor)
        if (newAnchor === null || _.isEqual(newAnchor, selection.anchor)) return

        selection.anchor = newAnchor
      }
    }
  }

  private moveToStartOfContent = (
    selection: Selection,
    command: TextCommandArrow
  ): void => {
    if (selection.anchor === null) {
      const newFocus = this.layout.getStartOfContent()
      if (newFocus === null || _.isEqual(newFocus, selection.focus)) return

      if (command.parameters.shift) {
        selection.anchor = _.cloneDeep(selection.focus)
      }
      selection.focus = newFocus
    } else {
      if (this.isForwards(selection)) {
        const newAnchor = this.layout.getStartOfContent()
        if (newAnchor === null || _.isEqual(newAnchor, selection.anchor)) return

        selection.anchor = newAnchor
      } else {
        const newFocus = this.layout.getStartOfContent()
        if (newFocus === null) return

        selection.focus = newFocus
      }
    }
  }

  private moveToEndOfContent = (
    selection: Selection,
    shapedText: ShapedText,
    command: TextCommandArrow
  ): void => {
    if (selection.anchor === null) {
      const newFocus = this.layout.getEndOfContent(shapedText)
      if (newFocus === null || _.isEqual(newFocus, selection.focus)) return

      if (command.parameters.shift) {
        selection.anchor = _.cloneDeep(selection.focus)
      }
      selection.focus = newFocus
    } else {
      if (this.isForwards(selection)) {
        const newFocus = this.layout.getEndOfContent(shapedText)
        if (newFocus === null || _.isEqual(newFocus, selection.focus)) return

        selection.focus = newFocus
      } else {
        const newAnchor = this.layout.getEndOfContent(shapedText)
        if (newAnchor === null || _.isEqual(newAnchor, selection.anchor)) return

        selection.anchor = newAnchor
      }
    }
  }

  private isForwards = (selection: Selection): boolean => {
    return (
      selection.anchor === null ||
      selection.anchor.index <= selection.focus.index
    )
  }
}
