import {
  BoxShadow,
  isBoxShadow2,
  isBoxShadow3,
  isBoxShadow4,
  Properties,
  unitColorToString,
  unitSizeToString,
} from 'application/export/css'
import { TailwindClassRenderer } from '../types'

export class TailwindShadow implements TailwindClassRenderer {
  render = (properties: Partial<Properties>): string[] => {
    const classes: string[] = []

    const boxShadow = properties.boxShadow
    if (boxShadow !== undefined && boxShadow !== 'none') {
      classes.push(...this.renderBoxShadow(boxShadow))
    }

    return classes
  }

  private renderBoxShadow = (shadows: BoxShadow[]): string[] => {
    return [`shadow-[${shadows.map(this.renderShadow).join(',')}]`]
  }

  private renderShadow = (shadow: BoxShadow): string => {
    let shadowString = ''

    if (shadow.inset) {
      shadowString += 'inset '
    }

    if (isBoxShadow2(shadow.values)) {
      shadowString += `${unitSizeToString(shadow.values[0])}_${unitSizeToString(shadow.values[1])}_${unitColorToString(shadow.color)}`
    } else if (isBoxShadow3(shadow.values)) {
      shadowString += `${unitSizeToString(shadow.values[0])}_${unitSizeToString(shadow.values[1])}_${unitSizeToString(shadow.values[2])}_${unitColorToString(shadow.color)}`
    } else if (isBoxShadow4(shadow.values)) {
      shadowString += `${unitSizeToString(shadow.values[0])}_${unitSizeToString(shadow.values[1])}_${unitSizeToString(shadow.values[2])}_${unitSizeToString(shadow.values[3])}_${unitColorToString(shadow.color)}`
    }

    return shadowString
  }
}
