import { ReadOnlyDocument } from 'application/document'
import { Layer } from './types'
import {
  ClientUpdateListener,
  CommandHandler,
  Update,
} from 'application/client'
import { ReadOnlyDocumentSelection } from 'application/selection'
import { ReadOnlyNode } from 'application/node'
import {
  EditorHighlightService,
  HighlightListener,
} from 'editor/highlight/service'
import _ from 'lodash'
import { ActionInitiator } from 'editor/action/initiator'
import {
  DraggingLayersLineListener,
  DraggingLine,
} from 'editor/action/dragLayers/line'
import { isContainerType, isDisplayNone } from 'application/attributes'
import {
  Action,
  ActionHandler,
  ActiveActionListener,
} from 'editor/action/types'
import { NodeSelectionAction } from 'application/action'

export type LayersPanelSettings = {
  layers: Layer[]
  selected: string[]
  line: DraggingLine | null
  collapsed: boolean
  selectDisabled: boolean
}

export type LayersPanelHanders = {
  highlight: (id: string) => void
  select: (id: string, multi: boolean) => void
  selectRange: (id: string) => void

  startDrag: () => void

  setLocked: (id: string, locked: boolean) => void
  setName: (id: string, name: string) => void

  setEditing: (id: string, editing: boolean) => void

  toggleOpen: (id: string) => void

  toggleCollapsed: () => void
}

export type LayersListener = (settings: LayersPanelSettings) => void

export class LayersPanel
  implements
    ClientUpdateListener,
    HighlightListener,
    DraggingLayersLineListener,
    ActiveActionListener
{
  private document: ReadOnlyDocument
  private selection: ReadOnlyDocumentSelection
  private selectionAction: NodeSelectionAction
  private highlightService: EditorHighlightService
  private actionInitiator: ActionInitiator
  private commandHandler: CommandHandler

  private highlighted: string[]
  private open: Set<string>
  private lastSelected: string | null
  private line: DraggingLine | null

  private listeners: { [key: string]: LayersListener }
  private layers: Layer[]
  private selected: string[]
  private editing: string | null
  private action: Action | null

  constructor(
    document: ReadOnlyDocument,
    selection: ReadOnlyDocumentSelection,
    selectionAction: NodeSelectionAction,
    highlightService: EditorHighlightService,
    actionInitiator: ActionInitiator,
    commandHandler: CommandHandler
  ) {
    this.document = document
    this.selection = selection
    this.selectionAction = selectionAction
    this.highlightService = highlightService
    this.actionInitiator = actionInitiator
    this.commandHandler = commandHandler

    this.highlighted = []
    this.open = new Set()
    this.lastSelected = null
    this.line = null

    this.listeners = {}
    this.layers = this.generateLayers()
    this.selected = this.getSelected()
    this.editing = null
    this.action = null
  }

  getSettings = (): LayersPanelSettings => {
    return {
      layers: this.layers,
      selected: this.selected,
      line: this.line,
      collapsed: this.isCollapsed(),
      selectDisabled: this.action !== null,
    }
  }

  getHandlers = (): LayersPanelHanders => {
    return {
      select: this.select,
      selectRange: this.selectRange,
      highlight: this.highlight,
      startDrag: this.startDrag,
      toggleOpen: this.toggleOpen,
      setLocked: this.setLocked,
      setName: this.setName,
      setEditing: this.setEditing,
      toggleCollapsed: this.toggleCollapsed,
    }
  }

  getTypes = (): Update['type'][] => {
    return ['node_updated', 'selection', 'initialize']
  }

  onUpdate = (updates: Update[]) => {
    for (const update of updates) {
      switch (update.type) {
        case 'initialize':
        case 'selection':
          this.openSelectedAncestors()
      }
    }

    this.layers = this.generateLayers()
    this.selected = this.getSelected()
    this.notifyListeners()
  }

  onHighlight = (id: string | null): void => {
    const initial = this.highlighted
    this.highlighted = id ? [id] : []
    if (_.isEqual(initial, this.highlighted)) return

    this.layers = this.generateLayers()
    this.notifyListeners()
  }

  onDraggingLine = (line: DraggingLine | null): void => {
    this.line = line
    this.notifyListeners()
  }

  onActiveAction = (handler: ActionHandler | null): void => {
    this.action = handler?.getType() || null
    this.notifyListeners()
  }

  subscribe(key: string, listener: LayersListener): void {
    this.listeners[key] = listener
    listener(this.getSettings())
  }

  unsubscribe(key: string): void {
    delete this.listeners[key]
  }

  rename = (): void => {
    const selected = this.getSelected()
    if (selected.length !== 1) return

    this.openAncestors(selected[0])
    this.setEditing(selected[0], true)
  }

  toggleCollapsed = (): void => {
    this.isCollapsed() ? this.openAll() : this.closeAll()
    this.layers = this.generateLayers()
    this.notifyListeners()
  }

  private select = (id: string, multi: boolean): void => {
    if (multi && this.isSelected(id)) {
      this.selectionAction.unselectNode(id)
    } else {
      this.selectionAction.selectNodes([id], !multi)
    }
    this.commandHandler.handle({ type: 'commit' })
  }

  private selectRange = (id: string): void => {
    if (!this.lastSelected) return

    const i1 = this.layers.findIndex((l) => l.id === this.lastSelected)
    const i2 = this.layers.findIndex((l) => l.id === id)

    const start = Math.min(i1, i2)
    const end = Math.max(i1, i2)

    const ids = this.layers.slice(start, end + 1).map((l) => l.id)
    this.selectionAction.selectNodes(ids, false)
    this.commandHandler.handle({ type: 'commit' })
  }

  private highlight = (id: string): void => {
    this.highlightService.highlight(id)
    this.layers = this.generateLayers()
    this.notifyListeners()
  }

  private startDrag = (): void => {
    this.actionInitiator.dragLayers()
  }

  private setLocked = (id: string, locked: boolean): void => {
    const node = this.document.getNode(id)
    if (!node) return

    this.commandHandler.handle({
      type: 'setNodeAttribute',
      params: {
        id: id,
        base: { locked: locked },
        style: {},
      },
    })
    this.commandHandler.handle({ type: 'commit' })
  }

  private setName = (id: string, name: string): void => {
    const node = this.document.getNode(id)
    if (!node) return

    this.commandHandler.handle({
      type: 'setNodeAttribute',
      params: {
        id: id,
        base: { name: name, nameOverridden: true },
        style: {},
      },
    })
  }

  private setEditing = (id: string, editing: boolean): void => {
    this.editing = editing ? id : null
    this.layers = this.generateLayers()
    this.notifyListeners()
  }

  private toggleOpen = (id: string): void => {
    if (this.open.has(id)) {
      this.open.delete(id)
    } else {
      this.open.add(id)
    }
    this.layers = this.generateLayers()
    this.notifyListeners()
  }

  private isCollapsed = (): boolean => {
    const open = Array.from(this.open)
    for (const id of open) {
      if (!this.hasSelectedDescendant(id)) return false
    }
    return true
  }

  private notifyListeners = (): void => {
    const settings = this.getSettings()
    for (const key in this.listeners) {
      this.listeners[key](settings)
    }
  }

  private hasSelectedDescendant = (id: string): boolean => {
    const node = this.document.getNode(id)
    if (!node) return false

    const descendants = this.document.getDescendants(node)
    return descendants.some((descendant) => this.isSelected(descendant.getId()))
  }

  private openSelectedAncestors = (): void => {
    const ids = this.getSelected()
    for (const id of ids) {
      const node = this.document.getNode(id)
      if (!node) continue

      this.openAncestors(id)
    }
    this.lastSelected = ids[0] || null
  }

  private openAncestors = (id: string): void => {
    const node = this.document.getNode(id)
    if (!node) return

    const ancestors = this.document.getAncestors(node)
    for (const ancestor of ancestors) {
      this.open.add(ancestor.getId())
    }
  }

  private openAll = (): void => {
    this.open.clear()

    const canvasId = this.selection.getSelectedCanvas()
    if (!canvasId) return

    const canvas = this.document.getNode(canvasId)
    if (!canvas) return

    const allDescendants = this.document.getDescendants(canvas)
    allDescendants.forEach((d) => {
      const node = this.document.getNode(d.getId())
      if (!node) return

      if (isContainerType(node.getBaseAttribute('type'))) {
        this.open.add(d.getId())
      }
    })
  }

  private closeAll = (): void => {
    const toClose: string[] = []
    for (const id of this.open) {
      if (!this.hasSelectedDescendant(id)) {
        toClose.push(id)
      }
    }
    for (const id of toClose) {
      this.open.delete(id)
    }
  }

  private generateLayers = (): Layer[] => {
    const canvasId = this.selection.getSelectedCanvas()
    if (!canvasId) return []

    const layers: Layer[] = []
    const canvas = this.document.getNode(canvasId)
    if (!canvas) return layers

    const children = canvas.getChildren()
    if (!children) return layers

    children.forEach((childId) => this.addTree(childId, layers))

    return layers
  }

  private addTree = (id: string, layers: Layer[], parent?: Layer): void => {
    const node = this.document.getNode(id)
    if (!node) return

    const layer = this.nodeToLayer(node, parent)
    layers.push(layer)

    const children = node.getChildren()
    if (!children || !layer.open) return

    children.forEach((childId) => this.addTree(childId, layers, layer))
  }

  private nodeToLayer = (
    node: ReadOnlyNode,
    parentLayer: Layer | null = null
  ): Layer => {
    const children = node.getChildren()
    const hasChildren = children && children.length > 0
    const layer: Layer = {
      id: node.getId(),
      name: node.getBaseAttribute('name'),
      type: node.getBaseAttribute('type'),
      level: parentLayer ? parentLayer.level + 1 : 0,
      selected: this.isSelected(node.getId()),
      parentSelected: parentLayer
        ? parentLayer.selected || parentLayer.parentSelected
        : false,
      editing: this.editing === node.getId(),
      hovered: this.highlighted.includes(node.getId()),
      locked: node.getBaseAttribute('locked') || false,
      hidden: isDisplayNone(node) || parentLayer?.hidden || false,
      parentLocked: parentLayer ? parentLayer.locked : false,
      parentHidden: parentLayer ? parentLayer.hidden : false,
      open: hasChildren ? this.open.has(node.getId()) : undefined,
    }
    return layer
  }

  private isSelected = (id: string): boolean => {
    return this.selection.getSelected().some((node) => node.getId() === id)
  }

  private getSelected = (): string[] => {
    return this.selection.getSelected().map((node) => node.getId())
  }
}
