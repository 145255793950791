import { DesignColor } from 'themes'

interface PageBackgroundProps {
  children?: React.ReactNode
}

export default function ProjectBackground({ children }: PageBackgroundProps) {
  return (
    <div
      style={{
        width: '100vw',
        height: '100vh',
        backgroundColor: DesignColor('menuBackground'),
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {children}
    </div>
  )
}
