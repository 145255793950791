import { Style } from '../css'
import { ReadOnlyNode } from 'application/node'
import { HTMLDocument } from '../html/types'
import { createSelector } from '../utils'
import { AttributeTransformer } from './types'
import { AttributeCursor } from 'application/attributes'

export class CursorStyle implements AttributeTransformer {
  transformBase = (node: ReadOnlyNode, htmlDocument: HTMLDocument): void => {
    const selector = createSelector(node.getId(), 'default')
    const style = htmlDocument.getStyles().get(selector)

    const mode = node.getDefaultSelector().styles['cursor']
    if (!mode) return

    switch (mode) {
      case 'auto':
      case 'pointer':
      case 'cancel':
        this.addCursor(style, mode)
        break
    }
  }

  private addCursor = (style: Style, mode: AttributeCursor): void => {
    switch (mode) {
      case 'auto':
      case 'pointer':
        style.setProperty('cursor', mode)
        break
      case 'cancel':
        style.setProperty('cursor', 'not-allowed')
        break
    }
  }
}
