import { Properties, UnitPercent } from 'application/export/css'
import { TailwindClassRenderer } from '../types'

export class TailwindClassOpacity implements TailwindClassRenderer {
  render = (properties: Partial<Properties>): string[] => {
    const classes: string[] = []

    const opacity = properties.opacity
    if (opacity !== undefined) {
      classes.push(...this.renderOpacity(opacity))
    }

    return classes
  }

  private renderOpacity = (opacity: UnitPercent): string[] => {
    const value = opacity.value
    if (value === 100) return []
    if (value % 5 === 0) {
      return [`opacity-${value}`]
    }
    return [`opacity-[${value / 100}]`]
  }
}
