import { WriteDocument } from 'application/document'
import { PastePositionHandler } from '../paste'
import { PasteAtOriginalOffset } from './original'
import { CopySnapshot } from '../../types'
import { PasteAtAvailableCanvasPosition } from './canvasAvailable'
import { DocumentSelection } from 'application/selection'
import { PasteAtPoint } from './point'
import { ReadOnlyNode } from 'application/node'
import { PasteAtCanvasCenter } from './canvas'
import { CameraService } from 'application/camera'
import { Point } from 'application/shapes'

export class PastePositionHandlerFactory {
  private document: WriteDocument
  private documentSelection: DocumentSelection
  private cameraService: CameraService

  constructor(
    document: WriteDocument,
    documentSelection: DocumentSelection,
    cameraService: CameraService
  ) {
    this.document = document
    this.documentSelection = documentSelection
    this.cameraService = cameraService
  }

  createPaste = (
    copySnapshot: CopySnapshot,
    addOffset: boolean = true,
    outside: boolean = false,
    point: Point | undefined = undefined
  ): PastePositionHandler => {
    if (point) return new PasteAtPoint(this.document, point)

    const selected = this.documentSelection.getSelected()
    const ids = selected.map((n) => n.getId())
    const allSame = copySnapshot.ids.every((id) => ids.includes(id))

    if (copySnapshot.ids.length === 1 && allSame && addOffset) {
      const node = this.document.getNode(copySnapshot.ids[0])
      if (!node) return new PasteAtOriginalOffset(this.document)

      const parent = this.document.getParent(node)
      if (!parent) return new PasteAtOriginalOffset(this.document)

      if (parent.getBaseAttribute('type') === 'canvas') {
        return new PasteAtAvailableCanvasPosition(this.document)
      }
    }

    const selectedOnPageDescendant = this.selectedOnPageDescendant()
    if (!selectedOnPageDescendant && outside) {
      return new PasteAtCanvasCenter(this.cameraService)
    } else {
      return new PasteAtOriginalOffset(this.document)
    }
  }

  createReplace = (node: ReadOnlyNode): PastePositionHandler => {
    const x = node.getBaseAttribute('x')
    const y = node.getBaseAttribute('y')
    return new PasteAtPoint(this.document, { x, y })
  }

  private selectedOnPageDescendant = (): boolean => {
    const selected = this.documentSelection.getSelected()
    if (selected.length === 0) return false
    return selected.every((node) => {
      if (node.getBaseAttribute('type') === 'page') return true
      const ancestors = this.document.getAncestors(node)
      return ancestors.some(
        (ancestor) => ancestor.getBaseAttribute('type') === 'page'
      )
    })
  }
}
