import { Layout } from '../layout/layout'
import { Shaper } from 'application/text'
import { CommandHandler, EditorState } from '../textEditor'
import { TextCommand } from '../command/types'
import { State } from '../state/types'
import { deleteSelection } from './utils'

export class DeleteHandler implements CommandHandler {
  private state: EditorState
  private layout: Layout
  private shaper: Shaper

  constructor(state: EditorState, layout: Layout, shaper: Shaper) {
    this.state = state
    this.layout = layout
    this.shaper = shaper
  }

  handle = (command: TextCommand): void => {
    switch (command.type) {
      case 'delete':
        this.handleDelete()
        break
    }
  }

  private handleDelete = (): void => {
    const state = this.state.get()

    this.deleteText(state)

    this.state.set(state)
  }

  private deleteText = (state: State): void => {
    if (state.selection.anchor) {
      deleteSelection(state)
      return
    }

    const { content, selection } = state
    const { focus } = selection

    const index = focus.index
    if (index === 0) return

    const shapedText = this.shaper.getShapedText(state.content, state.width)
    const newFocus = this.layout.getIndexBefore(shapedText, focus)
    if (newFocus === null) return

    const newContent =
      content.content.slice(0, index - 1) + content.content.slice(index)
    const newContentStyles = content.contentStyles
      .slice(0, index - 1)
      .concat(content.contentStyles.slice(index))

    state.content.content = newContent
    state.content.contentStyles = newContentStyles

    state.selection.focus = newFocus
    state.selection.anchor = null
  }
}
