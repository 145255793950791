import { ReadOnlyDocument } from 'application/document'
import { ReadOnlyDocumentSelection } from 'application/selection'
import { ReadOnlyNode, ReadOnlyNodeMap } from 'application/node'
import { Point, encapsulates } from 'application/shapes'
import { CopySnapshot } from '../types'
import { getClippedRectangle, getRectangle } from 'application/attributes'

export class Copy {
  private document: ReadOnlyDocument
  private documentSelection: ReadOnlyDocumentSelection

  private snapshot: CopySnapshot | null

  constructor(
    document: ReadOnlyDocument,
    documentSelection: ReadOnlyDocumentSelection
  ) {
    this.document = document
    this.documentSelection = documentSelection

    this.snapshot = null
  }

  getSnapshot = (): CopySnapshot => {
    const selected = this.documentSelection.getSelected()

    const ids = selected.map((n) => n.getId())
    const canvasId = this.documentSelection.getSelectedCanvas()

    const nodes = this.getNodeMap(selected)

    const canvasChild = this.getCanvasChild(selected)
    const outsideParent = this.getOutsideParent(selected)

    const offsets = this.getOffsets(selected)
    const indexes = this.getIndexes(selected)

    return {
      ids,
      canvasId,
      nodes,
      canvasChild,
      outsideParent,
      offsets,
      indexes,
    }
  }

  getSavedSnapshot = (): CopySnapshot | null => {
    return this.snapshot
  }

  saveSnapshot = (): void => {
    this.snapshot = this.getSnapshot()
  }

  setSnapshot = (snapshot: CopySnapshot): void => {
    this.snapshot = snapshot
  }

  clearSnapshot = (): void => {
    this.snapshot = null
  }

  private getNodeMap = (nodes: ReadOnlyNode[]): ReadOnlyNodeMap => {
    return nodes
      .flatMap((n) => [n, ...this.document.getDescendants(n)])
      .map((n) => n.clone())
      .reduce((acc, n) => {
        acc[n.getId()] = n
        return acc
      }, {} as ReadOnlyNodeMap)
  }

  private getCanvasChild = (
    nodes: ReadOnlyNode[]
  ): { [key: string]: boolean } => {
    const canvasChild: { [key: string]: boolean } = {}
    nodes.forEach((n) => {
      const parent = this.document.getParent(n)
      if (!parent) return

      canvasChild[n.getId()] = parent.getBaseAttribute('type') === 'canvas'
    })
    return canvasChild
  }

  private getOutsideParent = (
    nodes: ReadOnlyNode[]
  ): { [key: string]: boolean } => {
    const outsideParent: { [key: string]: boolean } = {}
    nodes.forEach((n) => {
      const parent = this.document.getParent(n)
      if (!parent) return

      const parentBounds = getClippedRectangle(parent)
      const nodeBounds = getRectangle(n)

      outsideParent[n.getId()] = !encapsulates(parentBounds, nodeBounds)
    })
    return outsideParent
  }

  private getOffsets = (nodes: ReadOnlyNode[]): { [key: string]: Point } => {
    const offsets: { [key: string]: Point } = {}
    nodes.forEach((n) => {
      const x = n.getBaseAttribute('x')
      const y = n.getBaseAttribute('y')

      const parent = this.document.getParent(n)
      if (!parent) return { x, y }

      const parentX = parent.getBaseAttribute('x')
      const parentY = parent.getBaseAttribute('y')

      offsets[n.getId()] = {
        x: x - parentX,
        y: y - parentY,
      }
    })
    return offsets
  }

  private getIndexes = (nodes: ReadOnlyNode[]): { [key: string]: number } => {
    const indexes: { [key: string]: number } = {}
    nodes.forEach((n, i) => {
      const parent = this.document.getParent(n)
      if (!parent) return

      const children = parent.getChildren()
      if (!children) return

      indexes[n.getId()] = children.indexOf(n.getId())
    })
    return indexes
  }
}
