import { AttributeOverflow } from 'application/attributes'
import Dropdown, {
  dropdownOption,
} from 'components/Library/Components/Dropdown/Dropdown'
import OverrideReset from 'components/Library/Components/Inputs/OverrideReset/OverrideReset'
import usePanelHelper from 'hooks/editor/usePanelHelper'
import { editor } from 'index'

export default function OverflowBlock() {
  const { settings, handlers } = usePanelHelper(
    editor.getUI().getOverflowPanel()
  )
  const overrides = usePanelHelper(editor.getUI().getStyleOverridesPanel())
  if (!settings || !settings.attributes) return <></>

  const { overflow } = settings.attributes
  const { setOverflow } = handlers
  const { hasOverride } = overrides.handlers

  return (
    <OverrideReset keys={['overflow']}>
      <Dropdown
        width={96}
        mixed={overflow === 'Mixed'}
        tooltipKey={'Overflow'}
        selected={overflow === undefined ? 'visible' : overflow}
        options={dropdownOptions}
        select={(o) => {
          if (o === 'Mixed' || o === undefined) return
          setOverflow(o)
        }}
        defaultIcon={'Overflow'}
        mixedIcon={'Overflow'}
        empty={overflow === undefined}
        hasIcon={true}
        commit={true}
        hasOverride={hasOverride(['overflow'])}
      />
    </OverrideReset>
  )
}

const dropdownOptions: dropdownOption<AttributeOverflow>[] = [
  {
    value: 'auto',
    text: 'Auto',
  },
  {
    value: 'visible',
    text: 'Visible',
  },
  {
    value: 'hidden',
    text: 'Hidden',
  },
  {
    value: 'scroll',
    text: 'Scroll',
  },
]
