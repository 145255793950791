import {
  AttributeShadow,
  AttributeType,
  MultiselectStyleMap,
  createNewShadow,
} from 'application/attributes'
import { StyleAttributePanel } from './styleAttributePanel'
import { ReadOnlyNode } from 'application/node'

type ShadowPanelKeys = 'shadows'

type ShadowPanelAttributes = Pick<MultiselectStyleMap, ShadowPanelKeys> | null

export interface ShadowPanelState {
  attributes: ShadowPanelAttributes
}

export interface ShadowPanelHandlers {
  add: () => void
  remove: (index: number) => void
  update: (index: number, shadow: AttributeShadow) => void
  clearMixed: () => void
}

export class ShadowPanel extends StyleAttributePanel<
  ShadowPanelState,
  ShadowPanelHandlers,
  ShadowPanelKeys
> {
  getSettings(): ShadowPanelState {
    return {
      attributes: this.attributes,
    }
  }

  getHandlers(): ShadowPanelHandlers {
    return {
      add: this.handleAdd,
      remove: this.handleRemove,
      update: this.handleUpdate,
      clearMixed: this.handleClearMixed,
    }
  }

  private handleAdd = (): void => {
    const defaults = this.getDefault()
    this.setMulti({ shadows: [createNewShadow(), ...defaults] })
  }

  private handleRemove = (index: number): void => {
    const defaults = this.getDefault()
    this.setMulti({ shadows: defaults.filter((_, i) => i !== index) })
  }

  private handleUpdate = (index: number, shadow: AttributeShadow): void => {
    const defaults = this.getDefault()
    this.setMulti({
      shadows: defaults.map((s, i) => (i === index ? shadow : s)),
    })
  }

  private handleClearMixed = (): void => {
    const defaults = this.getDefault()
    this.setMulti({ shadows: defaults })
  }

  private getDefault = (): AttributeShadow[] => {
    const nodes = this.getNodes()
    if (nodes.length === 0) return []

    for (const node of nodes) {
      const shadow = node.getStyleAttribute('shadows')
      if (shadow && shadow.length > 0) return shadow
    }

    return []
  }

  protected override getNodeFilterPredicate = (): ((
    node: ReadOnlyNode,
    parent: ReadOnlyNode | null
  ) => boolean) => {
    return (node, _) => allowedTypes.includes(node.getBaseAttribute('type'))
  }
}

const allowedTypes: AttributeType[] = [
  'frame',
  'image',
  'input',
  'form',
  'anchor',
]
