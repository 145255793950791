import { ReadOnlyNode } from 'application/node'
import { HTMLDocument } from '../html/types'
import { createSelector } from '../utils'
import { AttributeTransformer } from './types'
import { addBackgroundToStyle } from './utils'

export class BackgroundStyle implements AttributeTransformer {
  transformBase = (node: ReadOnlyNode, htmlDocument: HTMLDocument): void => {
    const selector = createSelector(node.getId(), 'default')
    const style = htmlDocument.getStyles().get(selector)

    const fills = node.getDefaultSelector().styles['background']
    if (!fills || fills.length !== 1) return

    const fill = fills[0]
    addBackgroundToStyle(fill, style)
  }
}
