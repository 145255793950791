import { HashingService } from './hash'
import { HTTPService } from './http'
import {
  RequestLogin,
  RequestUserCreate,
  RequestUserUpdate,
  AuthToken,
  UserInfo,
  RequestReset,
} from './types'

interface AuthService {
  setToken(token: string): void
  getToken(): string
}

export class UserService {
  private httpService: HTTPService
  private authService: AuthService
  private hashingService: HashingService

  constructor(
    httpService: HTTPService,
    authService: AuthService,
    hashingService: HashingService
  ) {
    this.httpService = httpService
    this.authService = authService
    this.hashingService = hashingService
  }

  login = async (request: RequestLogin): Promise<void> => {
    const hash = await this.hashingService.hash(request.password)
    return this.httpService
      .request<AuthToken>('/api/auth/login', 'POST', {
        email: request.email,
        password: hash,
      })
      .then((response: AuthToken) => {
        this.authService.setToken(response.token)
      })
  }

  reset = async (request: RequestReset): Promise<void> => {
    return this.httpService.request<void>('/api/auth/reset', 'POST', request)
  }

  createAccount = async (request: RequestUserCreate): Promise<void> => {
    const hash = await this.hashingService.hash(request.password)
    const response = await this.httpService.request<AuthToken>(
      '/api/user',
      'POST',
      {
        ...request,
        password: hash,
      }
    )
    this.authService.setToken(response.token)
  }

  getUserInfo = async (): Promise<UserInfo> => {
    const token = this.authService.getToken()
    return this.httpService.request<UserInfo>(
      '/api/user',
      'GET',
      {},
      { Authorization: `Bearer ${token}` }
    )
  }

  updateUserInfo = async (request: RequestUserUpdate): Promise<UserInfo> => {
    const token = this.authService.getToken()
    return this.httpService.request<UserInfo>('/api/user', 'PUT', request, {
      Authorization: `Bearer ${token}`,
    })
  }

  updateUserPassword = async (
    token: string,
    email: string,
    password: string
  ): Promise<void> => {
    const hash = await this.hashingService.hash(password)
    await this.httpService.request<void>(
      '/api/user/password',
      'PUT',
      { password: hash },
      { Authorization: `Bearer ${token}` }
    )

    return this.httpService
      .request<AuthToken>('/api/auth/login', 'POST', {
        email: email,
        password: hash,
      })
      .then((response: AuthToken) => {
        this.authService.setToken(response.token)
      })
  }
}
