import { Window } from './types'
import { Camera, CameraUpdateListener } from 'application/camera'
import { ClientUpdateListener, Update } from 'application/client'
import { ReadOnlyDocument } from 'application/document'
import { Rectangle } from 'application/shapes'
import { DesignColor } from 'themes'
import { ReadOnlyNode } from 'application/node'
import { WindowTransformer } from './transformer/window'
import { Canvas, CanvasUpdateListener } from 'editor/canvas/canvas'
import { computeSelectionRectangle } from 'application/selection'
import { HighlightListener } from 'editor/highlight/service'

export const hapticHighlightWindowKey = 'highlightWindow'

export class HapticHighlightWindow
  implements
    ClientUpdateListener,
    CameraUpdateListener,
    CanvasUpdateListener,
    HighlightListener
{
  private document: ReadOnlyDocument
  private canvas: Canvas

  private ids: string[]
  private window: Window | null
  private camera: Camera | null

  constructor(document: ReadOnlyDocument, canvas: Canvas) {
    this.document = document
    this.canvas = canvas

    this.ids = []
    this.window = null
    this.camera = null
  }

  onCamera = (camera: Camera) => {
    this.camera = camera
    this.render()
  }

  onCanvasInit = () => {
    this.render()
  }

  getTypes = (): Update['type'][] => {
    return ['node_updated']
  }

  onHighlight = (id: string | null) => {
    this.ids = id ? [id] : []
    this.window = this.computeWindow(this.ids)
    this.render()
  }

  onUpdate = (updates: Update[]) => {
    for (const update of updates) {
      switch (update.type) {
        case 'node_updated':
          this.updateNode(update.data.id)
          break
      }
    }
  }

  private updateNode = (id: string) => {
    if (this.ids.includes(id)) {
      this.window = this.computeWindow(this.ids)
      this.render()
    }
  }

  private render = () => {
    if (!this.canvas.isReady()) return
    if (this.window === null || this.camera === null) {
      this.canvas.deleteHaptic(hapticHighlightWindowKey)
    } else {
      this.canvas.setHaptic(
        hapticHighlightWindowKey,
        WindowTransformer.transform(
          this.canvas.getContext(),
          [this.window],
          this.camera
        )
      )
    }
  }

  private computeWindow = (ids: string[]): Window | null => {
    if (ids.length === 0) return null
    return {
      rectangle: this.computeRectangle(ids),
      border: DesignColor('inputHighlight'),
      fill: null,
      borderWidth: 2,
      bubbles: 'none',
      shape: 'rectangle',
    }
  }

  private computeRectangle = (ids: string[]): Rectangle => {
    const nodes = ids
      .map((id) => this.document.getNode(id))
      .filter((n) => n) as ReadOnlyNode[]
    return computeSelectionRectangle(nodes) || { x: 0, y: 0, w: 0, h: 0 }
  }
}
