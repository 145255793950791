import React, { useEffect, useState } from 'react'

export function useAnimatedTabs<T>(tab: T, tabs: T[], offset: number = 0) {
  const refs: { [key: string]: React.RefObject<HTMLDivElement> } = tabs
    .map((tab) => ({
      [tab as string]: React.createRef<HTMLDivElement>(),
    }))
    .reduce((a, b) => ({ ...a, ...b }), {})
  const [firstLoad, setFirstLoad] = useState(true)
  const [line, setLine] = useState<{
    animate: boolean
    left: number
    width: number
  }>({
    animate: false,
    left: 0,
    width: 0,
  })

  useEffect(() => {
    setFirstLoad(false)
    const ref = refs[tab as string]
    if (ref) {
      setLine({
        animate: !firstLoad,
        left: (ref.current?.offsetLeft || 0) + offset,
        width: ref.current?.offsetWidth || 0,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab])

  return { refs, line }
}
