import { FlexPositionItem } from './flexPositionItem'
import { PositionContext } from '../context/positionContext'
import { CssFlexAlign, CssFlexDirection } from '../types/types'

export class FlexPositionItemImpl extends FlexPositionItem {
  initialize = (direction: CssFlexDirection, align: CssFlexAlign): void => {
    switch (direction) {
      case CssFlexDirection.row:
        this.mainSize = this.layoutItem.getWidth()
        this.crossSize = this.layoutItem.getHeight()
        break
      case CssFlexDirection.column:
        this.mainSize = this.layoutItem.getHeight()
        this.crossSize = this.layoutItem.getWidth()
        break
    }

    const alignSelf = this.layoutItem.getAlignSelf()
    if (alignSelf !== CssFlexAlign.auto) {
      this.alignSelf = alignSelf
    } else {
      this.alignSelf = align
    }

    this.flexDirection = direction
  }

  place = (context: PositionContext, crossSize: number): void => {
    switch (this.alignSelf) {
      case CssFlexAlign.auto:
      case CssFlexAlign.start:
      case CssFlexAlign.stretch:
        this.layoutItem.place(context)
        break
      case CssFlexAlign.center:
        switch (this.flexDirection) {
          case CssFlexDirection.row:
            this.layoutItem.place(
              context.newOffsetY((crossSize - this.crossSize) / 2)
            )
            break
          case CssFlexDirection.column:
            this.layoutItem.place(
              context.newOffsetX((crossSize - this.crossSize) / 2)
            )
            break
        }
        break
      case CssFlexAlign.end:
        switch (this.flexDirection) {
          case CssFlexDirection.row:
            this.layoutItem.place(
              context.newOffsetY(crossSize - this.crossSize)
            )
            break
          case CssFlexDirection.column:
            this.layoutItem.place(
              context.newOffsetX(crossSize - this.crossSize)
            )
            break
        }
        break
    }
  }
}
